<template>
  <card>
    <div class="row" style="margin-right:10px !important;margin-left:10px !important;background-color:rgba(249, 249, 249 , 5;">
      <div class="col-md-6" v-if="finalUserPermissions.includes('CUSTOMERS_ALL_GET')">
          <label class="typo__label" v-if="allCustomers.length > 0">Search TASAC customer(s)</label>
          <label class="typo__label" v-else>
              <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
              Loading TASAC customers, please wait
          </label>
          <multiselect v-model="customerIds" 
          :options="allCustomers" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="true"
          :hide-selected="true"
          :taggable="true"
          :disabled="allCustomers.length <= 0"
          placeholder="Search TASAC customer(s)" 
          label="name" 
          track-by="customerId" 
          :preselect-first="false"
          >
          <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{values.length === 1 ? 'customer' : 'customers'}} selected</span>
          </template>
          </multiselect>
          <!-- <pre class="language-json"><code>{{ customerIds  }}</code></pre> -->
      </div>
      <div :class="[finalUserPermissions.includes('CUSTOMERS_ALL_GET') ? 'col-md-6' : 'col-md-12']">
          <label class="typo__label" v-if="activeTariffItems.length > 0">Select tariff Items</label>
          <label class="typo__label" v-else>
              <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
              Loading tariff Items, please wait
          </label>
          <multiselect v-model="tariffItem" 
          :options="activeTariffItems"
          :close-on-select="true" 
          :clear-on-select="false" 
          :preserve-search="true"
          :disabled="activeTariffItems.length <= 0"
          placeholder="Select tariff Items" 
          label="tariffItemName" 
          track-by="tariffItemId" 
          :preselect-first="false"
          >

          </multiselect>
          <!-- <pre class="language-json"><code>{{ tariffItem  }}</code></pre> -->
      </div>
      <div class="col-md-12">
        <br />
      </div>
      <div class="col-md-4">
        <label>Application Type</label>
        <model-list-select
          :list="applicationTypes"
          v-model="selectedRequestType"
          class="form-control"
          option-value="id"
          option-text="name"
          placeholder="Application Type"
        >
        </model-list-select>
      </div>
      
      <div class="col-md-4">
        <label for="fileNo" class="form-label">File Number</label>
        <input type="text" class="form-control" id="fileNo" v-model="fileNo" placeholder="File Number">
      </div>
      <div class="col-md-4">
          <label class="typo__label">Select application status(es)</label>
          <multiselect v-model="applicationStatuses" 
          :options="applicationStatusesProp" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="true" 
          :hide-selected="true"
          placeholder="Select application status(es)" 
          label="name" 
          track-by="id" 
          :preselect-first="false"
          >
          <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{values.length === 1 ? 'status' : 'statuses'}} selected</span>
          </template>
          </multiselect>
          <!-- <pre class="language-json"><code>{{ applicationStatuses  }}</code></pre> -->
      </div>
      <div class="col-md-12">
        <br />
      </div>
      <div class="col-md-4">
        <label for="sDate" class="form-label">Start Date</label>
        <input type="date" class="form-control" id="sDate" v-model="sDate" placeholder="Start Date">
      </div>
      <div class="col-md-4">
        <label for="eDate" class="form-label">End Date</label>
        <input type="date" class="form-control" id="eDate" v-model="eDate" placeholder="End Date">
      </div>
      <div class="col-md-4">
        <label for="blNo" class="form-label">BL Number</label>
        <input type="text" class="form-control" id="blNo" v-model="blNo" placeholder="BL Number">
      </div>
      <div class="col-md-12">
        <br />
      </div>
      <div class="col-md-4">
          <label class="typo__label">Select regime(s)</label>
          <multiselect v-model="regimeIds" 
          :options="getRegimes" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="true"
          :hide-selected="true"
          placeholder="Select regime(s)" 
          label="regimeName" 
          track-by="regimeId" 
          :preselect-first="false"
          >
          <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{values.length === 1 ? 'regime' : 'regimes'}} selected</span>
          </template>
          </multiselect>
          <!-- <pre class="language-json"><code>{{ regimeIds  }}</code></pre> -->
      </div>
      <div class="col-md-4">
          <label class="typo__label" v-if="stations.length > 0">Select stations</label>
          <label class="typo__label" v-else>
              <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
              Loading stations, please wait
          </label>
          <multiselect v-model="stationIds" 
          :options="stations" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="true"
          :hide-selected="true"
          :disabled="stations.length <= 0"
          placeholder="Select stations" 
          label="stationDescription" 
          track-by="stationId" 
          :preselect-first="false"
          >
          <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{values.length === 1 ? 'station' : 'stations'}} selected</span>
          </template>
          </multiselect>
          <!-- <pre class="language-json"><code>{{ stationIds  }}</code></pre> -->
      </div>
      <div class="col-md-4">
          <!-- WAITING BACKEND -->
          <base-input class="special-bottom" placeholder="TANSAD Number" label="TANSAD Number"
          type="text" v-model="tansadNo"></base-input>
      </div>
      <div class="col-md-4">
          <label class="typo__label">Select dismissal Reason</label>
          <multiselect v-model="dismissCase" 
          :options="dismissCases"
          :close-on-select="true" 
          :clear-on-select="false" 
          :preserve-search="true"
          placeholder="Select dismissal Reason" 
          label="name" 
          track-by="id" 
          :preselect-first="false"
          >

          </multiselect>
          <!-- <pre class="language-json"><code>{{ dismissCase  }}</code></pre> -->
      </div>
      <div class="col-md-4" style="margin-top:35px;" v-if="finalUserPermissions.includes('CUSTOMERS_ALL_GET')">
        <div class="custom-control custom-checkbox">
          <base-checkbox v-model="isMyAssignment" :id="'isMyAssignment'" :label="'My assignments'" :inline="true"><label> My Assignment</label></base-checkbox>
        </div>
      </div>
      <div class="col-md-12">
        <hr />
      </div>
      <div class="col-md-12">
        <button
          @click.prevent="resetParams"
          class="btn btn-fill btn-outline-info float-right custom-btn-all"
          data-dismiss="modal"
        >
          Reset
        </button>
        <button
          @click.prevent="generateReportNew"
          class="btn mr-2 btn-primary float-right custom-btn"
          data-dismiss="modal"
        >
          Generate Report
        </button>
      </div>
      <div class="col-md-12">
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <button class="btn btn-primary btn-space custom-btn" @click.prevent="loadAllreports">Refresh Reports</button>
      </div>
      <div class="col-md-12">
        <data-table
          :tableHeaders="tableHeaders"
          :tableData="filteredRequests"
          :tableDataKeys="tableDataKeys"
          :pageSizeProp="pageSize"
          :pageNo="pageNo"
          :hasFilter="false"
          :myLoader="loading"
          :myFirstLoader="loading"
          :noDataFound="noDataFound"
          :searchParameterProp="searchParameter"
          :loadingTitle="'Please wait while system is loading reports'"
          :hasManageButton="false"
          :totalElements="totalElements"
          :totalPages="totalPages"
          :isFirst="isFirst"
          :isLast="isLast"
          :isHovered="[]"
          :pageNumber="pageNumber"
          :has-custom-button="true"
          custom-button-name="Download"
          :is-hovered="false"
          :permissions="[ {key: 'searchPermission', value: 'CFAREPORT_ASYNC_REPORT_GET'} ]"
          @fireCustomMethod="getFileFromMinio"
          @showToolTip="showToolTip"
          @hideToolTip="hideToolTip"
          @onChange="onChange"
          @goToPage="goToPage"
          @goToBillGenerate="goToApplication"
        >
          <div slot = "pagination">
            <paginate
              v-model="getIsPage"
              :page-count=parseInt(totalPages)
              :page-range="3"
              :margin-pages="2"
              :click-handler="goToPage"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'">
            </paginate>
          </div>
        </data-table>

      </div>
      <global-modal v-if="loadingDoenload">
        <div slot="globalLoader">
          <div class="row text-center">
            <div class="col-md-12">
              {{ reportMessage }}, Please wait
            </div>
            <div class="col-md-12">
              <br>
            </div>
            <div class="col-md-12">
              <pulse-loader
                :color="color"
                :size="size"
              />
            </div>
          </div>
        </div>
      </global-modal>
    </div>
  </card>

</template>

<script>
    import API from "../../../api";
    import API_CFA from '../../../api/cfa'
    import {ModelListSelect} from 'vue-search-select';
    import PulseLoader from "vue-spinner/src/PulseLoader";
    import {mapActions, mapGetters} from "vuex";
    import { CFA_REPORT_APPLICATION_TYPES, CFA_REQUEST_STATUSES, DISMISSAL_REASONS } from '../../../mixins/constants'
    import Multiselect from 'vue-multiselect'
    import BaseCheckbox from '../../../components/Inputs/BaseCheckbox.vue';
    import GlobalModal from '../../../components/globalLoader'


    export default {
        name: "report",

      components: {
        PulseLoader,
        ModelListSelect,
        GlobalModal,
        Multiselect,
        DataTable: () => import('../../../components/DataTable/dataTable'),
        BaseCheckbox,
      },
      created() {

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
        this.$store.dispatch("pagenames/currentPageName", "CFA Reports");
        this.finalUserPermissions.includes('CUSTOMERS_ALL_GET') ? this.loadAllCustomers() : ''
        this.loadAllStations();
        this.loadAllRegimes();
        this.loadAllreports();
        this.loadAllActiveTariffItems()
      },

      computed:{
        ...mapGetters('global' , [

          'getAllCustomers',
          'getAllRegimes',
          'getAllStations',
          'getActiveTariffItems',
          'getAllCustomers'

        ]),

        getIsPage: {

          get: function () {
            return this.isPage
          },
          set: function (newValue) {
            this.isPage = newValue;
          }

        }
      },

      data(){
          return{
            tableHeaders:['Description','From Date','To Date','Date Created', 'Generated By'],
            tableDataKeys:['description','startDate','endDate','createdDate', 'createdBy'],
            filteredRequests:[],
            finalUserPermissions: [],
            reportMessage: '',
            regimeIds: null,
            stationIds: null,
            customerIds: null,
            tariffItem: null,
            tansadNo: null,
            applicationStatuses: null,
            dismissCase: null,
            isMyAssignment: false,
            applicationStatusesProp: CFA_REQUEST_STATUSES,
            dismissCases: DISMISSAL_REASONS,
            getRegimes: [],
            activeTariffItems: [],
            loading:false,
            loadingDoenload: false,
            pageNo: 0,
            pageSize: 15,
            totalElements: "",
            totalPages: "",
            isFirst: false,
            isLast: false,
            pageNumber: '',
            pageCount: 1,
            noDataFound:false,
            searchParameter: '',
            isPage: 0,

            params:"",
            color: 'green',
            size: '12px',
            size1: '9px',

            allCustomers:[],
            stations:[],
            regimes:[],

            selectedRequestType:"",
            selectedStationId:"",
            fileNo:"",
            sDate:"",
            eDate:"",
            blNo:"",
            applicationTypes:CFA_REPORT_APPLICATION_TYPES
          }
      },

      methods:{

        ...mapActions("global", [

          'loadRegimes',
          'loadStations',
          'loadFileFromMinio',
          'loadActiveTariffItems',
          'loadCustomers'
        ]),

        customRegimeCodeAndName (item) {

            return `${item.regimeName} [ ${item.regimeCode} ]`

        },

        loadAllRegimes() {

            this.loadRegimes({}).then(() => {

                this.getRegimes = this.getAllRegimes

            }).catch(() => {});

        },

        loadAllCustomers() {

            this.loadCustomers({}).then(() => {

              this.allCustomers = this.getAllCustomers;

            }).catch(() => {})

        },

        loadAllStations() {

          this.loadStations({}).then(() => {

            this.stations = this.getAllStations;

          }).catch(() => {


          });

        },

        loadAllActiveTariffItems() {

          this.loadActiveTariffItems({}).then(() => {

            this.activeTariffItems = this.getActiveTariffItems;

          }).catch(() => {})

      },

        generateReportNew () {
          
          this.loadingDoenload = true;
          this.reportMessage = 'Sending report request'

          let regimeIds = []
          let applicationStatuses = []
          let customerIds = []
          let stationIds = []
          
          if(this.regimeIds !== null && this.regimeIds.length >  0) {

              for(let x = 0 ; x < this.regimeIds.length ; x++) {

                  regimeIds.push(this.regimeIds[x].regimeId)

              }

          }
          
          if(this.applicationStatuses !== null && this.applicationStatuses.length >  0) {

              for(let y = 0 ; y < this.applicationStatuses.length ; y++) {

                  applicationStatuses.push(this.applicationStatuses[y].id)

              }

          }

          if(this.customerIds !== null && this.customerIds.length >  0) {

              for(let w = 0 ; w < this.customerIds.length ; w++) {

                  customerIds.push(this.customerIds[w].customerId)

              }

          }

          if(this.stationIds !== null && this.stationIds.length >  0) {

              for(let w = 0 ; w < this.stationIds.length ; w++) {

                  stationIds.push(this.stationIds[w].stationId)

              }

          }

          this.multipleParams = {

              applicationStatuses: applicationStatuses.length <= 0 ? null : applicationStatuses,
              assigmentUserId: this.assigmentUserId === '' ? null : this.assigmentUserId,
              blNumber: this.blNumber === '' ? null : this.blNumber,
              customerIds: customerIds.length <= 0 ? null : customerIds,
              dismissCase: this.dismissCase === null ? null : this.dismissCase.id,
              endDate: this.endDate === '' ? null : this.endDate,
              fileNo: this.fileNo === '' ? null : this.fileNo,
              regimeIds: regimeIds.length <= 0 ? null : regimeIds,
              requestType: this.requestType === '' ? null : this.requestType,
              startDate: this.startDate === '' ? null : this.startDate,
              stationIds: stationIds.length <= 0 ? null : stationIds,
              tansadNumber: this.tansadNumber === null ? null : this.tansadNumber,
              tariffItem: this.tariffItem === null ? null : this.tariffItem.tariffItemId,
              tansadNumber: this.tansadNo === null ? null : this.tansadNo,
              isMyAssignment: this.isMyAssignment

          }

          API_CFA.filterCfaApplicationsReportWithMultipleParameters(this.multipleParams).then((response) => {

            this.loadingDoenload = false;

            if(response.data.status){

              this.loadNotification('success', 4000, 'Report Generation',
                  'Report request has been successfully sent',
                  'response', 1000 , true  , true)

            }else {

              this.loadNotification('error', 4000, 'Report Generation',
                  'Report request not sent',
                  'response', 1000 , true  , true)

            }
          }).catch((err)=>{

            this.loadingDoenload = false;
            this.loadNotification('error', 4000, 'Report Generation',
                  'Report request not sent',
                  'response', 1000 , true  , true)

          })

        },

        loadParameters (fileUrl, fileName) {

          return new Promise((resolve) => {

            this.$store.dispatch('global/fileUrling', fileUrl);
            this.$store.dispatch('global/fileNaming', fileName);

            resolve()
          })

        },


        getFileFromMinio (data) {

          this.loadParameters(data.attachmentUrl, 'CFAReport').then(() => {
            this.loadingDoenload=true;
            this.loadFileFromMinio({}).then((res) => {

              if(res.status === 204) {

                this.loadNotification('warn', 4000, 'File Download',
                  'File not found, please contact system admin',
                  'response', 1000 , true  , true)

              } else {

                this.loadNotification('success', 4000, 'File Download',
                  'File has been successfully downloaded, please have a look at it',
                  'response', 1000 , true  , true)


              }

              this.loadingDoenload = false;

            }).catch(() => {
              this.loadingDoenload=false;
              this.loadNotification('error', 4000, 'File Download',
                'File not downloaded, please check your internet connection',
                'response', 1000 , true  , true);

              this.globalLoader = this.getGlobalLoader

            });

            this.globalLoader = this.getGlobalLoader

          }).catch(() => {

          })

        },

        sortingData(data) {
          this.filteredRequests=[];
          for(let x = 0 ; x < data.length ; x++) {

            const requestObject = {
              ...data[x],
              startDate: data[x].startDate !== 'START' ? this.dateTimeToHuman(data[x].startDate) : data[x].startDate,
              endDate: data[x].endDate !== 'END' ? this.dateTimeToHuman(data[x].endDate) : data[x].endDate,
              createdDate: this.dateTimeToHuman(data[x].createdDate),
            };

            this.filteredRequests.push(requestObject)

          }

        },

        resetParams(){

          this.selectedRequestType="";
          this.fileNo="";
          this.sDate="";
          this.eDate="";
          this.blNo="";

          this.applicationStatuses = null
          this.assigmentUserId = ''
          this.blNumber = ''
          this.customerIds = null
          this.dismissCase = ''
          this.endDate = ''
          this.fileNo = ''
          this.regimeIds = null
          this.requestType = ''
          this.startDate = ''
          this.stationIds = null
          this.tansadNumber = ''
          this.tariffItem = ''
          this.tansadNumber = ''
          this.isMyAssignment = false
        },

        generateReport(){
          this.loading=true;
          this.setparams();

          API.generateAsyncReport(this.params).then((resp)=>{
            this.loading=false;
            if(resp.data.status){
              this.notifyUser('success','Success','Report Generated');
            }else {
              this.notifyUser('error','Error','Report failed to be Generated');
            }
          }).catch((err)=>{
            this.loading=false;
            this.notifyUser('error','Error','Report failed to be Generated');
          })
        },

        setparams(){

          let params="";

          // this.customerId!==""? params=params + "customerId=" + this.customerId:"";
          // this.selectedRequestStatus!==""? params!==""? params=params + "&applicationStatus=" + this.selectedRequestStatus:params="applicationStatus=" + this.selectedRequestStatus:"";
          this.selectedRequestType!==""? params!==""? params=params + "&requestType=" + this.selectedRequestType:params="requestType=" + this.selectedRequestType:"";
          // this.selectedStationId!==""?params!==""?  params=params + "&stationId=" + this.selectedStationId:params="stationId=" + this.selectedStationId:"";
          // this.selectedRegimeId!==""?  params!==""? params=params + "&regimeId=" + this.selectedRegimeId:params="regimeId=" + this.selectedRegimeId: "";
          this.fileNo!==""? params!==""? params=params + "&fileNo=" + this.fileNo:params="fileNo=" + this.fileNo:"";
          this.sDate!==""? params!==""? params=params + "&startDate=" + this.sDate:params="startDate=" + this.sDate:"";
          this.eDate!==""? params!==""? params=params + "&endDate=" + this.eDate:params="startDate=" + this.sDate:"";
          this.blNo!==""? params!==""? params=params + "&blNo=" + this.blNo:params="blNo=" + this.blNo:"";

          this.params=params;
        },

        loadAllreports(){

          this.reportMessage = 'Downloading requested report'
          this.loading = true;
          API.loadAllGenerated(this.pageNo,this.pageSize).then((response) => {
            
            if(response.data.status){

              this.loading = false;
              this.totalPages = response.data.data.totalPages;
              this.totalElements = response.data.data.totalElements;
              this.pageNumber = response.data.data.number;
              this.isFirst = response.data.data.first;
              this.isLast = response.data.data.isLast;

              if (response.data.data.content.length > 0) {

                this.noDataFound = false;
                this.sortingData(response.data.data.content);

              }else {

                this.noDataFound = true;

              }

            }else {
              this.notifyUser('error','Error','System Error please Contact System Administrator');
            }



          }).catch((error)=>{
            this.loading=false;
          })
        },

        goToApplication(object) {

        },

        onChange(event) {
          this.pageSize=event;
          this.pageNo=0;
        },

        goToPage(page) {

          if(page > 0) {page = page - 1;}
          this.isPage = page + 1;
          this.pageNo=page;

          this.loadAllreports();

        },

        showToolTip(id) {
          this.isHovered = [id]
        },

        hideToolTip(id) {
          this.isHovered.splice(this.isHovered.indexOf(id), 1)
        }
      }
    }
</script>

<style scoped>
  .back-color-vat {
    background-color: rgba(216, 225, 232, .3);
  }

  .custom-btn, .custom-btn-all {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .custom-btn:hover, .custom-btn-all:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
  }
</style>
