<template>
  <div>
    <card>
      <div class="row">
        <div
          @click="toggle"
          class="col-md-12 toggle-code-key"
        >
          <div class="row">
            <div class="col-md-6">
              Tariff keys and icons color keys
            </div>
            <div class="col-md-6 text-right">
              <font-awesome-icon
                icon="angle-down"
                v-show="!showSection"
              />
              <font-awesome-icon
                icon="angle-up"
                v-show="showSection"
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-12"
          v-show="showSection"
        >
          <hr>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 30px"
              v-show="showSection"
            >
              <b>icons keys</b>
            </div>
            <div class="col-md-12">
              <div
                class="col-md-12"
                v-show="showSection"
              >
                <div class="row">
                  <div class="col-md-4">
                    <span>
                      <font-awesome-icon
                        class="edit-fa-icon"
                        icon="edit"
                      /><i class=" text-muted"> Edit Tariff</i>
                    </span>
                  </div>
                  <div class="col-md-4">
                    <span>
                      <font-awesome-icon
                        class="view-fa-icon"
                        icon="eye"
                      /><i class=" text-muted"> View Tariff Fees</i>
                    </span>
                  </div>
                  <div class="col-md-4">
                    <span>
                      <font-awesome-icon icon="plus" /><i class=" text-muted"> Add New Tariff</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close close-custom"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close close-custom"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <br>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Tariffs
          </div>
          <div class="text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            class="all-fa-icons btn-space"
            title="add new request"
            v-if="finalUserPermissions.includes('TARIFFS_CREATE_POST')"
            @click="openModal('NEW_TARIFF')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  #
                </th>
                <th scope="col">
                  Name
                </th>
                <th scope="col">
                  Description
                </th>
                <th scope="col">
                  Status
                </th>
                <th scope="col">
                  Date Created
                </th>
                <th scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(list,index) in Tariffs"
                :key="list.id"
              >
                <th scope="row">
                  {{ index + 1 }}
                </th>
                <td>
                  {{ list.tariffName }}
                </td>
                <td>
                  {{ list.description }}
                </td>
                <td
                  class="text-success"
                  v-if="list.active"
                >
                  Active
                </td>
                <td
                  class="text-muted"
                  v-else=""
                >
                  Not Active
                </td>
                <td>{{ dateTimeToHuman(list.createdDate) }}</td>
                <td class="special-limit">
                  <div
                    class=" all-fa-icons btn-space edit-fa-icon"
                    v-if="finalUserPermissions.includes('TARIFFS_UPDATE_PUT')"
                    title="Edit"
                  >
                    <font-awesome-icon
                      icon="edit"
                      @click="openModal('EDIT_TARIFF',list)"
                    />
                  </div>

                  <div
                    class=" all-fa-icons btn-space view-fa-icon"
                    v-if="finalUserPermissions.includes('TARIFF-FEES_LIST_{TARIFFID}_GET')"
                    title="view tariff fees"
                  >
                    <font-awesome-icon
                      icon="eye"
                      @click="tariffClicked(list.tariffId)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <modal v-if="showModal">
        <div
          slot="header"
          class="modal-title col-md-12"
        >
          <div class="row">
            <div
              class="col-md-12"
              v-if="tariffModal"
            >
              Add New Tariff
            </div>
            <div
              class="col-md-12"
              v-if="tariffeDITModal"
            >
              Edit Tariff [ <b>{{ tariffName }}</b> ]
            </div>
          </div>
        </div>

        <div slot="body">
          <card>
            <form v-if="tariffModal">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="tariffName">Tariff Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="tariffName"
                      placeholder="Tariff Name"
                      v-model="tarriffData.tariffName"
                    >
                    <div v-if="tariffNameError">
                      <div
                        class="error"
                        v-if="!$v.tarriffData.tariffName.required"
                      >
                        This field is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="status">Select Status</label>
                    <select
                      class="form-control"
                      id="status"
                      v-model="tarriffData.active"
                    >
                      <option />
                      <option value="true">
                        Active
                      </option>
                      <option value="false">
                        Not Active
                      </option>
                    </select>
                    <div v-if="tariffStatusError">
                      <div
                        class="error"
                        v-if="!$v.tarriffData.active.required"
                      >
                        This field is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Description</label>
                    <textarea
                      class="form-control"
                      id="description"
                      rows="3"
                      v-model="tarriffData.description"
                    />
                    <div v-if="descriptionError">
                      <div
                        class="error"
                        v-if="!$v.tarriffData.description.required"
                      >
                        This field is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br>

              <div class="text-center row">
                <div class="col-md-7 text-right loader-div">
                  <span v-if="myloader">Please wait ....</span>
                </div>
                <div class="col-md-2 left loader-div-gif">
                  <pulse-loader
                    :loading="loading"
                    :color="color"
                    :size="size"
                    v-if="myloader"
                  />
                </div>
                <div
                  class="col-md-3"
                  v-if="tariffModal"
                >
                  <button
                    class="btn btn-fill btn-outline-info float-right"
                    @click.prevent="closeModal()"
                  >
                    Close
                  </button>

                  <button
                    class="btn btn-primary btn-space float-right"
                    @click.prevent="saveTariff()"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>

            <!--  EDIT -->
            <form v-if="tariffeDITModal">
              <div class="row">
                <div class="col-md-6">
                  <base-input
                    type="text"
                    label="Tariff Name"
                    placeholder="Tariff Name"
                    v-model="tariffName"
                    value="tariffName"
                  />
                  <div v-if="tariffNameError">
                    <div
                      class="error"
                      v-if="!$v.tarriffData.tariffName.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="statusEdit">Select Status</label>
                    <select
                      class="form-control"
                      id="statusEdit"
                      v-model="active"
                    >
                      <option />
                      <option
                        value="true"
                        :selected="tarriffData.active==='true'"
                      >
                        Active
                      </option>
                      <option
                        value="false"
                        :selected="tarriffData.active==='false'"
                      >
                        Not Active
                      </option>
                    </select>
                    <div v-if="tariffStatusError">
                      <div
                        class="error"
                        v-if="!$v.tarriffData.active.required"
                      >
                        This field is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="descriptionEdit">Description</label>
                    <textarea
                      class="form-control"
                      id="descriptionEdit"
                      rows="3"
                      v-model="description"
                      value="description"
                    />
                    <div v-if="descriptionError">
                      <div
                        class="error"
                        v-if="!$v.tarriffData.description.required"
                      >
                        This field is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br>

              <div class="text-center row">
                <div class="col-md-7 text-right loader-div">
                  <span v-if="myloader">Please wait ....</span>
                </div>
                <div class="col-md-2 left loader-div-gif">
                  <pulse-loader
                    :loading="loading"
                    :color="color"
                    :size="size"
                    v-if="myloader"
                  />
                </div>
                <div
                  class="col-md-3"
                  v-if="tariffeDITModal"
                >
                  <button
                    class="btn btn-fill btn-outline-info float-right"
                    @click.prevent="closeModal()"
                  >
                    Close
                  </button>
                  <button
                    class="btn btn-primary btn-space float-right"
                    @click.prevent="editTariff()"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </form>
          </card>
        </div>
      </modal>
    </card>
  </div>
</template>

<script>
import API from '../../../api/index'
import API_REVENUE from '../../../api/rev'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { required } from 'vuelidate/lib/validators'

export default {

  name: 'Tariffs',

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.getTariffs()
  },

  components: {

    PulseLoader,
    Modal

  },

  data () {
    return {

      Tariffs: [],
      finalUserPermissions: [],
      showModal: false,
      myLoader: false,
      disableButton: false,
      tariffModal: false,
      tariffeDITModal: false,
      showSection: true,

      color: 'green',
      size: '15px',
      loading: true,

      tarriffData: {

        tariffName: '',
        description: '',
        active: ''

      },

      tariffName: '',
      description: '',
      active: '',
      tariffId: '',

      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',

      tariffNameError: false,
      descriptionError: false,
      tariffStatusError: false
    }
  },

  validations: {

    tarriffData: {

      tariffName: { required },
      description: { required },
      active: { required }

    },

    tariffName: { required },
    description: { required },
    active: { required }

  },

  methods: {

    toggle () {
      this.showSection = !this.showSection
    },

    date (date) {
      const newDate = new Date(date)

      const day = (newDate.getDay()).toString.length > 1 ? newDate.getDay() : '0' + newDate.getDay()
      const month = (newDate.getMonth()).toString.length > 1 ? newDate.getMonth() : '0' + newDate.getMonth()
      return day + '/' + month + '/' + newDate.getFullYear()
    },
    tariffClicked (id) {
      this.$router.push({ path: '/configurations/tariff/fees/' + id })
    },

    getTariffs () {
      API_REVENUE.getTariffs().then((response) => {
        if (response.data.status) {
          this.Tariffs = response.data.data
        }
      }).catch((error) => {

      })
    },

    saveTariff () {
      if (this.$v.tarriffData.tariffName.$invalid || this.$v.tarriffData.description.$invalid ||
          this.$v.tarriffData.active.$invalid) {
        if (this.$v.tarriffData.tariffName.$invalid) {
          this.tariffNameError = true
        }
        if (this.$v.tarriffData.description.$invalid) {
          this.descriptionError = true
        }
        if (this.$v.tarriffData.active.$invalid) {
          this.tariffStatusError = true
        }
      } else {
        this.myloader = true

        API_REVENUE.saveTariff(JSON.stringify(this.tarriffData)).then(
          (response) => {
            if (response.data.status) {
              this.ErrorMessage = ''
              this.SuccessAlert = true
              this.ErrorAlert = false
              this.SuccessMessage = ', Tariff has been created'
              this.myloader = false
              this.tariffNameError = false
              this.descriptionError = false
              this.tariffStatusError = false

              this.tarriffData.tariffName = ''
              this.tarriffData.description = ''
              this.tarriffData.active = ''

              this.closeModal()
            } else {

            }
          }).catch(() => {
          this.ErrorMessage = ' Record not Added'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false

          this.tariffNameError = false
          this.descriptionError = false
          this.tariffStatusError = false
        })
      }
    },

    editTariff () {
      if (this.$v.tariffName.$invalid || this.$v.description.$invalid || this.$v.active.$invalid) {
        if (this.$v.tariffName.$invalid) {
          this.tariffNameError = true
        }
        if (this.$v.description.$invalid) {
          this.descriptionError = true
        }
        if (this.$v.active.$invalid) {
          this.tariffStatusError = true
        }
      } else {
        const customerData = {

          tariffId: this.tariffId,
          tariffName: this.tariffName,
          active: this.active,
          description: this.description

        }

        this.myloader = true

        this.Tariffs.map((x) => {
          API_REVENUE.editTariff(x.tariffId !== this.tariffId ? {
            active: false,
            description: x.description,
            tariffId: x.tariffId,
            tariffName: x.tariffName
          } : customerData).then((response) => {
            if (response.data.status) {
              this.ErrorMessage = ''
              this.SuccessAlert = true
              this.ErrorAlert = false
              this.SuccessMessage = ', Tariff updated'

              this.myloader = false
              this.tariffName = ''
              this.description = ''
              this.active = ''
              this.tariffId = ''

              this.getTariffs()
              this.closeModal()

              this.tariffNameError = false
              this.descriptionError = false
              this.tariffStatusError = false
            }
          }).catch((erorr) => {
            this.myloader = false
            this.disableButton = false

            this.tariffNameError = false
            this.descriptionError = false
            this.tariffStatusError = false
          })
        })
      }
    },

    openModal (modal, data = null) {
      this.showModal = true
      this.tariffModal = false
      this.tariffeDITModal = false

      this.ErrorMessage = ''
      this.SuccessAlert = false
      this.ErrorAlert = false
      this.SuccessMessage = ''

      this.disableButton = false
      this.myloader = false

      if (modal === 'NEW_TARIFF') {
        this.tariffModal = true
      }

      if (modal === 'EDIT_TARIFF') {
        this.tariffeDITModal = true
        this.tariffName = data.tariffName
        this.description = data.description
        this.active = data.active ? 'true' : 'false'
        this.tariffId = data.tariffId
      }
    },

    closeModal () {
      this.showModal = false
      this.tariffeDITModal = false
      this.tarriffData.tariffName = ''
      this.tarriffData.description = ''
      this.tarriffData.active = ''

      this.tariffNameError = false
      this.descriptionError = false
      this.tariffStatusError = false
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    }
  }
}
</script>

<style scoped>

  .clickable {
    cursor: pointer;
  }

  .btn-space {
    margin-right: 10px !important;
  }

</style>
