<template>
  <card>
    <div class="row">
      <div class="col-md-6">
        <div class="text-left">
          Tariff Rates
        </div>
        <div class="text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="myloader"
          />
        </div>
      </div>
      <div class="col-md-6 text-right">
        <span
          class="all-fa-icons btn-space"
          title="add new request"
          v-if="finalUserPermissions.includes('TARIFF_ADD')"
          @click="openModal('NEW')"
        >
          <font-awesome-icon icon="plus" />
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <select
          class="custom-select"
          @change="onChange($event,`SELECTED-TARIFF`)"
        >
          <option />
          <option
            v-for="list in tariffRules"
            :value="list.tariffId"
            :key="list.tariffId"
          />
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th scope="col">
                Minimum Value
              </th>
              <th scope="col">
                Rate Currency
              </th>
              <th scope="col">
                Rate Per
              </th>
              <th scope="col">
                Rate Unit
              </th>
              <th scope="col">
                Rate Value
              </th>
              <th scope="col">
                Rate Value
              </th>
              <th scope="col">
                Taxable
              </th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(list,index) in tariffRules"
              :key="index + 1"
            >
              <th scope="row">
                {{ index + 1 }}
              </th>
              <td>{{ list.minimumValue }}</td>
              <td>{{ list.rateCurrency }}</td>
              <td>{{ list.ratePer }}</td>
              <td>{{ list.rateUnit }}</td>
              <td>{{ list.rateValue }}</td>
              <td>{{ list.tariffRuleId }}</td>
              <td>{{ list.vatTaxable ? "Taxable":"Not Taxable" }}</td>
              <td class="text-center mx-auto">
                {{ list.isActive? "Active":"Not Active" }}
              </td>
              <td class="special-limit">
                <div
                  class=" all-fa-icons edit-fa-icon"
                  v-if="finalUserPermissions.includes('TARIFF_ADD')"
                  title="Edit"
                >
                  <font-awesome-icon
                    icon="edit"
                    @click="openModal('EDIT_RATE',list)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          Add New Tariff Fee
        </div>
        <div slot="body">
          <card>
            <div
              class="col-md-12 alert-dismissible fade show"
              role="alert"
              :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
              style="border-radius:5px;"
            >
              <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
              <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                v-if="SuccessAlert"
                style="line-height:2px;"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                v-if="ErrorAlert"
                style="line-height:2px;"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <small
                    v-if="!istariffRuleId"
                    :class="[istariffRuleId ? '':'text-danger']"
                  >This field is required</small><br>
                  <select
                    class="custom-select"
                    @change="onChange($event,`SELECTED-TARIFF`)"
                    :class="[istariffRuleId ? '':'is-invalid']"
                  >
                    <option />
                    <option
                      v-for="(list,index) in tariffRules" :value="list.tariffId" :key="index">
                      hh
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <small
                    v-if="!isfixed"
                    :class="[isfixed ? '':'text-danger']"
                  >This field is required</small><br>
                  <label for="fixed">Fixed</label>
                  <select
                    class="form-control"
                    id="fixed"
                    @change="onChange($event,`FIXED`)"
                    :class="[isfixed ? '':'is-invalid']"
                  >
                    <option />
                    <option
                      value="true"
                      :selected="tariffRateData.fixed===true"
                    >
                      Fixed
                    </option>
                    <option
                      value="false"
                      :selected="tariffRateData.fixed===false"
                    >
                      Not Fixed
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <small
                    v-if="!isminimumValue"
                    :class="[isminimumValue ? '':'text-danger']"
                  >This field is required</small><br>
                  <label for="mvalue">Minimum Value</label>
                  <input
                    type="number"
                    class="form-control"
                    id="mvalue"
                    placeholder="Ninimum Value"
                    :class="[isminimumValue ? '':'is-invalid']"
                    v-model="tariffRateData.minimumValue"
                  >
                </div>
                <div class="form-group">
                  <small
                    v-if="!israteCurrency"
                    :class="[israteCurrency ? '':'text-danger']"
                  >This field is required</small><br>
                  <label for="cy">Rate Currency</label>
                  <input
                    type="number"
                    class="form-control"
                    id="cy"
                    placeholder="Rate Currency"
                    :class="[israteCurrency ? '':'is-invalid']"
                    v-model="tariffRateData.rateCurrency"
                  >
                </div>
                <div class="form-group">
                  <small
                    v-if="!isratePer"
                    :class="[isratePer ? '':'text-danger']"
                  >This field is required</small><br>
                  <label for="rate">Rate Per</label>
                  <input
                    type="number"
                    class="form-control"
                    id="rate"
                    placeholder="Rate Per"
                    :class="[isratePer ? '':'is-invalid']"
                    v-model="tariffRateData.ratePer"
                  >
                </div>
                <div class="form-group">
                  <small
                    v-if="!israteUnit"
                    :class="[israteUnit ? '':'text-danger']"
                  >This field is required</small><br>
                  <label for="unit">Rate Unit</label>
                  <input
                    type="number"
                    class="form-control"
                    id="unit"
                    placeholder="Rate Unit"
                    :class="[israteUnit ? '':'is-invalid']"
                    v-model="tariffRateData.rateUnit"
                  >
                </div>
                <div class="form-group">
                  <small
                    v-if="!israteValue"
                    :class="[israteValue ? '':'text-danger']"
                  >This field is required</small><br>
                  <label for="value">Rate Value</label>
                  <input
                    type="number"
                    class="form-control"
                    id="value"
                    placeholder="Rate Value"
                    :class="[israteValue ? '':'is-invalid']"
                    v-model="tariffRateData.rateValue"
                  >
                </div>
                <div class="form-group">
                  <small
                    v-if="!isvatTaxable"
                    :class="[isvatTaxable ? '':'text-danger']"
                  >This field is required</small><br>
                  <label for="vat">Vatable</label>
                  <select
                    class="form-control"
                    id="vat"
                    @change="onChange($event,`VAT`)"
                    :class="[isvatTaxable ? '':'is-invalid']"
                  >
                    <option />
                    <option
                      value="true"
                      :selected="tariffRateData.vatTaxable===true"
                    >
                      Vatable
                    </option>
                    <option
                      value="false"
                      :selected="tariffRateData.vatTaxable===false"
                    >
                      Not Vatable
                    </option>
                  </select>
                </div>
                <div
                  class="col-md-12"
                  v-if="newRate"
                >
                  <button
                    class="btn btn-outline-info float-right"
                    @click="saveRate()"
                    :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                  >
                    save
                  </button>
                  <button
                    class="btn btn-default btn-fill float-right"
                    @click="closeModal()"
                    :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                  >
                    Back
                  </button>
                  <div class="text-center">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="myloader"
                    />
                  </div>
                </div>
                <div
                  class="col-md-12"
                  v-if="editRate"
                >
                  <button
                    class="btn btn-outline-info float-right"
                    @click="saveEditRate()"
                    :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                  >
                    Edit
                  </button>
                  <button
                    class="btn btn-default btn-fill float-right"
                    @click="closeModal()"
                    :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                  >
                    Back
                  </button>
                  <div class="text-center">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="myloader"
                    />
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </modal>
  </card>
</template>

<script>
import API from '../../../api'
import API_REVENUE from '../../../api/rev'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'

export default {
  name: 'TasacRate',

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadRules()
  },

  components: {
    PulseLoader,
    Modal
  },

  data () {
    return {
      color: 'green',
      size: '15px',
      loading: true,
      selectedRule: '',
      myLoader: false,
      showModal: false,

      tariffRules: [],
      rates: [],
      finalUserPermissions: [],

      tariffRateData: {
        fixed: '',
        minimumValue: '',
        rateCurrency: '',
        ratePer: '',
        rateUnit: '',
        rateValue: '',
        tariffRuleId: $route.params.id,
        vatTaxable: ''
      },

      isfixed: true,
      isminimumValue: true,
      israteCurrency: true,
      isratePer: true,
      israteUnit: true,
      israteValue: true,
      istariffRuleId: true,
      isvatTaxable: true,

      newRate: false,
      editRate: false,

      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',

      disableButton: false
    }
  },
  methods: {

    loadRules () {
      API.getTariffRule(this.tariffRateData.tariffRuleId).then((response) => {
        this.tariffRules = response.data.data
      })
    },

    loadRates () {
      API_REVENUE.editTariffRates(this.selectedRule).then((response) => {
        this.rates = response.data.data
      })
    },

    saveRate () {
      if (
        this.tariffRateData.fixed !== '' &&
          this.tariffRateData.minimumValue !== '' &&
          this.tariffRateData.rateCurrency !== '' &&
          this.tariffRateData.ratePer !== '' &&
          this.tariffRateData.rateUnit !== '' &&
          this.tariffRateData.rateValue !== '' &&
          this.tariffRateData.vatTaxable !== '' &&
          this.tariffRateData.tariffRuleId !== ''
      ) {
        this.myloader = true
        this.disableButton = true

        API_REVENUE.saveTariffRate(this.tariffRateData).then((response) => {
          this.ErrorMessage = ''
          this.SuccessAlert = true
          this.ErrorAlert = false
          this.SuccessMessage = 'Record Added'
          this.SuccessAlert = true
          this.myloader = false
          this.disableButton = false

          this.tariffRateData.fixed = ''
          this.tariffRateData.minimumValue = ''
          this.tariffRateData.rateCurrency = ''
          this.tariffRateData.ratePer = ''
          this.tariffRateData.rateUnit = ''
          this.tariffRateData.rateValue = ''
          this.tariffRateData.vatTaxable = ''
        }).catch((error) => {
          this.ErrorMessage = ' Record not Added'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false
          this.disableButton = false
        })
      } else {
        this.tariffRateData.fixed === '' ? this.isfixed = false : this.isfixed = true
        this.tariffRateData.minimumValue === '' ? this.isminimumValue = false : this.isminimumValue = true
        this.tariffRateData.rateCurrency === '' ? this.israteCurrency = false : this.israteCurrency = true
        this.tariffRateData.ratePer === '' ? this.isratePer = false : this.isratePer = true
        this.tariffRateData.rateUnit === '' ? this.israteUnit = false : this.israteUnit = true
        this.tariffRateData.rateValue === '' ? this.israteValue = false : this.israteValue = true
        this.tariffRateData.rateValue === '' ? this.israteValue = false : this.israteValue = true
        this.tariffRateData.vatTaxable === '' ? this.isvatTaxable = false : this.isvatTaxable = true
        this.tariffRateData.tariffRuleId === '' ? this.istariffRuleId = false : this.istariffRuleId = true
      }
    },

    saveEditRate () {
      if (
        this.tariffRateData.fixed !== '' &&
          this.tariffRateData.minimumValue !== '' &&
          this.tariffRateData.rateCurrency !== '' &&
          this.tariffRateData.ratePer !== '' &&
          this.tariffRateData.rateUnit !== '' &&
          this.tariffRateData.rateValue !== '' &&
          this.tariffRateData.vatTaxable !== ''
      ) {

      } else {
        this.tariffRateData.fixed === '' ? this.isfixed = false : this.isfixed = true
        this.tariffRateData.minimumValue === '' ? this.isminimumValue = false : this.minimumValue = true
        this.tariffRateData.rateCurrency === '' ? this.israteCurrency = false : this.israteCurrency = true
        this.tariffRateData.ratePer === '' ? this.isratePer = false : this.isratePer = true
        this.tariffRateData.rateUnit === '' ? this.israteUnit = false : this.israteUnit = true
        this.tariffRateData.rateValue === '' ? this.israteValue = false : this.israteValue = true
        this.tariffRateData.rateValue === '' ? this.israteValue = false : this.israteValue = true
        this.tariffRateData.vatTaxable === '' ? this.isvatTaxable = false : this.isvatTaxable = true
        this.tariffRateData.tariffRuleId === '' ? this.istariffRuleId = false : this.istariffRuleId = true
      }
    },

    onChange (event, data = null) {
      if (data === 'SELECTED_TARIFF') {
        this.loadRules()
      }

      if (data === 'FIXED') {
        this.tariffRateData.fixed = event.target.value
      }

      if (data === 'VAT') {
        this.tariffRateData.vatTaxable = event.target.value
      }

      if (data === 'SELECTED-NEW-TARIFF') {
        this.tariffRateData.tariffRuleId = event.target.value
      }
    },

    openModal (data, list = null) {
      this.showModal = true
      this.editRate = false
      this.newRate = false

      this.tariffRateData.fixed = ''
      this.tariffRateData.minimumValue = ''
      this.tariffRateData.rateCurrency = ''
      this.tariffRateData.ratePer = ''
      this.tariffRateData.rateUnit = ''
      this.tariffRateData.rateValue = ''
      this.tariffRateData.vatTaxable = ''

      this.isminimumValue = true
      this.israteCurrency = true
      this.isratePer = true
      this.israteUnit = true
      this.israteUnit = true
      this.israteValue = true
      this.isvatTaxable = true
      this.isfixed = true

      if (data === 'NEW') {
        this.newRate = true
      }

      if (data === 'EDIT_RATE') {
        this.editRate = true
        this.tariffRateData.fixed = list.fixed
        this.tariffRateData.minimumValue = list.minimumValue
        this.tariffRateData.rateCurrency = list.rateCurrency
        this.tariffRateData.vatTaxable = list.vatTaxable
        this.tariffRateData.ratePer = list.ratePer
        this.tariffRateData.rateUnit = list.rateUnit
      }
    },
    closeModal () {
      this.showModal = false
      this.newRate = false
      this.editRate = false
    }

  }
}
</script>

<style scoped>
  .clickable{
    cursor: pointer;
  }
</style>
