<template>
  <div>
    <card>
      <modal-edit-plan v-if="showAddedDisbursementEstimate">
        <div slot="header" class="modal-title">
          FINAL-SUB DISBURSEMENT INVOICE

        </div>
        <div slot="body">
          <card v-if="!isApproved">
            <div class="row">
              <div class="col-md-12">
                <ul
                  class="nav-justified"
                  style="padding-left: 10px;padding-right: 10px">
                  <li v-if="subDisbursmentStatus.toUpperCase() === 'APPROVED'">
                    <button
                      @click="prepareAddData"
                      class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
                      Add New Item
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </card>
          <card style="font-size: 12px">
            <div class="row">
              <div class="col-md-12">
                <div class="legend">
                  <h6 class="legend-title">
                  </h6>
                  <div class="row div-table-row">
                    <div class="col-md-2 div-table div-table-title">
                      Approved Service Name
                    </div>
                    <div class="col-md-10 div-table div-table-value">
                      {{ serviceCategoryDetails.description }}
                    </div>
                  </div>
                  <br/>
                  <div class="row div-table-row">
                    <div class="col-md-2 div-table div-table-title">
                      Amount ( {{ serviceCategoryDetails.currency }} )
                    </div>
                    <div class="col-md-2 div-table div-table-value">
                      {{ putComma(serviceCategoryDetails.amount) }}
                    </div>
                    <div class="col-md-2 div-table div-table-title">
                      VAT ( {{ serviceCategoryDetails.currency }} )
                    </div>
                    <div class="col-md-2 div-table div-table-value">
                      {{ putComma(serviceCategoryDetails.vat) }}
                    </div>
                    <div class="col-md-2 div-table div-table-title">
                      Vatted Amount ( {{ serviceCategoryDetails.currency }} )
                    </div>
                    <div class="col-md-2 div-table div-table-value">
                      {{ putComma(serviceCategoryDetails.total) }}
                    </div>
                  </div>
                  <div class="col-md-10 mt-2">
                    <pulse-loader :loading="loadDisbursementData" :color="color" :size="size"/>
                  </div>
                  <div class="legend">
                    <h6 class="legend-title">
                      Paid Service Category Items
                    </h6>

                    <data-table
                      v-if="serviceCategoryDetails.disbursementInvoiceItems !== undefined"
                      :tableHeaders="tableHeaders"
                      :tableData="addedItems"
                      :tableDataKeys="tableDataKeys"
                      :myFirstLoader="myFirstLoader"
                      :noDataFound="noDataFound"
                      :has-filter="false"
                      :has-pagination="false"
                      :is-pageable="false"
                      :has-manage-button="false"
                      :has-delete-icon-button=!isApproved
                      :has-edit-icon-button="subDisbursmentStatus.toUpperCase() !== 'APPROVED'"
                      :permissions="[ {key: 'searchPermission', value: 'DISBURSEMENT_INVOICE_ADD_POST'} ]"
                      :categoryItemIds="categoryItemIds"
                      @addItemToPaymentRequest="addItemToPaymentRequestList"
                      @removeItemFromList="deleteItem"
                      @editItemFromList="prepareEditData">
                    </data-table>
                  </div>
                </div>
              </div>
            </div>
            <card v-if="isEditable" id="edit-form">
              <div class="row">
                <div class="col-md-12 text-center">
                  EDIT SELECTED ITEM
                </div>
                <div class="col-md-12">
                  <hr/>
                </div>
                <div class="col-md-12">
                  <base-input
                    v-model="description"
                    class="special-bottom"
                    label="Description"
                    placeholder="Description"
                    readonly
                  />
                  <div v-if="!$v.description.required && descriptionError" class="text-danger"> This field is required
                  </div>

                </div>
                <div class="col-md-4">
                  <base-input
                    v-model="itemsFactor"
                    class="special-bottom"
                    label="Factor/MTS/Amount"
                    placeholder="Factor/MTS/Amount"
                    type="number"
                  />
                  <div v-if="!$v.itemsFactor.required && itemsFactorError" class="text-danger"> This field is required
                  </div>

                </div>
                <div class="col-md-4">
                  <base-input
                    v-model="itemsQuantity"
                    class="special-bottom"
                    label="Quantity"
                    placeholder="Quantity"
                    type="number"
                  />
                  <div v-if="!$v.itemsQuantity.required && itemsQuantityError" class="text-danger"> This field is
                    required
                  </div>
                </div>
                <div class="col-md-4">
                  <base-input
                    v-model="itemsRate"
                    class="special-bottom"
                    label="Rate"
                    placeholder="Rate"
                    step="0.001"
                    type="number"
                  />
                  <div v-if="!$v.itemsRate.required && itemsRateError" class="text-danger"> This field is required</div>
                </div>
                <div class="col-md-4">
                  <base-input
                    v-model="amount"
                    class="special-bottom"
                    label="Amount"
                    placeholder="Amount"
                  />
                  <div v-if="!$v.amount.required && amountError" class="text-danger"> This field is required</div>

                </div>
                <div class="col-md-4">
                  <base-input
                    v-model="vat"
                    class="special-bottom"
                    label="VAT"
                    placeholder="VAT"
                  />
                  <div v-if="!$v.vat.required && vatError" class="text-danger"> This field is required</div>
                </div>
                <div class="col-md-4">
                  <base-input
                    v-model="total"
                    class="special-bottom"
                    label="Total"
                    placeholder="Total"
                    step="0.001"
                  />
                  <div v-if="!$v.total.required && totalError" class="text-danger"> This field is required</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <hr/>
                    </div>
                    <div class="col-md-10 text-right pt-3">
                      <pulse-loader :loading="myLoader" :color="color" :size="size"/>
                    </div>
                    <div class="col-md-2 pr-3">
                      <button
                        type="button"
                        class="btn btn-fill btn-outline-info custom-btn-all float-right"
                        :disabled="myLoader"
                        @click.prevent="closeForm"
                      >
                        Close form
                      </button>
                      <button
                        v-if="finalUserPermissions.includes('DISBURSEMENT_ITEM_UPDATE_PUT')"
                        type="button"
                        class="btn btn-fill btn-primary custom-btn float-right mr-2"
                        :disabled="myLoader"
                        @click.prevent="editItem"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </card>
            <card v-if="isAddiable && !hasPaymentSent" id="add-form">
              <div class="row">
                <div class="col-md-12">
                  <label v-if="disbursementServiceCategoryItemLoader">
                    <pulse-loader :color="color" :size="size" class="float-left mr-4 pt-1"/>
                    Loading disbursement service category items, please wait</label>
                  <label v-if="!disbursementServiceCategoryItemLoader">Select Disbursement Service Category Items </label>
                  <list-select
                    :list="disbursementServiceItems"
                    class="form-control"
                    :isDisabled="disbursementServiceCategoryItemLoader"
                    :selected-item="disbursementServiceItem"
                    option-text="name"
                    option-value="serviceItemId"
                    placeholder="Disbursement Service Category Items"
                    @select="populateDisbursmentServiceItem"
                  />
                  <div v-if="!$v.disbursementServiceItem.required && disbursementCategoryError" class="text-danger"> This
                    field is required
                  </div>
                </div>
                <div class="col-md-4">
                  <base-input
                    v-model="itemsFactor"
                    class="special-bottom"
                    label="Factor/MTS/Amount"
                    placeholder="Factor/MTS/Amount"
                    type="number"
                    :disabled="Object.keys(disbursementServiceItem).length === 0"
                  />
                  <div v-if="!$v.itemsFactor.required && itemsFactorError" class="text-danger"> This field is required
                  </div>
                </div>
                <div class="col-md-4">
                  <base-input
                    v-model="itemsQuantity"
                    class="special-bottom"
                    label="Quantity n"
                    placeholder="Quantity"
                    type="number"
                    min="1"
                    @input="checkValue"
                    :disabled="Object.keys(disbursementServiceItem).length === 0"
                  />
                  <div v-if="!$v.itemsQuantity.required && itemsQuantityError" class="text-danger"> This field is
                    required
                  </div>
                </div>
                <div class="col-md-4">
                  <base-input
                    v-model="itemsRate"
                    class="special-bottom"
                    label="Rate"
                    placeholder="Rate"
                    step="0.001"
                    type="number"
                    :disabled="Object.keys(disbursementServiceItem).length === 0"
                    readonly
                  />
                  <div v-if="!$v.itemsRate.required && itemsRateError" class="text-danger"> This field is required</div>

                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <hr/>
                    </div>
                    <div class="col-md-10 text-right pt-3">
                      <pulse-loader :loading="savingSubDisbursmentAccountEstimateLoader" :color="color" :size="size"/>
                    </div>
                    <div class="col-md-2 pr-3">
                      <button
                        type="button"
                        class="btn btn-fill btn-outline-info custom-btn-all float-right mr-2"
                        @click.prevent="closeForm"
                      >
                        Clear form
                      </button>
                      <button
                        v-if="finalUserPermissions.includes('DISBURSEMENT_INVOICE_ADD_POST')"
                        @click.prevent="addItem"
                        class="btn btn-primary btn-space custom-btn float-right mr-2"
                        data-dismiss="modal">
                        Save Item
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </card>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <hr/>
                  </div>
                  <div class="col-md-8 text-right pt-3">
                  </div>
                  <div class="col-md-4 pr-3">
                    <button type="button" class="btn btn-fill btn-outline-info custom-btn-all float-right" @click.prevent="closeModal" :disabled="savingServiceInvoicePaymentRequestLoader || savingSubDisbursmentAccountEstimateLoader">Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </card>
          <br>
        </div>
      </modal-edit-plan>
    </card>
  </div>
</template>

<script>

import ModalEditPlan from '../../../../components/Inputs/ModalEditPlan.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import {mapActions, mapGetters} from 'vuex'
import {required} from 'vuelidate/lib/validators'
import {ListSelect} from 'vue-search-select'
import AGENCY_API from '../../../../api/agency'
import DisbursementPaymentAttachments from "../disbursementPaymentAttachments";
// import { Money } from 'v-money'

export default {

  name: 'addedFinalInvoiceAndItemList',

  components: {
    DisbursementPaymentAttachments,

    PulseLoader,
    ModalEditPlan,
    ListSelect,
    // Money,
    DataTable: () => import('../../../../components/DataTable/dataTable')

  },

  props: {

    isApproved:{
      type:Boolean,
      default:false
    },

    showAddedDisbursementEstimate: {
      type: Boolean,
      default: () => false
    },

    serviceCategoryDetails: {
      type: Object,
      default: () => {
      }
    },

    subDisbursmentStatus: {
      type: String,
      default: () => ''
    },

    subDisbursmentInvoiceNumber: {
      type: String,
      default: () => ''
    }

  },

  computed: {

    ...mapGetters('subdisbursment', [

      'getRequestId',
      'getMyLoader'

    ]),

    ...mapGetters('global', [

      'getAllDisbursementServiceCategories',
      'getAllDisbursementServiceCategoryItems',
      'getDisbursementServiceCategoryItemLoader',
      'getAllShippingAgencyServiceProviders',
      'getSAServiceProviderLoader'

    ]),

  },

  created() {

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.getDisbursementInvoice()

  },

  validations: {
    amount: {required},
    vat: {required},
    total: {required},
    itemsRate: {required},
    itemsFactor: {required},
    itemsQuantity: {required},
    disbursementServiceItem: {required},
    accountNo: {required},
    address: {required},
    description: {required},
    gepgControlNo: {required},
    // paymentAmount: { required },
    paymentDeadline: {required},
    paymentReceiver: {required},
    tinNo: {required},
    paymentType: {required}

  },

  data() {

    return {
      color: 'green',
      size: '11px',
      loading: false,
      loadDisbursementData:false,
      finalUserPermissions: [],
      description: '',
      itemsFactor: '',
      itemsQuantity: '',
      itemsRate: '',
      amount: '',
      vat: '',
      total: '',
      descriptionError: false,
      itemsFactorError: false,
      itemsQuantityError: false,
      itemsRateError: false,
      amountError: false,
      vatError: false,
      totalError: false,
      isEditable: false,
      editObject: {},
      editObjectCopy: {},

      paymentType: '',
      paymentTypeToSend: '',
      accountNo: '',
      address: '',
      gepgControlNo: '',
      invoiceNo: '',
      paymentAmount: '',
      paymentAmountMoney: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false
      },
      paymentDeadline: '',
      paymentReceiver: '',
      paymentReceiverLoader: false,
      paymentReceiverObject: {},
      allShippingAgencyServiceProviders: [],
      serviceType: '',
      status: '',
      tinNo: '',
      tag: '',
      id: {},

      accountNoError: false,
      gepgControlNoError: false,
      serviceTypeError: false,
      paymentAmountError: false,
      paymentReceiverError: false,
      tinNoError: false,
      paymentDeadlineError: false,
      addressError: false,
      savingServiceInvoicePaymentRequestLoader: false,

      disbursementServiceItems: [],
      disbursementServiceCategories: [],
      shippingAgencyServiceProviders: [],
      disbursementServiceCategoryItemLoader: false,
      disbursementServiceItem: {},
      isAddiable: false,
      disbursementCategoryError: false,
      serviceCategoryId: '',
      serviceCategoryName: '',
      savingSubDisbursmentAccountEstimateLoader: false,
      addedItems: [],
      isAnyItemHasPaymentRequest: false,

      disbursementInvoicePayment: {},
      disbursementInvoicePaymentAttachments: [],
      hasPaymentSent: false,
      categoryItems: [],
      categoryItemIds: [],

      myFirstLoader: false,
      myLoader: false,
      noDataFound: false,
      tableHeaders: ['Item Name', 'Quantity', 'Factor', 'Rate', 'Amount', 'VAT', 'Total'],
      tableDataKeys: ['description', 'quantity', 'factor', 'rate', 'amount', 'vat', 'total']

    }

  },

  methods: {


    checkValue () {

      if(this.itemsQuantity <= 0) {

        this.itemsQuantity = ''

      }

    },

    ...mapActions('subdisbursment', [

      'deleteDisbursementInvoiceItem',
      'deleteFinalDisbursementInvoiceItem',
      'deleteDisbursementInvoice',
      'updateDisbursementInvoiceItem',
      'saveSubDisbursmentAccountEstimation',
      'saveSubDisbursmentFinalAccountEstimation',
      'deleteDisbursementInvoice',
      'addDisbursementInvoicePayment'

    ]),

    ...mapActions('global', [

      'loadDisbursementServiceCategories',
      'loadDisbursementServiceCategoryItems',
      'loadShippingAgencyServiceProvider'

    ]),

    sortingData() {
      this.addedItems=[];
      let data = this.serviceCategoryDetails.disbursementInvoiceItems;
      console.log('rrrrr',data);
      if(data){

        for (let x = 0; x < data.length; x++) {

          const requestObject = {

            ...data[x],
            amount: this.putComma(data[x].amount,2),
            total:this.putComma(data[x].total,2)

          };

          this.addedItems.push(requestObject)

        }

      }


    },

    getDisbursementInvoice(){
      this.loadDisbursementData=true;
      AGENCY_API.getDisbursementInvoiceByInvoiceId(this.serviceCategoryDetails.disbursementInvoiceId).then(resp => {
        if (resp.data.status) {
          this.serviceCategoryDetails = resp.data.data;
          this.sortingData();
        }
        this.loadDisbursementData=false;
      })

    },

    loadAllShippingAgencyServiceProvider() {
      this.loadShippingAgencyServiceProvider({}).then(() => {
        this.allShippingAgencyServiceProviders = this.getAllShippingAgencyServiceProviders;
        this.paymentReceiverLoader = this.getSAServiceProviderLoader
      }).catch(() => {

      });
      this.paymentReceiverLoader = this.getSAServiceProviderLoader
    },

    deleteItem(object) {
      let howManyItemsMessage = '';
      if (this.serviceCategoryDetails.disbursementInvoiceItems.length > 1) {
        howManyItemsMessage = 'You are about to delete the item [ ' + object.description + ' ], are you sure?'
      } else {
        howManyItemsMessage = 'This service has only one item which if deleted will delete associated service category ' +
          '[ ' + this.serviceCategoryDetails.description + ' ], are you sure?'
      }

      this.$dialog.confirm(howManyItemsMessage, {

        loader: true,
        html: true,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        okText: 'Yes, delete',
        cancelText: 'No, close',

      }).then((dialog) => {

        if (this.serviceCategoryDetails.disbursementInvoiceItems.length > 1) {
          this.loadDisbursementData=true;
          this.$store.dispatch('subdisbursment/requestid', object.disbursementInvoiceItemId).then(() => {
            this.deleteFinalDisbursementInvoiceItem({}).then((response) => {
              if (response.data.status) {
                this.serviceCategoryDetails.disbursementInvoiceItems.splice(this.serviceCategoryDetails.disbursementInvoiceItems.indexOf(object), 1);
                this.loadNotification('success', 4000, 'Sub Disbursment Account Estimation', ' item [ ' + object.description + ' ] has been successfully deleted',
                  'response', 1000, true, true);
                dialog.close();
                this.loadDisbursementData=false;
                this.getDisbursementInvoice();
              } else {
                this.loadNotification('error', 4000, 'Sub Disbursment Account Estimation', ' Item [ ' + object.description + ' ] not deleted',
                  'response', 1000, true, true);
                dialog.close();

              }

            }).catch(() => {
              dialog.close();
            });

            this.myFirstLoader = this.getMyFirstLoader;

          })

        } else {

          this.$store.dispatch('subdisbursment/requestid', this.serviceCategoryDetails.disbursementInvoiceId).then(() => {

            this.deleteDisbursementInvoice({}).then((response) => {
              if (response.data.status) {
                this.loadNotification('success', 4000, 'Sub Disbursment Account Estimation', ' Service Category [ ' + this.serviceCategoryDetails.description + ' ] has been successfully deleted',
                  'response', 1000, true, true);
                this.loadSubDisbursementDetails();
                this.closeModal();
                dialog.close();


              } else {

                this.loadNotification('error', 4000, 'Sub Disbursment Account Estimation', ' Service Category [ ' + this.serviceCategoryDetails.description + ' ] not deleted',
                  'response', 1000, true, true);
                dialog.close();

              }

            }).catch(() => {
              dialog.close();
            });

            this.myFirstLoader = this.getMyFirstLoader;

          })

        }

      }).catch(() => {
      });

    },

    showEditForm() {

      return new Promise((resolve) => {

        this.isEditable = true;

        resolve()
      })

    },

    prepareEditData(object) {

      this.showEditForm().then(() => {

        let element = document.getElementById("edit-form");
        element.scrollIntoView();

        this.description = object.description;
        this.itemsFactor = object.factor;
        this.itemsQuantity = object.quantity;
        this.itemsRate = object.rate;
        this.amount = object.amount;
        this.vat = object.vat;
        this.total = object.total;

        this.editObject = {

          disbursementInvoiceId: object.disbursementInvoiceId,
          disbursementInvoiceItemId: object.disbursementInvoiceItemId,
          description: object.description,
          isCancelled: object.isCancelled

        };

        this.editObjectCopy = object

      })

    },

    editItem() {

      let editObject = {

        ...this.editObject,
        amount: this.amount,
        factor: this.itemsFactor,
        quantity: this.itemsQuantity,
        rate: this.itemsRate,
        total: this.total,
        vat: this.vat

      };

      this.$store.dispatch('subdisbursment/savingDetails', editObject).then(() => {

        this.updateDisbursementInvoiceItem({}).then((response) => {
          this.myLoader = this.getMyLoader;
          if (response.data.status) {
            this.serviceCategoryDetails.disbursementInvoiceItems.splice(this.serviceCategoryDetails.disbursementInvoiceItems.indexOf(this.editObjectCopy), 1);
            this.serviceCategoryDetails.disbursementInvoiceItems.push(editObject);
            this.loadNotification('success', 4000, 'Sub Disbursment Account Estimation', ' Item [ ' + editObject.description + ' ] has been successfully edited',
              'response', 1000, true, true);
            this.isEditable = false;
            this.editObject = {};
            editObject = {}
          } else {
            this.loadNotification('error', 4000, 'Sub Disbursment Account Estimation', ' Item [ ' + editObject.description + ' ] not edited',
              'response', 1000, true, true);
            this.editObject = {};
            editObject = {}
          }

        }).catch(() => {

        });

        this.myLoader = this.getMyLoader;
      })

    },

    showAddForm() {
      return new Promise((resolve) => {
        this.isAddiable = true;
        resolve()
      })

    },

    populateDisbursmentServiceItem(object) {
      this.disbursementServiceItem = object;
      this.itemsRate = object.rate;
    },

    prepareAddData() {

      this.showAddForm().then(() => {

        let element = document.getElementById("add-form");
        element.scrollIntoView();

        this.loadDisbursementServiceCategories({}).then(() => {

          this.disbursementServiceCategories = this.getAllDisbursementServiceCategories;
          this.disbursementServiceCategoryItemLoader = this.getDisbursementServiceCategoryItemLoader;

          for (let x = 0; x < this.disbursementServiceCategories.length; x++) {

            if (this.disbursementServiceCategories[x].name === this.serviceCategoryDetails.description) {

              this.serviceCategoryId = this.disbursementServiceCategories[x].serviceCategoryId;
              this.serviceCategoryName = this.disbursementServiceCategories[x].name;
              break

            }

          }

          this.$store.dispatch("global/disbursmentServiceCategoryIding", this.serviceCategoryId).then(() => {

            this.loadDisbursementServiceCategoryItems({}).then(() => {

              this.disbursementServiceItems = this.getAllDisbursementServiceCategoryItems;
              this.disbursementServiceCategoryItemLoader = this.getDisbursementServiceCategoryItemLoader

            }).catch(() => {
            });

            this.disbursementServiceCategoryItemLoader = this.getDisbursementServiceCategoryItemLoader
          }).catch(() => {
          });

        })

      });
      this.disbursementServiceCategoryItemLoader = this.getDisbursementServiceCategoryItemLoader

    },

    addItem() {

      if (this.$v.disbursementServiceItem.$invalid || this.$v.itemsFactor.$invalid || this.$v.itemsQuantity.$invalid) {
        if (this.$v.disbursementServiceItem.$invalid) {
          this.disbursementCategoryError = true

        }

        if (this.$v.itemsFactor.$invalid) {
          this.itemsFactorError = true

        }

        if (this.$v.itemsQuantity.$invalid) {
          this.itemsQuantityError = true

        }
      } else {

        let flag = 1;

        for (let x = 0; x < this.addedItems.length; x++) {

          if (this.addedItems[x].description === this.disbursementServiceItem.name) {

            this.loadNotification('error', 4000, 'Disbursment Estimation', ' Item [ ' + this.disbursementServiceItem.name +
              ' ] for service category [ ' + this.serviceCategoryName + ' ] already exists in the invoice',
              'response', 1000, true, true);
            this.disbursementServiceItem = {};
            flag = 0;
            this.itemsQuantity = '';
            this.itemsFactor = '';
            this.itemsRate = '';
            this.disbursementCategoryError = false;
            this.itemsFactorError = false;
            this.itemsQuantityError = false;
            break;

          }

        }

        if (flag !== 0) {

          let addedServiceCategoriesAndItemsObjectForDisplay = {

            categoryName: this.serviceCategoryName,
            serviceCategoryId: this.serviceCategoryId,
            itemName: this.disbursementServiceItem.name,
            serviceItemId: this.disbursementServiceItem.serviceItemId,
            quantity: this.itemsQuantity,
            factor: this.itemsFactor,
            rate: this.itemsRate,
            vatApplicable: this.disbursementServiceItem.vatApplicable ? 'YES' : 'NO',
            vatApplicableSave: this.disbursementServiceItem.vatApplicable

          };

          let addedServiceCategoryItemsObjectForSave = {

            factor: parseInt(addedServiceCategoriesAndItemsObjectForDisplay.factor),
            itemName: addedServiceCategoriesAndItemsObjectForDisplay.itemName,
            quantity: parseInt(addedServiceCategoriesAndItemsObjectForDisplay.quantity),
            rate: addedServiceCategoriesAndItemsObjectForDisplay.rate,
            serviceItemId: addedServiceCategoriesAndItemsObjectForDisplay.serviceItemId,
            vatApplicable: addedServiceCategoriesAndItemsObjectForDisplay.vatApplicableSave

          };

          let addedServiceCategoryItemsArrayForSave = [];
          addedServiceCategoryItemsArrayForSave.push(addedServiceCategoryItemsObjectForSave);

          let addedServiceCategoriesObjectForSave = {

            categoryName: addedServiceCategoriesAndItemsObjectForDisplay.categoryName,
            serviceCategoryId: addedServiceCategoriesAndItemsObjectForDisplay.serviceCategoryId,
            disbursementInvoiceId: this.serviceCategoryDetails.disbursementInvoiceId,
            invoiceItem: addedServiceCategoryItemsArrayForSave

          };

          let saveObject = {
            object: addedServiceCategoriesObjectForSave,
            subDisbursementAccountId: this.serviceCategoryDetails.subDisbursementAccountId,
          };

          this.$store.dispatch("subdisbursment/savingDetails", saveObject).then(() => {

            this.saveSubDisbursmentFinalAccountEstimation({}).then((response) => {

              if (response.data.status) {

                this.getDisbursementInvoice();
                this.loadNotification('success', 4000, 'Sub Disbursment Account Estimation', ' Item has been successfully added',
                  'response', 1000, true, true)

              } else {

                this.loadNotification('error', 4000, 'Sub Disbursment Account Estimation', ' Item not added',
                  'response', 1000, true, true)

              }

              this.savingSubDisbursmentAccountEstimateLoader = this.getMyLoader

            }).catch(() => {

              this.savingSubDisbursmentAccountEstimateLoader = false

            });

            this.savingSubDisbursmentAccountEstimateLoader = this.getMyLoader

          }).catch(() => {

            this.savingSubDisbursmentAccountEstimateLoader = false

          });

        }

      }

    },


    addItemToPaymentRequestList (object) {

      let newObject =  { ...object, amount: Number(this.trimComma(object.amount)), total: Number(this.trimComma(object.total)) }

      this.categoryItems.push(newObject)
      this.categoryItemIds.push(newObject.disbursementInvoiceItemId)

      newObject = {}

      if(this.categoryItems.length === 1) {

        this.showPaymentRequestForm().then(() => {

          let element = document.getElementById("payment-request");
          element.scrollIntoView();
          this.loadAllShippingAgencyServiceProvider()

        })

      }

    },

    // prepareRequestInvoicePayment(object) {
    //
    //   if (typeof object.disbursementInvoiceItemId !== 'undefined') { // item
    //
    //     this.tag = 'ITEM';
    //     this.serviceType = object.description
    //
    //   } else { // service
    //
    //     this.tag = 'SERVICE';
    //     this.serviceType = this.serviceCategoryDetails.description
    //
    //   }
    //
    //   this.showPaymentRequestForm().then(() => {
    //
    //     let element = document.getElementById("payment-request");
    //     element.scrollIntoView();
    //     this.loadAllShippingAgencyServiceProvider()
    //
    //   })
    //
    // },

    populatePaymentReceiver(object) {

      this.paymentReceiverObject = object;
      this.paymentReceiver = object.id;
      this.name = object.name;
      this.address = object.address;
      this.tinNo = object.tinNo;
      this.vrn = object.vrn;

      if (object.government) {

        this.paymentType = 'Government'

      } else {

        this.paymentType = 'Non Government';
        this.accountNo = object.accountNo

      }

    },

    sendPaymentRequest() {

      if ((this.$v.accountNo.$invalid && this.paymentType === 'Non Government') || this.$v.address.$invalid || this.$v.description.$invalid ||
        (this.$v.gepgControlNo.$invalid && this.paymentType === 'Government') || this.$v.paymentDeadline.$invalid
        || this.$v.paymentReceiver.$invalid || this.$v.tinNo.$invalid) {

        if (this.$v.accountNo.$invalid && this.paymentType === 'Non Government') {

          this.accountNoError = true

        }

        if (this.$v.gepgControlNo.$invalid && this.paymentType === 'Government') {

          this.gepgControlNoError = true

        }

        if (this.$v.address.$invalid) {

          this.addressError = true

        }

        if (this.$v.description.$invalid) {

          this.descriptionError = true

        }

        // if (this.$v.paymentAmount.$invalid || this.paymentAmount === 0) {
        //     this.paymentAmountError = true
        // }

        if (this.$v.paymentDeadline.$invalid) {

          this.paymentDeadlineError = true

        }

        if (this.$v.paymentReceiver.$invalid) {

          this.paymentReceiverError = true

        }

        if (this.$v.tinNo.$invalid) {

          this.tinNoError = true

        }

      } else {

        let gepgControlNo = '';
        let accountNo = '';

        if (this.paymentType === 'Government') {

          gepgControlNo = this.gepgControlNo;
          this.accountNo = '';
          accountNo = this.accountNo

        } else {

          this.gepgControlNo = '';
          gepgControlNo = this.gepgControlNo;
          accountNo = this.accountNo

        }

        let disbursementInvoices = {};

        if (this.tag === 'SERVICE') {

          disbursementInvoices = {

            disbursementInvoiceId: this.serviceCategoryDetails.disbursementInvoiceId,
            disbursementInvoiceItems: this.serviceCategoryDetails.disbursementInvoiceItems

          }

        } else {

          disbursementInvoices = {

            disbursementInvoiceId: this.serviceCategoryDetails.disbursementInvoiceId,
            disbursementInvoiceItems: this.categoryItems

          }

        }

        let paymentRequestObject = {

          disbursementInvoice: disbursementInvoices,
          accountNo: accountNo,
          subDisbursementAccountId: this.serviceCategoryDetails.disbursementAccountId,
          description: this.description,
          gepgControlNo: gepgControlNo,
          invoiceNo: this.subDisbursmentInvoiceNumber,
          paymentDeadline: this.paymentDeadline,
          serviceProviderId: this.paymentReceiver,
          status: "Not Approved",
          // tinNo: this.tinNo
          // invoiceItemIds: invoiceItemIds,
          // address: this.address,
          // paymentAmount: null,
          // paymentType: this.paymentTypeToSend,
          // disbursementInvoiceId: this.serviceCategoryDetails.disbursementInvoiceId,

        };

        this.$store.dispatch('subdisbursment/savingDetails', paymentRequestObject).then(() => {

          this.addDisbursementInvoicePayment({}).then((response) => {

            if (response.data.status) {

              this.loadNotification('success', 4000, 'Sub Disbursment Account Invoice Payment Request', ' Invoice payment requerst has been successfully sent for payment',
                'response', 1000, true, true)
              this.loadSubDisbursementDetails()
              this.getDisbursementInvoicePayment()
              this.sortingData()

            } else {

              this.loadNotification('error', 4000, 'Sub Disbursment Account Invoice Payment Request', ' Invoice payment requerst not sent for payment',
                'response', 1000, true, true)

            }

            this.savingServiceInvoicePaymentRequestLoader = this.getMyLoader

          }).catch(() => {

            this.savingServiceInvoicePaymentRequestLoader = false

          });

          this.savingServiceInvoicePaymentRequestLoader = this.getMyLoader

        }).catch(() => {

          this.savingServiceInvoicePaymentRequestLoader = false

        });

      }

    },

    validateTin() {

      if (isNaN(this.tinNo)) {

        this.tinNo = ''

      }

    },

    hyphenifyThisTin() {

      this.tinNo = this.hyphenifyTin(this.tinNo)

    },

    loadSubDisbursementDetails() {

      return this.$emit('loadSubDisbursementDetails')

    },

    closeModal() {

      return this.$emit('closeModal')

    },

    clearFormPaymentRequest() {

      this.paymentType = '';
      this.accountNo = '';
      this.address = '';
      this.description = '';
      this.gepgControlNo = '';
      this.invoiceNo = '';
      this.paymentAmount = '';
      this.paymentDeadline = '';
      this.paymentReceiver = '';
      this.serviceType = '';
      this.tag = '';
      this.status = '';
      this.tinNo = '';
      this.accountNoError = false;
      this.gepgControlNoError = false;
      this.serviceTypeError = false;
      this.paymentAmountError = false;
      this.paymentReceiverError = false;
      this.tinNoError = false;
      this.paymentDeadlineError = false;
      this.addressError = false;
      this.descriptionError = false

    },

    closeForm() {

      this.isEditable = false;
      this.isAddiable = false;
      this.description = '';
      this.itemsFactor = '';
      this.itemsQuantity = '';
      this.itemsRate = '';
      this.amount = '';
      this.vat = '';
      this.total = '';

      this.editObject = {}

    }

  }

}
</script>

<style scoped>
ul.nav-justified {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ededee;
  border-radius: 3px;
}

ul.nav-justified li {
  float: left;
}

ul.nav-justified li a:hover {
  color: #d59a18;
}

.text-link {
  color: blue;
}

.text-link:hover {
  color: #d59a18;
  cursor: pointer;
}

ul.nav-justified li a {
  display: block;
  text-align: center;
  padding: 16px 20px 10px 21px;
  text-decoration: none;
  color: blue;
  font-weight: bold;
  text-transform: uppercase;
}

.custom-btn-all {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px 9px 5px 9px !important;
  font-size: 14px !important;
  width: auto;
}

.custom-btn {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px 9px 5px 9px !important;
  font-size: 14px !important;
}

.legend {
  position: relative;
  border: 1px solid #DCDCDC;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  background-color: inherit;
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #d59a18;
}

.div-table-row {
  padding: 0 15px 0 15px;
}

.div-table {
  padding: 9px 8px 9px 8px;
}

.div-table-title {
  width: 250px;
  background-color: rgba(153, 186, 221, .5);
  font-weight: normal;
}

.div-table-value {
  border: 1px solid #DCDCDC;
  font-weight: 900;
}

.btn-danger-custom:hover {
  border-color: #ff0000;
  color: #ff0000;
  background-color: #ffffff;
}

.btn-danger-custom {
  border-color: rgba(255, 0, 0, .5);
  color: rgba(255, 0, 0, .5);
}

.btn-danger-custom-fill {
  background-color: rgba(255, 0, 0, .5);
  opacity: 1;
  color: #FFFFFF;
}

.btn-success-custom:hover {
  border-color: #008000;
  color: #008000;
  background-color: #ffffff;
}

.btn-success-custom {
  border-color: rgba(0, 128, 0, .5);
  color: rgba(0, 128, 0, .5);
}

.btn-success-custom-fill {
  background-color: rgba(0, 128, 0, .8);
  opacity: 1;
  color: #FFFFFF;
}

.btn-linen:hover {
  border-color: #D2691E;
  color: #D2691E;
  background-color: #ffffff;
}

.btn-linen {
  border-color: rgba(210, 105, 30, .5);
  color: rgba(210, 105, 30, .5);
}

.btn-linen-fill {
  background-color: rgb(210, 105, 30);
  opacity: 1;
  color: #FFFFFF;
}

.dg-btn--ok:hover {
  border-color: #008000;
  color: #008000;
  background-color: #ffffff;
}

.dg-btn--ok {
  /* border-color: green; */
  border-color: rgba(0, 128, 0, .5);
  background-color: rgba(0, 128, 0, .8);
  color: rgb(255, 255, 255);
  padding: 5px 9px 5px 9px !important;
  font-size: 14px !important;
}

.dg-btn--cancel:hover {
  border-color: grey;
  color: grey;
  background-color: #ffffff;
}

.dg-btn--cancel {
  /* border-color: green; */
  border-color: rgba(128, 128, 128, .5);
  background-color: rgba(128, 128, 128, .8);
  color: rgb(255, 255, 255);
  padding: 5px 9px 5px 9px !important;
  font-size: 14px !important;
}

.dg-btn-loader .dg-circle {
  background-color: white;
  font-size: 14px !important;
}
</style>
