<template>
  <card>
    <div
      class="row"
      style="padding: 0 15px 0 15px"
    >
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successfully!</strong> <span
          v-if="SuccessAlert"
        >{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <form>
          <div class="form-group">
            <label for="name">Property Name</label>
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="name"
            >
            <div v-if="nameError">
              <div
                class="error text-danger"
                v-if="!$v.name.required"
              >
                This field is required
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea
              class="form-control"
              id="description"
              rows="3"
              v-model="description"
            />
            <div v-if="descriptionError">
              <div
                class="error text-danger"
                v-if="!$v.description.required"
              >
                This field is required
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-primary mb-2"
            @click.prevent="postProperties"
            v-if="!propertiesToEdit"
          >
            Submit
          </button>
          <button
            type="submit"
            class="btn btn-primary mb-2"
            @click.prevent="editProperties"
            v-if="propertiesToEdit"
          >
            Edit
          </button>
        </form>
      </div>
    </div>
  </card>
</template>

<script>
import TALLYAPI from '../../../api/tally'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'VehicleProperties',
  validations: {
    description: { required },
    name: { required }
  },

  components: {
    PulseLoader
  },
  created () {
  },

  data () {
    return {
      loading: false,
      color: 'green',
      size: '15px',

      description: this.propertiesToEdit ? this.propertiesToEdit.description : '',
      descriptionError: false,
      name: this.propertiesToEdit ? this.propertiesToEdit.name : '',
      nameError: false,
      propertyId: this.propertiesToEdit.id,

      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: ''
    }
  },
  props: ['propertiesToEdit', 'callback'],
  watch: {
    propertiesToEdit: function (data) {
      this.description = data.description
      this.name = data.name
    }
  },

  methods: {

    editProperties () {
      this.loading = true

      if (this.description !== '' && this.name !== '') {
        this.descriptionError = false
        this.nameError = false

        TALLYAPI.postVehicleProperties({
          description: this.description,
          name: this.name,
          id: this.propertyId
        }).then(() => {
          this.loading = false
          this.ErrorMessage = ''
          this.SuccessAlert = true
          this.ErrorAlert = false
          this.SuccessMessage = 'Vehicle Property Inserted Successfully'
          this.SuccessAlert = true
          this.callback()
        }).catch(() => {
          this.loading = false
          this.ErrorMessage = ', Vehicle Property Not Inserted'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
        })
      } else {
        this.descriptionError = true
        this.nameError = true
      }
    },

    postProperties () {
      this.loading = true
      if (this.description !== '' && this.name !== '') {
        this.descriptionError = false
        this.nameError = false

        TALLYAPI.postVehicleProperties({
          description: this.description,
          name: this.name
        }).then(() => {
          this.loading = false
          this.ErrorMessage = ''
          this.SuccessAlert = true
          this.ErrorAlert = false
          this.SuccessMessage = 'Vehicle Property Inserted Successfully'
          this.SuccessAlert = true

          this.description = ''
          this.name = ''
        }).catch(() => {
          this.loading = false
          this.ErrorMessage = ', Vehicle Property Not Inserted'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
        })
      } else {
        this.descriptionError = true
        this.nameError = true
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000)
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    }

  }
}
</script>

<style scoped>

</style>
