<template>
  <div class="content">
    <card>
      <div class="row">
        <div
          @click="toggle"
          class="col-md-12 toggle-code-key"
        >
          <div class="row">
            <div class="col-md-6">
              Role icons color keys
            </div>
            <div class="col-md-6 text-right">
              <font-awesome-icon
                icon="angle-down"
                v-show="!showSection"
              />
              <font-awesome-icon
                icon="angle-up"
                v-show="showSection"
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-12"
          v-show="showSection"
        >
          <hr>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 30px"
              v-show="showSection"
            >
              <b>icons keys</b>
            </div>
            <div class="col-md-12">
              <div
                class="col-md-12"
                v-show="showSection"
              >
                <div class="row">
                  <div class="col-md-2">
                    <span>
                      <font-awesome-icon icon="plus"/><i class=" text-muted"> Add Role</i>
                    </span>
                  </div>
                  <div class="col-md-2">
                    <span>
                      <font-awesome-icon
                        class="edit-fa-icon"
                        icon="edit"
                      /><i class=" text-muted"> Edit Role</i>
                    </span>
                  </div>
                  <div class="col-md-2">
                    <span>
                      <font-awesome-icon
                        class="view-fa-icon"
                        icon="eye"
                      /><i class=" text-muted"> View Role</i>
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span>
                      <font-awesome-icon icon="exchange-alt"/><i class=" text-muted"> Assign / Remove Permission</i>
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span>
                      <font-awesome-icon
                        class="delete-fa-icon"
                        icon="trash-alt"
                      /><i class=" text-muted"> Delete Role</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        @enter="enter"
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        style="border-radius:5px;"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          @click="fadeMe"
          aria-label="Close"
          class="close"
          data-dismiss="alert"
          style="line-height:2px;"
          type="button"
          v-if="SuccessAlert"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          @click="fadeMe"
          aria-label="Close"
          class="close"
          data-dismiss="alert"
          style="line-height:2px;"
          type="button"
          v-if="ErrorAlert"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <br>

      <div class="text-center">
        <pulse-loader
          :color="color"
          :loading="loading"
          :size="size"
          v-if="myloader"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Roles List
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            @click.prevent="openModal(role, 'AddModal')"
            class="all-fa-icons btn-space"
            title="add new request"
            v-if="finalUserPermissions.includes('ROLES_ADD_POST')"
          >
            <font-awesome-icon icon="plus"/>
          </span>
        </div>
      </div>
      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>

            <div class="col-md-2 float-left">
              <select
                @change="onChange($event)"
                class=""
                name="PageSize"
                style="width:73px;"
                v-model="pageSize"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-7 text-right"
              v-if="finalUserPermissions.includes('USERS_ROLES_SEARCH_GET')"
            >
              <form
                @submit.prevent="loadSearchRequestRole(pageNo, pageSize)"
                class="search"
              >
                <div class="form-group float-right">
                  <div class="input-group">
                    <div
                      @click.prevent="reloadByIcon"
                      class="refresh-all"
                      title="load all requests"
                      v-if="reloadAllRequest"
                    >
                      <font-awesome-icon icon="sync-alt"/>
                    </div>
                    <input
                      @input="loadAllPageble"
                      id="inputGroup"
                      placeholder="Search ...."
                      type="text"
                      v-model="searchParamenter"
                    >
                    <span
                      @click.prevent="loadSearchRequestRole(pageNo, pageSize)"
                      class="input-group-addon"
                    >
                      <font-awesome-icon icon="search"/>
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div
                class="col-md-12 disable-highlight"
                style="font-size: 20px"
                unselectable="on"
              >
                <span
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  class="btn-space"
                  title="go to first page"
                >First
                </span>
                <span
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  class="btn-space"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left"/>
                </span>

                <span class="btn-space"/>
                <span class="btn-space"/>

                <span
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  class="btn-space"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right"/>
                </span>
                <span
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  class="btn-space"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <!--   END TOP PAGENATION AND SEARCH ELEMENTS   -->

      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">
            #
          </th>
          <th scope="col">
            Name
          </th>
          <th scope="col">
            Description
          </th>
          <th scope="col">
            Action
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          :key="list.id"
          v-for="(list, index) in getRoles"
          v-if="!noDataFound"
        >
          <th scope="row">
            {{ index + 1 +(pageNo * pageSize) }}
          </th>
          <td>
            {{ list.name }}
          </td>

          <td>
            {{ list.description }}
          </td>

          <td>
            <div
              @click.prevent="openModal(list, actionButton = 'EditModal')"
              class=" all-fa-icons edit-fa-icon"
              title="edit role"
              v-if="list.status !== 'Request Accepted' && finalUserPermissions.includes('ROLES_{ID}_PUT')"
            >
              <font-awesome-icon icon="edit"/>
            </div>

            <div
              @click.prevent="openModal(list, actionButton = 'EditPermissionModal')"
              class="all-fa-icons fa-icons-space envelop-fa-icon"
              title="add / remove permission from role"
              v-if="finalUserPermissions.includes('ROLES_PRIVILEGE_ADD_POST')"
            >
              <font-awesome-icon icon="exchange-alt"/>
            </div>

            <div
              @click.prevent="openModal(list, actionButton = 'ShowModal')"
              class="all-fa-icons fa-icons-space view-fa-icon"
              title="view role"
              v-if="finalUserPermissions.includes('ROLES_{ID}_GET')"
            >
              <font-awesome-icon icon="eye"/>
            </div>
            <div
              @click.prevent="deleteRole(list.id , list.name)"
              class="all-fa-icons fa-icons-space delete-fa-icon"
              title="delete role"
              v-if="finalUserPermissions.includes('ROLES_{ID}_DELETE')"
            >
              <font-awesome-icon icon="trash-alt"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div
        class="col-md-12 text-center bg-grey"
        v-if="noDataFound"
      >
        No data found
      </div>

      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
          </div>

          <div
            class="col-md-9 text-right disable-highlight"
            style="line-height: 5px;font-size: 20px"
            unselectable="on"
          >
            <span
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              class="btn-space"
              title="go to first page"
            >First
            </span>
            <span
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="prevPage"
              class="btn-space"
              title="go to previous page"
            >
              <font-awesome-icon icon="angle-double-left"/>
            </span>

            <span class="btn-space"/>
            <span class="btn-space"/>

            <span
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="nextPage"
              class="btn-space"
              title="go to next page"
            >
              <font-awesome-icon icon="angle-double-right"/>
            </span>
            <span
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="lastPage"
              class="btn-space"
              title="go to last page"
            >Last
            </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->
    </card>

    <modal-pace v-if="showModal">
      <div
        class="modal-title col-md-12"
        slot="header"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Role: <b>{{ name }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="EditPermissionsModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                Edit permissions for role: <b>{{ name }}</b>
              </div>
              <div class="col-md-6 text-right loader-div-gif">
                <pulse-loader
                  :color="color"
                  :loading="loading"
                  :size="size"
                  v-if="permissionMyLoader"
                />
              </div>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            Register New Role
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                Permission(s) for Role: <b>{{ name }}</b>
              </div>
              <div class="col-md-6 text-right loader-div-gif">
                <pulse-loader
                  :color="color"
                  :loading="loading"
                  :size="size"
                  v-if="permissionMyLoader"
                />
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <!--     EDIT FORM       -->
        <form
          @submit.prevent="editRole"
          method="POST"
          v-if="EditModalDiv"
        >
          <card>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  label="Role Name"
                  placeholder="Role Name"
                  type="text"
                  v-model="name"
                />
                <div v-if="roleNameError">
                  <div
                    class="error role-error"
                    v-if="!$v.name.required"
                  >
                    This filed is required
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <label>Description</label>
                <textarea
                  class="form-control"
                  placeholder="Role Description"
                  rows="5"
                  v-model="description"
                />
                <div v-if="roleDescriptionError">
                  <div
                    class="error role-error"
                    v-if="!$v.description.required"
                  >
                    This filed is required
                  </div>
                </div>
              </div>
            </div>
          </card>
          <div
            class="text-center row"
            v-if="EditModalDiv"
          >
            <div class="col-md-7 text-right loader-div">
              <span v-if="editMyLoader">Please wait ....</span>
            </div>
            <div class="col-md-2 left loader-div-gif">
              <pulse-loader
                :color="color"
                :loading="loading"
                :size="size"
                v-if="editMyLoader"
              />
            </div>
            <div
              class="col-md-3"
              v-if="EditModalDiv"
            >
              <button
                @click="closeModal()"
                class="btn btn-fill btn-outline-info float-right"
                type="button"
              >
                Close
              </button>
              <button
                class="btn btn-primary btn-space  float-right"
                data-dismiss="modal"
                type="submit"
              >
                Edit
              </button>
            </div>
          </div>
        </form>

        <!--     EDIT PERMISSIONS FORM       -->
        <form
          @submit.prevent="saveRolePermissions(id , name)"
          method="POST"
          v-if="EditPermissionsModalDiv"
        >
          <div class="col-md-12">
            <div class="row">
              <div
                :key="index"
                class="card col-md-4"
                v-for="(permission,index) in allPermissionsGrouped"
              >
                <div class="">
                  <b>{{ Object.keys(permission)[0] }}</b>
                </div>
                <hr>
                <div
                  :key="perm.id"
                  class="custom-control"
                  v-for="perm in permission[Object.keys(permission)[0]]"
                >
                  <input
                    :checked="checkPermissions.includes(perm.id)"
                    :id="perm.id"
                    :value="perm.id"
                    @input="changePermissionOnClick(perm.id , perm.name ,
                                                    $event.target.checked , id , $event)"
                    name="permissionIds[]"
                    type="checkbox"
                  >&nbsp;
                  <label
                    :for="perm.id"
                    class="roles"
                  > {{ perm.name }}</label>
                </div>
              </div>
            </div>
          </div>
          <br>

          <div
            class="text-center row"
            v-if="EditPermissionsModalDiv"
          >
            <div class="col-md-7 text-right loader-div">
              <span v-if="editMyLoader">Please wait ....</span>
            </div>
            <div class="col-md-2 left loader-div-gif">
              <pulse-loader
                :color="color"
                :loading="loading"
                :size="size"
                v-if="editMyLoader"
              />
            </div>
            <div
              class="col-md-3"
              v-if="EditPermissionsModalDiv"
            >
              <button
                @click="closeModal()"
                class="btn btn-fill btn-outline-info float-right"
                type="button"
              >
                Close
              </button>
              <button
                class="btn btn-primary btn-space  float-right"
                data-dismiss="modal"
                type="submit"
              >
                Edit
              </button>
            </div>
          </div>
        </form>

        <!--      ADD FORM      -->
        <form
          @submit.prevent="saveRole"
          method="POST"
          v-if="AddModalDiv"
        >
          <card>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  class="special-bottom"
                  label="Role Name"
                  placeholder="Role Name"
                  type="text"
                  v-model="role.name"
                />
                <div v-if="roleNameError">
                  <div
                    class="error role-error"
                    v-if="!$v.role.name.required"
                  >
                    This filed is required
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <label>Description</label>
                <textarea
                  class="form-control"
                  placeholder="Role Description"
                  rows="5"
                  v-model="role.description"
                />
                <div v-if="roleDescriptionError">
                  <div
                    class="error role-error"
                    v-if="!$v.role.description.required"
                  >
                    This filed is required
                  </div>
                </div>
              </div>
            </div>
          </card>

          <div
            class="text-center row"
            v-if="AddModalDiv"
          >
            <div class="col-md-7 text-right loader-div">
              <span v-if="editMyLoader">Please wait ....</span>
            </div>
            <div class="col-md-2 left loader-div-gif">
              <pulse-loader
                :color="color"
                :loading="loading"
                :size="size"
                v-if="editMyLoader"
              />
            </div>
            <div
              class="col-md-3"
              v-if="AddModalDiv"
            >
              <button
                @click="closeModal()"
                class="btn btn-fill btn-outline-info float-right"
                type="button"
              >
                Close
              </button>
              <button
                class="btn btn-primary btn-space float-right"
                data-dismiss="modal"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>

        <card v-if="ShowModalDiv">
          <div class="col-md-12">
            <div class="row">
              <div
                :key="permission.id"
                class="col-md-6"
                v-for="permission in rolePermissions"
              >
                <div
                  class="custom-control"
                  v-if="rolePermissions.length > 0"
                >
                  <input
                    :checked="checkPermissions.includes(permission.id)"
                    :id="permission.id"
                    :value="permission.id"
                    disabled
                    name="permissionIds[]"
                    type="checkbox"
                  >
                  &nbsp;<label
                  :for="permission.id"
                  class="roles"
                > {{ permission.name }}</label>&nbsp;&nbsp;
                </div>
                <div
                  class="col-md-12"
                  v-else
                >
                  <div>No permission(s) assigned to this role</div>
                </div>
              </div>
              <div
                class="col-md-12"
                v-if="rolePermissions.length === 0"
              >
                <div>No permission(s) assigned to this role</div>
              </div>
            </div>
          </div>
          <br>
        </card>

        <div
          class="text-center row"
          v-if="ShowModalDiv"
        >
          <div class="col-md-12">
            <button
              @click="closeModal()"
              class="btn btn-default btn-fill float-right"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </modal-pace>
  </div>
</template>
<script>
  import API from '../../api/index'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import Modal from '../../components/Inputs/Modal'
  import ModalPace from '../../components/Inputs/ModalPlanAndCostEstimation'
  import {mapGetters} from 'vuex'
  import {required} from 'vuelidate/lib/validators'

  export default {

    name: 'RoleList',

    components: {

      PulseLoader,
      Modal,
      ModalPace

    },

    created() {
      this.$store.dispatch('pagenames/currentPageName', 'System Roles List')

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
      this.sortBy = 'id'
      this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
    },

    data() {
      return {

        array: [],
        getRoles: [],
        allPermissions: [],
        permissionIds: [],
        rolePermissions: {},
        allPermissionsGrouped: [],
        checkPermissions: [],
        storedPermissionIds: [],
        permissionNames: [],
        addedPermission: [],
        removedPermission: [],
        permissionGroups: [],
        permissionByGroups: [],
        permissionByMainGroups: [],
        finalUserPermissions: [],
        permissionGroupsOnLoad: [],
        userDetails: {},
        showSection: true,
        booleanValue: true,

        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        docPageNo: 0,
        docPageCount: 0,
        docSortBy: 'id',
        docPageSize: 0,
        getPageableDoc: 0,
        searchParameter: '',
        hasSearchedVariable: false,
        reloadAllRequest: false,
        noDataFound: false,
        searchParamenter: '',

        data: {},

        c: [],

        color: 'green',
        size: '15px',
        loading: true,

        SuccessMessage: '',
        ErrorMessage: '',
        name: '',
        id: '',
        description: '',

        error: '',

        role: {

          name: '',
          roleDescription: ''

        },

        SuccessAlert: false,
        ErrorAlert: false,
        showModal: false,
        myLoader: false,
        permissionMyLoader: false,
        editMyLoader: false,
        EditModalDiv: false,
        AddModalDiv: false,
        ShowModalDiv: false,
        EditPermissionsModalDiv: false,
        roleNameError: false,
        roleDescriptionError: false,
        permissionIdsCount: 0

      }
    },

    validations: {

      role: {

        name: {required},
        description: {required}

      },

      searchParameter: {required},

      name: {required},
      description: {required}

    },

    computed: {

      ...mapGetters('permissionswithroles', [

        'getValuesBeforeEdit',
        'getNameBeforeDelete'

      ])

    },

    methods: {

      toggle() {
        this.showSection = !this.showSection
      },

      onChange(event) {
        this.pageSize = event.target.value
        this.pageNo = 0
        if (this.hasSearchedVariable) {
          this.loadSearchRequestRole(this.pageNo, this.pageSize)
        } else {
          this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
        }
        this.docPageNo = this.pageNo
        this.docPageSize = this.pageSize
        this.docPageCount = this.pageCount
        this.docSortBy = this.sortBy
      },

      nextPage() {
        this.pageNo++
        this.docPageNo = this.pageNo
        if (this.hasSearchedVariable) {
          this.loadSearchRequestRole(this.pageNo, this.pageSize)
        } else {
          this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
        }
      },

      prevPage() {
        this.pageNo--
        this.docPageNo = this.pageNo
        if (this.hasSearchedVariable) {
          this.loadSearchRequestRole(this.pageNo, this.pageSize)
        } else {
          this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
        }
      },

      firstPage() {
        this.pageNo = 0
        this.docPageNo = this.pageNo

        if (this.hasSearchedVariable) {
          this.loadSearchRequestRole(this.pageNo, this.pageSize)
        } else {
          this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
        }
      },

      lastPage() {
        this.pageNo = this.pageCount - 1
        this.docPageNo = this.pageNo
        if (this.hasSearchedVariable) {
          this.loadSearchRequestRole(this.pageNo, this.pageSize)
        } else {
          this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
        }
      },

      loadAllPageble() {
        if (this.searchParameter === '' && this.hasSearchedVariable === true) {
          this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
          this.noDataFound = false
          this.hasSearchedVariable = false
          this.reloadAllRequest = false
          this.searchParamenter = ''
        }
      },

      reloadByIcon() {
        this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
        this.searchParamenter = ''
      },

      loadSearchRequestRole(pageNo, pageSize) {
        if (this.$v.searchParameter.$invalid) {

        } else {
          this.myloader = true
          this.reloadAllRequest = true
          this.hasSearchedVariable = true

          API.getSearchPageableRoles(pageNo, pageSize, this.searchParameter).then(
            response => {
              if (response.data.status) {
                this.myloader = false

                if (response.data.data.length > 0) {
                  this.getRoles = response.data.data
                  this.pageCount = response.data.message
                  this.docPageCount = this.pageCount
                  this.docPageNo = this.pageNo
                  this.docPageSize = this.pageSize
                  this.myloader = false
                } else {
                  this.noDataFound = true
                  this.pageCount = response.data.message
                  this.docPageCount = this.pageCount
                  this.docPageNo = this.pageNo
                  this.docPageSize = this.pageSize
                  this.myloader = false
                }
              } else {
                this.noDataFound = true
                this.pageCount = response.data.message
                this.docPageCount = this.pageCount
                this.docPageNo = this.pageNo
                this.docPageSize = this.pageSize
                this.getPageableDoc = response.data.data
                this.myloader = false
              }
            }
          ).catch(e => {
            this.myloader = false
          })
        }
      },

      loadPageableRole(pageNo, pageSize, sortBy) {
        return new Promise((resolve, reject) => {
          this.myloader = true

          API.getPageableRoles(pageNo, pageSize, sortBy).then(
            response => {
              if (response.data.status) {
                this.pageCount = response.data.message
                this.docPageCount = this.pageCount
                this.docPageNo = this.pageNo
                this.docPageSize = this.pageSize
                this.getRoles = response.data.data
                this.myloader = false
              } else {

              }

              resolve()
            }
          ).catch(e => {
            this.ErrorMessage = ', Requests Not loaded'
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.myloader = false

            reject(e)
          })
        })
      },

      loadAllPermissions() {
        this.permissionMyLoader = true

        this.permissionGroups = []
        this.permissionByGroups = []
        this.permissionByMainGroups = []

        API.getAllPermissions().then(
          response => {
            if (response.data.status) {
              this.allPermissions = response.data.data

              this.permissionMyLoader = false

              let groupName = ''

              const newGrouped = []
              let newGroups = []

              const newPerms = this.allPermissions

              for (let i = 0; i < newPerms.length; i++) {
                const firstWord = this.allPermissions[i].name.split('_')[0]
                const permission = this.allPermissions[i]

                let perm_elements = this.allPermissions[i].name.split('_')

                perm_elements = perm_elements.slice(1, perm_elements.length)

                if (i === 0) {
                  groupName = firstWord
                  permission.name = perm_elements.join(' ')
                  newGroups.push(permission)
                } else if (groupName === firstWord && i !== newPerms.length - 1) {
                  permission.name = perm_elements.join(' ')
                  newGroups.push(permission)
                } else if (groupName !== firstWord && groupName !== '' && i !== newPerms.length - 1) {
                  newGrouped.push({[groupName]: newGroups})
                  newGroups = []
                  groupName = firstWord
                  permission.name = perm_elements.join(' ')
                  newGroups.push(permission)
                } else if (i === (newPerms.length - 1)) {
                  groupName = firstWord
                  permission.name = perm_elements.join(' ')
                  newGroups.push(permission)
                  newGrouped.push({[groupName]: newGroups})
                }
              }

              this.allPermissionsGrouped = newGrouped
            } else {

            }
          }
        ).catch(() => {
          this.ErrorMessage = ', Record not loaded'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
        })
      },

      loadRolePermissions(data) {
        this.permissionMyLoader = true

        API.getRolePermissions(data).then(
          response => {
            if (response.data.status) {
              this.checkPermissions = []
              this.addedPermission = []
              this.removedPermission = []
              this.permissionNames = []

              this.rolePermissions = response.data.data

              this.permissionMyLoader = false

              for (let i = 0; i < this.rolePermissions.length; i++) {
                this.checkPermissions.push(this.rolePermissions[i].id)
                this.permissionNames.push(this.rolePermissions[i].name)
                this.addedPermission.push(this.rolePermissions[i].name)
                this.removedPermission.push(this.rolePermissions[i].name)

                this.checkPermissions = Array.from(new Set(this.checkPermissions))
                this.permissionNames = Array.from(new Set(this.permissionNames))
                this.addedPermission = Array.from(new Set(this.addedPermission))
                this.removedPermission = Array.from(new Set(this.removedPermission))
              }
            } else {

            }
          }
        ).catch(e => {

        })
      },

      loadSingleRole(data) {
        API.getRole(data).then(
          response => {
            if (response.data.status) {
              this.data = response.data.data
            } else {
              this.data = ', Role was not found'
            }
          }
        ).catch(() => {

        })
      },

      saveRole() {
        console.log(this.$v)
        if (this.$v.role.name.$invalid || this.$v.role.description.$invalid) {
          if (this.$v.role.name.$invalid) {
            this.roleNameError = true
          }

          if (this.$v.role.description.$invalid) {
            this.roleDescriptionError = true
          }
        } else {
          this.editMyLoader = true

          // setTimeout(() => {

          API.postRole(JSON.stringify(this.role)).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ', Role with name [ ' + response.data.data.name + ' ] Saved'
                this.SuccessAlert = true
                this.ErrorMessage = ''
                this.ErrorAlert = false
                this.closeModal()
                this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
                this.editMyLoader = false
                this.role.name = ''
                this.role.description = ''
              } else {

              }
            }
          ).catch(() => {
            this.editMyLoader = false
            this.ErrorMessage = ', Role not Added, try again or contact system administrator'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.editMyLoader = false
            this.role.name = false
          })

          // }, 2000);
        }
      },

      changePermissionOnClick(id, name, check, roleId, event) {
        this.permissionIdsCount++
        if (this.checkPermissions.includes(id) && check === true) { // no changes
          if (this.checkPermissions.length > 0) {
            this.permissionIds = this.checkPermissions
          }
        }

        if (!this.checkPermissions.includes(id) && check === true) { // additional
          if (this.checkPermissions.length > 0) {
            this.permissionIds = this.checkPermissions
          }

          this.permissionIds.push(parseInt(event.target.value))

          this.addedPermission.push(name)
        }

        if (this.checkPermissions.includes(id) && check === false) { // removal
          this.checkPermissions.splice(this.checkPermissions.indexOf(id), 1)

          this.removedPermission.splice(this.removedPermission.indexOf(name), 1)

          if (this.checkPermissions.length > 0) {
            this.permissionIds = this.checkPermissions
          } else {
            this.permissionIds = []

            this.removedPermission = []
          }
        }
      },

      saveRolePermissions(data, name) {
        this.editMyLoader = true

        const privileges = []

        this.permissionIds.forEach(element => {
          privileges.push({id: element})
        })

        let rolePermissionsOneTime = {

          privileges,

          id: data

        }

        if(this.permissionIdsCount  <= 0) {

            this.loadNotification('info', 4000, 'Request Message',
              'No change made to permissions, please review or click close button to exit',
              'response', 1000 , true  , true);
            this.editMyLoader = false

        } else {

            this.permissionIdsCount = 0

            // setTimeout(() => {

            API.postRolePermissions(JSON.stringify(rolePermissionsOneTime)).then(
              response => {
                if (response.data.status) {
                  this.SuccessMessage = `${this.updatedRoleAndPermissionStatus(this.addedPermission,
                    this.removedPermission, this.permissionNames, name, 'permission')}`
                  this.SuccessAlert = true
                  this.ErrorMessage = ''
                  this.ErrorAlert = false
                  this.closeModal()
                  this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
                  this.editMyLoader = false
                  this.checkPermissions = []
                  this.permissionNames = []
                  this.permissionIds = []
                  rolePermissionsOneTime = {}
                } else {

                }
              }
            ).catch(e => {
              this.editMyLoader = false
              this.ErrorMessage = ', Permission(s) not Added'
              this.SuccessMessage = ''
              this.SuccessAlert = false
              this.ErrorAlert = true
              this.editMyLoader = false
              this.checkPermissions = []
              this.permissionNames = []
              this.permissionIds = []
              this.closeModal()
            })

            // }, 2000);

        }

      },

      deleteRole(data, name) {
        this.$store.dispatch('permissionswithroles/nameBeforeDelete', name)

        this.$dialog.confirm('If you delete role [ ' + name + " ], it'll be gone forever.", {

          loader: true
        })
          .then((dialog) => {
            // setTimeout(() => {

            API.deleteRole(data).then(
              response => {
                if (response.data.status) {
                  this.SuccessMessage = ', Role [ ' + this.getNameBeforeDelete + ' ] has been deleted'
                  this.$store.dispatch('permissionswithroles/nameBeforeDelete', '')
                  this.ErrorMessage = ''
                  this.SuccessAlert = true
                  this.ErrorAlert = false
                  this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
                } else {

                }
              }
            ).catch(e => {
              this.ErrorMessage = ', Role not deleted'
              this.SuccessMessage = ''
              this.SuccessAlert = false
              this.ErrorAlert = true
            })

            dialog.close()
            // }, 2500);
          })
          .catch(() => {
            this.myloader = false
            this.SuccessAlert = false
          })
      },

      editRole() {
        if (this.$v.name.$invalid || this.$v.description.$invalid) {
          if (this.$v.name.$invalid) {
            this.roleNameError = true
          }

          if (this.$v.description.$invalid) {
            this.roleDescriptionError = true
          }
        } else {
          this.editMyLoader = true

          this.array =
            {
              name: this.name,
              id: this.id,
              description: this.description
            }

          // setTimeout(() => {

          API.updateRole(this.id, JSON.stringify(this.array)).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = `${this.updatedValueStatus(this.getValuesBeforeEdit,
                  [response.data.data.name, response.data.data.description], 'Role', response.data.data.name)}`
                this.SuccessAlert = true
                this.ErrorMessage = ''
                this.ErrorAlert = false
                this.closeModal()
                this.editMyLoader = false
                this.loadPageableRole(this.pageNo, this.pageSize, this.sortBy)
                this.$store.dispatch('permissionswithroles/valuesBeforeEdit', [])
                this.name = ''
                this.description = ''
              } else {

              }
            }
          ).catch(() => {
            this.editMyLoader = false
            this.ErrorMessage = ', Role not Edited'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.editMyLoader = false
            this.closeModal()
            this.$store.dispatch('permissionswithroles/valuesBeforeEdit', [])
          })

          // }, 2000);
        }
      },

      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert
        } else {
          this.ErrorAlert = !this.ErrorAlert
        }
      },

      enter: function (el, done) {
        var that = this

        setTimeout(function () {
          that.SuccessAlert = false
          that.ErrorAlert = false
        }, 3000) // hide the message after 3 seconds
      },

      openModal(userData, actionButton) {
        if (actionButton === 'EditModal') {
          this.EditModalDiv = true
          this.showModal = true
          this.AddModalDiv = false
          this.ShowModalDiv = false
          this.EditPermissionsModalDiv = false
          this.id = userData.id
          this.name = userData.name
          this.description = userData.description
          this.$store.dispatch('permissionswithroles/valuesBeforeEdit', [this.name, this.description])
        } else if (actionButton === 'AddModal') {
          this.EditModalDiv = false
          this.AddModalDiv = true
          this.ShowModalDiv = false
          this.showModal = true
          this.EditPermissionsModalDiv = false
        } else if (actionButton === 'EditPermissionModal') {
          this.EditModalDiv = false
          this.AddModalDiv = false
          this.ShowModalDiv = false
          this.showModal = true
          this.EditPermissionsModalDiv = true
          this.name = userData.name
          this.id = userData.id
          this.description = userData.description
          this.loadAllPermissions()
          this.loadRolePermissions(this.id)
        } else {
          this.name = userData.name
          this.id = userData.id
          this.description = userData.description
          this.loadRolePermissions(this.id)
          this.loadSingleRole(this.id)
          this.EditModalDiv = false
          this.AddModalDiv = false
          this.ShowModalDiv = true
          this.showModal = true
          this.EditPermissionsModalDiv = false
        }
      },

      closeModal() {
        this.showModal = false
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.checkPermissions = []
        this.rolePermissions = {}
        this.role.name = ''
        this.role.description = ''
        this.name = ''
        this.description = ''
      }

    }

  }
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limitu {
    max-width: 270px !important;
    min-width: 200px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  ul li {
    display: inline;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

  .role-error {
    margin-left: 15px;
  }
</style>
