<template>
  <div>
    <card>
      <div class="row">
        <div
          @click="toggle"
          class="col-md-12 toggle-code-key"
        >
          <div class="row">
            <div class="col-md-6">
              Tariff keys and icons color keys
            </div>
            <div class="col-md-6 text-right">
              <font-awesome-icon
                icon="angle-down"
                v-show="!showSection"
              />
              <font-awesome-icon
                icon="angle-up"
                v-show="showSection"
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-12"
          v-show="showSection"
        >
          <hr>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 30px"
              v-show="showSection"
            >
              <b>icons keys</b>
            </div>
            <div class="col-md-12">
              <div
                class="col-md-12"
                v-show="showSection"
              >
                <div class="row">
                  <div class="col-md-4">
                    <span>
                      <font-awesome-icon
                        class="edit-fa-icon"
                        icon="edit"
                      /><i class=" text-muted"> Edit Tariff</i>
                    </span>
                  </div>
                  <div class="col-md-4">
                    <span>
                      <font-awesome-icon
                        class="view-fa-icon"
                        icon="eye"
                      /><i class=" text-muted"> View Rules</i>
                    </span>
                  </div>
                  <div class="col-md-4">
                    <span>
                      <font-awesome-icon icon="plus" /><i class=" text-muted"> Add New Tariff</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-12">
          <div class="text-left">
            Tariff Details
          </div>
          <hr>
        </div>
        <hr>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <b>Tariff Name</b>
                </div>
                <div class="col-md-6">
                  {{ this.getTariffById.tariffName }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <b>Description</b>
                </div>
                <div class="col-md-6">
                  {{ this.getTariffById.description }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <b>Status</b>
                </div>
                <div
                  class="col-md-6 text-success"
                  v-if="this.getTariffById.active"
                >
                  Active
                </div>
                <div
                  class="col-md-6 text-muted"
                  v-else
                >
                  Not Active
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <b>Created Date</b>
                </div>
                <div class="col-md-6">
                  {{ date(this.getTariffById.createdDate) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Tariff Fees For {{ this.getTariffById.tariffName }}
          </div>
          <div class="text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            class="all-fa-icons btn-space"
            title="add new request"
            v-if="finalUserPermissions.includes('TARIFF-FEES_CREATE_POST')"
            @click="openModal('NEW_TARIFFEES')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  #
                </th>
                <th scope="col">
                  TARIFF FEE NAME
                </th>
                <th scope="col">
                  REMARKS
                </th>
                <th scope="col">
                  STATUS
                </th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(list,index) in TariffFees"
                :key="list.id"
              >
                <th scope="row">
                  {{ index + 1 }}
                </th>
                <td>{{ list.tariffFeeName }}</td>
                <td>{{ list.remarks }}</td>
                <td
                  class="mx-auto text-success"
                  v-if="list.isActive"
                >
                  Active
                </td>
                <td
                  class="mx-auto text-muted"
                  v-else
                >
                  Not Active
                </td>
                <td class="special-limit">
                  <div
                    class=" all-fa-icons fa-icons-space edit-fa-icon"
                    title="Edit"
                  >
                    <font-awesome-icon
                      icon="edit"
                      @click="openModal('EDIT_TARIFFEES',list)"
                    />
                  </div>
                  <div
                    class=" all-fa-icons fa-icons-space view-fa-icon"
                    v-if="finalUserPermissions.includes('TARIFF-RULES_LIST-BY-FEE_{TARIFFFEEID}_GET')"
                    title="view Rules"
                  >
                    <font-awesome-icon
                      icon="eye"
                      @click="tariffeeClicked(list.tariffFeeId)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <modal v-if="showModal">
        <div
          slot="header"
          class="modal-title col-md-12"
        >
          <div class="row">
            Add New Tariff Fee
          </div>
          <div slot="body">
            <card>
              <div
                class="col-md-12 alert-dismissible fade show"
                role="alert"
                :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
                style="border-radius:5px;"
              >
                <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
                <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  v-if="SuccessAlert"
                  style="line-height:2px;"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  v-if="ErrorAlert"
                  style="line-height:2px;"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <div v-if="!istariffFeeNameValid">
                        <small
                          v-if="!$v.tarriffFeesData.tariffFeeName.required"
                          :class="[!$v.tarriffFeesData.tariffFeeName.required ? 'text-danger':'']"
                        >This field is required</small><br>
                      </div>
                      <label for="fee">Tariff Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="fee"
                        placeholder="Tariff Name"
                        v-model="tarriffFeesData.tariffFeeName"
                      >
                    </div>
                    <div class="form-group">
                      <div v-if="!isactiveValid">
                        <small
                          v-if="!$v.tarriffFeesData.isActive.required"
                          :class="[!$v.tarriffFeesData.isActive.required ? 'text-danger':'']"
                        >This field is required</small><br>
                      </div>
                      <label for="statusEdit">Select Status</label>
                      <select
                        class="form-control"
                        id="statusEdit"
                        @change="onChange($event,`TARIFFEE_STATUS`)"
                      >
                        <option />
                        <small
                          v-if="!this.istariffFeeNameValid"
                          :class="[this.istariffFeeNameValid ? '':'text-danger']"
                        >This field is required</small><br>
                        <option
                          value="true"
                          :selected="tarriffFeesData.isActive==='true'"
                        >
                          Active
                        </option>
                        <option
                          value="false"
                          :selected="tarriffFeesData.isActive==='false'"
                        >
                          Not Active
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <div v-if="!isremarksValid">
                        <small
                          v-if="!$v.tarriffFeesData.remarks.required"
                          :class="[!$v.tarriffFeesData.remarks.required ? 'text-danger':'']"
                        >This field is required</small><br>
                      </div>
                      <label for="exampleFormControlTextarea1">Remarks</label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        v-model="tarriffFeesData.remarks"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="text-center row"
                  v-if="tariffeeModal"
                >
                  <div class="col-md-7 text-right loader-div">
                    <span v-if="myloader">Please wait ....</span>
                  </div>
                  <div class="col-md-2 left loader-div-gif">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="myloader"
                    />
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-space btn-fill btn-outline-info float-right"
                      @click="closeModal()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Close
                    </button>
                    <button
                      class="btn btn-primary btn-space float-right"
                      data-dismiss="modal"
                      @click="saveTariffee()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Save
                    </button>
                  </div>
                </div>

                <div
                  class="text-center row"
                  v-if="tariffeeEDITModal"
                >
                  <div class="col-md-7 text-right loader-div">
                    <span v-if="myloader">Please wait ....</span>
                  </div>
                  <div class="col-md-2 left loader-div-gif">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="myloader"
                    />
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-space btn-fill btn-outline-info float-right"
                      @click="closeModal()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Close
                    </button>
                    <button
                      class="btn btn-primary btn-space float-right"
                      data-dismiss="modal"
                      @click="editTariffee()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </modal>
    </card>
  </div>
</template>

<script>
import API from '../../../api'
import API_REVENUE from '../../../api/rev'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TariffFees',

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.getTariffFees();
    this.getTariffwithId()
  },

  components: {

    PulseLoader,
    Modal

  },
  validations: {
    tarriffFeesData: {
      isActive: { required },
      remarks: { required },
      tariffFeeName: { required },
      tariffFeeId: { required }
    }
  },

  data () {
    return {
      TariffFees: [],
      Tariffs: [],
      finalUserPermissions: [],

      showModal: false,
      myLoader: false,
      disableButton: false,
      tariffeeModal: false,
      tariffeeEDITModal: false,
      showSection: true,
      selectedTariff: '',
      getTariffById: {},

      color: 'green',
      size: '15px',
      loading: true,

      /* tariffFees */
      tarriffFeesData: {
        isActive: '',
        remarks: '',
        tariffFeeName: '',
        tariffId: this.$route.params.id,
        tariffFeeId: '',
        minimumLimit: '',
        maximumLimit: ''
      },

      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',

      istariffFeeNameValid: true,
      isremarksValid: true,
      isactiveValid: true,
      isSelectTarifValid: true
    }
  },

  methods: {

    toggle () {
      this.showSection = !this.showSection
    },

    date (date) {
      const newDate = new Date(date);

      const day = (newDate.getDay()).toString.length > 1 ? newDate.getDay() : '0' + newDate.getDay();
      const month = (newDate.getMonth()).toString.length > 1 ? newDate.getMonth() : '0' + newDate.getMonth();
      return day + '/' + month + '/' + newDate.getFullYear()
    },

    tariffeeClicked (id) {
      this.$router.push({ path: '/configurations/tariff/rule/' + id })
    },

    /* get all tariffs */
    getTariffFees () {
      API_REVENUE.getTariffFees(this.tarriffFeesData.tariffId).then((response) => {
        if (response.data.status) {
          this.TariffFees = response.data.data
        }
      }).catch((error) => {

      })
    },

    getTariffwithId () {
      API_REVENUE.getTariffByTarifId(this.tarriffFeesData.tariffId).then((response) => {
        this.getTariffById = response.data.data
      }).catch(() => {
        this.ErrorMessage = 'Failed To load Tariff';
        this.SuccessAlert = false;
        this.ErrorAlert = true;
        this.SuccessMessage = ''
      })
    },

    openModal (modal, data = null) {
      this.showModal = true;
      this.tariffeeModal = false;
      this.tariffeeEDITModal = false;

      this.ErrorMessage = '';
      this.SuccessAlert = false;
      this.ErrorAlert = false;
      this.SuccessMessage = '';

      this.isactiveValid = true;
      this.isremarksValid = true;
      this.istasacGfsCodeValid = true;
      this.isSelectTarifValid = true;
      this.istariffFeeNameValid = true;

      this.disableButton = false;
      this.myloader = false;

      if (modal === 'NEW_TARIFFEES') {
        this.tariffeeModal = true
      }

      if (modal === 'EDIT_TARIFFEES') {
        this.tariffeeEDITModal = true;
        this.tarriffFeesData.tariffFeeName = data.tariffFeeName;
        this.tarriffFeesData.tariffFeeId = data.tariffFeeId;
        this.tarriffFeesData.tariffId = data.tariffId;
        this.tarriffFeesData.isActive = data.isActive ? 'true' : 'false';
        this.tarriffFeesData.remarks = data.remarks;
      }
    },
    closeModal () {
      this.showModal = false;
      this.tariffeDITModal = false;
      this.tarriffFeesData.tariffFeeName = '';
      this.tarriffFeesData.isActive = '';
      this.tarriffFeesData.tasacGfsCode = '';
      this.tarriffFeesData.remarks = ''
    },
    /* save tariffs */

    saveTariffee () {
      this.myloader = true;
      this.disableButton = true;
      /** check if all fields are filled**/
      if (
        this.$v.tarriffFeesData.tariffFeeName.required &&
          this.$v.tarriffFeesData.remarks.required &&
          this.$v.tarriffFeesData.isActive.required
      ) {
        API_REVENUE.saveTariffees(this.tarriffFeesData).then((response) => {
          this.ErrorMessage = '';
          this.SuccessAlert = true;
          this.ErrorAlert = false;
          this.SuccessMessage = ' Record Added';
          this.myloader = false;
          this.disableButton = false;

          this.istariffFeeNameValid = true;
          this.isremarksValid = true;
          this.isactiveValid = true;
          this.isSelectTarifValid = true;

          this.tarriffFeesData.tariffFeeName = '';
          this.tarriffFeesData.remarks = '';
          this.tarriffFeesData.isActive = '';

          this.getTariffFees();

          setTimeout(() => {
            this.closeModal()
          }, 700)
        }).catch((e) => {
          this.istariffFeeNameValid = true;
          this.isremarksValid = true;
          this.isactiveValid = true;
          this.isSelectTarifValid = true;
          this.ErrorMessage = ' Record not Added';
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = '';
          this.SuccessAlert = false;
          this.myloader = false;
          this.disableButton = false
        })
      } else {
        this.tarriffFeesData.tariffFeeName === '' ? this.istariffFeeNameValid = false : this.istariffFeeNameValid = true;
        this.tarriffFeesData.tariffId === '' ? this.isSelectTarifValid = false : this.isSelectTarifValid = true;
        this.tarriffFeesData.remarks === '' ? this.isremarksValid = false : this.isremarksValid = true;
        this.tarriffFeesData.isActive === '' ? this.isactiveValid = false : this.isactiveValid = true;

        this.disableButton = false;
        this.myloader = false
      }
    },
    editTariffee () {
      if (
        this.$v.tarriffFeesData.tariffFeeName.required &&
          this.$v.tarriffFeesData.remarks.required &&
          this.$v.tarriffFeesData.isActive.required &&
          this.$v.tarriffFeesData.tariffFeeId.required
      ) {
        this.myloader = true;
        this.disableButton = true;
        API_REVENUE.editTariffee(this.tarriffFeesData).then((response) => {
          this.myloader = false;
          this.disableButton = false;
          this.tarriffFeesData.tariffFeeName = '';
          this.tarriffFeesData.remarks = '';
          this.tarriffFeesData.isActive = '';
          this.tarriffFeesData.tariffFeeId = '';

          this.getTariffFees();

          setTimeout(() => {
            this.closeModal()
          }, 700);

          this.ErrorMessage = '';
          this.SuccessAlert = true;
          this.ErrorAlert = false;
          this.SuccessMessage = 'Record Updated';
          this.istariffFeeNameValid = true;
          this.isremarksValid = true;
          this.isactiveValid = true;
          this.isSelectTarifValid = true
        }).catch((e) => {
          this.myloader = false;
          this.disableButton = false
        })
      } else {
        this.tarriffFeesData.tariffFeeName === '' ? this.istariffFeeNameValid = false : this.istariffFeeNameValid = true;
        this.tarriffFeesData.tariffId === '' ? this.isSelectTarifValid = false : this.isSelectTarifValid = true;
        this.tarriffFeesData.remarks === '' ? this.isremarksValid = false : this.isremarksValid = true;
        this.tarriffFeesData.isActive === '' ? this.isactiveValid = false : this.isactiveValid = true;
        this.disableButton = false;
        this.myloader = false
      }
    },

    onChange (event, data) {
      if (data === 'SELECTED-TARIFF') {
        this.selectedTariff = event.target.value;
        this.getTariffFees()
      }

      if (data === 'SELECTED-TARIFFEE-NEW') {
        this.tarriffFeesData.tariffId = event.target.value
      }

      if (data === 'TARIFFEE_STATUS') {
        this.tarriffFeesData.isActive = event.target.value
      }
    }
  }
}
</script>

<style scoped>
  .clickable{
    cursor: pointer;
  }
  .btn-space {
    margin-left: 10px;
  }
</style>
