<template>
  <div>
    <card>
      <div class="row">
        <div
          @click="toggle"
          class="col-md-12 toggle-code-key"
        >
          <div class="row">
            <div class="col-md-6">
              Tariff Item icons color keys odgjsfdogj
            </div>
            <div class="col-md-6 text-right">
              <font-awesome-icon
                icon="angle-down"
                v-show="!showSection"
              />
              <font-awesome-icon
                icon="angle-up"
                v-show="showSection"
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-12"
          v-show="showSection"
        >
          <hr>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 30px"
              v-show="showSection"
            >
              <b>icons keys</b>
            </div>
            <div class="col-md-12">
              <div
                class="col-md-12"
                v-show="showSection"
              >
                <div class="row">
                  <div class="col-md-6">
                    <span>
                      <font-awesome-icon icon="plus" /><i class=" text-muted"> Edit Tariff Item</i>
                    </span>
                  </div>
                  <div class="col-md-6">
                    <span>
                      <font-awesome-icon
                        class="edit-fa-icon"
                        icon="edit"
                      /><i class=" text-muted"> Edit Tariff Item</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Tariff Fees
          </div>
          <div class="text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            class="all-fa-icons btn-space"
            title="add new request"
            v-if="finalUserPermissions.includes('TARIFF-ITEMS_CREATE_POST')"
            @click="openModal('NEW_TARIFF_ITEM')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>
      <hr>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Item Name
            </th>
            <th scope="col">
              Item Unit
            </th>
            <th scope="col">
              TASAC GFS CODE
            </th>
            <th scope="col">
              GEPG GFS CODE
            </th>
            <th scope="col">
              remarks
            </th>
            <th scope="col">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list,index) in getTariffItems"
            :key="list.id"
          >
            <th scope="row">
              {{ index + 1 }}
            </th>
            <td>{{ list.tariffItemName }}</td>
            <td>{{ list.tariffItemUnit }}</td>
            <td>{{ list.tasacGfsCode }}</td>
            <td>{{ list.gepgGfsCode }}</td>
            <td>{{ list.remarks }}</td>
            <td>
              <div
                class=" all-fa-icons edit-fa-icon"
                v-if="finalUserPermissions.includes('TARIFF-ITEMS_UPDATE_PUT')"
                title="Edit"
              >
                <font-awesome-icon
                  icon="edit"
                  @click="openModal('EDIT_TARIFF_ITEM',list)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <modal v-if="showModal">
        <div
          slot="header"
          class="modal-title col-md-12"
        >
          <div class="row">
            Add New Tariff Item
          </div>
          <div slot="body">
            <card>
              <div
                class="col-md-12 alert-dismissible fade show"
                role="alert"
                :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
                style="border-radius:5px;"
              >
                <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
                <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  v-if="SuccessAlert"
                  style="line-height:2px;"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  v-if="ErrorAlert"
                  style="line-height:2px;"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <small
                        v-if="!isTariffItemNameValid"
                        :class="[isTariffItemNameValid ? '':'text-danger']"
                      >
                        This field is required</small><br>
                      <label for="name">Item Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Item Name"
                        v-model="tariffeeItemData.tariffItemName"
                        :class="[isTariffItemNameValid ? '':'is-invalid']"
                      >
                    </div>
                    <div class="form-group">
                      <label>Tariff Category</label>
                      <list-select
                        :list="customerTypes"
                        :selected-item="selectedCustomerType"
                        class="form-control"
                        option-value="id"
                        option-text="name"
                        placeholder="Select Tariff Category"
                        @select="selectedCustomer">
                      </list-select>
                    </div>
                    <div class="form-group">
                      <small
                        v-if="!isTariffItemUnitValid"
                        :class="[isTariffItemUnitValid ? '':'text-danger']"
                      >
                        This field is required</small><br>
                      <label for="unit">Item Unit</label>
                      <input
                        type="text"
                        class="form-control"
                        id="unit"
                        placeholder="Item Unit"
                        v-model="tariffeeItemData.tariffItemUnit"
                        :class="[isTariffItemUnitValid ? '':'is-invalid']"
                      >
                    </div>
                    <div class="form-group">
                      <small
                        v-if="!isTariffGFSValid"
                        :class="[isTariffGFSValid ? '':'text-danger']"
                      >
                        This field is required</small><br>
                      <label for="gfs">GEPG GFS Code</label>
                      <input
                        type="text"
                        class="form-control"
                        id="gfs"
                        placeholder="GFS Code"
                        v-model="tariffeeItemData.gepgGfsCode"
                        :class="[isTariffGFSValid ? '':'is-invalid']"
                      >
                    </div>
                    <div class="form-group">
                      <small
                        v-if="!isTariffTasacGFSValid"
                        :class="[isTariffTasacGFSValid ? '':'text-danger']"
                      >
                        This field is required</small><br>
                      <label for="tgfs">TASAC GFS Code</label>
                      <input
                        type="text"
                        class="form-control"
                        id="tgfs"
                        placeholder="TASAC GFS Code"
                        v-model="tariffeeItemData.tasacGfsCode"
                        :class="[isTariffTasacGFSValid ? '':'is-invalid']"
                      >
                    </div>
                    <div class="form-group form-check">
                      <small
                        v-if="!isTariffRemarksValid"
                        :class="[isTariffRemarksValid ? '':'text-danger']"
                      >
                        This field is required</small><br>
                      <label for="remarks">Remarks</label>
                      <textarea
                        class="form-control"
                        id="remarks"
                        rows="3"
                        v-model="tariffeeItemData.remarks"
                        :class="[isTariffRemarksValid ? '':'is-invalid']">
                      </textarea>
                    </div>
                  </div>
                </div>
                <div
                  class="text-center row"
                  v-if="showNewModal"
                >
                  <div class="col-md-7 text-right loader-div">
                    <span v-if="myloader">Please wait ....</span>
                  </div>
                  <div class="col-md-2 left loader-div-gif">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="myloader"
                    />
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-space btn-fill btn-outline-info float-right"
                      @click="closeModal()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Close
                    </button>
                    <button
                      class="btn btn-primary btn-space float-right"
                      data-dismiss="modal"
                      @click="saveItemTariff()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div
                  class="text-center row"
                  v-if="showEditModal"
                >
                  <div class="col-md-7 text-right loader-div">
                    <span v-if="myloader">Please wait ....</span>
                  </div>
                  <div class="col-md-2 left loader-div-gif">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="myloader"
                    />
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-space btn-fill btn-outline-info float-right"
                      @click="closeModal()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Close
                    </button>
                    <button
                      class="btn btn-primary btn-space float-right"
                      data-dismiss="modal"
                      @click="editTariffItem()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </modal>
    </card>
  </div>
</template>

<script>
import API_REVENUE from '../../../api/rev'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { ListSelect } from 'vue-search-select';
import {TARIFF_TYPE} from "../../../mixins/constants";

export default {
  name: 'TariffItem',

  components: {
    ListSelect,
    PulseLoader,
    Modal
  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.loadItems()
  },

  data () {
    return {
      color: 'green',
      size: '15px',
      loading: true,

      getTariffItems: [],
      finalUserPermissions: [],
      showModal: false,
      showEditModal: false,
      showNewModal: false,
      selectedCustomerType:{},
      disableButton: false,
      showSection: true,

      tariffeeItemData: {
        remarks: '',
        tariffItemName: '',
        tariffItemUnit: '',
        tariffItemId: '',
        gepgGfsCode: '',
        tasacGfsCode: '',
        tariffFlag: ''
      },

      errorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      myloader: false,
      customerTypes: TARIFF_TYPE,

      isTariffItemNameValid: true,
      isTariffItemUnitValid: true,
      isTariffRemarksValid: true,
      isTariffGFSValid: true,
      isTariffTasacGFSValid: true

    }
  },

  methods: {

    toggle () {
      this.showSection = !this.showSection
    },

    loadItems () {
      API_REVENUE.getTariffItems().then((response) => {
        this.getTariffItems = response.data.data
      })
    },

    onChange (event, data) {
      if (data === 'SELECTED-TARIFF-FEE') {

      }
    },

    openModal (modal, data = null) {
      this.showModal = true;
      this.isTariffRemarksValid = true;
      this.isTariffItemUnitValid = true;
      this.isTariffItemNameValid = true;

      if (modal === 'EDIT_TARIFF_ITEM') {
        this.showEditModal = true;
        this.tariffeeItemData.tariffItemName = data.tariffItemName;
        this.tariffeeItemData.remarks = data.remarks;
        this.tariffeeItemData.tariffItemUnit = data.tariffItemUnit;
        this.tariffeeItemData.tariffItemId = data.tariffItemId;
        this.tariffeeItemData.gepgGfsCode = data.gepgGfsCode;
        this.tariffeeItemData.tasacGfsCode = data.tasacGfsCode;

        if (data.tariffFlag !== null) {
          const newArray = TARIFF_TYPE.filter(function (el) {
            return el.id === data.tariffFlag
          });
          this.selectedCustomerType = newArray[0];
        }
        //this.tariffeeItemData.tariffFlag = data.tariffFlag;
      }

      if (modal === 'NEW_TARIFF_ITEM') {
        this.showNewModal = true
      }
    },

    selectedCustomer(data) {
      this.tariffeeItemData.tariffFlag = this.selectedCustomerType.id;
      this.selectedCustomerType = data;
    },

    closeModal () {
      this.showModal = false;
      this.showEditModal = false;
      this.showNewModal = false;
      this.tariffeeItemData.tariffItemName = '';
      this.tariffeeItemData.remarks = '';
      this.tariffeeItemData.tariffItemUnit = '';
      this.tariffeeItemData.gepgGfsCode = '';
      this.tariffeeItemData.tasacGfsCode = ''
    },

    saveItemTariff () {
      if (
        this.tariffeeItemData.remarks !== '' &&
          this.tariffeeItemData.tariffItemUnit !== '' &&
          this.tariffeeItemData.tariffItemName !== '' &&
          this.tariffeeItemData.gepgGfsCode !== '' &&
          this.tariffeeItemData.tasacGfsCode !== ''
      ) {
        this.myloader = true;
        if (this.selectedCustomerType !== undefined) {
          this.tariffeeItemData.tariffFlag = this.selectedCustomerType.id;
        }
        API_REVENUE.saveTariffItems(this.tariffeeItemData).then(() => {
          this.ErrorMessage = '';
          this.SuccessAlert = true;
          this.ErrorAlert = false;
          this.SuccessMessage = ' Record Added';
          this.myloader = false;
          this.disableButton = false;

          this.isTariffRemarksValid = true;
          this.isTariffItemUnitValid = true;
          this.isTariffItemNameValid = true;
          this.isTariffGFSValid = true;
          this.isTariffTasacGFSValid = true;

          this.tariffeeItemData.tariffItemUnit = '';
          this.tariffeeItemData.remarks = '';
          this.tariffeeItemData.tariffItemName = '';

          this.loadItems();

          setTimeout(() => {
            this.closeModal()
          }, 700)
        }).catch((error) => {
          this.ErrorMessage = 'Record Not Added';
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = '';
          this.myloader = false;
          this.disableButton = false;
          this.myloader = false
        })
      } else {
        this.tariffeeItemData.tariffItemName === '' ? this.isTariffItemNameValid = false : this.isTariffItemNameValid = true;
        this.tariffeeItemData.tariffItemUnit === '' ? this.isTariffItemUnitValid = false : this.isTariffItemNameValid = true;
        this.tariffeeItemData.remarks === '' ? this.isTariffRemarksValid = false : this.isTariffRemarksValid = true;
        this.tariffeeItemData.gepgGfsCode === '' ? this.isTariffGFSValid = false : this.isTariffGFSValid = true;
        this.tariffeeItemData.tasacGfsCode === '' ? this.isTariffTasacGFSValid = false : this.isTariffTasacGFSValid = true
      }
    },
    editTariffItem () {
      if (
        this.tariffeeItemData.remarks !== '' &&
          this.tariffeeItemData.tariffItemUnit !== '' &&
          this.tariffeeItemData.tariffItemName !== '' &&
          this.tariffeeItemData.gepgGfsCode !== '' &&
          this.tariffeeItemData.tasacGfsCode !== ''
      ) {
        this.myloader = true;
        this.tariffeeItemData.tariffFlag = this.selectedCustomerType.id;
        API_REVENUE.editTariffItems(this.tariffeeItemData).then(() => {
          this.myloader = false;
          this.isTariffRemarksValid = true;
          this.isTariffItemUnitValid = true;
          this.isTariffItemNameValid = true;
          this.isTariffGFSValid = true;
          this.isTariffTasacGFSValid = true;

          this.loadItems();

          setTimeout(() => {
            this.closeModal()
          }, 700)
        }).catch((err) => {
          this.myloader = false
        })
      } else {
        this.tariffeeItemData.tariffItemName === '' ? this.isTariffItemNameValid = false : this.isTariffItemNameValid = true;
        this.tariffeeItemData.tariffItemUnit === '' ? this.isTariffItemUnitValid = false : this.isTariffItemNameValid = true;
        this.tariffeeItemData.remarks === '' ? this.isTariffRemarksValid = false : this.isTariffRemarksValid = true;
        this.tariffeeItemData.gepgGfsCode === '' ? this.isTariffGFSValid = false : this.isTariffGFSValid = true;
        this.tariffeeItemData.tasacGfsCode === '' ? this.isTariffTasacGFSValid = false : this.isTariffTasacGFSValid = true
      }
    }
  }
}
</script>

<style scoped>
  .btn-space {
    margin-left: 10px;
  }
</style>
