<template>
    <div>
        <card v-if="thisFirstRequestLoader">
            <div class="row">
                <div class="col-md-12 text-center loading-text-color">
                <b>Please wait while system is loading application details</b>
                </div>
                <div class="col-md-12 text-center">
                <br>
                </div>
                <div class="col-md-12 text-center">
                    <pulse-loader
                        :color="color"
                        :size="size"
                    />
                </div>
            </div>
        </card>
        <card v-if="!thisFirstRequestLoader && singleRequest.requestAttachDtos !== undefined">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-10">
                        Attach document(s) for supporting application with BL/Airway Bill/RCN
                        [ <b>{{ singleRequest.blNumber }}</b> ]
                        </div>
                        <div class="col-md-2 text-right">
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <hr />
                </div>
                <div class="col-md-12" v-if="singleRequest.requestAttachDtos.length > 0">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col"> # </th>
                                <th scope="col">Attachment Type</th>
                                <th scope="col">Download url</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr :key="index" v-for="(attachment , index) in singleRequest.requestAttachDtos">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ attachment.attachmentName }}</td>
                            <td class="text-primary">
                            <a href=""><span class="text-success" @click.prevent="openPdfViewer(attachment.attachmentUrl , attachment.attachmentName, 'view')">view</span></a>
                            </td>
                            <td class="text-success">Uploaded</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12 text-center" v-else>
                    No attachment found
                </div>
                <div class="col-md-12" v-if="singleRequest.requestAttachDtos.length > 0">
                    <hr />
                </div>
                <div class="col-md-12" v-if="singleRequest.requestAttachDtos.length <= 0">
                    <br />
                </div>
                <div class="col-md-12">
                    <table class="table table-striped" v-if="documentToAttachArray.length > 0">
                        <thead>
                            <tr>
                                <th scope="col"> # </th>
                                <th scope="col">Attachment Type</th>
                                <th scope="col">Download url</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr :key="tempo_index" v-for="(attachment , tempo_index) in documentToAttachArray">
                            <th scope="row">{{ singleRequest.requestAttachDtos.length + (tempo_index + 1) }}</th>
                            <td>{{ attachment.document.attachmentTypesName }}</td>
                            <td class="text-primary">
                            <a href=""><span class="text-success">view</span></a>
                            </td>
                            <td class="text-danger">Not Uploaded yet</td>
                            <td>
                                <div @click.prevent="deleteAttachment(attachment)"
                                class="all-fa-icons fa-icons-space delete-fa-icon" title="remove">
                                    <font-awesome-icon icon="trash-alt" />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12">
                    <form enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-md-7">
                            <label>Search attachment type</label>

                            <multiselect v-model="selectedDocumentId" 
                                :options="options"
                                :close-on-select="true" 
                                :clear-on-select="false" 
                                :preserve-search="true"
                                :disabled="options.length <= 0"
                                placeholder="Select attachment type" 
                                label="attachmentTypesName" 
                                track-by="attachmentTypeId" 
                                :preselect-first="false"
                            >

                            </multiselect>

                            <div v-if="documentTypeIdErrorMany">
                                <div class="text-danger">
                                Select document type
                                </div>
                            </div>
                            <div v-if="pdfFormatError && fileData" class="text-danger">
                                Attach pdf document only
                            </div>
                            </div>
                            <div class="col-md-5" style="margin-top:25px;">
                                <div class="upload-btn-wrapper">
                                    <button class="btn btn-info btn-fill" :disabled="isUploadingAttachment">Choose Document..</button>
                                    <input type="file" name="cfa-attachment" id="cfa-attachment" 
                                    @change.prevent="saveTemporaryAttachment($event)" />
                                </div>
                                <label
                                    for="first_upload2"
                                    class="label-upload mt-2"
                                    v-if="uploadOneByOne"
                                >
                                    {{ file ? 'System is uploading file:  ' + file : 'No File Selected' }} </label>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-3">
                    <hr />
                </div>
                <div class="col-md-10 text-right">
                    <pulse-loader
                        :color="color"
                        :size="size"
                        :loading="isUploadingAttachment"
                        style="margin-top: 12px"
                    />
                </div>
                <div class="col-md-2 text-right">
                    <button
                        class="btn btn-primary btn-space custom-btn mr-2"
                        :disabled="isUploadingAttachment"
                        data-dismiss="modal"
                        @click.prevent="saveAmendment">
                        Submit
                    </button>
                    <button class="btn btn-default btn-fill btn-space custom-btn-all" @click.prevent="goBack" :disabled="isUploadingAttachment">
                        Back
                    </button>
                    
                </div>
            </div>
        </card>
        <global-modal v-if="globalLoader">
        <div slot="globalLoader">
            <div class="row text-center">
            <div class="col-md-12">
                Preparing requested file, Please wait
            </div>
            <div class="col-md-12">
                <br>
            </div>
            <div class="col-md-12">
                <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                />
            </div>
            </div>
        </div>
        </global-modal>
        <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer"
        @downloadPdf="openPdfViewer(attachmentUrlForDownload, attachmentNameForDownload + '_FOR_APPLICATION_WITH_FILE_NUMBER_' + singleRequest.fileNo)"></pdf-viewer>
    </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import API from '../../../api'
import Multiselect from 'vue-multiselect'
import GlobalModal from '../../../components/globalLoader'
import PdfViewer from '../../../components/pdfViewer.vue'

export default {

    name: 'additionalAttachmentToApplication',

    components: {
        
        PulseLoader,
        Multiselect,
        GlobalModal,
        PdfViewer

    },

    created () {

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

        if(this.$route.params.requestId) {

            this.loadThisRequestDetails(this.$route.params.requestId).then(() => {

                this.loadDocumentType()

            }).catch(() => {})

        }

    },

    computed:{

        ...mapGetters('global' , [

            'getGlobalLoader'

        ])

    },

    data () {

        return {

            color: 'green',
            size: '13px',
            size1: '9px',
            loading: true,

            singleRequest: {},
            file: "",
            thisFirstRequestLoader: false,
            fileData: '',

            isUploadingAttachment: false,
            uploadOneByOne: false,
            options: [],
            documentTypeId: '',
            documentTypeIdErrorMany: false,
            pdfFormatError: false,
            globalLoader: false,
            finalUserPermissions: [],
            documentToAttachArray: [],
            selectedDocumentId: {},
            attachmentTypeIds: [],
            showPdfViewer: false,
            attachmentNameForDownload: '',
            attachmentUrlForDownload: '',

        }

    },

    methods: {

        ...mapActions("global", [

            'loadFileFromMinio'

        ]),

        loadThisRequestDetails (data) {

            return new Promise((resolve, reject) => {

                this.thisFirstRequestLoader = true;

                API.getFullRequestDetails(data).then(
                    response => {

                        if (response.data.status) {

                            this.singleRequest = response.data.data
                        
                            for (let i = 0; i < this.singleRequest.requestAttachDtos.length; i++) {

                                this.attachmentTypeIds.push(this.singleRequest.requestAttachDtos[i].attachmentTypeId)
                                
                            }

                            this.thisFirstRequestLoader = false

                            resolve()

                        } else {

                            this.thisFirstRequestLoader = false
                            this.loadNotification('error', 4000, 'Application Details',
                            'Application details not loaded, please contact system admin',
                            'response', 1000 , true  , true)

                            reject()

                        }

                    }
                ).catch(() => {

                    this.thisFirstRequestLoader = false

                })

            })

        },

        loadParameters (fileUrl, fileName) {
        
            return new Promise((resolve) => {

                this.$store.dispatch('global/fileUrling', fileUrl)
                this.$store.dispatch('global/fileNaming', fileName)

                resolve()
            })

        },

        openPdfViewer (fileUrl, fileName, action) {

            this.attachmentNameForDownload = fileName
            this.attachmentUrlForDownload = fileUrl

            this.getFileFromMinio(fileUrl, fileName, action).then(() => {

                this.showPdfViewer = true

            }).catch(() => {})

        },

        closePdfViewer () {

            this.showPdfViewer = false
            this.attachmentNameForDownload = ''
            this.attachmentUrlForDownload = ''
            this.scr = ''

        },

        getFileFromMinio (fileUrl, fileName, action) {

            return new Promise((resolve, reject) => {

                this.loadParameters(fileUrl, fileName).then(() => {

                this.loadFileFromMinio({action}).then((res) => {

                    if(res[1].status === 204) {

                        this.loadNotification('warn', 4000, '',
                        'File not found, please contact system admin',
                        'response', 1000 , true  , true)

                    } else {

                        // this.loadNotification('success', 4000, '',
                        // 'File has been successfully loaded, please have a look at it',
                        // 'response', 1000 , true  , true)
                        this.scr = res[0]
                        resolve()


                    }

                        this.globalLoader = this.getGlobalLoader

                    }).catch((error) => {

                    // this.loadNotification('error', 4000, '',
                    //     'File not loaded, please contact system admin',
                    //     'response', 1000 , true  , true)

                    this.globalLoader = false

                    })

                    this.globalLoader = this.getGlobalLoader

                }).catch(() => {

                    this.globalLoader = false

                })

            })

        },

        // getFileFromMinio (fileUrl, fileName) {

        //     this.globalLoader = this.getGlobalLoader

        //     this.loadParameters(fileUrl, fileName).then(() => {

        //     this.loadFileFromMinio({}).then((res) => {

        //         if(res.status === 204) {

        //             this.loadNotification('warn', 4000, 'File Download (click to close)',
        //             'File not found, please contact system admin',
        //             'response', 1000 , true  , true)

        //         } else {

        //             this.loadNotification('success', 4000, 'File Download (click to close)',
        //             'File has been successfully downloaded, please have a look at it',
        //             'response', 1000 , true  , true)


        //         }

        //             this.globalLoader = this.getGlobalLoader
                
        //         }).catch(() => {

        //         this.loadNotification('error', 4000, 'File Download (click to close)',
        //             'File not downloaded, please check your internet connection',
        //             'response', 1000 , true  , true)

        //         this.globalLoader = this.getGlobalLoader

        //         })

        //         this.globalLoader = this.getGlobalLoader

        //     }).catch(() => {

        //     })

        // },

        loadDocumentType() {

            API.getAttachments().then(response => {

                if (response.data.status) {

                    for (let i = 0; i < response.data.data.length; i++) {

                        if (response.data.data[i].attachmentGroupCode !== 'CRA') {

                            if(this.attachmentTypeIds.includes(response.data.data[i].attachmentTypeId)) {

                                let optionObject = {

                                    attachmentTypesName:response.data.data[i].attachmentTypesName + '     <<< UPLOADED >>>',
                                    attachmentTypeId:response.data.data[i].attachmentTypeId
                                }

                                this.options.push(optionObject)

                            } else {

                                let optionObject = {

                                    attachmentTypesName:response.data.data[i].attachmentTypesName,
                                    attachmentTypeId:response.data.data[i].attachmentTypeId
                                }

                                this.options.push(optionObject)

                            }

                        }

                    }

                } else {}

            }).catch(() => {});

        },

        saveTemporaryAttachment (event) {

            this.fileData = event.target.files[0]

            if (this.documentTypeId === undefined || this.fileData.type !== 'application/pdf') {
                            
                    if (this.documentTypeId === undefined) {

                        this.documentTypeIdErrorMany = true

                    } else {

                        this.documentTypeIdErrorMany = true

                    }

                    if (this.fileData.type !== 'application/pdf') {

                        this.pdfFormatError = true

                    } else {

                        this.pdfFormatError = false

                    }

            } else {

                this.uploadOneByOne = true

                this.fileData.name

                const formData = new FormData()

                formData.append('file', this.fileData)
                formData.append('requestId', this.singleRequest.requestId)
                formData.append('attachmentTypeId', this.selectedDocumentId.attachmentTypeId)

                this.documentToAttachArray.push({file: this.fileData, requestId: this.singleRequest.requestId, document: this.selectedDocumentId, formData: formData})

                this.fileData = ''
                this.documentTypeId = ''
                this.file = ''
                event = ''
                this.selectedDocumentId = {}

            }
        },

        verifyThisAttachment (data) {

            API.verifyAttachedAttachment(data).then(() => {

            }).catch((error) => {})

        },

        saveAmendment () {

            this.isUploadingAttachment = true

            for (let i = 0; i < this.documentToAttachArray.length; i++) {

                API.uploadRequestAttachment(this.documentToAttachArray[i].formData).then(
                    response => {
                        if (response.data.status) {

                            this.documentTypeId = ''
                            this.documentTypeIdErrorMany = false
                            this.file = ''
                            this.isUploadingAttachment = false
                            if(this.finalUserPermissions.includes('CLEARINGFORWARDING_VERIFY-ATTACHMENT_{REQUESTATTACHMENTID}_PUT')) {

                                this.verifyThisAttachment(response.data.data.requestAttachmentId)

                            }
                            this.loadThisRequestDetails(this.singleRequest.requestId)

                        } else {

                            this.isUploadingAttachment = false

                        }
                    }
                    ).catch(() => {
                    
                        this.isUploadingAttachment = false

                    })
                
            }
            this.documentToAttachArray = []

        },

        deleteAttachment (data) {

            this.documentToAttachArray.splice(this.documentToAttachArray.indexOf(data), 1)

        },

        goBack () {

            if (window.$cookies.isKey('browser-local-cashed-url-back-11')) {
                this.$router.push(window.$cookies.get('browser-local-cashed-url-back-11'))

                window.$cookies.remove('browser-local-cashed-url-back-11')
            }

        },

    }
    
}

</script>

<style scoped>

.delete-fa-icon {
    color:red;
}

.delete-fa-icon:hover {
    cursor: pointer;
}

.legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 2rem;
    border-radius: 3px;
    margin-top: 2rem;
}

.legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
}

.remove-bl {

    margin-top: 142px;
    color: red;

}

.minus-bl:hover, .plus-bl:hover {

    cursor: pointer;

}

.add-bl {

color: green;
background-color: rgba(128, 128, 128, .1);
padding-right: 5px;
border-radius: 3px;

}

.minus-bl-row {

background-color: rgba(128, 128, 128, .1);
border-radius: 3px;

}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

.upload-btn-wrapper {
position: relative;
overflow: hidden;
display: inline-block;
}

.upload-btn-wrapper input[type=file]:hover {
cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
font-size: 100px;
position: absolute;
left: 0;
top: 0;
opacity: 0;
}

.label-upload {
position: absolute;
top: 9px;
left: 200px
}

</style>