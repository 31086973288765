<template>
    <div>
        <card>
            <div class="row">
                <div class="col-md-4 text-center" v-if="typeof responseCFA.importData !== 'undefined' && responseCFA.exportData !== 'undefined'">
                    <div class="row">
                        <div class="col-md-12"><h4>Total Revenue (Tsh)</h4></div>
                        <div class="col-md-12"><h5><b>{{ putComma(totalRevenue) }}</b></h5></div>
                    </div>
                </div>
                <div class="col-md-4 text-center" v-if="typeof responseCFA.importData !== 'undefined' && responseCFA.exportData !== 'undefined'">
                    <div class="row">
                        <div class="col-md-12"><h4>Total CFA Applications </h4></div>
                        <div class="col-md-12"><h5><b>{{ (responseCFA.importData.total + responseCFA.exportData.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</b></h5></div>
                    </div>
                </div>
                <div class="col-md-4 text-center" v-if="typeof responseCFA.importData !== 'undefined' && responseCFA.exportData !== 'undefined'">
                    <div class="row">
                        <div class="col-md-12"><h4>Total Documents Controlled</h4></div>
                        <div class="col-md-12"><h5><b>{{ putComma(dcTotal, 0) }}</b></h5></div>
                    </div>
                </div>
                <div class="col-md-4 text-center">
                    <g-chart
                    v-if="!revenueTotalDashboardLoader"
                    type="PieChart"
                    :options="optionsRevenue"
                    :data="chartDataRevenue"
                    />
                    <span v-else>
                        <pulse-loader :color="color" :size="size1" class="mr-4 pt-1"/>
                            Loading chart data, please wait
                    </span>
                </div>
                <div class="col-md-4 text-center" v-if="typeof responseCFA.importData !== 'undefined' && responseCFA.exportData !== 'undefined'" style="border-left: 1px solid #DCDCDC;">
                    <g-chart
                    type="PieChart"
                    v-if="!cfaTotalLoader"
                    :options="optionsCFA"
                    :data="chartDataCFA"
                    />
                    <span v-else>
                        <pulse-loader :color="color" :size="size1" class="mr-4 pt-1"/>
                            Loading chart data, please wait
                    </span>
                </div>
                <div class="col-md-4 text-center" v-if="typeof responseCFA.importData !== 'undefined' && responseCFA.exportData !== 'undefined'" style="border-left: 1px solid #DCDCDC;">
                    <g-chart
                    type="PieChart"
                    v-if="!dcTotalLoader"
                    :options="optionsDC"
                    :data="chartDataDC"
                    />
                    <span v-else>
                        <pulse-loader :color="color" :size="size1" class="mr-4 pt-1"/>
                            Loading chart data, please wait
                    </span>
                </div>
            </div>
        </card>
        <card>
            <div class="row">
                <div class="col-md-12 text-center">
                    <h4>Revenue Trend for system life time will appear here</h4>
                </div>
                <div class="col-md-12" v-if="typeof responseRevenueTrend.totalMonthly !== 'undefined' && responseRevenueTrend.totalMonthly.length > 0">
                    <g-chart
                        type="ColumnChart"
                        :data="chartDataRevenueTrend"
                        :options="optionsRevenueTrend"
                    />
                </div>
            </div>
        </card>
        <card>
            <div class="row">
                <div class="col-md-12 text-center">
                    <h4>Revenue Trend for specific year (Default is current year, select year to view it's revenue trend)</h4>
                </div>
                <div class="col-md-12" v-if="typeof responseRevenueTrend.totalMonthly !== 'undefined' && responseRevenueTrend.totalMonthly.length > 0">
                    <g-chart
                        type="ColumnChart"
                        :data="chartDataRevenueTrend"
                        :options="optionsRevenueTrend"
                    />
                </div>
                <div class="col-md-4 text-center"></div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="typo__label" v-if="!revenueTrendDashboardLoader">Search Year</label>
                        <label class="typo__label" v-else>
                            <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                            Loading chart data, please wait
                        </label>
                        <model-list-select
                        :list="revenueTrendYears"
                        v-model="revenueTrendYear"
                        class="form-control"
                        option-value="value"
                        option-text="text"
                        placeholder="Search year"
                        :isDisabled="revenueTrendDashboardLoader"
                        @input="getRevenueTrendDashboardData"
                        />
                    </div>
                </div>
                <div class="col-md-4 text-center"></div>
            </div>
        </card>
    </div>
</template>

<script>

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { GChart } from "vue-google-charts";
import API_CFA from '../../api/cfa'
import API_REVENUE from '../../api/rev'
import API_DC from '../../api/dc'
import { ModelListSelect } from 'vue-search-select'

export default {

    components: {

        PulseLoader,
        GChart,
        ModelListSelect,

    },

    created () {

        this.getCfaDashboardData()
        this.getRevenueTrendDashboardData()
        this.getRevenueTotalDashboardData()
        this.getDcDashboardData()
        this.revenueTrendYears = this.autoYearGenerator(2019)
        this.$store.dispatch('pagenames/currentPageName', 'Dashboard');

    },

    data () {

        return {

            color: 'green',
            size: '12px',
            size1: '9px',

            revenueTrendYears: [],
            revenueTrendYear: new Date().getFullYear(),

            chartDataCFA: [],
            optionsCFA: {},
            responseCFA: {},
            noContentCFA: false,

            chartDataRevenue: [],
            optionsRevenue: {},
            responseRevenue: {},
            totalRevenue: 0,
            noContentRevenue: false,

            chartDataDC: [],
            optionsDC: {},
            responseDC: {},
            dcTotal: 0,
            noContentDC: false,

            chartDataRevenueTrend: [],
            chartDataHeaderRevenueTrend: ["Year", "CFA", "DC", "AGENCY", "TALLY"],
            optionsRevenueTrend: {},
            responseRevenueTrend: {},
            noContentRevenueTrend: false,

            revenueTrendDashboardLoader: false,
            revenueTotalDashboardLoader: false,
            dcTotalLoader: false,
            cfaTotalLoader: false

        }

    },

    methods: {

        getCfaDashboardData () {

            this.cfaTotalLoader = true

            API_CFA.getTotalCfaApplicationForDashboard().then(responce => {

                if (responce.data.status) {

                    this.responseCFA = responce.data.data
                    this.cfaTotalLoader = false
                    this.noContentRevenueTrend = false

                    this.chartDataCFA.push(

                        ['Module', 'Total'],
                        [typeof this.responseCFA.importData !== 'undefined' ? 'IMPORT ( ' + this.responseCFA.importData.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' : 'IMPORT',
                        typeof this.responseCFA.importData !== 'undefined' ? this.responseCFA.importData.total : ''],

                        [typeof this.responseCFA.exportData !== 'undefined' ? 'EXPORT ( ' + this.responseCFA.exportData.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' )' : 'EXPORT', 
                        typeof this.responseCFA.exportData !== 'undefined' ? this.responseCFA.exportData.total : '']

                    )

                    this.optionsCFA = {

                        width: 520,
                        height: 400,
                        is3D: false,
                        colors: ['#2e65d4', '#d59a18'],
                        legend: {
                            position: 'bottom', textStyle: {color: 'black', fontSize: 10}
                        },

                    }

                } else {

                    this.cfaTotalLoader = false
                    this.noContentCFA = true

                }

            }).catch(() => {
                
                this.cfaTotalLoader = false
                this.noContentCFA = true
                
            })

        },

        getDcDashboardData () {

            this.dcTotalLoader = true

            API_DC.getTotalDcApplicationForDashboard().then(responce => {

                if (responce.data.status) {

                    this.responseDC = responce.data.data
                    this.dcTotalLoader = false

                    this.chartDataDC = [
                        ['Module', 'Total'],
                        ['IMPORT ( ' + this.putComma(this.responseDC.importDocuments, 0) + ' )', this.responseDC.importDocuments],
                        ['EXPORT ( ' + this.putComma(this.responseDC.exportDocuments, 0) + ' )', this.responseDC.exportDocuments],
                    ]
                    this.dcTotal = this.responseDC.totalDocuments
                    this.optionsDC = {
                        width: 520,
                        height: 400,
                        is3D: false,
                        colors: ['#800080', '#008080'],
                        legend: {
                            position: 'bottom', textStyle: {color: 'black', fontSize: 10}
                        },
                    }

                } else {

                    this.dcTotalLoader = false

                }

            }).catch(() => {
                
                this.dcTotalLoader = false
                
            })

        },

        getRevenueTrendDashboardData () {

            this.revenueTrendDashboardLoader = true

            API_REVENUE.getRevenueTrendPerYearForDashboard(this.revenueTrendYear).then(responce => {

                if (responce.data.status) {

                    this.responseRevenueTrend = responce.data.data
                    this.revenueTrendDashboardLoader = false

                    this.chartDataRevenue = [
                        ['Module', 'Total'],
                        ['CFA ( ' + this.putComma(this.responseRevenueTrend.total.cfaTotal) + ' )', this.responseRevenueTrend.total.cfaTotal],
                        ['DC ( ' + this.putComma(this.responseRevenueTrend.total.dcTotal) + ' )', this.responseRevenueTrend.total.dcTotal],
                        ['AGENCY ( ' + this.putComma(this.responseRevenueTrend.total.shipAgencyTotal) + ' )', this.responseRevenueTrend.total.shipAgencyTotal],
                        ['TALLY ( ' + this.putComma(this.responseRevenueTrend.total.tallyTotal) + ' )', this.responseRevenueTrend.total.tallyTotal]
                    ]
                    this.totalRevenue = this.responseRevenueTrend.total.cfaTotal + this.responseRevenueTrend.total.dcTotal + this.responseRevenueTrend.total.shipAgencyTotal + this.responseRevenueTrend.total.tallyTotal
                    this.optionsRevenue = {
                        width: 520,
                        height: 400,
                        is3D: false,
                        colors: ['#2e65d4', '#d59a18', '#800080', '#008080'],
                        legend: {
                            position: 'bottom', textStyle: {color: 'black', fontSize: 10}
                        },
                    }

                    let object = []
                    this.chartDataRevenueTrend = []
                    this.chartDataRevenueTrend.push(this.chartDataHeaderRevenueTrend)
                    for (let i = 0; i < this.responseRevenueTrend.totalMonthly.length; i++) {

                        object = [
                            this.responseRevenueTrend.totalMonthly[i].yearMonth.split('-')[0] + '-' + this.responseRevenueTrend.totalMonthly[i].yearMonth.split('-')[1],
                            this.responseRevenueTrend.totalMonthly[i].cfaTotal,
                            this.responseRevenueTrend.totalMonthly[i].dcTotal,
                            this.responseRevenueTrend.totalMonthly[i].shipAgencyTotal,
                            this.responseRevenueTrend.totalMonthly[i].tallyTotal
                        ]

                        this.chartDataRevenueTrend.push(object)

                        
                    }

                    this.optionsRevenueTrend = {

                        animation: {
                        duration: 1000,
                        easing: "out"
                        },
                        height: 600,
                        legend: {
                            position: 'bottom', textStyle: {color: 'black', fontSize: 10}
                        }

                    }

                } else {

                    this.revenueTrendDashboardLoader = false

                }

            }).catch(() => {
                
                this.revenueTrendDashboardLoader = false
                
            })

        },

        getRevenueTotalDashboardData () {

            this.revenueTotalDashboardLoader = true

            API_REVENUE.getRevenueTrendPerYearForDashboard(this.revenueTrendYear).then(responce => {

                if (responce.data.status) {

                    this.responseRevenueTrend = responce.data.data
                    this.revenueTotalDashboardLoader = false
                    this.noContentCFA = false

                    this.chartDataRevenue = [
                        ['Module', 'Total'],
                        ['CFA ( ' + this.putComma(this.responseRevenueTrend.total.cfaTotal) + ' )', this.responseRevenueTrend.total.cfaTotal],
                        ['DC ( ' + this.putComma(this.responseRevenueTrend.total.dcTotal) + ' )', this.responseRevenueTrend.total.dcTotal],
                        ['AGENCY ( ' + this.putComma(this.responseRevenueTrend.total.shipAgencyTotal) + ' )', this.responseRevenueTrend.total.shipAgencyTotal],
                        ['TALLY ( ' + this.putComma(this.responseRevenueTrend.total.tallyTotal) + ' )', this.responseRevenueTrend.total.tallyTotal]
                    ]
                    this.totalRevenue = this.responseRevenueTrend.total.cfaTotal + this.responseRevenueTrend.total.dcTotal + this.responseRevenueTrend.total.shipAgencyTotal + this.responseRevenueTrend.total.tallyTotal
                    this.optionsRevenue = {
                        width: 520,
                        height: 400,
                        is3D: false,
                        colors: ['#2e65d4', '#d59a18', '#800080', '#008080'],
                        legend: {
                            position: 'bottom', textStyle: {color: 'black', fontSize: 10}
                        },
                    }

                } else {

                    this.revenueTotalDashboardLoader = false

                }

            }).catch(() => {
                
                this.revenueTotalDashboardLoader = false
                
            })

        }

    }
    
}
</script>

<style scoped>

</style>