<template>
  <div>
    <div
      class="row"
      v-if="myFirstLoader"
    >
      <div class="col-md-12 text-center">
        <b>{{ loadingTitle }}</b>
      </div>
      <div class="col-md-12 text-center">
        <br>
      </div>
      <div class="col-md-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
        />
      </div>
    </div>
    <div
      class="row"
      v-if="!myFirstLoader"
    >
      <div
        class="col-md-6 noprint"
        id="entries"
      >
        <div class="row">
          <div class="col-md-3 text-left">
            Entries per page
          </div>

          <div class="col-md-2 float-left">
            <select
              name="PageSize"
              @change="onChange()"
              class=""
              v-model="pageSize"
              style="width:73px;"
              :class="[ (pageNo == 0 && pageCount - 1 < 0)
                || (pageCount - 1 == pageNo && pageCount - 1 < 0) ? 'disabled-click' : 'enabled-click']"
            >
              <option value="15">
                15
              </option>
              <option value="25">
                25
              </option>
              <option value="50">
                50
              </option>
              <option value="75">
                75
              </option>
              <option value="100">
                100
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="col-md-6 noprint"
        id="queryDate"
      >
        <div class="row">
          <div
            class="col-md-7 text-right"
            v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SEARCH-FLAG_{REQUESTTYPEFLAG}_POST')
              && hasDateQuery"
          >
            <form
              class="search"
              @submit.prevent="queryByDate"
            >
              <div class="form-group float-right">
                <div
                  class="input-group"
                  :class="[ pageCount == 0 && !noDataFound ?
                    'disabled-click' : 'enabled-click']"
                >
                  <div
                    class="refresh-all"
                    title="load all requests"
                    v-if="reloadAllRequest"
                    @click.prevent="reloadDateQueryByIcon"
                  >
                    <font-awesome-icon icon="sync-alt" />
                  </div>
                  <label
                    for="dateQuery"
                    style="margin-right: 4px"
                  >Search</label><input
                    placeholder="Date"
                    id="dateQuery"
                    v-model="dateParameter"
                    @input="loadDateDetails"
                    type="date"
                    pattern="\d{4}-\d{2}-\d{2}"
                  >
                  <span
                    class="input-group-addon"
                    style="margin-left:4px "
                    @click.prevent="queryByDate"
                  >
                    <font-awesome-icon icon="search" /></span>
                </div>
              </div>
            </form>
          </div>
          <div
            class="col-md-7 text-right"
            v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SEARCH-FLAG_{REQUESTTYPEFLAG}_POST')
              && !hasDateQuery"
          >
            <form
              class="search"
              @submit.prevent="searchRequest"
            >
              <div class="form-group float-right">
                <div
                  class="input-group"
                  :class="[ pageCount == 0 && !noDataFound ?
                    'disabled-click' : 'enabled-click']"
                >
                  <div
                    class="refresh-all"
                    title="load all requests"
                    v-if="reloadAllRequest"
                    @click.prevent="reloadByIcon"
                  >
                    <font-awesome-icon icon="sync-alt" />
                  </div>
                  <input
                    type="text"
                    placeholder="Search ...."
                    id="inputGroup"
                    v-model="searchParameter"
                    @input="loadAllPageable"
                  >
                  <span
                    class="input-group-addon"
                    @click.prevent="searchRequest"
                  >
                    <font-awesome-icon icon="search" /></span>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-5 text-right">
            <div class="row">
              <div class="col-md-12 disable-highlight up-pagination">
                <span
                  class="btn-space"
                  :class="[ pageNo == 0 || pageCount - 1 < 0 ?
                    'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >
                  First
                </span>
                <span
                  class="btn-space"
                  @click="prevPage"
                  :class="[ pageNo == 0 || pageCount - 1 < 0 ?
                    'disabled-click nc-icon-inner-disabled' : 'enabled-click nc-icon-inner']"
                  title="go to previous page"
                >
                  <svg-icon
                    icon="double_arrow_left"
                    :has-fill="true"
                  />
                </span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  @click="nextPage"
                  :class="[ pageCount - 1 == pageNo || pageCount - 1 < 0 ?
                    'disabled-click nc-icon-inner-disabled' : 'enabled-click nc-icon-inner']"
                  title="go to next page"
                >
                  <svg-icon
                    icon="double_arrow_right"
                    :has-fill="true"
                  />
                </span>
                <span
                  class="btn-space"
                  :class="[ pageCount - 1 == pageNo || pageCount - 1 < 0 ?
                    'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >
                  Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 table-responsive">
      <table class="table col-md-12 table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th
              v-for="(tableHeader , indexHT) in tableHeaders"
              :key="indexHT"
            >
              {{ tableHeader }}
            </th>
            <th
              class="text-center noprint"
              v-if="status !== 'NO_ACTION_BUTTON' || hasAction === true"
            >
              Action
            </th>
            <th style="display: none" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(data , indexTD) in tableData"
            :key="indexTD"
            v-if="!noDataFound"
          >
            <td>{{ indexTD + 1 +(pageNo * pageSize) }}</td>
            <td v-for="tableDataKey in tableDataKeys">
              <span v-if="tableDataKey === 'customer'">
                <span
                  class="more-customer-details"
                  @click="showToolTip(data['requestId'])"
                >
                  {{ (data[tableDataKey].name).toUpperCase() }}</span>
                <span
                  v-if="isHovered.includes(data['requestId'])"
                  class="customer-info"
                >
                  <p><u><b>Customer Information</b> (click <a
                    @click="hideToolTip(data['requestId'])"
                    class="close-info"
                  >here</a> to close)</u></p>
                  <b>TIN:</b> {{ data[tableDataKey].tin }}<br>
                  <b>VRN:</b> {{ data[tableDataKey].vrn }} <br>
                  <b>MOBILE:</b> {{ data[tableDataKey].mobile }} <br>
                  <b>EMAIL:</b> {{ data[tableDataKey].email }} <br>
                  <b>CUSTOMER TYPE:</b>
                  {{ data[tableDataKey].customerType === 'ind001' ? 'Individual' : 'company' }} <br>
                </span>
              </span>
              <span
                v-if="tableDataKey !== 'customer'&& tableDataKey !== 'grossWeight' && tableDataKey !== 'tareWeight' && tableDataKey !== 'netWeight' &&
                  tableDataKey !== 'requestDate' && tableDataKey !== 'createdAt' && tableDataKey !== 'eta' && tableDataKey !== 'updatedAt'"
              >{{ data[tableDataKey] }}</span>
              <span v-if="tableDataKey === 'requestDate'">{{ dateTimeToHuman(data[tableDataKey]) }}</span>
              <span v-if="tableDataKey === 'createdAt' || tableDataKey === 'updatedAt' || tableDataKey === 'eta'">{{ dateTimeToHuman(data[tableDataKey]) }}</span>
              <span v-if="tableDataKey === 'tareWeight' ">{{ data[tableDataKey] }} {{ data['tareWeightUnit'] }}</span>
              <span v-if="tableDataKey === 'grossWeight' ">{{ data[tableDataKey] }} {{ data['grossWeightUnit'] }}</span>
              <span v-if="tableDataKey === 'netWeight' ">{{ data[tableDataKey] }} {{ data['netWeightUnit'] }}</span>
            </td>
            <td
              class="btn-success-custom text-center noprint"
              v-if="hasAction"
            >
              <button
                class="btn btn-success mr-2 custom-btn"
                v-if="!myLoader && !data['isDuplicate'] && status !== 'SHIPPING_TALLY' && status !== 'SHIPPING_TALLY_DETAILS' && status !== 'NO_ACTION_BUTTON' && status !== 'DISBURSEMENT' &&
                  status !== 'PRINCIPAL' && status !=='PRINCIPAL_VESSEL' && finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"
                @click.prevent="goToApplication(data['requestId'])"
              >
                <b>Manage</b>
              </button>
              <button
                class="btn btn-success mr-2 custom-btn"
                v-if="!myLoader && !data['isDuplicate'] && status === 'DISBURSEMENT' &&
                  finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"
                @click.prevent="goToApplication(data['serviceCategoryId'])"
              >
                <b>Manage</b>
              </button>
              <button
                class="btn btn-success mr-2 custom-btn"
                v-if="!myLoader && !data['isDuplicate'] && status === 'PRINCIPAL' &&
                  finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"
                @click.prevent="goToApplication(data['principalParticularId'])"
              >
                <b>Manage</b>
              </button>
              <button
                class="btn btn-success mr-2 custom-btn"
                v-if="!myLoader && !data['isDuplicate'] && status === 'PRINCIPAL_VESSEL' &&
                  finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"
                @click.prevent="goToApplication(data['vesselParticularId'])"
              >
                <b>Manage</b>
              </button>
              <button
                class="btn btn-success mr-2 custom-btn"
                v-if="!myLoader && !data['isDuplicate'] && status === 'SHIPPING_TALLY' && status !== 'NO_ACTION_BUTTON' &&
                  finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"
                @click.prevent="goToApplication(data['id'])"
              >
                <b>Manage</b>
              </button>
              <button
                class="btn btn-success mr-2 custom-btn"
                v-if="!myLoader && !data['isDuplicate'] && status === 'SHIPPING_TALLY_DETAILS' && status !== 'NO_ACTION_BUTTON' &&
                  finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"
                @click.prevent="goToApplication(data['cargoType'])"
              >
                <b>Manage</b>
              </button>
              <button
                class="btn btn-success mr-2 custom-btn"
                v-if="!myLoader && hasManage && !data['isDuplicate'] &&
                  finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"
                @click.prevent="goToApplication(data)"
              >
                <b>Manage</b>
              </button><button
                class="btn btn-primary mr-2 custom-btn"
                v-if="!myLoader && hasView && !data['isDuplicate'] &&
                  finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"
                @click.prevent="goToView(data)"
              >
                <b>View</b>
              </button>
              <button
                class="btn btn-info mr-2 custom-btn"
                v-if="!myLoader && hasEdit && !data['isDuplicate'] &&
                  finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"
                @click.prevent="goToEdit(data)"
              >
                <b>Edit</b>
              </button>

              <button
                class="btn btn-danger custom-btn"
                style="width: 150px"
                @click.prevent="markAsDuplicate(data['requestId'],
                                                data['blNumber'], data['customer'].name)"
                v-if="!myLoader && !data['isDuplicate'] &&
                  finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET') &&
                  statusValue"
              >
                <b>Mark as duplicate</b>
              </button>
            </td>
            <td style="display: none" />
          </tr>
        </tbody>
      </table>
      <div
        class="col-md-12 text-center bg-grey"
        v-if="noDataFound"
      >
        No data found
      </div>
      <br>
      <div
        class="col-md-12 noprint"
        id="footer-entries"
      >
        <div class="row">
          <div class="col-md-3">
            <span v-if="tableData.length > 0">
              Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
            </span>
          </div>

          <div class="col-md-4 text-right middle" />

          <div class="col-md-5 text-right disable-highlight down-pagination">
            <span
              class="btn-space"
              :class="[ pageNo == 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              title="go to first page"
            >
              First
            </span>
            <span
              class="btn-space"
              :class="[ pageNo == 0 || pageCount - 1 < 0 ?
                'disabled-click nc-icon-inner-disabled' : 'enabled-click nc-icon-inner']"
              @click="prevPage"
              title="go to previous page"
            >
              <svg-icon
                icon="double_arrow_left"
                :has-fill="true"
              />
            </span>

            <span class="btn-space" />
            <span class="btn-space" />

            <span
              class="btn-space"
              :class="[ pageCount - 1 == pageNo || pageCount - 1 < 0 ?
                'disabled-click nc-icon-inner-disabled' : 'enabled-click nc-icon-inner']"
              @click="nextPage"
              title="go to next page"
            >
              <svg-icon
                icon="double_arrow_right"
                :has-fill="true"
              />
            </span>
            <span
              class="btn-space"
              :class="[ pageCount - 1 == pageNo || pageCount - 1 < 0 ?
                'disabled-click' : 'enabled-click']"
              @click="lastPage"
              title="go to last page"
            >
              Last
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SvgIcon from '../SvgLoader/svgLoader'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {

  name: 'Table',

  data () {
    return {

      finalUserPermissions: [],
      searchParameter: '',
      dateParameter: '',
      pageSize: 15,

      color: 'green',
      size: '12px',
      loading: true,
      statuses: [
        'ASSESSED', 'REJECTED_ASSESSMENT', 'CHARGED', 'BILLED', 'BILL_APPROVED', 'PAID', 'CLOSED',
        'SHIPPING_TALLY', 'NO_DUPLICATE_ACTION', 'SHIPPING_TALLY_DETAILS', 'NO_ACTION_BUTTON', 'DISBURSEMENT',
        'PRINCIPAL', 'PRINCIPAL_VESSEL'
      ],
      statusValue: ''

    }
  },

  props: {

    tableHeaders: {
      type: Array,
      default: []
    },
    tableData: {
      type: Array,
      default: []
    },
    tableDataKeys: {
      type: Array,
      default: []
    },
    noDataFound: {
      type: Boolean,
      default: false
    },
    status: '',
    pageNo: '',
    pageSizeProp: '',
    hasDateQuery: false,
    isHovered: {
      type: Array
    },
    myLoader: {
      type: Boolean,
      default: false
    },
    hasEdit: {
      type: Boolean,
      default: false
    },
    hasAction: {
      type: Boolean,
      default: true
    },
    hasView: {
      type: Boolean,
      default: false
    },
    hasManage: {
      type: Boolean,
      default: false
    },
    pageCount: '',
    searchParameterProp: String,
    dateParameterProp: Date,
    reloadAllRequest: '',
    myFirstLoader: {
      type: Boolean,
      default: false
    },

    loadingTitle: ''
  },

  components: {

    SvgIcon,
    PulseLoader

  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.statusValue = this.checkApplicationStatus(this.status)
    this.accessedFrom = localStorage.getItem('accessedFrom')
  },

  methods: {

    searchRequest () {
      this.$emit('searchRequest', this.searchParameter)
    },
    queryByDate () {
      this.$emit('queryByDate', this.dateParameter)
    },
    onChange () {
      this.$emit('onChange', this.pageSize)
    },

    prevPage () {
      this.$emit('prevPage')
    },

    nextPage () {
      this.$emit('nextPage')
    },

    firstPage () {
      this.$emit('firstPage')
    },

    lastPage () {
      this.$emit('lastPage')
    },

    loadAllPageable () {
      this.$emit('loadAllPageable', this.searchParameter)
    },

    loadDateDetails () {
      this.$emit('loadDateDetails', this.dateParameter)
    },

    reloadByIcon () {
      this.searchParameter = ''

      this.$emit('reloadByIcon', this.searchParameter)
    },
    reloadDateQueryByIcon () {
      this.dateParameter = ''
      this.$emit('reloadDateQueryByIcon', this.dateParameter)
    },

    showToolTip (requestId) {
      this.$emit('showToolTip', requestId)
    },

    hideToolTip (requestId) {
      this.$emit('hideToolTip', requestId)
    },

    markAsDuplicate (requestId, blNumber, customerName) {
      this.$emit('markAsDuplicate', requestId, blNumber, customerName)
    },

    goToApplication (requestId) {
      this.$emit('goToApplication', requestId)
    },
    goToEdit (editDetails) {
      this.$emit('goToEdit', editDetails)
    },
    goToView (viewDetails) {
      this.$emit('goToView', viewDetails)
    },
    checkApplicationStatus (status) {
      return !this.statuses.includes(status)
    }

  }
}
</script>

<style scoped>

    .table-hover tbody tr:hover {
        background-color: rgba(153, 186, 221, .5) !important;
        font-weight: normal !important;
    }

    tbody tr {
        color: #212529 !important;
        font-weight: 400 !important;
    }

    .table td, .table th {
        text-align: center;
    }

    .search .form-group .input-group #inputGroup {
        width: 250px;
        padding-top: 3.5px;
    }

    .search .form-group .input-group .input-group-addon:hover {
        cursor: pointer;
        background-color: rgba(220, 220, 220, .5);
    }

    .more-customer-details:hover {
        cursor: pointer;
        color: #d59a18;
    }

    .customer-info {
        position: absolute;
        bottom: 40px;
        background-color: #FFFFFF;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid #d59a18;
        text-align: left
    }

    .close-info {
        color: blue !important;
    }

    .close-info:hover {
        cursor: pointer;
        color: green !important;
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: 90px;
        background-color: #FFFFFF !important;
    }

    .btn-success-custom {
        padding: 0 4px 0 4px;
        color: #3cb371;
    }

    .btn-success-custom:hover {
        cursor: pointer;
        color: green;
    }

    .refresh-all {
        margin-top: 6px;
        margin-right: 20px
    }

    .refresh-all:hover {
        cursor: pointer;
    }

    .search .form-group .input-group .input-group-addon {
        width: 40px;
        text-align: center;
        padding-top: 3.5px;
        background-color: rgba(220, 220, 220, .3);
    }

    .search .form-group .input-group .input-group-addon:hover {
        cursor: pointer;
        background-color: rgba(220, 220, 220, .5);
    }

    .bg-grey {
        background-color: #dcdcdc;
    }

    .btn-space {
        margin-right: 10px !important;
    }

    .middle {
        padding-right: 90px !important
    }

    .disable-highlight:hover span {
        cursor: pointer;
    }

    .down-pagination {
        line-height: 5px;
        font-size: 20px;
        padding-right: 0 !important;
    }

    .up-pagination {
        font-size: 20px
    }

    .nc-icon-inner {
        font-size: 13px !important;
        fill: #000000;
    }

    .nc-icon-inner-disabled {
        font-size: 13px !important;
        fill: #dcdcdc;
    }

    .disabled-click {
        pointer-events: none;
        color: #dcdcdc;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }

    .enabled-click {
        pointer-events: auto;
    }

</style>
