<template>
    <div>
        <card v-if="thisFirstRequestLoader">
            <div class="row">
                <div class="col-md-12 text-center loading-text-color">
                <b>Please wait while system is loading application details</b>
                </div>
                <div class="col-md-12 text-center">
                <br>
                </div>
                <div class="col-md-12 text-center">
                    <pulse-loader
                        :color="color"
                        :size="size"
                    />
                </div>
            </div>
        </card>
        <card style="padding-left:20px; padding-right:20px;" v-if="!thisFirstRequestLoader">
            <form>
                <div class="row justify-content-center" v-if="singleRequest.status === applicationStatuses.SUBMITTED || singleRequest.status === applicationStatuses.DRAFT">
                    <div class="col-md-6">
                        <label for="myselect">Select application type</label>
                        <select class="form-control" id="myselect" name="myselect" v-model="requestType" @change="changeMyTittle(requestType)">
                            <option disabled value="">Select application type</option>
                            <option :value="RequestType.id" :key="index" v-for="(RequestType, index) in getRequestTypes">
                                {{ RequestType.name }}
                            </option>
                        </select>
                        <div v-if="requestTypeError">
                            <div class="text-danger" v-if="!$v.requestType.required">
                            This field is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row dynamic-bg" v-for="(row , index) in rows" :key="index">

                    <div class="col-md-12">
                        <div class="legend">
                            <h6 class="legend-title">
                                {{ myTittle + ' ( ' + (index + 1) + ' )' }}
                            </h6>

                            <div class="row" style="margin-left:5px;margin-right:5px;">
                                <div :class="[rows.length > 1 ? 'col-md-11' : 'col-md-12']">
                                    <div class="row pr-4">
                                        <div class="col-md-3">
                                            <base-input
                                                class="special-bottom"
                                                :label="myTittle"
                                                :placeholder="myTittle"
                                                type="text"
                                                v-model="row.requestName"
                                            />
                                            <div v-if="requestNameError.includes(row.entry)">
                                                <div class="text-danger">
                                                This field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 mt-1" v-if="singleRequest.status === applicationStatuses.SUBMITTED || singleRequest.status === applicationStatuses.DRAFT"> <!--  v-if="singleRequest.status === applicationStatuses.SUBMITTED" -->
                                            <label>Search Regime {{ filteredRegimes.length === 0 ? ' ( Select Application type first )' : '' }}</label>
                                            <model-list-select
                                                :list="filteredRegimes"
                                                :isDisabled="filteredRegimes.length === 0"
                                                v-model="row.requestUniqueIdentifier"
                                                class="form-control"
                                                option-value="regimeId"
                                                option-text="regimeName"
                                                placeholder="regime name"
                                            />
                                            <div v-if="requestUniqueIdentifierError.includes(row.entry)" class="mt-3">
                                                <div class="text-danger">
                                                This field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <base-input
                                                class="special-bottom"
                                                label="PO Number"
                                                placeholder="PO Number"
                                                type="text"
                                                v-model="row.poNumber"
                                            />
                                        </div>
                                        <div class="col-md-3">
                                            <base-input
                                                class="special-bottom"
                                                :label="myDate"
                                                :placeholder="myDate"
                                                type="date"
                                                v-model="row.cargoExpectedDate"
                                            />
                                            <div v-if="cargoExpectedDateError.includes(row.entry)">
                                                <div class="text-danger">
                                                This field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <label for="myselect">Select product type</label>
                                            <select class="form-control" id="myselect" name="myselect" v-model="row.productType">
                                                <option disabled value="">Select product type</option>
                                                <option :value="productType.id" :key="index" v-for="(productType, index) in productTypes">
                                                    {{ productType.name }}
                                                </option>
                                            </select>
                                            <div v-if="productTypeError.includes(row.entry)">
                                                <div class="text-danger">
                                                This field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <base-input
                                                class="special-bottom"
                                                label="Invoice Number (separate with comma if more than one)"
                                                placeholder="Invoice Number (separate with comma if more than one)"
                                                type="text"
                                                v-model="row.invoiceNumber"
                                            />
                                            <div v-if="invoiceNumberError.includes(row.entry)" style="margin-top:-16px;">
                                                <div class="text-danger">
                                                This field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label>Select tariff items</label>
                                            <multiselect v-model="row.tariffItem"
                                            :options="activeTariffItems"
                                            :multiple="true"
                                            :close-on-select="false"
                                            :clear-on-select="false"
                                            :preserve-search="true"
                                            :hide-selected="true"
                                            placeholder="Select tariff items"
                                            label="tariffItemName"
                                            track-by="tariffItemId"
                                            :preselect-first="false"
                                            :openDirection="'top'"
                                            :disabled="activeTariffItems.length <= 0"
                                            @select="getSelectedObject($event, row.entry)"
                                            @remove="removeSelectedObject($event, row.entry)"
                                            >
                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{values.length === 1 ? 'tariff item' : 'tariff items'}} selected</span>
                                                </template>
                                            </multiselect>
                                        </div>
                                        <div class="col-md-12">
                                            <label>Application Description</label>
                                            <textarea class="special-bottom form-control col-md-12" placeholder="Request Details" rows="6" type="textarea" v-model="row.description" id="textarea"/>
                                            <div v-if="descriptionError.includes(row.entry)">
                                                <div class="text-danger">
                                                    This field is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 minus-bl-row-1" v-if="rows.length > 1">
                                    <div class="row">
                                        <div class="col-md-12 text-center remove-bl" title="remove BL/Airway Bill/RCN">
                                            <button
                                            v-if="rows.length > 1"
                                            class="btn btn-danger btn-space float-right custom-btn mr-2"
                                            :disabled="sendRequestLoader"
                                            data-dismiss="modal"
                                            @click="deleteRow(row)">
                                            Remove {{ myTittle }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </card>
        <card v-if="!thisFirstRequestLoader && singleRequest.requestAttachDtos !== undefined && singleRequest.status !== 'Draft Request'">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-10">
                        Attach document(s) for supporting application with BL/Airway Bill/RCN
                        [ <b>{{ singleRequest.blNumber }}</b> ]
                        </div>
                        <div class="col-md-2 text-right">
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <hr />
                </div>
                <div class="col-md-12" v-if="singleRequest.requestAttachDtos.length > 0">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col"> # </th>
                                <th scope="col">Attachment Type</th>
                                <th scope="col">Download url</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr :key="index" v-for="(attachment , index) in singleRequest.requestAttachDtos">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ attachment.attachmentName }}</td>
                            <td class="text-primary">
                            <a href=""><span class="text-success" @click.prevent="openPdfViewer(attachment.attachmentUrl , attachment.attachmentName, 'view')">view</span></a>
                            </td>
                            <td class="text-success">Uploaded</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12 text-center" v-else>
                    No attachment found
                </div>
                <div class="col-md-12" v-if="singleRequest.requestAttachDtos.length > 0">
                    <hr />
                </div>
                <div class="col-md-12" v-if="singleRequest.requestAttachDtos.length <= 0">
                    <br />
                </div>
                <div class="col-md-12">
                    <table class="table table-striped" v-if="documentToAttachArray.length > 0">
                        <thead>
                            <tr>
                                <th scope="col"> # </th>
                                <th scope="col">Attachment Type</th>
                                <th scope="col">Download url</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr :key="tempo_index" v-for="(attachment , tempo_index) in documentToAttachArray">
                            <th scope="row">{{ singleRequest.requestAttachDtos.length + (tempo_index + 1) }}</th>
                            <td>{{ attachment.document.attachmentTypesName }}</td>
                            <td class="text-primary">
                            <a href=""><span class="text-success">view</span></a>
                            </td>
                            <td class="text-danger">Not Submitted</td>
                            <td>
                                <div @click.prevent="deleteAttachment(attachment)"
                                class="all-fa-icons fa-icons-space delete-fa-icon" title="remove">
                                    <font-awesome-icon icon="trash-alt" />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12">
                    <form enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-md-7">
                            <label>Search attachment type</label>

                            <multiselect v-model="selectedDocumentId"
                                :options="options"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :preserve-search="true"
                                :disabled="options.length <= 0"
                                placeholder="Select attachment type"
                                label="attachmentTypesName"
                                track-by="attachmentTypeId"
                                :preselect-first="false"
                            >

                            </multiselect>

                            <div v-if="documentTypeIdErrorMany">
                                <div class="text-danger">
                                Select document type
                                </div>
                            </div>
                            <div v-if="pdfFormatError && fileData" class="text-danger">
                                Attach pdf document only
                            </div>
                            </div>
                            <div class="col-md-5" style="margin-top:25px;">
                                <div class="upload-btn-wrapper">
                                    <button class="btn btn-info btn-fill" :disabled="isUploadingAttachment">Choose Document..</button>
                                    <input type="file" name="cfa-attachment" id="cfa-attachment"
                                    @change.prevent="saveTemporaryAttachment($event)" />
                                </div>
                                <label
                                    for="first_upload2"
                                    class="label-upload mt-2"
                                    v-if="uploadOneByOne"
                                >
                                    {{ file ? 'System is uploading file:  ' + file : 'No File Selected' }} </label>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </card>
        <card v-if="!thisFirstRequestLoader">
            <div class="row">
                <div class="col-md-10 text-right">
                    <pulse-loader
                        :color="color"
                        :size="size"
                        :loading="isUploadingAttachment"
                        style="margin-top: 12px"
                    />
                </div>
                <div class="col-md-2 text-right">
                    <button v-if="singleRequest.status === 'Draft Request'"
                        class="btn btn-primary btn-space custom-btn mr-2"
                        :disabled="isUploadingAttachment"
                        data-dismiss="modal"
                        @click.prevent="saveRequest">
                        Submit
                    </button>
                    <button v-if="singleRequest.status !== 'Draft Request'"
                        class="btn btn-primary btn-space custom-btn mr-2"
                        :disabled="isUploadingAttachment"
                        data-dismiss="modal"
                        @click.prevent="saveAmendment">
                        Submit
                    </button>
                    <button class="btn btn-default btn-fill btn-space custom-btn-all" @click.prevent="goBack" :disabled="isUploadingAttachment">
                        Back
                    </button>

                </div>
            </div>
        </card>
        <global-modal v-if="globalLoader">
        <div slot="globalLoader">
            <div class="row text-center">
            <div class="col-md-12">
                Preparing requested file, Please wait
            </div>
            <div class="col-md-12">
                <br>
            </div>
            <div class="col-md-12">
                <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                />
            </div>
            </div>
        </div>
        </global-modal>
        <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer"
        @downloadPdf="openPdfViewer(attachmentUrlForDownload, attachmentNameForDownload + '_FOR_APPLICATION_WITH_FILE_NUMBER_' + singleRequest.fileNo)"></pdf-viewer>
    </div>
</template>

<script>

import { CFA_APPLICATION_TYPES, PRODUCT_TYPES } from '../../../mixins/constants'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from "vuex";
import { ListSelect, ModelListSelect } from 'vue-search-select';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import API from '../../../api'
import Modal from '../../../components/Inputs/Modal'
import Multiselect from 'vue-multiselect'
import GlobalModal from '../../../components/globalLoader'
import DatePicker from 'vue2-datepicker'
import PdfViewer from '../../../components/pdfViewer.vue'
import CfaApplicationDescriptionItems from '../../../assets/files/cfaApplicationDescriptionItems.json'
import { CFA_REQUEST_STATUSE } from '../../../mixins/constants'

export default {

    name: 'TASACNewApplication',

    props: {

        requestIdArrayProp: {

            type: Array,
            default: () => []

        },

        requestBlArrayProp: {

            type: Array,
            default: () => []

        },

        ifRequestSubmittedProp: {

            type: Boolean,
            default: () => false

        }

    },

    components: {

        ListSelect,
        ModelListSelect,
        PulseLoader,
        Modal,
        Multiselect,
        GlobalModal,
        DatePicker,
        PdfViewer

    },

    created () {

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
        this.$store.dispatch('pagenames/currentPageName', 'Edit Application Datails');

        if(this.$route.params.requestId) {

            this.loadAllRegimes()
            // this.loadAllActiveTariffItems().then(() => {

                this.loadThisRequestDetails(this.$route.params.requestId).then(() => {

                    this.loadDocumentType()

                }).catch(() => {})

            // }).catch(() => {})

        }

    },

    computed:{

        ...mapGetters('global' , [

            'getAllRegimes',
            'getGlobalLoader',
            // 'getActiveTariffItems'

        ])

    },

    validations: {

        requestType: { required },
        requestName: { required },
        description: { required },
        requestUniqueIdentifier: { required }

    },

    data () {

        return {

            color: 'green',
            size: '13px',
            size1: '9px',
            loading: true,
            applicationStatuses: CFA_REQUEST_STATUSE,


            requestType: '',
            myTittle: 'BL/Airway Bill/RCN',
            myDate: 'Expected Time of Arrival (ETA)',
            getRequestTypes: CFA_APPLICATION_TYPES,
            requestUniqueIdentifier: '',
            regimes: [],
            filteredRegimes: [],
            rows: [ { entry: 1 , requestName: '', description: '', requestUniqueIdentifier: '', poNumber: '', cargoExpectedDate: '', productType: '', invoiceNumber: '', tariffItem: [], descriptionsArray: [] } ],
            selectedRegime: {},
            singleRequest: {},
            file: "",
            thisFirstRequestLoader: false,
            attachmentManyAlertMessage: '',
            fileData: '',

            requestTypeError: false,
            requestUniqueIdentifierError: [],
            cargoExpectedDateError: [],
            requestNameError: [],
            descriptionError: [],
            sendRequestLoader: false,
            ifRequestSubmitted: false,
            hasUserUploadedAnyThing: false,
            pdfFormatSingleError: false,
            loadAttachmentLoader: false,
            showConfirmManyModal: false,
            isUploadingAttachment: false,
            finalSubmitLoader: false,
            initialEntry: 1,

            requestIdArray: [],
            requestBlArray: [],
            responseAttachment: [],
            uploadOneByOne: false,
            options: [],
            documentTypeId: '',
            documentTypeIdErrorMany: false,
            pdfFormatError: false,
            globalLoader: false,
            blsToSubmit: [],
            finalUserPermissions: [],
            documentToAttachArray: [],
            selectedDocumentId: {},
            productTypeError: [],
            invoiceNumberError: [],
            productTypes: PRODUCT_TYPES,
            tariffItemCopy: [],
            activeTariffItems: CfaApplicationDescriptionItems,
            manuallyInsertedDecription: '',
            attachmentTypeIds: [],

            attachmentNameForDownload: '',
            attachmentUrlForDownload: '',
            scr: '',
            showPdfViewer: false,

        }

    },

    methods: {

        ...mapActions("global", [

            'loadRegimes',
            'loadFileFromMinio',
            // 'loadActiveTariffItems'

        ]),

        getSelectedObject(selectedObject, entry) {

            this.rows[entry - 1].tariffItem = []
            this.tariffItemCopy.push(selectedObject)
            this.rows[entry - 1].tariffItem = this.tariffItemCopy
            this.currentEntry = entry
            let description = []
            description = this.rows[entry - 1].description.split('\n')
            this.rows[entry - 1].description = ''

            for (let i = 0; i < this.rows.length; i++) {

                if (this.rows[i].entry === this.currentEntry && !this.rows[entry - 1].descriptionsArray.includes(selectedObject)) {

                    this.rows[entry - 1].descriptionsArray.push(selectedObject)
                    description.push(selectedObject.tariffItemName)
                    if (this.rows[i].description !== '' && this.rows[i].description.includes('\r\n') && this.manuallyInsertedDecription === '') {

                        this.manuallyInsertedDecription = this.rows[i].description
                        this.rows[entry - 1].descriptionsArray.push(this.manuallyInsertedDecription)

                    }

                    // return this.rows[i].description = this.rows[entry - 1].descriptionsArray.join('\n')

                }

            }

            this.rows[entry - 1].description = description.join('\n')

            selectedObject = {}


        },

        removeSelectedObject(selectedObject, entry) {

            this.rows[entry - 1].tariffItem = []
            this.tariffItemCopy.splice(this.tariffItemCopy.indexOf(selectedObject), 1)
            this.rows[entry - 1].tariffItem = this.tariffItemCopy
            this.currentEntry = entry
            let description = []
            description = this.rows[entry - 1].description.split('\n')
            this.rows[entry - 1].description = ''

            for (let i = 0; i < this.rows.length; i++) {

                if (this.rows[i].entry === this.currentEntry) {

                    this.rows[entry - 1].descriptionsArray.splice(this.rows[entry - 1].descriptionsArray.indexOf(selectedObject.tariffItemName), 1)
                    description.splice(description.indexOf(selectedObject.tariffItemName), 1)

                }

            }

            this.rows[entry - 1].description = description.join('\n')

        },

        // loadAllActiveTariffItems() {

        //     return new Promise((resolve, reject) => {

        //         this.loadActiveTariffItems({}).then(() => {

        //             this.activeTariffItems = this.getActiveTariffItems;

        //         }).catch(() => {})

        //         resolve()

        //     })

        // },

        loadThisRequestDetails (data) {

            return new Promise((resolve, reject) => {

                this.thisFirstRequestLoader = true;

                API.getFullRequestDetails(data).then(
                    response => {

                        if (response.data.status) {

                            this.singleRequest = response.data.data
                            this.requestType = this.singleRequest.requestType
                            let description = []
                            if(this.singleRequest.description.includes('\n')) {

                                description = this.singleRequest.description.split('\n')

                            } else {

                                description.push(this.singleRequest.description)

                            }

                            for (let i = 0; i < this.activeTariffItems.length; i++) {

                                // console.log('description.includes(this.activeTariffItems[i].tariffItemName)', description.includes(this.activeTariffItems[i].tariffItemName))

                                if (description.includes(this.activeTariffItems[i].tariffItemName)) {

                                    // console.log('this.activeTariffItems[i].tariffItemName', this.activeTariffItems[i].tariffItemName)

                                    this.rows[0].descriptionsArray.push(this.activeTariffItems[i])
                                    this.rows[0].tariffItem.push(this.activeTariffItems[i])
                                    this.tariffItemCopy.push(this.activeTariffItems[i])
                                    description.splice(description.indexOf(this.activeTariffItems[i].tariffItemName), 1)

                                }

                            }
                            for (let i = 0; i < this.singleRequest.requestAttachDtos.length; i++) {

                                this.attachmentTypeIds.push(this.singleRequest.requestAttachDtos[i].attachmentTypeId)

                            }

                            // console.log('description', description)

                            // console.log('this.rows[0].tariffItem', this.rows[0].tariffItem)

                            // console.log('description', description)

                            // console.log('this.tariffItemCopy 1', this.tariffItemCopy)

                            // console.log('this.rows[0].descriptionsArray 1', this.rows[0].descriptionsArray)

                            // this.rows[0].descriptionsArray = this.rows[0].descriptionsArray.concat(description)

                            // console.log('this.rows[0].descriptionsArray 2', this.rows[0].descriptionsArray)

                            // console.log('this.singleRequest.description', this.singleRequest.description)

                            this.rows = [
                                { entry: 1 ,
                                requestName: this.singleRequest.blNumber,
                                description: this.singleRequest.description,
                                requestUniqueIdentifier: this.singleRequest.regime.regimeId,
                                poNumber: this.singleRequest.poNumber,
                                cargoExpectedDate: this.singleRequest.cargoExpectedDate !== null ? this.singleRequest.cargoExpectedDate.split('T')[0] : '',
                                tariffItem: this.rows[0].tariffItem,
                                productType: this.singleRequest.productType,
                                invoiceNumber: this.singleRequest.invoiceNo,
                                descriptionsArray: this.rows[0].descriptionsArray } ]
                            this.changeMyTittle(this.requestType)
                            this.thisFirstRequestLoader = false

                            // console.log('bvfgfb', this.rows[0].description.split("\n").length)

                            resolve()

                        } else {

                            this.thisFirstRequestLoader = false
                            this.loadNotification('error', 4000, 'Application Details',
                            'Application details not loaded, please contact system admin',
                            'response', 1000 , true  , true)

                            reject()

                        }

                    }
                ).catch(() => {

                    this.thisFirstRequestLoader = false

                })

            })

        },

        loadParameters (fileUrl, fileName) {

            return new Promise((resolve) => {

                this.$store.dispatch('global/fileUrling', fileUrl)
                this.$store.dispatch('global/fileNaming', fileName)

                resolve()
            })

        },

        openPdfViewer (fileUrl, fileName, action) {

            this.attachmentNameForDownload = fileName
            this.attachmentUrlForDownload = fileUrl

            this.getFileFromMinio(fileUrl, fileName, action).then(() => {

                this.showPdfViewer = true

            }).catch(() => {})

        },

        closePdfViewer () {

            this.showPdfViewer = false
            this.attachmentNameForDownload = ''
            this.attachmentUrlForDownload = ''
            this.scr = ''

        },

        getFileFromMinio (fileUrl, fileName, action) {

            return new Promise((resolve, reject) => {

                this.loadParameters(fileUrl, fileName).then(() => {

                this.loadFileFromMinio({action}).then((res) => {

                    if(res[1].status === 204) {

                        this.loadNotification('warn', 4000, '',
                        'File not found, please contact system admin',
                        'response', 1000 , true  , true)

                    } else {

                        // this.loadNotification('success', 4000, '',
                        // 'File has been successfully loaded, please have a look at it',
                        // 'response', 1000 , true  , true)
                        this.scr = res[0]
                        resolve()


                    }

                        this.globalLoader = this.getGlobalLoader

                    }).catch((error) => {

                    // this.loadNotification('error', 4000, '',
                    //     'File not loaded, please contact system admin',
                    //     'response', 1000 , true  , true)

                    this.globalLoader = false

                    })

                    this.globalLoader = this.getGlobalLoader

                }).catch(() => {

                    this.globalLoader = false

                })

            })

        },

        // getFileFromMinio (fileUrl, fileName) {

        //     this.globalLoader = this.getGlobalLoader

        //     this.loadParameters(fileUrl, fileName).then(() => {

        //     this.loadFileFromMinio({}).then((res) => {

        //         if(res.status === 204) {

        //             this.loadNotification('warn', 4000, 'File Download (click to close)',
        //             'File not found, please contact system admin',
        //             'response', 1000 , true  , true)

        //         } else {

        //             this.loadNotification('success', 4000, 'File Download (click to close)',
        //             'File has been successfully downloaded, please have a look at it',
        //             'response', 1000 , true  , true)


        //         }

        //             this.globalLoader = this.getGlobalLoader

        //         }).catch(() => {

        //         this.loadNotification('error', 4000, 'File Download (click to close)',
        //             'File not downloaded, please check your internet connection',
        //             'response', 1000 , true  , true)

        //         this.globalLoader = this.getGlobalLoader

        //         })

        //         this.globalLoader = this.getGlobalLoader

        //     }).catch(() => {

        //     })

        // },

        loadDocumentType() {

            API.getAttachments().then(response => {

                if (response.data.status) {

                    for (let i = 0; i < response.data.data.length; i++) {

                        if (response.data.data[i].attachmentGroupCode !== 'CRA') {

                            if(this.attachmentTypeIds.includes(response.data.data[i].attachmentTypeId)) {

                                let optionObject = {

                                    attachmentTypesName:response.data.data[i].attachmentTypesName + '     <<< UPLOADED >>>',
                                    attachmentTypeId:response.data.data[i].attachmentTypeId
                                }

                                this.options.push(optionObject)

                            } else {

                                let optionObject = {

                                    attachmentTypesName:response.data.data[i].attachmentTypesName,
                                    attachmentTypeId:response.data.data[i].attachmentTypeId
                                }

                                this.options.push(optionObject)

                            }

                        }

                    }

                } else {}

            }).catch(() => {});

        },

        loadAllRegimes() {

            this.loadRegimes({}).then(() => {

                this.regimes = this.getAllRegimes;

            }).catch(() => {


            });

        },

        populateSelectedRegime (object) {

            this.selectedRegime = object

        },

        saveRequest () {

            if (this.$v.requestType.$invalid) {

                if (this.$v.requestType.$invalid) {

                    this.requestTypeError = true

                }

            } else {

                let errorFree = 0

                for (let w = 0; w < this.rows.length; w++) {

                    if (this.rows[w].requestName === '' || this.rows[w].description === '' || this.rows[w].requestUniqueIdentifier === '' || this.rows[w].productType === '' || this.rows[w].invoiceNumber === '') {

                        if (this.rows[w].requestName === '') {

                            this.requestNameError.push(this.rows[w].entry)
                            errorFree++

                        } else {

                            this.requestNameError.splice(this.requestNameError.indexOf(this.rows[w].entry), 1)

                        }

                        if (this.rows[w].description === '') {

                            this.descriptionError.push(this.rows[w].entry)
                            errorFree++

                        } else {

                            this.descriptionError.splice(this.descriptionError.indexOf(this.rows[w].entry), 1)

                        }

                        if (this.rows[w].requestUniqueIdentifier === '') {

                            this.requestUniqueIdentifierError.push(this.rows[w].entry)
                            errorFree++

                        } else {

                            this.requestUniqueIdentifierError.splice(this.requestUniqueIdentifierError.indexOf(this.rows[w].entry), 1)

                        }

                        if (this.rows[w].productType === '') {

                            this.productTypeError.push(this.rows[w].entry)
                            errorFree++

                        } else {

                            this.productTypeError.splice(this.productTypeError.indexOf(this.rows[w].entry), 1)

                        }

                        if (this.rows[w].invoiceNumber === '') {

                            this.invoiceNumberError.push(this.rows[w].entry)
                            errorFree++

                        } else {

                            this.invoiceNumberError.splice(this.invoiceNumberError.indexOf(this.rows[w].entry), 1)

                        }


                    }

                }

                if (errorFree === 0) {

                    this.isUploadingAttachment = true

                    const request = {

                    blNumber: this.rows[0].requestName,
                    description: this.rows[0].description,
                    invoiceNo: this.rows[0].invoiceNumber,
                    productType: this.rows[0].productType,
                    regimeId: this.rows[0].requestUniqueIdentifier,
                    requestId: this.singleRequest.requestId,
                    requestName: null,
                    requestType: this.requestType,
                    poNumber: this.rows[0].poNumber,
                    cargoExpectedDate: this.rows[0].cargoExpectedDate !== '' ? this.rows[0].cargoExpectedDate + 'T00:00:00.000Z' : '',

                    }

                    API.updateSingleRequest(JSON.stringify(request)).then(
                        response => {

                            if (response.data.status) {

                                this.isUploadingAttachment = false

                                this.loadNotification('success', 5000, 'Edit Application (click to close)', 'Application has been successfully updated', 'response', 1000 , true  , true)
                                //this.$router.replace('/clearing-and-forwarding/' + response.data.data.requestType === 'IMP' ? 'import' : 'export' + '-' + this.spaceToHyphenString(response.data.data.status) + '-application-view/' + response.data.data.requestId);
                                this.$router.push({name: 'ApplicationPage', params: {requestId: response.data.data.requestId, applicationType: response.data.data.requestType === 'IMP' ? 'import' : 'export', status: this.spaceToHyphenString(response.data.data.status)}})

                                this.singleRequest = response.data.data
                                this.requestType = this.singleRequest.requestType
                                let description = []
                                this.tariffItemCopy = []
                                this.rows[0].descriptionsArray = []
                                this.rows[0].tariffItem = []
                                description = this.singleRequest.description.split('\n')

                                for (let i = 0; i < this.activeTariffItems.length; i++) {

                                    if (description.includes(this.activeTariffItems[i].tariffItemName)) {

                                        this.rows[0].descriptionsArray.push(this.activeTariffItems[i].tariffItemName)
                                        this.rows[0].tariffItem.push(this.activeTariffItems[i])
                                        this.tariffItemCopy.push(this.activeTariffItems[i])

                                    }

                                }

                                this.rows = [ 
                                    { entry: 1 , 
                                    requestName: this.singleRequest.blNumber,
                                    description: this.singleRequest.description, 
                                    requestUniqueIdentifier: this.singleRequest.regime.regimeId, 
                                    poNumber: this.singleRequest.poNumber, 
                                    cargoExpectedDate: this.singleRequest.cargoExpectedDate.split('T')[0],
                                    tariffItem: this.rows[0].tariffItem,
                                    productType: this.singleRequest.productType,
                                    invoiceNumber: this.singleRequest.invoiceNo,
                                    descriptionsArray: this.rows[0].descriptionsArray } ]
                                this.changeMyTittle(this.requestType)

                                this.isUploadingAttachment = false

                            } else {

                                this.loadNotification('error', 5000, 'Edit Application (click to close)', 'Application not updated, please retry', 'response', 1000 , true  , true)
                                this.isUploadingAttachment = false

                        }
                        }
                    ).catch(() => {

                        this.loadNotification('error', 5000, 'Edit Application (click to close)', 'Application not updated, please retry', 'response', 1000 , true  , true)
                        this.isUploadingAttachment = false

                    })


                } else {
                }

            }

        },

        saveTemporaryAttachment (event) {

            this.fileData = event.target.files[0]

            if (this.documentTypeId === undefined || this.fileData.type !== 'application/pdf') {

                    if (this.documentTypeId === undefined) {

                        this.documentTypeIdErrorMany = true

                    } else {

                        this.documentTypeIdErrorMany = true

                    }

                    if (this.fileData.type !== 'application/pdf') {

                        this.pdfFormatError = true

                    } else {

                        this.pdfFormatError = false

                    }

            } else {

                this.uploadOneByOne = true

                this.fileData.name

                const formData = new FormData()

                formData.append('file', this.fileData)
                formData.append('requestId', this.singleRequest.requestId)
                formData.append('attachmentTypeId', this.selectedDocumentId.attachmentTypeId)

                this.documentToAttachArray.push({file: this.fileData, requestId: this.singleRequest.requestId, document: this.selectedDocumentId, formData: formData})

                // for (var pair of formData.entries()) {

                //     console.log(pair[0]+ ', ' + pair[1])

                // }

                // console.log('formData', formData)

                this.fileData = ''
                this.documentTypeId = ''
                this.file = ''
                event = ''
                this.selectedDocumentId = {}

            }
        },

        verifyThisAttachment (data) {

            API.verifyAttachedAttachment(data).then(() => {

            }).catch((error) => {})

        },

        saveAmendment () {

            this.isUploadingAttachment = true

            if(this.documentToAttachArray.length > 0) {

                for (let i = 0; i < this.documentToAttachArray.length; i++) {

                    API.uploadRequestAttachment(this.documentToAttachArray[i].formData).then(
                        response => {
                            if (response.data.status) {

                                this.documentTypeId = ''
                                this.documentTypeIdErrorMany = false
                                this.file = ''
                                this.isUploadingAttachment = false
                                if(this.finalUserPermissions.includes('CLEARINGFORWARDING_VERIFY-ATTACHMENT_{REQUESTATTACHMENTID}_PUT')) {

                                    this.verifyThisAttachment(response.data.data.requestAttachmentId)

                                }
                                this.saveRequest()

                            } else {

                                this.isUploadingAttachment = false

                            }
                        }
                        ).catch(() => {

                            this.isUploadingAttachment = false

                        })

                }
                this.documentToAttachArray = []

            } else {

                this.saveRequest()

            }

        },

        deleteAttachment (data) {

            this.documentToAttachArray.splice(this.documentToAttachArray.indexOf(data), 1)

        },

        changeMyTittle (flag) {

            this.filteredRegimes = []

            if (flag === 'EXP') {

            this.myTittle = 'REFERENCE NUMBER'
            this.myDate = 'Expected Time of Departure (ETD)'

            } else {

            this.myTittle = 'BL/Airway Bill/RCN'
            this.myDate = 'Expected Time of Arrival (ETA)'
            }

            for (let x = 0; x <= this.regimes.length; x++) {

                if(typeof this.regimes[x] !== 'undefined' && this.regimes[x].operationType === this.requestType) {

                    this.filteredRegimes.push(this.regimes[x])

                }

            }

        },

        goBack () {

            if (window.$cookies.isKey('browser-local-cashed-url-back-7')) {
                this.$router.push(window.$cookies.get('browser-local-cashed-url-back-7'))

                window.$cookies.remove('browser-local-cashed-url-back-7')
            }

        },

    }

}

</script>

<style scoped>

.delete-fa-icon {
    color:red;
}

.delete-fa-icon:hover {
    cursor: pointer;
}

.legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 2rem;
    border-radius: 3px;
    margin-top: 2rem;
}

.legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
}

.remove-bl {

    margin-top: 142px;
    color: red;

}

.minus-bl:hover, .plus-bl:hover {

    cursor: pointer;

}

.add-bl {

color: green;
background-color: rgba(128, 128, 128, .1);
padding-right: 5px;
border-radius: 3px;

}

.minus-bl-row {

background-color: rgba(128, 128, 128, .1);
border-radius: 3px;

}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

.upload-btn-wrapper {
position: relative;
overflow: hidden;
display: inline-block;
}

.upload-btn-wrapper input[type=file]:hover {
cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
font-size: 100px;
position: absolute;
left: 0;
top: 0;
opacity: 0;
}

.label-upload {
position: absolute;
top: 9px;
left: 200px
}

</style>
