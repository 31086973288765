<template>
  <card>
    <div class="col-md-12">
      <div class="col-12 text-right">
        <button @click="loadPageableRequestOnCreated" class="btn btn-sm btn-success" style="color: green !important;">
          ALL
        </button>

        <button v-for="(day, index) in days" @click="loadPageableRequestOnDay(day)" class="btn btn-sm ml-1 btn-default" style="color: #000000 !important;" :id="day.name" :key="index"> 
          {{ day.name }}
        </button>

      </div>

      <hr/>

      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :no-data-found="noDataFound"
        :is-pageable="false"
        :has-search-form="false"
        :has-manage-button="false"
        :loading-title="'Please wait while system is loading' + all + 'tally clerks list in this shift ' + dayName"
      />
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9 left loader-div-gif" />
          <div class="col-md-3">
            <button type="button" class="btn btn-fill btn-outline-info float-right" @click="goBack">
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>

  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import DataTable from '../../../components/DataTable/updatedTable';
  import {mapGetters, mapActions} from "vuex";
  import TALLY_API from '../../../api/tally'

  export default {
    name: 'ClerkInShift',

    components: {

      PulseLoader,
      DataTable

    },

    created() {

      this.$store.dispatch('pagenames/currentPageName', 'Tally Clerks List In Shift');
      this.loadPageableRequestOnCreated();
      this.days = this.getDays();

    },

    data() {

      return {

        clerks: [],

        loading: false,
        color: 'green',
        size: '15px',

        day: 'ALL',

        days: [],
        dayName: '',
        all: '',

        myFirstLoader: false,
        noDataFound: false,
        requests: [],

        tableHeaders: ['First Name', 'Last Name', 'Email'],
        tableDataKeys: ['firstName', 'lastName', 'email'],

      }

    },

    computed: {

      ...mapGetters({

        getRequestList: "tallyshift/getRequestList",
        getMyFirstLoader: "tallyshift/getMyFirstLoader",
        getNoDataFound: 'tallyshift/getNoDataFound',
        getShiftId: "tallyshift/getShiftId"
      }),

    },

    methods: {

      ...mapActions("tallyshift", [

        "loadClerkInShift",
        "loadClerkInShiftOnDay"

      ]),

      loadParameters(day = null) {

        return new Promise((resolve) => {

          this.$store.dispatch("tallyshift/shiftiding", this.$route.params.shiftId);
          this.$store.dispatch("tallyshift/shiftdaying", day);

          resolve();
        });

      },

      setAllGetters() {

        this.requests = this.getRequestList;
        this.myFirstLoader = this.getMyFirstLoader;
        this.noDataFound = this.getNoDataFound;

      },

      loadPageableRequestOnCreated() {

        this.dayName = '';
        this.all = ' all ';

        this.loadParameters(null).then(() => {

          this.loadClerkInShift({}).then(() => {

            this.setAllGetters();

          }).catch(() => {


          });

          this.myFirstLoader = this.getMyFirstLoader;

        }).catch(() => {

        });

      },

      loadPageableRequestOnDay(day) {

        this.dayName = 'for ' + day.name;
        this.all = ' ';

        // this.loadParameters(day).then(() => {

        //   this.loadClerkInShift({}).then(() => {

        //     this.setAllGetters();

        //   }).catch(() => {


        //   });

        //   this.myFirstLoader = this.getMyFirstLoader;

        // }).catch(() => {

        // });

        this.myFirstLoader = true

        TALLY_API.getClerksInShiftOnDay(this.$route.params.shiftId, day.id).then((response) => {

          if(response.data.status) {

            if(response.data.data.length > 0) {

              this.requests = response.data.data;
              this.myFirstLoader = false;
              this.noDataFound = false;

            } else {

              this.myFirstLoader = false;
              this.noDataFound = true;

            }

          } else {

            this.myFirstLoader = false

          }
          this.loading = false;

        }).catch(()=>{

          this.myFirstLoader = false
          this.loading = false;

        })

      },

      goBack () {

        if (window.$cookies.isKey('browser-local-cashed-url-back')) {

          this.$router.push(window.$cookies.get('browser-local-cashed-url-back'));
          window.$cookies.remove('browser-local-cashed-url-back');

        }

      },

    }
  }
</script>

<style scoped>
  #MONDAY:hover,
  #TUESDAY:hover,
  #WEDNESDAY:hover,
  #THURSDAY:hover,
  #FRIDAY:hover,
  #SATURDAY:hover,
  #SUNDAY:hover {
    background-color: #dcdcdc;
  }
</style>
