<template>
<div>
  <card v-if="thisFirstRequestLoader">
    <div class="row">
    <div class="col-md-12 text-center">
      <b>Please wait while system is loading charges for bulk billing</b>
    </div>
    <div class="col-md-12 text-center">
      <br>
    </div>
    <div class="col-md-12 text-center">
      <pulse-loader
        :color="color"
        :size="size"
      />
    </div>
  </div>
  </card>
  <card v-if="!thisFirstRequestLoader">
    <div slot="header" class="col-md-12">
      <div class="row text-center">
        <div class="col-md-12 text-center">
          <b>CFA BULK BILLING</b>
          <br />
          <i>
            ( Enter customer name and application file number as optional to search for charges. <sup class="mandatory-star">*</sup> means field is mandatory )
          </i>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
          <label>search customer <sup class="mandatory-star">*</sup></label>
          <model-list-select
            :list="allCustomers"
            v-model="bulkCustomerId"
            class="form-control"
            option-value="customerId"
            option-text="name"
            placeholder="Search customer"
            @input="getCustomerName">
          </model-list-select>
          <div v-if="bulkCustomerIdError">
            <div class="error-color" v-if="!$v.bulkCustomerId.required">Customer field is required</div>
          </div>
      </div>
      <div class="col-md-4">
          <div class="form-group">
            <base-input class="special-bottom" placeholder="FIle NUmber" label="FIle NUmber" type="text" v-model="fileNumber"></base-input>
          </div>
      </div>
      <div class="col-md-12">
        <button class="btn btn-default float-right custom-btn btn-fill" @click.prevent="clearForm" v-if="bulkCustomerId !== '' || fileNumber !== ''">Clear</button>
        <button type="submit" title = "search for eligible charges for bulk billing by customer name application file number as optional to search for charges"
          class="btn btn-primary btn-space float-right mt-2 custom-btn" :class="[bulkCustomerId !== '' ? 'mr-2' : '']" @click.prevent="searchRequestByFileNumberAndCustomer">
          Search
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr />
      </div>
    </div>

    <div class="row" v-if="filteredRequests.length > 0">
      <div class="col-md-12 text-center">
        <b>SEARCHED CHARGE LIST</b>
        <br />
          <i>
            ( Click on plus icon to add respective charge to unsaved bulk bill list that will appear below this searched list )
          </i>
      </div>
      <div class="col-md-12">
        <br />
      </div>
    </div>

    <data-table
        v-if="filteredRequests.length > 0"
        :tableHeaders="tableHeaders"
        :tableData="filteredRequests"
        :tableDataKeys="tableDataKeys"
        :noDataFound="requestNotFound"
        :hasManageButton="false"
        :is-hovered="isHovered"
        :hasFilter="false"
        :hasPagination="false"
        :isPageable="false"
        :hasAddIconButton="true"
        :permissions="[ {key: 'searchPermission', value: 'CLEARINGFORWARDING_REQUEST-BY-FILENUMBER_POST'} ]"
        @showToolTip="showToolTip"
        @hideToolTip="hideToolTip"
        @addItemFromList="addToSelectedRequest"
      >
      </data-table>

    <div class="row" v-if="filteredBillsForBulkBilling.length > 0">
      <div class="col-md-12">
        <hr />
      </div>
    </div>

    <div class="row" v-if="filteredBillsForBulkBilling.length > 0">
      <div class="col-md-12 text-center text-success">
        <b>SELECTED CHARGE LIST FOR BULK BILLING</b>
        <br />
          <i>
            ( Click on the trash icon to remove respective charge from unsaved bulk bill list, click <b>Create Bulk Bill</b> button to create bulk bill )
          </i>
      </div>
      <div class="col-md-12">
        <br />
      </div>
    </div>
    <data-table
        v-if="filteredBillsForBulkBilling.length > 0"
        :tableHeaders="tableHeaders"
        :tableData="filteredBillsForBulkBilling"
        :tableDataKeys="tableDataKeys"
        :noDataFound="requestNotFound"
        :hasManageButton="false"
        :is-hovered="isHovered"
        :hasFilter="false"
        :hasPagination="false"
        :isPageable="false"
        :hasDeleteIconButton="true"
        :permissions="[ {key: 'searchPermission', value: 'CLEARINGFORWARDING_REQUEST-BY-FILENUMBER_POST'} ]"
        @showToolTip="showToolTip"
        @hideToolTip="hideToolTip"
        @removeItemFromList="removeRequest"
      >
    </data-table>

    <div class="row" v-if="filteredBillsForBulkBilling.length > 0 || filteredRequests.length > 0">
      <div class="col-md-12">
        <hr />
      </div>
    </div>
    <base-input v-model="pbpabulkAmount" @input="commafyThisAmountWithDecimal('pbpaBill')"
                label="PBPA TOTAL BILL AMOUNT(TZS)" placeholder="PBPA TOTAL BILL AMOUNT(TZS)" type="text" class="special-bottom" v-if="isPbPa && filteredBillsForBulkBilling.length > 1"/>
    <button class="btn btn-default float-right custom-btn btn-fill" @click.prevent="goBack">Back</button>
    <button class="btn btn-primary float-right custom-btn mr-2" @click="submitForBulkBilling" v-if="filteredBillsForBulkBilling.length > 1">Create Bulk Bill</button>

  </card>
  <global-modal v-if="loading">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            {{ loadingMessage }}
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
</div>
</template>

<script>
  import API from '../../../../../api'
  import API_REVENUE from '../../../../../api/rev'
  import { required } from "vuelidate/lib/validators";
  import {ModelListSelect} from 'vue-search-select';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import GlobalModal from '../../../../../components/globalLoader'


  export default {

      name: "bulkBilling",

      components:{

        ModelListSelect,
        PulseLoader,
        GlobalModal,
        DataTable: () => import('../../../../../components/DataTable/dataTable')

      },

      data() {

          return {
            isPbPa: false,
            containsPbPa:false,
            searchedRequest: [],
            pbpabulkAmount:"",
            requestNotFound:false,
            filteredRequests: [],
            selectectedBillsForBulkBilling: [],
            filteredBillsForBulkBilling: [],
            isHovered: [],
            color: 'green',
            size: '13px',
            loading: false,
            ErrorMessage:'',
            SuccessAlert:false,
            ErrorAlert:false,
            SuccessMessage:'',
            allCustomers: [],
            bulkCustomerId: '',
            fileNumber: '',
            bulkCustomerIdError: false,
            thisFirstRequestLoader: false,
            loadingMessage: '',
            customerName: '',
            tableHeaders: ['FILE NUMBER', 'id', 'B/L NUMBER', 'CUSTOMER', 'TANSAD NUMBER', 'INVOICE NUMBER', 'TARIFF USED', 'CUSTOMS VALUE (TZS)'],
            tableDataKeys: ['fileNo', 'requestId', 'blNumber','customer', 'tansadNo', 'invoiceNumber', 'tariffItemName', 'customValue'],

          }
    },

    validations: {

      bulkCustomerId: {required}

    },

    created() {

      this.loadAllCustomers();
      this.$store.dispatch('pagenames/currentPageName', 'CFA Bulk Billing')

    },

    methods:{

      commafyThisAmountWithDecimal (flag) {

        this.pbpabulkAmount = this.commafyAmountWithDecimal(this.pbpabulkAmount);

      },

      getCustomerName (id) {

        for(let x = 0 ; x < this.allCustomers.length ; x++) {
          if(this.allCustomers[x].customerId === id) {
            this.customerName = this.allCustomers[x].name
          }
        }
      },

      sortingData(data) {

        for(let x = 0 ; x < data.length ; x++) {

          if(this.filteredBillsForBulkBilling.length > 0) {

            if(this.filteredBillsForBulkBilling.some(key => key.requestId === data[x].requestId)) {

                if(this.fileNumber !== '') {

                    this.loadNotification('info', 4000, 'Bulk Info Message',' Charge is already selected for bulk billing',
                    'response', 1000 , true  , true)

                }

            } else {

                const requestObject = {

                  ...data[x],
                  tansadNo: data[x].assessmentNotice.tansadNo,
                  invoiceNumber: data[x].assessmentNotice.invoiceNumber,
                  tariffItemName: data[x].assessmentNotice.tariffItemName,
                  customValue: this.putComma(this.amountDecimalPlaces(data[x].assessmentNotice.customValue))

                };

                this.filteredRequests.push(requestObject)

            }

          } else {

            const requestObject = {

                  ...data[x],
                  tansadNo: data[x].assessmentNotice.tansadNo,
                  invoiceNumber: data[x].assessmentNotice.invoiceNumber,
                  tariffItemName: data[x].assessmentNotice.tariffItemName,
                  customValue: this.putComma(this.amountDecimalPlaces(data[x].assessmentNotice.customValue))

                };

                this.filteredRequests.push(requestObject)

          }

        }

      },

      showToolTip(id) {
        this.isHovered = [id]
      },

      hideToolTip(id) {
        this.isHovered.splice(this.isHovered.indexOf(id), 1)
      },


      submitForBulkBilling() {
        console.log('starting....')
        if(this.containsPbPa && !this.isPbPa){
          console.log('hello')
          this.notifyUser('error','Cant Mix Other Tariffs with PBPA');
          return;
        }


        this.$dialog.confirm('You are about to create bulk bill for <br><br>' +
                    ' customer - [ ' + this.customerName + ' ] with total of [ ' + this.selectectedBillsForBulkBilling.length + ' ]  bills<br><br>' +
                    '  Are you sure!.', {

        loader: true,
        html: true

      }).then((dialog) => {

        this.myLoader = true;

        this.loadingMessage = 'Creating bulk billing, Please wait';

          if (this.filteredBillsForBulkBilling.length > 0) {

            let bulkIds = [];
            for(let x = 0 ; x < this.filteredBillsForBulkBilling.length ; x++) {
              bulkIds.push(this.filteredBillsForBulkBilling[x].requestId)
            }

            let bulkObject = {

              customerId: this.bulkCustomerId,
              requestIds: bulkIds,
              amount: this.pbpabulkAmount===""? null:this.removeComma(this.pbpabulkAmount)

            };

            this.loading = true;

            API_REVENUE.generateCFABulkCharges(JSON.stringify(bulkObject)).then(
              response => {

              if(response.data.status) {

                this.loading = false;
                this.bulkCustomerId = "";
                this.loadingMessage = '';
                this.pbpabulkAmount="";
                this.filteredBillsForBulkBilling = [];
                this.loadNotification('success', 4000, 'Bulk Success Message',' Bulk bill has been successifully created',
                'response', 1000 , true  , true)

              } else {

                this.loading = false;
                this.bulkCustomerId = "";
                this.loadingMessage = '';
                this.loadNotification('error', 4000, 'Bulk Error Message', 'Bulk bill not generated, ' + response.data.message,
                'response', 3000 , true  , true)
              }

            }).catch(() => {

              this.loading = false;
                this.loadingMessage = '';
                this.loadNotification('error', 4000, 'Bulk Error Message',' Bulk bill not created, please try',
                'response', 3000 , true  , true)
            })
          }

          dialog.close()

      }).catch(() => {

        this.myLoader = false

      })

      },

      removeRequest (request) {

        this.filteredRequests.push(request);
        this.filteredBillsForBulkBilling.splice(this.filteredBillsForBulkBilling.indexOf(request), 1);
        for (let i=0; i<this.filteredBillsForBulkBilling.length; i++){
          this.isPbPa=true;
          this.containsPbPa=false;
          if(!this.filteredBillsForBulkBilling[i].isPBPA){
            this.isPbPa=false;
            break;
          }

        }

      },

      addToSelectedRequest (request) {
        this.filteredBillsForBulkBilling.push(request);
        this.filteredRequests.splice(this.filteredRequests.indexOf(request), 1);

        for (let i=0; i<this.filteredBillsForBulkBilling.length; i++){
          this.isPbPa=true;
          this.containsPbPa=false;
          if(!this.filteredBillsForBulkBilling[i].isPBPA){
            this.isPbPa=false;
            break;
          }
        }
      },

      loadAllCustomers() {

        this.thisFirstRequestLoader = true;

        API.getCustomerAll().then(
          response => {

            if (response.data.status) {

              this.allCustomers = response.data.data;
              this.thisFirstRequestLoader = false;
            } else {
              this.thisFirstRequestLoader = false;
            }
          }
        ).catch(() => {
          this.thisFirstRequestLoader = false;

        })
      },

      searchRequestByFileNumberAndCustomer() {

        if(this.$v.bulkCustomerId.$invalid) {

          this.bulkCustomerIdError = true;

        } else {
          this.bulkCustomerIdError = false;
          this.requestNotFound = false;
          this.notSelectedRequestsError = false;
          this.bulkBillCreated = false;
          this.loading = true;
          this.filteredRequests = [];
          this.loadingMessage = 'Searching for eligible charges for bulk billing, Please wait';

          API.getRequestsByFileNumberCustomer(this.fileNumber ,this.bulkCustomerId).then(
            response => {

            this.loading = false;
            if(response.data.data.length > 0) {
              this.searchedRequest = response.data.data;
              this.sortingData(response.data.data);
              this.fileNumber = "";
              this.loadingMessage = '';
            }else {
              this.requestNotFound = true;
              this.loadingMessage = '';
            }
          }).catch(() => {

            this.loading = false;
            this.loadingMessage = '';
          })

        }

      },

      clearForm () {

        this.fileNumber = '';
        this.bulkCustomerId = '';
        this.searchedRequest = [];
        this.filteredRequests = [];
        this.selectectedBillsForBulkBilling = [];
        this.filteredBillsForBulkBilling = [];

      },

        goBack() {
          history.back()
        }
      }
    }
</script>

<style scoped>
  .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
    }
</style>
<style>
.mandatory-star {
  color: red;
  font-weight: bold;
}

.error-color {
  color: red;
  font-weight: bold;
}
</style>
