<template>
  <div class="col-md-11 legend">
    <h6 class="legend-title">
      Cargo {{ index + 1 }}
    </h6>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>Search Package Type</label>
          <model-list-select :list="packageTypes"
                             v-model="goods.packageType"
                             class="form-control"
                             option-value="packageTypeName"
                             option-text="packageTypeName"
                             placeholder="Package Type">
          </model-list-select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="name" class="custom-font">Cargo Name</label>
          <input type="text" class="form-control" id="name" v-model="goods.cargoName">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="mxNumbers" class="custom-font">Marks And Numbers</label>
          <input type="text" class="form-control" id="mxNumbers" v-model="goods.marksAndNumbers">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="gWeight" class="custom-font">Gross Weight</label>
          <input type="text" class="form-control" id="gWeight" v-model="goods.weight">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Gross Weight Unit</label>
          <select class="form-control" v-model="goods.weightUnit">
            <option value="" selected>Select gross weight unit</option>
            <option v-for="(unitG, index_unitG) in cargoUnits" :value="unitG.id" :key="index_unitG">{{ unitG.name }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="mt" class="custom-font">Net Weight</label>
          <input type="text" class="form-control" id="mt" v-model="goods.netWeight">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Net Weight Unit</label>
          <select class="form-control" v-model="goods.netWeightUnit">
            <option value="" selected>Select net weight unit</option>
            <option v-for="(unitN, index_unitN) in cargoUnits" :value="unitN.id" :key="index_unitN">{{ unitN.name }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="volume" class="custom-font">Volume</label>
          <input type="text" class="form-control" id="volume" v-model="goods.volumeCbm">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="volumeUnit" class="custom-font">Volume Unit</label>
          <input type="text" class="form-control" id="volumeUnit" v-model="goods.volumeUnit">
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="nPackages" class="custom-font">Number Of Packages</label>
          <input type="number" class="form-control" id="nPackages" v-model="goods.numberOfPackages">
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="vTin" class="custom-font">Vehicle VIN</label>
          <input type="number" class="form-control" id="vTin" v-model="goods.vehicleVIN">
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="vModel" class="custom-font">Vehicle Model</label>
          <input type="number" class="form-control" id="vModel" v-model="goods.vehicleModel">
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="Vmake" class="custom-font">Vehicle Make</label>
          <input type="number" class="form-control" id="Vmake" v-model="goods.vehicleMake">
        </div>
      </div>
      <div class="col-md-12">
        <label class="custom-font">Special Cargo</label>
        <div class="row">
          <div class="col-md-12">
            <hr />
          </div>
          <div class="col-md-3">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input DangerousGoods" :id="'danger' + index" value="DangerousGoods" @change="(e)=>addSpecialCargos(index,e)">
              <label class="custom-control-label custom-font" :for="'danger' + index">Dangerous goods</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input custom-font TemperatureControl" :id="'temp' + index" value="TemperatureControl" @change="(e)=>addSpecialCargos(index,e)">
              <label class="custom-control-label custom-font" :for="'temp'+index">temperature control</label><br/>
              <div>
                <input type="text" class="mr-2 minTmp" placeholder="Minimum" @change="(e)=>specialCargoDescriptions('TemperatureControl',index,e,'minTmp')"/>
                <input type="text" placeholder="Maximum" class="maxTmp" @change="(e)=>specialCargoDescriptions('TemperatureControl',index,e,'maxTmp')"/>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input ContainsBattery" :id="'battery'+ index" value="ContainsBattery" @change="(e)=>addSpecialCargos(index,e)" >
              <label class="custom-control-label custom-font" :for="'battery'+index">contains battery</label><br/>
              <input type="text" placeholder="Class" @change="(e)=>specialCargoDescriptions('ContainsBattery',index,e,'goodClass')"/>
            </div>
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="description" class="custom-font">Cargo description</label>
          <textarea class="form-control" id="description" rows="3" v-model="goods.cargoDescription"></textarea>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import { CARGO_UNITS } from '../../../mixins/constants';
  import {ModelListSelect, ModelSelect} from 'vue-search-select';
  import API from "../../../api";


  export default {
        name: "shippingOrderGoods",
    components:{
      ModelListSelect
    },

    created() {
      this.getPackageTypes();
    },

    watch:{
      $data:{
        handler:function (val,oldVal) {
          this.$emit('detailsData',this.details);
        },
        deep:true
      }
    },


    props:{
      index:{
        type:String,
        required:false
      },
      edit:{
        type: Boolean,
        required: false
      },
    },


      watch:{
        $data:{
          handler:function (val,oldVal) {
            this.$emit('detailsData',{goods:this.goods,index:this.index});
          },
          deep:true
        },
      },

      data(){
          return{
            packageTypes:[],
            cargoUnits: CARGO_UNITS,
            goods:{
              cargoDescription: "",
              cargoName: "",
              shippingOrderGoodId: null,
              marksAndNumbers: "",
              netWeight: 0,
              netWeightUnit: "G",
              numberOfPackages: 0,
              packageType: 0,
              shippingOrderId: 0,
              specialCargos: null,
              vehicleMake: "",
              vehicleModel: "",
              vehicleVIN: "",
              volumeCbm: 0,
              volumeUnit: "",
              weight: 0,
              weightUnit: "G",
            }
          }
      },

      methods:{

        checkIf(){
          if(this.edit && this.goods.specialCargos!==null){
            console.log('goods',this.goods.specialCargos);
            //check if cargo object has special cargos which are DangerousGoods,TemperatureControl and ContainsBattery
            let specialCargos=['DangerousGoods','TemperatureControl','ContainsBattery'];
            //loop specialCargos and find if each of special cargo is in object that is saved in database
            for (let i=0;i<specialCargos.length;i++){
              //find  the index of special cargo in the data from db if present return the index if not return negative one
              let specialCargoIndex = this.goods.specialCargos.findIndex((data) => {

                return specialCargos[i] === data.specialCargo;
              });


              //if this cargo is present in object from db then the form to display those values
              if(specialCargoIndex!==-1){
                document.querySelector('.' + this.goods.specialCargos[specialCargoIndex].specialCargo).checked=true;
                if(this.goods.specialCargos[specialCargoIndex].maxTmp!==null && this.goods.specialCargos[specialCargoIndex].minTmp){
                  document.querySelector('.minTmp').setAttribute('value',this.goods.specialCargos[specialCargoIndex].minTmp);
                  document.querySelector('.maxTmp').setAttribute('value',this.goods.specialCargos[specialCargoIndex].maxTmp);
                }
              }
            }
          }
        },

        populate(goods){
          this.goods=goods;
          this.checkIf();

        },

        specialCargoDescriptions(cargo, index, e, inputValue) {
          let specialCargoIndex = this.goods.specialCargos.findIndex((data) => {
            return cargo === data.specialCargo;
          });

          this.goods.specialCargos[specialCargoIndex][inputValue] = e.target.value;

        },


        addSpecialCargos(index, e) {

          if(this.goods.specialCargos===null){
            this.goods.specialCargos=[];
          }

          let specialCargoIndex = this.goods.specialCargos.findIndex((data) => {
            return e.target.value === data.specialCargo;
          });

          if (specialCargoIndex === -1) {
            this.goods.specialCargos.push(
              {
                goodClass: null,
                maxTmp: 0,
                minTmp: 0,
                specialCargo: e.target.value
              }
            );

            console.log('newGoods',this.goods);
          } else {
            this.goods.specialCargos.splice(specialCargoIndex, 1);
          }
        },

        getPackageTypes(){

          API.getPackageTypes().then((response)=>{

            for(let i=0;i<response.data.data.length;i++){

              this.packageTypes.push({

                packageTypeCode:response.data.data[i].packageTypeCode,

                packageTypeId:response.data.data[i].packageTypeId,

                packageTypeName:"(" + response.data.data[i].packageTypeCode + ")" + response.data.data[i].packageTypeName

              });

            }


          }).catch((e)=>{
            this.loadNotification('error',4000,'Error','Failed To Load Package Types ',1000 , true  , true);
          })

        }
      }
    }
</script>

<style scoped>

</style>
