<template>
  <div class="content">
    <card>
      <div class="row">
        <div @click="toggle" class="col-md-12 toggle-code-key">
          <div class="row">
            <div class="col-md-6">
              Application icons and status color keys
            </div>
            <div class="col-md-6 text-right">
              <font-awesome-icon icon="angle-down" v-show="!showSection"/>
              <font-awesome-icon icon="angle-up" v-show="showSection"/>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-show="showSection">
          <hr/>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12" style="padding-left: 30px" v-show="showSection">
                <b>Status color keys</b>
              </div>
              <div class="col-md-12">
                <div class="col-md-12" v-show="showSection">
                  <div class="row">
                    <div class="col-md-4">
                    <span>
                    <font-awesome-icon class="received-request" icon="circle"/>
                      <i class=" text-muted"> Application Sent</i>
                    </span>
                    </div>
                    <div class="col-md-4">
                    <span>
                    <font-awesome-icon class="accepted-request" icon="circle"/>
                      <i class=" text-muted"> Application Accepted</i>
                  </span>
                          </div>
                          <div class="col-md-4">
                    <span>
                    <font-awesome-icon class="declined-request" icon="circle"/><i class=" text-muted">
                      Application Returned for Major Changes</i>
                  </span>
                          </div>
                        </div>
                        <br/>
                        <div class="row">
                          <div class="col-md-4">
                    <span>
                    <font-awesome-icon class="request-after-changes" icon="circle"/><i class=" text-muted">
                      Application Resubmitted After Changes</i>
                  </span>
                          </div>
                          <div class="col-md-4">
                    <span>
                    <font-awesome-icon class="returned-for-changes" icon="circle"/><i class=" text-muted">
                      Application Returned for Minor Changes</i>
                  </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon icon="circle" class="request-in-action"/><i
                        class=" text-muted"> Application Assessed</i>
                      </span>
                    </div>
                  </div>
                  <br/>
                  <div class="row">
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon icon="circle" class="rejected-app-assess"/>
                        <i class=" text-muted">
                        Rejected Application Assessment</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon icon="circle" class="request-charged"/>
                        <i class=" text-muted"> Application Charged</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon icon="circle" class="request-billed"/><i class=" text-muted">
                        Application Billed</i>
                      </span>
                    </div>
                  </div>
                  <br/>
                  <div class="row">
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon icon="circle" class="app-bill-approved"/>
                        <i class=" text-muted">
                        Application Bill Approved</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon icon="circle" class="app-bill-paid"/><i
                        class=" text-muted"> Application Bill Paid</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon icon="circle" class="app-closed"/><i class=" text-muted">
                        Application Closed</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12" v-show="showSection">
            <br/>
          </div>

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12" style="padding-left: 30px" v-show="showSection">
                <b>icons keys</b>
              </div>
              <div class="col-md-12">
                <div class="col-md-12" v-show="showSection">
                  <div class="row">
                    <div class="col-md-4">
              <span>
              <font-awesome-icon icon="plus"/><i class=" text-muted"> Add Application</i>
            </span>
                    </div>
                    <div class="col-md-4">
              <span>
              <font-awesome-icon class="edit-fa-icon" icon="edit"/><i class=" text-muted"> Edit Application</i>
            </span>
                    </div>
                    <div class="col-md-4">
              <span>
              <font-awesome-icon class="view-fa-icon" icon="eye"/><i class=" text-muted"> View Application</i>
            </span>
                    </div>
                  </div>
                  <br/>
                  <div class="row">
                    <div class="col-md-4">
              <span>
              <font-awesome-icon class="delete-fa-icon" icon="trash-alt"/><i class=" text-muted">
                Delete Application</i>
            </span>
                    </div>
                    <div class="col-md-4">
              <span>
              <font-awesome-icon class="envelop-fa-icon" icon="envelope"/><i class=" text-muted">
                Returned message for changes</i>
            </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div class="col-md-12 alert-dismissible fade show" role="alert"
          style="border-radius:5px;"
          v-bind:class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]" v-on:enter="enter">

        <strong v-if="SuccessAlert">Successful!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button aria-label="Close" class="close close-custom" data-dismiss="alert" style="line-height:2px;"
                type="button"
                v-if="SuccessAlert" v-on:click="fadeMe">
          <span aria-hidden="true">&times;</span>
        </button>
        <button aria-label="Close" class="close close-custom" data-dismiss="alert" style="line-height:2px;"
                type="button"
                v-if="ErrorAlert" v-on:click="fadeMe">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <br/>
      <div class="row">
        <div class="col-md-4">
          <div class="text-left">Applications List</div>
        </div>
        <div class="col-md-4 text-center">
          <pulse-loader :color="color" :loading="loading" :size="size" v-if="myloader"></pulse-loader>
        </div>
        <div class="col-md-4 text-right">
          <span @click.prevent="openModal(null , actionButton = 'AddModal')" class="all-fa-icons btn-space"
                title="add new request"
                v-if="finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_POST')">
            <font-awesome-icon icon="plus"/>
          </span>
        </div>
      </div>

      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->
      <hr/>
      <div class="row">
        <div class="col-md-6">
          <div class="row">

            <div class="col-md-3 text-left">

              Entries per page

            </div>

            <div class="col-md-2 float-left">

              <select name="PageSize" @change="onChange($event)" class="" v-model="pageSize" style="width:73px;">
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </select>

            </div>

          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-7 text-right" v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SEARCH-ALL_POST')">
              <form class="search" @submit.prevent="searchRequestByName(pageNo , pageSize)">
                <div class="form-group float-right">
                  <div class="input-group">
                    <div class="refresh-all" title="load all requests" v-if="reloadAllRequest"
                        @click.prevent="reloadByIcon">
                      <font-awesome-icon icon="sync-alt"/>
                    </div>
                    <input type="text" placeholder="Search ...." id="inputGroup" v-model="searchParamenter"
                          @input="loadAllPageble"/>
                    <span class="input-group-addon"
                          @click.prevent="searchRequestByName(pageNo , pageSize)">
                        <font-awesome-icon icon="search"/>
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div class="col-md-12 disable-highlight" unselectable="on" style="font-size: 20px">

              <span class="btn-space"
                    v-bind:class="[ this.pageNo === 0 || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                    @click="firstPage" title="go to first page">First
              </span>
                <span class="btn-space"
                      v-bind:class="[ this.pageNo === 0 || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                      @click="prevPage" title="go to previous page">
                <font-awesome-icon icon="angle-double-left"/>
              </span>

                <span class="btn-space"></span>
                <span class="btn-space"></span>

                <span class="btn-space"
                      v-bind:class="[ this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                      @click="nextPage" title="go to next page">
                <font-awesome-icon icon="angle-double-right"/>
              </span>
                <span class="btn-space"
                      v-bind:class="[ this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                      @click="lastPage" title="go to last page">Last
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <!--   END TOP PAGENATION AND SEARCH ELEMENTS   -->

      <table class="table table-striped">
        <thead>
        <tr>
          <th>#</th>
          <th>BL/Airway Bill/RCN</th>
          <th>File no.</th>
          <th>Customer</th>
          <th>Type</th>
          <th>Requested Date</th>
          <th>Status</th>
          <th class="special-limit" scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr :key="index" v-for="(list, index) in requests" v-if="!noDataFound">
          <td scope="row">{{ index + 1 +(pageNo * pageSize) }}</td>
          <td>
            {{ list.blNumber }}
          </td>
          <td v-if="list.fileNo">
            {{list.fileNo}}
          </td>
          <td v-else class="text-muted">
            <i>NIL</i>
          </td>
          <td v-if="list.customer !== null">
            <span class="more-customer-details" @click="showToolTip(list.requestId)">
              {{ (list.customer.name).toUpperCase() }}</span>
            <span v-if="isHoved.includes(list.requestId)"
                  style="position: absolute;bottom:50px;background-color: #FFFFFF; padding: 10px;
                  border-radius: 4px;border: 1px solid #d59a18;">
              <p><u><b>Customer Information</b> (click <a @click="hideToolTip(list.requestId)"
                                                          class="close-info">here</a> to close)</u></p>
              <b>TIN:</b> {{ list.customer.tin }} <br/>
              <b>VRN:</b> {{ list.customer.vrn }} <br/>
              <b>MOBILE:</b> {{ list.customer.mobile }} <br/>
              <b>EMAIL:</b> {{ list.customer.email }} <br/>
              <b>CUSTOMER TYPE:</b> {{ list.customer.customerType === 'ind001' ? 'Individual' : 'company' }} <br/>
            </span>
          </td>
          <td v-else>
            <I>NIL</I>
          </td>
          <td>
            {{ list.requestTypeName }}
          </td>
          <td>
            {{ dateTimeToHuman(list.requestDate) }}
          </td>
          <td>
            <span title="Application Sent" v-if="list.status === 'Request Submitted' ">
              <font-awesome-icon class="received-request" icon="circle"/>
            </span>
            <span title="Plan Approved" v-if="list.status === 'Plan Approved' ">
              <font-awesome-icon class="approved-plan" icon="circle"/>
            </span>
            <span title="Request Returned For Major Changes"
                  v-if="list.status === 'Request Returned For Major Changes' ">
              <font-awesome-icon class="declined-request" icon="circle"/>
            </span>
            <span title="Application Accepted" v-if="list.status === 'Request Accepted' ">
              <font-awesome-icon class="accepted-request" icon="circle"/>
            </span>
            <span title="Application Returned For Minor Changes"
                  v-if="list.status === 'Request Returned For Minor Changes' ">
              <font-awesome-icon class="returned-for-changes" icon="circle"/>
            </span>
            <span title="Application Resubmitted After Changes"
                  v-if="list.status === 'Request Resubmitted After Changes' ">
              <font-awesome-icon class="request-after-changes" icon="circle"/>
            </span>
            <span v-if="list.status === 'Request Assessed' " :title="list.status">
              <font-awesome-icon icon="circle" class="request-in-action"/>
            </span>
            <span v-if="list.status === 'Request Assessment Rejected' " title="Rejected Application Assessment">
              <font-awesome-icon icon="circle" class="rejected-app-assess"/>
            </span>
            <span v-if="list.status === 'Request Charged' " title="Application Charged">
              <font-awesome-icon icon="circle" class="request-charged"/>
            </span>
            <span v-if="list.status === 'Request Assessment Sent To GEPG' "
                  title="Application Bill Approved and Sent To GEPG">
              <font-awesome-icon icon="circle" class="app-bill-approved"/>
            </span>
            <span v-if="list.status === 'Request Bill Paid' " :title="list.status">
              <font-awesome-icon icon="circle" class="app-bill-paid"/>
            </span>
            <span v-if="list.status === 'Request Billed' " :title="list.status">
              <font-awesome-icon icon="circle" class="request-billed"/>
            </span>
            <span v-if="list.status === 'Request Closed' " :title="list.status">
              <font-awesome-icon icon="circle" class="app-closed"/>
            </span>
          </td>
          <td>
            <div>
              <div @click.prevent="openModal(list , actionButton = 'EditModal')" class=" all-fa-icons edit-fa-icon"
                  title="edit"
                  v-if="(list.status === requestStatuses.SUBMITTED
                    || list.status === requestStatuses.REJECTED_MAJOR
                    || list.status === requestStatuses.REJECTED_MINOR
                    || list.isAmended === requestStatuses.AMENDED
                    || list.status === requestStatuses.DRAFT)
                    && finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_PUT')">
                <font-awesome-icon icon="edit"/>
              </div>

              <div class=" all-fa-icons edit-fa-icon" style="width:17px;" v-else>

              </div>
            </div>
            <div>
              <div @click.prevent="openModal(list , actionButton = 'ShowModal')"
                  class="all-fa-icons fa-icons-space view-fa-icon" title="view"
                  v-if="finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_{REQUESTID}_GET')">
                <font-awesome-icon icon="eye"/>
              </div>
            </div>
            <div>
              <div @click.prevent="deleteSingleRequest(list.requestId , list.blNumber)"
                  class="all-fa-icons fa-icons-space delete-fa-icon"
                  title="delete"
                  v-if="(list.status === 'Request Submitted'
                    || list.status === 'Request Returned For Minor Changes'
                    || list.status === 'Request Returned For Major Changes')
                  && finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_{REQUESTID}_DELETE')">
                <font-awesome-icon icon="trash-alt"/>
              </div>

              <div class=" all-fa-icons edit-fa-icon" style="width:24px;" v-else>

              </div>
            </div>

            <div>
              <div @click.prevent="showModalRejectedMessage(list.rejectReason)"
                  class="all-fa-icons fa-icons-space envelop-fa-icon"
                  title="returned message for changes"
                  v-if="list.status === 'Request Returned For Major Changes' ||
                  list.status === 'Request Returned For Minor Changes' ||
                  list.isAmended === 'Request amendment' ||
                  list.isAmended === 'Request amendment done' &&
                  finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_{REQUESTID}_GET')">
                <font-awesome-icon icon="envelope"/>
              </div>
              <div class=" all-fa-icons edit-fa-icon" style="width:24px;" v-else>

              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="col-md-12 text-center bg-grey" v-if="noDataFound">
        No data found
      </div>

      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr/>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <span v-if="requests.length > 0">Showing page <b>{{pageNo + 1}} </b> of {{pageCount}}</span>
          </div>

          <div class="col-md-3 text-right">
            <pulse-loader :loading="loading" :color="color" :size="size" v-if="myloader"></pulse-loader>
          </div>

          <div class="col-md-6 text-right disable-highlight" unselectable="on" style="line-height: 5px;font-size: 20px">

              <span class="btn-space"
                    v-bind:class="[ this.pageNo === 0 || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                    @click="firstPage" title="go to first page">First
              </span>
            <span class="btn-space"
                  v-bind:class="[ this.pageNo === 0 || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage" title="go to previous page">
                <font-awesome-icon icon="angle-double-left"/>
              </span>

            <span class="btn-space"></span>
            <span class="btn-space"></span>

            <span class="btn-space"
                  v-bind:class="[ this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage" title="go to next page">
                <font-awesome-icon icon="angle-double-right"/>
              </span>
            <span class="btn-space"
                  v-bind:class="[ this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage" title="go to last page">Last
              </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->

    </card>

    <modal v-if="showModal">

      <div class="modal-title col-md-12" slot="header">
        <div class="row">
          <div class="col-md-12" v-if="EditModalDiv && ifRequestSubmitted">
            Add / Remove Attachment(s) for Application: File No. - [ <b>
            {{ editFileNo ? editFileNo : 'NIL' }}</b> ] |
            BL/Airway Bill/RCN - [ <b>{{ requestName }}</b> ]
          </div>
          <div class="col-md-12" v-if="EditModalDiv && !ifRequestSubmitted">
            Edit Application: File No. - [ <b>
            {{ editFileNo ? editFileNo : 'NIL' }}</b> ] |
            BL/Airway Bill/RCN - [ <b>{{ requestName }}</b> ]
          </div>
          <div class="col-md-12" v-if="AddModalDiv">
            <span v-if="!ifRequestSubmitted">
            Continue to attachment
            <br/>
            1) <b><i>BL/Airway Bill/RCN</i></b> that will be added will be recognized under the same application type
            <b><i>Import </i></b> or <b><i>Export</i></b>.
            <br/>
            2) user icon
            <font-awesome-icon icon="plus"/>
            to add <b><i>BL/Airway Bill/RCN</i></b> if there are more than entry.
            <br/>
            3) use icon
            <font-awesome-icon class="delete-fa-icon" icon="trash-alt"/> remove a <b><i>BL/Airway Bill/RCN</i></b>
              from an application
            <br/>
            4) click button <b>"Continue to attachment"</b> to attach required documents to your application only
              and only if all of your <b><i>BL/Airway Bill/RCN</i></b> have been added.
            </span>
            <span v-if="ifRequestSubmitted">
              Attach required attachment(s) for each <b><i>BL/Airway Bill/RCN</i></b> and click Close to leave the page.
            </span>
          </div>
          <div class="col-md-12" v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-10">
                Details for Application: File No. - [ <b>
                {{ singleRequest.fileNo ? singleRequest.fileNo : 'NIL' }}</b> ] |
                BL/Airway Bill/RCN - [ <b>{{ singleRequest.requestName }}</b> ]
              </div>
              <div class="col-md-2">
                <pulse-loader :color="color" :loading="loading" :size="size" v-if="loadingShowRequest"></pulse-loader>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="!showRejectedMessage">
            <hr/>
          </div>
        </div>
      </div>

      <div slot="body">
        <div v-if="showRejectedMessage">

          <card>

            <p>

              {{ rejectedMessage }}

            </p>

          </card>

          <div class="text-center row">
            <div class="col-md-7 text-right loader-div">

            </div>
            <div class="col-md-2 left loader-div-gif">

            </div>
            <div class="col-md-3">
              <button @click="closeModal()" class="btn btn-fill btn-outline-info float-right" type="button">
                Close
              </button>
            </div>
          </div>

        </div>

        <!--     EDIT FORM       -->
        <div v-if="EditModalDiv">
          <card v-if="!ifRequestSubmitted">
            <form @submit.prevent="editRequest" method="POST" v-if="EditModalDiv">
              <div class="row">
                <div class="col-md-6">
                  <base-input class="special-bottom" label="Shipping Document Number (BL/Airway Bill/RCN)"
                              placeholder="Shipping Document Number (BL/Airway Bill/RCN)"
                              type="text" v-model="editBlNumber" value="editBlNumber"></base-input>
                  <div v-if="requestNameError">
                    <div class="error" v-if="!$v.editBlNumber.required">This field is required</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="myselect" style="margin-top: 7px">Select regime type</label>
                  <select class="form-control" id="myselect1" name="myselect1" ref="editRegime">
                    <option disabled value="">Select regime type</option>
                    <option :value="regime.regimeId" v-bind:key="index"
                            v-for="(regime, index) in regimes" :selected="regimeId === regime.regimeId">
                      {{ regime.regimeName}}
                    </option>
                  </select>
                  <div v-if="requestUniqueIdentifierError">
                    <div class="error" v-if="!$v.editRegime.required">This field is required
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div class="row">
                <div class="col-md-12">
                  <label>Application Details</label>
                  <textarea class="special-bottom form-control col-md-12" placeholder="Request Details" rows="10"
                            type="textarea" v-model="description" value="description"></textarea>
                  <div v-if="descriptionError">
                    <div class="error" v-if="!$v.description.required">This field is required</div>
                  </div>
                </div>
              </div>

              <br/>

              <div class="text-center row">
                <div class="col-md-4 text-right loader-div">
                  <span v-if="sendRequestLoader">Please wait ....</span>
                </div>
                <div class="col-md-2 loader-div-gif">
                  <pulse-loader :color="color" :loading="loading" :size="size" v-if="sendRequestLoader"></pulse-loader>
                </div>
                <div class="col-md-6" v-if="EditModalDiv">
                  <button @click="closeModal()" class="btn btn-fill btn-outline-info float-right" type="button">
                    Close
                  </button>
                  <button class="btn btn-primary btn-space float-right custom-btn" data-dismiss="modal" type="submit">
                    Edit
                  </button>
                  <button @click.prevent="showAddOrRemoveAttachment" class="btn btn-primary btn-space  float-right"
                          data-dismiss="modal"
                          type="submit">
                    Add / Remove Attachment
                  </button>
                </div>
              </div>
            </form>
          </card>

          <br v-if="ifRequestSubmitted"/>

          <div class="col-md-12 alert-dismissible fade show" v-if="ifRequestSubmitted" role="alert"
                style="border-radius:5px;"
                v-bind:class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
                v-on:enter="enter">

            <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
            <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

            <button aria-label="Close" class="close close-custom" data-dismiss="alert" style="line-height:2px;"
                    type="button"
                    v-if="SuccessAlert" v-on:click="fadeMe">
              <span aria-hidden="true">&times;</span>
            </button>
            <button aria-label="Close" class="close close-custom" data-dismiss="alert" style="line-height:2px;"
                    type="button"
                    v-if="ErrorAlert" v-on:click="fadeMe">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <card v-if="ifRequestSubmitted">

            <div class="col-md-12" v-if="ifRequestSubmitted">
              <div class="row">
                <div class="col-md-10">
                  Attach document(s) for supporting application for [ <b>{{ editBlNumber }}</b> ]
                </div>
                <div class="col-md-2 text-right">
                  <pulse-loader :color="color" :loading="loading" :size="size" v-if="loadAttachmentLoader">

                  </pulse-loader>
                </div>
              </div>
            </div>

            <hr v-if="ifRequestSubmitted"/>

            <div v-if="ifRequestSubmitted">
              <table class="table table-striped" v-if="responseAttachmentSingle.length > 0">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th>Attachment Type</th>
                  <th scope="col">Download url</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-bind:key="index" v-for="(attachment , index) in responseAttachmentSingle">
                  <th scope="row">{{index + 1}}</th>
                  <td>{{ attachment.attachmentName }}</td>
                  <td class="text-primary" @click.prevent="getFileFromMinio(attachment.attachmentUrl , attachment.attachmentName)">
                    <a href=""><span class="text-success">view</span></a>
                  </td>
                  <td class="text-success">
                    Uploaded
                  </td>
                  <td>
                    <div
                      @click.prevent="deleteSingleRequestAttachment(attachment.requestAttachmentId, editBlNumber ,
                      attachment.attachmentName)"
                      class="all-fa-icons fa-icons-space delete-fa-icon" title="remove">
                      <font-awesome-icon icon="trash-alt"/>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <form enctype="multipart/form-data" v-if="ifRequestSubmitted">
              <div class="row">
                <div class="col-md-4">
                  <label>Search attachment type</label>
                  <model-list-select :list="options"
                                  v-model="documentTypeIdSingle"
                                  class="form-control"
                                  option-value="attachmentTypeId"
                                  option-text="attachmentTypesName"
                                  placeholder="attachment type">
                  </model-list-select>

                  <div v-if="documentTypeIdSingleError">
                    <div class="error">Select document type</div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="fileUpload btn btn-info btn-fill">
                    <span>Choose Document..</span>
                    <input @change.prevent="EditAttachment($event , requestId , editBlNumber)" class="upload"
                          id="first_upload" label="Upload File"
                          name="attachment"
                          type="file"/>
                  </div>
                  <label for="first_upload" class="label-upload">
                    {{ file ? '" ' + file + ' "' : 'No File Selected' }} </label>
                  <div v-if="pdfFormatSingleError" class="red-color">
                    Attach pdf document only
                  </div>
                </div>
                <div class="col-md-2">
                  <pulse-loader :color="color" :loading="loading" :size="size" class="float-center"
                                style="margin-top: 38px" v-if="uploadOneByOneSingle"></pulse-loader>
                </div>

              </div>
            </form>
          </card>

          <div class="text-center row">
            <div class="col-md-12" v-if="ifRequestSubmitted">
              <button @click="closeAttachModal()" class="btn btn-fill btn-outline-info btn-space float-right"
                      type="button">
                Send
              </button>
            </div>
          </div>
        </div>

        <!--      ADD FORM      -->
        <div v-if="AddModalDiv">
          <div class="content">
            <div class="col-md-12">
              <card v-if="!ifRequestSubmitted">
                <form @submit.prevent="saveRequest" method="post" v-if="!ifRequestSubmitted">
                  <div class="row">
                    <div class="col-md-12">
                      <label>Search TASAC customer</label>
                      <model-list-select :list="allCustomers"
                                        v-model="request.SingleCustomerName"
                                        class="form-control"
                                        option-value="customerId"
                                        option-text="name"
                                        placeholder="customer name">
                      </model-list-select>
                      <div v-if="SingleCustomerNameError">
                        <div class="error" v-if="!$v.request.SingleCustomerName.required">This field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label for="myselect">Select application type</label>
                      <select class="form-control" id="myselect" name="myselect"
                              @change="changeMyTittle(request.requestType)"
                              v-model="request.requestType">
                        <option disabled value="">Select application type</option>
                        <!-- <option
                          :value="requestType.requestType" v-bind:key="index"
                          v-for="(requestType, index) in getRequestType">{{ requestType.requestTypeName}}
                        </option> -->
                        <option :value="requestType.id" :key="index" v-for="(requestType, index) in getRequestTypes">
                                {{ requestType.name }}
                        </option>
                      </select>
                      <div v-if="requestTypeIdError">
                        <div class="error" v-if="!$v.request.requestType.required">This field is required</div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <label>Search Regime</label>
                      <model-list-select :list="regimes"
                                        v-model="request.requestUniqueIdentifier"
                                        class="form-control"
                                        option-value="regimeId"
                                        option-text="regimeName"
                                        placeholder="regime name">
                      </model-list-select>
                      <div v-if="requestUniqueIdentifierError">
                        <div class="error" v-if="!$v.request.requestUniqueIdentifier.required">This field is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row dynamic-bg" v-for="(row , index) in rows" v-bind:key="index">

                    <div class="col-md-12">
                      <hr/>
                      <b>{{index + 1}}</b>
                    </div>

                    <div class="col-md-10">
                      <base-input class="special-bottom" :label="myTittle"
                                  :placeholder="myTittle"
                                  type="text" v-model="row.requestName"></base-input>
                      <div v-if="requestNameError">
                        <div class="error" v-if="row.requestName === '' ">This field is required</div>
                      </div>
                    </div>
                    <!-- <div class="col-md-2 text-center remove-bl" title="remove BL/Airway Bill/RCN"
                        @click="deleteRow(row)">
                      <font-awesome-icon class="delete-fa-icon minus-bl" icon="trash-alt"/>
                    </div> -->

                    <div class="col-md-12">
                      <label>Application Details</label>
                      <textarea class="special-bottom form-control col-md-12" placeholder="Request Details" rows="8"
                                type="textarea" v-model="row.description"></textarea>
                      <div v-if="descriptionError">
                        <div class="error" v-if="row.description === '' ">This field is required</div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-12 add-bl" style="padding-right: 70px" title="add BL/Airway Bill/RCN"
                      @click="addRow">
                    <font-awesome-icon class="plus-bl" icon="plus" title="add BL/Airway Bill/RCN"/>
                  </div> -->

                  <hr/>

                  <div class="text-center row">
                    <div class="col-md-4 text-right loader-div">
                      <span v-if="sendRequestLoader">Please wait ....</span>
                    </div>
                    <div class="col-md-2 left loader-div-gif">
                      <pulse-loader :color="color" :loading="loading" :size="size"
                                    v-if="sendRequestLoader"></pulse-loader>
                    </div>
                    <div class="col-md-6" v-if="AddModalDiv">
                      <button @click="closeModal()" class="btn btn-fill btn-outline-info float-right" type="button"
                              v-if="!ifRequestSubmitted">
                        Close
                      </button>
                      <button class="btn btn-primary btn-space float-right custom-btn" data-dismiss="modal" type="submit">
                        Continue to attachment
                      </button>
                    </div>
                  </div>
                </form>
              </card>

              <br v-if="ifRequestSubmitted"/>

              <card v-if="ifRequestSubmitted" v-for="(request , index) in requestIdArray" v-bind:key="index">

                <div class="col-md-12 alert-dismissible fade show" v-if="displayFeedback.includes(request.requestMyId)"
                    role="alert"
                    style="border-radius:5px;"
                    v-bind:class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
                    v-on:enter="enter">

                  <strong v-if="SuccessAlert">Successifully!</strong> <span
                  v-if="SuccessAlert">{{ SuccessMessage }}</span>
                  <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

                  <button aria-label="Close" class="close close-custom" data-dismiss="alert" style="line-height:2px;"
                          type="button"
                          v-if="SuccessAlert" v-on:click="fadeMe">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <button aria-label="Close" class="close close-custom" data-dismiss="alert" style="line-height:2px;"
                          type="button"
                          v-if="ErrorAlert" v-on:click="fadeMe">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="col-md-12" v-if="ifRequestSubmitted">
                  <div class="row">
                    <div class="col-md-10">
                      Attach document(s) for supporting application for [ <b>{{ requestBlArray[index] }}</b> ]
                    </div>
                    <div class="col-md-2 text-right">
                      <!-- <pulse-loader :color="color" :loading="loading"
                      :size="size" v-if="loadAttachmentLoader"></pulse-loader>-->
                    </div>
                  </div>
                </div>

                <hr v-if="ifRequestSubmitted"/>

                <div v-if="ifRequestSubmitted">
                  <table class="table table-striped" v-if="request.responseAttachment.length > 0">
                    <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Attachment Type</th>
                      <th scope="col">Download url</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-bind:key="index" v-for="(attachment , index) in request.responseAttachment"
                        v-if="attachment.requestId === request.requestMyId">
                      <th scope="row">{{index + 1}}</th>
                      <td>{{ attachment.attachmentName }}</td>
                      <td class="text-primary" @click.prevent="getFileFromMinio(attachment.attachmentUrl , attachment.attachmentName)">
                        <a href=""><span class="text-success">view</span></a>
                      </td>
                      <td class="text-success">
                        Uploaded
                      </td>
                      <td>
                        <div @click.prevent="deleteArrayRequestAttachment(attachment.requestAttachmentId ,
                          request.requestMyId , requestBlArray[index] , attachment.attachmentName)"
                            class="all-fa-icons fa-icons-space delete-fa-icon" title="remove">
                          <font-awesome-icon icon="trash-alt"/>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <form enctype="multipart/form-data" v-if="ifRequestSubmitted">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Search attachment type</label>

                      <model-list-select :list="options"
                                        v-model="documentTypeId[request.requestMyId]"
                                        class="form-control"
                                        option-value="attachmentTypeId"
                                        option-text="attachmentTypesName"
                                        placeholder="attachment type">
                      </model-list-select>

                      <div v-if="documentTypeIdErrorMany.includes(request.requestMyId)">
                        <div class="error">Select document type</div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="fileUpload btn btn-info btn-fill">
                        <span>Choose Document..</span>
                        <input @change.prevent="saveAttachment($event , request.requestMyId , requestBlArray[index])"
                              class="upload" id="first_upload2" label="Upload File"
                              name="attachment"
                              type="file"/>
                      </div>
                      <label for="first_upload2" class="label-upload"
                            v-if="uploadOneByOne.includes(request.requestMyId)">
                        {{ file ? '" ' + file + ' "' : 'No File Selected'}} </label>
                      <div v-if="pdfFormatError.includes(request.requestMyId)" class="red-color">
                        Attach pdf document only
                      </div>
                    </div>
                    <div class="col-md-2">
                      <pulse-loader :color="color" :loading="loading" :size="size" class="float-center"
                                    style="margin-top: 38px"
                                    v-if="uploadOneByOne.includes(request.requestMyId)"></pulse-loader>
                    </div>

                  </div>
                </form>
              </card>
            </div>

            <div class="text-center row">
              <div class="col-md-12" v-if="ifRequestSubmitted">
                <button @click="closeAttachManyModal()" class="btn btn-fill btn-outline-info btn-space float-right"
                        type="button">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--      SHOW FORM      -->
        <div v-if="ShowModalDiv">
          <card v-if="!loadingShowRequest">
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12 text-bold">
                    <b>Application Type</b>
                  </div>
                  <div class="col-md-12 text-value special-data-bg">
                    {{ singleRequest.requestTypeName }}
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="row" v-if="singleRequest.regime !== undefined ">
                  <div class="col-md-12 text-bold">
                    <b>Regime Type</b>
                  </div>
                  <div class="col-md-12 text-value special-data-bg" v-if="singleRequest.regime !== null">
                    {{ singleRequest.regime.regimeName }}
                  </div>
                  <div class="col-md-12 text-value special-data-bg" v-else>
                    <i>NIL</i>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12 text-bold">
                    <b>Application BL/Airway Bill/RCN</b>
                  </div>
                  <div class="col-md-12 text-value special-data-bg">
                    {{ singleRequest.blNumber }}
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-md-12 text-bold">
                <b>Description</b>
              </div>
              <div class="col-md-12 text-value special-data-bg" v-if="singleRequest.description === null ||
              singleRequest.description === '' ">
                <i class="text-muted">NIL</i>
              </div>
              <div class="col-md-12 text-value special-data-bg">
                {{ singleRequest.description }}
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-10">
                    <b>Associated Attachment(s)</b>
                  </div>
                  <div class="col-md-2 text-right">
                    <pulse-loader :color="color" :loading="loading" :size="size" v-if="loadAttachmentLoader">

                    </pulse-loader>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <table class="table table-striped" v-if="responseAttachmentSingle.length > 0">
                    <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th>Attachment Type</th>
                      <th scope="col">Download url</th>
                      <th scope="col">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-bind:key="index" v-for="(attachment , index) in responseAttachmentSingle">
                      <th scope="row">{{index + 1}}</th>
                      <td>{{ attachment.attachmentName }}</td>
                      <td class="text-primary"@click.prevent="getFileFromMinio(attachment.attachmentUrl , attachment.attachmentName)">
                        <a href=""><span class="text-success">view</span></a>
                      </td>
                      <td class="text-success">
                        Uploaded
                      </td>
                    </tr>
                    </tbody>
                  </table>

                  <div class="table table-striped" v-if="responseAttachmentSingle.length === 0">
                    <div style="margin-left: 15px">No attachment</div>
                  </div>

                </div>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-md-12">
                <b>Application progress</b>

              </div>
              <div class="col-md-12 text-value special-data-bg">
                status:
                <span title="Application Sent" v-if="singleRequest.status === 'Request Submitted' ">
                  Application Submitted <font-awesome-icon class="received-request" icon="circle"/>
                </span>
                <span title="singleRequest.status" v-if="singleRequest.status === 'Plan Approved' ">
                  {{ singleRequest.status }} <font-awesome-icon class="approved-plan" icon="circle"/>
                </span>
                <span title="Application Returned For Major Changes"
                      v-if="singleRequest.status === 'Request Returned For Major Changes' ">
                  Application Returned For Major Changes <font-awesome-icon class="declined-request" icon="circle"/>
                </span>
                <span title="Application Returned For Minor Changes"
                      v-if="singleRequest.status === 'Request Returned For Minor Changes' ">
                  Application Returned For Minor Changes <font-awesome-icon class="declined-request" icon="circle"/>
                </span>
                <span title="Application Resubmitted After Changes"
                      v-if="singleRequest.status === 'Request Resubmitted After Changes' ">
                  Application Resubmitted After Changes <font-awesome-icon class="request-after-changes" icon="circle"/>
                </span>
                <span title="Request Accepted" v-if="singleRequest.status === 'Request Accepted' ">
                  Application Accepted <font-awesome-icon class="accepted-request" icon="circle"/>
                </span>
                <span v-if="singleRequest.status === 'Request Assessed' " title="Application Assessed">
                  Application Assessed <font-awesome-icon icon="circle" class="request-in-action"/>
                </span>
                <span v-if="singleRequest.status === 'Request Assessment Rejected' "
                      title="Rejected Application Assessment">
                  Rejected Application Assessment <font-awesome-icon icon="circle" class="rejected-app-assess"/>
                </span>
                <span v-if="singleRequest.status === 'Request Charged' " title="Application Charged">
                  Application Charged <font-awesome-icon icon="circle" class="request-charged"/>
                </span>
                <span v-if="singleRequest.status === 'Request Assessment Sent To GEPG' "
                      title="Application Bill Approved and Sent To GEPG">
                  Application Bill Approved and Sent To GEPG
                  <font-awesome-icon icon="circle" class="app-bill-approved"/>
                </span>
                <span v-if="singleRequest.status === 'Request Bill Paid' " title="Application Bill Paid">
                  Application Bill Paid <font-awesome-icon icon="circle" class="app-bill-paid"/>
                </span>
                <span v-if="singleRequest.status === 'Request Billed' " title="Application Billed">
                  Application Billed <font-awesome-icon icon="circle" class="request-billed"/>
                </span>
                <span v-if="singleRequest.status === 'Request Closed' " title="Application Closed">
                  Application Closed <font-awesome-icon icon="circle" class="app-closed"/>
                </span>
              </div>
            </div>

            <hr/>

            <div class="text-center row" v-if="!docViewer">
              <div class="col-md-12">
                <button @click="closeModal" class="btn btn-default btn-fill float-right" >Back</button>
              </div>
            </div>

          </card>
        </div>
      </div>
    </modal>


    <modal v-if="showConfirmModal">

      <div class="modal-title col-md-12" slot="header">
      </div>

      <div slot="body">
        <!--      CONFIRM LEAVING WITHOUT ATTACHING ANY DOC   -->
        <div v-if="ShowConfirmModalDiv">
          <card class="text-center">

            <p>{{ attachmentAlertMessage }}</p>

            <div class="text-center row">
              <div class="col-md-12 text-center">
                <button @click="markApplicationAsSubmited" class="btn btn-fill btn-outline-info btn-space float-right" type="button">
                  No!, Send
                </button>
                <button @click="closeBackModal" class="btn btn-success btn-space float-right" type="button">
                  Yes!
                </button>
              </div>
            </div>

          </card>
        </div>
      </div>
    </modal>

    <modal v-if="showConfirmManyModal">

      <div class="modal-title col-md-12" slot="header">
      </div>

      <div slot="body">
        <!--      CONFIRM LEAVING WITHOUT ATTACHING ANY DOC   -->
        <div v-if="ShowConfirmManyModalDiv">
          <card class="text-center">

            <p> {{ attachmentManyAlertMessage }} </p>

            <br/><br/><br/>

            <div class="text-center row">
              <div class="col-md-12 text-center">
                <button @click="markApplicationAsSubmited" class="btn btn-fill btn-outline-info btn-space float-right" type="button">
                  No!, Send
                </button>
                <button @click="closeBackModal()" class="btn btn-success btn-space float-right" type="button">
                  Yes!
                </button>
              </div>
            </div>

          </card>
        </div>
      </div>
    </modal>
    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Downloading requested file, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

  import API from "../../../api/index"
  import { CFA_REQUEST_STATUSE } from "../../../mixins/constants";
  import CFA_API from "../../../api/cfa"
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import Modal from '../../../components/Inputs/Modal'
  import ModalConfirm from '../../../components/Inputs/ModalConfirmExit'
  import ModalEditPlan from '../../../components/Inputs/ModalEditPlan'
  import { mapActions, mapGetters } from 'vuex'
  import {ModelListSelect} from 'vue-search-select'
  import {required} from "vuelidate/lib/validators"
  import GlobalModal from '../../../components/globalLoader'
import { CFA_APPLICATION_TYPES } from '../../../mixins/constants'


  export default {

    name: "ClearingRequest",

    created() {

      this.$store.dispatch("pagenames/currentPageName", "Applications List");

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.loadDocumentType();
      // this.loadRequestType();
      this.loadAllCustomers();
      this.loadRegime();
      this.loadPageableRequest(this.pageNo, this.pageSize);

    },

    data() {

      return {

        rows: [
          {'requestName': '', 'description': ''}
        ],
        requestStatuses: CFA_REQUEST_STATUSE,
        loader: false,
        color: 'green',
        size: '15px',
        loading: true,
        sendRequestLoader: false,
        attachLoader: false,
        uploadOneByOne: [],
        displayFeedback: [],
        uploadOneByOneSingle: false,
        uploadedMessage: "",
        userDetails: {},
        allCustomers: [],
        regimes: [],

        myTittle: "Shipping Document Number (BL/Airway Bill/RCN)",

        request: {

          requestName: "",
          description: "",
          customerId: "",
          requestType: "",
          status: "SUBMITTED",
          isAmended: "DEFAULT_AMENDED",
          requestUniqueIdentifier: "",
          SingleCustomerName: "",

        },

        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        docPageNo: 0,
        docPageCount: 0,
        docSortBy: "requestId",
        sortBy: "requestId",
        docPageSize: 0,
        getPageableDoc: 0,
        searchParamenter: "",
        hasSearchedVariable: false,
        reloadAllRequest: false,

        documentTypeId: [],
        documentTypeIdSingle: "",
        file: "",
        fileData: "",
        isHoved: [],
        options: [],
        getDocType: [],
        getRequestTypes: CFA_APPLICATION_TYPES,
        responseAttachment: [],
        responseAttachmentSingle: [],
        requests: [],
        singleRequest: {},
        requestSeached: {},
        finalUserPermissions: [],
        permissionGroups: [],
        requestBlArray: [],
        requestIdArray: [],
        incomingData: [],
        documentTypeIdErrorMany: [],

        requestName: "",
        description: "",
        RequestTypeId: "",
        requestId: "",
        status: "",
        regimeId: "",
        requestDate: "",
        rejectedMessage: "",
        customerId: "",
        rejectReason: "",
        editBlNumber: "",
        editFileNo: "",
        editRequestNumber: "",
        editCustomerId: "",
        stationId: "",
        transportMethodId: "",
        approveDate: "",
        approvedBy: "",
        SingleCustomerName: "",
        getAttachmentTypeName: "",
        attachmentAlertMessage: "",
        attachmentManyAlertMessage: "",
        isAmended: "",
        editRegime: "",
        globalLoader: "",
        responseRequestName: "",

        SuccessMessage: "",
        ErrorMessage: "",
        SuccessAlert: false,
        ErrorAlert: false,
        deciderReturn: false,

        showConfirmModal: false,
        showConfirmManyModal: false,

        ShowConfirmModalDiv: false,
        ShowConfirmManyModalDiv: false,

        showModal: false,
        myloader: false,
        editMyLoader: false,
        EditModalDiv: false,
        AddModalDiv: false,
        ShowModalDiv: false,
        ifRequestSubmitted: false,
        ifRequestSubmittedEdited: false,
        loadingShowRequest: false,
        showRejectedMessage: false,
        pdfFormatError: [],
        pdfFormatSingleError: false,
        showSection: true,
        requestNameError: false,
        descriptionError: false,
        documentTypeIdError: false,
        documentTypeIdSingleError: false,
        requestTypeIdError: false,
        requestUniqueIdentifierError: false,
        docViewer: false,
        hasUserUploadedAnyThing: false,
        noDataFound: false,
        loadAttachmentLoader: false,
        SingleCustomerNameError: false,
        requestIdToUpdate: ''

      }

    },

    computed: {

      ...mapGetters("clearingrequests", [

        "getValuesBeforeEdit",
        "getNameBeforeDelete",
        "getRequestId",
        "getAttachmentNameBeforeDelete",
        "getCustomerNameBeforeDelete"

      ]),

      ...mapGetters('global', [

      'getGlobalLoader'

    ])
    },

    validations: {

      request: {

        requestType: {required},
        requestName: {required},
        description: {required},
        requestUniqueIdentifier: {required},
        SingleCustomerName: {required}

      },

      searchParamenter: {required},

      requestType: {required},
      editBlNumber: {required},
      description: {required},
      documentTypeId: {required}

    },

    components: {

      PulseLoader,
      Modal,
      ModalEditPlan,
      ModelListSelect,
      ModalConfirm,
      GlobalModal

    },

    methods: {

      ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    loadParameters (fileUrl, fileName) {

      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
      })

    },

    getFileFromMinio (fileUrl, fileName) {

      this.loadParameters(fileUrl, fileName).then(() => {

      this.loadFileFromMinio({}).then((res) => {

          if(res.status === 204) {

              this.loadNotification('warn', 4000, 'File Download',
              'File not found, please contact system admin',
              'response', 1000 , true  , true)

          } else {

            this.loadNotification('success', 4000, 'File Download',
              'File has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true)


          }

            this.globalLoader = this.getGlobalLoader

        }).catch(() => {

          this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

          this.globalLoader = this.getGlobalLoader

        })

        this.globalLoader = this.getGlobalLoader

      }).catch(() => {

      })

    },

    markApplicationAsSubmited () {
      CFA_API.applicationStatusToSubmit(this.requestId, 'SUBMITTED').then(
          response => {

            if (response.data.status) {

              this.closeModal()
              this.loadNotification('success', 4000, 'New Application', 'Application has been successfully submitted', 'response', 1000 , true  , true)

            } else {

              this.loadNotification('error', 4000, 'New Application', response.data.message, 'response', 1000 , true  , true)

            }

          }
        ).catch(() => {

        });

    },

      toggle() {

        this.showSection = !this.showSection;

      },

      showToolTip(id) {

        this.isHoved = [id];

      },

      hideToolTip(id) {

        this.isHoved.splice(this.isHoved.indexOf(id), 1)

      },

      changeMyTittle (flag) {

          if (flag === 'EXP') {

          this.myTittle = 'REFERENCE NUMBER'

          } else {

          this.myTittle = 'Shipping Document Number (BL/Airway Bill/RCN)'
          }

      },

      onChange(event) {

        this.pageSize = event.target.value;
        this.pageNo = 0;
        if (this.hasSearchedVariable) {

          this.searchRequestByName(this.pageNo, this.pageSize)

        } else {

          this.loadPageableRequest(this.pageNo, this.pageSize);

        }
        this.docPageNo = this.pageNo;
        this.docPageSize = this.pageSize;
        this.docPageCount = this.pageCount;
        this.docSortBy = this.sortBy;


      },

      nextPage() {

        this.pageNo++;
        this.docPageNo = this.pageNo;
        if (this.hasSearchedVariable) {

          this.searchRequestByName(this.pageNo, this.pageSize)

        } else {

          this.loadPageableRequest(this.pageNo, this.pageSize);

        }

      },

      prevPage() {

        this.pageNo--;
        this.docPageNo = this.pageNo;
        if (this.hasSearchedVariable) {

          this.searchRequestByName(this.pageNo, this.pageSize)

        } else {

          this.loadPageableRequest(this.pageNo, this.pageSize);

        }

      },

      firstPage() {

        this.pageNo = 0;
        this.docPageNo = this.pageNo;
        if (this.hasSearchedVariable) {

          this.searchRequestByName(this.pageNo, this.pageSize)

        } else {

          this.loadPageableRequest(this.pageNo, this.pageSize);

        }

      },

      lastPage() {

        this.pageNo = this.pageCount - 1;
        this.docPageNo = this.pageNo;

        if (this.hasSearchedVariable) {

          this.searchRequestByName(this.pageNo, this.pageSize)

        } else {

          this.loadPageableRequest(this.pageNo, this.pageSize);

        }


      },

      loadAllPageble() {

        if (this.searchParamenter === "" && this.hasSearchedVariable === true) {

          this.loadPageableRequest(this.pageNo, this.pageSize);
          this.noDataFound = false;
          this.hasSearchedVariable = false;
          this.reloadAllRequest = false;
          this.searchParamenter = "";

        }

      },

      reloadByIcon() {

        this.loadPageableRequest(this.pageNo, this.pageSize);

        this.noDataFound = false;
        this.hasSearchedVariable = false;
        this.reloadAllRequest = false;
        this.searchParamenter = "";

      },

      searchRequestByName(pageNo, pageSize) {

        if (this.$v.searchParamenter.$invalid) {


        } else {

          this.myloader = true;
          this.reloadAllRequest = true;
          this.hasSearchedVariable = true;
          this.noDataFound = false;

          API.searchAllRequest(pageNo, pageSize, this.searchParamenter).then(
            response => {

              if (response.data.status) {

                this.myloader = false;

                if (response.data.data.length > 0) {

                  this.requests = response.data.data;
                  this.pageCount = response.data.message;
                  this.docPageCount = this.pageCount;
                  this.docPageNo = this.pageNo;
                  this.docPageSize = this.pageSize;
                  this.myloader = false;

                } else {

                  this.noDataFound = true;
                  this.pageCount = response.data.message;
                  this.docPageCount = this.pageCount;
                  this.docPageNo = this.pageNo;
                  this.docPageSize = this.pageSize;
                  this.myloader = false;

                }

              } else {

                this.noDataFound = true;
                this.pageCount = response.data.message;
                this.docPageCount = this.pageCount;
                this.docPageNo = this.pageNo;
                this.docPageSize = this.pageSize;
                this.getPageableDoc = response.data.data;
                this.myloader = false;

              }
            }
          ).catch(e => {

            this.myloader = false;

          });

        }

      },

      loadPageableRequest(pageNo, pageSize) {

        return new Promise((resolve, reject) => {

          this.myloader = true;

          API.getRequestAll(pageNo, pageSize).then(
            response => {

              if (response.data.status) {

                this.pageCount = response.data.message;
                this.docPageCount = this.pageCount;
                this.docPageNo = this.pageNo;
                this.docPageSize = this.pageSize;
                this.requests = response.data.data;
                this.myloader = false;

              } else {

                this.myloader = false;

              }

              resolve();
            }
          ).catch(e => {

            this.ErrorMessage = " Requests Not loaded, please contact system administrator";
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.SuccessMessage = "";
            this.SuccessAlert = false;
            this.myloader = false;

            reject(e);

          });

        });
      },

      loadRegime() {

        API.getRegime().then(
          response => {

            if (response.data.status) {

              this.regimes = response.data.data;

            } else {

            }
          }
        ).catch(e => {

          this.ErrorMessage = " Regimes not loaded";
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = "";
          this.SuccessAlert = false;
          this.myloader = false;

        });
      },

      loadRequestAll() {

        API.getRequestAll().then(
          response => {

            if (response.data.status) {

              this.requests = response.data.data;

            } else {

            }
          }
        ).catch(e => {


        });

      },

      addRow: function () {

        this.rows.push({'requestName': '', 'description': ''});

      },
      deleteRow: function (row) {

        // this.rows.$remove(row);
        this.rows.splice(this.rows.indexOf(row), 1);

      },

      showModalRejectedMessage(data) {

        this.rejectedMessage = data;
        this.showRejectedMessage = true;
        this.EditModalDiv = false;
        this.AddModalDiv = false;
        this.ShowModalDiv = false;
        this.showModal = true;

      },

      loadASingleRequest(data) {

        this.loadingShowRequest = true;

        API.getFullRequestDetails(data).then(
          response => {

            if (response.data.status) {

              this.loadingShowRequest = false;

              this.singleRequest = response.data.data;
              this.loadRequestAttachmentSingle(response.data.data.requestId)

            } else {

            }
          }
        ).catch(e => {

          this.loadingShowRequest = false;

        });

      },

      loadDocumentType() {

        API.getAttachments().then(response => {

            if (response.data.status) {

              for (let i = 0; i < response.data.data.length; i++) {

                if (response.data.data[i].attachmentGroupCode !== 'CRA') {

                  this.options.push(response.data.data[i])

                }

              }

            } else {

            }
          }
        ).catch(() => {


        });

      },

      loadAllCustomers() {

        this.myloader = true;

        API.getCustomerAll().then(response => {

            if (response.data.status) {

              this.allCustomers = response.data.data;

            } else {

            }
          }
        ).catch(() => {

          this.ErrorMessage = ", TASAC Customers not loaded, please contact system administrator";
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = "";
          this.SuccessAlert = false;
          this.myloader = false;

        });
      },

      // loadRequestType() {

      //   this.myloader = true;

      //   API.getRequestType().then(
      //     response => {

      //       if (response.data.status) {

      //         this.getRequestType = response.data.data;
      //         this.myloader = false;

      //       } else {

      //       }

      //     }
      //   ).catch(e => {

      //     this.ErrorMessage = ", Application types not loaded, please contact system administrator";
      //     this.SuccessAlert = false;
      //     this.ErrorAlert = true;
      //     this.SuccessMessage = "";
      //     this.SuccessAlert = false;
      //     this.myloader = false;

      //   });
      // },

      showAddOrRemoveAttachment() {

        this.ifRequestSubmitted = true;
        this.deciderReturn = true;
        this.hasUserUploadedAnyThing = true;
        this.loadRequestAttachmentSingle(this.requestId);

      },

      saveRequest() {

        if (this.$v.request.requestUniqueIdentifier.$invalid || this.$v.request.requestType.$invalid ||
          this.$v.request.SingleCustomerName.$invalid) {

          if (this.$v.request.requestType.$invalid) {
            this.requestTypeIdError = true;
          }
          if (this.$v.request.requestUniqueIdentifier.$invalid) {
            this.requestUniqueIdentifierError = true;
          }
          if (this.$v.request.SingleCustomerName.$invalid) {
            this.SingleCustomerNameError = true;
          }

        } else {

          let errorFree = 0;

          for (let w = 0; w < this.rows.length; w++) {

            if (this.rows[w].requestName === "" || this.rows[w].description === "") {

              if (this.rows[w].requestName === "") {
                this.requestNameError = true;
              }
              if (this.rows[w].description === "") {
                this.descriptionError = true;
              }

              errorFree++;

            }

          }

          if (errorFree === 0) {

            this.sendRequestLoader = true;

            let requestBls = [];

            for (let y = 0; y < this.rows.length; y++) {

              let singleBl = {

                "blNumber": this.rows[y].requestName,
                "regimeId": this.request.requestUniqueIdentifier,
                "description": this.rows[y].description

              };

              requestBls.push(singleBl);

            }

            let request = {

              customerId: this.request.SingleCustomerName,
              requestBls,
              requestName: null,
              requestType: this.request.requestType

            };
            this.sendRequestLoader = true;

            API.postRequest(JSON.stringify(request)).then(
              response => {

                if (response.data.status) {

                  this.incomingData = response.data.data;
                  this.request.requestUniqueIdentifier = "";
                  this.request.requestType = "";
                  this.request.SingleCustomerName = "";
                  this.loadPageableRequest(this.pageNo, this.pageSize);

                  for (let ww = 0; ww < this.rows.length; ww++) {

                    this.rows[ww].requestName = "";
                    this.rows[ww].description = "";

                  }


                  for (let a = 0; a < this.incomingData.length; a++) {

                    this.requestId = this.incomingData[a].requestId;
                    this.requestBlArray.push(this.incomingData[a].blNumber);
                    let newObject = {
                      "requestMyId": this.incomingData[a].requestId,
                      "responseAttachment": []
                    };

                    this.requestIdArray.push(newObject);

                  }

                  // this.$store.dispatch("clearingrequests/requestid", response.data.data.requestId);

                  setTimeout(() => {

                    this.ifRequestSubmitted = true;
                    this.sendRequestLoader = false;
                    this.hasUserUploadedAnyThing = true;

                  }, 2000);

                } else {
                  this.myloader = false;
                  this.sendRequestLoader = false;
                  this.notifyUser("error","Error",response.data.message);
                }

              }
            ).catch(e => {

              this.ErrorMessage = " Application not added, please contact system administrator";
              this.SuccessMessage = "";
              this.SuccessAlert = false;
              this.ErrorAlert = true;
              this.sendRequestLoader = false;
              this.request.requestName = "";
              this.request.description = "";
              this.closeModal();

            });

          } else {


          }

        }

      },

      saveAttachment(event, requestId, responseRequestBl) {

        for (let z = 0; z < this.requestIdArray.length; z++) {

          if (this.requestIdArray[z].requestMyId === requestId) {

            this.fileData = event.target.files[0];

            if (this.documentTypeId[this.requestIdArray[z].requestMyId] === undefined ||
              this.fileData.type !== 'application/pdf') {

              if (this.documentTypeId[this.requestIdArray[z].requestMyId] === undefined) {

                this.documentTypeIdErrorMany = [this.requestIdArray[z].requestMyId];

              }
              if (this.fileData.type !== 'application/pdf') {

                this.pdfFormatError = [this.requestIdArray[z].requestMyId];

              }

            } else {

              this.uploadOneByOne = [this.requestIdArray[z].requestMyId];
              this.displayFeedback = [this.requestIdArray[z].requestMyId];

              this.file = this.fileData.name;

              let formData = new FormData();

              formData.append("file", this.fileData);
              formData.append("requestId", requestId);
              formData.append("attachmentTypeId", this.documentTypeId[this.requestIdArray[z].requestMyId]);

              // setTimeout(() => {


              API.uploadRequestAttachment(formData).then(
                response => {
                  if (response.data.status) {

                    this.SuccessMessage = ", Attachment [ " + this.file + " ] for application for " +
                      "[ " + responseRequestBl + " ] has been saved";
                    this.SuccessAlert = true;
                    this.ErrorMessage = "";
                    this.ErrorAlert = false;
                    this.fileData = "";
                    this.loadRequestAttachment(response.data.data.requestId);
                    this.pdfFormatError = [];
                    this.uploadOneByOne = [];
                    this.documentTypeId = [];
                    this.documentTypeIdErrorMany = [];
                    this.file = "";

                    if (this.deciderReturn) {

                      this.editRequest();

                    }

                    setTimeout(() => {

                      this.uploadedMessage = "";

                    }, 7000);

                  } else {

                  }
                }
              ).catch(e => {

                this.editMyLoader = false;
                this.ErrorMessage = " Attachment not uploaded, please contact system administrator";
                this.SuccessMessage = "";
                this.SuccessAlert = false;
                this.ErrorAlert = true;
                this.attachLoader = false;

              });

              // }, 2500);

            }

          }

        }


      },

      EditAttachment(event, requestId, responseRequestBl) {

        this.fileData = event.target.files[0];

        if (this.documentTypeIdSingle === "" || this.fileData.type !== 'application/pdf') {

          if (this.documentTypeIdSingle === "") {

            this.documentTypeIdSingleError = true;

          }
          if (this.fileData.type !== 'application/pdf') {

            this.pdfFormatSingleError = true;

          }

        } else {

          this.uploadOneByOneSingle = true;

          this.file = this.fileData.name;

          let formData = new FormData();

          formData.append("file", this.fileData);
          formData.append("requestId", requestId);
          formData.append("attachmentTypeId", this.documentTypeIdSingle);

          // setTimeout(() => {


          API.uploadRequestAttachment(formData).then(
            response => {
              if (response.data.status) {

                this.SuccessMessage = ", Attachment [ " + this.file + " ] for application for " +
                  "[ " + responseRequestBl + " ] has been saved";
                this.SuccessAlert = true;
                this.ErrorMessage = "";
                this.ErrorAlert = false;
                this.fileData = "";
                this.loadRequestAttachmentSingle(response.data.data.requestId);
                this.pdfFormatSingleError = false;
                this.documentTypeIdSingleError = false;
                this.uploadOneByOneSingle = false;
                this.documentTypeIdSingle = "";
                this.file = "";

                if (this.deciderReturn) {

                  this.editRequest();
                  this.hasUserUploadedAnyThing = true;

                }

                setTimeout(() => {

                  this.uploadedMessage = "";

                }, 7000);

              } else {

              }
            }
          ).catch(e => {

            this.editMyLoader = false;
            this.ErrorMessage = " Attachment not uploaded, please contact system administrator";
            this.SuccessMessage = "";
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.attachLoader = false;

          });

          // }, 2500);

        }

      },

      loadRequestAttachment(data) {

        this.loadAttachmentLoader = true;

        for (let q = 0; q < this.requestIdArray.length; q++) {

          if (this.requestIdArray[q].requestMyId === data) {

            API.getFullRequestDetails(data).then(
              response => {

                if (response.data.status) {

                  this.requestIdArray[q].responseAttachment = response.data.data.requestAttachDtos;

                  this.loadAttachmentLoader = false;

                } else {

                }

              }
            ).catch(e => {

              this.ErrorMessage = ", Application attachment not loaded, please contact system administrator";
              this.SuccessAlert = false;
              this.ErrorAlert = true;
              this.SuccessMessage = "";
              this.SuccessAlert = false;

            });

          }

        }

      },

      loadRequestAttachmentSingle(data) {

        this.loadAttachmentLoader = true;

        API.getFullRequestDetails(data).then(
          response => {

            if (response.data.status) {

              this.responseAttachmentSingle = response.data.data.requestAttachDtos;

              this.loadAttachmentLoader = false;

            } else {

            }

          }
        ).catch(e => {

          this.ErrorMessage = ", Application attachment not loaded, please contact system administrator";
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = "";
          this.SuccessAlert = false;

        });

      },

      editRequest() {

        let stationId = "";
        let transportMethodId = "";
        let status = "";
        let isAmended = "";

        if (this.stationId === 0) {

          stationId = null;

        } else {

          stationId = this.stationId;

        }

        if (this.transportMethodId === 0) {

          transportMethodId = null;

        } else {

          transportMethodId = this.transportMethodId;

        }

        if (this.status === "Request Returned For Major Changes" ||
          this.status === "Request Returned For Minor Changes") {

          status = "RETURNED";

        } else {

          status = this.status;

        }

        if (this.isAmended === "Request amendment") {

          isAmended = "AMENDED";

        } else {

          isAmended = this.isAmended

        }

        if (this.deciderReturn) {

          this.sendRequestLoader = true;

          let requestData = {

            approveDate: this.approveDate,
            approvedBy: this.approvedBy,
            blNumber: this.editBlNumber,
            customerId: this.customerId,
            description: this.description,
            fileNo: this.editFileNo,
            rejectReason: this.rejectReason,
            requestDate: this.requestDate,
            requestId: this.requestId,
            regimeId: this.regimeId,
            requestName: this.requestName,
            requestNumber: this.editRequestNumber,
            requestType: this.requestType,
            stationId: stationId,
            status: status,
            isAmended: isAmended,
            transportMethodId: transportMethodId

          };

          API.updateSingleRequest(JSON.stringify(requestData)).then(
            response => {

              if (response.data.status) {

                if (!this.hasUserUploadedAnyThing) {

                  this.SuccessMessage = `${this.updatedValueStatus(this.getValuesBeforeEdit,
                    [response.data.data.blNumber, response.data.data.description], "Request",
                    response.data.data.blNumber)}`;
                  this.ErrorMessage = "";
                  this.SuccessAlert = true;
                  this.ErrorAlert = false;

                }
                this.sendRequestLoader = false;

                if (this.hasSearchedVariable) {

                  this.searchRequestByName(this.pageNo, this.pageSize)

                } else {

                  this.loadPageableRequest(this.pageNo, this.pageSize);

                }

                window.scrollTo(10, 0);

                this.$store.dispatch("clearingrequests/valuesBeforeEdit", []);
                this.$store.dispatch("clearingrequests/requestid", response.data.data.requestId);


              } else {


              }

            }
          ).catch(e => {

            this.ErrorMessage = ", Application not edited, please contact system administrator";
            this.SuccessMessage = "";
            this.SuccessAlert = false;
            this.ErrorAlert = false;
            this.sendRequestLoader = false;
            this.request.requestName = "";
            this.request.description = "";

          });


        } else {


          if (this.$v.editBlNumber.$invalid || this.$v.description.$invalid) {

            if (this.$v.editBlNumber.$invalid) {
              this.requestNameError = true;
            }
            if (this.$v.description.$invalid) {
              this.descriptionError = true;
            }

          } else {

            this.sendRequestLoader = true;

            let requestData = {

              approveDate: this.approveDate,
              approvedBy: this.approvedBy,
              blNumber: this.editBlNumber,
              customerId: this.customerId,
              description: this.description,
              fileNo: this.editFileNo,
              rejectReason: this.rejectReason,
              requestDate: this.requestDate,
              requestId: this.requestId,
              requestName: this.requestName,
              regimeId: this.$refs.editRegime.value,
              requestNumber: this.editRequestNumber,
              requestType: this.requestType,
              stationId: stationId,
              status: status,
              isAmended: this.isAmended,
              transportMethodId: transportMethodId

            };

            API.updateSingleRequest(JSON.stringify(requestData)).then(
              response => {

                if (response.data.status) {

                  this.SuccessMessage = `${this.updatedValueStatus(this.getValuesBeforeEdit,
                    [response.data.data.blNumber, response.data.data.description], "Request",
                    response.data.data.blNumber)}`;
                  this.ErrorMessage = "";
                  this.SuccessAlert = true;
                  this.ErrorAlert = false;
                  this.sendRequestLoader = false;
                  this.closeModal();

                  if (this.hasSearchedVariable) {

                    this.searchRequestByName(this.pageNo, this.pageSize)

                  } else {

                    this.loadPageableRequest(this.pageNo, this.pageSize);

                  }

                  window.scrollTo(10, 0);
                  this.closeModal();

                  this.$store.dispatch("clearingrequests/valuesBeforeEdit", []);
                  this.$store.dispatch("clearingrequests/requestid", response.data.data.requestId);


                } else {


                }

              }
            ).catch(e => {

              this.ErrorMessage = " Application not edited, please contact system administrator";
              this.SuccessMessage = "";
              this.SuccessAlert = false;
              this.ErrorAlert = true;
              this.sendRequestLoader = false;
              this.request.requestName = "";
              this.request.description = "";
              this.closeModal();

            });

          }

        }

      },

      deleteSingleRequestAttachment(data, blNumber, AttachmentName) {

        this.$store.dispatch("clearingrequests/customerNameBeforeDelete", blNumber);
        this.$store.dispatch("clearingrequests/attachmentNameBeforeDelete", AttachmentName);

        this.$dialog
          .confirm(
            "If you delete attachment [ " +
            AttachmentName +
            " ], it'll be gone forever.",
            {
              loader: true
            }
          )
          .then(dialog => {
            // setTimeout(() => {

            API.deleteRequestAttachment(data)
              .then(response => {
                if (response.data.status) {
                  this.SuccessMessage = ", Attachment [ " + this.getAttachmentNameBeforeDelete + "] has been " +
                    "removed from BL/Airway Bill/RCN  [ " + this.getCustomerNameBeforeDelete + " ]";
                  this.$store.dispatch("clearingrequests/customerNameBeforeDelete", "");
                  this.$store.dispatch("clearingrequests/attachmentNameBeforeDelete", "");
                  this.ErrorMessage = "";
                  this.SuccessAlert = true;
                  this.ErrorAlert = false;
                  this.loadRequestAttachmentSingle(this.requestId);

                  if (this.deciderReturn) {

                    this.editRequest();

                  }

                  window.scrollTo(10, 0);

                } else {

                }
              })
              .catch(e => {

                this.ErrorMessage = " Record not deleted, please contact system administrator";
                this.SuccessMessage = "";
                this.SuccessAlert = false;
                this.ErrorAlert = true;

              });

            dialog.close();
            // }, 2500);
          })
          .catch(() => {

            this.myloader = false;
            this.SuccessAlert = false;

          });
      },

      deleteArrayRequestAttachment(data, myRequestId, blNumber, AttachmentName) {

        for (let k = 0; k < this.requestIdArray.length; k++) {

          if (this.requestIdArray[k].requestMyId === myRequestId) {

            this.$store.dispatch("clearingrequests/customerNameBeforeDelete", blNumber);
            this.$store.dispatch("clearingrequests/attachmentNameBeforeDelete", AttachmentName);

            this.$dialog
              .confirm(
                "If you delete attachment [ " +
                AttachmentName +
                " ], it'll be gone forever.",
                {
                  loader: true
                }
              )
              .then(dialog => {
                // setTimeout(() => {

                API.deleteRequestAttachment(data)
                  .then(response => {
                    if (response.data.status) {
                      this.SuccessMessage = ", Attachment [ " + this.getAttachmentNameBeforeDelete + " ] has " +
                        "been removed from BL/Airway Bill/RCN  [ " + this.getCustomerNameBeforeDelete + " ]";
                      this.$store.dispatch("clearingrequests/customerNameBeforeDelete", "");
                      this.$store.dispatch("clearingrequests/attachmentNameBeforeDelete", "");
                      this.ErrorMessage = "";
                      this.SuccessAlert = true;
                      this.ErrorAlert = false;
                      this.loadRequestAttachment(myRequestId);

                      if (this.deciderReturn) {

                        this.editRequest();

                      }

                      window.scrollTo(10, 0);

                    } else {

                    }
                  })
                  .catch(e => {

                    this.ErrorMessage = ", Record not deleted, please contact system administrator";
                    this.SuccessMessage = "";
                    this.SuccessAlert = false;
                    this.ErrorAlert = true;

                  });

                dialog.close();
                // }, 2500);
              })
              .catch(() => {

                this.myloader = false;
                this.SuccessAlert = false;

              });

          }
        }

      },

      deleteSingleRequest(data, name) {

        this.$store.dispatch("clearingrequests/nameBeforeDelete", name);

        this.$dialog
          .confirm(
            "If you delete Request [ " +
            name +
            " ], it'll be gone forever.",
            {
              loader: true
            }
          )
          .then(dialog => {
            // setTimeout(() => {

            API.deleteARequest(data)
              .then(response => {
                if (response.data.status) {
                  this.SuccessMessage = ", Application has been deleted"; //[ " + this.getNameBeforeDelete + " ]
                  this.$store.dispatch("clearingrequests/nameBeforeDelete", "");
                  this.ErrorMessage = "";
                  this.SuccessAlert = true;
                  this.ErrorAlert = false;

                  if (this.hasSearchedVariable) {

                    this.searchRequestByName(this.pageNo, this.pageSize)

                  } else {

                    this.loadPageableRequest(this.pageNo, this.pageSize);

                  }

                  window.scrollTo(10, 0);

                } else {

                }
              })
              .catch(e => {

                this.ErrorMessage = " Record not deleted, please contact system administrator";
                this.SuccessMessage = "";
                this.SuccessAlert = false;
                this.ErrorAlert = true;

              });

            dialog.close();
            // }, 2500);
          })
          .catch(() => {

            this.myloader = false;
            this.SuccessAlert = false;

          });
      },


      fadeMe: function () {

        if (this.SuccessAlert) {

          this.SuccessAlert = !this.SuccessAlert;

        } else {

          this.ErrorAlert = !this.ErrorAlert;

        }

        this.displayFeedback = [];

      },

      enter: function (el, done) {

        let that = this;

        setTimeout(function () {

          that.SuccessAlert = false;
          that.ErrorAlert = false;

        }, 3000); // hide the message after 3 seconds
      },

      openModal(requestDetails = null, actionButton) {

        if (actionButton === 'EditModal') {

          this.requestType = requestDetails.requestType;
          this.requestName = requestDetails.blNumber;
          this.responseRequestName = requestDetails.requestName;
          this.description = requestDetails.description;
          this.requestId = requestDetails.requestId;
          this.status = requestDetails.status;
          this.requestDate = requestDetails.requestDate;
          this.rejectReason = requestDetails.rejectReason;
          this.customerId = requestDetails.customerId;
          this.loadRequestAttachment(requestDetails.requestId);
          this.editBlNumber = requestDetails.blNumber;
          this.editFileNo = requestDetails.fileNo;
          this.editRequestNumber = requestDetails.requestNumber;
          this.editCustomerId = requestDetails.customerId;
          this.approveDate = requestDetails.approveDate;
          this.approvedBy = requestDetails.approvedBy;
          this.regimeId = requestDetails.regime.regimeId;
          this.isAmended = requestDetails.isAmended;

          if (requestDetails.station === null) {

            this.stationId = 0;

          } else {

            this.stationId = requestDetails.station.stationId;

          }

          if (requestDetails.transportMethod === null) {

            this.transportMethodId = 0;

          } else {

            this.transportMethodId = requestDetails.transportMethod.transportMethodId;

          }

          this.EditModalDiv = true;
          this.showConfirmModal = false;
          this.showModal = true;
          this.AddModalDiv = false;
          this.ShowModalDiv = false;
          this.ShowConfirmModalDiv = false;
          this.showRejectedMessage = false;
          this.$store.dispatch("clearingrequests/requestid", requestDetails.requestId);
          this.$store.dispatch("clearingrequests/valuesBeforeEdit",
            [requestDetails.blNumber, requestDetails.description]);

        } else if (actionButton === 'AddModal') {

          this.EditModalDiv = false;
          this.AddModalDiv = true;
          this.ShowModalDiv = false;
          this.showModal = true;
          this.showConfirmModal = false;
          this.ShowConfirmModalDiv = false;
          this.showRejectedMessage = false;

        } else {


          this.loadASingleRequest(requestDetails.requestId);
          this.EditModalDiv = false;
          this.AddModalDiv = false;
          this.ShowModalDiv = true;
          this.showModal = true;
          this.showConfirmModal = false;
          this.ShowConfirmModalDiv = false;
          this.showRejectedMessage = false;

        }

      },

      openConfirmModal() {

        this.showConfirmModal = true;
        this.ShowConfirmModalDiv = true;

        if (this.documentTypeIdSingle !== "") {

          API.getIndividualAttachment(this.documentTypeIdSingle).then(
            response => {

              if (response.data.status) {

                this.getAttachmentTypeName = response.data.data.attachmentTypesName;

                this.attachmentAlertMessage = "You have selected attachment [ " + this.getAttachmentTypeName + " ] " +
                  "but you have not uploaded any file, do you want to attach respective file?"

              } else {

                this.attachmentAlertMessage = "It's seems you are trying to attach document for unknown attachment " +
                  "type, please contact system administrator";

              }
            }
          ).catch(() => {

            // this.state.errors.push(e)

          });

        } else {

          this.attachmentAlertMessage = "Do you have any other document(s) to attach?";

        }

      },

      openConfirmManyModal() {

        this.ShowConfirmManyModalDiv = true;
        this.showConfirmManyModal = true;
        let rightFlag = 0;

        for (let c = 0; c < this.requestIdArray.length; c++) {

          if (typeof this.documentTypeId[this.requestIdArray[c].requestMyId] !== 'undefined') {

            if (this.documentTypeId[this.requestIdArray[c].requestMyId] !== []) {

              API.getIndividualAttachment(this.documentTypeId[this.requestIdArray[c].requestMyId]).then(
                response => {

                  if (response.data.status) {

                    this.getAttachmentTypeName = response.data.data.attachmentTypesName;

                    this.attachmentManyAlertMessage = "You have selected attachment " +
                      "[ " + this.getAttachmentTypeName + " ] for BL/Airway Bill/RCN " +
                      "[ " + this.requestBlArray[c] + " ] but you have not uploaded any file, do you " +
                      "want to attach respective file?"

                  } else {

                    this.attachmentManyAlertMessage = "It's seems you are trying to attach document for " +
                      "unknown attachment type, please contact system administrator";

                  }
                }
              ).catch(e => {

                // this.state.errors.push(e)

              });

              rightFlag++;

            } else {


            }

          } else {

          }

        }

        if (rightFlag === 0) {

          this.attachmentManyAlertMessage = "Do you have any other document(s) to attach?";

        }

      },

      closeBackModal() {

        this.ShowConfirmModalDiv = false;
        this.showConfirmModal = false;

        this.ShowConfirmManyModalDiv = false;
        this.showConfirmManyModal = false;

        this.documentTypeIdSingle = "";
        this.documentTypeId = [];
        this.attachmentManyAlertMessage = "";
        this.attachmentAlertMessage = "";
        this.singleRequest = {};
        this.requestSeached = {};

      },

      closeAttachModal() {

        if (this.hasUserUploadedAnyThing) {

          this.openConfirmModal();

        } else {

          this.showModal = false;
          this.EditModalDiv = false;
          this.AddModalDiv = false;

          this.documentTypeIdSingle = "";
          this.documentTypeId = [];

          this.ifRequestSubmitted = false;

          this.descriptionError = false;
          this.requestNameError = false;
          this.descriptionError = false;
          this.showRejectedMessage = false;
          this.ShowConfirmModalDiv = false;
          this.showConfirmModal = false;

          this.documentTypeIdError = false;
          this.pdfFormatError = [];

          this.request.requestName = "";
          this.request.description = "";
          this.request.requestType = "";


          //after upload empty state
          this.$store.dispatch("clearingrequests/requestid", "");

          if (this.deciderReturn) {

            this.loadPageableRequest(this.pageNo, this.pageSize);

          }

        }

      },

      closeAttachManyModal() {

        if (this.hasUserUploadedAnyThing) {

          this.openConfirmManyModal();

        } else {

          this.showModal = false;
          this.EditModalDiv = false;
          this.AddModalDiv = false;

          this.documentTypeIdSingle = "";
          this.documentTypeId = [];

          this.ifRequestSubmitted = false;

          this.descriptionError = false;
          this.requestNameError = false;
          this.descriptionError = false;
          this.showRejectedMessage = false;
          this.ShowConfirmManyModalDiv = false;
          this.showConfirmManyModal = false;

          this.documentTypeIdError = false;
          this.documentTypeIdErrorMany = [];
          this.pdfFormatError = [];

          this.request.requestName = "";
          this.request.description = "";
          this.request.requestType = "";

          //after upload empty state
          this.$store.dispatch("clearingrequests/requestid", "");

          if (this.deciderReturn) {

            this.loadPageableRequest(this.pageNo, this.pageSize);

          }

        }

      },

      closeModal() {

        this.showModal = false;
        this.EditModalDiv = false;
        this.AddModalDiv = false;

        this.ifRequestSubmitted = false;

        this.descriptionError = false;
        this.requestNameError = false;
        this.descriptionError = false;
        this.showRejectedMessage = false;
        this.ShowConfirmModalDiv = false;
        this.showConfirmModal = false;
        this.ShowConfirmManyModalDiv = false;
        this.showConfirmManyModal = false;

        this.SuccessMessage = "";
        this.ErrorMessage = "";
        this.SuccessAlert = false;
        this.ErrorAlert = false;

        this.documentTypeIdError = false;
        this.documentTypeIdErrorMany = [];
        this.documentTypeIdSingleError = false;
        this.pdfFormatError = [];

        this.request.requestName = "";
        this.request.description = "";
        this.request.requestType = "";
        this.request.requestUniqueIdentifier = "";

        this.documentTypeIdSingle = "";
        this.documentTypeId = [];
        this.requestIdArray = [];
        this.attachmentManyAlertMessage = "";
        this.attachmentAlertMessage = "";
        this.singleRequest = {};
        this.requestSeached = {};

        this.myTittle = "Shipping Document Number (BL/Airway Bill/RCN)";

        for (let ww = 0; ww < this.rows.length; ww++) {

          this.rows[ww].requestName = "";
          this.rows[ww].description = "";

        }

        this.rows = [
          {'requestName': '', 'description': ''}
        ];

        this.SingleCustomerNameError = false;
        this.requestUniqueIdentifierError = false;
        this.requestUniqueIdentifierError = false;

        //after upload empty state
        this.$store.dispatch("clearingrequests/requestid", "");

        if (this.deciderReturn) {

          this.loadPageableRequest(this.pageNo, this.pageSize);

        }

      }

    }

  }
</script>

<style scoped>

  .my-swal {
    z-index: 5915 !important;
  }

  .red-color {
    color: red;
    margin-top: 13px;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    top: 23px;
    font-weight: normal;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .label-upload {
    position: relative;
    top: 23px;
  }

  .fileUpload span {
    cursor: pointer;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

</style>

<style>

  .dynamic-bg {
    background-color: rgba(220, 220, 220, .2);
    border-radius: 3px;
  }

  .remove-bl .minus-bl {
    margin-top: 42px;
  }

  .remove-bl .minus-bl:hover, .add-bl .plus-bl {
    cursor: pointer;
  }

  .add-bl {
    text-align: right;
  }

  .search .form-group .input-group .input-group-addon {
    width: 40px;
    text-align: center;
    padding-top: 3.5px;
    background-color: rgba(220, 220, 220, .3);
  }

  .search .form-group .input-group #inputGroup {
    width: 250px;
    padding-top: 3.5px;
  }

  .search .form-group .input-group .input-group-addon:hover {
    cursor: pointer;
    background-color: rgba(220, 220, 220, .5);
  }

  .bg-grey {
    background-color: rgba(220, 220, 220, .5);
    padding: 10px 0 10px 0;
  }

  .refresh-all {
    margin-top: 6px;
    margin-right: 20px
  }

  .refresh-all:hover {
    cursor: pointer;
  }

  form.search input[type=text]:focus {
    border: 2px solid #DCDCDC;
  }
</style>
