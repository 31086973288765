<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="filteredRequests"
        :table-data-keys="tableDataKeys"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :my-loader="myLoader"
        :reload-all-request="reloadAllRequest"
        :my-first-loader="myFirstLoader"
        :no-data-found="noDataFound"
        :search-parameter-prop="searchParameter"
        :has-no-action="!finalUserPermissions.includes('API_TALLIED-DE-STUFFING_GET-BY-ID_{TALLIEDDESTUFFINGID}_GET')"
        :loading-title="'Please wait while system is loading destuffing tally sheets'"
        :has-manage-button="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_GET-BY-ID_{TALLIEDDESTUFFINGID}_GET')"
        :placeholder="'Search by voyage or call id'"
        :total-elements="totalElements"
        :total-pages="totalPages"
        :is-first="isFirst"
        :is-last="isLast"
        :disabled="true"
        :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Mark As Completed Tallying' , 'class' : 'btn-danger'}]"
        :page-number="pageNumber"
        @onChange="onChange"
        @goToPage="goToPage"
        @goToApplication="goToApplication"
        @goToFinishTallying="goToFinishTallying"/>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import GlobalModal from '../../../components/globalLoader'
  import DataTable from '../../../components/DataTable/updatedTable'
  import Modal from '../../../components/Inputs/Modal'

  export default {

    name: 'DestuffingTally',

    components: {

      PulseLoader,
      GlobalModal,
      DataTable,
      Modal

    },

    created() {
      this.$store.dispatch('pagenames/currentPageName', 'Destuffing Tally Sheet');

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.loadPageableRequestOnCreated()
    },
    computed: {

      ...mapGetters('talliedStuffingOrStripping', [

        'getRequestList',
        'getPageCount',
        'getMyFirstLoader',
        'getNoDataFound',
        'getSuccessAlert',
        'getErrorMessage',
        'getErrorAlert',
        'getSuccessMessage',
        'getReloadAllRequest',
        'getMyLoader',
        'getSearchParameter',
        'getHasSearchedVariable',
        'getPageNo',
        'getRequestId',
        'getTotalElement',
        'getTotalPage',
        'getPageNumber',
        'getIsFirst',
        'getIsLast'
      ]),

      ...mapGetters({

        getTalliedDocumentId: "shippingtallying/getTalliedDocumentId",
        getSuccessAlertS: 'shippingtallying/getSuccessAlert',
        getErrorAlertS: 'shippingtallying/getErrorAlert',
        getMyLoaderS: 'shippingtallying/getMyLoader',
      }),

    },

    data() {
      return {

        pageNo: 0,
        pageSize: 15,
        totalElements: '',
        totalPages: '',
        isFirst: false,
        isLast: false,
        pageNumber: '',
        stuffingType: 'DESTUFFING',

        color: 'green',
        size: '12px',
        loading: true,


        searchParameter: '',
        hasSearchedVariable: false,
        reloadAllRequest: false,
        noDataFound: false,
        pdfFormatError: false,
        loadAllHeaderLoader: false,
        allAttachmentVerified: false,
        myLoader: false,
        myFirstLoader: false,
        isSearchable: false,
        requests: [],
        filteredRequests: [],

        ifThereIsParam: '',

        finalUserPermissions: [],
        tableHeaders: ['Voyage', 'Vessel Name', 'Completed', 'Created At'],
        tableDataKeys: ['voyage', 'vessel', 'completed', 'createdAt'],

      }
    },

    methods: {

      ...mapActions('talliedStuffingOrStripping', [

        'loadTalliedStuffingOrStrippingOnCreated',
        'loadTalliedStuffingOrStripping',

      ]),

      ...mapActions("shippingtallying", [

        "markAsCompletedStuffingDestuffingTally",

      ]),

      loadParameters() {
        return new Promise((resolve) => {
          this.$store.dispatch('talliedStuffingOrStripping/pageSizing', this.pageSize);
          this.$store.dispatch('talliedStuffingOrStripping/pageNumbering', this.pageNo);
          this.$store.dispatch('talliedStuffingOrStripping/stuffingtyping', this.stuffingType);

          resolve()
        })
      },

      setAllGetters(flag) {
        this.requests = this.getRequestList;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
        this.noDataFound = this.getNoDataFound;
        this.SuccessMessage = this.getSuccessAlert;
        this.ErrorMessage = this.getErrorMessage;
        this.ErrorAlert = this.getErrorAlert;
        this.SuccessAlert = this.getSuccessMessage;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;

        this.totalElements = this.getTotalElement;
        this.totalPages = this.getTotalPage;
        this.pageNumber = this.getPageNumber;
        this.isFirst = this.getIsFirst;
        this.isLast = this.getIsLast;
        this.sortingData(this.requests);
      },

      setGettersAfterCompletingTallyDocument(flag) {

        this.requests = this.getRequestList;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
        this.noDataFound = this.getNoDataFound;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;

        this.totalElements = this.getTotalElement;
        this.totalPages = this.getTotalPage;
        this.pageNumber = this.getPageNumber;
        this.isFirst = this.getIsFirst;
        this.isLast = this.getIsLast;

      },

      loadPageableRequestOnCreated() {
        this.loadParameters().then(() => {
          this.loadTalliedStuffingOrStrippingOnCreated({}).then(() => {
            this.setAllGetters('onCreated')
          }).catch(() => {

          });

          this.myFirstLoader = this.getMyFirstLoader
        }).catch(() => {

        })
      },

      sortingData(data) {
        for (let x = 0; x < data.length; x++) {

          const requestObject = {
            ...data[x],
            voyage: data[x].notification !== null ? data[x].notification.voyage : '',
            vessel: data[x].notification !== null ? data[x].notification.vesselName : '',
          };
          this.filteredRequests.push(requestObject)
        }
      },

      loadPageableRequestsAfterCreated() {
        this.loadParameters().then(() => {
          this.loadTalliedStuffingOrStripping({}).then(() => {
            this.setAllGetters('afterCreated')
          });

          this.myLoader = this.getMyLoader
        }).catch(() => {

        })
      },

      onChange(event) {
        this.$store.dispatch('talliedStuffingOrStripping/pageSizing', event);
        this.$store.dispatch('talliedStuffingOrStripping/pageNumbering', 0);

        this.pageSize = this.getPageSize;
        this.pageNo = this.getPageNo;

        if (this.getHasSearchedVariable) {
          this.searchRequestByName(this.getSearchParameter)
        } else {
          this.loadTalliedStuffingOrStripping()
        }
      },

      goToFinishTallying(object) {
        this.$store.dispatch("shippingtallying/tallieddocumentiding", object.id);
        this.$dialog.confirm("You are about to mark tallied document as complete <br><br>" +
          " Voyage - [ " + object.voyage + " ] <br>" +
          " Cargo Type - [ " + object.cargoType + " ] <br>" +
          " Operation Type - [ " + this.stuffingType + " ] <br><br>" +
          " Are you sure!.", {
          loader: true,
          html: true

        }).then((dialog) => {
          this.markAsCompletedStuffingDestuffingTally(this.getTalliedDocumentId).then(() => {
            this.myLoader = this.getMyLoaderS;
            if (this.getSuccessAlertS) {
              this.loadNotification('success', 4000, 'Request Message',
                'Tallied Document with voyage [ ' + object.voyage + ' ] has been marked as complete',
                'response', 1000, true, true);
            }

            if (this.getErrorAlertS) {
              this.loadNotification('error', 4000, 'Request Message',
                'Tallied Document with voyage [ ' + object.voyage + ' ] has been marked as complete',
                'response', 1000, false, true);
            }
            this.loadPageableRequestOnCreated({}).then(() => {
              this.setGettersAfterCompletingTallyDocument('onCreated');
            }).catch(() => {

            });
            this.myFirstLoader = this.getMyFirstLoader;
          }).catch(() => {

          });
          this.myLoader = this.getMyLoader;
          dialog.close();
        });

      },

      goToApplication(documentId) {
        document.cookie = "browser-local-cashed-url-back=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
        this.$router.push("/shipping-tallying/tally-sheet-details/" + documentId.id);
      },

      goToPage(page) {
        this.pageNo = page;
        this.loadTalliedStuffingOrStripping()
      }
    }
  }

</script>

<style scoped>

</style>
