<template>
  <div>
    <card v-if="myFirstLoader">
      <div class="row">
        <div class="col-md-12 text-center">
          <b>Please wait while system is loading disbursment payment request details </b>
        </div>
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader
            :color="color"
            :size="size"
          />
        </div>
      </div>
    </card>
    <card style="font-size: 12px" v-if="!myFirstLoader">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-justified"
            style="padding-left: 10px;padding-right: 10px">
            <li>
              <button @click.prevent="approveDisbursmentPaymentRequest"
                      class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn" v-if="paymentRequestDetails.status!=='Approved'">
                Approve
              </button>
            </li>
            <li>
              <button @click.prevent="downloadDaPdf"
                      class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
                Download PDA
              </button>
            </li>
          </ul>
        </div>
      </div>
    </card>
    <card style="font-size: 12px" v-if="!myFirstLoader">
      <div class="row">
        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Payment Request Details
            </h6>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Proforma Number.
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.invoiceNo }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Reference Number
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.referenceNo }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Payment Type
              </div>
              <div class="col-md-2 div-table div-table-value"
                    v-if="paymentRequestDetails.serviceProvider !== undefined">
                {{ paymentRequestDetails.serviceProvider.government ? 'Government' : 'Non Government' }}
              </div>
            </div>
            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title"
                    v-if="paymentRequestDetails.serviceProvider !== undefined">
                {{ paymentRequestDetails.serviceProvider.government ? 'GePG Control Number' : 'Bank Account Number' }}
              </div>
              <div class="col-md-2 div-table div-table-value"
                    v-if="paymentRequestDetails.serviceProvider !== undefined">
                {{ paymentRequestDetails.serviceProvider.government ? paymentRequestDetails.gepgControlNo :
                paymentRequestDetails.serviceProvider.accountNo }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Payment Request Status
              </div>
              <div class="col-md-2 div-table div-table-value" style="text-transform: uppercase">
                {{ paymentRequestDetails.status }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Payment Deadline
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.paymentDeadline }}
              </div>
            </div>
            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Payment Amount
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.paymentAmount }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Payment Vat
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.paymentVat }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Payment Total
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.paymentTotal }}
              </div>

            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Payment Deadline
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.paymentDeadline }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Vessel Name
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.vesselName }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Voyage Number
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.voyageNumber }}
              </div>
            </div>
            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Status
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.status }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Approved By
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.approvedBy }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Approved Date
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ paymentRequestDetails.approvedDate }}
              </div>
            </div>
            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Description
              </div>
              <div class="col-md-10 div-table div-table-value">
                {{ paymentRequestDetails.description }}
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>
      <br>
      <div class="legend" v-if="paymentRequestDetails.serviceProvider">
        <h6 class="legend-title">
          Service Provider Details
        </h6>

        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            Name
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ paymentRequestDetails.serviceProvider.name }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Account Number
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ paymentRequestDetails.serviceProvider.accountNo }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            TIN
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ paymentRequestDetails.serviceProvider.tinNo }}
          </div>
        </div>
        <br>

        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            VRN
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ paymentRequestDetails.serviceProvider.vrn }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Phone Number
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ paymentRequestDetails.serviceProvider.phoneNumber }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Sector TYpe
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ paymentRequestDetails.serviceProvider.government ? 'Government' : 'Non Government' }}
          </div>
        </div>
        <br>

        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            Address
          </div>
          <div class="col-md-10 div-table div-table-value">
            {{ paymentRequestDetails.serviceProvider.address }}
          </div>
        </div>
      </div>
      <br>
      <div class="legend" v-if="paymentRequestDetails.subDisbursementAccount &&
      paymentRequestDetails.subDisbursementAccount.principalParticularDto">
        <h6 class="legend-title">
          Principal Particular Details
        </h6>

        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            Name
          </div>
          <div class="col-md-2 div-table div-table-value pointer text-success" @click="$router.push({name:'PrincipalItems',params:{principalId:paymentRequestDetails.subDisbursementAccount.principalParticularDto.principalParticularId}},)">
            {{ paymentRequestDetails.subDisbursementAccount.principalParticularDto.name }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Account Number
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ paymentRequestDetails.subDisbursementAccount.principalParticularDto.accountNo }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Address
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ paymentRequestDetails.subDisbursementAccount.principalParticularDto.address }}
          </div>
        </div>
        <br>

        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            Carrier Code
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ paymentRequestDetails.subDisbursementAccount.principalParticularDto.carrierCode }}
          </div>
        </div>
      </div>

      <br>

      <div class="legend" v-if="paymentRequestDetails.invoicePaymentItems !== undefined && paymentRequestDetails.invoicePaymentItems.length > 0">
        <h6 class="legend-title">
          Service Payment Details
        </h6>

        <data-table
          v-if="paymentRequestDetails.invoicePaymentItems !== undefined"
          :tableHeaders="tableInvoiceHeaders"
          :tableData="paymentRequestDetails.invoicePaymentItems"
          :tableDataKeys="tableInvoiceDataKeys"
          :myFirstLoader="myFirstLoader"
          :noDataFound="noDataFound"
          :has-filter="false"
          :has-pagination="false"
          :is-pageable="false"
          :has-manage-button="false"
          :permissions="[ {key: 'searchPermission', value: 'DISBURSEMENT_SUB_LIST_GET'} ]">
        </data-table>

      </div>

      <br />

      <disbursement-payment-attachments
        :attachments="disbursementInvoicePaymentAttachments"
        :disbursement-invoice-payment="paymentRequestDetails"
        :show-form="false"
        :show-close="false"
        :show-delete="false"
        @closeModal="closeModal">
      </disbursement-payment-attachments>

      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-default btn-fill btn-space custom-btn-all"
            style="float:right;"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo"/> &nbsp; Back
          </button>
        </div>
      </div>
    </card>

    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">System is downloading {{ getFileName }}, Please wait</div>
          <div class="col-md-12">
            <br/>
          </div>
          <div class="col-md-12">
            <pulse-loader :color="color" :size="size"></pulse-loader>
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>
<script>

  import PulseLoader from 'vue-spinner/src/PulseLoader'
  import GlobalModal from '../../../../components/globalLoader'
  import {mapActions, mapGetters} from 'vuex'
  import DisbursementPaymentAttachments from "../disbursementPaymentAttachments";

  export default {

    name: '',

    components: {
      DisbursementPaymentAttachments,
      PulseLoader,
      GlobalModal,
      DataTable: () => import('../../../../components/DataTable/dataTable')

    },

    computed: {

      ...mapGetters('disbursmentpaymentrequest', [

        'getDisbursmentPaymentRequestObject',
        'getMyFirstLoader',
        'getMyLoader'

      ]),

      ...mapGetters('global', [

        'getGlobalLoader',
        'getFileName'

      ]),

    },

    data() {

      return {

        paymentRequestDetails: {},
        disbursementInvoicePaymentAttachments:[],
        id: '',
        myFirstLoader: false,
        noDataFound: false,
        globalLoader: false,
        viewAttachments: false,
        approvingPaymentRequestLOader: false,
        color: 'green',
        size: '12px',

        tableInvoiceHeaders: ['Service Name', 'Quantity', 'Factor', 'Rate', 'Amount', 'VAT', 'Total'],
        tableInvoiceDataKeys: ['description', 'quantity', 'factor', 'rate', 'amount', 'vat', 'total'],

        tableItemHeaders: ['Item Name', 'Quantity', 'Factor', 'Rate', 'Amount', 'VAT', 'Total'],
        tableItemDataKeys: ['description', 'quantity', 'factor', 'rate', 'amount', 'vat', 'total']

      }

    },

    created() {

      this.id = this.$route.params.id;
      this.loadDisbursmentPaymentRequestDetails();
      this.$store.dispatch('pagenames/currentPageName', 'Sub Disbursment Account Payment Request')

    },

    methods: {

      ...mapActions('disbursmentpaymentrequest', [
        'getDisbursementInvoicePaymentRequestDetailsById',
        'approveDisbursementInvoicePaymentRequest'
      ]),

      ...mapActions('global', [

        'downloadBlobDocument'

      ]),

      loadDisbursmentPaymentRequestDetails() {

        this.$store.dispatch('disbursmentpaymentrequest/requestid', this.id).then(() => {
          this.getDisbursementInvoicePaymentRequestDetailsById({}).then(() => {
            this.myFirstLoader = this.getMyFirstLoader;
            this.paymentRequestDetails = this.getDisbursmentPaymentRequestObject
            this.disbursementInvoicePaymentAttachments = this.paymentRequestDetails.invoicePaymentAttachments;
          }).catch((e) => {
            this.myFirstLoader = false
          });
          this.myFirstLoader = this.getMyFirstLoader
        }).catch((e) => {
          this.myFirstLoader = false
        });
      },

      approveDisbursmentPaymentRequest() {

        this.$dialog.confirm('You are about to approve the Proforma Number [ ' + this.paymentRequestDetails.invoiceNo + ' ] invoice, are you sure?', {
          loader: true,
          html: true,
          animation: 'zoom', // Available: "zoom", "bounce", "fade"
          okText: 'Yes, approve',
          cancelText: 'No, close',

        }).then((dialog) => {

          this.$store.dispatch('disbursmentpaymentrequest/requestid', this.paymentRequestDetails.disbursementInvoicePaymentId).then(() => {

            this.approveDisbursementInvoicePaymentRequest({}).then((response) => {
              if (response.data.status) {
                this.loadNotification('success', 4000, 'Sub Disbursment Account Invoice Payment Request', ' Proforma Number [ ' + this.paymentRequestDetails.invoiceNo + ' ] invoice payment requerst has been successfully approved',
                  'response', 1000, true, true);
                this.loadDisbursmentPaymentRequestDetails();
                dialog.close();
              } else {
                this.loadNotification('error', 4000, 'Sub Disbursment Account Invoice Payment Request', ' Proforma Number [ ' + this.paymentRequestDetails.invoiceNo + ' ] invoice payment requerst not approved',
                  'response', 1000, true, true);
                dialog.close();
              }

              this.approvingPaymentRequestLOader = this.getMyLoader
            }).catch((e) => {
              this.approvingPaymentRequestLOader = false;
              dialog.close();
            });
            this.approvingPaymentRequestLOader = this.getMyLoader
          }).catch((e) => {
            this.approvingPaymentRequestLOader = false;
            dialog.close();
          });

        }).catch(() => {
          this.approvingPaymentRequestLOader = false
        })
      },

      loadDownloadDocumentParams(extension) {

        return new Promise((resolve) => {

          this.$store.dispatch('global/requestid', this.paymentRequestDetails.subDisbursementAccount.subDisbursementAccountId);
          this.$store.dispatch('global/fileNaming', 'PDA-' + this.paymentRequestDetails.subDisbursementAccount.invoiceNo);
          this.$store.dispatch('global/fileExtensioning', extension);

          resolve();

        })

      },

      downloadDaPdf() {

        this.loadDownloadDocumentParams('pdf').then(() => {

          this.downloadBlobDocument({}).then((response) => {

            if (response.status === 204) {

              this.loadNotification('warn', 4000, 'File Download',
                'File not found, please contact system admin',
                'response', 1000, true, true)

            } else {

              this.loadNotification('success', 4000, 'File Download',
                'File has been successfully downloaded, please have a look at it',
                'response', 1000, true, true)
            }

            this.globalLoader = this.getGlobalLoader

          }).catch(() => {

            this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000, true, true);

            this.globalLoader = this.getGlobalLoader

          });

          this.globalLoader = this.getGlobalLoader
        })

      },

      closeModal(value) {

        this.viewAttachments = value;

      },

      goBack() {

        if (window.$cookies.isKey('browser-local-cashed-url-back')) {
          this.$router.push(window.$cookies.get('browser-local-cashed-url-back'));
          window.$cookies.remove('browser-local-cashed-url-back')
        } else {
          history.back()
        }
      }
    }
  }

</script>
<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
  }

  ul.nav-justified li a:hover {
    color: #d59a18;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .btn-success-custom:hover {
    border-color: #008000;
    color: #008000;
    background-color: #ffffff;
  }

  .btn-success-custom {
    border-color: rgba(0, 128, 0, .5);
    color: rgba(0, 128, 0, .5);
  }

  .btn-success-custom-fill {
    background-color: rgba(0, 128, 0, .8);
    opacity: 1;
    color: #FFFFFF;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
  }

  .pointer{
    cursor: pointer;
  }
</style>
