<template>
  <div>
    <card>
      <div class="row">
        <div
          @click="toggle"
          class="col-md-12 toggle-code-key"
        >
          <div class="row">
            <div class="col-md-6">
              Tariff Rule icons color keys
            </div>
            <div class="col-md-6 text-right">
              <font-awesome-icon
                icon="angle-down"
                v-show="!showSection"
              />
              <font-awesome-icon
                icon="angle-up"
                v-show="showSection"
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-12"
          v-show="showSection"
        >
          <hr>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 30px"
              v-show="showSection"
            >
              <b>icons keys</b>
            </div>
            <div class="col-md-12">
              <div
                class="col-md-12"
                v-show="showSection"
              >
                <div class="row">
                  <div class="col-md-3">
                    <span>
                      <font-awesome-icon
                        class="add-fa-icon"
                        icon="plus"
                      /><i class=" text-muted"> Add Tariff Rule</i>
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span>
                      <font-awesome-icon
                        class="edit-fa-icon"
                        icon="edit"
                      /><i class=" text-muted"> Edit Tariff Rules</i>
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span>
                      <font-awesome-icon
                        class="text-success"
                        icon="plus"
                      /><i class=" text-muted"> Add Rate to Rule</i>
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span>
                      <font-awesome-icon
                        class="view-fa-icon"
                        icon="eye"
                      /><i class=" text-muted"> View Rate in Rule</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-12">
          <div class="text-left">
            Tariff Fee Details
          </div>
          <hr>
        </div>
        <hr>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <b>Tariff Name</b>
                </div>
                <div class="col-md-6">
                  {{ this.feeById.tariffFeeName }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <b>Remarks</b>
                </div>
                <div class="col-md-6">
                  {{ this.feeById.remarks }}
                </div>
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-6">
                  <b>TASAC GFS Code</b>
                </div>
                <div class="col-md-6 text-muted">
                  {{ this.feeById.tasacGfsCode }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-6">
                  <b>GEPG GFS Code</b>
                </div>
                <div class="col-md-6">
                  {{ this.feeById.gepgGfsCode }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-6">
                  <b>Created Date</b>
                </div>
                <div class="col-md-6">
                  {{ date(this.feeById.createdDate) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Tariff Rules For {{ this.feeById.tariffFeeName }}
          </div>
          <div class="text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            class="all-fa-icons btn-space"
            title="Add new Item"
            v-if="finalUserPermissions.includes('TARIFF-RULES_CREATE_POST')"
            @click="openModal('CREATE')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  #
                </th>
                <th scope="col">
                  Tariff Item
                </th>
                <th scope="col">
                  Service Time
                </th>
                <th scope="col">
                  Service Weight
                </th>
                <th scope="col">
                  Service Withing
                </th>
                <th scope="col">
                  Weight Withing
                </th>
                <th scope="col">
                  Minimum Limit
                </th>
                <th scope="col">
                  Maximum Limit
                </th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(list,index) in tariffRules"
                :key="index"
              >
                <th scope="row">
                  {{ index + 1 }}
                </th>
                <td class="">
                  {{ list.tariffItem ? list.tariffItem.tariffItemName :"No Rate" }}
                </td>
                <td class="">
                  {{ list.serviceTime }}
                </td>
                <td class="">
                  {{ list.serviceWeight }}
                </td>
                <td class="">
                  {{ list.serviceWithing }}
                </td>
                <td class="">
                  {{ list.weightWithing }}
                </td>
                <td class="">
                  {{ list.minimumLimit || list.minimumLimit===0 ? list.minimumLimit: "Null" }}
                </td>
                <td class="">
                  {{ list.maximumLimit ? list.maximumLimit: "Null" }}
                </td>
                <td class="special-limit">
                  <div
                    class=" all-fa-icons fa-icons-space edit-fa-icon"
                    v-if="finalUserPermissions.includes('TARIFF-RULES_UPDATE_PUT')"
                    title="Edit"
                  >
                    <font-awesome-icon
                      icon="edit"
                      @click="openModal('EDIT',list)"
                    />
                  </div>

                  <div>
                    <div
                      class="all-fa-icons fa-icons-space text-success"
                      v-if="finalUserPermissions.includes('TARIFF-RATES_CREATE_POST') && !isRateCreated(list.tariffRuleId)"
                      title="Add Rate"
                    >
                      <font-awesome-icon
                        icon="plus"
                        @click="openModal('NEW-RATE',list)"
                      />
                    </div>
                    <div
                      v-else
                      class="all-fa-icons fa-icons-space text-success"
                      style="width:10px;"></div>
                  </div>

                  <div>
                    <div
                      class="all-fa-icons fa-icons-space view-fa-icon"
                      title="Add Rate"
                      v-if="finalUserPermissions.includes('TARIFF-RATES_GET_{TARIFFRATEID}_GET') && isRateCreated(list.tariffRuleId)"
                    >
                      <font-awesome-icon
                        icon="eye"
                        @click="openModal('VIEW-RATE',list)"
                      />
                    </div>
                    <div
                      v-else
                      class="all-fa-icons fa-icons-space view-fa-icon"
                      style="width:10px;"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <modal v-if="showModal">
        <div
          slot="header"
          class="modal-title col-md-12"
        >
          <div class="row">
            {{ this.rule ? "New Rule": this.viewRate ? "Rate":"" }}
          </div>
          <div slot="body">
            <card>
              <div
                class="col-md-12 alert-dismissible fade show"
                role="alert"
                :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
                style="border-radius:5px;"
              >
                <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
                <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  v-if="SuccessAlert"
                  style="line-height:2px;"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  v-if="ErrorAlert"
                  style="line-height:2px;"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div v-if="viewRate">
                <div class="row">
                  <div class="col-12 text-center">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="loadRate"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">
                            Minimum Value
                          </th>
                          <th scope="col">
                            Rate Currency
                          </th>
                          <th scope="col">
                            Rate Per
                          </th>
                          <th scope="col">
                            Rate Unit
                          </th>
                          <th scope="col">
                            Fixed
                          </th>
                          <th scope="col">
                            Rate Value
                          </th>
                          <th scope="col">
                            Vatable
                          </th>
                          <th scope="col">
                            Excess Rate Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="">
                            {{ selectedRate.minimumValue + " " + selectedRate.rateCurrency }}
                          </td>
                          <td class="">
                            {{ selectedRate.rateCurrency }}
                          </td>
                          <td class="">
                            {{ selectedRate.ratePer }}
                          </td>
                          <td class="">
                            {{ selectedRate.rateUnit }}
                          </td>
                          <td class="">
                            {{ selectedRate.isFixed ? "Fixed":"Not Fixed" }}
                          </td>
                          <td class="">
                            {{ selectedRate.rateValue }}
                          </td>
                          <td class="">
                            {{ selectedRate.vatTaxable ? "VATABLE":"Not VATABLE" }}
                          </td>
                          <td class="">
                            {{ selectedRate.excessRateValue ? selectedRate.excessRateValue:"Not Applicable" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <div class="col-md-12">
                    <button
                      class="btn btn-default btn-fill float-right"
                      @click="closeModal()"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <div
                  class="row"
                  v-if="rule"
                >
                  <div class="col-12">
                    Please insert the figure per each Field,or insert <strong>ANY</strong> in Capital Letter if any value can be aplied
                  </div>
                  <div class="col-12">
                    <div>
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <div v-if="!istariffserviceTimeValid">
                              <small
                                v-if="!$v.tariffRuleData.serviceTime.required"
                                :class="[!$v.tariffRuleData.serviceTime.required ? 'text-danger':'']"
                              >This field is required</small><br>
                            </div>
                            <label for="servicetime">ServiceTime</label>
                            <input
                              type="text"
                              class="form-control"
                              id="servicetime"
                              placeholder="Service Time"
                              v-model="tariffRuleData.serviceTime"
                            >
                          </div>
                          <div class="form-group">
                            <div v-if="!isgserviceWeightValid">
                              <small
                                v-if="!$v.tariffRuleData.serviceWeight.required"
                                :class="[!$v.tariffRuleData.serviceWeight.required ? 'text-danger':'']"
                              >This field is required</small><br>
                            </div>
                            <label for="serviceWeight">Service Weight</label>
                            <input
                              type="text"
                              class="form-control"
                              id="serviceWeight"
                              placeholder="Service Weight"
                              v-model="tariffRuleData.serviceWeight"
                            >
                          </div>
                          <div class="form-group">
                            <div v-if="!isserviceWithingValid">
                              <small
                                v-if="!$v.tariffRuleData.serviceWithing.required"
                                :class="[!$v.tariffRuleData.serviceWithing.required ? 'text-danger':'']"
                              >This field is required</small><br>
                            </div>
                            <label for="serviceWithing">Service Withing</label>
                            <input
                              type="text"
                              class="form-control"
                              id="serviceWithing"
                              placeholder="Service Withing"
                              v-model="tariffRuleData.serviceWithing"
                            >
                          </div>
                          <div class="form-group">
                            <label for="maxLimit">Maximum Limit</label>
                            <input
                              type="text"
                              class="form-control"
                              id="maxLimit"
                              placeholder="Maximum Limit"
                              v-model="tariffRuleData.maximumLimit"
                            >
                          </div>
                          <div class="form-group">
                            <label for="minLimit">Minimum Limit</label>
                            <input
                              type="text"
                              class="form-control"
                              id="minLimit"
                              placeholder="Minimum Limit"
                              v-model="tariffRuleData.minimumLimit"
                            >
                          </div>
                          <div class="form-group">
                            <div v-if="!isweightWithingValid">
                              <small
                                v-if="!$v.tariffRuleData.weightWithing.required"
                                :class="[!$v.tariffRuleData.weightWithing.required ? 'text-danger':'']"
                              >This field is required</small><br>
                            </div>
                            <label for="serviceWithing">Weight Withing</label>
                            <input
                              type="text"
                              class="form-control"
                              id="weightWithing"
                              placeholder="Weight Withing"
                              v-model="tariffRuleData.weightWithing"
                            >
                          </div>
                          <div class="form-group">
                            <div v-if="!istariffItemIdValid">
                              <small
                                v-if="!$v.tariffRuleData.tariffItemId.required"
                                :class="[!$v.tariffRuleData.tariffItemId.required ? 'text-danger':'']"
                              >This field is required</small><br>
                            </div>
                            <label for="item">Tariff Item</label>
                            <select
                              class="custom-select"
                              @change="onChange($event,`SELECTED-ITEM`)"
                              id="item"
                            >
                              <option value="" />
                              <option
                                v-for="(list,index) in getTariffItems"
                                :value="list.tariffItemId"
                                :key="index"
                                :selected="tariffRuleData.tariffItemId===list.tariffItemId"
                              >
                                {{ list.tariffItemName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="text-center row"
                  v-if="tariffRuleCreate && rule"
                >
                  <div class="col-md-7 text-right loader-div">
                    <span v-if="myloader">Please wait ....</span>
                  </div>
                  <div class="col-md-2 left loader-div-gif">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="myloader"
                    />
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-space btn-fill btn-outline-info float-right"
                      @click="closeModal()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Close
                    </button>
                    <button
                      class="btn btn-primary btn-space float-right"
                      data-dismiss="modal"
                      @click="createRule()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div
                  class="text-center row"
                  v-if="tariffRuleEDIT && rule"
                >
                  <div class="col-md-7 text-right loader-div">
                    <span v-if="myloader">Please wait ....</span>
                  </div>
                  <div class="col-md-2 left loader-div-gif">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="myloader"
                    />
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-space btn-fill btn-outline-info float-right"
                      @click="closeModal()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Close
                    </button>
                    <button
                      class="btn btn-primary btn-space float-right"
                      data-dismiss="modal"
                      @click="editTariffRule()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Edit
                    </button>
                  </div>
                </div>

                <div
                  class="row"
                  v-if="addRate"
                >
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12">
                        New Rate
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <div v-if="!isfixed">
                            <small
                              v-if="!$v.tariffRateData.fixed.required"
                              :class="[!$v.tariffRateData.fixed.required ? 'text-danger':'']"
                            >This field is required</small><br>
                          </div>
                          <label for="fixed">Fixed</label>
                          <select
                            class="form-control"
                            id="fixed"
                            @change="onChange($event,`FIXED`)"
                          >
                            <option />
                            <option
                              value="true"
                              :selected="tariffRateData.fixed===true"
                            >
                              Fixed
                            </option>
                            <option
                              value="false"
                              :selected="tariffRateData.fixed===false"
                            >
                              Not Fixed
                            </option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label for="eRvalue">Excess Rate Value</label>
                          <input
                            type="number"
                            class="form-control"
                            id="eRvalue"
                            placeholder="Excess Rate Value"
                            v-model="tariffRateData.excessRateValue"
                          >
                        </div>
                        <div class="form-group">
                          <div v-if="!isminimumValue">
                            <small
                              v-if="!$v.tariffRateData.minimumValue.required"
                              :class="[!$v.tariffRateData.minimumValue.required ? 'text-danger':'']"
                            >This field is required</small><br>
                          </div>
                          <label for="mvalue">Minimum Value</label>
                          <input
                            type="number"
                            class="form-control"
                            id="mvalue"
                            placeholder="Ninimum Value"
                            v-model="tariffRateData.minimumValue"
                          >
                        </div>
                        <div class="form-group">
                          <div v-if="!israteCurrency">
                            <small
                              v-if="!$v.tariffRateData.rateCurrency.required"
                              :class="[!$v.tariffRateData.rateCurrency.required ? 'text-danger':'']"
                            >This field is required</small><br>
                          </div>
                          <label for="cy">Rate Currency</label>
                          <select
                            v-model="tariffRateData.rateCurrency"
                            id="cy"
                            class="form-control"
                          >
                            <option value="TZS">
                              TZS
                            </option>
                            <option value="USD">
                              USD
                            </option>
                          </select>
                        </div>
                        <div class="form-group">
                          <div v-if="!israteUnit">
                            <small
                              v-if="!$v.tariffRateData.rateUnit.required"
                              :class="[!$v.tariffRateData.rateUnit.required ? 'text-danger':'']"
                            >This field is required</small><br>
                          </div>
                          <label for="runit">Rate Unit (Kg,G,Ltr..)</label>
                          <select
                            id="runit"
                            class="form-control"
                            v-model="tariffRateData.rateUnit"
                          >
                            <option value="TEU">
                              TEU
                            </option>
                            <option value="FEU">
                              FEU
                            </option>
                            <option value="CBM">
                              CBM
                            </option>
                            <option value="Ltr">
                              Ltr
                            </option>
                            <option value="Consignment">
                              Consignment
                            </option>
                            <option value="MT">
                              MT
                            </option>
                            <option value="HTN">
                              HTN
                            </option>
                            <option value="B/L">
                              B/L
                            </option>
                            <option value="HTN">
                              HTN
                            </option>
                            <option value="Unit">
                              Unit
                            </option>
                            <option value="Ton">
                              Ton
                            </option>
                          </select>
                        </div>
                        <div class="form-group">
                          <div v-if="!isratePer">
                            <small
                              v-if="!$v.tariffRateData.ratePer.required"
                              :class="[!$v.tariffRateData.ratePer.required ? 'text-danger':'']"
                            >This field is required</small><br>
                          </div>
                          <label for="rate">Rate Per (The Fee is calculated per)</label>
                          <select
                            id="rate"
                            class="form-control"
                            v-model="tariffRateData.ratePer"
                          >
                            <option value="TEU">
                              TWENTY FEET CONTAINER
                            </option>
                            <option value="FEU">
                              FORTY FEET CONTAINER
                            </option>
                            <option value="CBM">
                              CUBIC METER
                            </option>
                            <option value="Ltr">
                              LITRES
                            </option>
                            <option value="Consignment">
                              CONSIGNMENT
                            </option>
                            <option value="MT">
                              METRIC TONNE
                            </option>
                            <option value="HTN">
                              HABOUR TONNAGE
                            </option>
                            <option value="B/L">
                              BILL OF LADING
                            </option>
                            <option value="HTN">
                              HABOUR TONNAGE
                            </option>
                            <option value="Unit">
                              Unit
                            </option>
                            <option value="Ton">
                              Ton
                            </option>
                          </select>
                        </div>
                        <div class="form-group">
                          <div v-if="!israteValue">
                            <small
                              v-if="!$v.tariffRateData.rateValue.required"
                              :class="[!$v.tariffRateData.rateValue.required ? 'text-danger':'']"
                            >This field is required</small><br>
                          </div>
                          <label for="value">Rate Value (Fee for for this rate)</label>
                          <input
                            type="number"
                            class="form-control"
                            id="value"
                            placeholder="Rate Value"
                            v-model="tariffRateData.rateValue"
                          >
                        </div>
                        <div class="form-group">
                          <div v-if="!isvatTaxable">
                            <small
                              v-if="!$v.tariffRateData.vatTaxable.required"
                              :class="[!$v.tariffRateData.vatTaxable.required ? 'text-danger':'']"
                            >This field is required</small><br>
                          </div>
                          <label for="vat">Vatable</label>
                          <select
                            class="form-control"
                            id="vat"
                            @change="onChange($event,`VAT`)"
                          >
                            <option />
                            <option
                              value="true"
                              :selected="tariffRateData.vatTaxable===true"
                            >
                              Vatable
                            </option>
                            <option
                              value="false"
                              :selected="tariffRateData.vatTaxable===false"
                            >
                              Not Vatable
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="text-center row"
                  v-if="addRate"
                >
                  <div class="col-md-7 text-right loader-div">
                    <span v-if="myloader">Please wait ....</span>
                  </div>
                  <div class="col-md-2 left loader-div-gif">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="myloader"
                    />
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-space btn-fill btn-outline-info float-right"
                      @click="closeModal()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Close
                    </button>
                    <button
                      class="btn btn-primary btn-space float-right"
                      data-dismiss="modal"
                      @click="saveRate()"
                      :class="[disableButton ? 'disabled-click' : 'enabled-click']"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </modal>
    </card>
  </div>
</template>

<script>
  import API_REVENUE from '../../../api/rev'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import Modal from '../../../components/Inputs/Modal'
  import {required} from 'vuelidate/lib/validators'

  export default {
  name: 'TasacRule',

  components: {
    PulseLoader,
    Modal
  },

  validations: {
    tariffRuleData: {
      serviceTime: { required },
      serviceWeight: { required },
      serviceWithing: { required },
      tariffItemId: { required },
      weightWithing: { required }
    },
    tariffRateData: {
      fixed: { required },
      minimumValue: { required },
      rateCurrency: { required },
      ratePer: { required },
      rateUnit: { required },
      rateValue: { required },
      tariffRuleId: { required },
      vatTaxable: { required }
    }
  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.loadRules();
    this.getAllRates();
    this.loadItems();
    this.getTariffeesById()
  },

  data () {
    return {
      getTariffItems: [],
      getTariffFees: [],
      tariffRules: [],
      finalUserPermissions: [],
      selectedItem: '',
      myLoader: false,
      editLoader: false,
      tariffRuleCreate: false,
      tariffRuleEDIT: false,
      showModal: false,
      addRate: false,
      rule: false,
      showSection: true,
      TariffFees: [],
      disableButton: false,
      Tariffs: [],
      selectedTariff: '',
      feeById: {},
      allRates: [],

      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',

      color: 'green',
      size: '15px',
      loading: true,

      tariffRuleData: {
        tariffRuleId: '',
        serviceTime: '',
        maximumLimit: '',
        minimumLimit: '',
        serviceWeight: '',
        serviceWithing: '',
        tariffFeeId: this.$route.params.tariffFeeId,
        tariffItemId: '',
        weightWithing: '',
        active: undefined

      },

      tariffRateData: {
        fixed: '',
        minimumValue: '',
        rateCurrency: '',
        ratePer: '',
        rateUnit: '',
        rateValue: '',
        tariffRuleId: '',
        vatTaxable: '',
        excessRateValue: ''
      },

      istariffserviceTimeValid: true,
      isgserviceWeightValid: true,
      isserviceWithingValid: true,
      istariffIdValid: true,
      istariffItemIdValid: true,
      isweightWithingValid: true,

      isfixed: true,
      isminimumValue: true,
      israteCurrency: true,
      isratePer: true,
      israteUnit: true,
      israteValue: true,
      istariffRuleId: true,
      isvatTaxable: true,
      viewRate: false,
      loadRate: false,
      selectedRate: {},
      selectedRule: []
    }
  },

  methods: {

    toggle () {
      this.showSection = !this.showSection
    },

    clickedRle (id) {
      this.$router.push({ path: '/tariff/rate2/' + id })
    },

    loadRules () {
      API_REVENUE.getTariffRuleByFee(this.tariffRuleData.tariffFeeId).then((response) => {
        this.tariffRules = response.data.data
      })
    },

    getAllRates () {
      API_REVENUE.getAllRate().then((response) => {
        this.allRates = response.data.data;
        this.loadRules()
      }).catch((e) => {
        this.ErrorMessage = 'Failed To load Rates Contact System Administrator';
        this.SuccessAlert = false;
        this.ErrorAlert = true;
        this.SuccessMessage = '';
        this.loadRate = false
      })
    },

    isRateCreated (tariffRuleId) {
      let created = false;

      for (let i = 0; i < this.allRates.length; i++) {
        created = this.allRates[i].tariffRuleId === tariffRuleId;
        if (created) {
          break
        }
      }
      return created
    },

    date (date) {
      const newDate = new Date(date);

      const day = (newDate.getDay()).toString.length > 1 ? newDate.getDay() : '0' + newDate.getDay();
      const month = (newDate.getMonth()).toString.length > 1 ? newDate.getMonth() : '0' + newDate.getMonth();
      return day + '/' + month + '/' + newDate.getFullYear()
    },

    /* get fee by passing id */
    getTariffeesById () {
      API_REVENUE.getTariffFeesById(this.tariffRuleData.tariffFeeId).then((response) => {
        this.feeById = response.data.data
      }).catch(() => {

      })
    },

    /* load all tarrifs */
    getTariffs () {
      API_REVENUE.getTariffs().then((response) => {
        if (response.data.status) {
          this.Tariffs = response.data.data
        }
      }).catch((error) => {

      })
    },

    loadItems () {
      API_REVENUE.getTariffItems().then((response) => {
        this.getTariffItems = response.data.data
      })
    },

    getFees () {
      API_REVENUE.getTariffFees(this.selectedTariff).then((response) => {
        if (response.data.status) {
          this.TariffFees = response.data.data
        }
      }).catch((error) => {

      })
    },

    loadRules () {
      this.tariffRules = [];
      API_REVENUE.getTariffRuleByFee(this.tariffRuleData.tariffFeeId).then((response) => {
        this.tariffRules = response.data.data
      })
    },

    onChange ($event, data) {
      if (data === 'SELECTED-ITEM-TARIFF') {
        this.selectedItem = $event.target.value;
        this.loadRules()
      }

      if (data === 'SELECTED-ITEM') {
        this.tariffRuleData.tariffItemId = $event.target.value
      }

      if (data === 'SELECTED-TARIFF') {
        this.selectedTariff = $event.target.value;
        this.getFees()
      }

      if (data === 'FIXED') {
        this.tariffRateData.fixed = $event.target.value
      }

      if (data === 'VAT') {
        this.tariffRateData.vatTaxable = $event.target.value
      }
    },

    saveRate () {
      if (
        this.$v.tariffRateData.fixed.required &&
          this.$v.tariffRateData.minimumValue.required &&
          this.$v.tariffRateData.rateCurrency.required &&
          this.$v.tariffRateData.ratePer.required &&
          this.$v.tariffRateData.rateUnit.required &&
          this.$v.tariffRateData.rateValue.required &&
          this.$v.tariffRateData.vatTaxable.required &&
          this.$v.tariffRateData.tariffRuleId.required
      ) {
        this.myloader = true;
        this.disableButton = true;

        API_REVENUE.saveTariffRate(this.tariffRateData).then((response) => {
          this.getAllRates();

          this.ErrorMessage = '';
          this.SuccessAlert = true;
          this.ErrorAlert = false;
          this.SuccessMessage = 'Record Added';
          this.SuccessAlert = true;
          this.myloader = false;
          this.disableButton = false;

          this.tariffRateData.fixed = '';
          this.tariffRateData.minimumValue = '';
          this.tariffRateData.rateCurrency = '';
          this.tariffRateData.ratePer = '';
          this.tariffRateData.rateUnit = '';
          this.tariffRateData.rateValue = '';
          this.tariffRateData.vatTaxable = '';
          this.tariffRuleData.tariffRuleId = '';
          this.loadRules();
          this.closeModal()
        }).catch((error) => {
          this.ErrorMessage = ' Record not Added';
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = '';
          this.SuccessAlert = false;
          this.myloader = false;
          this.disableButton = false
        })
      } else {
        this.tariffRateData.fixed === '' ? this.isfixed = false : this.isfixed = true;
        this.tariffRateData.minimumValue === '' ? this.isminimumValue = false : this.isminimumValue = true;
        this.tariffRateData.rateCurrency === '' ? this.israteCurrency = false : this.israteCurrency = true;
        this.tariffRateData.ratePer === '' ? this.isratePer = false : this.isratePer = true;
        this.tariffRateData.rateUnit === '' ? this.israteUnit = false : this.israteUnit = true;
        this.tariffRateData.rateValue === '' ? this.israteValue = false : this.israteValue = true;
        this.tariffRateData.rateValue === '' ? this.israteValue = false : this.israteValue = true;
        this.tariffRateData.vatTaxable === '' ? this.isvatTaxable = false : this.isvatTaxable = true;
        this.tariffRateData.tariffRuleId === '' ? this.istariffRuleId = false : this.istariffRuleId = true
      }
    },

    openModal (data, data2 = null) {
      this.istariffserviceTimeValid = true;
      this.isgserviceWeightValid = true;
      this.isweightWithingValid = true;
      this.isweightWithingValid = true;
      this.isserviceWithingValid = true;
      this.istariffItemIdValid = true;

      this.isminimumValue = true;
      this.israteCurrency = true;
      this.isratePer = true;
      this.israteUnit = true;
      this.israteUnit = true;
      this.israteValue = true;
      this.isvatTaxable = true;
      this.isfixed = true;

      this.tariffRuleData.serviceTime = '';
      this.tariffRuleData.serviceWeight = '';
      this.tariffRuleData.serviceWithing = '';
      this.tariffRuleData.tariffItemId = '';
      this.tariffRuleData.weightWithing = '';

      this.ErrorMessage = '';
      this.SuccessAlert = false;
      this.ErrorAlert = false;
      this.SuccessMessage = '';

      this.showModal = true;
      this.tariffRuleCreate = false;
      this.tariffRuleEDIT = false;
      this.addRate = false;

      if (data === 'CREATE') {
        this.tariffRuleCreate = true;
        this.rule = true;
        this.edit = true
      }

      if (data === 'EDIT') {
        this.rule = true;
        this.edit = true;
        this.tariffRuleEDIT = true;
        this.tariffRuleData.tariffRuleId = data2.tariffRuleId;
        this.tariffRuleData.serviceTime = data2.serviceTime;
        this.tariffRuleData.serviceWeight = data2.serviceWeight;
        this.tariffRuleData.serviceWithing = data2.serviceWithing;
        this.tariffRuleData.tariffItemId = data2.tariffItem.tariffItemId;
        this.tariffRuleData.weightWithing = data2.weightWithing;
        this.tariffRuleData.maximumLimit = data2.maximumLimit;
        this.tariffRuleData.minimumLimit = data2.minimumLimit;
      }

      if (data === 'NEW-RATE') {
        this.addRate = true;
        this.tariffRateData.tariffRuleId = data2.tariffRuleId
      }

      if (data === 'EDIT-RATE') {
        this.addRate = true;
        this.tariffRateData.tariffRuleId = data2.tariffRuleId;
        this.getTariffRateByTariffId()
      }

      if (data === 'VIEW-RATE') {
        this.viewRate = true;

        this.selectedRate = this.allRates.find((x) => {
          return x.tariffRuleId === data2.tariffRuleId
        });
        this.selectedRule = data2
      }
    },

    closeModal () {
      this.showModal = false;
      this.tariffRuleEDIT = false;
      this.addRate = false;
      this.rule = false;
      this.disableButton = false;
      this.viewRate = false
    },

    createRule () {
      if (
        this.$v.tariffRuleData.serviceTime.required &&
          this.$v.tariffRuleData.serviceWeight.required &&
          this.$v.tariffRuleData.serviceWithing &&
          this.$v.tariffRuleData.weightWithing
      ) {
        this.myloader = true;
        this.disableButton = true;

        API_REVENUE.saveTariffRule(this.tariffRuleData).then((response) => {
          this.istariffserviceTimeValid = true;
          this.isgserviceWeightValid = true;
          this.isweightWithingValid = true;
          this.isweightWithingValid = true;

          this.tariffRuleData.serviceTime = '';
          this.tariffRuleData.serviceWeight = '';
          this.tariffRuleData.serviceWithing = '';
          this.tariffRuleData.weightWithing = '';
          this.myloader = false;

          this.ErrorMessage = '';
          this.SuccessAlert = true;
          this.ErrorAlert = false;
          this.SuccessMessage = 'Record Added';

          this.loadRules();

          setTimeout(() => {
            this.closeModal()
          }, 700)
        }).catch(() => {
          this.ErrorMessage = 'Record Not Added';
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = '';

          this.myloader = false;
          this.istariffserviceTimeValid = true;
          this.isgserviceWeightValid = true;
          this.isweightWithingValid = true;
          this.isweightWithingValid = true
        })
      } else {
        this.tariffRuleData.serviceTime === '' ? this.istariffserviceTimeValid = false : this.istariffserviceTimeValid = true;
        this.tariffRuleData.serviceWeight === '' ? this.isgserviceWeightValid = false : this.isgserviceWeightValid = true;
        this.tariffRuleData.serviceWithing === '' ? this.isserviceWithingValid = false : this.isserviceWithingValid = true;
        this.tariffRuleData.weightWithing === '' ? this.isweightWithingValid = false : this.isweightWithingValid = true;
        this.tariffRuleData.tariffItemId === '' ? this.istariffItemIdValid = false : this.istariffItemIdValid = true;
        this.tariffRuleData.tariffId === '' ? this.istariffIdValid = false : this.istariffIdValid = true
      }
    },

    editTariffRule () {
      if (
        this.$v.tariffRuleData.serviceTime.required &&
          this.$v.tariffRuleData.serviceWeight.required &&
          this.$v.tariffRuleData.serviceWithing.required &&
          this.$v.tariffRuleData.weightWithing.required
      ) {
        this.myloader = true;
        API_REVENUE.editTariffRule(this.tariffRuleData).then(() => {
          this.istariffserviceTimeValid = true;
          this.isgserviceWeightValid = true;
          this.isweightWithingValid = true;
          this.isweightWithingValid = true;
          this.myloader = false;
          this.loadRules();
          this.ErrorMessage = '';
          this.SuccessAlert = true;
          this.ErrorAlert = false;
          this.SuccessMessage = 'Record Updated'
        }).catch(() => {
          this.istariffserviceTimeValid = true;
          this.isgserviceWeightValid = true;
          this.isweightWithingValid = true;
          this.isweightWithingValid = true;
          this.myloader = false
        })
      } else {
        this.tariffRuleData.serviceTime === '' ? this.istariffserviceTimeValid = false : this.istariffserviceTimeValid = true;
        this.tariffRuleData.serviceWeight === '' ? this.isgserviceWeightValid = false : this.isgserviceWeightValid = true;
        this.tariffRuleData.serviceWithing === '' ? this.isserviceWithingValid = false : this.isserviceWithingValid = true;
        this.tariffRuleData.weightWithing === '' ? this.isweightWithingValid = false : this.isweightWithingValid = true;
        this.tariffRuleData.tariffItemId === '' ? this.istariffItemIdValid = false : this.istariffItemIdValid = true;
        this.tariffRuleData.tariffId === '' ? this.istariffIdValid = false : this.istariffIdValid = true
      }
    },

    getTariffRateByTariffId () {
      this.editLoader = true
    }
  }
}
</script>

<style scoped>
  .btn-space {
    margin-left: 10px;
  }
</style>
