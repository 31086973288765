<template>
  <div>
    <crud-notification
      :error-message="ErrorMessage"
      :success-message="SuccessMessage"
      :error-alert="ErrorAlert"
      :success-alert="SuccessAlert"
      @fadeMe="fadeMe"
      @enter="enter"
    />
    <card>
<!--      <div-->
<!--        class="row"-->
<!--        v-if="!shippingOrder.canceled && !shippingOrder.closed && !shippingOrder.partialClosed"-->
<!--      >-->
<!--        <div class="col-md-12">-->
<!--          <button-->
<!--            class="btn btn-success custom-btn mr-2"-->
<!--            style="float: right"-->
<!--            @click.prevent="newCargo=true"-->
<!--          >-->
<!--            Add Cargo-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
      <br>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :is-pageable="false"
        :no-data-found="noDataFound"
        :is-first="true"
        :is-last="false"
        :date-prop="'Created At '"
        :has-manage-button="true"
        :has-search-form="false"
        :has-view-button="true"
        :has-edit-button="false"
        :has-delete-button="true"
        @goToView="goToView"
        @goToDelete="deleteCargo"
        @goToApplication="goToApplication"
        :loading-title="'Please wait while system is loading Cargo Details'"
      />
      <div class="row pt-2">
        <div class="col-md-12">
          <button
            class="btn btn-default btn-fill btn-space"
            style="float:right;"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo" /> &nbsp; Back
          </button>
        </div>
      </div>
    </card>

    <modal v-if="newCargo">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        Add Cargo
      </div>
      <div slot="body">
        <div class="row">
          <div
            class="col-md-12 alert-dismissible fade show"
            role="alert"
            :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
            style="border-radius:5px;"
          >
            <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
            <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-if="SuccessAlert"
              style="line-height:2px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-if="ErrorAlert"
              style="line-height:2px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="col-12 text-center">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                v-if="loading"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 mb-3  mt-3 text-center">
                <h3>
                  <span class="cargo">Cargo Details</span>
                </h3>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="name"
                    class="custom-font mb-2"
                  >Cargo Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="Cargodetails.cargoName"
                    :readonly="isView"
                  >
                  <div
                    class="error text-danger"
                    v-if="cargoNameError"
                  >
                    <span v-if="!$v.Cargodetails.cargoName.required">Field is required</span>
                  </div>
                </div>
                <div class="form-group">
                  <label
                    for="description"
                    class="custom-font mb-2"
                  >Cargo description</label>
                  <input
                    type="text"
                    class="form-control"
                    id="description"
                    v-model="Cargodetails.cargoDescription"
                    :readonly="isView"
                  >
                  <div
                    class="error text-danger"
                    v-if="cargoDescriptionError"
                  >
                    <span v-if="!$v.Cargodetails.cargoDescription.required">Field is required</span>
                  </div>
                </div>
                <div class="form-group">
                  <label
                    for="description"
                    class="custom-font mb-2"
                  >Number Of Packages</label>
                  <input
                    type="number"
                    class="form-control"
                    id="nPackages"
                    v-model="Cargodetails.numberOfPackages"
                    :readonly="isView"
                  >
                </div>
                <div class="form-group mt-4">
                  <label
                    for="typepackage"
                    class="custom-font"
                  >Type of Package</label>
                  <select
                    class="form-control"
                    id="typepackage"
                    v-model="Cargodetails.packageType"
                    :disabled="isView"
                  >
                    <option />
                    <option value="Cartoons">
                      Cartoons
                    </option>
                    <option value="Pallets">
                      Pallets
                    </option>
                    <option value="Boxes">
                      Boxes
                    </option>
                    <option value="Bundles">
                      Bundles
                    </option>
                    <option value="Cases">
                      Cases
                    </option>
                    <option value="Units">
                      Units
                    </option>
                    <option value="Others">
                      Others
                    </option>
                  </select>
                </div>
                <div class="form-group mt-4">
                  <label
                    for="delivery"
                    class="custom-font"
                  >Terms of Delivery</label>
                  <select
                    class="form-control"
                    id="delivery"
                    v-model="Cargodetails.termOfDelivery"
                    :disabled="isView"
                  >
                    <option />
                    <option>EXW</option>
                    <option>FCA</option>
                    <option>CPT</option>
                    <option>CIP</option>
                    <option>DAF</option>
                    <option>DDU</option>
                    <option>DDP</option>
                    <option>XXX</option>
                  </select>
                  <div
                    class="error text-danger"
                    v-if="termOfDeliveryError"
                  >
                    <span v-if="!$v.Cargodetails.termOfDelivery.required">Field is required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="weight"
                    class="custom-font mb-2"
                  >Weight</label>
                  <input
                    type="text"
                    class="form-control"
                    id="weight"
                    v-model="Cargodetails.weight"
                    :readonly="isView"
                  >
                </div>
                <div class="form-group">
                  <label
                    for="volume"
                    class="custom-font mb-2"
                  >Volume (cbm) and/or dims (cm)</label>
                  <input
                    type="text"
                    class="form-control"
                    id="volume"
                    v-model="Cargodetails.volumeCbm"
                    :readonly="isView"
                  >
                </div>
                <div class="form-group">
                  <label
                    for="volume"
                    class="custom-font mt-3"
                  >Special Cargo</label>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="danger"
                      value="DangerousGoods"
                      v-model="Cargodetails.specialCargos"
                      :disabled="isView"
                    >
                    <label
                      class="custom-control-label"
                      for="danger"
                    >Dangerous goods</label>
                  </div>
                  <div class="custom-control custom-checkbox mt-2">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="temp"
                      value="TemperatureControl"
                      v-model="Cargodetails.specialCargos"
                      :disabled="isView"
                    >
                    <label
                      class="custom-control-label"
                      for="temp"
                    >Temperature control</label>
                  </div>
                  <div class="custom-control custom-checkbox mt-2">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="battery"
                      value="ContainsBattery"
                      v-model="Cargodetails.specialCargos"
                      :disabled="isView"
                    >
                    <label
                      class="custom-control-label"
                      for="battery"
                    >Contains battery</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button
              class="btn btn-fill btn-outline-info btn-space float-right mr-2"
              data-dismiss="modal"
              @click="closeModal"
            >
              Close
            </button>
            <button
              v-if="!isView"
              type="button"
              class="btn btn-primary float-right mr-2"
              @click.prevent="addCargo"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import AGENYAPI from '../../../api/agency'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { required } from 'vuelidate/lib/validators'
import API from '../../../api'
import { ModelListSelect } from 'vue-search-select'
import CrudNotification from '../../../components/DataTable/crudNotification'
import { mapActions, mapGetters } from 'vuex'
import DataTable from '../../../components/DataTable/updatedTable';

export default {
  name: 'ApplicationCargo',

  validations: {
    Cargodetails: {
      cargoDescription: { required },
      cargoName: { required },
      termOfDelivery: { required }
    },
    activity: {
      descrition: { required },
      officerId: { required },
      activityId: { required }
    }
  },

  components: {
    PulseLoader,
    Modal,
    ModelListSelect,
    CrudNotification,
    DataTable,
  },

  created () {
    // this.$store.dispatch("pagenames/currentPageName", "Shipping Order Cargo");
    this.shippingOrderId = this.$route.params.shippingOrderId
    this.getCargoForShippingOrder()
  },
  computed: {
    ...mapGetters('shippingOrder', [
      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getMyLoader',
      'getPageNo'
    ])

  },
  data () {
    return {
      cargos: [],
      shippingOrderId: '',
      shippingOrder: {
        canceled: false,
        cityCode: null,
        closed: false,
        consigneeAddress: '',
        consigneeCity: '',
        consigneeCodeTin: '',
        consigneeContactPerson: '',
        consigneeCountry: '',
        consigneeEmail: '',
        consigneeName: '',
        consigneeTel: '',
        consigneeZipCode: '',
        countryCode: null,
        createdAt: '',
        customerCodeTansad: null,
        expectedArrivalDate: '',
        expectedDepatureDate: '',
        id: 1,
        lastUpdatedAt: '',
        notiyAddress: '',
        notiyCity: '',
        notiyCodeTin: '',
        notiyContactPerson: '',
        notiyCountry: '',
        notiyEmail: '',
        notiyName: '',
        notiyTel: '',
        notiyZipCode: '',
        partialClosed: false,
        shipperAddress: '',
        shipperCity: '',
        shipperCodeTin: '',
        shipperContactPerson: '',
        shipperCountry: '',
        shipperEmail: '',
        shipperName: '',
        shipperTel: '',
        shipperZipCode: '',
        shippingOrderContainers: null,
        shippingOrderGoods: null,
        shippingOrderNo: null,
        shippingOrderStatus: '',
        termOfDelivery: '',
        vessel: null
      },

      color: 'green',
      size: '15px',

      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      loading: false,
      smallLoading: false,
      newCargo: false,

      viewCargoDetails: false,
      isView: false,
      requests: [],
      tableHeaders: ['Cargo Name', 'Package Type', 'Shipping Order Status', 'Terms of Delivery'],
      tableDataKeys: ['cargoName', 'packageType', 'shippingOrderStatus', 'termOfDelivery'],
      myFirstLoader: false,
      noDataFound: false,

      Cargodetails: {
        cargoDescription: '',
        cargoName: '',
        numberOfPackages: 0,
        packageType: '',
        specialCargos: [],
        volumeCbm: '',
        weight: '',
        weightUnit: 'G',
        termOfDelivery: ''
      },

      cargoDescriptionError: false,
      cargoNameError: false,
      termOfDeliveryError: false,

      allUsers: [],

      activity: {
        activityId: '',
        descrition: '',
        officerId: '',
        shippingOrderGoodId: '',
        shippingOrderId: parseInt(this.$route.params.shippingOrderId)
      },
      descritionError: false,
      officerIdError: false,
      activityIdError: false,
      assignActivity: false,
      selectedCargo: {},
      getactivity: [],
      cargoActivities: []

    }
  },

  methods: {
    ...mapActions('shippingOrder', [
      'addCargoForShippingOrder',
      'updateCargoForShippingOrder',
      'loadCargoForShippingOrder',
      'deleteCargoForShippingOrder'
    ]),
    loadParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('shippingOrder/requestid', this.shippingOrderId)
        resolve()
      })
    },

    getCargoForShippingOrder () {
      this.$store.dispatch('shippingOrder/requestid', this.shippingOrderId)
      this.loadCargoForShippingOrder({}).then(() => {
        this.myFirstLoader = this.getMyFirstLoader
        this.requests = this.getRequestList
        this.noDataFound = this.getNoDataFound
        this.shippingOrder = this.requests[0].shippingOrder
      }).catch(() => {

      })
    },
    goToView (data) {
      this.Cargodetails = data
      this.isView = true
      this.newCargo = true
    },
    closeModal () {
      this.Cargodetails = {
        cargoDescription: '',
        cargoName: '',
        numberOfPackages: 0,
        packageType: '',
        specialCargos: [],
        volumeCbm: '',
        weight: '',
        weightUnit: 'G',
        termOfDelivery: ''
      }
      this.isView = false
      this.newCargo = false
    },
    goBack () {
      history.back()
    },
    goToApplication (data) {
      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
      this.$router.push('/shipping-agency/booking/view/shippingorder/cargo-details/' + data.id)
    },
    activityName (data) {
      return data.name
    },

    /**
             * @return {string}
             */
    // OfficerName(data){
    //     return `${data.firstName}  ${data.lastName}`;
    //
    // },

    //   getCargoActivities(){
    //     this.smallLoading=true;
    //     AGENYAPI.getShippingOderGoodActivities(this.activity.shippingOrderGoodId,this.activity.shippingOrderId).then((response)=>{
    //         this.cargoActivities=response.data.data;
    //         this.smallLoading=false;
    //     }).catch((error)=>{
    //         this.smallLoading=false;
    //         this.ErrorMessage = "Failed To Load Assigned Activities";
    //         this.SuccessAlert = false;
    //         this.ErrorAlert = true;
    //         this.SuccessMessage = "";
    //     })
    // },

    getAllUsers () {
      API.getUsers().then((response) => {
        this.allUsers = response.data.data
      }).catch(() => {

      })
    },

    ordinal_suffix_of (i) {
      const j = i % 10
      const k = i % 100
      if (j === 1 && k !== 11) {
        return i + 'st'
      }
      if (j === 2 && k !== 12) {
        return i + 'nd'
      }
      if (j === 3 && k !== 13) {
        return i + 'rd'
      }
      return i + 'th'
    },
    dateToHuman (stringDate) {
      if (stringDate !== null || stringDate !== undefined) {
        const splittedDate = stringDate.split('T')
        return splittedDate[0]
      }
    },

    addCargo () {
      if (this.Cargodetails.cargoDescription !== '' &&
                      this.Cargodetails.cargoName !== '' &&
                      this.Cargodetails.termOfDelivery !== '') {
        this.Cargodetails.shippingOrderId = this.shippingOrderId
        this.Cargodetails.numberOfPackages = parseInt(this.Cargodetails.numberOfPackages ? this.Cargodetails.numberOfPackages : '0')
        this.Cargodetails.volumeCbm = parseInt(this.Cargodetails.volumeCbm ? this.Cargodetails.volumeCbm : '0')
        this.Cargodetails.weight = parseInt(this.Cargodetails.weight ? this.Cargodetails.weight : '0')

        this.$store.dispatch('shippingOrder/savingDetails', this.Cargodetails)
        this.addCargoForShippingOrder({}).then(() => {
          this.SuccessMessage = 'Cargo Saved Successfully'
          this.ErrorMessage = 'Sorry, Something went wrong...'
          this.ErrorAlert = this.getErrorAlert
          this.SuccessAlert = this.getSuccessAlert
          this.myFirstLoader = this.getMyFirstLoader
          this.getCargoForShippingOrder()
          this.newCargo = false
        }).catch(() => {})
      } else {
        this.cargoDescriptionError = true
        this.cargoNameError = true
        this.termOfDeliveryError = true
      }
    },
    deleteCargo (data) {
      this.$dialog.confirm('You are about to delete the Cargo, are you sure?', {
        loader: true,
        html: true
      }).then((dialog) => {
        this.$store.dispatch('shippingOrder/requestid', data.id)
        this.deleteCargoForShippingOrder({}).then(() => {
          this.SuccessAlert = this.getSuccessAlert
          this.ErrorAlert = this.getErrorAlert
          this.ErrorMessage = 'Failed To Delete Cargo'
          this.SuccessMessage = ' Cargo deleted.'
          this.getCargoForShippingOrder()
        }).catch((e) => {
          console.log(e)
        })
        dialog.close()
      }).catch((e) => {})
    },
    //   updateCargo(data){
    //
    //
    //   if(this.Cargodetails.cargoDescription!=="" && this.Cargodetails.cargoName!=="" && this.Cargodetails.termOfDelivery!==""){
    //     AGENYAPI.addCargoToShippingOrder(this.Cargodetails).then((response)=>{
    //
    //       this.cargoDescriptionError=false;
    //       this.cargoNameError=false;
    //       this.termOfDeliveryError=false;
    //
    //       this.ErrorMessage = "";
    //       this.SuccessAlert = true;
    //       this.ErrorAlert = false;
    //       this.SuccessMessage = "Cargo Added";
    //       this.Cargodetails.cargoDescription="";
    //       this.Cargodetails.cargoName="";
    //       this.Cargodetails.termOfDelivery="";
    //       this.getCargoForShippingOrder();
    //       this.newCargo=false;
    //     }).catch((error)=>{
    //
    //       this.ErrorMessage = "Failed to Add new Cargo";
    //       this.SuccessAlert = false;
    //       this.ErrorAlert = true;
    //       this.SuccessMessage = "";
    //
    //     })
    //
    //   }else {
    //
    //     this.cargoDescriptionError=true;
    //     this.cargoNameError=true;
    //     this.termOfDeliveryError=true;
    //   }
    //
    // },

    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },
    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    }

  }
}
</script>

<style scoped>
    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        border-radius: 3px;
        margin-top: 1rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }

    .div-table-value{
        border: 1px solid #DCDCDC;
    }
    .div-table-row {
        padding: 0 15px 0 15px;
    }

    .div-table {
        padding: 9px 8px 9px 8px;
    }

    .div-table-title {
        width: 250px;
        background-color: rgba(153, 186, 221, .5);
        font-weight: normal;
    }

    .div-table-value {
        border: 1px solid #DCDCDC;
        font-weight: 900;
    }

    h3 {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid green;
        line-height: 0.1em;
        margin: 10px 0 20px;
    }

    h3 span {
        background:#fff;
        padding:0 10px;
    }

</style>
