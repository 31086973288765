<template>
  <div id="toPrint">
    <card style="font-size: 12px">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav-justified">
            <li>
              <button
                v-if="finalUserPermissions.includes('API_TALLY-OPERATION-SHIFTS_GET') &&
                 (finalUserPermissions.includes('API_TALLY-REPORTS_TALLY-SHEET-DESTUFFING_{SHIFTID}_{TALLIEDDOCUMENTID}_{DAY}_{OPERATIONTYPE}_GET')
                 ||finalUserPermissions.includes('API_TALLY-REPORTS_TALLY-SHEET_{SHIFTID}_{TALLIEDDOCUMENTID}_{DAY}_{CARGOTYPE}_{OPERATIONTYPE}_GET')) "
                @click="openModalTallySheet('TALLY_SHEET')"
                class="btn btn-blue btn-blue-fill mr-2 custom-btn">
                <svg-icon
                  icon="download"
                  :has-fill="true"
                  class="icons"
                  id="icon-d"
                /> &nbsp;
                Download Tally Sheet
              </button>
            </li>
            <li>
              <button
                @click="openModalTallySheet('DELAYS')"
                class="btn btn-blue btn-blue-fill mr-2 custom-btn">
                <svg-icon
                  icon="eye"
                  :has-fill="true"
                  class="icons"
                  id="icon-ed"
                /> &nbsp;Delays Report
              </button>
            </li>
            <li>
              <button
                @click="openModalTallySheet('EVENTS')"
                class="btn btn-blue btn-blue-fill mr-2 custom-btn">
                <svg-icon
                  icon="eye"
                  :has-fill="true"
                  class="icons"
                  id="icon-ee"
                /> &nbsp;Event Report
              </button>
            </li>
            <li v-if="!singleRequest.charged">
              <button
                type="submit"
                class="btn btn-primary mr-2 custom-btn float-right"
                data-dismiss="modal"
                @click="addTallyDocumentCharge"
              >
                Create Tally Charge
              </button>
            </li>
          </ul>
        </div>
      </div>
    </card>
    <card style="font-size: 12px">
      <div class="row">
        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Tallying Sheet Details
            </h6>

            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Vessel Name
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.notification !== undefined ? singleRequest.notification.vesselName : "" }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Voyage
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.notification !== undefined ? singleRequest.notification.voyage : "" }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Operation Type
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.operationType }}
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Vessel Type
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselType !== null ? vesselType.replace("_", " ") : '' }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Created At
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ dateTimeToHuman(singleRequest.createdAt) }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Completion Status
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.completed ? 'YES' : 'NO' }}
              </div>
            </div>
          </div>

          <br>

          <!--<div class="col-md-12">-->
          <div class="legend">
            <h6 class="legend-title">
              Tallied Bills of Lading
            </h6>

            <br>

            <data-table
              :table-headers="cardTableHeaders"
              :table-data="tallySheets"
              :table-data-keys="cardTableKeys"
              :page-size-prop="pageSize"
              :page-no="pageNo"
              :is-hovered="isHovered"
              :my-loader="myLoader"
              :page-count="pageCount"
              :reload-all-request="reloadAllRequest"
              :my-first-loader="myFirstLoader"
              :no-data-found="noDataFound"
              :search-parameter-prop="searchParameter"
              :has-date-query="true"
              :is-pageable="false"
              :loading-title="'Please wait while system is loading tallied bills of lading'"
              :date-prop="'Created At '"
              :has-custom-button="true"
              :custom-button-name="buttonName"
              @fireCustomMethod="openModal"
            />
          </div>
          <!--</div>-->

          <div class="row noprint">
            <div class="col-md-12 text-right">
              <div class="col-md-12 text-right">
                <!--                        print vessel tally-->

                <!--                <button-->
                <!--                  @click.prevent="openModal(requestId, 'assignUserModal')"-->
                <!--                  class="btn btn-blue btn-blue-fill mr-2"-->
                <!--                >-->
                <!--                  <svg-icon-->
                <!--                    icon="billing"-->
                <!--                    :has-fill="true"-->
                <!--                    class="icons"-->
                <!--                    id="icon-gb"-->
                <!--                  /> &nbsp;-->
                <!--                  Generate Bill-->
                <!--                </button>-->

                <button
                  class="btn btn-default btn-fill btn-space"
                  @click.prevent="goBack"
                >
                  <font-awesome-icon icon="undo"/> &nbsp; Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>

    <modal v-if="showDocumentChargeModal">
      <div class="modal-title col-md-12" slot="header">
        <div class="row">
          <div class="col-md-12">
            <b>Add Document Charge</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <form method="POST">
            <div class="row">
              <div class="col-md-12 form-group">
                <label for="tariffFees">Tariff Fees</label>
                <select
                  class="form-control"
                  id="tariffFees"
                  v-model="tariffFeeId"
                >

                  <option
                    :value="fee.tariffFeeId" v-for="(fee,index) in tariffFees" :key="index">
                    {{ fee.tariffFeeName }}
                  </option>
                </select>
                <div v-if="tariffFeeIdError">
                  <div
                    class="error text-danger"
                    v-if="!$v.tariffFeeId.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :color="color"
                  :loading="loading"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div class="col-md-3">
                <button
                  @click.prevent="closeModal"
                  class="btn btn-fill btn-outline-info float-right"
                  style="margin-inline-start:20px"
                  type="button"
                >
                  Close
                </button>
                <button
                  @click.prevent="submitTallyCharge"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>

          </form>
        </card>
      </div>
    </modal>

    <large-modal v-if="viewContainerOrGoods">
      <div slot="body">
        <div class="row">
          <div class="col-md-12">
            <card v-if="viewGoods">
              <div class="legend">
                <h6 class="legend-title">
                  Cargo Detail
                </h6>
                <div class="row div-table-row">
                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-title mb-3">
                    Truck Number.
                  </div>
                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.truckNo}}
                  </div>

                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-title mb-3">
                    Ticket Number.
                  </div>
                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.ticketNo }}
                  </div>

                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-title mb-3">
                    Package Vehicle Reference.
                  </div>
                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.packageReferenceVehicleVIN }}
                  </div>

                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-title mb-3">
                    Marks and Numbers.
                  </div>
                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.marksAndNumbers }}
                  </div>

                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-title mb-3">
                    Good Item Name.
                  </div>
                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.goodsItemName }}
                  </div>

                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-title mb-3">
                    Net Weight.
                  </div>
                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.netWeight }}
                  </div>

                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-title mb-3">
                    Net Weight Unit.
                  </div>
                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.netWeightUnit }}
                  </div>

                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-title mb-3">
                    Gross Weight
                  </div>
                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.grossWeight }}
                  </div>

                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-title mb-3">
                    Gross Weight Unit
                  </div>
                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.grossWeightUnit }}
                  </div>

                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-title mb-3">
                    Tare Weight
                  </div>
                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.tareWeight }}
                  </div>

                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-title mb-3">
                    Tare Weight Unit
                  </div>
                  <div v-if="currentCargoType==='General Cargo'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.tareWeightUnit }}
                  </div>

                  <div v-if="currentCargoType==='Liquid Bulk'" class="col-md-2 div-table div-table-title mb-3">
                    Volume
                  </div>
                  <div v-if="currentCargoType==='Liquid Bulk'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.volume }}
                  </div>

                  <div v-if="currentCargoType==='Liquid Bulk'" class="col-md-2 div-table div-table-title mb-3">
                    Volume Unit
                  </div>
                  <div v-if="currentCargoType==='Liquid Bulk'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.volumeUnit }}
                  </div>

                  <div class="col-md-2 div-table div-table-title mb-3">
                    Remarks
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.description }}
                  </div>

                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-title mb-3">
                    Package Quantity
                  </div>
                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.packageQuantity }}
                  </div>


                  <!--<div class="col-md-2 div-table div-table-title mb-3">-->
                  <!--Good Category-->
                  <!--</div>-->
                  <!--<div class="col-md-2 div-table div-table-value mb-3">-->
                  <!--{{ requests.goodCategory }}-->
                  <!--</div>-->

                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-title mb-3">
                    Package Type
                  </div>
                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.packageType }}
                  </div>

                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-title mb-3">
                    Cargo Condition
                  </div>
                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.cargoCondition }}
                  </div>

                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-title mb-3">
                    Damages
                  </div>
                  <div v-if="currentCargoType==='Package'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.cargoDamageTypes !== "" ? requests.cargoDamageTypes.toString() : "N/A"}}
                  </div>

                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-title mb-3">
                    Vehicle Vin
                  </div>
                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.vehicleVIN }}
                  </div>

                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-title mb-3">
                    Vehicle Model
                  </div>
                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.vehicleModel }}
                  </div>

                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-title mb-3">
                    Vehicle Make
                  </div>
                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.vehicleMake }}
                  </div>

                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-title mb-3">
                    Vehicle Status
                  </div>
                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.vehicleStatus }}
                  </div>

                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-title mb-3">
                    Vehicle Condition
                  </div>
                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.vehicleCondition }}
                  </div>

                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-title mb-3">
                    Damages
                  </div>
                  <div v-if="currentCargoType==='Vehicle'" class="col-md-2 div-table div-table-value mb-3">
                    {{ (requests.vehicleDamageTypes !== "" && requests.vehicleDamageTypes !== undefined) ?
                    requests.vehicleDamageTypes.toString() : "N/A" }}
                  </div>

                  <div class="col-md-2 div-table div-table-title mb-3">
                    Tallied By
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.createdBy }}
                  </div>

                  <div class="col-md-2 div-table div-table-title mb-3">
                    Tally Date.
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.createdAt }}
                  </div>

                </div>
              </div>
            </card>
            <card v-if="viewContainer">
              <div class="legend">
                <h6 class="legend-title">
                  Container Details
                </h6>
                <div class="row div-table-row">
                  <div class="col-md-2 div-table div-table-title mb-3">
                    Container Number.
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.containerNo }}
                  </div>

                  <div class="col-md-2 div-table div-table-title mb-3">
                    Container Size.
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.containerSize }}
                  </div>

                  <div class="col-md-2 div-table div-table-title mb-3">
                    Container Status.
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.containerStatus }}
                  </div>

                  <div class="col-md-2 div-table div-table-title mb-3">
                    Container Condition.
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.containerCondition }}
                  </div>

                  <div class="col-md-2 div-table div-table-title mb-3">
                    Remarks.
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.description }}
                  </div>

                  <div class="col-md-2 div-table div-table-title mb-3">
                    Seal Numbers.
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ (requests.sealNumbers !== null && requests.sealNumbers !== undefined) ?
                    Array.prototype.map.call(requests.sealNumbers, function(item) { return item.sealNumber; }).join(",")
                    :
                    "NILL"}}
                  </div>

                  <div class="col-md-2 div-table div-table-title mb-3">
                    Damages.
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ (requests.damageTypes !== "" && requests.damageTypes !== undefined) ?
                    requests.damageTypes.toString() : "N/A" }}
                  </div>

                  <div class="col-md-2 div-table div-table-title mb-3">
                    Tallied By.
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.createdBy }}
                  </div>

                  <div class="col-md-2 div-table div-table-title mb-3">
                    Tally Date.
                  </div>
                  <div class="col-md-2 div-table div-table-value mb-3">
                    {{ requests.createdAt }}
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
        <div class="col-md-12">
          <hr>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-9 left loader-div-gif"/>
            <div class="col-md-3">
              <button
                type="button"
                class="btn btn-fill btn-outline-info float-right"
                @click="closeModal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </large-modal>
    <modal v-if="tallyReports">
      <div slot="header" class="modal-title col-md-12">
        <div class="row">
          <div class="col-md-12 text-center pl-5">
            <h5><b>{{ reportName }} Report</b></h5>
          </div>
          <div class="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <div slot="body">
        <div class="row">
          <div class="col-md-12">
            <tally-reports-form
              :tallyDaily="tallyDaily"
              :tallySheet="tallySheet"
              :tallyShift="tallyShift"
              :tallyVesselPerformance="tallyVesselPerformance"
              :operation-type="stuffingType"
              :tally-delays="tallyDelays"
              @closeModal="closeModal"
              @downloadReports="downloadReports">

            </tally-reports-form>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

  import {mapGetters, mapActions} from 'vuex';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import GlobalModal from '../../../components/globalLoader';
  import DataTable from '../../../components/DataTable/updatedTable';
  import Modal from '../../../components/Inputs/Modal';
  import LargeModal from '../../../components/Inputs/ModalPlanAndCostEstimation';
  import TallyReportsForm from "../../../components/Form/tallySheetShiftDailyVesselPerformance";
  import SvgIcon from '../../../components/SvgLoader/svgLoader';
  import TALLYAPI from '../../../api/tally';
  import API_REVENUE from '../../../api/rev';

  export default {

    name: 'TallySheetDetails',

    components: {

      PulseLoader,
      Modal,
      DataTable,
      GlobalModal,
      SvgIcon,
      LargeModal,
      TallyReportsForm

    },
    created() {
      this.documentId = this.$route.params.documentId;
      this.loadTallySheetDetailsOnCreated(this.documentId);
      this.getTariffFees();
      this.$store.dispatch('pagenames/currentPageName', 'Tally Sheet Details');
    },

    computed: {
      ...mapGetters('notifications', [

        'getNewAppPage'

      ]),
      ...mapGetters({

        getRequestList: 'shippingtallying/getRequestList',
        getPageCount: 'shippingtallying/getPageCount',
        getMyFirstLoader: 'shippingtallying/getMyFirstLoader',
        getNoDataFound: 'shippingtallying/getNoDataFound',
        getSuccessAlert: 'shippingtallying/getSuccessAlert',
        getErrorMessage: 'shippingtallying/getErrorMessage',
        getErrorAlert: 'shippingtallying/getErrorAlert',
        getSuccessMessage: 'shippingtallying/getSuccessMessage',
        getReloadAllRequest: 'shippingtallying/getReloadAllRequest',
        getMyLoader: 'shippingtallying/getMyLoader',
        getSearchParameter: 'shippingtallying/getSearchParameter',
        getHasSearchedVariable: 'shippingtallying/getHasSearchedVariable',
        getPageNo: 'shippingtallying/getPageNo',
        getRequestId: 'shippingtallying/getRequestId'
      }),

      ...mapGetters({

        getRequestListC: 'talliedloadingordischarge/getRequestList',
        getPageCountC: 'talliedloadingordischarge/getPageCount',
        getMyFirstLoaderC: 'talliedloadingordischarge/getMyFirstLoader',
        getNoDataFoundC: 'talliedloadingordischarge/getNoDataFound',
        getSuccessAlertC: 'talliedloadingordischarge/getSuccessAlert',
        getErrorMessageC: 'talliedloadingordischarge/getErrorMessage',
        getErrorAlertC: 'talliedloadingordischarge/getErrorAlert',
        getSuccessMessageC: 'talliedloadingordischarge/getSuccessMessage',
        getReloadAllRequestC: 'talliedloadingordischarge/getReloadAllRequest',
        getMyLoaderC: 'talliedloadingordischarge/getMyLoader',
        getSearchParameterC: 'talliedloadingordischarge/getSearchParameter',
        getHasSearchedVariableC: 'talliedloadingordischarge/getHasSearchedVariable',
        getPageNoC: 'talliedloadingordischarge/getPageNo',
        getRequestIdC: 'talliedloadingordischarge/getRequestId',
        getBlNumberIdC: 'talliedloadingordischarge/getBlNumberId'
      }),

      ...mapGetters({

        getTallyShiftList: "tallyshift/getRequestList",
        getSuccessMessageR: "tallyshift/getSuccessMessage",
        getErrorAlertR: "tallyshift/getErrorAlert",
        getErrorMessageR: "tallyshift/getErrorMessage",
        getSuccessAlertR: "tallyshift/getSuccessAlert",
        getMyLoaderR: "tallyshift/getMyLoader",
      }),
    },

    data() {
      return {

        // modals
        showDelayModal: false,
        remarks: [],
        stuffingType: '',
        vesselType: '',
        editMyLoader: false,
        color: 'green',
        size: '12px',
        loading: true,

        ifThereIsParam: '',
        currentCargoType: '',

        tariffFeeId: '',
        tariffFeeIdError: false,
        tariffFees: [],

        finalUserPermissions: [],

        singleRequest: {},
        tallySheets: [],

        showDocumentChargeModal: false,
        searchParameter: '',
        hasSearchedVariable: false,
        reloadAllRequest: false,
        noDataFound: false,
        pdfFormatError: false,
        loadAllHeaderLoader: false,
        allAttachmentVerified: false,
        myLoader: false,
        myFirstLoader: false,
        isSearchable: false,
        requests: {},
        requestFlag: 'EXP',
        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        sortBy: 'createdAt',
        isHovered: [],
        buttonName: 'View',
        viewContainer: false,
        viewGoods: false,
        viewContainerOrGoods: false,
        isContainerOrGoods: '',

        cardTableHeaders: ['cargo Type', 'Container', 'Chassis Number', 'Container Size'],
        cardTableKeys: ['cargoType', 'containerNumber', 'chassisNumber', 'containerSize'],

        tallyReports: false,
        tallySheet: false,
        tallyDelays: false,
        tallyDaily: false,
        tallyShift: false,
        tallyVesselPerformance: false,
        reportName: "",
      };
    },
    methods: {

      ...mapActions('shippingtallying', [

        'loadShippingTallySheetsOnCreated',
        'loadAllShippingTallyingSheets',
        'getShippingTallySheetsDetails'


      ]),

      ...mapActions("tallyshift", [

        "downloadTallySheetReport",
        "downloadTallyDailyReport",
        "downloadTallyShiftReport",
        "downloadVesselPerformanceReport",

        "downloadTallySheetStuffing",
        "downloadTallyDailyReportStuffing",
        "downloadTallyShiftReportStuffing",

      ]),

      getDelayReport() {
        this.loading = true;

        TALLYAPI.getTallyDelayRemarks(this.documentId).then((response) => {
          this.loading = false;
          this.remarks = response.data.data;
        });
      },

      ...mapActions('talliedloadingordischarge', [

        'loadTalliedBlContainer',
        'loadTTalliedBlGoods'

      ]),

      loadParameters() {
        return new Promise((resolve) => {
          this.$store.dispatch('shippingtallying/pageSizing', this.pageSize);
          this.$store.dispatch('shippingtallying/pageNumbering', this.pageNo);
          this.$store.dispatch('shippingtallying/requestid', this.documentId);

          resolve();
        });
      },


      loadTallySheetDetailsOnCreated() {
        this.loadParameters().then(() => {
          this.getShippingTallySheetsDetails({}).then(() => {
            this.singleRequest = this.getRequestList;
            this.pageCount = this.getPageCount;
            this.myFirstLoader = this.getMyFirstLoader;
            this.noDataFound = this.getNoDataFound;
            this.SuccessMessage = this.getSuccessAlert;
            this.ErrorMessage = this.getErrorMessage;
            this.ErrorAlert = this.getErrorAlert;
            this.SuccessAlert = this.getSuccessMessage;
            this.pageNo = this.getPageNo;
            this.pageSize = this.getPageSize;
            this.reloadAllRequest = this.getReloadAllRequest;
            this.vesselType = this.singleRequest.notification.cargoType;
            this.stuffingType = this.singleRequest.operationType;

            this.singleRequest.billOfLadings.map((data) => {
              data = Object.assign(data, {
                containerNumber: data.containers.length !== 0 ? data.containers[0].containerNo : "N/A",
                containerSize: data.containers.length !== 0 ? data.containers[0].containerSize : "N/A",
                chassisNumber: data.goodDetails.length !== 0 ? data.goodDetails[0].vehicleVIN : "N/A",

              });

              this.tallySheets.push(data)
            });

            this.tableDetails = this.singleRequest.billOfLadings;
            this.pageCount = this.tableDetails.length;
          }).catch(() => {

          });

          this.myFirstLoader = this.getMyFirstLoader;
          this.singleRequest = this.getRequestList;
        }).catch(() => {

        });
      },


      setAllGettersForContainer() {
        return new Promise((resolve) => {
          this.requests = this.getRequestListC[0];
          this.myFirstLoader = this.getMyFirstLoaderC;
          this.noDataFound = this.getNoDataFoundC;
          this.SuccessMessage = this.getSuccessAlertC;
          this.ErrorMessage = this.getErrorMessageC;
          this.ErrorAlert = this.getErrorAlertC;
          this.SuccessAlert = this.getSuccessMessageC;
          resolve();
        });
      },

      loadContainer() {
        this.loadTalliedBlContainer({}).then(() => {
          this.setAllGettersForContainer();
        }).catch(() => {

        });

        this.myFirstLoader = this.getMyFirstLoaderC;
      },

      loadGoods() {
        this.loadTTalliedBlGoods({}).then(() => {
          this.setAllGettersForContainer();
        }).catch(() => {

        });

        this.myFirstLoader = this.getMyFirstLoaderC;
      },

      loadTallySheetPayload(operationDate, cargoTypeName, shiftId, operationLocation) {
        return new Promise((resolve) => {
          this.$store.dispatch("tallyshift/tallieddocumentiding", this.documentId);
          this.$store.dispatch("tallyshift/cargotyping", this.vesselType);
          this.$store.dispatch("tallyshift/patloaddating", operationDate);
          this.$store.dispatch("tallyshift/shiftiding", shiftId);
          this.$store.dispatch("tallyshift/reportnaming", this.singleRequest.voyage);

          this.$store.dispatch("tallyshift/operationLocation", operationLocation);
          this.$store.dispatch("tallyshift/operationTypeValue", this.singleRequest.operationType);
          resolve();
        })
      },

      downloadReports(operationDate, cargoTypeName, shiftId, operationLocation) {
        if (this.singleRequest.operationType === 'LOADING' || this.singleRequest.operationType === 'DISCHARGING') {
          if (this.tallySheet) {
            this.loadTallySheetPayload(operationDate, cargoTypeName, shiftId).then(() => {
              this.downloadTallySheetReport({}).then(() => {
                this.myLoader = this.getMyLoaderR;
                if (this.getSuccessAlertR) {
                  this.loadNotification('success', 4000, 'Request Message',
                    'Your tally sheet report has been successfully downloaded.',
                    'response', 1000, true, true);
                }
                if (this.getErrorAlertR) {
                  this.loadNotification('warn', 4000, 'Request Message',
                    'No content found for requested tally sheet, please try with different category options',
                    'response', 1000, false, true);
                }
              }).catch(() => {

              });
              this.myLoader = this.getMyLoaderR;
            }).catch(() => {

            });
          } else if (this.tallyDaily) {
            this.loadTallySheetPayload(operationDate, cargoTypeName).then(() => {
              this.downloadTallyDailyReport({}).then(() => {
                this.myLoader = this.getMyLoaderR;
                if (this.getSuccessAlertR) {
                  this.loadNotification('success', 4000, 'Request Message',
                    'Your tally sheet report has been successfully downloaded, please have a look at it',
                    'response', 1000, true, true);
                }
                if (this.getErrorAlertR) {
                  this.loadNotification('warn', 4000, 'Request Message',
                    'No content found for requested tally sheet, please try with different category options',
                    'response', 1000, false, true);
                }
              }).catch(() => {

              });
              this.myLoader = this.getMyLoaderR;
            }).catch(() => {

            });
          } else if (this.tallyShift) {
            this.loadTallySheetPayload(operationDate, cargoTypeName, shiftId).then(() => {
              this.downloadTallyShiftReport({}).then(() => {
                this.myLoader = this.getMyLoaderR;
                if (this.getSuccessAlertR) {
                  this.loadNotification('success', 4000, 'Request Message',
                    'Your tally sheet report has been successifully downloaded, please have a look at it',
                    'response', 1000, true, true);
                }
                if (this.getErrorAlertR) {
                  this.loadNotification('warn', 4000, 'Request Message',
                    'No content found for requested tally sheet, please try with different category options',
                    'response', 1000, false, true);
                }
              }).catch(() => {

              });
              this.myLoader = this.getMyLoaderR;
            }).catch(() => {

            });
          }
        }
      },

      getTariffFees() {
        API_REVENUE.getActiveTariffFee().then((response) => {
          this.tariffFees = response.data.data
        })
      },

      addTallyDocumentCharge() {
        this.showDocumentChargeModal = true;
      },

      openModalTallySheet(flag) {
        this.tallyReports = true;

        if (flag === 'TALLY_SHEET') {
          this.tallySheet = true;
          this.tallyShift = false;
          this.tallyDaily = false;
          this.tallyVesselPerformance = false;
          this.reportName = "Tally Sheet ";


        } else {

          this.tallyDelays = true;
          this.tallySheet = false;
          this.reportName = "Tally Delays ";

        }

      },

      submitTallyCharge() {

        if (this.tariffFeeId === '') {
          this.tariffFeeIdError = true
        } else if (this.tariffFeeId !== '') {

          this.showDocumentChargeModal = false;
          this.loading = true;

          const charge = {
            documentId: this.singleRequest.id,
            notificationId: this.singleRequest.notification.id,
            operationType: this.singleRequest.operationType,
            tariffFeeId: this.tariffFeeId
          };

          TALLYAPI.addTallyCharge(charge).then((response) => {
            this.loading = false;
            if (response.data.status) {
              this.loadNotification('success', 4000, 'Charging Message', ' Tally charge has been successfully created',
                'response', 1000, true, true);

              this.loadEDIDocumentDetailsOnCreated();
            } else {
              this.loadNotification('error', 4000, 'Mail Message', ' Something went wrong, please retry',
                'response', 1000, true, true);
            }

            //this.goBack();

          }).catch((e) => {
            this.loading = false;
          });
        }
      },

      openModal(object) {

        this.viewContainerOrGoods = true;
        this.currentCargoType = object.cargoType;

        if (this.currentCargoType === 'Container') {
          this.$store.dispatch('talliedloadingordischarge/loadingOrDischargingId', object.containers[0].id);
          this.loadContainer();
          this.viewContainer = true;
        } else {
          this.$store.dispatch('talliedloadingordischarge/loadingOrDischargingId', object.goodDetails[0].id);
          this.loadGoods();
          this.viewGoods = true;
        }
      },

      closeModal() {
        this.viewContainerOrGoods = false;
        this.viewContainer = false;
        this.viewGoods = false;
        this.tallyReports = false;
        this.tallySheet = false;
        this.tallyShift = false;
        this.tallyDaily = false;
        this.tallyVesselPerformance = false;
        this.showDocumentChargeModal = false;

      },

      goBack() {
        if (window.$cookies.isKey('browser-local-cashed-url-back')) {
          this.$router.push(window.$cookies.get('browser-local-cashed-url-back'));

          window.$cookies.remove('browser-local-cashed-url-back');
        }
      }
    }
  };
</script>

<style scoped>

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
  }

  ul.nav-justified li a:hover {
    color: #d59a18;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
  }

  .btn-blue:hover {
    border-color: #0000ff;
    color: #0000ff;
    background-color: #ffffff;
  }

  .btn-blue-fill:hover #icon-d, .btn-blue-fill:hover #icon-ee, .btn-blue-fill:hover #icon-ed {

    fill: #000000;
  }

  .btn-blue {
    border-color: #4169e1;
    color: #4169e1;
  }

  .btn-blue-fill {
    background-color: #4169e1;
    opacity: 1;
    color: #FFFFFF;
  }

  .icons {
    margin-top: 5px;
    fill: #FFFFFF;
    font-size: 14px;
  }

</style>
