<template>
    <div>
        <card>
        <div class="row">
            <div class="col-md-12">
            <button
                type="submit"
                class="btn btn-primary btn-space mr-2 custom-btn float-right"
                @click.prevent="openAddModal('ADD')"
            > Add Service Provider
            </button>
            </div>
        </div>

        <hr />

        <data-table
            :table-headers="tableHeaders"
            :table-data="filteredRequests"
            :table-data-keys="tableDataKeys"
            :my-first-loader="myFirstLoader"
            :myLoader="myLoader"
            :page-size-prop="pageSize"
            :page-no="pageNo"
            :total-elements="totalElements"
            :total-pages="totalPages"
            :is-first="isFirst"
            :is-last="isLast"
            :page-number="pageNumber"
            :no-data-found="noDataFound"
            :has-manage-button="false"
            :has-view-button="true"
            :loading-title="'Please wait while the system is loading shipping agency service providers'"
            :permissions="[ {key: 'searchPermission', value: 'SERVICE_PROVIDER_ALL_GET'} ]"
            :whichModuleAndType="whichModuleAndType"
            @resetParams="resetParams"
            @search="searchGeneralChargeParams"
            @goToView="openEditModal"
            @goToPage="goToPage"
            @onChange="onChange"
        >
        <div slot = "pagination">
            <paginate
                v-model="getIsPage"
                :page-count=parseInt(totalPages)
                :page-range="3"
                :margin-pages="2"
                :click-handler="goToPage"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'">
            </paginate>
        </div>
        </data-table>
    </card>
    <modal-edit-plan v-if="showModal">
            <div slot="header" class="modal-title">
                {{ title }}
            </div>
            <div slot="body">
                <card>
                    <div class="row">
                        <div class="col-md-12">
                            <form>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="legend">
                                            <h6 class="legend-title">
                                            Service Provider Type
                                            </h6>

                                            <div class="row">
                                                <div class="col-md-6 text-right">
                                                <div class="custom-control">
                                                <input id="1" value="1" name="charged" type="radio" v-model="government">&nbsp;
                                                <label for="1"><b>Government Service Provide</b></label>
                                                </div>
                                                </div>
                                                <div class="col-md-6 text-left">
                                                    <div class="custom-control">
                                                    <input id="2" value="2" name="charged" type="radio" v-model="government">&nbsp;
                                                    <label for="2"><b>Non Government Service Provide</b></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="government === '1' || government === '2'">
                                    <div class="col-md-12">
                                        <base-input
                                        v-model="name"
                                        class="special-bottom"
                                        label="Name"
                                        placeholder="Name"
                                        />
                                        <div v-if="!$v.name.required && nameError" class="text-danger" > This field is required </div>
                                    </div>
                                    <div class="col-md-6" v-if="government === '2'">
                                        <base-input
                                        v-model="accountNo"
                                        class="special-bottom"
                                        label="Account Number"
                                        placeholder="Account Number"
                                        />
                                        <div v-if="!$v.accountNo.required && accountNoError" class="text-danger" > This field is required </div>
                                    </div>
                                    <div class="col-md-6">
                                        <base-input
                                        v-model="phoneNumber"
                                        class="special-bottom"
                                        label="Phone Number"
                                        placeholder="Phone Number"
                                        />
                                        <div v-if="!$v.phoneNumber.required && phoneNumberError" class="text-danger" > This field is required </div>
                                    </div>
                                    <div class="col-md-6">
                                        <base-input
                                        v-model="tinNo"
                                        class="special-bottom"
                                        label="TIN"
                                        placeholder="TIN"
                                        @input="validateTin"
                                        @blur="hyphenifyThisTin"
                                        />
                                        <div v-if="!$v.tinNo.required && tinNoError" class="text-danger" > This field is required </div>
                                    </div>
                                    <div class="col-md-6">
                                        <base-input
                                        v-model="vrn"
                                        class="special-bottom"
                                        label="VRN"
                                        placeholder="VRN"
                                        />
                                        <div v-if="!$v.vrn.required && vrnError" class="text-danger" > This field is required </div>
                                    </div>
                                    <div class="col-md-12">
                                        <label>Address</label>
                                        <textarea class="special-bottom form-control col-md-12" placeholder="Address" rows="5"
                                        type="textarea" v-model="address" value="address"></textarea>
                                        <div v-if="!$v.address.required && addressError" class="text-danger" > This field is required </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <hr />
                                </div>
                                <div class="col-md-10 text-right pt-3">
                                    <pulse-loader :loading="isSavingServiceProvider" :color="color" :size="size" />
                                </div>
                                <div class="col-md-2 pr-3">
                                    <button
                                    type="button"
                                    class="btn btn-fill btn-outline-info custom-btn-all float-right"
                                    :disabled="isSavingServiceProvider"
                                    @click.prevent="closeModal"
                                    >
                                    Close
                                    </button>
                                    <button  v-if="(government === '1' || government === '2') && isAddAction"
                                    @click.prevent="addNewServiceProvider"
                                    class="btn btn-primary btn-space custom-btn float-right mr-2"
                                    :disabled="isSavingServiceProvider"
                                    data-dismiss="modal">
                                    Save
                                    </button>
                                    <button
                                    @click.prevent="editServiceProvider" v-if="(government === '1' || government === '2') && !isAddAction"
                                    class="btn btn-primary btn-space custom-btn float-right mr-2"
                                    :disabled="isSavingServiceProvider"
                                    data-dismiss="modal">
                                    Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
    </modal-edit-plan>
    </div>
</template>
<script>

import ModalEditPlan from '../../../components/Inputs/ModalEditPlan.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { ListSelect } from 'vue-search-select'

export default {

    name: 'ShippingAgencyServiceProvider',

    components: {

        PulseLoader,
        ModalEditPlan,
        ListSelect,
        DataTable: () => import('../../../components/DataTable/dataTable')

    },

    created () {

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
        this.$store.dispatch('pagenames/currentPageName', 'Shipping Agency Service Providers')
        this.loadRequest()

    },

    computed: {

        ...mapGetters('shippingagencyserviceprovider', [

            'getRequestList',
            'getPageCount',
            'getMyFirstLoader',
            'getNoDataFound',
            'getMyLoader',
            'getHasSearchedVariable',
            'getPageNo',
            'getTotalElement',
            'getTotalPage',
            'getPageNumber',
            'getIsFirst',
            'getIsLast',
            'getPageSize'

        ]),

        getIsPage: {

            get: function () {

                return this.isPage

            },
            set: function (newValue) {

                this.isPage = newValue;

            }

        }

    },

    validations: {

        accountNo: {required},
        address: {required},
        government: {required},
        name: {required},
        phoneNumber: {required},
        tinNo: {required},
        vrn: {required},

    },

    data () {

        return {

            finalUserPermissions: [],
            filteredRequests: [],
            requests: [],

            color: 'green',
            size: '11px',

            accountNo: '',
            address: '',
            government: '',
            name: '',
            phoneNumber: '',
            tinNo: '',
            vrn: '',
            id: '',

            nameError: false,
            accountNoError: false,
            phoneNumberError: false,
            tinNoError: false,
            vrnError: false,
            addressError: false,

            title: '',
            showModal: false,
            isAddAction: true,
            isSavingServiceProvider: false,

            whichModuleAndType: 'SAServiceProvider',

            myFirstLoader: false,
            myLoader: false,
            noDataFound: false,
            isPage: 0,
            pageNo: 0,
            pageSize: 15,
            pageCount: 1,
            totalElements: '',
            totalPages: '',
            isFirst: false,
            isLast: false,
            pageNumber: '',
            initialParams: '?size=' + 15 + '&page=' + 0,
            newParams: '',
            tableHeaders: ['Account Number', 'Address', 'Payment Type', 'Name', 'Phone Number', 'TIN', 'VRN'],
            tableDataKeys: ['accountNo', 'address', 'government', 'name', 'phoneNumber', 'tinNo', 'vrn'],

        }
    },

    methods: {

        ...mapActions('shippingagencyserviceprovider', [

            'loadShippingAgencyServiceProvidersOnCreated',
            'loadShippingAgencyServiceProvidersAfterCreated',
            'searchWithGeneralChargeParams',
            'createShippingAgencyServiceProvider',
            'updateShippingAgencyServiceProvider'

        ]),

        loadRequest() {

            if (localStorage.getItem("search-params") !== null) {

                this.newParams = ""
                this.newParams = localStorage.getItem("search-params")

                let secondPart = this.newParams.split("size=")[1]
                this.pageNoPart = parseInt(secondPart.split("page=")[1])
                this.isPage = this.pageNoPart + 1

                this.searchGeneralChargeParams(this.newParams, this.pageNoPart)

            } else {

                this.loadPageableRequestOnCreated(this.initialParams , 0)

                this.$store.dispatch("shippingagencyserviceprovider/hasSearchngParams", false);
                this.newParams = ""
                this.pageNoPart = 0

            }

            this.filteredRequests = []

        },

        sortingData(data) {

            for(let x = 0 ; x < data.length ; x++) {

                const requestObject = {

                ...data[x],
                government: data[x].government ? 'Government' : 'Non Government'

                }

                this.filteredRequests.push(requestObject)

            }

        },

        setAllGetters(flag) {

            this.requests = this.getRequestList;
            flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
            flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
            this.noDataFound = this.getNoDataFound;
            this.pageNo = this.getPageNo;
            this.pageSize = this.getPageSize;

            this.totalElements = this.getTotalElement;
            this.totalPages = this.getTotalPage;
            this.pageNumber = this.getPageNumber;
            this.isFirst = this.getIsFirst;
            this.isLast = this.getIsLast;

            this.sortingData(this.requests)

        },

        loadSearchParameters(params, fromApplicationPageNo) {

            let fromApplicationPageNoOrNot
            typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0

            let firstPart = params.split("&page=")[0]
            let secondPart = firstPart.split("size=")[0]
            this.newParams = ""

            this.isPage = fromApplicationPageNo
            this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + fromApplicationPageNoOrNot

            return new Promise((resolve) => {

                this.$store.dispatch("shippingagencyserviceprovider/pageSizing", this.pageSize);
                this.$store.dispatch("shippingagencyserviceprovider/pageNumbering", 0);
                this.$store.dispatch("shippingagencyserviceprovider/paramsSearching", params);

                resolve();

            })

        },

        searchGeneralChargeParams(params, fromApplicationPageNo) {

            this.newParams = params

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.searchWithGeneralChargeParams({}).then(() => {

                this.setAllGetters('onCreated')

                }).catch(() => {


                });

                this.myFirstLoader = this.getMyFirstLoader;

            }).catch(() => {


            });

        },

        loadPageableRequestOnCreated(params, fromApplicationPageNo) {

            this.newParams = params

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.loadShippingAgencyServiceProvidersOnCreated({}).then(() => {

                this.setAllGetters('onCreated')

                }).catch(() => {


                });

                this.myFirstLoader = this.getMyFirstLoader;

            }).catch(() => {


            });

        },

        loadPageableRequestsAfterCreated(params, fromApplicationPageNo) {

            this.newParams = params

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.loadShippingAgencyServiceProvidersAfterCreated({}).then(() => {

                this.setAllGetters('afterCreated')

                }).catch(() => {


                });

                this.myLoader = this.getMyLoader;

            }).catch(() => {


            });

        },

        resetParams () {

            this.loadPageableRequestOnCreated(this.initialParams , 0)
            this.$store.dispatch("shippingagencyserviceprovider/hasSearchngParams", false)
            this.newParams = ""
            this.pageNoPart = 0
            this.isPage = this.pageNoPart + 1
            this.filteredRequests = []

        },

        onChange(event) {

            this.$store.dispatch("shippingagencyserviceprovider/pageSizing", event);
            this.$store.dispatch("shippingagencyserviceprovider/pageNumbering", 0);
            localStorage.removeItem("page-no")
            localStorage.removeItem("page-size")
            this.isPage = 0

            this.pageSize = this.getPageSize;
            this.pageNo = this.getPageNo;

            if (this.getHasSearchedVariable) {

                let firstPart = this.newParams.split("&page=")[0]
                let secondPart = firstPart.split("size=")[0]
                let thirdPart = firstPart.split("size=")[1]
                this.newParams = ""
                this.newParams = secondPart + 'size=' + this.pageSize + '&page=' + 0
                this.pageNoPart = 0

                this.searchGeneralChargeParams(this.newParams , this.pageNoPart);
                this.filteredRequests = []

            } else {

                this.newParams = '?size=' + this.pageSize + '&page=' + this.pageNo
                this.pageNoPart = this.isPage

                this.loadPageableRequestsAfterCreated(this.newParams , 0);
                this.filteredRequests = []

            }

        },

        goToPage(page) {

            this.filteredRequests = []

            if(page > 0) {page = page - 1;}
            this.isPage = page + 1

            this.pageNo = page;

            if (this.getHasSearchedVariable) {

                let firstPart = this.newParams.split("page=")[0]
                this.newParams = ""
                this.newParams = firstPart + '&page=' + this.pageNo
                this.pageNoPart = this.isPage

                this.searchGeneralChargeParams(this.newParams, this.pageNoPart);
                this.filteredRequests = []

            } else {

                this.newParams = '?size=' + this.pageSize + '&page=' + this.pageNo

                this.pageNoPart = this.isPage

                this.loadPageableRequestsAfterCreated(this.newParams, this.pageNoPart - 1);
                this.filteredRequests = []
            }

        },

        addNewServiceProvider () {

            if((this.$v.accountNo.$invalid && this.government === '2') || this.$v.address.$invalid  || this.$v.vrn.$invalid
                || this.$v.name.$invalid || this.$v.phoneNumber.$invalid || this.$v.tinNo.$invalid) {

                if(this.$v.accountNo.$invalid && this.government === '2') {

                    this.accountNoError = true

                }

                if (this.$v.address.$invalid) {

                    this.addressError = true

                }

                if(this.$v.name.$invalid) {

                    this.nameError = true

                }

                if (this.$v.phoneNumber.$invalid) {

                    this.phoneNumberError = true

                }

                if (this.$v.tinNo.$invalid) {

                    this.tinNoError = true

                }

                if (this.$v.vrn.$invalid) {

                    this.vrnError = true

                }

            } else {

                let government

                this.government === '1' ? government = true : government = false

                let saveObject = {

                    accountNo: this.accountNo,
                    address: this.address,
                    government: government,
                    id: null,
                    name: this.name,
                    phoneNumber: this.phoneNumber,
                    tinNo: this.tinNo,
                    vrn: this.vrn

                }

                this.$store.dispatch('shippingagencyserviceprovider/serviceProviderobjecting', saveObject).then(() => {

                    this.createShippingAgencyServiceProvider({}).then((response) => {

                        this.isSavingServiceProvider = this.getMyLoader;

                        if(response.data.status) {

                            this.loadNotification('success', 4000, 'Creation', 'Service provider has been successfully saved',
                            'response', 1000 , true  , true)
                            this.loadRequest()
                            this.closeModal()

                        } else {

                            this.loadNotification('error', 4000, 'Creation', 'Service provider not saved',
                            'response', 1000 , true  , true)

                        }

                    }).catch((e) => {
                        
                        this.loadNotification('error', 4000, 'Creation', 'Service provider not saved',
                            'response', 1000 , true  , true)
                            console.log(e)

                    })

                    this.isSavingServiceProvider = this.getMyLoader;

                })

            }

        },

        editServiceProvider () {

            if(this.$v.accountNo.$invalid || this.$v.address.$invalid  || this.$v.vrn.$invalid
                || this.$v.name.$invalid || this.$v.phoneNumber.$invalid || this.$v.tinNo.$invalid) {

                if(this.$v.accountNo.$invalid) {

                    this.accountNoError = true

                }

                if (this.$v.address.$invalid) {

                    this.addressError = true

                }

                if(this.$v.name.$invalid) {

                    this.nameError = true

                }

                if (this.$v.phoneNumber.$invalid) {

                    this.phoneNumberError = true

                }

                if (this.$v.tinNo.$invalid) {

                    this.tinNoError = true

                }

                if (this.$v.vrn.$invalid) {

                    this.vrnError = true

                }

            } else {

                let government

                this.government === '1' ? government = true : government = false

                let editObject = {

                    accountNo: this.accountNo,
                    address: this.address,
                    government: government,
                    id: this.id,
                    name: this.name,
                    phoneNumber: this.phoneNumber,
                    tinNo: this.tinNo,
                    vrn: this.vrn

                }

                this.$store.dispatch('shippingagencyserviceprovider/serviceProviderobjecting', editObject).then(() => {

                    this.updateShippingAgencyServiceProvider({}).then((response) => {

                        this.isSavingServiceProvider = this.getMyLoader;

                        if(response.data.status) {

                            this.loadNotification('success', 4000, 'Creation', 'Service provider has been successfully updated',
                            'response', 1000 , true  , true)
                            this.loadRequest()
                            this.closeModal()

                        } else {

                            this.loadNotification('error', 4000, 'Creation', 'Service provider not updated',
                            'response', 1000 , true  , true)

                        }

                    }).catch((e) => {
                        
                        this.loadNotification('error', 4000, 'Creation', 'Service provider not updated',
                            'response', 1000 , true  , true)

                    })

                    this.isSavingServiceProvider = this.getMyLoader;

                })

            }

        },

        validateTin () {

            if(isNaN(this.tinNo.replace(/-/g, ''))) {

                this.tinNo = this.tinNo.replace(/[^0-9]/g, '')

            }

        },

        hyphenifyThisTin () {

            this.tinNo = this.hyphenifyTin(this.tinNo)

        },

        openAddModal () {

            this.showModal = true

            this.title = "ADD NEW SERVICE PROVIDER"
            this.isAddAction = true

        },

        openEditModal (object) {

            this.showModal = true

            this.title = "EDIT SERVICE PROVIDER"
            this.isAddAction = false
            this.government = object.government === 'Government' ? '1' : '2'
            this.name = object.name
            this.accountNo = object.accountNo
            this.phoneNumber = object.phoneNumber
            this.tinNo = object.tinNo
            this.vrn = object.vrn
            this.address = object.address
            this.id = object.id

        },

        closeModal () {

            this.showModal = false
            this.isAddAction = true
            this.title = ''

            this.nameError =  false
            this.accountNoError =  false
            this.phoneNumberError =  false
            this.tinNoError =  false
            this.vrnError =  false
            this.addressError =  false

            this.government = ''
            this.name = ''
            this.accountNo = ''
            this.phoneNumber = ''
            this.tinNo = ''
            this.vrn = ''
            this.address = ''

        }

    }
    
}

</script>
<style scoped>
    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
        background-color: #FFFFFF !important;
    }

    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        border-radius: 3px;
        margin-top: 1rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }
</style>
<style>

</style>