<template>
  <div class="content">
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <br>

      <div class="row">
        <div class="col-md-4">
          <div class="text-left">
            Vessel Type List
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
        </div>
        <div class="col-md-4 text-right">
          <span
            class="all-fa-icons btn-space"
            title="add new request"
            @click.prevent="openModal(null , null , null , actionButton = 'AddModal')"
            v-if="finalUserPermissions.includes('VESSELTYPE_CONFIG_POST')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>

      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->

      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>

            <div class="col-md-2 float-left">
              <select
                name="PageSize"
                @change="onChange($event)"
                class=""
                v-model="pageSize"
                style="width:73px;"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-7 text-right"
              v-if="finalUserPermissions.includes('VESSELTYPE_SEARCH-PAGEABLE_POST')"
            >
              <form
                class="search"
                @submit.prevent="loadSearchRequestVesselTypeList(pageNo , pageSize)"
              >
                <div class="form-group float-right">
                  <div class="input-group">
                    <div
                      class="refresh-all"
                      title="load all requests"
                      v-if="reloadAllRequest"
                      @click.prevent="reloadByIcon"
                    >
                      <font-awesome-icon icon="sync-alt" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search ...."
                      id="inputGroup"
                      v-model="searchParamenter"
                      @input="loadAllPageble"
                    >
                    <span
                      class="input-group-addon"
                      @click.prevent="loadSearchRequestVesselTypeList(pageNo , pageSize)"
                    >
                      <font-awesome-icon icon="search" />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div
                class="col-md-12 disable-highlight"
                unselectable="on"
                style="font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left" />
                </span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right" />
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <!--   END TOP PAGENATION AND SEARCH ELEMENTS   -->

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Vessel Name
            </th>
            <th scope="col">
              Vessel Code
            </th>
            <th scope="col">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in getVesselType"
            :key="list.vesselTypeId"
            v-if="!noDataFound"
          >
            <th scope="row">
              {{ index + 1 }}
            </th>
            <td>
              {{ list.vesselTypeName }}
            </td><td>
              {{ list.vesselTypeCode }}
            </td>
            <td>
              <div
                class=" all-fa-icons edit-fa-icon"
                @click.prevent="openModal(list.vesselTypeId ,
                                          list.vesselTypeName , list.vesselTypeCode , actionButton = 'EditModal')"
                title="edit"
                v-if="finalUserPermissions.includes('VESSELTYPE_CONFIG_PUT')"
              >
                <font-awesome-icon icon="edit" />
              </div>
              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                @click.prevent="openModal(list.vesselTypeId , null , null , actionButton = 'ShowModal')"
                title="view"
                v-if="finalUserPermissions.includes('VESSELTYPE_CONFIG_{VESSELTYPEID}_GET')"
              >
                <font-awesome-icon icon="eye" />
              </div>
              <div
                class="all-fa-icons fa-icons-space delete-fa-icon"
                @click.prevent="deleteVesselType(list.vesselTypeId , list.vesselTypeName , list.vesselTypeCode)"
                title="delete"
                v-if="finalUserPermissions.includes('VESSELTYPE_CONFIG_{VESSELTYPEID}_DELETE')"
              >
                <font-awesome-icon icon="trash-alt" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="col-md-12 text-center bg-grey"
        v-if="noDataFound"
      >
        No data found
      </div>

      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
          </div>

          <div
            class="col-md-9 text-right disable-highlight"
            unselectable="on"
            style="line-height: 5px;font-size: 20px"
          >
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              title="go to first page"
            >First
            </span>
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="prevPage"
              title="go to previous page"
            >
              <font-awesome-icon icon="angle-double-left" />
            </span>

            <span class="btn-space" />
            <span class="btn-space" />

            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="nextPage"
              title="go to next page"
            >
              <font-awesome-icon icon="angle-double-right" />
            </span>
            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="lastPage"
              title="go to last page"
            >Last
            </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Vessel Type: <b>{{ vesselTypeName }} ({{ vesselTypeCode }})</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            Register Vessel Type
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            Details for Vessel Type: <b>{{ data.vesselTypeName }}</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editVesselType"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Vessel Name"
                  placeholder="Vessel Name"
                  v-model="vesselTypeName"
                  value="vesselTypeName"
                  class="special-bottom"
                />
                <div v-if="vesselNameErrorE">
                  <div
                    class="error"
                    v-if="!$v.vesselTypeName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Vessel Code"
                  placeholder="Vessel Code"
                  v-model="vesselTypeCode"
                  value="vesselTypeCode"
                  class="special-bottom"
                />
                <div v-if="vesselCodeErrorE">
                  <div
                    class="error"
                    v-if="!$v.vesselTypeCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveVesselType"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Vessel Name"
                  placeholder="Vessel Name"
                  v-model="vesseltype.vesselTypeName"
                  class="special-bottom"
                />
                <div v-if="vesselNameError">
                  <div
                    class="error"
                    v-if="!$v.vesseltype.vesselTypeName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Vessel Code"
                  placeholder="Vessel Code"
                  v-model="vesseltype.vesselTypeCode"
                  class="special-bottom"
                />
                <div v-if="vesselCodeError">
                  <div
                    class="error"
                    v-if="!$v.vesseltype.vesselTypeCode.required"
                  >
                    This filed is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Vessel Name
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.vesselTypeName }}
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Vessel Code
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.vesselTypeCode }}
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'VesselTypeList',

  components: {

    PulseLoader,
    Modal

  },

  computed: {

    ...mapGetters('vesseltypes', [

      'getValuesBeforeEdit',
      'getNameBeforeDelete'

    ])

  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageableVesselTypeList(this.pageNo, this.pageSize)
  },

  validations: {

    vesseltype: {
      vesselTypeName: {
        required
      },
      vesselTypeCode: {
        required
      }
    },

    vesselTypeName: {
      required
    },
    vesselTypeCode: {
      required
    }

  },

  data () {
    return {
      array: [],
      getVesselType: [],

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      docPageNo: 0,
      docPageCount: 0,
      docSortBy: 'id',
      docPageSize: 0,
      getPageableDoc: 0,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,

      userDetails: {},
      permissionGroups: [],
      finalUserPermissions: [],

      data: {},

      color: 'green',
      size: '15px',
      loading: true,

      SuccessMessage: '',
      ErrorMessage: '',

      vesselTypeName: '',
      vesselTypeCode: '',
      vesselTypeId: '',

      error: '',
      vesseltype: {
        vesselTypeName: '',
        vesselTypeCode: ''
      },

      title: null,
      options: {
        color: 'primary',
        width: 290,
        zIndex: 200
      },

      SuccessAlert: false,
      ErrorAlert: false,
      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,

      vesselNameError: false,
      vesselCodeError: false,
      vesselNameErrorE: false,
      vesselCodeErrorE: false
    }
  },
  methods: {

    loadSearchRequestVesselTypeList (pageNo, pageSize) {
      this.myloader = true
      this.reloadAllRequest = true
      this.hasSearchedVariable = true

      API.getSearchPageableVesselTypeList(pageNo, pageSize, this.searchParameter).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            if (response.data.data.length > 0) {
              this.getVesselType = response.data.data
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            } else {
              this.noDataFound = true
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            }
          } else {
            this.noDataFound = true
            this.pageCount = response.data.message
            this.docPageCount = this.pageCount
            this.docPageNo = this.pageNo
            this.docPageSize = this.pageSize
            this.getPageableDoc = response.data.data
            this.myloader = false
          }
        }
      ).catch(e => {
        this.myloader = false
      })
    },

    loadPageableVesselTypeList (pageNo, pageSize) {
      return new Promise((resolve, reject) => {
        this.myloader = true

        API.getPageableVesselTypeList(pageNo, pageSize).then(
          response => {
            if (response.data.status) {
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.getVesselType = response.data.data
              this.myloader = false
            } else {

            }

            resolve()
          }
        ).catch(e => {
          this.ErrorMessage = ' Requests Not loaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false

          reject(e)
        })
      })
    },

    onChange (event) {
      this.pageSize = event.target.value
      this.pageNo = 0
      if (this.hasSearchedVariable) {
        this.loadSearchRequestVesselTypeList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableVesselTypeList(this.pageNo, this.pageSize)
      }
      this.docPageNo = this.pageNo
      this.docPageSize = this.pageSize
      this.docPageCount = this.pageCount
      this.docSortBy = this.sortBy
    },

    nextPage () {
      this.pageNo++
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestVesselTypeList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableVesselTypeList(this.pageNo, this.pageSize)
      }
    },

    prevPage () {
      this.pageNo--
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestVesselTypeList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableVesselTypeList(this.pageNo, this.pageSize)
      }
    },

    firstPage () {
      this.pageNo = 0
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.loadSearchRequestVesselTypeList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableVesselTypeList(this.pageNo, this.pageSize)
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestVesselTypeList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableVesselTypeList(this.pageNo, this.pageSize)
      }
    },

    reloadByIcon () {
      this.loadPageableVesselTypeList(this.pageNo, this.pageSize)
      this.noDataFound = false
      this.hasSearchedVariable = false
      this.reloadAllRequest = false
      this.searchParamenter = ''
    },

    loadAllPageble () {
      if (this.searchParameter === '' && this.hasSearchedVariable === true) {
        this.loadPageableVesselTypeList(this.pageNo, this.pageSize)
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
        this.searchParamenter = ''
      }
    },

    loadVesselType () {
      this.myloader = true

      API.getVesselType().then(
        response => {
          if (response.data.status) {
            this.getVesselType = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadSingleVesselType (data) {
      API.getIndividualVesselType(data).then(
        response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'Data not found'
          }
        }
      ).catch(e => {
        // this.state.errors.push(e)
      })
    },

    saveVesselType () {
      if (this.$v.vesseltype.vesselTypeName.$invalid || this.$v.vesseltype.vesselTypeCode.$invalid) {
        if (this.$v.vesseltype.vesselTypeName.$invalid) {
          this.vesselNameError = true
        }
        if (this.$v.vesseltype.vesselTypeCode.$invalid) {
          this.vesselCodeError = true
        }
      } else {
        this.editMyLoader = true

        // setTimeout(() => {

        API.postVesselType(JSON.stringify(this.vesseltype)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = ', Vessel type [ ' + response.data.data.vesselTypeName + ' ] saved with code [ ' + response.data.data.vesselTypeCode + ' ]'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadPageableVesselTypeList(this.pageNo, this.pageSize)
              this.editMyLoader = false
              this.vesseltype.vesselTypeName = ''
              this.vesseltype.vesselTypeCode = ''
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ' Record not loaded'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.vesseltype.vesselTypeName = ''
          this.vesseltype.vesselTypeCode = ''
          this.closeModal()
        })

        // }, 2000);
      }
    },

    deleteVesselType (data, name, code) {
      this.$store.dispatch('vesseltypes/nameBeforeDelete', name)

      this.$dialog.confirm('If you delete vessel type [ ' + name + ' ] with Code [ ' + code + " ], it'll be gone forever.", {

        loader: true
      })
        .then((dialog) => {
          // setTimeout(() => {

          API.deleteVesselType(data).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ', Vessel type [ ' + this.getNameBeforeDelete + ' ] has been deleted'
                this.$store.dispatch('vesseltypes/nameBeforeDelete', '')
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                this.loadPageableVesselTypeList(this.pageNo, this.pageSize)
              } else {

              }
            }
          ).catch(e => {
            this.ErrorMessage = ' Record not deleted'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
          })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    editVesselType () {
      if (this.$v.vesselTypeName.$invalid || this.$v.vesselTypeCode.$invalid) {
        if (this.$v.vesselTypeName.$invalid) {
          this.vesselNameErrorE = true
        }
        if (this.$v.vesselTypeCode.$invalid) {
          this.vesselCodeErrorE = true
        }
      } else {
        this.editMyLoader = true

        this.array =
            {
              vesselTypeName: this.vesselTypeName,
              vesselTypeCode: this.vesselTypeCode,
              vesselTypeId: this.vesselTypeId
            }

        // setTimeout(() => {

        API.updateVesselType(JSON.stringify(this.array)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = `${this.updatedValueStatus(this.getValuesBeforeEdit,
                  [response.data.data.vesselTypeName, response.data.data.vesselTypeCode], 'Vessel Type', response.data.data.vesselTypeName)}`
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              window.scrollTo(10, 0)
              this.closeModal()
              this.loadPageableVesselTypeList(this.pageNo, this.pageSize)
              this.$store.dispatch('vesseltypes/valuesBeforeEdit', [])
              this.editMyLoader = false
            } else {

            }
          }
        ).catch(e => {
          this.errors = e
          this.editMyLoader = false
          this.ErrorMessage = ' Record not edited'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.closeModal()
        })

        // }, 2000);
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (vesselTypeId = null, vesselTypeName = null, vesselTypeCode = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.AddModalDiv = false
        this.ShowModalDiv = false
        this.vesselTypeName = vesselTypeName
        this.vesselTypeCode = vesselTypeCode
        this.vesselTypeId = vesselTypeId
        this.$store.dispatch('vesseltypes/valuesBeforeEdit', [this.vesselTypeName, this.vesselTypeCode])
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
      } else {
        this.loadSingleVesselType(vesselTypeId)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.vesselNameError = false
      this.vesselCodeError = false
      this.vesselNameErrorE = false
      this.vesselCodeErrorE = false
    }

  }
}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>
