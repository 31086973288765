<template>
  <div>
    <modal v-if="showModal">
      <div slot="body">
        <div class="row">
          <div class="col-md-12">
            <shipping-order-goods @detailsData="receivingDetailsData" ref="selectedCargo" :edit="edit"></shipping-order-goods>
          </div>
          <div class="col-md-12">
            <button class="btn btn-default btn-fill float-right" @click="closeModal">Close</button>
            <button class="btn btn-success float-right mr-3" @click="subMit">Save</button>
          </div>
        </div>
      </div>
    </modal>
    <div class="row">
      <div class="col-md-12 mb-3" v-if="actionButton">
        <button class="btn btn-success mr-2 custom-btn float-right btn-sm" @click.prevent="openModal('add',null)">
          <b>Add Cargo</b>
        </button>
      </div>
      <div class="col-md-12">
        <data-table
          :tableHeaders="tableHeaders"
          :tableData="filteredRequests"
          :tableDataKeys="tableDataKeys"
          :hasFilter="false"
          :myLoader="loading"
          :hasPagination="false"
          :isPageable="false"
          :myFirstLoader="loading"
          :noDataFound="noDataFound"
          :loadingTitle="'Please wait while system is loading CFA applications charges'"
          :hasManageButton="actionButton"
          @goToApplication="goToApplication"
          :hasEditButton="actionButton"
          @goToEdit="showModalEdit"
          :hasDeleteButton="actionButton"
          @goToDelete="deleteCargo"
          :isHovered="[]"
          :is-hovered="false"
          :permissions="[ {key: 'searchPermission', value: 'CFAREPORT_ASYNC_REPORT_GET'} ]"
        >
        </data-table>
      </div>

    </div>
  </div>
</template>

<script>
  import AGENCY_API from "../../../api/agency";
  import Modal from '../../../components/Inputs/Modal'
  import shippingOrderGoods from "./shippingOrderGoods";


    export default {
        name: "applicationCargoV2",

      //props:['shippingOrderId'],
      props:{
        shippingOrderId:{
          type:String,
          required:true
        },
        editSave:{
          type:Boolean,
          default:false
        },
        actionButton:{
          type:Boolean,
          default:true
        }
      },

      created() {
          this.loadCargoDetails();
      },

      components:{
        DataTable: () => import('../../../components/DataTable/dataTable'),
        Modal,
        shippingOrderGoods
      },

      data(){

          return{
            tableHeaders: ['Cargo Name', 'Package Type', 'Shipping Order Status', 'Terms of Delivery'],
            tableDataKeys: ['cargoName', 'packageType', 'shippingOrderStatus', 'termOfDelivery'],
            loading:false,
            noDataFound:false,
            filteredRequests:[],
            showModal:false,
            edit:false,
            add:false,
            selectedCargo:{
              cargoDescription: "",
              cargoName: "",
              shippingOrderGoodId: null,
              marksAndNumbers: "",
              netWeight: 0,
              netWeightUnit: "G",
              numberOfPackages: 0,
              packageType: 0,
              shippingOrderId: 0,
              specialCargos: null,
              vehicleMake: "",
              vehicleModel: "",
              vehicleVIN: "",
              volumeCbm: 0,
              volumeUnit: "",
              weight: 0,
              weightUnit: "G"
            }
          }
      },

      methods:{

        receivingDetailsData(data){
          this.selectedCargo=data.goods;
        },

        loadCargoDetails(){
          this.loading=true;
          AGENCY_API.getCargoforshippingOrder(this.shippingOrderId).then((resp)=>{
            this.loading=false;
            this.filteredRequests=resp.data.data;
          }).catch((err)=>{
            this.loading=false;
            this.loadNotification('error',4000,'Error','Failed to load Cargo Data',1000 , true  , true);
          });
        },

        goToApplication(){

          this.$router.push('/shipping-agency/booking/view/shippingorder/cargo-details/' + this.shippingOrderId);

        },

        showModalEdit(data){
          this.openModal('edit',data);
        },

        showForm(){

          return new Promise((resolve) => {

            this.showModal=true;
            resolve();
          });
        },


        editCargo(data){

          this.showForm().then(()=>{
            this.$refs.selectedCargo.populate(data);
          });
        },

        openModal(type,data){
          if(type==='edit'){
            this.edit=true;
            this.editCargo(data);
          }else{
            this.showModal=true;
            this.add=true;
          }
        },

        closeModal(){
          this.showModal=false;
          this.edit=false;
          this.add=false;
        },

        subMit(){
          this.loading=true;
          this.selectedCargo.shippingOrderId=this.shippingOrderId;
          if(this.edit){
            AGENCY_API.updateCargoToShippingOrder(this.selectedCargo).then((resp)=>{
              this.loading=false;
              this.loadNotification('success',4000,'Success','Cargo Edited',1000 , true  , true);
              this.loadCargoDetails();
              this.closeModal();
            }).catch((err)=>{
              this.loadNotification('error',4000,'Error','Failed to Edit Cargo Data',1000 , true  , true);
            });

          }else {
            AGENCY_API.addCargoToShippingOrder(this.selectedCargo).then((resp)=>{
              this.loading=false;
              this.loadNotification('success',4000,'Success','Cargo Saved',1000 , true  , true);
              this.loadCargoDetails();
              this.closeModal();
            }).catch((err)=>{
              this.loadNotification('error',4000,'Error','Failed to Save Cargo Data',1000 , true , true);
            });
          }

        },

        deleteCargo(cargo){
          AGENCY_API.deleteCargoToShippingOrder(cargo.id).then((resp)=>{
            this.loading=false;
            this.loadNotification('success',4000,'Success','Cargo Deleted',1000 , true  , true);
            this.loadCargoDetails();
          }).catch((err)=>{
            this.loadNotification('error',4000,'Error','Failed to be Deleted',1000 , true  , true);
          });

        },


      },
    }
</script>

<style scoped>

</style>
