<template>
    <div>
      <div class="legend">
        <h6 class="legend-title">
          Disbursement Invoice Payment Attachments
        </h6>

        <data-table
          v-if="localAttachments !== undefined"
          :tableHeaders="attachmentTableHeaders"
          :tableData="localAttachments"
          :tableDataKeys="attachmentTableDataKeys"
          :myFirstLoader="myFirstLoader"
          :noDataFound="noDataFound"
          :has-filter="false"
          :has-pagination="false"
          :is-pageable="false"
          :has-download-icon-button="true"
          :has-delete-icon-button="showDelete"
          :permissions="[ {key: 'searchPermission', value: 'DISBURSEMENT_SUB_LIST_GET'} ]"
          @removeItemFromList="deleteItem"
          @downloadItem="downloadMinioObject">
        </data-table>
      </div>

      <div class="legend mt-3" v-if="showForm">
        <h6 class="legend-title">
          Add Invoice Payment Attachment
        </h6>
        <div class="row">
          <div class="col-md-6" style="margin-top:29px;">
              <div class="upload-btn-wrapper">
                <button class="btn btn-info btn-fill" :disabled="loading">Choose Document..</button>
                <input type="file" name="pda-attachment" id="pda-attachment" @change.prevent="selectedFile" />
              </div>
              <label for="pda-attachment" class="label-upload">
              {{ file ? 'File selected:  ' + file : 'No File Selected' }} </label>

              <div v-if="fileDataError">
                <div class="text-danger"
                      v-if="!$v.fileData.required">This field is required</div>
              </div>
              <div v-if="fileDataPdfError">
                <div class="text-danger">Attach PDF file only</div>
              </div>
          </div>

          <div class="col-md-4">
            <base-input class="special-bottom" placeholder="Description" label="Description"
                        type="text" v-model="description"></base-input>
            <div v-if="descriptionError">
                <div class="text-danger"
                      v-if="!$v.description.required">This field is required</div>
              </div>
          </div>

          <div class="col-md-2" style="margin-top:28px;">
            <button class="btn btn-primary btn-space custom-btn float-right mr-2 float-right" v-if="!loading"
                    @click.prevent="uploadAttachment">Upload
            </button>
            <pulse-loader :color="color" :size="size" style="margin-top:10px;" v-if="loading"/>
          </div>
        </div>
      </div>

      <button
        v-if="showClose"
        @click.prevent="closeModal"
        class="btn btn-fill btn-outline-info float-right btn-space"
        type="button">Close
      </button>

      <global-modal v-if="globalLoader">
          <div slot="globalLoader">
              <div class="row text-center">
              <div class="col-md-12">
                  Downloading requested file, Please wait
              </div>
              <div class="col-md-12">
                  <br>
              </div>
              <div class="col-md-12">
                  <pulse-loader
                  :color="color"
                  :size="size"
                  />
              </div>
              </div>
          </div>
      </global-modal>
    </div>
</template>

<script>
    import AGENCY_API from '../../../api/agency';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import { mapActions, mapGetters } from 'vuex';
    import { required } from 'vuelidate/lib/validators';
    import GlobalModal from '../../../components/globalLoader'

    export default {
      name: "disbursementPaymentAttachments",

      props: {

        // type, required and default are optional, you can reduce it to 'options: Object'
        attachments: { type: Array, required: false},
        disbursementInvoicePayment: {type: Object, required: false},
        showForm: '',
        showClose: '',
        showDelete: ''

      },

      validations: {

        fileData: { required },
        description: { required }

      },

      components: {

        GlobalModal,
        PulseLoader,
        DataTable: () => import('../../../components/DataTable/dataTable')

      },

      computed: {

        ...mapGetters('global', [

          'getGlobalLoader'

        ])

      },

      created() {

          this.localAttachments = this.attachments

      },

      data() {

        return {

          color: 'green',
          size: '13px',

          attachmentTableHeaders: ['Attachment Description'],
          attachmentTableDataKeys: ['description'],
          myFirstLoader: false,
          noDataFound: false,
          fileData: '',
          file: '',
          fileDataError: false,
          descriptionError: false,
          fileDataPdfError: false,
          description: '',
          loading: false,
          globalLoader: false,
          localAttachments: []

        }

      },

      methods: {

        ...mapActions('global', [

          'loadFileFromMinio'

        ]),

        deleteItem(attachment) {

          this.$dialog.confirm('You are about to delete the attachment [ ' + attachment.description + ' ], are you sure?', {

            loader: true,
            html: true,
            animation: 'zoom', // Available: "zoom", "bounce", "fade"
            okText: 'Yes, delete',
            cancelText: 'No, close',

          }).then((dialog) => {

            AGENCY_API.deleteDisbursementPaymentAttachment(attachment.disbursementInvoicePaymentAttachmentId).then(resp => {

            if (resp.status) {

              this.loadNotification('success', 4000, 'Attachment Deleted', ' Attachment has been successfully deleted',
                'response', 1000, true, true);
              this.getAttachments();

            }

          }).catch(() => {})

            dialog.close()

          }).catch(() => {})

        },

        downloadMinioObject(object) {

          let attachName = object.attachmentUrl;
          let fileName = 'Attachment-Payment';

          this.loadParameters(attachName, fileName).then(() => {

            this.loadFileFromMinio({}).then((res) => {

              if(res.status === 204) {

                this.loadNotification('warn', 4000, 'File Download',
                  'File not found, please contact system admin',
                  'response', 1000 , true  , true)

              } else {

                this.loadNotification('success', 4000, 'File Download',
                  'File has been successfully downloaded, please have a look at it',
                  'response', 1000 , true  , true)

              }
              this.globalLoader = this.getGlobalLoader

            }).catch(() => {

              this.loadNotification('error', 4000, 'File Download',
                'File not downloaded, please check your internet connection',
                'response', 1000 , true  , true);
              this.globalLoader = this.getGlobalLoader

            });

            this.globalLoader = this.getGlobalLoader

          }).catch(() => {})

        },

        loadParameters (fileUrl, fileName) {

          return new Promise((resolve) => {

            this.$store.dispatch('global/fileUrling', fileUrl);
            this.$store.dispatch('global/fileNaming', fileName);
            resolve()

          })

        },

        getAttachments() {

          AGENCY_API.getDisbursementPaymentAttachmentsByPaymentId(this.disbursementInvoicePayment.disbursementInvoicePaymentId).then(resp => {

            if (resp.status) {

              this.localAttachments = resp.data.data;

            }

          })

        },

        selectedFile (event) {

          this.fileData = event.target.files[0]
          this.file = this.fileData.name

          if (this.fileData.type !== 'application/pdf') {

              this.fileDataPdfError = true
              this.file = ''

          } else {

              this.fileDataPdfError = false

          }

        },

        uploadAttachment () {

          if (this.$v.fileData.$invalid || this.$v.description.$invalid) {

              if(this.$v.fileData.$invalid) {

                  this.fileDataError = true;

              }

              if(this.$v.description.$invalid) {

                  this.descriptionError = true;

              }

          } else {

              this.loading = true;
              this.fileDataError = false;

              let invoicePaymentId = this.disbursementInvoicePayment.disbursementInvoicePaymentId;

              let formData = new FormData();
              formData.append('file', this.fileData);

              AGENCY_API.uploadPDAPaymentAttachment(formData, invoicePaymentId, this.description).then((response) => {

                this.description = '';
                this.fileData = '';
                this.file = ''
                this.loading = false;
                this.getAttachments()

              }).catch(() => {

                this.loading = false;

              })

          }

        },

        closeModal() {

          this.$emit('closeModal', false);

        }
      }
    }
</script>

<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
  }

  ul.nav-justified li a:hover {
    color: #d59a18;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }
  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }
  .btn-success-custom:hover {
    border-color: #008000;
    color: #008000;
    background-color: #ffffff;
  }

  .btn-success-custom {
    border-color: rgba(0, 128, 0, .5);
    color: rgba(0, 128, 0, .5);
  }

  .btn-success-custom-fill {
    background-color: rgba(0, 128, 0, .8);
    opacity: 1;
    color: #FFFFFF;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    /* color: #054114; */
    padding: 5px 9px 5px 9px !important;
    background-color: #FFFFFF !important;
  }

  .div-table-value{
    border: 1px solid #DCDCDC;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  h3,h4 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid green;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  h3 span,h4 span {
    background:#fff;
    padding:0 10px;
  }

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type=file]:hover {
  cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.label-upload {
  position: absolute;
  top: 9px;
  left: 200px
}

</style>
