<template>
  <div>
    <card>
      <!-- <div class="row">
          <div class="col-12">
              <crud-notification
                  :SuccessMessage="SuccessMessage"
                  :ErrorMessage="ErrorMessage"
                  :SuccessAlert="SuccessAlert"
                  :ErrorAlert="ErrorAlert"
                  @fadeMe="fadeMe"
                  @enter="enter">

              </crud-notification>
          </div>
          <div class="col-md-12">
              <br/>
          </div>
      </div> -->
      <data-table
        :tableHeaders="tableHeaders"
        :tableData="filteredRequests"
        :tableDataKeys="tableDataKeys"
        :pageSizeProp="pageSize"
        :pageNo="pageNo"
        :myLoader="myLoader"
        :reloadAllRequest="reloadAllRequest"
        :myFirstLoader="myFirstLoader"
        :noDataFound="noDataFound"
        :searchParameterProp="searchParameter"
        :loadingTitle="'Please wait while system is loading discharge tally sheets'"
        :hasManageButton="finalUserPermissions.includes('API_TALLYING-DOCUMENTS_{TALLIEDDOCUMENTID}_GET')"
        :placeholder="'Search by voyage or call id'"
        :totalElements="totalElements"
        :totalPages="totalPages"
        :isFirst="isFirst"
        :isLast="isLast"
        :disabled="true"
        :pageNumber="pageNumber"
        :buttonList=" finalUserPermissions.includes('API_TALLYING-DOCUMENTS_COMPLETE_TALLYING_{TALLIEDDOCUMENTID}_PUT') ?  [{'code' : 'FinishTallying' ,'name' : 'Mark As Completed Tallying' , 'class' : 'btn-danger'}]: []"
        @onChange="onChange"
        @goToPage="goToPage"
        @goToApplication="goToApplication"
        @goToFinishTallying="goToFinishTallying">

      </data-table>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">Loading, Please wait</div>
          <div class="col-md-12">
            <br/>
          </div>
          <div class="col-md-12">
            <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

  import {mapGetters, mapActions} from "vuex";
  import PulseLoader from "vue-spinner/src/PulseLoader.vue";
  import GlobalModal from "../../../components/globalLoader";
  import DataTable from "../../../components/DataTable/updatedTable";
  import CrudNotification from "../../../components/DataTable/crudNotification";

  export default {

    name: "dischargeTally",

    components: {
      PulseLoader,
      GlobalModal,
      DataTable,
      CrudNotification
    },

    created() {

      this.$store.dispatch("pagenames/currentPageName", "Discharge Tally Sheet");

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.loadPageableRequestOnCreated();

      this.$store.dispatch("shippingtallying/erroralerting", false);
      this.$store.dispatch("shippingtallying/successalerting", false);
    },

    computed: {

      ...mapGetters("notifications", [

        "getNewAppPage"

      ]),

      ...mapGetters("shippingtallying", [

        "getRequestList",
        "getPageCount",
        "getMyFirstLoader",
        "getNoDataFound",
        "getSuccessAlert",
        "getErrorMessage",
        "getErrorAlert",
        "getSuccessMessage",
        "getReloadAllRequest",
        "getMyLoader",
        "getSearchParameter",
        "getHasSearchedVariable",
        "getPageNo",
        "getPageSize",
        "getRequestId",
        "getTotalElement",
        "getTotalPage",
        "getPageNumber",
        "getIsFirst",
        "getIsLast",
        "getTalliedDocumentId",
      ]),

    },

    data() {

      return {

        pageNo: 0,
        pageSize: 15,
        totalElements: "",
        totalPages: "",
        isFirst: false,
        isLast: false,
        pageNumber: '',
        stuffingType: 'DISCHARGING',

        color: "green",
        size: "12px",
        loading: true,

        searchParameter: "",
        hasSearchedVariable: false,
        reloadAllRequest: false,
        noDataFound: false,
        pdfFormatError: false,
        loadAllHeaderLoader: false,
        allAttachmentVerified: false,
        myLoader: false,
        myFirstLoader: false,
        isSearchable: false,
        requests: [],
        filteredRequests: [],

        ifThereIsParam: "",

        finalUserPermissions: [],
        tableHeaders: ['Voyage', 'Vessel Name', 'Completed', 'Created At'],
        tableDataKeys: ['voyage', 'vessel', 'completed', 'createdAt'],

        SuccessAlert: false,
        ErrorAlert: false,
        SuccessMessage: "",
        ErrorMessage: ""

      }

    },

    methods: {

      ...mapActions("shippingtallying", [

        "loadShippingTallySheetsOnCreated",
        "loadShippingTallySheets",
        "markAsCompletedTally",

      ]),

      loadParameters() {

        return new Promise((resolve) => {

          this.$store.dispatch("shippingtallying/pageSizing", this.pageSize);
          this.$store.dispatch("shippingtallying/pageNumbering", this.pageNo);
          this.$store.dispatch("shippingtallying/stuffingtyping", this.stuffingType);

          resolve();

        })

      },

      setAllGetters(flag) {

        this.requests = this.getRequestList;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
        this.noDataFound = this.getNoDataFound;
        this.SuccessMessage = this.getSuccessMessage;
        this.ErrorMessage = this.getErrorMessage;
        this.ErrorAlert = this.getErrorAlert;
        this.SuccessAlert = this.getSuccessAlert;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;

        this.totalElements = this.getTotalElement;
        this.totalPages = this.getTotalPage;
        this.pageNumber = this.getPageNumber;
        this.isFirst = this.getIsFirst;
        this.isLast = this.getIsLast;
        this.sortingData(this.requests);

      },

      setGettersAfterCompletingTallyDocument(flag) {

        this.requests = this.getRequestList;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
        this.noDataFound = this.getNoDataFound;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;

        this.totalElements = this.getTotalElement;
        this.totalPages = this.getTotalPage;
        this.pageNumber = this.getPageNumber;
        this.isFirst = this.getIsFirst;
        this.isLast = this.getIsLast;

      },

      loadPageableRequestOnCreated() {
        this.loadParameters().then(() => {
          this.loadShippingTallySheetsOnCreated({}).then(() => {
            this.setAllGetters('onCreated');
          }).catch(() => {

          });
          this.myFirstLoader = this.getMyFirstLoader;
        }).catch(() => {

        });
      },

      loadPageableRequestsAfterCreated() {

        this.loadParameters().then(() => {
          this.loadShippingTallySheets({}).then(() => {
            this.setAllGetters('afterCreated');
          });
          this.myLoader = this.getMyLoader;
        }).catch(() => {

        });
      },

      sortingData(data) {
        for (let x = 0; x < data.length; x++) {

          const requestObject = {
            ...data[x],
            voyage: data[x].notification !== null ? data[x].notification.voyage : '',
            vessel: data[x].notification !== null ? data[x].notification.vesselName : '',
          };
          this.filteredRequests.push(requestObject)
        }
      },

      onChange(event) {
        this.$store.dispatch("shippingtallying/pageSizing", event);
        this.$store.dispatch("shippingtallying/pageNumbering", 0);
        this.pageSize = this.getPageSize;
        this.pageNo = this.getPageNo;
        if (this.getHasSearchedVariable) {
          this.searchRequestByName(this.getSearchParameter);
        } else {
          this.loadPageableRequestsAfterCreated();
        }
      },

      goToFinishTallying(object) {

        this.$store.dispatch("shippingtallying/tallieddocumentiding", object.id);

        this.$dialog.confirm("You are about to mark tallied document as complete <br><br>" +
          " Voyage - [ " + object.voyage + " ] <br>" +
          " Cargo Type - [ " + object.cargoTypes + " ] <br>" +
          " Operation Type - [ " + object.operationType + " ] <br><br>" +
          " Are you sure!.", {
          loader: true,
          html: true

        }).then((dialog) => {

          this.markAsCompletedTally(this.getTalliedDocumentId).then(() => {
            this.myLoader = this.getMyLoader;
            if (this.getSuccessAlert) {

              this.loadNotification('success', 4000, 'Request Message',
                'Tallied Document with voyage [ ' + object.voyage + ' ] has been marked as complete',
                'response', 1000, true, true);

            }
            if (this.getErrorAlert) {
              this.loadNotification('error', 4000, 'Request Message',
                'Tallied Document with voyage [ ' + object.voyage + ' ] has been marked as complete',
                'response', 1000, false, true);
            }

            this.loadShippingTallySheetsOnCreated({}).then(() => {
              this.setGettersAfterCompletingTallyDocument('onCreated');
            }).catch(() => {

            });
            this.myFirstLoader = this.getMyFirstLoader;
          }).catch(() => {

          });
          this.myLoader = this.getMyLoader;
          dialog.close();
        });
      },

      goToApplication(documentId) {
        document.cookie = "browser-local-cashed-url-back=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
        this.$router.push("/shipping-tallying/tally-sheet-details/" + documentId.id);
      },

      goToPage(page) {
        this.pageNo = page;
        this.loadPageableRequestsAfterCreated();
      },

      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert;
          this.$store.dispatch("shippingtallying/successalerting", this.SuccessAlert);
        } else {
          this.ErrorAlert = !this.ErrorAlert;
          this.$store.dispatch("shippingtallying/erroralerting", this.ErrorAlert);
        }
      },

      enter: function (el, done) {
        let that = this;
        setTimeout(function () {
          that.SuccessAlert = false;
          that.ErrorAlert = false;
          this.$store.dispatch("shippingtallying/erroralerting", that.ErrorAlert);
          this.$store.dispatch("shippingtallying/successalerting", that.SuccessAlert);
        }, 3000); // hide the message after 3 seconds
      },
    }

  }
</script>

<style scoped>

</style>
