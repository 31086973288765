<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :my-loader="myLoader"
        :page-count="pageCount"
        :reload-all-request="reloadAllRequest"
        :my-first-loader="myFirstLoader"
        :no-data-found="noDataFound"
        :status="'SHIPPING_TALLY'"
        :search-parameter-prop="searchParameter"
        :loading-title="'Please wait while system is loading approved manifest list'"
        @onChange="onChange"
        @goToApplication="goToApplication"
        @searchRequest="searchRequestByName"
        @reloadByIcon="reloadByIcon"
        @loadAllPageable="loadAllPageable"
        @firstPage="firstPage"
        @prevPage="prevPage"
        @nextPage="nextPage"
        @lastPage="lastPage"
      />
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading Applications, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../components/globalLoader'
import DataTable from '../../../components/DataTable/table'

export default {

  name: 'NewApplication',

  components: {

    PulseLoader,
    GlobalModal,
    DataTable

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Approved Manifest List');

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))

    // this.loadApprovedManifestListOnCreated();
  },

  computed: {

    ...mapGetters('notifications', [

      'getNewAppPage'

    ]),

    ...mapGetters('shippingtallying', [

      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getMyLoader',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo'

    ])

  },

  data () {
    return {

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      sortBy: 'requestDate',

      color: 'green',
      size: '12px',
      loading: true,

      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,
      pdfFormatError: false,
      loadAllHeaderLoader: false,
      allAttachmentVerified: false,
      myLoader: false,
      myFirstLoader: false,
      requests: [
        {
          documentId: '112',
          callId: '12',
          mrn: '19PIL000059',
          voyage: '9V9975',
          lineName: 'Lomboki',
          docTime: '18:45',
          terminal: 'WITZDL022',
          eat: '22/07/2019',
          ata: '22/07/2019'
        },
        {
          documentId: '113',
          callId: '13',
          mrn: '19PIL000059',
          voyage: '9V9975',
          lineName: 'Lomboki',
          docTime: '18:45',
          terminal: 'WITZDL022',
          eat: '22/07/2019',
          ata: '22/07/2019'
        },
        {
          documentId: '114',
          callId: '14',
          mrn: '19PIL000059',
          voyage: '9V9975',
          lineName: 'Lomboki',
          docTime: '18:45',
          terminal: 'WITZDL022',
          eat: '22/07/2019',
          ata: '22/07/2019'
        }],

      ifThereIsParam: '',

      finalUserPermissions: [],

      tableHeaders: ['Call ID', 'MRN', 'Voyage', 'liner', 'Doc Time', 'Terminal', 'ETA', 'ATA'],
      tableDataKeys: ['callId', 'mrn', 'voyage', 'lineName', 'docTime', 'terminal', 'eat', 'ata']

    }
  },

  validations: {

    searchParameter: { required }

  },

  methods: {

    ...mapActions('shippingtallying', [

      'loadShippingTallyingApprovedManifestListOnCreated',
      'searchShippingTallyingApprovedManifestList',
      'loadAllShippingTallyingApprovedManifestList',
      'loadShippingTallyingApprovedManifestList'
    ]),

    loadParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('shippingtallying/pageSizing', this.pageSize);
        this.$store.dispatch('shippingtallying/pageNumbering', this.pageNo);

        resolve()
      })
    },

    getSearchParameterValue (searchParam) {
      return new Promise((resolve) => {
        this.searchParameter = searchParam;
        this.$store.dispatch('shippingtallying/searchingParameter', searchParam);
        this.$store.dispatch('shippingtallying/sortingBy', this.sortBy);

        resolve()
      })
    },

    searchRequestByName (searchParam) {
      this.getSearchParameterValue(searchParam).then(() => {
        this.searchShippingTallyingApprovedManifestList({}).then(() => {
          this.requests = this.getRequestList;
          this.pageCount = this.getPageCount;
          this.myLoader = this.getMyLoader;
          this.noDataFound = this.getNoDataFound;
          this.pageNo = this.getPageNo;
          this.pageSize = this.getPageSize;
          this.searchParameter = this.getSearchParameter
        }).catch(() => {

        });

        this.requests = this.getRequestList;
        this.myLoader = this.getMyLoader;
        this.pageCount = this.getPageCount;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;
        this.noDataFound = this.getNoDataFound;
        this.reloadAllRequest = this.getReloadAllRequest;
        this.hasSearchedVariable = this.getHasSearchedVariable
      }).catch(() => {

      })
    },

    loadApprovedManifestListOnCreated () {
      this.loadParameters().then(() => {
        this.loadShippingTallyingApprovedManifestListOnCreated({}).then(() => {
          this.requests = this.getRequestList;
          this.pageCount = this.getPageCount;
          this.myFirstLoader = this.getMyFirstLoader;
          this.noDataFound = this.getNoDataFound;
          this.SuccessMessage = this.getSuccessAlert;
          this.ErrorMessage = this.getErrorMessage;
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessAlert = this.getSuccessMessage;
          this.pageNo = this.getPageNo;
          this.pageSize = this.getPageSize
        }).catch(() => {

        });

        this.myFirstLoader = this.getMyFirstLoader
      }).catch(() => {

      })
    },

    onChange (event) {
      this.$store.dispatch('shippingtallying/pageSizing', event);
      this.$store.dispatch('shippingtallying/pageNumbering', 0);

      this.pageSize = this.getPageSize;
      this.pageNo = this.getPageNo;

      if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
      } else {
        this.loadShippingTallyingApprovedManifestList({}).then(() => {
          this.requests = this.getRequestList;
          this.myLoader = this.getMyLoader;
          this.pageCount = this.getPageCount
        });

        this.myLoader = this.getMyLoader
      }
    },

    nextPage () {
      this.$store.dispatch('shippingtallying/pageNumbering', this.pageNo = this.pageNo + 1);
      this.pageNo = this.getPageNo;

      if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
      } else {
        this.loadShippingTallyingApprovedManifestList({}).then(() => {
          this.requests = this.getRequestList;
          this.myLoader = this.getMyLoader;
          this.pageCount = this.getPageCount
        });

        this.myLoader = this.getMyLoader
      }
    },

    prevPage () {
      this.$store.dispatch('shippingtallying/pageNumbering', this.pageNo = this.pageNo - 1);
      this.pageNo = this.getPageNo;

      if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
      } else {
        this.loadShippingTallyingApprovedManifestList({}).then(() => {
          this.requests = this.getRequestList;
          this.myLoader = this.getMyLoader;
          this.pageCount = this.getPageCount
        });

        this.myLoader = this.getMyLoader
      }
    },

    firstPage () {
      this.$store.dispatch('shippingtallying/pageNumbering', 0);
      this.pageNo = this.getPageNo;

      if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
      } else {
        this.loadShippingTallyingApprovedManifestList({}).then(() => {
          this.requests = this.getRequestList;
          this.myLoader = this.getMyLoader;
          this.pageCount = this.getPageCount
        });

        this.myLoader = this.getMyLoader
      }
    },

    lastPage () {
      this.pageCount = this.getPageCount;

      this.$store.dispatch('shippingtallying/pageNumbering', this.pageNo = this.pageCount - 1);
      this.pageNo = this.getPageNo;

      if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
      } else {
        this.loadShippingTallyingApprovedManifestList({}).then(() => {
          this.requests = this.getRequestList;
          this.myLoader = this.getMyLoader;
          this.pageCount = this.getPageCount
        });

        this.myLoader = this.getMyLoader
      }
    },

    loadAllPageable (searchParam) {
      this.searchParameter = searchParam;

      if (this.searchParameter === '' && this.getHasSearchedVariable) {
        this.loadAllShippingTallyingApprovedManifestList({}).then(() => {
          this.loadShippingTallyingApprovedManifestList({}).then(() => {
            this.requests = this.getRequestList;
            this.myLoader = this.getMyLoader;
            this.pageCount = this.getPageCount;
            this.pageNo = this.getPageNo;
            this.pageSize = this.getPageSize
          });

          this.myLoader = this.getMyLoader;
          this.noDataFound = this.getNoDataFound;
          this.hasSearchedVariable = this.getHasSearchedVariable;
          this.reloadAllRequest = this.getReloadAllRequest;
          this.searchParameter = this.getSearchParameter;
          this.pageCount = this.getPageCount;
          this.pageNo = this.getPageNo;
          this.pageSize = this.getPageSize
        }).catch(() => {

        })
      }
    },

    reloadByIcon (searchParam) {
      this.loadAllShippingTallyingApprovedManifestList({}).then(() => {
        this.loadShippingTallyingApprovedManifestList({}).then(() => {
          this.requests = this.getRequestList;
          this.myLoader = this.getMyLoader;
          this.pageCount = this.getPageCount;
          this.$store.dispatch('shippingtallying/searchingParameter', searchParam)
        });

        this.myLoader = this.getMyLoader;
        this.noDataFound = this.getNoDataFound;
        this.hasSearchedVariable = this.getHasSearchedVariable;
        this.reloadAllRequest = this.getReloadAllRequest;
        this.pageCount = this.getPageCount
      }).catch(() => {

      })
    },

    goToApplication (documentId) {
      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';

      this.$router.push('/shipping-tallying/document-view/' + documentId.documentId)
    }

  }

}
</script>

<style scoped>

    .btn-space {
        margin-right: 10px !important;
    }

    .btn-success-custom {
        padding: 0 4px 0 4px;
        color: #3cb371;
    }

    .btn-danger-custom {
        padding: 0 4px 0 4px;
        color: #e97451;
    }

    .btn-success-custom:hover {
        cursor: pointer;
        color: green;
    }

    .btn-danger-custom:hover {
        cursor: pointer;
        color: red;
    }

    .table-striped tr {
        line-height: 20px;
    }

    .disabled-click {
        pointer-events: none;
        color: #dcdcdc;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */
    }

    .enabled-click {
        pointer-events: auto;
    }

    .search .form-group .input-group .input-group-addon {
        width: 40px;
        text-align: center;
        padding-top: 3.5px;
        background-color: rgba(220, 220, 220, .3);
    }

    .disable-highlight:hover span {
        cursor: pointer;
    }

    .refresh-all {
        margin-top: 6px;
        margin-right: 20px
    }

    .refresh-all:hover {
        cursor: pointer;
    }

    .search .form-group .input-group #inputGroup {
        width: 250px;
        padding-top: 3.5px;
    }

    .search .form-group .input-group .input-group-addon:hover {
        cursor: pointer;
        background-color: rgba(220, 220, 220, .5);
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: 90px;
        background-color: #FFFFFF !important;
    }

    .table td, .table th {
        text-align: center;
    }

    .text-center {
        text-align: center !important;
    }

    .table-hover tbody tr:hover {
        background-color: rgba(153, 186, 221, .5) !important;
    }

    .more-customer-details:hover {
        cursor: pointer;
        color: #d59a18;
    }

    .bg-grey {
        background-color: #dcdcdc;
    }

    .close-info {
        color: blue !important;
    }

    .close-info:hover {
        cursor: pointer;
        color: green !important;
    }

</style>
