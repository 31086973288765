<template>
  <div>
    <card>
      <div
        class="row"
        v-if="myFirstLoader"
      >
        <div class="col-md-12 text-center">
          <b>Please wait while system is loading discharge list</b>
        </div>
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
          />
        </div>
      </div>
      <div
        class="row"
        v-if="!myFirstLoader"
      >
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>

            <div class="col-md-2 float-left">
              <select
                name="PageSize"
                @change="onChange($event)"
                class=""
                v-model="pageSize"
                style="width:73px;"
                :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-7 text-right"
              v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SEARCH-FLAG_{REQUESTTYPEFLAG}_POST')"
            >
              <form
                class="search"
                @submit.prevent="searchRequestByName(pageNo, pageSize, 'requestDate', 'APPROVED')"
              >
                <div class="form-group float-right">
                  <div
                    class="input-group"
                    :class="[ pageCount == 0 && !noDataFound ? 'disabled-click' : 'enabled-click']"
                  >
                    <div
                      class="refresh-all"
                      title="load all requests"
                      v-if="reloadAllRequest"
                      @click.prevent="reloadByIcon"
                    >
                      <font-awesome-icon icon="sync-alt" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search ...."
                      id="inputGroup"
                      v-model="searchParameter"
                      @input="loadAllPageable"
                    >
                    <span
                      class="input-group-addon"
                      @click.prevent="searchRequestByName(pageNo, pageSize, 'requestDate', 'APPROVED')"
                    ><font-awesome-icon icon="search" /></span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div class="row">
                <div
                  class="col-md-12 disable-highlight"
                  style="font-size: 20px"
                >
                  <span
                    class="btn-space"
                    :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                    @click="firstPage"
                    title="go to first page"
                  >First</span>
                  <span
                    class="btn-space"
                    :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                    @click="prevPage"
                    title="go to previous page"
                  ><font-awesome-icon icon="angle-double-left" /></span>

                  <span class="btn-space" />
                  <span class="btn-space" />

                  <span
                    class="btn-space"
                    :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                    @click="nextPage"
                    title="go to next page"
                  ><font-awesome-icon icon="angle-double-right" /></span>
                  <span
                    class="btn-space"
                    :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                    @click="lastPage"
                    title="go to last page"
                  >Last</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">
                  #
                </th>
                <th scope="col">
                  Call Id
                </th>
                <th scope="col">
                  Document Name
                </th>
                <th scope="col">
                  Voyage Number
                </th>
                <th scope="col">
                  Tasac Control Number
                </th>
                <th scope="col">
                  Action
                </th>
                <th style="display: none" />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(list, index) in documents"
                :key="index"
                v-if="!noDataFound"
              >
                <th scope="row">
                  {{ index + 1 +(pageNo * pageSize) }}
                </th>
                <td :class="[ list.isDuplicate ? 'isDublicate' : '']">
                  {{ list.blNumber }}
                </td>
                <td v-if="list.customer !== null && list.customer !== undefined">
                  <span
                    class="more-customer-details"
                    @click="showToolTip(list.requestId)"
                  >{{ (list.customer.name).toUpperCase() }}</span>
                  <span
                    v-if="isHovered.includes(list.requestId)"
                    style="position: absolute;bottom:50px;background-color: #FFFFFF; padding: 10px;border-radius: 4px;border: 1px solid #d59a18;text-align: left"
                  >
                    <p><u><b>Customer Information</b> (click <a
                      @click="hideToolTip(list.requestId)"
                      class="close-info"
                    >here</a> to close)</u></p>
                    <b>TIN:</b> {{ list.customer.tin }}<br>
                    <b>VRN:</b> {{ list.customer.vrn }} <br>
                    <b>MOBILE:</b> {{ list.customer.mobile }} <br>
                    <b>EMAIL:</b> {{ list.customer.email }} <br>
                    <b>CUSTOMER TYPE:</b> {{ list.customer.customerType === 'ind001' ? 'Individual' : 'company' }} <br>
                  </span>
                </td>
                <td v-else="">
                  <I>NIL</I>
                </td>
                <td>{{ dateTimeToHuman(list.requestDate) }}</td>
                <td class="btn-success-custom text-center">
                  <button
                    class="btn btn-success mr-2 custom-btn"
                    v-if="!myLoader && !list.isDuplicate &&
                      finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"
                    @click.prevent="goToApplication(list.requestId)"
                  >
                    <b>Manage</b>
                  </button>

                  <button
                    class="btn btn-danger custom-btn"
                    style="width: 150px"
                    @click.prevent="markAsDuplicate(list.requestId,
                                                    list.blNumber, list.customer.name)"
                    v-if="!myLoader && !list.isDuplicate &&
                      finalUserPermissions.includes('CLEARINGFORWARDING_FULL-REQUEST_{REQUESTID}_GET')"
                  >
                    <b>Mark as duplicate</b>
                  </button>
                </td>
                <td style="display: none" />
              </tr>
            </tbody>
          </table>

          <div
            class="col-md-12 text-center bg-grey"
            v-if="noDataFound"
          >
            No data found
          </div>

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <span v-if="documents.length > 0">Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}</span>
              </div>

              <div
                class="col-md-4 text-right"
                style="padding-right: 90px !important"
              />

              <div
                class="col-md-5 text-right disable-highlight"
                style="line-height: 5px;font-size: 20px;padding-right: 0 !important;"
              >
                <span
                  class="btn-space"
                  :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First</span>
                <span
                  class="btn-space"
                  :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                ><font-awesome-icon icon="angle-double-left" /></span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                ><font-awesome-icon icon="angle-double-right" /></span>
                <span
                  class="btn-space"
                  :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading Applications, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../components/globalLoader'

export default {

  name: 'ApprovedDocument',

  data () {
    return {

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      sortBy: 'requestId',

      color: 'green',
      size: '12px',
      loading: true,

      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,
      myLoader: false,
      myFirstLoader: false,

      documents: [],
      requestFlag: 'EXP',

      isHovered: [],

      ifThereIsParam: '',

      finalUserPermissions: []

    }
  },

  components: {

    PulseLoader,
    GlobalModal

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Import Approved Discharge List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
  },

  methods: {

    onChange (event) {
      this.pageSize = event.target.value
      this.pageNo = 0

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, 'requestDate', 'APPROVED')
      } else {
        if (this.$route.params.requestId) {
          this.ifThereIsParam = this.$route.params.requestId

          this.loadASingleRequestFromNotification(this.$route.params.requestId)
        } else {
          this.loadPageableRequest(this.pageNo, this.pageSize, 'APPROVED')
        }
      }
    },

    nextPage () {
      this.pageNo++

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, 'requestDate', 'APPROVED')
      } else {
        if (this.$route.params.requestId) {
          this.ifThereIsParam = this.$route.params.requestId

          this.loadASingleRequestFromNotification(this.$route.params.requestId)
        } else {
          this.loadPageableRequest(this.pageNo, this.pageSize, 'APPROVED')
        }
      }
    },

    prevPage () {
      this.pageNo--

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, 'requestDate', 'APPROVED')
      } else {
        if (this.$route.params.requestId) {
          this.ifThereIsParam = this.$route.params.requestId

          this.loadASingleRequestFromNotification(this.$route.params.requestId)
        } else {
          this.loadPageableRequest(this.pageNo, this.pageSize, 'APPROVED')
        }
      }
    },

    firstPage () {
      this.pageNo = 0

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, 'requestDate', 'APPROVED')
      } else {
        if (this.$route.params.requestId) {
          this.ifThereIsParam = this.$route.params.requestId

          this.loadASingleRequestFromNotification(this.$route.params.requestId)
        } else {
          this.loadPageableRequest(this.pageNo, this.pageSize, 'APPROVED')
        }
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, 'requestDate', 'APPROVED')
      } else {
        if (this.$route.params.requestId) {
          this.ifThereIsParam = this.$route.params.requestId

          this.loadASingleRequestFromNotification(this.$route.params.requestId)
        } else {
          this.loadPageableRequest(this.pageNo, this.pageSize, 'APPROVED')
        }
      }
    },

    loadAllPageable () {
      if (this.searchParameter === '' && this.hasSearchedVariable === true) {
        this.loadPageableRequest(this.pageNo, this.pageSize, 'APPROVED')
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
        this.searchParameter = ''
      }
    },

    reloadByIcon () {
      this.loadPageableRequest(this.pageNo, this.pageSize, 'APPROVED')

      this.noDataFound = false
      this.hasSearchedVariable = false
      this.reloadAllRequest = false
      this.searchParameter = ''

      this.$router.push('/clearing-and-forwarding/export/accepted-application')
    },

    searchRequestByName (pageNo, pageSize, sortBy, status) {
      if (this.$v.searchParameter.$invalid) {

      } else {
        this.myLoader = true
        this.reloadAllRequest = true
        this.hasSearchedVariable = true
        this.noDataFound = false

        API.searchRequestActiveTab(pageNo, pageSize, sortBy, this.searchParameter, this.requestFlag, status).then(
          response => {
            if (response.data.status) {
              this.myLoader = false

              if (response.data.data.length > 0) {
                this.documents = response.data.data
                this.pageCount = response.data.message
                this.myLoader = false
              } else {
                this.noDataFound = true
                this.pageCount = response.data.message
                this.myLoader = false
              }
            } else {
              this.noDataFound = true
              this.pageCount = response.data.message
              this.myLoader = false
            }
          }
        ).catch(() => {
          this.myLoader = false
        })
      }
    },

    loadPageableRequestOnCreated (pageNo, pageSize, status) {
      return new Promise((resolve, reject) => {
        this.myFirstLoader = true

        API.getPageableRequest(this.requestFlag, pageNo, pageSize, status).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                this.documents = response.data.data
                this.pageCount = response.data.message
                this.myFirstLoader = false
              } else {
                this.noDataFound = true
                this.pageCount = response.data.message
                this.myFirstLoader = false
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          this.ErrorMessage = ', Requests Not loaded, please contact system administrator'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myFirstLoader = false

          reject(e)
        })
      })
    },

    loadPageableRequest (pageNo, pageSize, status) {
      return new Promise((resolve, reject) => {
        this.myLoader = true

        API.getPageableRequest(this.requestFlag, pageNo, pageSize, status).then(
          response => {
            if (response.data.status) {
              if (response.data.data.length > 0) {
                this.documents = response.data.data
                this.pageCount = response.data.message
                this.myLoader = false
              } else {
                this.noDataFound = true
                this.pageCount = response.data.message
                this.myLoader = false
              }
            } else {

            }

            resolve()
          }
        ).catch(e => {
          this.ErrorMessage = ', Requests Not loaded, please contact system administrator'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myLoader = false

          reject(e)
        })
      })
    }

  }
}
</script>

<style scoped>
    .btn-space {
        margin-right: 10px !important;
    }

    .btn-success-custom {
        padding: 0 4px 0 4px;
        color: #3cb371;
    }

    .btn-danger-custom {
        padding: 0 4px 0 4px;
        color: #e97451;
    }

    .btn-success-custom:hover {
        cursor: pointer;
        color: green;
    }

    .btn-danger-custom:hover {
        cursor: pointer;
        color: red;
    }

    .table-striped tr {
        line-height: 20px;
    }

    .disabled-click {
        pointer-events:none;
        color:#dcdcdc;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    .enabled-click {
        pointer-events:auto;
    }

    .search .form-group .input-group .input-group-addon {
        width: 40px;
        text-align: center;
        padding-top: 3.5px;
        background-color: rgba(220, 220, 220, .3);
    }

    .disable-highlight:hover span {
        cursor: pointer;
    }

    .refresh-all {
        margin-top: 6px;
        margin-right: 20px
    }

    .refresh-all:hover {
        cursor: pointer;
    }

    .search .form-group .input-group #inputGroup {
        width: 250px;
        padding-top: 3.5px;
    }

    .search .form-group .input-group .input-group-addon:hover {
        cursor: pointer;
        background-color: rgba(220, 220, 220, .5);
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: 90px;
        background-color: #FFFFFF !important;
    }

    .table td , .table th {
        text-align: center;
    }

    .text-center {
        text-align: center !important;
    }

    .table-hover tbody tr:hover {
        background-color: rgba(153, 186, 221, .5) !important;
    }

    .more-customer-details:hover {
        cursor: pointer;
        color: #d59a18;
    }

    .bg-grey {
        background-color: #dcdcdc;
    }

    .close-info {
        color: blue !important;
    }

    .close-info:hover {
        cursor: pointer;
        color: green !important;
    }
</style>
