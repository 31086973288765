<template>
  <div class="content">
    <card>
      <div class="row">
        <div
          @click="toggle"
          class="col-md-12 toggle-code-key"
        >
          <div class="row">
            <div class="col-md-6">
              User icons color keys
            </div>
            <div class="col-md-6 text-right">
              <font-awesome-icon
                icon="angle-down"
                v-show="!showSection"
              />
              <font-awesome-icon
                icon="angle-up"
                v-show="showSection"
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-12"
          v-show="showSection"
        >
          <hr>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 30px"
              v-show="showSection"
            >
              <b>icons keys</b>
            </div>
            <div class="col-md-12">
              <div
                class="col-md-12"
                v-show="showSection"
              >
                <div class="row">
                  <div class="col-md-2">
                    <span>
                      <font-awesome-icon icon="user-plus" /><i class=" text-muted"> Add User</i>
                    </span>
                  </div>
                  <div class="col-md-2">
                    <span>
                      <font-awesome-icon
                        icon="user-edit"
                        class="edit-fa-icon"
                      /><i class=" text-muted"> Edit User</i>
                    </span>
                  </div>
                  <div class="col-md-2">
                    <span>
                      <font-awesome-icon
                        icon="eye"
                        class="view-fa-icon"
                      /><i class=" text-muted"> View User</i>
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span>
                      <font-awesome-icon icon="user-shield" /><i class=" text-muted"> Assign / Remove Role</i>
                    </span>
                  </div>
                  <div class="col-md-3">
                    <span>
                      <font-awesome-icon
                        icon="trash-alt"
                        class="delete-fa-icon"
                      /><i class=" text-muted"> Delete User</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->

      <br>

      <div class="text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="myloader"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            User List
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            class="all-fa-icons btn-space"
            title="add new request"
            @click.prevent="openModal(user , actionButton = 'AddModal')"
            v-if="finalUserPermissions.includes('USERS_ADD_POST')"
          >
            <font-awesome-icon icon="user-plus" />
          </span>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>
            <div class="col-md-2 float-left">
              <select
                name="PageSize"
                @change="onChange($event)"
                class=""
                v-model="pageSize"
                style="width:73px;"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-7 text-right"
              v-if="finalUserPermissions.includes('USERS_ALLUSERS_SEARCH_GET')"
            >
              <form
                class="search"
                @submit.prevent="searchRequestByName(pageNo , pageSize , sortBy)"
              >
                <div class="form-group float-right">
                  <div class="input-group">
                    <div
                      class="refresh-all"
                      title="load all requests"
                      v-if="reloadAllRequest"
                      @click.prevent="reloadByIcon"
                    >
                      <font-awesome-icon icon="sync-alt" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search ...."
                      id="inputGroup"
                      v-model="searchParamenter"
                      @input="loadAllPageble"
                    >
                    <span class="input-group-addon">
                      <font-awesome-icon
                        icon="search"
                        @click="searchRequestByName(pageNo , pageSize , sortBy)"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div
                class="col-md-12 disable-highlight"
                unselectable="on"
                style="font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left" />
                </span>
                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right" />&nbsp;&nbsp;
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <!--   END TOP PAGENATION AND SEARCH ELEMENTS   -->

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              User Name
            </th>
            <th scope="col">
              Name
            </th>
            <th scope="col">
              E-mail
            </th>
            <th scope="col">
              Status
            </th>
            <th
              scope="col"
              class="special-limit"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in getUsers"
            :key="list.id"
            v-if="!noDataFound"
          >
            <th scope="row">
              {{ index + 1 +(pageNo * pageSize) }}
            </th>
            <td>
              {{ list.username }}
            </td>
            <td>
              {{ list.firstName }} {{ list.middleName }} {{ list.lastName }}
            </td>
            <td>
              {{ list.email }}
            </td>
            <td :class="[ list.enabled ? 'text-success' : 'text-danger' ]">
              {{ list.enabled ? "Active" : "Not Active" }}
            </td>

            <td class="special-limit">
              <div
                class=" all-fa-icons edit-fa-icon"
                @click.prevent="openModal(list, actionButton =
                  'EditModal')"
                title="edit"
                v-if="finalUserPermissions.includes('USERS_{ID}_PUT')"
              >
                <font-awesome-icon icon="user-edit" />
              </div>
              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                @click.prevent="openModal(list ,
                                          actionButton = 'ShowModal')"
                title="view"
                v-if="finalUserPermissions.includes('USERS_{ID}_GET')"
              >
                <font-awesome-icon icon="eye" />
              </div>
              <div
                class="all-fa-icons fa-icons-space"
                @click.prevent="openModal(list , actionButton = 'RoleModal')"
                title="add / remove role"
                v-if="finalUserPermissions.includes('USERS_ROLES_{ID}_POST')"
              >
                <font-awesome-icon icon="user-shield" />
              </div>
              <div
                class="all-fa-icons fa-icons-space delete-fa-icon"
                @click.prevent="deleteUser(list)"
                title="delete"
                v-if="finalUserPermissions.includes('USERS_{ID}_DELETE')"
              >
                <font-awesome-icon icon="trash-alt" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="col-md-12 text-center bg-grey"
        v-if="noDataFound"
      >
        No data found
      </div>

      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
          </div>

          <div
            class="col-md-9 text-right disable-highlight"
            unselectable="on"
            style="line-height: 5px;font-size: 20px"
          >
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              title="go to first page"
            >First
            </span>
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="prevPage"
              title="go to previous page"
            >
              <font-awesome-icon icon="angle-double-left" />
            </span>

            <span class="btn-space" />
            <span class="btn-space" />

            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="nextPage"
              title="go to next page"
            >
              <font-awesome-icon icon="angle-double-right" />
            </span>
            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="lastPage"
              title="go to last page"
            >Last
            </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit ser: <b>{{ username }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            Register new user
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                Details for user: <b>{{ data.username }}</b>
              </div>
              <div class="col-md-6 text-right loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="permissionMyLoader"
                />
              </div>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="EditRoleModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                Change Roles for user: <b>{{ user.username }}</b>
              </div>
              <div class="col-md-6 text-right loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="permissionMyLoader"
                />
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editUser"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="email"
                  label="User Name"
                  placeholder="User Name"
                  v-model="username"
                  class="special-bottom"
                />
                <div v-if="usernameError">
                  <div
                    class="error"
                    v-if="!$v.username.required"
                  >
                    This field is required
                  </div>
                </div>
                <div
                  v-if="incorrectEmailError"
                  class="text-danger"
                >
                  incorrect email address
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  type="hidden"
                  v-model="email"
                  class="special-bottom"
                />
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="First Name"
                  placeholder="First Name"
                  v-model="firstName"
                  class="special-bottom"
                />
                <div v-if="firstNameError">
                  <div
                    class="error"
                    v-if="!$v.firstName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Middle Name"
                  placeholder="Middle Name"
                  v-model="middleName"
                  class="special-bottom"
                />
                <div v-if="middleNameError">
                  <div
                    class="error"
                    v-if="!$v.middleName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Last Name"
                  placeholder="Last Name"
                  v-model="lastName"
                  class="special-bottom"
                />
                <div v-if="lastNameError">
                  <div
                    class="error"
                    v-if="!$v.lastName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Check Number"
                  placeholder="Check Number"
                  v-model="checkno"
                  class="special-bottom"
                />
                <div v-if="checknoError">
                  <div
                    class="error"
                    v-if="!$v.checkno.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label>Select Department</label>
                <select
                  class="form-control special-bottom"
                  v-model="department"
                >
                  <option
                    disabled
                    value=""
                  >
                    Select Department
                  </option>
                  <option
                    v-for="department in getDepartment"
                    :value="department.departmentId"
                    :key="department.departmentId"
                    :selected="department === department.departmentId"
                  >
                    {{ department.departmentName }} - {{ department.departmentCode }}
                  </option>
                </select>
                <div v-if="departmentError">
                  <div
                    class="error"
                    v-if="!$v.department.required"
                  >
                    Select department
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label>Select Designation</label>
                <select
                  class="form-control special-bottom"
                  v-model="designation"
                >
                  <option
                    disabled
                    value=""
                  >
                    Select Designation
                  </option>
                  <option
                    v-for="designation in getDesignation"
                    :value="designation.designationId"
                    :key="designation.designationId"
                    :selected="designation === designation.designationId"
                  >
                    {{ designation.designationTitle }} - {{ designation.designationAbbr }}
                  </option>
                </select>

                <div v-if="designationError">
                  <div
                    class="error"
                    v-if="!$v.designation.required"
                  >
                    Select designation
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-12">
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="enabled"
                    value="false"
                    name="enabled"
                    v-model="enabled"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="enabled"
                  >Not Active</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="enabled2"
                    value="true"
                    name="enabled"
                    v-model="enabled"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="enabled2"
                  >Active</label>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--     EDIT ROLE FORM       -->
          <form
            method="POST"
            @submit.prevent="addSelectedRole(username)"
            v-if="EditRoleModalDiv"
          >
            <div class="col-md-12">
              <div class="row">
                <div
                  class="col-md-4"
                  v-for="(role , index) in getRoles"
                  :key="index"
                >
                  <div class="custom-control">
                    <input
                      type="checkbox"
                      :value="role.id"
                      :id="role.name"
                      :checked="checkRoles.includes(role.id)"
                      @input="changeRoleOnClick(role.id , role.name , $event.target.checked , $event)"
                    >&nbsp;
                    <label :for="role.name">{{ role.name }}</label>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mb-1">
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditRoleModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveUser"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="email"
                  label="User Name (email address)"
                  placeholder="User Name (email address)"
                  v-model="user.username"
                  class="special-bottom"
                />
                <div v-if="usernameError">
                  <div
                    class="error"
                    v-if="!$v.user.username.required"
                  >
                    This field is required
                  </div>
                </div>
                <div
                  v-if="incorrectEmailError"
                  class="text-danger"
                >
                  incorrect email address
                </div>
              </div>
              <div class="col-md-6" />
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="First Name"
                  placeholder="First Name"
                  v-model="user.firstName"
                  class="special-bottom"
                />
                <div v-if="firstNameError">
                  <div
                    class="error"
                    v-if="!$v.user.firstName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Middle Name"
                  placeholder="Middle Name"
                  v-model="user.middleName"
                  class="special-bottom"
                />
                <div v-if="middleNameError">
                  <div
                    class="error"
                    v-if="!$v.user.middleName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Last Name"
                  placeholder="Last Name"
                  v-model="user.lastName"
                  class="special-bottom"
                />
                <div v-if="lastNameError">
                  <div
                    class="error"
                    v-if="!$v.user.lastName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Check Number"
                  placeholder="Check Number"
                  v-model="user.checkno"
                  class="special-bottom"
                />
                <div v-if="checknoError">
                  <div
                    class="error"
                    v-if="!$v.user.checkno.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label>Select Department</label>
                <select
                  id="myselect3"
                  class="form-control special-bottom"
                  v-model="user.department"
                >
                  <option
                    disabled
                    value=""
                  >
                    Select Department
                  </option>
                  <option
                    v-for="department in getDepartment"
                    :value="department.departmentId"
                    :key="department.departmentId"
                  >
                    {{ department.departmentName }} - {{ department.departmentCode }}
                  </option>
                </select>
                <div v-if="departmentError">
                  <div
                    class="error"
                    v-if="!$v.user.department.required"
                  >
                    Select department
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label>Select Designation</label>
                <select
                  id="myselect4"
                  class="form-control special-bottom"
                  v-model="user.designation"
                >
                  <option
                    disabled
                    value=""
                  >
                    Select Designation
                  </option>
                  <option
                    v-for="designation in getDesignation"
                    :value="designation.designationId"
                    :key="designation.designationId"
                  >
                    {{ designation.designationTitle }} - {{ designation.designationAbbr }}
                  </option>
                </select>

                <div v-if="designationError">
                  <div
                    class="error"
                    v-if="!$v.user.designation.required"
                  >
                    Select designation
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-12 legend">
                <div class="row strip-row">
                  <div class="col-md-6 text-bold">
                    Username
                  </div>
                  <div class="col-md-6 text-value">
                    <b>{{ data.username }}</b>
                  </div>
                </div>
                <div class="row strip-row">
                  <div class="col-md-6 text-bold">
                    Email
                  </div>
                  <div class="col-md-6 text-value">
                    <b>{{ data.email }}</b>
                  </div>
                </div>
                <div class="row strip-row">
                  <div class="col-md-6 text-bold">
                    Full Names
                  </div>
                  <div class="col-md-6 text-value">
                    <b>{{ data.firstName }} {{ data.middleName }} {{ data.lastName }}</b>
                  </div>
                </div>
                <div class="row strip-row">
                  <div class="col-md-6 text-bold">
                    Status
                  </div>
                  <div class="col-md-6 text-value">
                    <b>{{ data.enabled ? "Active" : "Not Active" }}</b>
                  </div>
                </div>
                <div class="row strip-row">
                  <div class="col-md-12 text-bold">
                    Role(s)
                  </div>
                  <div class="col-md-12 text-value">
                    <div class="row">
                      <div
                        class="col-md-4"
                        v-for="(role , index) in checkNamesAndIds"
                        :key="index"
                      >
                        <div class="custom-control">
                          <span>{{ index + 1 }})</span>&nbsp;
                          <label :for="role.name">{{ role }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-12">
                <h3>Change Password</h3>
                <div class="form-group">
                  <label for="password">New Password</label>
                  <input type="password" class="form-control" id="password" placeholder="New Password" v-model="newPassword">
                  <span class="text-danger" v-if="newPasswordError">Please input new Password</span>
                </div>
                <pulse-loader
                  :loading="true"
                  :color="color"
                  :size="size"
                  v-if="changePasswordLoader"
                />
                <button type="submit" class="btn btn-primary float-right" @click.prevent="changePassword">Submit</button>
              </div>
            </div>
            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>
<script>

import API from '../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../components/Inputs/Modal'
import { required, email } from 'vuelidate/lib/validators'
import { ModelListSelect } from 'vue-search-select'
import { mapGetters } from 'vuex'

export default {

  name: 'UserList',

  components: {

    PulseLoader,
    Modal,
    ModelListSelect

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'System Users List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)
    this.loadDepartment()
    this.loadDesignation()
  },

  data () {
    return {

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      docPageNo: 0,
      docPageCount: 0,
      sortBy: 'id',
      docSortBy: 'id',
      docPageSize: 0,
      getPageableDoc: 0,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      changePasswordLoader:false,
      newPassword:"",
      newPasswordError:false,
      array: [],
      getUsers: [],
      getRoles: [],
      getUserRoles: [],
      getDesignation: [],
      getDepartment: [],
      checkRoles: [],
      roleIds: [],
      roleNames: [],
      addedRole: [],
      removedRole: [],
      checkNamesAndIds: [],
      finalUserPermissions: [],
      permissionGroupsOnLoad: [],
      userDetails: {},

      data: {},

      color: 'green',
      size: '15px',
      loading: true,

      SuccessMessage: '',
      selectedRoles: [],
      ErrorMessage: '',
      username: '',
      firstName: '',
      enabled: '',
      middleName: '',
      lastName: '',
      email: '',
      checkno: '',
      id: '',
      error: '',
      departmentId: '',
      designationId: '',
      myDepartment: '',
      myDesignation: '',
      department: '',
      designation: '',

      user: {

        username: '',
        firstName: '',
        middleName: '',
        lastName: '',
        enabled: false,
        email: '',
        checkno: '',
        departmentId: '',
        designationId: ''

      },
      searchParamenter: '',
      SuccessAlert: false,
      permissionMyLoader: false,
      ErrorAlert: false,
      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,
      DeleteModalDiv: false,
      RoleModal: false,
      EditRoleModalDiv: false,
      showSection: true,
      usernameError: false,
      firstNameError: false,
      middleNameError: false,
      departmentError: false,
      designationError: false,
      lastNameError: false,
      incorrectEmailError: false,
      emailError: false,
      checknoError: false,
      noDataFound: false

    }
  },

  validations: {

    user: {

      username: { required },
      firstName: { required },
      middleName: { required },
      lastName: { required },
      checkno: { required },
      department: { required },
      designation: { required }

    },

    searchParameter: { required },

    username: { required },
    firstName: { required },
    enabled: { required },
    middleName: { required },
    lastName: { required },
    email: { required, email },
    checkno: { required },
    department: { required },
    designation: { required }

  },

  computed: {

    ...mapGetters('users', [

      'getValuesBeforeEdit',
      'getNameBeforeDelete'

    ])

  },

  methods: {


    changePassword(){


      if(this.newPassword!==""){
        this.newPasswordError=false;
        this.changePasswordLoader=true;

        API.updateUserPassword({
          id: this.id,
          newPassword: this.newPassword
        }).then((resp)=>{

          this.loadNotification('success', 4000, 'Password Changed',
            'response', 1000, true, true);
          this.newPassword="";

        }).catch((err)=>{

          this.loadNotification('error', 4000, 'Failed To change Password',
            'response', 1000, true, true);
        });


      }else {
        this.newPasswordError=true;
      }

    },

    nextPage () {
      this.pageNo++
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)
      }
    },

    prevPage () {
      this.pageNo--
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)
      }
    },

    firstPage () {
      this.pageNo = 0
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)
      }
    },

    onChange (event) {
      this.getUsers = []
      this.searchParamenter = ''
      this.pageSize = event.target.value
      this.pageNo = 0

      this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)
      }
    },

    loadAllPageble () {
      if (this.searchParameter === '' && this.hasSearchedVariable === true) {
        this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
      }
    },

    reloadByIcon () {
      this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)

      this.noDataFound = false
      this.hasSearchedVariable = false
      this.reloadAllRequest = false
      this.searchParamenter = ''
    },

    searchRequestByName (pageNo, pageSize, sortBy) {
      if (this.$v.searchParameter.$invalid) {

      } else {
        this.myloader = true
        this.reloadAllRequest = true
        this.hasSearchedVariable = true

        API.searchPagebleUser(this.searchParameter, pageNo, pageSize, sortBy).then(
          response => {
            if (response.data.status) {
              this.myloader = false

              if (response.data.data.length > 0) {
                this.noDataFound = false
                this.getUsers = response.data.data
                this.pageCount = response.data.message
                this.docPageCount = this.pageCount
                this.docPageNo = this.pageNo
                this.docPageSize = this.pageSize
                this.myloader = false
              } else {
                this.noDataFound = true
                this.pageCount = response.data.message
                this.docPageCount = this.pageCount
                this.docPageNo = this.pageNo
                this.docPageSize = this.pageSize
                this.myloader = false
              }
            } else {
              this.noDataFound = true
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.getPageableDoc = response.data.data
              this.myloader = false
            }
          }
        ).catch(e => {
          this.myloader = false
        })
      }
    },

    loadPageableUser (pageNo, pageSize, sortBy) {
      return new Promise((resolve, reject) => {
        this.myloader = true

        API.getPagebleUsers(pageNo, pageSize, sortBy).then(
          response => {
            if (response.data.status) {
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.getUsers = response.data.data
              this.myloader = false
            } else {

            }

            resolve()
          }
        ).catch(e => {
          this.ErrorMessage = ' Requests Not loaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false

          reject(e)
        })
      })
    },

    toggle () {
      this.showSection = !this.showSection
    },

    loadDepartment () {
      this.myloader = true

      API.getDepartment().then(response => {
        if (response.data.status) {
          this.getDepartment = response.data.data
          this.myloader = false
        } else {

        }
      })
        .catch(() => {
          this.ErrorMessage = ' Record not loaded'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false
        })
    },

    loadDesignation () {
      this.myloader = true

      API.getDesignation().then(
        response => {
          if (response.data.status) {
            this.getDesignation = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(() => {
        this.ErrorMessage = ' Record not added'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadUsers () {
      this.myloader = true

      API.getUsers().then(
        response => {
          if (response.data.status) {
            this.getUsers = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not Added'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    addSelectedRole (name) {
      this.editMyLoader = true

      const roles = []

      this.roleIds.forEach(element => {
        roles.push({ id: element })
      })

      const rolesOneTime = {

        roles,

        id: this.user.id

      }

      API.postUserRoles(this.user.id, JSON.stringify(rolesOneTime)).then(
        response => {
          if (response.data.status) {
            this.SuccessMessage = `${this.updatedRoleAndPermissionStatus(this.addedRole, this.removedRole,
                this.roleNames, name, 'role')}`
            this.SuccessAlert = true
            this.ErrorMessage = ''
            this.ErrorAlert = false
            this.closeModal()
            this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)
            this.editMyLoader = false
            this.roleIds = []
          } else {
            this.editMyLoader = false
            this.ErrorMessage = ' Role not Added'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.roleIds = []
            this.closeModal()
          }
        }
      ).catch(e => {

        // this.state.errors.push(e)

      })
    },

    loadSingleUser (data) {
      API.getUser(data).then(
        response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'User were not Found'
          }
        }
      ).catch(e => {

        // this.state.errors.push(e)

      })
    },

    loadRoles () {
      API.getAllRoles().then(
        response => {
          if (response.data.status) {
            this.getRoles = response.data.data
          } else {
            this.data = 'User were not Found'
          }
        }
      ).catch(e => {

        // this.state.errors.push(e)

      })
    },

    loadUserRoles (userId) {
      this.permissionMyLoader = true

      API.getUserRoles(userId).then(
        response => {
          if (response.data.status) {
            this.checkRoles = []
            this.addedRole = []
            this.roleNames = []
            this.removedRole = []
            this.checkNamesAndIds = []

            this.getUserRoles = response.data.data

            for (let i = 0; i < this.getUserRoles.length; i++) {
              this.checkRoles.push(this.getUserRoles[i].id)
              this.roleNames.push(this.getUserRoles[i].name)
              this.addedRole.push(this.getUserRoles[i].name)
              this.removedRole.push(this.getUserRoles[i].name)
              this.checkNamesAndIds.push(this.getUserRoles[i].name)

              this.checkRoles = Array.from(new Set(this.checkRoles))
              this.roleNames = Array.from(new Set(this.roleNames))
              this.addedRole = Array.from(new Set(this.addedRole))
              this.removedRole = Array.from(new Set(this.removedRole))
              this.checkNamesAndIds = Array.from(new Set(this.checkNamesAndIds))
            }

            this.permissionMyLoader = false
          } else {
            this.data = 'User were not Found'
          }
        }
      ).catch(e => {

        // this.state.errors.push(e)

      })
    },

    changeRoleOnClick (id, name, check, event) {
      if (this.checkRoles.includes(id) && check === true) { // no changes
        if (this.checkRoles.length > 0) {
          this.roleIds = this.checkRoles
        }
      }

      if (!this.checkRoles.includes(id) && check === true) { // additional
        if (this.checkRoles.length > 0) {
          this.roleIds = this.checkRoles
        }

        this.roleIds.push(parseInt(event.target.value))

        this.addedRole.push(name)
      }

      if (this.checkRoles.includes(id) && check === false) { // removal
        this.checkRoles.splice(this.checkRoles.indexOf(id), 1)

        this.removedRole.splice(this.removedRole.indexOf(name), 1)

        if (this.checkRoles.length > 0) {
          this.roleIds = this.checkRoles
        } else {
          this.roleIds = []

          this.removedRole = []
        }
      }
    },

    saveUser () {
      if (this.$v.user.username.$invalid || this.$v.user.firstName.$invalid || this.$v.user.middleName.$invalid ||
          this.$v.user.lastName.$invalid || this.$v.user.checkno.$invalid || this.$v.user.department.$invalid ||
          this.$v.user.designation.$invalid) {
        if (this.$v.user.username.$invalid) {
          this.usernameError = true
        }
        if (this.$v.user.firstName.$invalid) {
          this.firstNameError = true
        }
        if (this.$v.user.middleName.$invalid) {
          this.middleNameError = true
        }
        if (this.$v.user.lastName.$invalid) {
          this.lastNameError = true
        }
        if (this.$v.user.checkno.$invalid) {
          this.checknoError = true
        }
        if (this.$v.user.department.$invalid) {
          this.departmentError = true
        }
        if (this.$v.user.designation.$invalid) {
          this.designationError = true
        }
      } else {
        const atPos = this.user.username.indexOf('@')
        const dotPos = this.user.username.lastIndexOf('.')

        if (this.user.username.length <= 0) {
          this.incorrectEmailError = true
          this.user.username = ''
        } else if (atPos < 1 || dotPos < atPos + 2 || dotPos + 2 >= this.user.username.length) {
          this.incorrectEmailError = true
          this.user.username = ''
        } else {
          this.editMyLoader = true

          const user = {

            username: this.user.username,
            firstName: this.user.firstName,
            middleName: this.user.middleName,
            lastName: this.user.lastName,
            enabled: false,
            email: this.user.username,
            checkno: this.user.checkno,
            departmentId: this.user.department,
            designationId: this.user.designation

          }

          // setTimeout(() => {

          API.postUser(JSON.stringify(user)).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ', User with username [ ' + response.data.data.username + ' ] Saved'
                this.SuccessAlert = true
                this.ErrorMessage = ''
                this.ErrorAlert = false
                this.closeModal()
                this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)
                this.editMyLoader = false
                this.user = {}
              } else {

              }
            }
          ).catch(e => {
            this.editMyLoader = false
            this.ErrorMessage = ' User not Added'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.editMyLoader = false
            this.closeModal()
          })

          // }, 2000);
        }
      }
    },

    deleteUser (data) {
      this.$store.dispatch('users/nameBeforeDelete', data.username)

      this.$dialog.confirm('If you delete user with username [ ' + data.username + " ], it'll be gone forever.", {

        loader: true
      })
        .then((dialog) => {
          // setTimeout(() => {

          API.deleteUser(data.id).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ', User with username [ ' + this.getNameBeforeDelete + ' ] has been deleted'
                this.$store.dispatch('permissionswithroles/nameBeforeDelete', '')
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                if (this.hasSearchedVariable) {
                  this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
                } else {
                  this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)
                }
              } else {

              }
            }
          ).catch(e => {
            this.ErrorMessage = ' Record not Deleted'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
          })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    editUser () {
      if (this.$v.username.$invalid || this.$v.firstName.$invalid || this.$v.middleName.$invalid ||
          this.$v.lastName.$invalid || this.$v.checkno.$invalid || this.$v.department.$invalid ||
          this.$v.designation.$invalid) {
        if (this.$v.username.$invalid) {
          this.usernameError = true
        }
        if (this.$v.firstName.$invalid) {
          this.firstNameError = true
        }
        if (this.$v.middleName.$invalid) {
          this.middleNameError = true
        }
        if (this.$v.lastName.$invalid) {
          this.lastNameError = true
        }
        if (this.$v.checkno.$invalid) {
          this.checknoError = true
        }
        if (this.$v.department.$invalid) {
          this.departmentError = true
        }
        if (this.$v.designation.$invalid) {
          this.designationError = true
        }
      } else {
        const atPos = this.username.indexOf('@')
        const dotPos = this.username.lastIndexOf('.')

        if (this.username.length <= 0) {
          this.incorrectEmailError = true
          this.username = ''
        } else if (atPos < 1 || dotPos < atPos + 2 || dotPos + 2 >= this.username.length) {
          this.incorrectEmailError = true
          this.username = ''
        } else {
          this.editMyLoader = true

          this.array =

            {
              firstName: this.firstName,
              username: this.username,
              checkno: this.checkno,
              lastName: this.lastName,
              email: this.username,
              id: this.id,
              enabled: this.enabled,
              middleName: this.middleName,
              departmentId: this.department,
              designationId: this.designation

            }

          console.log(JSON.stringify(this.array))

          // setTimeout(() => {

          API.updateUser(this.id, JSON.stringify(this.array)).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = `${this.updatedValueStatus(this.getValuesBeforeEdit, [response.data.data.username,
                    response.data.data.enabled, response.data.data.firstName, response.data.data.middleName,
                    response.data.data.lastName, response.data.data.checkno, response.data.data.email],
                  'User', response.data.data.username)}`
                this.SuccessAlert = true
                this.ErrorMessage = ''
                this.ErrorAlert = false
                window.scrollTo(10, 0)
                this.closeModal()
                this.editMyLoader = false
                if (this.hasSearchedVariable) {
                  this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
                } else {
                  this.loadPageableUser(this.pageNo, this.pageSize, this.sortBy)
                }
              } else {

              }
            }
          ).catch(e => {
            this.errors = e
            this.editMyLoader = false
            this.ErrorMessage = ' Record not Edited'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.editMyLoader = false
            this.closeModal()
            this.loadUsers()
          })

          // }, 2000);
        }
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (userData, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.AddModalDiv = false
        this.ShowModalDiv = false
        this.username = userData.username
        this.enabled = userData.enabled
        this.checkno = userData.checkno
        this.middleName = userData.middleName
        this.lastName = userData.lastName
        this.email = userData.email
        this.id = userData.id
        this.firstName = userData.firstName
        this.department = userData.departmentId
        this.designation = userData.designationId

        this.$store.dispatch('users/valuesBeforeEdit', [this.username, this.enabled, this.firstName,
          this.middleName, this.lastName, this.checkno, this.email])
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.RoleModalDiv = false
        this.showModal = true
      } else if (actionButton === 'RoleModal') {
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = false
        this.showModal = true
        this.RoleModal = true
        this.EditRoleModalDiv = true
        this.username = userData.username
        this.id = userData.id

        this.loadRoles()
        this.loadUserRoles(userData.id)
        this.user = userData
      } else {
        this.id = userData.id
        this.loadSingleUser(this.id)
        this.loadUserRoles(this.id)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.showModal = false
      this.RoleModal = false
      this.EditRoleModalDiv = false

      this.usernameError = false
      this.firstNameError = false
      this.middleNameError = false
      this.lastNameError = false
      this.emailError = false
      this.checknoError = false
      this.incorrectEmailError = false

      this.user = {}
    }

  }

}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

  .special-limitu {
    max-width: 125px !important;
    min-width: 125px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .legend .strip-row:nth-of-type(odd) div {
    background-color: #FFFFFF;
  }

  .legend .strip-row:nth-of-type(even) div {
    background: rgba(0, 0, 0, .05);
  }
</style>
