<template>
    <div>
        <card>
            <div class="row">
                <div class="col-md-12 text-center">
                    <button class="btn btn-primary btn-space custom-btn" @click.prevent="loadQueReports">Refresh Reports</button>
                </div>
            </div>
            <hr />
            <data-table
                    :table-headers="tableHeaders"
                    :table-data="filteredRequests"
                    :table-data-keys="tableDataKeys"
                    :my-first-loader="myFirstLoader"
                    :page-size-prop="pageSize"
                    :page-no="pageNo"
                    :total-elements="totalElements"
                    :total-pages="totalPages"
                    :is-first="isFirst"
                    :is-last="isLast"
                    :loadingTitle="'Please wait while system is loading reports'"
                    :permissions="[{ key: 'searchPermission', value: 'REPORTS_ALL_GET' }]"
                    :buttonList="[ {'code' : 'DownloadReport' , 'name' : 'Download Report' , 'class' : 'btn-primary'} ]"
                    :no-data-found="noDataFound"
                    :page-number="pageNumber"
                    :has-manage-button="false"
                    :has-refresh-button="true"
                    :has-filter="false"
                    :loading-title="currentLoadingTitle"
                    @goToPage="goToPage"
                    @onChange="onChange"
                    @goToDownloadReport="downloadReport">
                    <div slot = "pagination">
                    <paginate
                        v-model="getIsPage"
                        :page-count=parseInt(totalPages)
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="goToPage"
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :container-class="'pagination'"
                        :page-class="'page-item'">
                    </paginate>
                    </div>
            </data-table>
        </card>
        <global-modal v-if="globalLoader">
            <div slot="globalLoader">
                <div class="row text-center">
                    <div class="col-md-12">Loading, Please wait</div>
                    <div class="col-md-12">
                        <br/>
                    </div>
                    <div class="col-md-12">
                        <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
                    </div>
                </div>
            </div>
        </global-modal>
    </div>
</template>

<script>

import PulseLoader from "vue-spinner/src/PulseLoader.vue"
import GlobalModal from "../../../components/globalLoader"
import { mapActions, mapGetters } from 'vuex';

export default {

    name: "BillReportList",

    components: {

        PulseLoader,
        GlobalModal,
        DataTable: () => import ('../../../components/DataTable/dataTable.vue')
    },

    async created() {

        this.loadQueReports();
        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

    },

    computed: {

        ...mapGetters('global', [

            'getGlobalLoader'

        ]),

        ...mapGetters("report", [

            "getRequestList",
            "getPageCount",
            "getNoDataFound",
            "getMyFirstLoader",
            "getPageNo",
            "getPageSize",
            "getTotalElement",
            "getTotalPage",
            "getPageNumber",
            "getIsFirst",
            "getIsLast",

        ]),

        getIsPage: {

            get: function () {
                return this.isPage
            },
            set: function (newValue) {
                this.isPage = newValue;
            }

        }

    },

    data() {

        return {

            pageNo: 0,
            pageSize: 15,
            totalElements: "",
            totalPages: "",
            isFirst: false,
            isLast: false,
            pageNumber: '',
            noDataFound:false,

            color: "green",
            size: "12px",
            loading: true,
            myLoader: false,
            myFirstLoader: false,
            globalLoader: false,
            reports: [],
            filteredRequests: [],
            currentLoadingTitle: '',

            isPage: 0,
            pageNoPart: 0,

            finalUserPermissions: [],
            tableHeaders: ['Report Type','Generated At', 'Start Date', 'End Date', 'Duration in Days'],
            tableDataKeys: ['type', 'createdDate', 'startDate' , 'endDate', 'duration'],

        }

    },

    methods: {

    ...mapActions('global', [

        'loadFileFromMinio'

    ]),

    ...mapActions("report", [

        'loadPageableRequestOnCreated',

    ]),

    loadParameters (fileUrl, fileName) {

    return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
    })

    },

    getFileFromMinio (fileUrl, fileName) {

        this.loadParameters(fileUrl, fileName).then(() => {

        this.loadFileFromMinio({}).then((res) => {

            if(res.status === 204) {

                this.loadNotification('warn', 4000, 'File Download',
                'File not found, please contact system admin',
                'response', 1000 , true  , true)

            } else {

                this.loadNotification('success', 4000, 'File Download',
                'File has been successfully downloaded, please have a look at it',
                'response', 1000 , true  , true)


            }

                this.globalLoader = this.getGlobalLoader

            }).catch(() => {

            this.loadNotification('error', 4000, 'File Download',
                'File not downloaded, please check your internet connection',
                'response', 1000 , true  , true)

            this.globalLoader = this.getGlobalLoader

            })

            this.globalLoader = this.getGlobalLoader

        }).catch(() => {

        })

    },

    loadParametersForList(pageNo , pageSize) {

        return new Promise((resolve) => {

        this.$store.dispatch("report/pageSizing", pageSize);
        this.$store.dispatch("report/pageNumbering", pageNo);

        resolve();

        })

    },

    sortingData(data) {

    for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {

            ...data[x],
            duration: Number.isNaN(this.daysBetween(data[x].startDate , data[x].endDate , true)) ? '-' : this.daysBetween(data[x].startDate , data[x].endDate , true),
            createdDate: this.dateTimeToHuman(data[x].createdDate)

            }

            this.filteredRequests.push(requestObject)

        }

    },

    setAllGetters() {

        this.requests = this.getRequestList;
        this.myFirstLoader = this.getMyFirstLoader;
        this.noDataFound = this.getNoDataFound;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;

        this.totalElements = this.getTotalElement;
        this.totalPages = this.getTotalPage;
        this.pageNumber = this.getPageNumber;
        this.isFirst = this.getIsFirst;
        this.isLast = this.getIsLast;

        this.sortingData(this.requests)

    },

    getPageableRequestOnCreated(pageNo , pageSize) {

        this.loadParametersForList(pageNo , pageSize).then(() => {

        this.loadPageableRequestOnCreated({}).then(() => {

            this.setAllGetters()

        }).catch(() => {


        });

        this.myFirstLoader = this.getMyFirstLoader;

        }).catch(() => {


        });

    },

    loadQueReports () {

        this.getPageableRequestOnCreated(this.pageNo , this.pageSize)
        this.isPage = this.pageNo + 1
        this.pageNoPart = 0
        this.filteredRequests = []

    },

    goToPage(page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1

        this.pageNo = page;

        this.getPageableRequestOnCreated(this.pageNo , this.pageSize);
        this.filteredRequests = []

    },

    onChange(event) {

        this.$store.dispatch("report/pageSizing", event);
        this.$store.dispatch("report/pageNumbering", 0);
        this.isPage = 0

        this.pageSize = this.getPageSize;
        this.pageNo = this.getPageNo;

        this.getPageableRequestOnCreated(this.pageNo , this.pageSize);
        this.filteredRequests = []

    },

    downloadReport(data) {

        this.getFileFromMinio(data.attachmentUrl , this.spaceToUnderscoreString(data.description))

    }
}
}
</script>
<style scoped>
    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
    }
    ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        /*background-color: #ededee;*/
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
        border-top: 1px solid #DCDCDC;
        border-left: 1px solid #DCDCDC;
        border-right: 1px solid #DCDCDC;
        border-bottom: none !important;
        margin-right: 15px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 8px 15px 8px 15px !important;
        width: auto;
        text-decoration: none;
        color: #000000;
        font-weight: bold;
        text-transform: uppercase;
    }

    ul.nav-justified li :hover {
        color: #d59a18;
        padding: 16px 20px 10px 21px;
        background-color: #ffffff;
    }
</style>
