<template>
  <card>
    <div
      class="row"
      style="padding: 0 15px 0 15px"
    >
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successfully!</strong> <span
          v-if="SuccessAlert"
        >{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th scope="col">
                Name
              </th>
              <th scope="col">
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(property,index) in properties">
              <th scope="row">
                {{ index + 1 }}
              </th>
              <td>{{ property.name }}</td>
              <td>{{ property.description }}</td>
              <td
                class="pointer"
                @click="editFunc(property)"
              >
                <font-awesome-icon
                  icon="edit"
                  color="#fca130"
                />Edit
              </td>
              <td
                class="pointer"
                @click="deleteProperty(property)"
              >
                <font-awesome-icon
                  icon="trash-alt"
                  color="red"
                />Delete
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <vehicle-properties
      v-if="edit"
      :properties-to-edit="selectedProperty"
      :callback="getProperties"
    />
  </card>
</template>

<script>
import TALLYAPI from '../../../api/tally'
import VehicleProperties from './VehicleProperties'

export default {
  name: 'VehiclePropertiesView',
  components: { VehicleProperties },
  created () {
    this.getProperties()
  },
  data () {
    return {
      properties: [],

      loading: false,
      color: 'green',
      size: '15px',

      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: '',
      edit: false,
      selectedProperty: {}
    }
  },
  methods: {

    editFunc (property) {
      this.selectedProperty = property
      this.edit = true
    },

    getProperties () {
      this.loading = true
      this.edit = false
      TALLYAPI.getVehicleProperties().then((response) => {
        this.properties = response.data.data
        this.loading = false
      }).catch(() => {
        this.loading = false

        this.loading = false
        this.ErrorMessage = ', Vehicle Properties Not Loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
      })
    },

    deleteProperty (property) {
      this.loading = true
      TALLYAPI.deleteVehicleProperty(property.id).then((response) => {
        this.loading = false
        this.ErrorMessage = ''
        this.SuccessAlert = true
        this.ErrorAlert = false
        this.SuccessMessage = 'Property SuccessFully Deleted'
        this.SuccessAlert = true

        this.getProperties()
      }).catch(() => {
        this.ErrorMessage = ', Vehicle Properties Failed To Be Deleted'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
      })
    },
    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000)
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    }

  }
}
</script>

<style scoped>
    .pointer{
        cursor: pointer;
    }

</style>
