<template>
    <div>
        <card>
            <div class="nav-justified row">
                <div class="col-md-4">
                <a @click.prevent="loadShippingFeeProducts"
                    :class="[ isActive('Product') ? 'is-active-verified' : '' ]" href="#Product">Product</a>
                </div>
                <div class="col-md-4">
                <a @click.prevent="loadShippingFeeRoutes"
                    :class="[ isActive('Route') ? 'is-active-major' : '' ]" href="#Route">Route</a>
                </div>
                <div class="col-md-4">
                <a @click.prevent="switchTabs('Rate')"
                    :class="[ isActive('Rate') ? 'is-active-new' : '' ]"
                    href="#Rate1">Rate</a>
                </div>
            </div>

            <div class="tab-content py-2" id="myTabContent">
                <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('Product') }" id="Product">
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary mr-2 custom-btn" @click.prevent="showProductModal(null)">
                            <b>Add Product</b>
                            </button>
                        </div>
                        <div class="col-md-12">
                            <data-table
                                :tableHeaders="ProductTableHeaders"
                                :tableData="filteredProductsRequests"
                                :tableDataKeys="ProductTableDataKeys"
                                :myFirstLoader="myFirstLoader"
                                :noDataFound="noDataFound"
                                :hasPagination="false"
                                :hasFilter="false"
                                :hasEditButton="true"
                                :isPageable="false"
                                :loadingTitle="'Please wait while system is loading product list'"
                                :hasManageButton="false"
                                :permissions="[ {key: 'searchPermission', value: 'BILLS_SEARCH_BILLS_GET'} ]"
                                @goToEdit="showProductModal">
                            </data-table>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('Rate') }" id="Rate">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <!-- <button class="btn btn-primary mr-2 custom-btn" @click.prevent="showRateModal(null)"> -->
                            <b>Add new rate</b>
                            <!-- </button> -->
                            <hr />
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <base-input
                                        type="text"
                                        label="Rate Per"
                                        placeholder="Rate Per"
                                        v-model="ratePer"
                                        class="special-bottom"
                                    />
                                    <div v-if="ratePerError">
                                        <div class="text-danger" v-if="!$v.ratePer.required">
                                        This field is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <base-input
                                        type="text"
                                        label="Rate Value"
                                        placeholder="Example: 2.09"
                                        v-model="rateValue"
                                        @input="commafyThisAmountWithDecimal"
                                        class="special-bottom"
                                    />
                                    <div v-if="rateValueError">
                                        <div class="text-danger" v-if="!$v.rateValue.required">
                                        This field is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Product</label>
                                    <select class="form-control" v-model="shipFeeProductId">
                                        <option value="" selected>Select Product</option>
                                        <option v-for="(product, product_index) in products" :key="product_index" :value="product.shipFeeProductId">{{ product.name }}</option>
                                    </select>
                                    <div v-if="shipFeeProductIdError">
                                    <div class="text-danger" v-if="!$v.shipFeeProductId.required">
                                    This field is required
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Route</label>
                                    <select class="form-control" v-model="shipFeeRouteId">
                                        <option value="" selected>Select route</option>
                                        <option v-for="(route, route_index) in routes" :key="route_index" :value="route.shipFeeRouteId">{{ route.name }} ( {{ route.operationType }} )</option>
                                    </select>
                                    <div v-if="shipFeeRouteIdError">
                                    <div class="text-danger" v-if="!$v.shipFeeRouteId.required">
                                    This field is required
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <br />
                                </div>
                                <div class="col-md-7 text-right" style="padding-top:10px;">
                                    <span v-if="savingLoader">Please wait ....</span>
                                </div>
                                <div class="col-md-2 left loader-div-gif" style="padding-top:12px;">
                                    <pulse-loader
                                    :color="color"
                                    :size="size"
                                    v-if="savingLoader"
                                    />
                                </div>
                                <div class="col-md-3">
                                    <!-- <button type="button" class="btn btn-fill btn-outline-info float-right custom-btn-all ml-2" @click.prevent="closeModal" :disabled="savingLoader">
                                        Close
                                    </button> -->
                                    <button type="submit" class="btn btn-primary btn-space  float-right custom-btn" @click.prevent="saveRate" :disabled="savingLoader" v-if="!editable">
                                        Save
                                    </button>
                                    <button type="submit" class="btn btn-primary btn-space  float-right custom-btn" @click.prevent="editRate" :disabled="savingLoader" v-if="editable">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('Route') }" id="Route">
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary mr-2 custom-btn" @click.prevent="showRouteModal(null)">
                            <b>Add Route</b>
                            </button>
                        </div>
                        <div class="col-md-12">
                            <data-table
                                :tableHeaders="RouteTableHeaders"
                                :tableData="filteredRoutesRequests"
                                :tableDataKeys="RouteTableDataKeys"
                                :myFirstLoader="myFirstLoader"
                                :noDataFound="noDataFound"
                                :hasPagination="false"
                                :hasFilter="false"
                                :hasEditButton="true"
                                :isPageable="false"
                                :loadingTitle="'Please wait while system is loading route list'"
                                :hasManageButton="false"
                                :permissions="[ {key: 'searchPermission', value: 'BILLS_SEARCH_BILLS_GET'} ]"
                                @goToEdit="showRouteModal">
                            </data-table>
                        </div>
                    </div>
                </div>
            </div>
        </card>
        <modal v-if="showModal">
            <div slot="body" v-if="showProduct">
                <div class="row">
                    <div class="col-md-12">
                        <base-input
                            type="text"
                            label="Product Name"
                            placeholder="Product Name"
                            v-model="productName"
                            class="special-bottom"
                        />
                        <div v-if="productNameError">
                            <div class="text-danger" v-if="!$v.productName.required">
                            This field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 text-right" style="padding-top:10px;">
                        <span v-if="savingLoader">Please wait ....</span>
                    </div>
                    <div class="col-md-2 left loader-div-gif" style="padding-top:12px;">
                        <pulse-loader
                        :color="color"
                        :size="size"
                        v-if="savingLoader"
                        />
                    </div>
                    <div class="col-md-3">
                        <button type="button" class="btn btn-fill btn-outline-info float-right custom-btn-all ml-2" @click.prevent="closeModal" :disabled="savingLoader">
                            Close
                        </button>
                        <button type="submit" class="btn btn-primary btn-space  float-right custom-btn" @click.prevent="saveProduct" :disabled="savingLoader" v-if="!editable">
                            Save
                        </button>
                        <button type="submit" class="btn btn-primary btn-space  float-right custom-btn" @click.prevent="editProduct" :disabled="savingLoader" v-if="editable">
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <div slot="body" v-if="showRoute">
                <div class="row">
                    <div class="col-md-6">
                        <base-input
                            type="text"
                            label="Route Name"
                            placeholder="Route Name"
                            v-model="routeName"
                            class="special-bottom"
                        />
                        <div v-if="routeNameError">
                            <div class="text-danger" v-if="!$v.routeName.required">
                            This field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label>Operation Type</label>
                        <select class="form-control" v-model="operationType">
                            <option value="" selected>Select operation type</option>
                            <option value="IMPORT">IMPORT</option>
                            <option value="EXPORT">EXPORT</option>
                        </select>
                        <div v-if="operationTypeError">
                        <div class="text-danger" v-if="!$v.operationType.required">
                        This field is required
                        </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label>Please provide description</label>
                        <textarea
                            type="text"
                            placeholder="Please provide description"
                            class="form-control"
                            rows="5"
                            v-model="description"/>
                        <div v-if="descriptionError">
                        <div class="text-danger" v-if="!$v.description.required">
                        This field is required
                        </div>
                        </div>
                    </div>
                    <div class="col-md-7 text-right" style="padding-top:10px;">
                        <span v-if="savingLoader">Please wait ....</span>
                    </div>
                    <div class="col-md-2 left loader-div-gif" style="padding-top:12px;">
                        <pulse-loader
                        :color="color"
                        :size="size"
                        v-if="savingLoader"
                        />
                    </div>
                    <div class="col-md-3">
                        <button type="button" class="btn btn-fill btn-outline-info float-right custom-btn-all ml-2" @click.prevent="closeModal" :disabled="savingLoader">
                            Close
                        </button>
                        <button type="submit" class="btn btn-primary btn-space  float-right custom-btn" @click.prevent="saveRoute" :disabled="savingLoader" v-if="!editable">
                            Save
                        </button>
                        <button type="submit" class="btn btn-primary btn-space  float-right custom-btn" @click.prevent="editRoute" :disabled="savingLoader" v-if="editable">
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <!-- <div slot="body" v-if="showRate">
                <div class="row">
                    <div class="col-md-6">
                        <base-input
                            type="text"
                            label="Rate Per"
                            placeholder="Rate Per"
                            v-model="ratePer"
                            class="special-bottom"
                        />
                        <div v-if="ratePerError">
                            <div class="text-danger" v-if="!$v.ratePer.required">
                            This field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <base-input
                            type="text"
                            label="Rate Value"
                            placeholder="Rate Value"
                            v-model="rateValue"
                            class="special-bottom"
                        />
                        <div v-if="rateValueError">
                            <div class="text-danger" v-if="!$v.rateValue.required">
                            This field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label>Product</label>
                        <select class="form-control" v-model="shipFeeProductId">
                            <option value="" selected>Select Product</option>
                            <option v-for="(product, product_index) in products" :key="product_index" :value="product.shipFeeProductId">{{ product.name }}</option>
                        </select>
                        <div v-if="shipFeeProductIdError">
                        <div class="text-danger" v-if="!$v.shipFeeProductId.required">
                        This field is required
                        </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label>Route</label>
                        <select class="form-control" v-model="shipFeeRouteId">
                            <option value="" selected>Select route</option>
                            <option v-for="(route, route_index) in routes" :key="route_index" :value="route.shipFeeRouteId">{{ route.name }} ( {{ route.operationType }} )</option>
                        </select>
                        <div v-if="shipFeeRouteIdError">
                        <div class="text-danger" v-if="!$v.shipFeeRouteId.required">
                        This field is required
                        </div>
                        </div>
                    </div>
                    <hr />
                    <div class="col-md-7 text-right" style="padding-top:10px;">
                        <span v-if="savingLoader">Please wait ....</span>
                    </div>
                    <div class="col-md-2 left loader-div-gif" style="padding-top:12px;">
                        <pulse-loader
                        :color="color"
                        :size="size"
                        v-if="savingLoader"
                        />
                    </div>
                    <div class="col-md-3">
                        <button type="button" class="btn btn-fill btn-outline-info float-right custom-btn-all ml-2" @click.prevent="closeModal" :disabled="savingLoader">
                            Close
                        </button>
                        <button type="submit" class="btn btn-primary btn-space  float-right custom-btn" @click.prevent="saveRate" :disabled="savingLoader" v-if="!editable">
                            Save
                        </button>
                        <button type="submit" class="btn btn-primary btn-space  float-right custom-btn" @click.prevent="editRate" :disabled="savingLoader" v-if="editable">
                            Save
                        </button>
                    </div>
                </div>
            </div> -->

        </modal>
    </div>
</template>

<script>

import API from '../../../api/agency'
import Modal from '../../../components/Inputs/Modal.vue'
import {required} from "vuelidate/lib/validators";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {

    components: {

        DataTable: () => import('../../../components/DataTable/dataTable'),
        Modal,
        PulseLoader

    },

    created () {

        this.loadShippingFeeRoutes()
        this.loadShippingFeeProducts()
        
    },

    validations: {

        productName: { required },

        description: { required },
        routeName: { required },
        operationType: { required },

        ratePer: { required },
        rateValue: { required },
        shipFeeProductId: { required },
        shipFeeRouteId: { required },

    },

    data () {

        return {

            color: 'green',
            size: '15px',

            activeItem: 'Product',
            requests: [],
            products: [],
            routes: [],
            filteredProductsRequests: [],
            filteredRoutesRequests: [],

            ProductTableHeaders: ['Name'],
            ProductTableDataKeys: ['name'],
            RateTableHeaders: ['Rate Per', 'Rate Value'],
            RateTableDataKeys: ['ratePer', 'rateValue'],
            RouteTableHeaders: ['Name', 'Operation Type', 'Description'],
            RouteTableDataKeys: ['name', 'operationType', 'description'],

            myFirstLoader: false,
            noDataFound: false,

            productName: '',
            productNameError: false,
            showModal: false,
            showProduct: false,
            showRoute: false,
            savingLoader: false,
            editable: false,

            routeNameError: false,
            routeName: '',
            operationTypeError: false,
            operationType: '',
            descriptionError: false,
            description: '',

            showRate: false,
            ratePer: '',
            rateValue: '',
            shipFeeProductId: '',
            shipFeeRouteId: '',
            ratePerError: false,
            rateValueError: false,
            shipFeeProductIdError: false,
            shipFeeRouteIdError: false,

        }

    },

    methods: {

        commafyThisAmountWithDecimal () {

            this.rateValue = this.commafyAmountWithDecimal(this.rateValue);

        },

        isActive(menuItem) {

            return this.activeItem === menuItem;

        },

        /* FOR FUTURE USE IF CUSTOM DATA WILL BE NEEDED */ 
        sortingProductsData(data) {

            for (let x = 0; x < data.length; x++) {

                const requestObject = {
                ...data[x]
                }

                this.filteredProductsRequests.push(requestObject)
            }


        },

        /* FOR FUTURE USE IF CUSTOM DATA WILL BE NEEDED */
        sortingRoutesData(data) {

            for (let x = 0; x < data.length; x++) {

                const requestObject = {
                ...data[x]
                }

                this.filteredRoutesRequests.push(requestObject)
            }


        },

        loadShippingFeeProducts() {

            this.myFirstLoader = true
            this.activeItem = 'Product'

            /* THESE 2 ARE DOING THE SAME THING -> FOR FUTURE USE IF CUSTOM DATA WILL BE NEEDED */
            this.filteredProductsRequests = [];
            this.products = []

            API.getShippingFeeProducts().then((response) => {

                if(response.status) {

                    this.myFirstLoader = false
                    
                    if(response.data.data.length > 0) {

                        this.products = response.data.data
                        this.sortingProductsData(this.products)
                        this.noDataFound = false

                    } else {

                        this.noDataFound = true

                    }

                }

            }).catch(() => {

                this.myFirstLoader = false

            });

        },

        switchTabs (tab) {

            this.activeItem = tab

        },

        loadShippingFeeRates() {

            this.myFirstLoader = true

            this.filteredRequests = [];
            this.requests = []

            API.getShippingFeeRates(this.routeId, this.productId).then((response) => {

                if(response.status) {

                    this.myFirstLoader = false
                    
                    if(response.data.length > 0) {

                        this.requests = response.data
                        this.sortingData(this.requests)
                        this.noDataFound = false

                    } else {

                        this.noDataFound = true

                    }

                }

            }).catch(() => {

                this.myFirstLoader = false

            });

        },

        loadShippingFeeRoutes() {

            this.myFirstLoader = true
            this.activeItem = 'Route'

            /* THESE 2 ARE DOING THE SAME THING -> FOR FUTURE USE IF CUSTOM DATA WILL BE NEEDED */
            this.filteredRoutesRequests = [];
            this.routes = []

            API.getShippingFeeRoutes().then((response) => {

                if(response.status) {

                    this.myFirstLoader = false
                    
                    if(response.data.data.length > 0) {

                        this.routes = response.data.data
                        this.sortingRoutesData(this.routes)
                        this.noDataFound = false

                    } else {

                        this.noDataFound = true

                    }

                }

            }).catch(() => {

                this.myFirstLoader = false

            });

        },

        closeModal () {

            this.showModal = false
            this.showProduct = false
            this.productNameError = false
            this.productName = ''
            this.editable = false

        },

        showProductModal (data = null) {

            this.showModal = true
            this.showProduct = true
            this.showRoute = false
            this.showRate = false

            if (data !== null) {

                this.productName = data.name
                this.editable = true

            } else {

                this.productName = ''
                this.editable = false

            }

        },

        showRouteModal (data = null) {

            this.showModal = true
            this.showProduct = false
            this.showRoute = true
            this.showRate = false

            if (data !== null) {

                this.routeName = data.name
                this.operationType = data.operationType
                this.description = data.description
                this.editable = true

            } else {

                this.routeName = ''
                this.operationType = ''
                this.description = ''
                this.editable = false

            }

        },

        showRateModal (data = null) {

            this.showModal = true
            this.showProduct = false
            this.showRoute = false
            this.showRate = true

            if (data !== null) {

                this.ratePer = data.ratePer
                this.rateValue = data.rateValue
                this.shipFeeProductId = data.shipFeeProductId
                this.shipFeeRouteId = data.shipFeeRouteId
                this.editable = true

            } else {

                this.ratePer = ''
                this.rateValue = ''
                this.shipFeeProductId = ''
                this.shipFeeRouteId = ''
                this.editable = false

            }

        },

        saveProduct () {

            if (this.$v.productName.$invalid) {

                this.productNameError = true

            } else {

                this.savingLoader = true

                let object = {
                    name: this.productName,
                    productGroup: this.productName
                }

                API.addShippingFeeProduct(JSON.stringify(object)).then((response) => {

                if(response.status) {

                    this.savingLoader = false
                    this.loadNotification('success', 5000, 'Shipping Fee Product (click to close)', 'Product has been successfully saved', 'response', 1000 , true  , true)
                    this.loadShippingFeeProducts()
                    this.closeModal()

                } else {

                    this.savingLoader = false
                    this.loadNotification('error', 5000, 'Shipping Fee Product (click to close)', 'Product not saved', 'response', 1000 , true  , true)

                }

            }).catch(() => {

                this.savingLoader = false

            });

            }

        },

        editProduct () {

            if (this.$v.productName.$invalid) {

                this.productNameError = true

            } else {

                this.savingLoader = true

                let object = {
                    name: this.productName,
                    productGroup: this.productName
                }

                API.editShippingFeeProduct(JSON.stringify(object)).then((response) => {

                if(response.status) {

                    this.savingLoader = false
                    this.loadNotification('success', 5000, 'Shipping Fee Product (click to close)', 'Product has been successfully saved', 'response', 1000 , true  , true)
                    this.loadShippingFeeProducts()
                    this.closeModal()

                } else {

                    this.savingLoader = false
                    this.loadNotification('error', 5000, 'Shipping Fee Product (click to close)', 'Product not saved', 'response', 1000 , true  , true)

                }

            }).catch(() => {

                this.savingLoader = false

            });

            }

        },

        saveRoute () {

            if (this.$v.routeName.$invalid) {

                this.routeNameError = true

            } else if (this.$v.description.$invalid) {

                this.descriptionError = true

            } else if (this.$v.operationType.$invalid) {

                this.operationTypeError = true

            } else {

                this.savingLoader = true

                let object = {
                    description: this.description,
                    name: this.routeName,
                    operationType: this.operationType
                }

                API.addShippingFeeRoute(JSON.stringify(object)).then((response) => {

                if(response.status) {

                    this.savingLoader = false
                    this.loadNotification('success', 5000, 'Shipping Fee Route (click to close)', 'Route has been successfully saved', 'response', 1000 , true  , true)
                    this.loadShippingFeeRoutes()
                    this.closeModal()

                } else {

                    this.savingLoader = false
                    this.loadNotification('error', 5000, 'Shipping Fee Route (click to close)', 'Route not saved', 'response', 1000 , true  , true)

                }

            }).catch(() => {

                this.myFirstLoader = false

            });

            }

        },

        editRoute () {

            if (this.$v.routeName.$invalid) {

                this.routeNameError = true

            } else if (this.$v.description.$invalid) {

                this.descriptionError = true

            } else if (this.$v.operationType.$invalid) {

                this.operationTypeError = true

            } else {

                this.savingLoader = true

                let object = {
                    description: this.description,
                    name: this.routeName,
                    operationType: this.operationType
                }

                API.editShippingFeeRoute(JSON.stringify(object)).then((response) => {

                if(response.status) {

                    this.savingLoader = false
                    this.loadNotification('success', 5000, 'Shipping Fee Route (click to close)', 'Route has been successfully saved', 'response', 1000 , true  , true)
                    this.loadShippingFeeRoutes()
                    this.closeModal()

                } else {

                    this.savingLoader = false
                    this.loadNotification('error', 5000, 'Shipping Fee Route (click to close)', 'Route not saved', 'response', 1000 , true  , true)

                }

            }).catch(() => {

                this.savingLoader = false

            });

            }

        },

        saveRate () {

            if (this.$v.ratePer.$invalid) {

                this.ratePerError = true

            } else if (this.$v.rateValue.$invalid) {

                this.rateValueError = true

            } else if (this.$v.shipFeeProductId.$invalid) {

                this.shipFeeProductIdError = true

            } else if (this.$v.shipFeeRouteId.$invalid) {

                this.shipFeeRouteIdError = true

            } else {

                this.savingLoader = true

                let object = {
                    ratePer: this.ratePer,
                    rateValue: Number(this.trimComma(this.rateValue)),
                    shipFeeProductId: this.shipFeeProductId,
                    shipFeeRouteId: this.shipFeeRouteId
                }

                API.addShippingFeeRate(JSON.stringify(object)).then((response) => {

                    if(response.status) {

                        this.savingLoader = false
                        this.loadNotification('success', 5000, 'Shipping Fee Rate (click to close)', 'Rate has been successfully saved', 'response', 1000 , true  , true)
                        
                        this.ratePer = ''
                        this.rateValue = ''
                        this.shipFeeProductId = ''
                        this.shipFeeRouteId = ''

                    } else {

                        this.savingLoader = false
                        this.loadNotification('error', 5000, 'Shipping Fee Rate (click to close)', 'Route not saved', 'response', 1000 , true  , true)

                    }

                }).catch(() => {

                    this.myFirstLoader = false
                    this.loadNotification('error', 5000, 'Shipping Fee Rate (click to close)', 'Route not saved', 'response', 1000 , true  , true)

                });

            }

        },

        editRate () {

            if (this.$v.ratePer.$invalid) {

                this.ratePerError = true

            } else if (this.$v.rateValue.$invalid) {

                this.rateValueError = true

            } else if (this.$v.shipFeeProductId.$invalid) {

                this.shipFeeProductIdError = true

            } else if (this.$v.shipFeeRouteId.$invalid) {

                this.shipFeeRouteIdError = true

            } else {

                this.savingLoader = true

                let object = {
                    ratePer: this.ratePer,
                    rateValue: this.rateValue,
                    shipFeeProductId: this.shipFeeProductId,
                    shipFeeRouteId: this.shipFeeRouteId
                }

                API.editShippingFeeRate(JSON.stringify(object)).then((response) => {

                if(response.status) {

                    this.savingLoader = false
                    this.loadNotification('success', 5000, 'Shipping Fee Rate (click to close)', 'Rate has been successfully saved', 'response', 1000 , true  , true)
                    this.loadShippingFeeRates()
                    this.closeModal()

                } else {

                    this.savingLoader = false
                    this.loadNotification('error', 5000, 'Shipping Fee Rate (click to close)', 'Rate not saved', 'response', 1000 , true  , true)

                }

            }).catch(() => {

                this.savingLoader = false

            });

            }

        },

    }
    
}
</script>

<style scoped>

div.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* background-color: #ededee; */
    border-radius: 3px;
    border: 1px solid #ededee;
}

div.nav-justified div {
    float: left;
    border: none;
    position: relative;
}

div.nav-justified div a {
    display: block;
    text-align: center;
    padding: 16px 0px 10px 0px;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px !important;
}

div.nav-justified div::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    left: 50%;
    bottom: 0;
    background-color: #d59a18;
    transition: all ease-in-out .2s;
}

div.nav-justified div:hover::after {
    width: 100%;
    left: 0;
}

.tab-content {
    /* border-top: 1px solid #DCDCDC; */
    border-radius: 5px;
}

.is-active-major {
    box-shadow: inset 0 -3px 0 0 #2F4F4F;
    color: #2F4F4F !important;
}

.is-active-verified {
    box-shadow: inset 0 -3px 0 0 #3498db;
    color: #3498db !important;
}
.is-active-new {
    box-shadow: inset 0 -3px 0 0 gray;
    color: gray !important;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

</style>

<style>

</style>