<template>
  <div>

    <h6 class="legend-title">
      Sub Disbursment Details
    </h6>

    <div class="row div-table-row mb-3" v-if="accountDetails.principalParticularDto">
      <div class="col-md-2 div-table div-table-title">
        Principal Name
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ accountDetails.principalParticularDto.name }}
      </div>
      <div class="col-md-2 div-table div-table-title">
        Principal Account Number
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ accountDetails.principalParticularDto.accountNo }}
      </div>
      <div class="col-md-2 div-table div-table-title">
        Principal Carrier Code
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ accountDetails.principalParticularDto.carrierCode }}
      </div>
    </div>


    <div class="row div-table-row">
      <div class="col-md-2 div-table div-table-title">
        Disbursement Type
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ accountDetails.disbursementType }}
      </div>
      <div class="col-md-2 div-table div-table-title">
        Proforma Number
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ accountDetails.invoiceNo }}
      </div>
      <div class="col-md-2 div-table div-table-title">
        Disbursement Amount
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ putComma(accountDetails.disbursementAmount) }}
      </div>
    </div>
    <br>
    <div class="row div-table-row">
      <div class="col-md-2 div-table div-table-title">
        Deposit Amount
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ putComma(accountDetails.depositAmount) }}
      </div>
      <div class="col-md-2 div-table div-table-title">
        Bank Reference
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ accountDetails.bankReference }}
      </div>
      <div class="col-md-2 div-table div-table-title">
        PDA Status
      </div>
      <div v-if="accountDetails.status" class="col-md-2 div-table div-table-value" :class="[accountDetails.status.toUpperCase() === 'APPROVED' ? 'text-success' : 'text-danger']">
        {{ accountDetails.status }}
      </div>
    </div>
    <br>
    <div class="row div-table-row">
      <div class="col-md-2 div-table div-table-title">
        Balance
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ putComma(accountDetails.balance) }}
      </div>
      <div class="col-md-2 div-table div-table-title">
        Prepared By
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ accountDetails.preparedBy }}
      </div>
      <div class="col-md-2 div-table div-table-title">
        Approved By
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ accountDetails.approvedBy }}
      </div>
    </div>
    <br>
    <div class="row div-table-row">
      <div class="col-md-2 div-table div-table-title">
        Prepared Date
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ dateTimeToHuman(accountDetails.preparedDate) }}
      </div>
      <div class="col-md-2 div-table div-table-title">
        Approved Date
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ dateTimeToHuman(accountDetails.approvedDate) }}
      </div>
      <div class="col-md-2 div-table div-table-title">
        Deposit Details
      </div>
      <div class="col-md-2 div-table div-table-value">
        {{ accountDetails.depositDetails }}
      </div>
    </div>
    <br/>
    <div class="legend" v-if="isFDA">
      <h6 class="legend-title">
        FINAL Disbursement Details
      </h6>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          FDA generated
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ accountDetails.finalGenerated? 'Generated':'Not Generated' }}
        </div>
        <div class="col-md-3 div-table div-table-title">
          FDA generated By
        </div>
        <div class="col-md-5 div-table div-table-value">
          {{ accountDetails.finalPreparedBy }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          FDA Approved By
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ accountDetails.finalApprovedBy}}
        </div>
        <div class="col-md-4 div-table div-table-title">
          FDA Approved Date
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ dateTimeToHuman(accountDetails.finalApprovedDate) }}
        </div>
        <div class="col-md-4 div-table div-table-title mt-3">
          FDA status
        </div>
        <div class="col-md-4 div-table div-table-value mt-3" :class="[accountDetails.finalApproved ? 'text-success' : 'text-danger']">
          {{ accountDetails.finalApproved? 'APPROVED':'NOT APPROVED' }}
        </div>
      </div>
    </div>

    <div class="legend" v-if="accountDetails.vesselParticulars !== undefined">
      <h6 class="legend-title">
        vessel Details
      </h6>

      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Call ID
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ accountDetails.vesselParticulars.communicationAgreedId }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Control Reference Number
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ accountDetails.vesselParticulars.controlReferenceNumber }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Agent Code
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ accountDetails.vesselParticulars.agentCode }}
        </div>
      </div>
      <br/>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Voyage Number
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ accountDetails.vesselParticulars.voyageNo }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Vessel Name
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ accountDetails.vesselParticulars.vesselName }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Berth Number
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ accountDetails.vesselParticulars.berth }}
        </div>
      </div>
      <br/>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Vessel Type
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ accountDetails.vesselParticulars.vesselType }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Contract Type
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ accountDetails.vesselParticulars.contractType }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Call Sign
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ accountDetails.vesselParticulars.callSign }}
        </div>
      </div>
      <br/>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Expected Time of Arrival (ETA)
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ dateTimeToHuman(accountDetails.vesselParticulars.eta) }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Expected Time of Departure (ETD)
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ dateTimeToHuman(accountDetails.vesselParticulars.etd) }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Expected Time of Berthing (ETB)
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ dateTimeToHuman(accountDetails.vesselParticulars.etb) }}
        </div>
      </div>
      <br/>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Actual Time of Arrival (ATA)
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ dateTimeToHuman(accountDetails.vesselParticulars.ata) }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Actual Time of Departure (ATD)
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ dateTimeToHuman(accountDetails.vesselParticulars.atd) }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Actual Time of Berthing (ATB)
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ dateTimeToHuman(accountDetails.vesselParticulars.atb) }}
        </div>
      </div>
    </div>
    <br/>

    <br/>
  </div>

</template>

<script>

    export default {
        name: "subDisbursmentDetails",

      props:{
          isFDA:{
            type: Boolean,
            default:true
          },
        accountDetails:{
          required:true,
          type:Object
        }
      },

      components: {
        DataTable: () => import('../../../../components/DataTable/dataTable')

      },
    }
</script>

<style scoped>
  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }
</style>
