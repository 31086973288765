<template>
  <card>
    <modal v-if="modal">
      <div slot="body">
        <h5>New Maritime Vessel</h5>
        <div class="border">
          <div class="row">
            <div class="col-md-12 text-center">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
              />
            </div>

            <div class="col">
              <label>IMO NUMBER</label>
              <input type="text" class="form-control" placeholder="IMO NUMBER" v-model="postVesselData.IMO">
              <div v-if="IMOerror">
                <div
                  class="error text-danger"
                  v-if="!$v.postVesselData.IMO.required"
                >
                  This field is required
                </div>
              </div>
            </div>
            <div class="col">
              <label>CALL SIGN</label>
              <input type="text" class="form-control" placeholder="Call Sign" v-model="postVesselData.CALLSIGN">
              <div v-if="CALLSIGNerror">
                <div
                  class="error text-danger"
                  v-if="!$v.postVesselData.CALLSIGN.required"
                >
                  This field is required
                </div>
              </div>

            </div>
            <div class="col">
              <label>Ship Name</label>
              <input type="text" class="form-control" placeholder="Ship Name" v-model="postVesselData.NAME">
              <div v-if="NAMEerror">
                <div
                  class="error text-danger"
                  v-if="!$v.postVesselData.NAME.required"
                >
                  This field is required
                </div>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col">
              <label>Length Overall (m)</label>
              <input type="number" class="form-control" placeholder="Length Overall" v-model="postVesselData.LENGTH_OVERALL">
              <div v-if="LENGTH_OVERALLerror">
                <div
                  class="error text-danger"
                  v-if="!$v.postVesselData.LENGTH_OVERALL.required"
                >
                  This field is required
                </div>
              </div>

            </div>
            <div class="col">
              <label>Flag</label>
              <input type="text" class="form-control" placeholder="Flag" v-model="postVesselData.FLAG">
            </div>
            <div class="col">
              <label>Net Registered Tonnage (MT)</label>
              <input type="number" class="form-control" placeholder="Net Registered Tonnage" v-model="postVesselData.DISPLACEMENT_SUMMER" min="0">
              <div v-if="DISPLACEMENT_SUMMERError">
                <div
                  class="error text-danger"
                  v-if="!$v.postVesselData.DISPLACEMENT_SUMMER.required"
                >
                  This field is required
                </div>
              </div>

            </div>
            <div class="col">
              <label>Gross Registered Tonnage (MT)</label>
              <input type="number" class="form-control" placeholder="Gross Registered Tonnage" v-model="postVesselData.SUMMER_DWT" min="0">
              <div v-if="SUMMER_DWTerror">
                <div
                  class="error text-danger"
                  v-if="!$v.postVesselData.SUMMER_DWT.required"
                >
                  This field is required
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-2">
              <label>Select Vessel Type</label>
              <model-list-select
                :list="allCariers"
                v-model="postVesselData.VESSEL_TYPE"
                class="form-control"
                option-value="id"
                option-text="name"
                placeholder="Vessel Type"
              >
              </model-list-select>
              <div v-if="VESSEL_TYPEerror">
                <div
                  class="error text-danger"
                  v-if="!$v.postVesselData.VESSEL_TYPE.required"
                >
                  This field is required
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-12">
              <button
                @click.prevent="closeModal"
                class="btn btn-fill btn-outline-info float-right custom-btn-all"
                style="margin-inline-start:20px"
                type="button"
              >
                Close
              </button>
              <button
                @click.prevent="saveVesselV2"
                class="btn btn-primary btn-space float-right custom-btn"
                data-dismiss="modal"
                type="submit"
              >
                Save
              </button>
            </div>

          </div>

        </div>
      </div>
    </modal>

    <div class="row">
      <div class="col-md-12 text-right">
        <button
          class="btn btn-primary mr-2 custom-btn" @click.prevent="openModal('ADD')">
          <b>Add Maritime Vessel</b>
        </button>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12">
        <data-table
          :tableHeaders="tableHeaders"
          :tableData="vesselData"
          :tableDataKeys="tableDataKeys"
          :pageSizeProp="pageSize"
          :pageNo="pageNo"
          :hasFilter="false"
          :myLoader="loading"
          :myFirstLoader="loading"
          :noDataFound="noDataFound"
          :searchParameterProp="searchParameter"
          :loadingTitle="'Please wait while system is loading maritime vessels'"
          :hasManageButton="false"
          :totalElements="totalElements"
          :totalPages="totalPages"
          :isFirst="isFirst"
          :isLast="isLast"
          :isHovered="[]"
          :pageNumber="pageNumber"
          :is-hovered="false"
          :permissions="[ {key: 'searchPermission', value: 'VESSEL-DATA_ALL_GET'} ]"
          @onChange="onChange"
          @goToPage="goToPage"
        >
          <div slot = "pagination">
            <paginate
              v-model="getIsPage"
              :page-count=parseInt(totalPages)
              :page-range="3"
              :margin-pages="2"
              :click-handler="goToPage"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'">
            </paginate>
          </div>
        </data-table>

      </div>
    </div>

  </card>
</template>

<script>
  import PulseLoader from "vue-spinner/src/PulseLoader";
  import {ModelListSelect} from "vue-search-select";
  import AGENCY_API from "../../../api/agency";
  import Modal from '@/components/Inputs/Modal'
  import {required} from "vuelidate/lib/validators";
  import { VESSEL_TYPES } from '../../../mixins/constants.js'

  export default {

    name: "VesselList",

    components: {

      PulseLoader,
      Modal,
      ModelListSelect,
      DataTable: () => import('../../../components/DataTable/dataTable')

    },

    validations:{

      postVesselData:{

        DISPLACEMENT_SUMMER:{required},
        CALLSIGN:{required},
        FLAG:{required},
        IMO:{required},
        LENGTH_OVERALL:{required},
        NAME:{required},
        SUMMER_DWT:{required},
        VESSEL_TYPE:{required}

      },
    },

    computed:{

      getIsPage: {

        get: function () {

          return this.isPage

        },
        set: function (newValue) {

          this.isPage = newValue;

        }

      }

    },

    created() {

      this.$store.dispatch("pagenames/currentPageName", "Maritime Vessels");
      this.getAllVesselsV2();

    },

    data(){
      return {
        addVesselModal:false,
        editVesselModal:false,
        modal:false,
        tableHeaders:['Vessel Name','Vessel Type','IMO','LOA','Net Weight','Gross Weight','Flag'],
        tableDataKeys:['name','vesselType','imo','lengthOverall','summerDwt','displacementSummer','flag'],
        loading:false,
        pageNo: 0,
        pageSize: 15,
        totalElements: "",
        totalPages: "",
        isFirst: false,
        isLast: false,
        pageNumber: 0,
        pageCount: 1,
        noDataFound:false,
        searchParameter: '',
        isPage: 0,

        postVesselData:{
          DISPLACEMENT_SUMMER:"",
          CALLSIGN:"",
          FLAG:"",
          IMO:"",
          LENGTH_OVERALL:"",
          NAME:"",
          SUMMER_DWT:"",
          VESSEL_TYPE:""
        },
        carrierNameError:false,
        CALLSIGNerror:false,
        DISPLACEMENT_SUMMERError:false,
        LENGTH_OVERALLerror:false,
        IMOerror:false,
        NAMEerror:false,
        SUMMER_DWTerror:false,
        VESSEL_TYPEerror:false,

        color: 'green',
        size: '15px',

        carrierName:'',
        allCariers: VESSEL_TYPES,
        vesselData:[],
        CALLSIGN:"",

      }
    },

    methods:{

      getAllVesselsV2 () {

        this.loading=true;

        AGENCY_API.getAllVesselsV2(this.pageSize,this.pageNo).then((resp) => {

          this.loading=false;

          if (resp.data.status){

            this.vesselData=resp.data.data.content;
            this.totalElements=resp.data.data.totalElements;
            this.totalPages=resp.data.data.totalPages;
            this.isLast=resp.data.data.last;
            this.isFirst=resp.data.data.first;

          }else {

            this.notifyUser('error','Error','Failed to load Vessels please contact System Administrator');

          }
        }).catch((error) => {

          this.loading=false;

        })
      },

      editVesselV2(){
      },

      saveVesselV2 () {

        this.$dialog.confirm('You are about to save marine vessel, please confirm details before saving', {

          loader: true,
          html: true,
          animation: 'zoom', // Available: "zoom", "bounce", "fade"
          okText: 'Yes, save',
          cancelText: 'No, review details',

        }).then((dialog) => {

          if( this.postVesselData.DISPLACEMENT_SUMMER !== "" && this.postVesselData.IMO !== "" && this.postVesselData.LENGTH_OVERALL !== "" 
              && this.postVesselData.NAME !== "" && this.postVesselData.SUMMER_DWT !== "" && this.postVesselData.VESSEL_TYPE !== "") {

            this.carrierNameError = false;
            this.DISPLACEMENT_SUMMERError = false;
            this.LENGTH_OVERALLerror = false;
            this.IMOerror = false;
            this.NAMEerror = false;
            this.SUMMER_DWTerror = false;
            this.VESSEL_TYPEerror = false;
            this.loading = true;

            let data = {

              DISPLACEMENT_SUMMER:this.postVesselData.DISPLACEMENT_SUMMER.trim(),
              CALLSIGN:this.postVesselData.CALLSIGN.trim(),
              FLAG:this.postVesselData.FLAG.trim(),
              IMO:this.postVesselData.IMO.trim(),
              LENGTH_OVERALL:this.postVesselData.LENGTH_OVERALL.trim(),
              NAME:this.postVesselData.NAME.trim(),
              SUMMER_DWT:this.postVesselData.SUMMER_DWT.trim(),
              VESSEL_TYPE:this.postVesselData.VESSEL_TYPE.trim()

            };

            AGENCY_API.saveVesselsV2(data).then((resp) => {

              this.loading=false;

              if(resp.data.status){
                this.postVesselData={
                  DISPLACEMENT_SUMMER:"",
                  CALLSIGN:"",
                  FLAG:"",
                  IMO:"",
                  LENGTH_OVERALL:"",
                  NAME:"",
                  SUMMER_DWT:"",
                  VESSEL_TYPE:""
                };
                this.notifyUser('success','Success','Vessel Saved');
                this.getAllVesselsV2();
                this.closeModal();
              }else {
                this.notifyUser('error','Error','Failed to save Vessel,Contact System Administrator');
              }

            }).catch((error)=>{
              this.loading=false;
              this.notifyUser('error','Error','Failed to save Vessel,Contact System Administrator');
            });

          } else {
            this.carrierNameError=true;
            this.DISPLACEMENT_SUMMERError=true;
            this.LENGTH_OVERALLerror=true;
            this.IMOerror=true;
            this.NAMEerror=true;
            this.SUMMER_DWTerror=true;
            this.VESSEL_TYPEerror=true;
          }

        dialog.close();

        });


      },

      openModal(type){

        this.modal=true;

        if(type==='ADD'){
          this.addVesselModal=true;
        }

        if(type==='EDIT'){
          this.editVesselModal=true;

        }
      },

      closeModal(){
        this.addVesselModal=false;
        this.editVesselModal=false;
        this.modal=false;

      },


      goToPage(page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1;
        this.pageNo = page;

      },

      onChange(event) {
        this.isPage = 0;
        this.pageSize = event;
        this.pageNo =0;

        this.getAllVesselsV2();

      }




    }
  }
</script>

<style scoped>
  .border {
    border: 1px solid #ccc!important;
    padding: 20px;
    border-radius: 5px;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
  }
</style>
