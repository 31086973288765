<template>
<div>
<card>
    <div class="nav-justified row">
    <div class="col-md-2">
        <a @click.prevent="setActiveOnClick('Billed' , 'Not Approved')"
            :class="[ isActive('Billed') ? 'is-active-verified' : '' ]" href="#Billed">Waiting Approval</a>
    </div>
    <div class="col-md-2">
        <a @click.prevent="setActiveOnClick('WaitingControlNumber' , 'Waiting Control Number')"
            :class="[ isActive('WaitingControlNumber') ? 'is-active-waiting-control-number' : '' ]"
            href="#WaitingControlNumber1">Waiting GePG Control No.</a>
    </div>
    <div class="col-md-1">
        <a @click.prevent="setActiveOnClick('Approved' , 'Not Paid')"
            :class="[ isActive('Approved') ? 'is-active-major' : '' ]" href="#Approved">Approved</a>
    </div>
    <div class="col-md-1">
        <a @click.prevent="setActiveOnClick('Paid' , 'Paid')" :class="[ isActive('Paid') ? 'is-active-minor' : '' ]"
            href="#Paid">Paid</a>
    </div>
    <div class="col-md-2">
        <a @click.prevent="setActiveOnClick('Cancelled' , 'Canceled')"
            :class="[ isActive('Cancelled') ? 'is-active-returned' : '' ]" href="#Cancelled">Cancelled</a>
    </div>
    <div class="col-md-1">
        <a @click.prevent="setActiveOnClick('BillExpired' , 'Bill Expired')"
            :class="[ isActive('BillExpired') ? 'is-active-returned' : '' ]" href="#BillExpired">Expired</a>
    </div>
    <div class="col-md-2">
        <a @click.prevent="setActiveOnClick('ToGePG' , 'Submiting to GePG')"
            :class="[ isActive('ToGePG') ? 'is-active-new' : '' ]" href="#ToGePG">Submitting to GePG</a>
    </div>
    <div class="col-md-1">
        <a @click.prevent="setActiveOnClick('AllBills' , 'All')" :class="[ isActive('AllBills') ? 'is-active-all' : '' ]"
            href="#AllBills">All Bills</a>
    </div>
    </div>
    <div class="tab-content py-2" id="myTabContent">
    <div class="tab-pane fade pt-3" :class="{ 'active show': isActive(activeItem) }" :id="activeItem">
        <data-table
        ref="table"
        :key="pageSize"
        :tableHeaders="tableHeaders"
        :tableData="filteredRequests"
        :tableDataKeys="tableDataKeys"
        :pageSizeProp="parseInt(pageSize)"
        :pageNo="pageNo"
        :myLoader="myLoader"
        :reloadAllRequest="reloadAllRequest"
        :myFirstLoader="myFirstLoader"
        :noDataFound="noDataFound"
        :is-hovered="isHovered"
        :activeTariffItems="activeTariffItems"
        :searchParameterProp="searchParameter"
        :loadingTitle="'Please wait while system is loading ' + getBillStatus(billStatus)"
        :hasManageButton="false"
        :coloredStatus="true"
        :totalElements="totalElements"
        :applicationCurrentStatus="activeItem"
        :totalPages="totalPages"
        :isFirst="isFirst"
        :isLast="isLast"
        :pageNumber="pageNumber"
        :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Manage' , 'class' : 'btn-success'}]"
        :whichModuleAndType="whichModuleAndType"
        :allCustomers="allCustomers"
        :permissions="[ {key: 'searchPermission', value: 'BILLS_SEARCH_BILLS_GET'} ]"
        @resetParams="resetParams"
        @showToolTip="showToolTip"
        @hideToolTip="hideToolTip"
        @search="searchGeneralParams"
        @onChange="onChange"
        @goToPage="goToPage"
        @goToFinishTallying="goToApplication">
        <div slot="pagination">
            <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
            </paginate>
        </div>
        </data-table>
    </div>
    </div>
</card>
<global-modal v-if="myLoader">
    <div slot="globalLoader">
    <div class="row text-center">
        <div class="col-md-12">Loading, Please wait</div>
        <div class="col-md-12">
        <br/>
        </div>
        <div class="col-md-12">
        <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
        </div>
    </div>
    </div>
</global-modal>
</div>
</template>

<script>

import API from "../../../../api/index"
import {mapGetters, mapActions} from "vuex"
import PulseLoader from "vue-spinner/src/PulseLoader.vue"
import GlobalModal from "../../../../components/globalLoader"

export default {

name: "billsWaitingApproval",

components: {

    PulseLoader,
    GlobalModal,
    DataTable: () => import('../../../../components/DataTable/dataTable')

},

async created() {

    if (localStorage.getItem("search-params") !== null && localStorage.getItem("search-params") === 'undefined') {

    localStorage.removeItem("search-params");
    localStorage.removeItem("cfa-customer");

    }

    localStorage.setItem("bill-type", "bl");
    this.loadAllCustomers();

    this.$store.dispatch("pagenames/currentPageName", "Bill of Lading Bills");

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.loadRequest();
    if (localStorage.getItem("browser-tabs-index") !== null) {

    localStorage.removeItem("browser-tabs-index");

    }
    this.loadAllActiveTariffItems()

},

computed: {

    ...mapGetters("notifications", [

    "getNewAppPage"

    ]),

    ...mapGetters('global' , [

    'getActiveTariffItems'

    ]),

    ...mapGetters("shippingtallybilling", [

    "getRequestList",
    "getPageCount",
    "getMyFirstLoader",
    "getNoDataFound",
    "getSuccessAlert",
    "getErrorMessage",
    "getErrorAlert",
    "getSuccessMessage",
    "getReloadAllRequest",
    "getMyLoader",
    "getSearchParameter",
    "getHasSearchedVariable",
    "getPageNo",
    "getPageSize",
    "getRequestId",
    "getTotalElement",
    "getTotalPage",
    "getPageNumber",
    "getIsFirst",
    "getIsLast",
    "getTalliedDocumentId",
    "getSearchParams"
    ]),

    getIsPage: {

    get: function () {
        return this.isPage
    },
    set: function (newValue) {
        this.isPage = newValue;
    }

    }

},

data() {

    return {

    pageNo: 0,
    pageSize: 15,
    totalElements: "",
    totalPages: "",
    isFirst: false,
    isLast: false,
    pageNumber: '',
    billType: 'BL',
    billStatus: 'Not Approved',

    color: "green",
    size: "12px",
    loading: true,
    whichModuleAndType: 'blBill',

    searchParameter: "",
    hasSearchedVariable: false,
    reloadAllRequest: false,
    noDataFound: false,
    pdfFormatError: false,
    loadAllHeaderLoader: false,
    allAttachmentVerified: false,
    myLoader: false,
    myFirstLoader: false,
    isSearchable: false,
    requests: [],
    filteredRequests: [],
    allCustomers: [],
    isHovered: [],
    activeTariffItems: [],
    activeItem: 'Billed',
    newParams: '',
    isPage: 0,
    pageNoPart: 0,
    initialParams: '?status=Not Approved&billType=bl&size=' + 15 + '&page=' + 0,

    finalUserPermissions: [],
    tableHeaders: [],
    tableDataKeys: [],

    }

},

methods: {

    sortData (field) {

    this.filteredRequests.sort(function(a, b){

        if(a.field < b.field) { return -1; }
        if(a.field > b.field) { return 1; }
        return 0;
        
    })

    },

    loadAllCustomers() {

    this.myloader = true;

    API.getCustomerAll()
        .then((response) => {
        if (response.data.status) {
            this.allCustomers = response.data.data;
        } else {
        }
        })
        .catch((e) => {
        this.ErrorMessage =
            ", TASAC Customers not loaded, please contact system administrator";
        this.SuccessAlert = false;
        this.ErrorAlert = true;
        this.SuccessMessage = "";
        this.SuccessAlert = false;
        this.myloader = false;
        });

    },

    loadAllActiveTariffItems() {

        this.loadActiveTariffItems({}).then(() => {

        this.activeTariffItems = this.getActiveTariffItems;

        }).catch(() => {})

    },

    resetParams() {

    this.$store.dispatch("shippingtallybilling/hasSearchngParams", false)
    console.log('this.billStatus', this.billStatus)

    if (localStorage.getItem("browser-tabs-index") !== null) {

        this.setActive(localStorage.getItem("browser-tabs-index").split('**')[0], localStorage.getItem("browser-tabs-index").split('**')[1]);
    } else {

        this.setActive(this.activeItem, this.billStatus);

    }
    this.newParams = ""
    this.pageNoPart = 0
    this.isPage = this.pageNoPart + 1
    this.filteredRequests = []
    localStorage.removeItem("page-no")
    localStorage.removeItem("page-size")

    },

    loadRequest() {

    if (localStorage.getItem("browser-tabs-index") !== null) {

        this.pageSize = localStorage.getItem("page-size")
        this.setActive(localStorage.getItem("browser-tabs-index").split('**')[0], localStorage.getItem("browser-tabs-index").split('**')[1])

    } else {

        this.setActive(this.activeItem, this.billStatus);

    }

    },

    sortingData(data) {

    if(this.activeItem === 'AllBills') {

        this.tableHeaders = ['Bill Number', 'Exchange Rate Used (TZS)', 'Total Amount (USD)', 'Status Date', 'Control Number', 'Bill Type', 'Status']
        this.tableDataKeys = ['billNo', 'exchangeRate', 'totalVatedAmount', 'dateBilled', 'controlNumber', 'bulk', 'status']

        for (let x = 0; x < data.length; x++) {

        const requestObject = {
            ...data[x],
            dateBilled: this.dateTimeToHuman(data[x].dateBilled),
            exchangeRate: this.putComma(data[x].exchangeRate),
            billVatedAmountTzs: this.putComma(data[x].billVatedAmountTzs),
            bulk: data[x].bulk ? 'BULK' : 'SINGLE',
            status: data[x].status,
            controlNumber: data[x].controlNumber === null ? ' - ' : data[x].controlNumber
        }

        this.filteredRequests.push(requestObject)

        }

    } else {

        let myStatus = ''
        let controlNumberHeader = ''
        let controlNumberDataKey = ''

        let dateBilled = dateBilled

        switch (this.activeItem) {

        case  'Billed':

            myStatus = 'Billed'

            break;
        case  'WaitingControlNumber':

            myStatus = 'Billed'

            break;
        case  'ToGePG':

            myStatus = 'Billed'

            break;

        case 'Approved':

            myStatus = 'Approved'
            controlNumberHeader = 'Control Number'
            controlNumberDataKey = 'controlNumber'
            
            break;

        case 'Paid':

            myStatus = 'Payment'
            controlNumberHeader = 'Control Number'
            controlNumberDataKey = 'controlNumber'
            
            break;

        case 'Cancelled':

            myStatus = 'Cancelled'
            controlNumberHeader = 'Control Number'
            controlNumberDataKey = 'controlNumber'
            
            break;
        
        default: //BillExpired

            myStatus = 'Expiry'
            controlNumberHeader = 'Control Number'
            controlNumberDataKey = 'controlNumber'

            break;
        }

        this.tableHeaders = ['Bill Number', 'Exchange Rate Used (TZS)', 'Total Amount (USD)', myStatus + ' Date', controlNumberHeader, 'Bill Type']
        this.tableDataKeys = ['billNo', 'exchangeRate', 'totalVatedAmount', 'dateBilled', controlNumberDataKey, 'bulk']

        for (let x = 0; x < data.length; x++) {

        const requestObject = {
            ...data[x],
            dateBilled: this.dateTimeToHuman(data[x].dateBilled),
            exchangeRate: this.putComma(data[x].exchangeRate),
            billVatedAmountTzs: this.putComma(data[x].billVatedAmountTzs),
            bulk: data[x].bulk ? 'BULK' : 'SINGLE'
        }

        this.filteredRequests.push(requestObject)

        }

    }


    },

    ...mapActions("shippingtallybilling", [

    "searchWithGeneralParamsForAllBillsOnCreated",
    "searchWithGeneralParamsForAllBillsAfterCreated",
    "approveTallyBill",
    "searchWithGeneralParams"

    ]),

    ...mapActions("global", [

        'loadActiveTariffItems'

    ]),

    loadSearchParameters(params, fromApplicationPageNo) {

    let fromApplicationPageNoOrNot
    typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0

    if (this.getHasSearchedVariable) {

        this.newParams = params

    } else {

        let firstPart = params.split("&page=")[0]
        let secondPart = firstPart.split("size=")[0]
        this.newParams = ""
        this.isPage = fromApplicationPageNo
        // this.newParams = secondPart + 'size=' + this.pageSize + '&page=' + this.pageNo
        // console.log('secondPart', secondPart)
        // ?status=All&billType=bl&controlNumber=eeeeee&

        let index = secondPart.indexOf('&');
        let secondPartArray = [secondPart.slice(0, index), secondPart.slice(index + 1)];

        // console.log("secondPartArray[0]", secondPartArray[0]) //?status=All
        // console.log("secondPartArray[1]", secondPartArray[1]) //billType=bl&controlNumber=eeeeee&

        if(secondPartArray[0].split('=')[1] === 'All') {

        this.newParams =  '?' + secondPartArray[1] + this.pageSize + '&page=' + this.pageNo

        } else {

        this.newParams = secondPart + 'size=' + this.pageSize + '&page=' + this.pageNo

        }

    }

    return new Promise((resolve) => {

        this.$store.dispatch("shippingtallybilling/pageSizing", this.pageSize);
        this.$store.dispatch("shippingtallybilling/pageNumbering", this.pageNo);
        this.$store.dispatch("shippingtallybilling/paramsSearching", this.newParams);

        resolve();

    })

    },

    setAllGetters(flag) {

    this.requests = this.getRequestList;
    flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
    flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';

    this.noDataFound = this.getNoDataFound;
    this.SuccessMessage = this.getSuccessMessage;
    this.ErrorMessage = this.getErrorMessage;
    this.ErrorAlert = this.getErrorAlert;
    this.SuccessAlert = this.getSuccessAlert;
    this.pageNo = this.getPageNo;
    this.pageSize = this.getPageSize;

    this.totalElements = this.getTotalElement;
    this.totalPages = this.getTotalPage;
    this.pageNumber = this.getPageNumber;
    this.isFirst = this.getIsFirst;
    this.isLast = this.getIsLast;

    this.sortingData(this.requests)

    },

    searchGeneralParams(params, fromApplicationPageNo) {

    this.newParams = params

    this.filteredRequests = [];

    this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

        this.searchWithGeneralParams({}).then(() => {

        this.setAllGetters('onCreated')

        }).catch(() => {


        });

        this.myFirstLoader = this.getMyFirstLoader;

    }).catch(() => {


    });

    },

    loadPageableRequestOnCreated(params, fromApplicationPageNo) {

    this.newParams = params

    this.filteredRequests = [];

    this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

        this.searchWithGeneralParamsForAllBillsOnCreated({}).then((response) => {

        this.setAllGetters('onCreated');
        });

        this.myFirstLoader = this.getMyFirstLoader;

    }).catch(() => {

    });
    },

    loadPageableRequestsAfterCreated(params, fromApplicationPageNo) {

    this.newParams = params

    this.filteredRequests = [];

    this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

        this.searchWithGeneralParamsForAllBillsAfterCreated({}).then(() => {

        this.setAllGetters('afterCreated');
        });

        this.myLoader = this.getMyLoader;

    }).catch(() => {


    });
    },

    goToPage(page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1

        this.pageNo = page;

        if (this.getHasSearchedVariable) {

            let firstPart = this.newParams.split("page=")[0]
            this.newParams = ""
            this.newParams = firstPart + '&page=' + this.pageNo
            this.pageNoPart = this.isPage

            this.searchGeneralParams(this.newParams, this.pageNoPart);
            this.filteredRequests = []

        } else {

            let newParams = ''

            if(this.billStatus === 'All') {

            newParams =  '?&billType=bl&size=' + this.pageSize + '&page=' + this.pageNo

            } else {

            newParams =  '?status=' + this.billStatus + '&billType=bl&size=' + this.pageSize + '&page=' + this.pageNo

            }

            this.loadPageableRequestsAfterCreated(newParams, this.pageNoPart);
            this.filteredRequests = []
        }

    },

    onChange (event) {

        this.$store.dispatch("shippingtallybilling/pageSizing", event);
        this.$store.dispatch("shippingtallybilling/pageNumbering", 0);
        localStorage.removeItem("page-no")
        localStorage.removeItem("page-size")
        this.isPage = 0

        this.pageSize = Number(this.getPageSize);
        this.pageNo = this.getPageNo;

        if (this.getHasSearchedVariable) {

            let firstPart = this.newParams.split("&page=")[0]
            let secondPart = firstPart.split("size=")[0]
            let thirdPart = firstPart.split("size=")[1]
            this.newParams = ""
            this.newParams = secondPart + 'size=' + this.pageSize + '&page=' + 0
            this.pageNoPart = 0

            this.searchGeneralParams(this.newParams , this.pageNoPart);
            this.filteredRequests = []

        } else {

            let newParams = ''

            if(this.billStatus === 'All') {

            newParams =  '?&billType=bl&size=' + this.pageSize + '&page=' + 0

            } else {

            newParams =  '?status=' + this.billStatus + '&billType=bl&size=' + this.pageSize + '&page=' + 0

            }

            this.loadPageableRequestOnCreated(newParams , 0);
            this.filteredRequests = []
        }

    },

    goToApplication(bill) {

    document.cookie = "browser-local-cashed-url-back-2=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
    localStorage.setItem("browser-tabs-index", this.activeItem + '**' + this.billStatus);
    if (this.getHasSearchedVariable) {

        localStorage.setItem("search-params", this.newParams)
        this.newParams = ""

    } else {

        localStorage.setItem("page-no", this.getPageNo);
        localStorage.setItem("page-size", this.getPageSize);

    }
    this.$router.push("/clearing-and-forwarding/application-bill-view/" + bill.billId);

    },

    showToolTip(object) {

    this.isHovered = [object];
    },

    hideToolTip(object) {

    this.isHovered.splice(this.isHovered.indexOf(object), 1)

    },

    isActive(menuItem) {

    return this.activeItem === menuItem;

    },

    setActiveOnClick(menuItem, billStatus) {

    if (localStorage.getItem("search-params") !== null) {

        localStorage.setItem("remove-customer-name", "rcn")

    }
    
    this.pageSize = 15
    this.$refs.table.resetParamsInInputs()
    if(billStatus === 'All') {

        this.newParams =  '?&billType=bl&size=' + this.pageSize + '&page=' + 0

    } else {

        this.newParams =  '?status=' + billStatus + '&billType=bl&size=' + this.pageSize + '&page=' + 0

    }
    
    this.loadPageableRequestOnCreated(this.newParams, 0)

    this.activeItem = menuItem
    this.requests = []
    this.billStatus = billStatus
    this.filteredRequests = []
    this.newParams = ""
    this.isPage = 1
    localStorage.setItem("bill-status", billStatus)
    localStorage.removeItem("search-params")
    localStorage.removeItem("cfa-customer")
    localStorage.removeItem("page-no")
    localStorage.removeItem("page-size")

    },

    setActive(menuItem, billStatus) {

    if (localStorage.getItem("search-params") !== null) {

        this.newParams = ""
        this.newParams = localStorage.getItem("search-params")
        let secondPart = this.newParams.split("size=")[1]
        this.pageNoPart = parseInt(secondPart.split("page=")[1])
        this.isPage = this.pageNoPart + 1

        //this.searchGeneralChargeParams(this.newParams) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page with selected page bug
        this.searchGeneralParams(this.newParams, this.pageNoPart) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page without selected page bug

    } else {

        if (localStorage.getItem("page-no") !== null && localStorage.getItem("page-size") !== null) {

        this.$store.dispatch("shippingtallybilling/pageSizing", parseInt(localStorage.getItem("page-size")));
        this.$store.dispatch("shippingtallybilling/pageNumbering", parseInt(localStorage.getItem("page-no")));

        let initialParam =  '?status=' + billStatus + '&billType=bl&size=' + this.getPageSize + '&page=' + this.getPageNo
        this.loadPageableRequestOnCreated(initialParam, this.getPageNo)
        this.isPage = parseInt(localStorage.getItem("page-no")) + 1

        } else {
        let initialParams = '?status=' + billStatus + '&billType=bl&size=' + 15 + '&page=' + 0
        this.loadPageableRequestOnCreated(initialParams , 0)
        this.isPage = this.pageNo + 1

        }

        this.$store.dispatch("shippingtallybilling/hasSearchngParams", false);
        this.newParams = ""
        this.pageNoPart = 0

    }

    this.activeItem = menuItem
    this.requests = []
    this.billStatus = billStatus
    this.filteredRequests = []
    localStorage.setItem("bill-status", billStatus)

    }

},

destroyed() {

    localStorage.removeItem("bill-type")
    localStorage.removeItem("bill-status")

}

}
</script>

<style scoped>
/* ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
}

ul.nav-justified li {
    float: left;
    border: none;
    position: relative;
}

ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
}

ul.nav-justified li::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    left: 50%;
    bottom: 0;
    background-color: #d59a18;
    transition: all ease-in-out .2s;
}

ul.nav-justified li:hover::after {
    width: 100%;
    left: 0;
} */





div.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* background-color: #ededee; */
    border-radius: 3px;
    border: 1px solid #ededee;
}

div.nav-justified div {
    float: left;
    border: none;
    position: relative;
}

div.nav-justified div a {
    display: block;
    text-align: center;
    padding: 16px 0px 10px 0px;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px !important;
}

div.nav-justified div::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    left: 50%;
    bottom: 0;
    background-color: #d59a18;
    transition: all ease-in-out .2s;
}

div.nav-justified div:hover::after {
    width: 100%;
    left: 0;
}

.is-active-verified {
box-shadow: inset 0 -3px 0 0 #3498db;
color: #3498db !important;
}
.is-active-waiting-control-number {
box-shadow: inset 0 -3px 0 0 #d59a18;
color: #d59a18 !important;
}
.is-active-major {
box-shadow: inset 0 -3px 0 0 #2F4F4F;
color: #2F4F4F !important;
}
.is-active-minor {
box-shadow: inset 0 -3px 0 0 green;
color: green !important;
}
.is-active-returned {
box-shadow: inset 0 -3px 0 0 red;
color: red !important;
}
.is-active-new {
box-shadow: inset 0 -3px 0 0 gray;
color: gray !important;
}
.is-active-all {
box-shadow: inset 0 -3px 0 0 brown;
color: brown !important;
}

.tab-content {
/* border-top: 1px solid #DCDCDC; */
border-radius: 5px;
}

</style>
