<template>
    <div :key="requestId">
        {{ ketRerenderComponentChat }}
        <card style="padding-right:10px;padding-left:10px;">
            <div class="row">
                <div class="col-md-8">
                    Conversion for Application [ File Number: <b>{{ fileNo }}</b> ] [ B/L Number: <b title="Go to Application" class="go-to-application" @click="goToApplication">{{ blNumber }}</b>  ]
                </div>
                <div class="col-md-4 text-right">
                    <span class="go-to-application" v-if="!hasMajorIssue" @click="goToApplicationAction()">click here to attach any additional documents</span>
                </div>
                <div class="col-md-12 mb-2">
                    <hr />
                </div>
                <div class="col-md-12 text-center pt-4" v-if="loadAllHeaderLoader">
                    <p>Loading messages, Please wait</p><pulse-loader
                        :color="color"
                        :size="size"
                    />
                </div>
            </div>
            <div class="chat-wrapper">
                <div class="chat-messages" id="message-div">
                    <span v-for="(message , index) in requestMessages" :key="index">
                        <div class="message" :class="message.customerId === null ? 'admin' : ''">
                            <div>
                                <p class="message-header">
                                    <span class="message-user">{{ message.createdBy }}</span>
                                    <span class="message-date">{{ getFriendlyMessageSentTime(message.createdAt) }}</span>
                                </p>
                                <p class="message-content">{{ message.comment }}</p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <label>Please enter message</label>
                    <textarea
                        type="text"
                        placeholder="Please enter message"
                        class="form-control"
                        rows="5"
                        :disabled="sendRequestLoader"
                        v-model="tasacRejectMessageNew"/>
                    <div v-if="rejectedMessageError">
                        <div
                        class="text-danger"
                        v-if="!$v.tasacRejectMessageNew.required"
                        >
                        This field is required
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <br />
                </div>
                <div class="col-md-12" v-if="getSingleRequest.status === 'Request Submitted' || getSingleRequest.status === 'Request Resubmitted After Changes'">
                    <div class="custom-control float-right">
                    <input id="minor" :value="issueTypes.MINOR" name="issue-type" type="radio" v-model="issueType" style="height:15px; width:15px;">&nbsp;
                    <label for="minor" style="font-size:16px !important;color:orange !important;"> <b>Has Minor Issue</b> </label>
                    </div>
                    <div class="custom-control float-right">
                    <input id="major" :value="issueTypes.MAJOR" name="issue-type" type="radio" v-model="issueType" style="height:15px; width:15px;">&nbsp;
                    <label for="major" style="font-size:16px !important;color:red !important;"> <b>Has Major Issue</b> </label>
                    </div>
                    <!-- <div class="custom-control float-right">
                    <input id="no" :value="issueTypes.NONE" name="issue-type" type="radio" v-model="issueType" style="height:15px; width:15px;">&nbsp;
                    <label for="no" style="font-size:16px !important;color:green !important;"> <b>Has No Issue</b> </label>
                    </div> -->
                </div>
            </div>

            <div class="text-center row">
                <div class="col-md-10 text-right loader-div-gif mt-2">
                <pulse-loader
                    :loading="sendRequestLoader"
                    :color="color"
                    :size="size"
                />
                </div>
                <div class="col-md-2 text-right">
                <button
                    type="submit"
                    class="btn btn-success btn-space custom-btn mr-2"
                    data-dismiss="modal"
                    @click.prevent="messageChatRoom">
                    Send
                </button>
                <button class="btn btn-fill btn-outline-info float-right custom-btn-all" @click.prevent="goBack">
                    Close
                </button>
                </div>
            </div>
        </card>
    </div>
</template>

<script>

import API from '../../../api'
import { required } from 'vuelidate/lib/validators'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { ISSUE_TYPE } from '../../../mixins/constants'
import Chat from '../../../components/Chat/chat.vue'

export default {

    data () {

        return {

            pdfFormatAmendmentError: [],
            pdfFormatAmendmentSuccess: [],
            loadSingleEditableMessage: [],
            requestMessages: [],
            requestSingleMessage: {},

            color: 'green',
            size: '13px',
            size2: '13px',
            requestId: '',
            blNumber: '',
            fileNo: '',
            tasacRejectMessageNew: '',
            issueType: 'None',
            issueTypes: ISSUE_TYPE,

            loading: true,
            sendRequestLoader: false,
            loadAllHeaderLoader: false,
            rejectedMessageError: false,
            hasMajorIssue: false,
            getSingleRequest: {},

            file2: '',
            fileData2: '',

        }

    },

    components: {

        PulseLoader,
        Chat

    },

    validations: {

        tasacRejectMessageNew: { required }

    },

    computed: {

        ketRerenderComponentChat: function () {
            
            this.requestId = this.$route.params.requestId
            this.getAllMessagesPerRequest(this.requestId)

        }

    },

    created () {

        this.$store.dispatch('pagenames/currentPageName', 'Conversion Room')
        this.requestMessages = []
        this.getAllMessagesPerRequest(this.$route.params.requestId)
    
    },

    methods: {

        messageChatRoom () {

            if (this.$v.tasacRejectMessageNew.$invalid) {

                this.rejectedMessageError = true

            } else {

                this.sendRequestLoader = true

                const myObject = {

                comment: this.tasacRejectMessageNew,
                requestCommentAttachmentDtos: [],
                requestId: Number(this.requestId),
                issueType: this.issueType
                }

                API.sendNewMessageOnChatRoom(JSON.stringify(myObject)).then(
                response => {
                    if (response.data.status) {

                        this.tasacRejectMessageNew = ''
                        this.sendRequestLoader = false
                        this.getAllMessagesPerRequest(this.requestId)

                    } else {

                        this.tasacRejectMessageNew = ''
                        this.requestId = ''
                        this.sendRequestLoader = false

                    }
                }
                ).catch(() => {

                    this.tasacRejectMessageNew = ''
                    this.requestId = ''
                    this.sendRequestLoader = false

                })
            }

        },

        getAllMessagesPerRequest (data) {

            this.loadAllHeaderLoader = true

            API.getMessagesOnChatRoomPerRequest(data).then(
                response => {

                    if (response.data.status) {

                        this.requestMessages = response.data.data
                        
                        if(this.requestMessages.length > 0) {

                            if(this.requestMessages[0].applicationStatus == 'Request Returned For Major Changes') {

                                this.issueType = this.issueTypes.MAJOR

                            } else if (this.requestMessages[0].applicationStatus == 'Request Returned For Minor Changes') {

                                this.issueType = this.issueTypes.MINOR

                            } else {

                                this.issueType = this.issueTypes.NONE

                            }
                            
                        let objDiv = document.getElementById("message-div");
                        objDiv.scroll({ top: objDiv.scrollHeight, behavior: 'smooth' });


                        } else {

                            this.issueType = this.issueTypes.NONE

                        }

                        this.clearMessageAsRead(data)
                        this.getRequestDetails(data)
                        this.loadAllHeaderLoader = false

                    } else {}
                }

            ).catch(() => {})

        },

        clearMessageAsRead (data) {

            API.markChatMessagesAsRead(data).then(
                response => {

                    if (response.data.status) {

                    } else {}

                }
            ).catch(() => {})

        },

        getRequestDetails (data) {

            API.getSingleRequest(data).then(
                response => {

                    if (response.data.status) {

                        this.getSingleRequest = response.data.data
                        this.blNumber = response.data.data.blNumber
                        response.data.data.fileNo !== null && response.data.data.fileNo !== '' ? this.fileNo = response.data.data.fileNo : this.fileNo = 'NIL'
                        
                    } else {}

                }
            ).catch(() => {})

        },

        goBack () {

            if (window.$cookies.isKey('browser-local-cashed-url-back-to-chat')) {

                this.$router.push(window.$cookies.get('browser-local-cashed-url-back-to-chat'));
                window.$cookies.remove('browser-local-cashed-url-back-to-chat')

            } else {

                this.$router.go(-1);

            }

        },

        goToApplication () {

            let requestType = ''
            let status = ''
            this.getSingleRequest.requestType === 'IMP' ? requestType = 'import' : 'export'
            status = this.spaceToHyphenString(this.getSingleRequest.status)
            this.$router.push({name: 'ApplicationPage', params: {requestId: this.$route.params.requestId, applicationType: requestType, status: status}})

        },

        goToApplicationAction () {

            document.cookie = 'browser-local-cashed-url-back-11=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
            this.$router.push({name: 'additionalAttachmentToApplication', params: {requestId: this.$route.params.requestId}})

        }

    }
    
}

</script>
<style lang="scss" scoped>

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}

.message-body {
    background-color: rgba(236, 236, 236, 1);
    padding: 5px 15px 5px 15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.message-body-index {
    background-color: rgba(189, 195, 199, 1);
    padding: 5px 15px 5px 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.background-color-message-to-edit {
    background-color: #87cefa !important;
}

.edit-tasac-messages:hover {
    cursor: pointer;
    color: blue;
}

.edit-tasac-messages {
    color: green;
}

.chat-message-box {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom: 1px solid #0000ff;
    // border-right: 1px solid #0000ff;
    margin-bottom: 15px;
    background-color: rgba(236, 236, 236, .4);
}

.chat-message-box-customer {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom: 1px solid #008000;
    // border-left: 1px solid #008000;
    margin-bottom: 15px;
    background-color: #dcf8c6;
}

.attended {
    color:green;
}

.not-attended {
    color:red;
}

.fileUpload2 span {
    cursor: pointer;
}

.fileUpload2 {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    font-weight: normal;
}

.fileUpload2 input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.label2 {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
}

.label-upload2 {
    position: relative;
    top: 23px;
}

.go-to-application {
    color:blue;
}

.go-to-application:hover {
    color:#d59a18;
    cursor:pointer;
}

.chat-wrapper {
    display: flex;
    flex-direction: column;
    .chat-messages {
        overflow-y: auto;
        height: calc(100vh - 450px);
        padding: 0 1.3rem;
        .message {
        display: flex;
        div {
            width: 75%;
            // display: flex;
            // flex-direction: column;
            .message-header {
            display: flex;
            align-items: center;
            .message-date {
                color: var(--gray-600);
                margin-left: 1rem;
            }
            }
            .message-content {
            display: inline-flex;
            background-color: #add8e6;
            padding: 1rem;
            border-radius: 0.5rem;
            position: relative;
            &:after {
                bottom: 100%;
                left: calc(0% + 2rem);
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-bottom-color: #add8e6;
                border-width: 10px;
                margin-left: -10px;
            }
            }
        }
        &.admin {
            justify-content: flex-end;
            div {
            justify-content: flex-end;
            .message-header {
                justify-content: flex-end;
                .message-date {
                margin-right: 1rem;
                }
                .message-user {
                order: 2;
                margin-right: 1rem;
                }
            }
            .message-content {
                float: right;
                background-color: #dcf8c6;
                &:after {
                left: calc(100% - 2rem);
                border-bottom-color: #dcf8c6;
                }
            }
            }
        }
        }
    }
    .chat-form {
        padding: 0 1.3rem;
    }
}

</style>