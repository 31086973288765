<template>
  <div>
    <!--    <modal-edit-plan v-if="refundModal">-->
    <!--      <div slot="body">-->
    <!--        <sub-disbersment-adhock :sub-disbursement-id="this.$route.params.accountId" v-on:closemodal="closeModal"></sub-disbersment-adhock>-->
    <!--      </div>-->
    <!--    </modal-edit-plan>-->
    <card v-if="myFirstLoader">
      <div class="row">
        <div class="col-md-12 text-center loader-color">
          <b>Please wait while system is loading sub disbursment account details</b>
        </div>
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader :color="color" :size="size"/>
        </div>
      </div>
    </card>
    <card style="font-size: 12px" v-if="!myFirstLoader">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav-justified" style="padding-left: 10px;padding-right: 10px">
            <li v-if="finalUserPermissions.includes('DISBURSEMENT_SUB_PDF_{ACCOUNTID}_GET') && accountDetails.disbursementInvoices !== null">
              <button @click.prevent="openPDAPdfViewer('view')"
                      class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
                Download PDA
              </button>
            </li>
            <li v-if="finalUserPermissions.includes('DISBURSEMENT_SUB_APPROVE_{ID}_GET') && accountDetails.disbursementInvoices !== null && accountDetails.status && accountDetails.status.toUpperCase() !=='APPROVED'">
              <button @click.prevent="approve"
                      class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
                Approve PDA
              </button>
            </li>
            <!-- <li v-if="finalUserPermissions.includes('DISBURSEMENT_SUB_APPROVE_{ID}_GET') && accountDetails.disbursementInvoices !== null && accountDetails.status.toUpperCase() ==='APPROVED'">
              <button class="btn btn-default-custom btn-default-custom-fill mr-2 custom-btn" disabled>
                Approve PDA
              </button>
            </li> -->
            <li v-if="finalUserPermissions.includes('DISBURSEMENT_SUB_GENERATE_{ID}_GET') && accountDetails.disbursementInvoices !== null && !accountDetails.finalGenerated && accountDetails.status && accountDetails.status.toUpperCase() ==='APPROVED'">
              <button @click.prevent="generateFDA"
                      class="btn btn-purple-fill mr-2 custom-btn">
                Generate FDA
              </button>
            </li>
            <!-- <li v-if="finalUserPermissions.includes('DISBURSEMENT_SUB_PDF_{ACCOUNTID}_GET') && accountDetails.disbursementInvoices !== null && accountDetails.finalGenerated">
              <button class="btn btn-default mr-2 custom-btn" disabled>
                Generate FDA
              </button>
            </li> -->
            <li v-if="finalUserPermissions.includes('DISBURSEMENT_SUB_PDF_{ACCOUNTID}_GET') && accountDetails.disbursementInvoices !== null && accountDetails.finalGenerated">
              <button @click.prevent="openPDAPdfViewer('view',true)"
                      class="btn btn-primary btn-primary-fill mr-2 custom-btn">
                Download FDA
              </button>
            </li>
            <li v-if="finalUserPermissions.includes('DISBURSEMENT_SUB_FINAL_APPROVE_{ID}_GET') && accountDetails.disbursementInvoices !== null && !accountDetails.finalApproved">
              <button @click.prevent="approveFDA"
                      class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
                Approve FDA
              </button>
            </li>
            <li
              v-if="finalUserPermissions.includes('DISBURSEMENT_INVOICE_ADD-ALL_POST') && accountDetails.status !== undefined && accountDetails.status && accountDetails.status.toUpperCase() !=='APPROVED'">
              <button @click.prevent="openDisbursmentEstimateModal"
                      class="btn btn-purple btn-purple-fill mr-2 custom-btn">
                Add Invoice
              </button>
            </li>
            <li>
              <button @click.prevent="viewTransactions"
                      class="btn btn-linen btn-linen-fill mr-2 custom-btn">
                Sub Disbursement Transactions
              </button>
            </li>
            <li>
              <button @click.prevent="refundModal=!refundModal"
                      class="btn btn-dark-blue-like btn-dark-blue-like-fill mr-2 custom-btn" v-if="finalUserPermissions.includes('DISBURSEMENT_REFUND_ADD_POST')">
                CREDIT/DEBIT PRINCIPAL ACCOUNT
              </button>
            </li>
          </ul>
        </div>
      </div>
    </card>
    <card style="font-size: 12px" v-if="!myFirstLoader">
      <div class="row">
        <div class="col-md-12" v-if="refundModal">
          <Transition name="fade">
            <div class="legend">
              <h6 class="legend-title">CREDIT/DEBIT Details</h6>
              <sub-disbersment-adhock :sub-disbursement-id="this.$route.params.accountId" v-on:closemodal="closeModal"></sub-disbersment-adhock>
            </div>
          </Transition>
        </div>
        <div class="col-md-12">
          <div class="legend">
            <sub-disbursement-deatils :account-details="accountDetails"></sub-disbursement-deatils>
            <div class="legend">
              <h6 class="legend-title">
                Sub Disbursement Invoices
              </h6>

              <data-table
                v-if="accountDetails.disbursementInvoices !== null"
                :tableHeaders="tableHeaders"
                :tableData="filteredRequests"
                :tableDataKeys="tableDataKeys"
                :myFirstLoader="myFirstLoader"
                :noDataFound="noDataFound"
                :hasFilter="false"
                :hasPagination="false"
                :isPageable="false"
                :has-manage-button="finalUserPermissions.includes('DISBURSEMENT_INVOICE_ADD_POST')"
                :permissions="[ {key: 'searchPermission', value: 'DISBURSEMENT_SUB_{ACCOUNTID}_GET'} ]"
                @goToApplication="showItems">
              </data-table>
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-default btn-fill btn-space custom-btn-all"
            style="float:right;"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo"/> &nbsp; Back
          </button>
        </div>
      </div>
    </card>


    <added-final-invoice-and-item-list
      :is-approved=isApproved()
      v-if="showAddedDisbursementEstimate"
      @closeModal="closeModal"
      @reload=""
      @loadSubDisbursementDetails="loadSubDisbursementDetails"
      :sub-disbursment-invoice-number="accountDetails.invoiceNo"
      :sub-disbursment-status="accountDetails.status"
      :service-category-details="serviceCategoryDetails"
      :show-added-disbursement-estimate="showAddedDisbursementEstimate"/>

    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">Preparing requested file, Please wait</div>
          <div class="col-md-12">
            <br/>
          </div>
          <div class="col-md-12">
            <pulse-loader :color="color" :size="size"></pulse-loader>
          </div>
        </div>
      </div>
    </global-modal>

    <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer"
                @downloadPdf="downloadPdf"></pdf-viewer>
  </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import CreateEstimate from '../disbursement/sub-disbursment-account/createEstimate.vue'
import addedFinalInvoiceAndItemList from '../disbursement/sub-disbursment-account/addedFinalInvoiceAndItemList.vue'
import GlobalModal from "../../../components/globalLoader"
import PdfViewer from '../../../components/pdfViewer.vue'
import ModalEditPlan from "../../../components/Inputs/ModalEditPlan";
import subDisbersmentAdhock from "../disbursement/sub-disbursment-account/subDisbersmentAdhock";
import subDisbursementDeatils from "../disbursement/sub-disbursment-account/subDisbursmentDetails";
import AGENCYAPI from "../../../api/agency";
import principalRefundsAttachments from "../principal/principalRefundsAttachments";

export default {

  components: {
    ModalEditPlan,
    PulseLoader,
    CreateEstimate,
    addedFinalInvoiceAndItemList,
    GlobalModal,
    PdfViewer,
    subDisbersmentAdhock,
    subDisbursementDeatils,
    principalRefundsAttachments,
    DataTable: () => import('../../../components/DataTable/dataTable')

  },

  data() {

    return {

      finalUserPermissions: [],
      filteredRequests: [],
      accountDetails: {},
      subDisbursmentId: '',
      showAddDisbursementEstimate: false,
      showAddedDisbursementEstimate: false,
      serviceCategoryDetails: {},
      globalLoader: false,
      color: 'green',
      size: '12px',
      scr: '',
      showPdfViewer: false,
      isFda:false,
      refundModal:false,
      myFirstLoader: false,
      noDataFound: false,
      tableHeaders: ['Description', 'Payment Request', 'Currency', 'Amount', 'VAT', 'Total'],
      tableDataKeys: ['description', 'customPaymentRequested', 'currency', 'amount', 'vat', 'total'],

    }

  },

  computed: {

    ...mapGetters('subdisbursment', [

      'getSubDisbursementAccountObject',
      'getMyFirstLoader'

    ]),

    ...mapGetters('global', [

      'getGlobalLoader',
      'getFileName'

    ]),

  },

  created() {

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.$store.dispatch('pagenames/currentPageName', 'Final Disbursment Account Details');
    this.subDisbursmentId = this.$route.params.accountId;
    this.loadSubDisbursementDetails();

  },

  methods: {

    ...mapActions('subdisbursment', [

      'getSubDisbursementAccountDetailsById',
      'getFinalDisbursementAccountDetailsById',
      'approveSubDisbursement',
      'approveFinalDisbursement',
      'getAllDisbursementInvoicePaymentByInvoiceNo'

    ]),

    ...mapActions('global', [

      'downloadBlobDocument',
      'downloadFDAdocument'

    ]),


    isApproved(){
      return this.accountDetails.finalApproved;
    },

    sortingData(data) {

      for (let x = 0; x < data.length; x++) {

        const requestObject = {

          ...data[x],
          total:this.putComma(data[x].total.toFixed(2)),
          amount:this.putComma(data[x].amount.toFixed(2)),
          customPaymentRequested: data[x].paymentRequested ? 'REQUESTED' : 'NOT REQUESTED'

        };

        this.filteredRequests.push(requestObject)

      }

    },

    setAllGetters() {

      this.filteredRequests = [];

      this.accountDetails = this.getSubDisbursementAccountObject;
      this.myFirstLoader = this.getMyFirstLoader;

      if (typeof this.accountDetails.disbursementInvoices !== undefined || this.accountDetails.disbursementInvoices !== null)
        this.sortingData(this.accountDetails.disbursementInvoices)

    },

    loadParameters() {

      return new Promise((resolve) => {

        this.$store.dispatch('subdisbursment/requestid', this.subDisbursmentId);
        resolve()

      })

    },


    loadSubDisbursementDetails() {

      this.loadParameters().then(() => {

        this.getFinalDisbursementAccountDetailsById({}).then(() => {

          this.setAllGetters();
          this.$store.dispatch('subdisbursment/requestid', '')

        }).catch(() => {

        });

        this.myFirstLoader = this.getMyFirstLoader;

      })

    },

    viewTransactions(){
      this.$router.push({name:'sub-disbursementTransactions',params:{referenceId:this.accountDetails.invoiceNo}})
    },


    generateFDA(){
      
      this.$dialog.confirm('You are about to Generate FDA, are you sure?', {

        loader: true,
        html: true,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        okText: 'Yes, approve',
        cancelText: 'No, close',

      }).then((dialog) => {

        AGENCYAPI.approveFda(this.accountDetails.subDisbursementAccountId).then((response)=>{
          if (response.data.status) {

            this.loadNotification('success', 4000, 'FDA has been successfully approved',
              'response', 1000, true, true);
            this.loadSubDisbursementDetails();
            dialog.close();
            this.loadSubDisbursementDetails();

          } else {

            this.loadNotification('error', 4000, 'FDA not approved',
              'response', 1000, true, true);
            dialog.close();

          }


        }).catch((err)=>{
          this.loadNotification('error', 4000, 'FDA not approved',
            'response', 1000, true, true);
          dialog.close();
        });

      });
    },

    approveFDA() {

      this.$dialog.confirm('You are about to approve FDA, are you sure?', {

        loader: true,
        html: true,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        okText: 'Yes, approve',
        cancelText: 'No, close',

      }).then((dialog) => {

        this.$store.dispatch('subdisbursment/requestid', this.accountDetails.subDisbursementAccountId).then(() => {

          this.approveFinalDisbursement({}).then((response) => {

            this.myLoader = this.getMyLoader;
            dialog.close();

            console.log(response.data.data);
            if (response.data.status) {

              this.loadNotification('success', 4000, 'FDA Approved',
                "",
                'response', 1000 , true  , true)
              this.loadSubDisbursementDetails();

            } else {

              this.loadNotification('error', 4000, 'Failed to Approve FDA',
                response.data.message,
                'response', 1000 , true  , true)

            }

          }).catch(() => {
            dialog.close();
          });

          this.myLoader = this.getMyLoader;

        })

      }).catch((e) => {
        console.log(e);
      })

    },

    approve() {

      this.$dialog.confirm('You are about to approve this sub disbursment account, are you sure?', {

        loader: true,
        html: true,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        okText: 'Yes, approve',
        cancelText: 'No, close',

      }).then((dialog) => {

        this.$store.dispatch('subdisbursment/requestid', this.accountDetails.subDisbursementAccountId).then(() => {

          this.approveSubDisbursement({}).then((response) => {

            this.myLoader = this.getMyLoader;

            if (response.data.status) {

              this.loadNotification('success', 4000, 'Sub Disbursment Account has been successfully approved',
                'response', 1000, true, true);
              this.loadSubDisbursementDetails();
              dialog.close();

            } else {

              this.loadNotification('error', 4000, 'Sub Disbursment Account not approved',
                response.data.message, 1000, true, true);
              dialog.close();

            }

          }).catch(() => {
            dialog.close();
          });

          this.myLoader = this.getMyLoader;

        })

      }).catch(() => {
      })

    },

    loadDownloadDocumentParams(extension) {

      return new Promise((resolve) => {

        this.$store.dispatch('global/requestid', this.accountDetails.subDisbursementAccountId);
        this.$store.dispatch('global/fileNaming', 'PDA-' + this.accountDetails.invoiceNo);
        this.$store.dispatch('global/fileExtensioning', extension);

        resolve();

      })

    },

    downloadPdf () {

      this.openPDAPdfViewer('download',this.isFda)

    },

    openPDAPdfViewer (action,isFda=false) {

      this.isFda=isFda;
      if(isFda){

        this.downloadFDAPdf(action).then(() => {

          this.showPdfViewer = true

        }).catch(() => {})
      }else {
        this.downloadDaPdf(action).then(() => {

          this.showPdfViewer = true

        }).catch(() => {})
      }


    },

    closePdfViewer () {

      this.showPdfViewer = false
      this.scr = ''

    },

    downloadFDAPdf(action) {

      return new Promise((resolve, reject) => {

        this.loadDownloadDocumentParams('pdf').then(() => {

          this.downloadFDAdocument({action}).then((response) => {

            if(response[1].status === 204) {

              this.loadNotification('warn', 4000, '',
                'File not found, please contact system admin',
                'response', 1000 , true  , true)

            } else {

              this.scr = response[0]
              resolve()


            }
            this.globalLoader = this.getGlobalLoader

          }).catch(() => {

            // this.loadNotification('error', 4000, 'File Download',
            //   'File not downloaded, please check your internet connection',
            //   'response', 1000, true, true);

            this.globalLoader = this.getGlobalLoader

          });

          this.globalLoader = this.getGlobalLoader

        })

      })

    },

    downloadDaPdf(action) {

      return new Promise((resolve, reject) => {

        this.loadDownloadDocumentParams('pdf').then(() => {

          this.downloadBlobDocument({action}).then((response) => {

            if(response[1].status === 204) {

              this.loadNotification('warn', 4000, '',
                'File not found, please contact system admin',
                'response', 1000 , true  , true)

            } else {

              // this.loadNotification('success', 4000, '',
              // 'File has been successfully loaded, please have a look at it',
              // 'response', 1000 , true  , true)
              this.scr = response[0]
              resolve()


            }

            // if (response.status === 204) {

            //   this.loadNotification('warn', 4000, 'File Download',
            //     'File not found, please contact system admin',
            //     'response', 1000, true, true)

            // } else {

            //   this.loadNotification('success', 4000, 'File Download',
            //     'File has been successfully downloaded, please have a look at it',
            //     'response', 1000, true, true)
            // }

            this.globalLoader = this.getGlobalLoader

          }).catch(() => {

            // this.loadNotification('error', 4000, 'File Download',
            //   'File not downloaded, please check your internet connection',
            //   'response', 1000, true, true);

            this.globalLoader = this.getGlobalLoader

          });

          this.globalLoader = this.getGlobalLoader

        })

      })

    },

    openDisbursmentEstimateModal() {

      this.showAddDisbursementEstimate = true

    },

    showItems(invoiceObject) {
      console.log(44444)
      console.log(invoiceObject);

      this.serviceCategoryDetails = invoiceObject;
      this.showAddedDisbursementEstimate = true

    },

    closeModal() {

      this.showAddDisbursementEstimate = false;
      this.showAddedDisbursementEstimate = false
      this.refundModal=false;
    },

    goBack() {

      this.$router.go(-1)

    }

  }

}
</script>
<style scoped>

ul.nav-justified {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ededee;
  border-radius: 3px;
}

ul.nav-justified li {
  float: left;
}

ul.nav-justified li a:hover {
  color: #d59a18;
}

ul.nav-justified li a {
  display: block;
  text-align: center;
  padding: 16px 20px 10px 21px;
  text-decoration: none;
  color: blue;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-success-custom:hover {
  border-color: #008000;
  color: #008000;
  background-color: #ffffff;
}

.btn-success-custom {
  border-color: rgba(0, 128, 0, .5);
  color: rgba(0, 128, 0, .5);
}

.btn-success-custom-fill {
  background-color: rgba(0, 128, 0, .8);
  opacity: 1;
  color: #FFFFFF;
}

.btn-purple:hover {
  border-color: #800080;
  color: #800080;
  background-color: #ffffff;
}

.btn-purple {
  border-color: #9932cc;
  color: #9932cc;
}

.btn-purple-fill {
  background-color: #9932cc;
  opacity: 1;
  color: #FFFFFF;
}

.custom-btn {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px 9px 5px 9px !important;
  font-size: 14px !important;
}

.legend {
  position: relative;
  border: 1px solid #DCDCDC;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  background-color: inherit;
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #d59a18;
}

.div-table-row {
  padding: 0 15px 0 15px;
}

.div-table {
  padding: 9px 8px 9px 8px;
}

.div-table-title {
  width: 250px;
  background-color: rgba(153, 186, 221, .5);
  font-weight: normal;
}

.div-table-value {
  border: 1px solid #DCDCDC;
  font-weight: 900;
}

.btn-dark-blue-like:hover {
  border-color:  #154360 ;
  color:  #154360 ;
  background-color: #ffffff;
}

.btn-dark-blue-like {
  border-color: rgba( 21, 67, 96 , .5);
  color: rgba( 21, 67, 96 , .5);
}

.btn-dark-blue-like-fill {
  background-color: rgb( 21, 67, 96 );
  opacity: 1;
  color: #FFFFFF;
}

.btn-linen:hover {
  border-color: #D2691E;
  color: #D2691E;
  background-color: #ffffff;
}

.btn-linen {
  border-color: rgba(210, 105, 30, .5);
  color: rgba(210, 105, 30, .5);
}

.btn-linen-fill {
  background-color: rgb(210, 105, 30);
  opacity: 1;
  color: #FFFFFF;
}

</style>
