<template>
<div>
  <card style="font-size: 12px" v-if="!loading">
    <div class="row">
      <div class="col-md-12">
        <ul class="nav-justified">
          <li v-if="!payload.cancelled && !payload.billed">
            <button @click="openCancelReasonForm" class="btn btn-danger-custom btn-danger-custom-fill mr-2 custom-btn">
            Cancel Charge
          </button>
          </li>
          <li>
            <button @click="openPdfViewer('view')" class="btn btn-purple btn-purple-fill mr-2 custom-btn">
              Print Bill of Lading
            </button>
          </li>
          <!-- <li v-if="!billOfLading.billed">
            <button @click="generateBill" class="btn btn-purple btn-purple-fill custom-btn">
              Generate Bill
            </button>
          </li> -->
        </ul>
      </div>
    </div>
  </card>
  <card v-if="loading">
    <div class="row">
      <div class="col-md-12 text-center loader-color">
        <b>Please wait while system is loading bill of lading charge details</b>
      </div>
      <div class="col-md-12 text-center">
        <br>
      </div>
      <div class="col-md-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
        />
      </div>
    </div>
  </card>
  <card v-if="!loading">
    <!-- <div class="row"> -->
      <!-- <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
        />
      </div> -->
      <!-- <div class="col-md-12">
        <button class="btn btn-dark mr-2 custom-btn">
          Cancel
        </button>
        <button
          class="btn btn-success mr-2 custom-btn"
          @click.prevent="printBillofLadding"
        >
          Print Bill of Lading
        </button>
        <button
          class="btn btn-info mr-2 custom-btn"
          @click.prevent="generateBill"
          :disabled="billOfLading.billed"
        >
          Generate Bill
        </button>
      </div> -->
    <!-- </div> -->
    <!-- <br> -->
    <!--shipping order details-->
    <div class="legend">
      <h6 class="legend-title">
        Bill of Lading Details
      </h6>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Bill of Lading Status
        </div>
        <div class="col-md-4 div-table div-table-value" :class="[payload.cancelled ? 'text-danger' : '', payload.billed ? 'text-success' : '']">
          {{ payload.cancelled ? 'Cancelled' : billOfLading.blStatus }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          House BL
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.houseBl }}
        </div>
      </div>
      <br>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Bill of Lading Number
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.billOfLadingNo }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Shipper TIN
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.shipperCodeTin }}
        </div>
      </div>
      <br>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Shipper Name
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.shipperName }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Shipper Address
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.shipperAddress }}
        </div>
      </div>
      <br>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Shipper City
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.shipperCity }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Shipper Zip Code
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.shipperZipCode }}
        </div>
      </div>
      <br>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Shipper Country
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.shipperCountry }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Shipper Contact Person
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.shipperContactPerson }}
        </div>
      </div>
      <br>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title ">
          Shipper Phone Number
        </div>
        <div class="col-md-4 div-table div-table-value ">
          {{ billOfLading.shipperTel }}
        </div>

        <div class="col-md-2 div-table div-table-title ">
          Shipper Email
        </div>
        <div class="col-md-4 div-table div-table-value ">
          {{ billOfLading.email }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Consignee Name
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.consigneeName }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Consignee Tin
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.consigneeCodeTin }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title ">
          Consignee Name
        </div>
        <div class="col-md-4 div-table div-table-value ">
          {{ billOfLading.consigneeName }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Consignee Address
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.consigneeAddress }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Consignee Zip Code
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.shipperZipCode }}
        </div>

        <div class="col-md-2 div-table div-table-title">
          Consignee Country
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.consigneeCountry }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title ">
          Consignee Phone Number
        </div>
        <div class="col-md-4 div-table div-table-value ">
          {{ billOfLading.consigneeTel }}
        </div>

        <div class="col-md-2 div-table div-table-title ">
          Consignee Email
        </div>
        <div class="col-md-4 div-table div-table-value ">
          {{ billOfLading.consigneeEmail }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Consignee City
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.consigneeCity }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Consignee Contact Person
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.shipperContactPerson }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title ">
          Notify Name
        </div>
        <div class="col-md-4 div-table div-table-value ">
          {{ billOfLading.notiyName }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Notify City
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.notiyCity }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Notify Zip Code
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.notiyCodeTin }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Notify Country
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.notiyCountry }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Notify Contact Person
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ billOfLading.notiyContactPerson }}
        </div>
        <div class="col-md-2 div-table div-table-title ">
          Notify Phone Number
        </div>
        <div class="col-md-4 div-table div-table-value ">
          {{ billOfLading.notiyTel }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title ">
          Notify Email
        </div>
        <div class="col-md-4 div-table div-table-value ">
          {{ billOfLading.notiyEmail }}
        </div>
        <div class="col-md-2 div-table div-table-title ">
          Created Date
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{  billOfLading.createdAt ?  dateToHuman(billOfLading.createdAt): '' }}
        </div>
      </div> 
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Notify Address
        </div>
        <div class="col-md-10 div-table div-table-value">
          {{ billOfLading.notiyAddress }}
        </div>
      </div>
      
    </div>

    <!--Vessel Details-->
    <div class="legend">
      <h6 class="legend-title">
        Vessel Details
      </h6>

      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Vessel Name
        </div>
        <div class="col-md-10 div-table div-table-value">
          {{ getVessel.vesselName }}
        </div>
      </div>

      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Voyage No.
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.voyageNo }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Vessel Type
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.vesselType }}
        </div>
      </div>

      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Carrier Code
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.carrierCode }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Arrival Port
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.arrivalPort }}
        </div>
      </div>

      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Contract Type
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.contractType }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Berth
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.berth }}
        </div>
      </div>

      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Vessel ETA
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.eta ? dateTimeToHuman(getVessel.eta): "N/A" }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Vessel ETD
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.etd ? dateTimeToHuman(getVessel.etd): "N/A" }}
        </div>
      </div>
      
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Expected  Stay Time (Hrs.)
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.stayTime }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Vessel Factor
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.factor }}
        </div>
      </div>

      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Vessel Dead Weight Tonnage
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.dwt }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Vessel Gross Weight Tonnage
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.grt }}
        </div>
      </div>

      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Vessel Net  Weight Tonnage
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.nrt }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Length Overall (metres)
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.loa }}
        </div>
      </div>

      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Vessel Draft
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.draft }}
        </div>

        <div class="col-md-2 div-table div-table-title">
          Vessel Beam
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.beam }}
        </div>
      </div>

      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Vessel ATA
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.ata ? dateTimeToHuman(getVessel.ata):'N/A' }}
        </div>

        <div class="col-md-2 div-table div-table-title">
          Vessel ATD
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ getVessel.atd ? dateTimeToHuman(getVessel.atd): 'N/A' }}
        </div>
      </div>
      <br>
    </div>

    <!--  Shipping Order Cargo-->
    <div class="legend">
      <h6 class="legend-title">
        Shipping Order Cargo
      </h6>
      <application-cargo-v2 :shipping-order-id="billOfLading.shippingOrderId.toString()" v-if="billOfLading.shippingOrderId!==undefined" :actionButton="false"/>
    </div>
    <div class="legend">
      <h6 class="legend-title">
        Containers
      </h6>
      <containers :shippingOderId="billOfLading.shippingOrderId.toString()" v-if="billOfLading.shippingOrderId!==undefined" :actionButton="false"/>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button
            class="btn btn-default btn-fill btn-space custom-btn-all"
            style="float:right;"
            @click.prevent="goBack"
          >Back
          </button>
        <button
            type="submit"
            v-if="!payload.billed && !payload.cancelled"
            class="btn btn-primary mr-2 custom-btn"
            @click="generateBill"
            data-dismiss="modal"
          >Generate Bill
          </button>
      </div>
    </div>
  </card>
  <modal v-if="isCancelModal">
      <div slot="body" class="modal-title row">
        <div class="col-md-12">
          <label>Cancel Reason</label>
        <textarea class="special-bottom form-control col-md-12" placeholder="Provide cancel reason" rows="6" type="textarea" v-model="cancelReason"/>
        <div  v-if="cancelReasonError">
            <div class="text-danger" v-if="!$v.cancelReason.required">
                This field is required
            </div>
        </div>
        </div>

        <div class="col-md-12 text-right">
          <button
              class="btn btn-default btn-fill btn-space custom-btn-all"
              style="float:right;"
              @click.prevent="closeCancelReasonForm"
            >close
            </button>
          <button
              type="submit"
              v-if="!billOfLading.billed && !payload.cancelled"
              class="btn btn-primary mr-2 custom-btn"
              @click="cancelBlCharge"
              data-dismiss="modal"
            >Submit
            </button>
        </div>

      </div>
  </modal>
  <global-modal v-if="globalLoader">
    <div slot="globalLoader">
      <div class="row text-center">
        <div class="col-md-12">Preparing requested file, Please wait</div>
        <div class="col-md-12">
          <br/>
        </div>
        <div class="col-md-12">
          <pulse-loader :color="color" :size="size"></pulse-loader>
        </div>
      </div>
    </div>
  </global-modal>

  <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer" 
  @downloadPdf="openPdfViewer"> </pdf-viewer>
</div>
</template>
<script>
  import AGENYAPI from '../../../../api/agency';
  import REVAPI from '../../../../api/rev';
  import { mapActions, mapGetters } from 'vuex';
  import Modal from '../../../../components/Inputs/Modal';
  import {ModelListSelect} from 'vue-search-select';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import Table from '../../../../components/DataTable/table';
  import ApplicationCargo from '../../../shippingAgency/application/applicationCargo';
  import CrudNotification from '../../../../components/DataTable/crudNotification';
  import {required} from "vuelidate/lib/validators";
  import Containers from "../../../shippingAgency/container/containers";
  import applicationCargoV2 from "../../../shippingAgency/application/applicationCargoV2";
  import PdfViewer from '../../../../components/pdfViewer.vue'
  import GlobalModal from "../../../../components/globalLoader"

  export default {
    name: 'billOfLadingChargesView',

    components: {
      Containers,
      ApplicationCargo,
      applicationCargoV2,
      Table,
      Modal,
      ModelListSelect,
      PulseLoader,
      CrudNotification,
      PdfViewer,
      GlobalModal
    },

    computed: {

      ...mapGetters('global', [

        'getGlobalLoader'

      ])

    },

    validations: {
      cancelReason: {required}
    },

    created () {
      this.$store.dispatch('pagenames/currentPageName', 'Shipping Order Details');
      this.getBillOfLadingById();
    },

    data () {
      return {
        billOfLading: {},
        payload: {},
        assignVessel: false,
        vesselList: [],
        selectedVessel: '',
        selectedVesselError: false,

        getVessel: {},
        isCancelModal: false,
        color: 'green',
        size: '15px',

        loading: false,
        isreject:false,
        cancelReason:"",
        cancelReasonError: false,
        isSave:true,
        showPdfViewer: false,
        globalLoader: false,
        scr: ''

      };
    },

    methods: {

      ...mapActions('global', [

        'downloadBillOfLading'

      ]),

      loadDownloadDocumentParams(extension) {

        return new Promise((resolve) => {

          this.$store.dispatch('global/requestid', this.billOfLading.id);
          this.$store.dispatch('global/fileNaming', this.billOfLading.billOfLadingNo);
          this.$store.dispatch('global/fileExtensioning', extension);

          resolve();

        })

      },

      openPdfViewer (action) {

          this.downloadPdf(action).then(() => {

              this.showPdfViewer = true

          }).catch(() => {})

      },

      closePdfViewer () {

          this.showPdfViewer = false
          this.scr = ''

      },

      downloadPdf(action) {

        return new Promise((resolve, reject) => {

          this.loadDownloadDocumentParams('pdf').then(() => {

            this.downloadBillOfLading({action}).then((response) => {

              if(response[1].status === 204) {

                this.loadNotification('warn', 4000, '',
                  'File not found, please contact system admin',
                  'response', 1000 , true  , true)

              } else {

                this.scr = response[0]
                resolve()


              }
              this.globalLoader = this.getGlobalLoader

            }).catch((error) => {

              // this.loadNotification('error', 4000, 'File Download',
              //   'File not downloaded, please check your internet connection',
              //   'response', 1000, true, true);

              this.globalLoader = this.getGlobalLoader
              reject(error)

            });

            this.globalLoader = this.getGlobalLoader

          })

        })

      },

      openCancelReasonForm () {

        this.isCancelModal = true

      },

      closeCancelReasonForm () {

        this.isCancelModal = false

      },

      cancelBlCharge () {

        if (this.$v.cancelReason.$invalid) {

            this.cancelReasonError = true

        } else {

          this.$dialog.confirm("You are about to cancel charge for <br><br>" +
          " Bill of Lading - [ " + this.billOfLading.billOfLadingNo + " ] <br>" +
          " Are you sure!.", {
          loader: true,
          html: true,
          animation: 'zoom', // Available: "zoom", "bounce", "fade"
          okText: 'Yes, proceed',
          cancelText: 'No, close'

          }).then((dialog) => {

            this.globalLoader = false

            AGENYAPI.cancelBillOfLadingCharge(this.payload.billOfLadingChargeId, this.cancelReason).then((response) => {

              this.globalLoader = false
              if(response.data.status) {

                this.loadNotification('success', 4000, '',
                'Charge cancelled successfully',
                'response', 1000 , true  , true);
                this.isCancelModal = false
                this.getBillOfLadingById()

              } else {

                this.loadNotification('error', 4000, '',
                'Charge not cancelled',
                'response', 1000 , true  , true);

              }

            }).catch(() => {

              this.globalLoader = false
              this.loadNotification('error', 4000, '',
              'Charge not cancelled',
              'response', 1000 , true  , true);
              
            })

            dialog.close();

          });

        }

      },

      generateBill(){

        this.$dialog.confirm("You are about to generate bill for <br><br>" +
        " Bill of Lading - [ " + this.billOfLading.billOfLadingNo + " ] <br>" +
        " Are you sure!.", {
        loader: true,
        html: true,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        okText: 'Yes, proceed',
        cancelText: 'No, close'

        }).then((dialog) => {

          this.loading = true;
          
          REVAPI.generateBilOfLadingBill(this.payload.billOfLadingChargeId, null).then((response)=>{

            this.loading = false;
            if(response.data.status) {

              this.loadNotification('success', 4000, '',
              'Bill Generated',
              'response', 1000 , true  , true);
              this.getBillOfLadingById()

            } else {

              this.loadNotification('error', 4000, '',
              'Bill not generated',
              'response', 1000 , true  , true);

            }

          }).catch(() => {

            this.loading = false;
            this.loadNotification('error', 4000, '',
              'Bill not Generated',
              'response', 1000 , true  , true);
          })

          dialog.close();

        });

      },

      goBack() {

        if (window.$cookies.isKey('browser-local-cashed-url-back')) {

          this.$router.push(window.$cookies.get('browser-local-cashed-url-back'))

          window.$cookies.remove('browser-local-cashed-url-back')
        }

      },

      getBillOfLadingById () {

        this.loading = true;
        AGENYAPI.getBillOfLadingCharge(this.$route.params.billChargeId).then((response) => {
          this.loading=false;
          this.payload = response.data.data
          this.billOfLading = response.data.data.billOfLading;
          this.getVesselParticulars();

          if(this.billOfLading.vesselParticular !== null){

            this.getVessel=this.shippingOrder.vessel;

          }
        }).catch(() => {

          this.loading = false;

        });

      },

      viewOrderCargo (shippingOrderId) {
        this.$router.push({ name: 'shippingorderCargo', params: { shippingOrderId } });
      },


      goToShippingOrderAttachments (shippingOrderId) {
        this.$router.push({ name: 'shippingOrderAttachments', params: { shippingOrderId } });
      },

      getVesselParticulars () {
        AGENYAPI.listAllVesselParticularNoPaging().then((response) => {
          this.vesselList = response.data.data;
          //this.getVesselParticularById();
        }).catch((error) => {
        });
      },

    },
  }
</script>

<style lang="scss">
  .menu{
    .item{
      font-weight: bold;
    }
  }
</style>

<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified li {
      float: left;
  }

  ul.nav-justified li a:hover {
      color: #d59a18;
  }

  ul.nav-justified li a {
      display: block;
      text-align: center;
      padding: 16px 20px 10px 21px;
      text-decoration: none;
      color: blue;
      font-weight: bold;
      text-transform: uppercase;
  }

  .custom-btn {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      padding: 5px 9px 5px 9px !important;
      font-size: 14px !important;
  }

  .btn-linen:hover {
    border-color: #D2691E;
    color: #D2691E;
    background-color: #ffffff;
  }

  .btn-linen {
    border-color: rgba(210, 105, 30, .5);
    color: rgba(210, 105, 30, .5);
  }

  .btn-linen-fill {
      background-color: rgb(210, 105, 30);
      opacity: 1;
      color: #FFFFFF;
  }

  .btn-purple:hover {
    border-color: #800080;
    color: #800080;
    background-color: #ffffff;
  }

  .btn-purple {
      border-color: #9932cc;
      color: #9932cc;
  }

  .btn-purple-fill {
      background-color: #9932cc;
      opacity: 1;
      color: #FFFFFF;
  }
  .btn-danger-custom:hover {
        border-color: red;
        color: red;
        background-color: #ffffff;
    }

    .btn-danger-custom {
        border-color: rgba(255, 0, 0, .5);
        color: rgba(255, 0, 0, .5);
    }

    .btn-danger-custom-fill {
        background-color: rgba(255, 0, 0, .8);
        opacity: 1;
        color: #FFFFFF;
    }
  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .div-table-value{
    border: 1px solid #DCDCDC;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  h3,h4 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid green;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  h3 span,h4 span {
    background:#fff;
    padding:0 10px;
  }

</style>
