<template>
  <div>
    <modal-edit-plan v-if="showAddDisbursementEstimate">
      <div slot="header" class="modal-title">
        ADD INVOICE
      </div>
      <div slot="body">
        <card>
          <div class="row">
            <div class="col-md-12">{{ addedServiceCategoriesAndItemsForDisplay.length > 0 ?
              'ADDED DISBURSMENT SERVICES FOR SUBMISSION' : 'ADDED DISBURSMENT SERVICES WILL APPEAR IN THE TABLE BELOW' }}
            </div>
            <div class="col-md-12">
              <hr/>
            </div>
            <div class="col-md-12">
              <data-table
                :tableHeaders="tableHeaders"
                :tableData="addedServiceCategoriesAndItemsForDisplaySorted"
                :tableDataKeys="tableDataKeys"
                :myFirstLoader="myFirstLoader"
                :noDataFound="noDataFound"
                :hasFilter="false"
                :hasManageButton="false"
                :hasPagination="false"
                :isPageable="false"
                :hasDeleteIconButton="true"
                :permissions="[ {key: 'searchPermission', value: 'DISBURSEMENT_INVOICE_ADD_POST'} ]"
                @removeItemFromList="removeItem">
              </data-table>
            </div>
            <div class="col-md-12 text-right" v-if="addedServiceCategoriesAndItemsForDisplay.length > 0">
              <div class="row">
                <div class="col-md-12">
                  <hr/>
                </div>
                <div class="col-md-11 text-right pt-3">
                  <pulse-loader :loading="savingSubDisbursmentAccountEstimateLoader" :color="color" :size="size"/>
                </div>
                <div class="col-md-1 pr-3">
                  <button
                    v-if="finalUserPermissions.includes('DISBURSEMENT_INVOICE_ADD-ALL_POST')"
                    @click.prevent="saveDisbursementEstimate"
                    class="btn btn-primary btn-space custom-btn"
                    :disabled="savingSubDisbursmentAccountEstimateLoader"
                    data-dismiss="modal">
                    Save
                  </button>
                </div>
              </div>
              <hr/>
            </div>
          </div>
        </card>
        <div class="row">
          <div class="col-md-12">
            <label v-if="disbursementServiceCategoryLoader">
              <pulse-loader :color="color" :size="size" class="float-left mr-4 pt-1"/>
              Loading disbursement service categories, please wait</label>
            <label v-if="!disbursementServiceCategoryLoader">Select Disbursement Service Category </label>
            <list-select
              :list="disbursementServiceCategories"
              class="form-control"
              :isDisabled="disbursementServiceCategoryLoader"
              :selected-item="disbursementServiceCategory"
              :custom-text="customServiceNameAndCode"
              option-value="serviceCategoryId"
              placeholder="Select Disbursement Service Category"
              @select="populateDisbursmentServiceCategory"
            />
            <div v-if="!$v.disbursementServiceCategory.required && disbursementCategoryError" class="text-danger"> This
              field is required
            </div>
          </div>
        </div>
        <br/>
        <div class="row" v-if="Object.keys(disbursementServiceCategory).length > 0">
          <div class="col-md-12">
            <label v-if="disbursementServiceCategoryItemLoader">
              <pulse-loader :color="color" :size="size" class="float-left mr-4 pt-1"/>
              Loading disbursement service category items, please wait</label>
            <label v-if="!disbursementServiceCategoryItemLoader">Select Disbursement Service Category Items </label>
            <list-select
              :list="disbursementServiceItems"
              class="form-control"
              :isDisabled="disbursementServiceCategoryItemLoader"
              :selected-item="disbursementServiceItem"
              option-text="name"
              option-value="serviceItemId"
              placeholder="Disbursement Service Category Items"
              @select="populateDisbursmentServiceItem"
            />
            <div v-if="!$v.disbursementServiceItem.required && disbursementCategoryError" class="text-danger"> This
              field is required
            </div>
          </div>
          <!-- FOR SHIPPING FEE START -->

          <!-- <div class="col-md-4" v-if="disbursementServiceItem.hasTariff">
              <base-input
                  type="text"
                  label="Rate Per"
                  placeholder="Rate Per"
                  v-model="ratePer"
                  class="special-bottom"
                  :disabled="Object.keys(disbursementServiceItem).length === 0"
              />
              <div v-if="ratePerError">
                  <div class="text-danger" v-if="!$v.ratePer.required">
                  This field is required
                  </div>
              </div>
          </div>
          <div class="col-md-4" v-if="disbursementServiceItem.hasTariff">
              <base-input
                  type="text"
                  label="Rate Value"
                  placeholder="Rate Value"
                  v-model="rateValue"
                  class="special-bottom"
                  @input="commafyThisAmountWithDecimal"
                  :disabled="Object.keys(disbursementServiceItem).length === 0"
              />
              <div v-if="rateValueError">
                  <div class="text-danger" v-if="!$v.rateValue.required">
                  This field is required
                  </div>
              </div>
          </div> -->
          <div class="col-md-6" v-if="disbursementServiceItem.hasTariff">
              <label>Product</label>
              <select class="form-control" v-model="shipFeeProductId" @change="retrieveRate"
                  :disabled="Object.keys(disbursementServiceItem).length === 0">
                  <option value="" selected>Select Product</option>
                  <option v-for="(product, product_index) in products" :key="product_index" :value="product.shipFeeProductId">{{ product.name }}</option>
              </select>
              <div v-if="shipFeeProductIdError">
              <div class="text-danger" v-if="!$v.shipFeeProductId.required">
              This field is required
              </div>
              </div>
          </div>
          <div class="col-md-6" v-if="disbursementServiceItem.hasTariff">
              <label>Route</label>
              <select class="form-control" v-model="shipFeeRouteId"  @change="retrieveRate"
                  :disabled="Object.keys(disbursementServiceItem).length === 0">
                  <option value="" selected>Select route</option>
                  <option v-for="(route, route_index) in routes" :key="route_index" :value="route.shipFeeRouteId">{{ route.name }} ( {{ route.operationType }} )</option>
              </select>
              <div v-if="shipFeeRouteIdError">
              <div class="text-danger" v-if="!$v.shipFeeRouteId.required">
              This field is required
              </div>
              </div>
          </div>
          <div class="col-md-12 text-center mt-4" v-if="retrievingRateLoader" style="background-color: rgba(220, 220, 220, .5);">
            <pulse-loader :color="color" :size="size" class="float-center mr-4 pt-1 ml-4"/>Retrieving rate, please wait
          </div>

          <!-- FOR SHIPPING FEE END -->
          <div class="col-md-4">
            <base-input
              v-model="itemsFactor"
              class="special-bottom"
              label="Factor/MTS/Amount"
              placeholder="Factor/MTS/Amount"
              type="number"
              :disabled="Object.keys(disbursementServiceItem).length === 0"
            />
            <div v-if="!$v.itemsFactor.required && itemsFactorError" class="text-danger"> This field is required</div>

          </div>
          <div  class="col-md-4">
            <base-input
              v-model="itemsQuantity"
              class="special-bottom"
              label="Quantity"
              placeholder="Quantity"
              type="number"
              :disabled="Object.keys(disbursementServiceItem).length === 0"
            />
            <div v-if="!$v.itemsQuantity.required && itemsQuantityError" class="text-danger"> This field is required
            </div>
          </div>
          <div class="col-md-4">
            <base-input
              v-model="itemsRate"
              class="special-bottom"
              label="Rate"
              placeholder="Rate"
              step="0.001"
              type="number"
              :disabled="Object.keys(disbursementServiceItem).length === 0"
              readonly
            />
            <div v-if="!$v.itemsRate.required && itemsRateError" class="text-danger"> This field is required</div>

          </div>

        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <hr/>
              </div>
              <div class="col-md-8 text-right pt-3">
              </div>
              <div class="col-md-4 pr-3">
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info custom-btn-all float-right"
                  :disabled="addingToListAboveLoader || savingSubDisbursmentAccountEstimateLoader || retrievingRateLoader"
                  @click.prevent="closeModal"
                >
                  Close
                </button>
                <button
                  type="button"
                  v-if="Object.keys(disbursementServiceCategory).length > 0"
                  class="btn btn-fill btn-outline-info custom-btn-all float-right mr-2"
                  :disabled="addingToListAboveLoader || savingSubDisbursmentAccountEstimateLoader || retrievingRateLoader"
                  @click.prevent="clearForm"
                >
                  Hide form
                </button>
                <button
                  @click.prevent="addDisbursmentServiceCategoryItemToTableAbove"
                  v-if="Object.keys(disbursementServiceCategory).length > 0"
                  class="btn btn-primary btn-space custom-btn float-right mr-2"
                  :disabled="addingToListAboveLoader || savingSubDisbursmentAccountEstimateLoader || retrievingRateLoader"
                  data-dismiss="modal">
                  Add to list above
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal-edit-plan>
  </div>
</template>

<script>

  import API from '../../../../api/agency'
  import {required} from 'vuelidate/lib/validators'
  import {ListSelect} from 'vue-search-select'
  import {mapActions, mapGetters} from 'vuex'
  import PulseLoader from 'vue-spinner/src/PulseLoader'
  import ModalEditPlan from '../../../../components/Inputs/ModalEditPlan.vue'


  export default {

    name: 'createEstimate',

    components: {

      ListSelect,
      PulseLoader,
      ModalEditPlan,
      DataTable: () => import('../../../../components/DataTable/dataTable')

    },

    props: {

      showAddDisbursementEstimate: {

        type: Boolean,
        default: () => false

      },

      subDisbursementAccountId: {

        type: Number,
        default: () => 0

      }

    },

    data() {

      return {

        addedServiceCategoriesAndItemsForDisplay: [],
        finalUserPermissions: [],
        addedServiceCategoriesAndItemsForDisplaySorted: [],
        addedServiceCategoriesAndItemsForSave: [],
        disbursementServiceCategories: [],
        disbursementServiceItems: [],
        disbursementServiceCategory: {},
        disbursementServiceItem: {},
        codeAndNameAndDesc: '',
        itemsRate: '',
        itemsFactor: '',
        itemsQuantity: '',
        disbursementCategoryError: false,
        disbursementItemError: false,
        itemsRateError: false,
        itemsFactorError: false,
        itemsQuantityError: false,
        disbursementServiceCategoryLoader: false,
        disbursementServiceCategoryItemLoader: false,
        addingToListAboveLoader: false,
        savingSubDisbursmentAccountEstimateLoader: false,

        color: 'green',
        size: '11px',
        loading: false,

        myFirstLoader: false,
        noDataFound: false,
        requests: [],
        tableHeaders: ['Service Name', 'Item Name', 'Quantity', 'Factor', 'Rate', 'VAT Applicable'],
        tableDataKeys: ['categoryName', 'itemName', 'quantity', 'factor', 'rate', 'vatApplicable'],
        products: [],
        routes: [],
        ratePer: '',
        rateValue: '',
        shipFeeProductId: '',
        shipFeeRouteId: '',
        ratePerError: false,
        rateValueError: false,
        shipFeeProductIdError: false,
        shipFeeRouteIdError: false,
        retrievingRateLoader: false

      }

    },

    created() {

      this.$store.dispatch("pagenames/currentPageName", "CFA Applications Bills")
      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
      this.loadAllDisbursementServiceCategories()
      this.loadShippingFeeRoutes()
      this.loadShippingFeeProducts()

    },

    validations: {

      disbursementServiceCategory: {required},
      disbursementServiceItem: {required},
      itemsRate: {required},
      itemsFactor: {required},
      itemsQuantity: {required},

      ratePer: { required },
      rateValue: { required },
      shipFeeProductId: { required },
      shipFeeRouteId: { required },

    },

    computed: {

      ...mapGetters('global', [

        'getAllDisbursementServiceCategories',
        'getAllDisbursementServiceCategoryItems',
        'getDisbursementServiceCategoryLoader',
        'getDisbursementServiceCategoryItemLoader'

      ]),

      ...mapGetters('subdisbursment', [

        'getMyLoader'

      ])

    },


    methods: {

      ...mapActions('global', [

        'loadDisbursementServiceCategories',
        'loadDisbursementServiceCategoryItems'

      ]),

      ...mapActions('subdisbursment', [

        'saveSubDisbursmentAccountEstimations'

      ]),

      customServiceNameAndCode(item) {

        return `name: [ ${item.name} ] - code: [ ${item.code} ] - description:  [ ${item.description} ]`

      },

      commafyThisAmountWithDecimal () {

        this.rateValue = this.commafyAmountWithDecimal(this.rateValue);

      },

      loadShippingFeeProducts() {

          this.myFirstLoader = true

          this.products = []

          API.getShippingFeeProducts().then((response) => {

              if(response.status) {

                  this.myFirstLoader = false

                  if(response.data.data.length > 0) {

                      this.products = response.data.data

                  } else {


                  }

              }

          }).catch(() => {

              this.myFirstLoader = false

          });

      },
      loadShippingFeeRoutes() {

          this.myFirstLoader = true

          this.routes = []

          API.getShippingFeeRoutes().then((response) => {

              if(response.status) {

                  this.myFirstLoader = false

                  if(response.data.data.length > 0) {

                      this.routes = response.data.data

                  } else {


                  }

              }

          }).catch(() => {

              this.myFirstLoader = false

          });

      },

      sortingData(data) {

        data.sort(function (a, b) {

          let x = a.categoryName.toLowerCase();
          let y = b.categoryName.toLowerCase();

          if (x < y) {
            return -1;
          }

          if (x > y) {
            return 1;
          }

          return 0;

        });

        this.addedServiceCategoriesAndItemsForDisplaySorted = data

      },

      loadAllDisbursementServiceCategories() {

        this.loadDisbursementServiceCategories({}).then(() => {

          this.disbursementServiceCategories = this.getAllDisbursementServiceCategories;
          this.disbursementServiceCategoryLoader = this.getDisbursementServiceCategoryLoader

        }).catch(() => {
        });

        this.disbursementServiceCategoryLoader = this.getDisbursementServiceCategoryLoader

      },

      populateDisbursmentServiceCategory(object) {

        this.disbursementServiceCategory = object

        this.$store.dispatch("global/disbursmentServiceCategoryIding", object.serviceCategoryId).then(() => {

          this.loadDisbursementServiceCategoryItems({}).then(() => {

            this.disbursementServiceItems = this.getAllDisbursementServiceCategoryItems;
            this.disbursementServiceCategoryItemLoader = this.getDisbursementServiceCategoryItemLoader

          }).catch(() => {
          });

          this.disbursementServiceCategoryItemLoader = this.getDisbursementServiceCategoryItemLoader

        }).catch(() => {
        });

      },

      populateDisbursmentServiceItem(object) {

        this.disbursementServiceItem = object
        this.disbursementServiceItem.hasTariff ? this.itemsRate = '' : this.itemsRate = object.rate

      },

      retrieveRate () {

        let x = 0

          if(this.$v.shipFeeProductId.$invalid || this.$v.shipFeeRouteId.$invalid) {

            if (this.$v.shipFeeProductId.$invalid) {

              this.shipFeeProductIdError = true

            }

            if (this.$v.shipFeeRouteId.$invalid) {

              this.shipFeeRouteIdError = true

            }

          } else {

              this.retrievingRateLoader = true

              API.getShippingFeeRate(this.shipFeeProductId, this.shipFeeRouteId).then((response) => {

                this.retrievingRateLoader = false

                if(response.data.status) {

                  this.itemsRate = response.data.data.rateValue

                } else {

                  this.loadNotification('error', 4000, 'Rate Retrieving (click to close)', 'No rate found, please add in configuration', 'response', 1000, true, true)
                  this.itemsRate = ''
                }

            }).catch(() => {

                this.retrievingRateLoader = false
                this.loadNotification('error', 4000, 'Rate Retrieving (click to close)', 'No rate found, please contact system admin', 'response', 1000, true, true)
                this.itemsRate = ''

            });

        }

      },

      addDisbursmentServiceCategoryItemToTableAbove() {

        if (this.$v.disbursementServiceItem.$invalid || this.$v.itemsFactor.$invalid || this.$v.itemsQuantity.$invalid | this.$v.itemsRate.$invalid) {

          if (this.$v.disbursementServiceItem.$invalid) {

            this.disbursementCategoryError = true

          }

          if (this.$v.itemsFactor.$invalid) {

            this.itemsFactorError = true

          }

          if (this.$v.itemsQuantity.$invalid) {

            this.itemsQuantityError = true

          }

          if (this.$v.itemsRate.$invalid) {

            this.itemsRateError = true

          }

        } else {

            let addedServiceCategoriesAndItemsObjectForDisplay = {

              categoryName: this.disbursementServiceCategory.name,
              serviceCategoryId: this.disbursementServiceCategory.serviceCategoryId,
              itemName: this.disbursementServiceItem.name,
              serviceItemId: this.disbursementServiceItem.serviceItemId,
              quantity: this.itemsQuantity,
              factor: this.itemsFactor,
              rate: this.itemsRate,
              vatApplicable: this.disbursementServiceItem.vatApplicable ? 'YES' : 'NO',
              vatApplicableSave: this.disbursementServiceItem.vatApplicable

            };

            let flag = 1;

            if (this.addedServiceCategoriesAndItemsForDisplay.length > 0) {

              for (let x = 0; x < this.addedServiceCategoriesAndItemsForDisplay.length; x++) {

                if (this.addedServiceCategoriesAndItemsForDisplay[x].serviceItemId === addedServiceCategoriesAndItemsObjectForDisplay.serviceItemId) {

                  this.loadNotification('error', 4000, 'Disbursment Estimation', ' Item [ ' + addedServiceCategoriesAndItemsObjectForDisplay.itemName +
                    ' ] for service category [ ' + addedServiceCategoriesAndItemsObjectForDisplay.categoryName + ' ] already exists in the list above',
                    'response', 1000, true, true);
                  flag = 0;
                  addedServiceCategoriesAndItemsObjectForDisplay = {};
                  this.disbursementServiceItem = {};
                  this.itemsQuantity = '';
                  this.itemsFactor = '';
                  this.itemsRate = '';
                  this.disbursementCategoryError = false;
                  this.itemsFactorError = false;
                  this.itemsQuantityError = false;
                  break;

                } else {

                  if (typeof this.addedServiceCategoriesAndItemsForSave[x] !== 'undefined') {

                    if (this.addedServiceCategoriesAndItemsForSave[x].serviceCategoryId == addedServiceCategoriesAndItemsObjectForDisplay.serviceCategoryId) {

                      let addedServiceCategoryItemsObjectForSave = {

                        factor: addedServiceCategoriesAndItemsObjectForDisplay.factor,
                        itemName: addedServiceCategoriesAndItemsObjectForDisplay.itemName,
                        quantity: addedServiceCategoriesAndItemsObjectForDisplay.quantity,
                        rate: addedServiceCategoriesAndItemsObjectForDisplay.rate,
                        serviceItemId: addedServiceCategoriesAndItemsObjectForDisplay.serviceItemId,
                        vatApplicable: addedServiceCategoriesAndItemsObjectForDisplay.vatApplicableSave

                      }

                      this.addedServiceCategoriesAndItemsForSave[x].invoiceItem.push(addedServiceCategoryItemsObjectForSave)

                    }

                    if (this.addedServiceCategoriesAndItemsForSave[x].serviceCategoryId !== addedServiceCategoriesAndItemsObjectForDisplay.serviceCategoryId
                      && !this.addedServiceCategoriesAndItemsForSave.some(object => object.serviceCategoryId === addedServiceCategoriesAndItemsObjectForDisplay.serviceCategoryId)) {


                      let addedServiceCategoryItemsObjectForSave = {

                        factor: parseInt(addedServiceCategoriesAndItemsObjectForDisplay.factor),
                        itemName: addedServiceCategoriesAndItemsObjectForDisplay.itemName,
                        quantity: parseInt(addedServiceCategoriesAndItemsObjectForDisplay.quantity),
                        rate: addedServiceCategoriesAndItemsObjectForDisplay.rate,
                        serviceItemId: addedServiceCategoriesAndItemsObjectForDisplay.serviceItemId,
                        vatApplicable: addedServiceCategoriesAndItemsObjectForDisplay.vatApplicableSave

                      }

                      let addedServiceCategoryItemsArrayForSave = []
                      addedServiceCategoryItemsArrayForSave.push(addedServiceCategoryItemsObjectForSave)

                      let addedServiceCategoriesObjectForSave = {

                        categoryName: addedServiceCategoriesAndItemsObjectForDisplay.categoryName,
                        serviceCategoryId: addedServiceCategoriesAndItemsObjectForDisplay.serviceCategoryId,
                        invoiceItem: addedServiceCategoryItemsArrayForSave

                      }

                      this.addedServiceCategoriesAndItemsForSave.push(addedServiceCategoriesObjectForSave)
                      break

                    }

                  }

                }

              }

              if (Object.keys(addedServiceCategoriesAndItemsObjectForDisplay).length > 0 && flag !== 0) {

                this.addedServiceCategoriesAndItemsForDisplay.push(addedServiceCategoriesAndItemsObjectForDisplay)
                flag = 1
                addedServiceCategoriesAndItemsObjectForDisplay = {}
                this.disbursementServiceItem = {}
                this.itemsQuantity = ''
                this.itemsFactor = ''
                this.itemsRate = ''
                this.disbursementCategoryError = false
                this.itemsFactorError = false
                this.itemsQuantityError = false

              }

            } else {

              let addedServiceCategoryItemsObjectForSave = {

                factor: parseInt(addedServiceCategoriesAndItemsObjectForDisplay.factor),
                itemName: addedServiceCategoriesAndItemsObjectForDisplay.itemName,
                quantity: parseInt(addedServiceCategoriesAndItemsObjectForDisplay.quantity),
                rate: addedServiceCategoriesAndItemsObjectForDisplay.rate,
                serviceItemId: addedServiceCategoriesAndItemsObjectForDisplay.serviceItemId,
                vatApplicable: addedServiceCategoriesAndItemsObjectForDisplay.vatApplicableSave

              }

              let addedServiceCategoryItemsArrayForSave = []
              addedServiceCategoryItemsArrayForSave.push(addedServiceCategoryItemsObjectForSave)

              let addedServiceCategoriesObjectForSave = {

                categoryName: addedServiceCategoriesAndItemsObjectForDisplay.categoryName,
                serviceCategoryId: addedServiceCategoriesAndItemsObjectForDisplay.serviceCategoryId,
                invoiceItem: addedServiceCategoryItemsArrayForSave

              }

              this.addedServiceCategoriesAndItemsForSave.push(addedServiceCategoriesObjectForSave)

              this.addedServiceCategoriesAndItemsForDisplay.push(addedServiceCategoriesAndItemsObjectForDisplay)
              addedServiceCategoriesAndItemsObjectForDisplay = {}
              this.disbursementServiceItem = {}
              this.itemsQuantity = ''
              this.itemsFactor = ''
              this.itemsRate = ''
              this.disbursementCategoryError = false
              this.itemsFactorError = false
              this.itemsQuantityError = false

            }

            this.sortingData(this.addedServiceCategoriesAndItemsForDisplay)

        }

      },

      removeItem(item) {

        this.addedServiceCategoriesAndItemsForDisplay.splice(this.addedServiceCategoriesAndItemsForDisplay.indexOf(item), 1)
        for (let x = 0; x < this.addedServiceCategoriesAndItemsForSave.length; x++) {

          if (this.addedServiceCategoriesAndItemsForSave[x].serviceCategoryId === item.serviceCategoryId) {

            // this.addedServiceCategoriesAndItemsForSave.splice(this.addedServiceCategoriesAndItemsForSave.indexOf(this.addedServiceCategoriesAndItemsForSave[x]), 1)
            // this.addedServiceCategoriesAndItemsForSave.splice(this.addedServiceCategoriesAndItemsForSave.indexOf(this.addedServiceCategoriesAndItemsForSave[x]), 1)
            for (let y = 0; y < this.addedServiceCategoriesAndItemsForSave[x].invoiceItem.length; y++) {

              if (this.addedServiceCategoriesAndItemsForSave[x].invoiceItem[y].serviceItemId === item.serviceItemId) {

                this.addedServiceCategoriesAndItemsForSave[x].invoiceItem.splice(this.addedServiceCategoriesAndItemsForSave[x].invoiceItem.indexOf(this.addedServiceCategoriesAndItemsForSave[x].invoiceItem[y]), 1)
                break

              }

            }
            break

          }
        }
      },

      saveDisbursementEstimate() {

        let saveObject = {

          object: this.addedServiceCategoriesAndItemsForSave,
          subDisbursementAccountId: this.subDisbursementAccountId

        };

        this.$store.dispatch("subdisbursment/savingDetails", saveObject).then(() => {

          this.saveSubDisbursmentAccountEstimations({}).then((response) => {

            if (response.data.status) {

              this.loadNotification('success', 4000, 'Sub Disbursment Account Estimation', ' Estimation has been successfully saved',
                'response', 1000, true, true)
              this.loadSubDisbursementDetails()
              this.closeModal()

            } else {

              this.loadNotification('error', 4000, 'Sub Disbursment Account Estimation', ' Estimation not saved',
                'response', 1000, true, true)

            }

            this.savingSubDisbursmentAccountEstimateLoader = this.getMyLoader

          }).catch(() => {
          });

          this.savingSubDisbursmentAccountEstimateLoader = this.getMyLoader

        }).catch(() => {
        });

      },

      clearForm() {

        this.disbursementServiceItem = {}
        this.disbursementServiceCategory = {}
        this.itemsQuantity = ''
        this.itemsFactor = ''
        this.itemsRate = ''
        this.disbursementCategoryError = false
        this.itemsFactorError = false
        this.itemsQuantityError = false

      },

      closeModal() {

        this.addedServiceCategoriesAndItemsForDisplay = []
        this.addedServiceCategoriesAndItemsForSave = []
        this.clearForm()
        return this.$emit('closeModal')

      },

      loadSubDisbursementDetails() {

        return this.$emit('loadSubDisbursementDetails')

      }

    }

  }

</script>

<style lang="scss" scoped>
  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
  }

  .custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
  }
</style>
