<template>
  <div class="content">
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close close-custom"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close close-custom"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <br>
      <div class="text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="myloader"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Status List
          </div>
        </div>
        <div class="col-md-6 text-right">
          <div
            class="all-fa-icons btn-space"
            v-if="finalUserPermissions.includes('STATUS_CONFIG_POST')"
            @click.prevent="openModal(null , null , null , actionButton = 'AddModal')"
          >
            <font-awesome-icon icon="plus" />
          </div>
        </div>
      </div>
      <hr>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Status Description
            </th>
            <th scope="col">
              Status
            </th>
            <th
              scope="col"
              class="special-limit"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in getStatus"
            :key="list.statusId"
          >
            <th scope="row">
              {{ index + 1 }}
            </th>
            <td>
              {{ list.statusDescription }}
            </td>
            <td :class="[ list.isActive ? 'text-success' : 'text-danger' ]">
              {{ list.isActive ? "Active" : "Not Active" }}
            </td>
            <td class="special-limit">
              <div
                class="all-fa-icons fa-icons-space edit-fa-icon"
                v-if="finalUserPermissions.includes('STATUS_CONFIG_PUT')"
                @click.prevent="openModal(list.statusId , list.statusDescription , list.isActive ,
                                          actionButton = 'EditModal')"
                title="edit"
              >
                <font-awesome-icon icon="edit" />
              </div>

              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                v-if="finalUserPermissions.includes('STATUS_CONFIG_{STATUSID}_GET')"
                @click.prevent="openModal(list.statusId , null , null , actionButton = 'ShowModal')"
                title="view"
              >
                <font-awesome-icon icon="eye" />
              </div>

              <div
                class="all-fa-icons fa-icons-space delete-fa-icon"
                v-if="finalUserPermissions.includes('STATUS_CONFIG_{STATUSID}_DELETE')"
                @click.prevent="deleteStatus(list.statusId , list.statusDescription , list.isActive)"
                title="delete"
              >
                <font-awesome-icon icon="trash-alt" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Status Description<b>{{ statusDescription }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            Register Status
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            Details for Status: <b>{{ data.statusDescription }}</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--     EDIT FORM       -->

          <form
            method="POST"
            @submit.prevent="editStatus"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-12">
                <base-input
                  type="text"
                  label="Status Description"
                  placeholder="Status Description"
                  v-model="statusDescription"
                  value="statusDescription"
                  class="special-bottom"
                />
                <div v-if="statusDescriptionError">
                  <div
                    class="error"
                    v-if="!$v.statusDescription.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="isActive"
                      value="false"
                      name="isActive"
                      v-model="isActive"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="isActive"
                    >Not Active</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="isActive2"
                      value="true"
                      name="isActive"
                      v-model="isActive"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="isActive2"
                    >Active</label>
                  </div>
                  <div class="red" />
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveStatus"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-12">
                <base-input
                  type="text"
                  label="Status Description"
                  placeholder="Status Description"
                  v-model="status.statusDescription"
                  class="special-bottom"
                />
                <div v-if="statusDescriptionError">
                  <div
                    class="error"
                    v-if="!$v.status.statusDescription.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="isActive"
                    value="0"
                    name="isActive"
                    v-model="status.isActive"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="isActive"
                  >Not Active</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="isActive2"
                    value="1"
                    name="isActive"
                    v-model="status.isActive"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="isActive2"
                  >Active</label>
                </div>
                <div class="red" />
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Status Description
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.statusDescription }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row strip-row">
              <div class="col-md-6 text-bold">
                Status
              </div>
              <div class="col-md-6 text-value">
                <b>{{ data.isActive ? "Active" : "Not Active" }}</b>
              </div>
            </div>

            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { mapGetters } from 'vuex'
import Modal from '../../../components/Inputs/Modal'
import { required } from 'vuelidate/lib/validators'

export default {

  name: 'Status',

  components: {

    PulseLoader,
    Modal

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Status List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadStatus()
  },

  computed: {

    ...mapGetters('status', [

      'getValuesBeforeEdit',
      'getNameBeforeDelete'

    ])

  },

  data () {
    return {

      getStatus: [],
      finalUserPermissions: [],
      array: [],
      loader: false,
      color: 'green',
      size: '15px',
      loading: true,

      data: {},

      SuccessMessage: '',
      ErrorMessage: '',

      statusDescription: '',
      isActive: '',
      statusId: '',

      error: '',
      NameBeforeSubmit: '',

      status: {
        statusDescription: '',
        isActive: ''
      },

      SuccessAlert: false,
      ErrorAlert: false,
      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,

      statusDescriptionError: false,
      isActiveError: false
    }
  },

  validations: {

    status: {
      statusDescription: { required },
      isActive: { required }
    },

    statusDescription: { required },
    isActive: { required }
  },

  methods: {

    loadStatus () {
      this.myloader = true

      API.getStatus().then(
        response => {
          if (response.data.status) {
            this.getStatus = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not added ' + e
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadSingleStatus (data) {
      API.getIndividualStatus(data).then(
        response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'Data not found'
          }
        }
      ).catch(e => {
        // this.state.errors.push(e)
      })
    },

    saveStatus () {
      if (this.$v.status.statusDescription.$invalid || this.$v.status.isActive.$invalid) {
        if (this.$v.status.statusDescription.$invalid) {
          this.statusDescriptionError = true
        }
        if (this.$v.status.isActive.$invalid) {
          this.isActiveError = true
        }
      } else {
        this.editMyLoader = true

        // setTimeout(() => {

        API.postStatus(JSON.stringify(this.status)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = ' [' + response.data.data.statusDescription + '] Status Description saved'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadStatus()
              this.editMyLoader = false
              this.status.statusDescription = ''
              this.status.isActive = ''
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ' Record not added'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.status.statusDescription = ''
          this.status.isActive = ''
          this.closeModal()
        })

        // }, 2000);
      }
    },

    deleteStatus (data, name) {
      this.$store.dispatch('status/nameBeforeDelete', name)

      this.$dialog.confirm('If you delete Status [ ' + name + " ] , it'll be gone forever.", {

        loader: true
      })
        .then((dialog) => {
          // setTimeout(() => {

          API.deleteStatus(data).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ', Status  [ ' + name + ' ] has been deleted'
                this.$store.dispatch('status/nameBeforeDelete', '')
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                this.loadStatus()
              } else {

              }
            }
          ).catch(e => {
            this.ErrorMessage = ' Record not deleted'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
          })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    editStatus () {
      if (this.$v.statusDescription.$invalid || this.$v.isActive.$invalid) {
        if (this.$v.statusDescription.$invalid) {
          this.statusDescriptionError = true
        }
        if (this.$v.isActive.$invalid) {
          this.isActiveError = true
        }
      } else {
        this.editMyLoader = true
        this.SuccessMessage = ''

        this.array =
            {
              statusDescription: this.statusDescription,
              isActive: this.isActive,
              statusId: this.statusId
            }

        // setTimeout(() => {

        API.updateStatus(JSON.stringify(this.array)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = `${this.updatedValueStatus
                (this.getValuesBeforeEdit, [response.data.data.statusDescription, response.data.data.isActive],
                  'Status', response.data.data.statusDescription)}`
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadStatus()
              this.$store.dispatch('status/valuesBeforeEdit', [])
              this.editMyLoader = false
            } else {

            }
          }
        ).catch(e => {
          this.errors = e
          this.editMyLoader = false
          this.ErrorMessage = ' Record not edited'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.closeModal()
        })

        // }, 2000);
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (statusId = null, statusDescription = null, isActive = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.AddModalDiv = false
        this.ShowModalDiv = false
        this.statusDescription = statusDescription
        this.isActive = isActive
        this.statusId = statusId
        this.$store.dispatch('status/valuesBeforeEdit', [this.statusDescription, this.isActive])
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
      } else {
        this.loadSingleStatus(statusId)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false

      this.statusDescriptionError = false
      this.isActiveError = false
    }

  }
}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>

<style>
  .alert-success {
    padding-top:10px;
    padding-bottom: 10px;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-danger {
    padding-top:10px;
    padding-bottom: 10px;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .close-custom {
    margin-top: 10px;
    color: #155724;
  }
</style>
