<template>
  <div>
    <card>
      <crud-notification
        :error-message="ErrorMessage"
        :success-message="SuccessMessage"
        :error-alert="ErrorAlert"
        :success-alert="SuccessAlert"
        @fadeMe="fadeMe"
        @enter="enter"
      />
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-success mr-2 custom-btn"
            style="float:right;"
            @click.prevent="openModal('ADD')"
          >
            <b>Add Tally Event Code</b>
          </button>
        </div>
      </div>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="isPageable"
        :total-elements="totalElements"
        :no-data-found="noDataFound"
        :is-first="isFirst"
        :is-last="isLast"
        :total-pages="totalPages"
        :page-number="pageNumber"
        @goToPage="goToPage"
        :date-prop="'Created At '"
        :has-manage-button="false"
        :has-search-form="false"
        :has-view-button="false"
        :has-edit-button="true"
        :has-delete-button="true"
        @onChange="onChange"
        @goToEdit="goToEdit"
        @goToDelete="goToDelete"
        :loading-title="'Please wait while system is loading Tally Event Codes'"
      />
    </card>

    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            <b> Edit Tally Event</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            <b> Register Tally Event</b>
          </div>

          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--      ADD FORM      -->
          <form
            method="POST"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Tally Event Name"
                  placeholder="Tally Event Name"
                  v-model="tallyEventDetails.name"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="TallyEventNameError">
                  <div
                    class="error"
                    v-if="!$v.tallyEventDetails.name.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Tally Event Code"
                  placeholder="Tally Event Code"
                  v-model="tallyEventDetails.code"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="TallyEventCodeError">
                  <div
                    class="error"
                    v-if="!$v.tallyEventDetails.code.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label> Select Code Type</label>
                <model-list-select
                  :list="codeTypesEnum"
                  v-model="tallyEventDetails.codeType"
                  option-value="codeTypeValue"
                  class="form-control special-bottom"
                  option-text="codeTypeName"
                  placeholder="Select Associated Code Type"
                />
                <div v-if="TallyEventCodeTypeError">
                  <div
                    class="error"
                    v-if="!$v.tallyEventDetails.codeType.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  style="margin-inline-start:20px"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  v-if="AddModalDiv"
                  @click.prevent="addTallyEvent"
                >
                  Save
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  v-if="EditModalDiv"
                  @click.prevent="editTallyEvent"
                >
                  Edit
                </button>

              </div>
            </div>
          </form>
        </card>
      </div>
    </modal>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import { mapActions, mapGetters } from 'vuex'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import GlobalModal from '../../../components/globalLoader'
  import DataTable from '../../../components/DataTable/updatedTable'
  import Modal from '../../../components/Inputs/Modal'
  import CrudNotification from "../../../components/DataTable/crudNotification";
  import { ModelListSelect } from 'vue-search-select'

  export default {
    name: "TallyEventCodes",
    components: {
      PulseLoader,
      GlobalModal,
      DataTable,
      Modal,
      CrudNotification,
      ModelListSelect

    },
    created() {
      this.$store.dispatch('pagenames/currentPageName', 'Tally Event Codes');
      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

      this.loadTallyEventsOnCreated();
    },
    computed:{
      ...mapGetters('notifications', [

        'getNewAppPage'

      ]),

      ...mapGetters('tallyEvents', [

        'getTallyEventCodes',
        'getMyFirstLoader',
        'getNoDataFound',
        'getSuccessAlert',
        'getErrorMessage',
        'getErrorAlert',
        'getSuccessMessage',
        'getMyLoader',
        'getPageNo',

      ])

    },
    validations: {
      tallyEventDetails: {
        code: { required },
        codeType: { required },
        name: { required }

      }
    },
    data () {
      return {
        finalUserPermissions:[],
        color: 'green',
        size: '12px',
        loading: true,
        editMyLoader:false,
        pageNo: 0,
        pageSize: 15,
        totalElements: '',
        totalPages: '',
        isFirst: false,
        isLast: false,
        pageNumber: '',
        isPageable: true,
        requests: [],
        myLoader: false,
        myFirstLoader: false,
        reloadRequests: false,
        noDataFound: false,
        tableHeaders: ['Name', 'Code Type', 'Code'],
        tableDataKeys: ['name', 'codeType', 'code'],
        SuccessMessage: '',
        ErrorMessage: '',
        SuccessAlert: false,
        ErrorAlert: false,
        tallyEventDetails: {
          code: '',
          codeType: '',
          name: ''
        },
        TallyEventCodeError:false,
        TallyEventNameError:false,
        TallyEventCodeTypeError:false,
        codeTypesEnum: [
          {codeTypeValue: 'EVENT', codeTypeName: 'Event' },
          {codeTypeValue: 'CAUSE', codeTypeName: 'Cause' },
          {codeTypeValue: 'EFFECT', codeTypeName: 'Effect' },
          ],
        EditModalDiv: false,
        showModal: false,
        AddModalDiv: false,
      };
    },
    methods:{
      ...mapActions('tallyEvents',[
        'getPageableTallyEventCodes',
        'addTallyEventCode',
        'updateTallyEventCode',
        'deleteTallyEventCode'
      ]),
      loadParameters(){
        return new Promise((resolve) => {
          this.$store.dispatch('tallyEvents/pageNumbering', this.pageNo);
          this.$store.dispatch('tallyEvents/pageSizing', this.pageSize);
          resolve();
        });
      },
      loadTallyEventsOnCreated(){
        this.loadParameters().then(()=>{
          this.myFirstLoader = true;
          this.getPageableTallyEventCodes({}).then(()=>{
            this.myFirstLoader = this.getMyFirstLoader;
            this.isLast = this.getTallyEventCodes.last;
            this.totalElements = this.getTallyEventCodes.totalElements;
            this.totalPages = this.getTallyEventCodes.totalPages;
            this.pageNumber = this.getTallyEventCodes.number;
            this.isFirst = this.getTallyEventCodes.first;
            this.isLast = this.getTallyEventCodes.last;
            this.requests = this.getTallyEventCodes.content;
            this.noDataFound = this.getTallyEventCodes.empty;
          }).catch(()=>{
            this.myFirstLoader = this.getMyFirstLoader;
            this.noDataFound = this.getNoDataFound;
            this.ErrorAlert =this.getErrorAlert;
            this.ErrorMessage = 'Sorry, something went wrong'

          })
        }).catch(()=>{
          this.myFirstLoader = false;
        })
      },
      editTallyEvent(){
        if (this.$v.tallyEventDetails.codeType.$invalid ||
          this.$v.tallyEventDetails.code.$invalid||
          this.$v.tallyEventDetails.name.$invalid){
          if (this.$v.tallyEventDetails.codeType.$invalid){
            this.TallyEventCodeTypeError = true;
          }
          if (this.$v.tallyEventDetails.code.$invalid){
            this.TallyEventCodeError = true;
          }
          if (this.$v.tallyEventDetails.name.$invalid){
            this.TallyEventNameError = true;
          }
        }else{
          this.TallyEventCodeTypeError = true;
          this.TallyEventCodeError = true;
          this.TallyEventNameError = true;

          this.$store.dispatch('tallyEvents/savingDetails', this.tallyEventDetails);
          this.updateTallyEventCode({}).then(()=>{
            this.myLoader = this.getMyFirstLoader;
            this.SuccessAlert = this.getSuccessAlert;
            this.ErrorAlert = this.getErrorAlert;
            this.ErrorMessage = 'Sorry, something went wrong';
            this.SuccessMessage = 'Tally Event Code edited successfully';
            this.loadTallyEventsOnCreated();
            this.closeModal();
          }).catch(()=>{
            this.myLoader = this.getMyFirstLoader;
            this.ErrorAlert =this.getErrorAlert;
            this.ErrorMessage = 'Sorry, something went wrong'
          });
        }
      },


      addTallyEvent(){
        if (this.$v.tallyEventDetails.codeType.$invalid ||
          this.$v.tallyEventDetails.code.$invalid||
          this.$v.tallyEventDetails.name.$invalid){
          if (this.$v.tallyEventDetails.codeType.$invalid){
            this.TallyEventCodeTypeError = true;
          }
          if (this.$v.tallyEventDetails.code.$invalid){
            this.TallyEventCodeError = true;
          }
          if (this.$v.tallyEventDetails.name.$invalid){
            this.TallyEventNameError = true;
          }
        }else{
          this.TallyEventCodeTypeError = true;
          this.TallyEventCodeError = true;
          this.TallyEventNameError = true;

          this.$store.dispatch('tallyEvents/savingDetails', this.tallyEventDetails);
          this.addTallyEventCode({}).then(()=>{
            this.myLoader = this.getMyFirstLoader;
            this.SuccessAlert = this.getSuccessAlert;
            this.ErrorAlert = this.getErrorAlert;
            this.ErrorMessage = 'Sorry, something went wrong';
            this.SuccessMessage = 'Tally Event Code saved successfully';
            this.loadTallyEventsOnCreated();
            this.closeModal();
          }).catch(()=>{
            this.myLoader = this.getMyFirstLoader;
            this.ErrorAlert =this.getErrorAlert;
            this.ErrorMessage = 'Sorry, something went wrong'
          });
        }
      },

      goToDelete(data){
        this.$dialog.confirm('You are about to delete <br>' +
          '<b> [ ' + data.name + '] </b> <br>' +
          'Are you sure? ',{
          loader: true,
          html: true,
          okText: 'Yes'
        }).then((dialog)=>{
          this.$store.dispatch('tallyEvents/requestid', data.id);
          this.deleteTallyEventCode().then(()=>{
            this.myLoader = this.getMyFirstLoader;
            this.SuccessMessage = 'Tally Event Code deleted successfully';
            this.ErrorMessage = 'Sorry, something went wrong';
            this.ErrorAlert = this.getErrorAlert;
            this.SuccessAlert = this.getSuccessAlert;
            this.loadTallyEventsOnCreated();
          }).catch(()=>{
            this.myLoader = this.getMyFirstLoader;
            this.ErrorAlert =this.getErrorAlert;
            this.ErrorMessage = 'Sorry, something went wrong'
          })
          dialog.close();
        })
      },
      goToEdit(data){
        this.tallyEventDetails  = JSON.parse(JSON.stringify(data));
        this.openModal('EDIT');
      },
      openModal(modalType){
        if (modalType === 'ADD'){
          this.tallyEventDetails = {}
          this.AddModalDiv = true;
          this.EditModalDiv = false;
          this.showModal = true;
        }
        if (modalType === 'EDIT'){
          this.AddModalDiv = false;
          this.EditModalDiv = true;
          this.showModal = true;
        }

      },

      closeModal(){
        this.AddModalDiv = false;
        this.EditModalDiv = false;
        this.showModal = false;
        this.tallyEventDetails = {
          code: '',
          codeType: '',
          name: ''
        };
        this.TallyEventCodeError = false;
        this.TallyEventNameError = false;
        this.TallyEventCodeTypeError = false;
      },

      onChange (event) {
        this.pageSize = event;
        this.pageNo = 0;
        this.loadTallyEventsOnCreated();
      },
      goToPage (page) {
        this.pageNo = page;
        this.loadTallyEventsOnCreated();
      },
      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert
        } else {
          this.ErrorAlert = !this.ErrorAlert
        }
      },
      enter: function (el, done) {
        var that = this

        setTimeout(function () {
          that.SuccessAlert = false
          that.ErrorAlert = false
        }, 3000) // hide the message after 3 seconds
      },
    }
  }
</script>

<style scoped>
  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    /*width: 180px;*/
    background-color: #FFFFFF !important;
  }
  .error{
    color:#dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }
</style>
