<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-hovered="isHovered"
        :my-loader="myLoader"
        :page-count="pageCount"
        :reload-all-request="reloadAllRequest"
        :my-first-loader="myFirstLoader"
        :no-data-found="noDataFound"
        :search-parameter-prop="searchParameter"
        :status="status"
        :loading-title="'Please wait while system is loading accepted loading list'"
        @showToolTip="showToolTip"
        @hideToolTip="hideToolTip"
        @onChange="onChange"
        @goToApplication="goToApplication"
        @reloadByIcon="reloadByIcon"
        @loadAllPageable="loadAllPageable"
        @firstPage="firstPage"
        @prevPage="prevPage"
        @nextPage="nextPage"
        @lastPage="lastPage"
      />
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../components/globalLoader'
import DataTable from '../../../components/DataTable/table'
import Modal from '../../../components/Inputs/Modal'

export default {
  name: 'ApprovedLoadingList',
  components: {
    PulseLoader,
    GlobalModal,
    DataTable,
    Modal
  },
  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Approved Loading List')

    this.finalUserPermissions = JSON.parse(
      localStorage.getItem('browser-local-permits')
    )
    // TODO: remember to uncomment due to overiding api to VUEX store
    // this.loadPageableRequestOnCreated();
  },
  computed: {
    ...mapGetters('notifications', ['getNewAppPage']),
    ...mapGetters('loadinglists', [

      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getMyLoader',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo',
      'getCustomerName',
      'getRequestId',
      'getBlNumber'
    ])
  },
  data () {
    return {

      // for reusable components
      accessedFrom: 'approvedLoadingList',

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      sortBy: 'requestDate',

      color: 'green',
      size: '12px',
      loading: true,

      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,
      pdfFormatError: false,
      loadAllHeaderLoader: false,
      allAttachmentVerified: false,
      myLoader: false,
      myFirstLoader: false,
      status: 'SHIPPING_TALLY',
      // requests: [
      //   {"id":"1","declarantCd":"0","declarantYear":"2020-08-19T20:19:11.218Z","processType":"interfaces_cfa_franc_beac.pdf","declarantSerialNo":"a","masterBlNo":"d","houseBlNo":"n","processingYn":"2020-08-20T09:15:52.731Z","processingYnDt":"2020-08-20T08:30:58.059Z","discrepancyType":"Group","loadingPackage":"INR BTN","packageUnit":"VUV","loadingWeight":"600.76","weightUnit":"AWG","loadingVolume":"624.50","volumeUnit":"Inc","abnormalComment":"Organized impactful database","firstRegisterId":"h","firstRegisterDt":"2020-08-12T20:59:53.177Z","lastUpdateId":"c","lastUpdateDt":"2020-08-19T14:29:17.769Z","referenceNo":"7","refDeclarantNo":"0"},
      //   {"id":"2","declarantCd":"5","declarantYear":"2020-08-20T04:11:25.590Z","processType":"rich_standardization.mpg4","declarantSerialNo":"l","masterBlNo":"d","houseBlNo":"n","processingYn":"2020-08-20T09:59:10.547Z","processingYnDt":"2020-08-20T04:23:13.488Z","discrepancyType":"LLC","loadingPackage":"RUB","packageUnit":"BIF","loadingWeight":"708.12","weightUnit":"LRD","loadingVolume":"983.93","volumeUnit":"Inc","abnormalComment":"Re-engineered system-worthy success","firstRegisterId":"f","firstRegisterDt":"2019-11-06T15:59:18.603Z","lastUpdateId":"w","lastUpdateDt":"2020-08-19T16:17:34.952Z","referenceNo":"i","refDeclarantNo":"3"},
      //   {"id":"3","declarantCd":"4","declarantYear":"2020-08-20T12:27:31.921Z","processType":"backing_up.mpg4","declarantSerialNo":"f","masterBlNo":"y","houseBlNo":"l","processingYn":"2020-08-20T03:58:53.390Z","processingYnDt":"2020-08-19T17:11:37.061Z","discrepancyType":"Group","loadingPackage":"HUF","packageUnit":"BRL","loadingWeight":"632.07","weightUnit":"ANG","loadingVolume":"930.97","volumeUnit":"Group","abnormalComment":"Distributed zero tolerance paradigm","firstRegisterId":"t","firstRegisterDt":"2020-08-08T04:54:12.150Z","lastUpdateId":"f","lastUpdateDt":"2020-08-19T21:41:08.562Z","referenceNo":"s","refDeclarantNo":"k"},
      //   {"id":"4","declarantCd":"y","declarantYear":"2020-08-20T01:46:48.783Z","processType":"florida_bacon.mp4","declarantSerialNo":"k","masterBlNo":"b","houseBlNo":"k","processingYn":"2020-08-19T22:55:21.755Z","processingYnDt":"2020-08-19T20:09:25.544Z","discrepancyType":"Inc","loadingPackage":"MDL","packageUnit":"XDR","loadingWeight":"407.91","weightUnit":"QAR","loadingVolume":"328.86","volumeUnit":"Group","abnormalComment":"Assimilated well-modulated support","firstRegisterId":"x","firstRegisterDt":"2020-01-22T01:57:05.767Z","lastUpdateId":"0","lastUpdateDt":"2020-08-20T00:37:59.938Z","referenceNo":"n","refDeclarantNo":"w"},
      //   {"id":"5","declarantCd":"v","declarantYear":"2020-08-20T00:08:34.318Z","processType":"tan_home_loan_account_oklahoma.wav","declarantSerialNo":"j","masterBlNo":"y","houseBlNo":"9","processingYn":"2020-08-19T23:00:39.330Z","processingYnDt":"2020-08-19T19:20:28.309Z","discrepancyType":"LLC","loadingPackage":"BND","packageUnit":"JMD","loadingWeight":"366.23","weightUnit":"MAD","loadingVolume":"966.38","volumeUnit":"LLC","abnormalComment":"Public-key homogeneous emulation","firstRegisterId":"w","firstRegisterDt":"2019-09-15T00:32:30.898Z","lastUpdateId":"v","lastUpdateDt":"2020-08-19T19:35:30.870Z","referenceNo":"j","refDeclarantNo":"y"},
      //   {"id":"6","declarantCd":"9","declarantYear":"2020-08-19T21:21:02.210Z","processType":"monitor_research_enterprise_wide.m3a","declarantSerialNo":"f","masterBlNo":"m","houseBlNo":"l","processingYn":"2020-08-19T14:37:21.414Z","processingYnDt":"2020-08-19T20:27:07.272Z","discrepancyType":"LLC","loadingPackage":"NZD","packageUnit":"XTS","loadingWeight":"140.62","weightUnit":"VUV","loadingVolume":"931.95","volumeUnit":"and Sons","abnormalComment":"Stand-alone 4th generation website","firstRegisterId":"v","firstRegisterDt":"2020-07-26T05:27:46.482Z","lastUpdateId":"o","lastUpdateDt":"2020-08-20T03:43:52.876Z","referenceNo":"t","refDeclarantNo":"l"}
      //   ],
      requests: [
        {
          documentId: '112',
          callId: '10',
          mrn: '19PIL000059',
          voyage: '9V9975',
          lineName: 'Minerva',
          docTime: '18:45',
          terminal: 'WITZDL022',
          eta: '22/07/2019',
          ata: '22/07/2019',

          sender: 'SHAMON',
          senderReference: 'AOSIGR232',
          senderReferenceVersion: 'AD32',
          receiver: 'TASAC',
          documentType: 'LOADINGLIST',
          messageFunction: 'string',

          lineCode: 'WISRE0313',
          // lineName : "string"
          lineAgentCode: 'ASLX032',
          lineAgentName: 'John Haris & Co.',
          imoNumber: '52511',
          vesselName: 'KOTA MANIS',
          vesselNationality: 'USA',
          // eta : "22/07/2019",
          etd: '23/07/2019',
          // ata : "22/07/2019",
          atd: '23/07/2019',
          port: 'TZDAR',

          action: 'Create',

          bln: [
            {
              manifestId: 7,
              action: 'Create',
              blSequenceNo: 0,
              version: 0,
              blNumber: 'HUA900230100',
              blType: 'S',
              blLineCode: 'PIL',
              blLineName: 'string',
              blAgentCode: 'string',
              blAgentName: 'string',
              tradeType: 'string',
              terminal: 'WITZDL018',
              depot: 'string',
              packagesNo: 0,
              packagesNoUnit: 'PK',
              grossWeight: 219490,
              grossWeightUnit: 'KG',
              netWeight: 216580,
              netWeightUnit: 'KG',
              grossVolume: 544,
              grossVolumeUnit: 'CBM',
              freightPrepaidUnit: 'CBM',
              freightCollectUnit: 'CBM',
              totalGoodsValueUnit: 'CBM',
              insuranceValueUnit: 'CBM',
              goodsDescription: 'SHOES HANDBAGS MOBILE ACCESSORIES. PCIU8592647 NET WEIGHT:26010 KGS PCIU8913834 NET WEIGHT 27410 KGS PCIU8724194 NET WEIGHT:27480 KGS PCIU8396120 NET WEIGHT 27490 KGS PCIU9370407 NET WEIGHT 27640 KGS',
              shipper: {
                tin: 'null',
                code: 'null',
                name: 'WHITE DRAGON CO. LTD',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactPhone: 'null'
              },
              consignee: {
                consigneeId: 12,
                code: 'string',
                name: 'OPPO AGENCIES COMPANY LIMITED',
                addressline1: 'P O BOX:25538',
                addressline2: 'DAR ES SALAAM ',
                addressline3: 'TANZANIA',
                locationCode: 'string',
                locationName: 'string',
                contactName: 'string',
                contactTelephone: '+255 713 400 276',
                tin: 'string'
              },
              notify: {
                code: 'null',
                name: 'SAME AS CONSIGNEE',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              masterBL: {
                blnumber: 'string',
                crn: 'string'
              },
              goods: [
                {
                  goodsItemNo: 0,
                  commodityCode: 'string',
                  commoditySpecification: 'string',
                  hsCode: 'string',
                  description: 'string',
                  packageQuantity: 0,
                  packageType: 'string',
                  marksNumbers: 'string',
                  vehicleVIN: 'string',
                  vehicleModel: 'string',
                  vehicleMake: 'string',
                  vehicleOwnDrive: 'string',
                  containerID: 12,
                  grossWeight: 0,
                  grossWeightUnit: 'string',
                  netWeight: 0,
                  netWeightUnit: 'string',
                  volume: 0,
                  volumeUnit: 'string',
                  length: 0,
                  lengthUnit: 'string',
                  width: 0,
                  widthUnit: 'string',
                  height: 0,
                  heightUnit: 'string',
                  goodsValue: 0,
                  goodsValueUnit: 'string',
                  dgs: {
                    imdgClass: 'string',
                    unNumber: 'string',
                    classCode: 'string',
                    imdgPage: 'string',
                    description: 'string',
                    flashPointValue: 0,
                    flashPointUnit: 'string',
                    shipmFlashptValue: 0,
                    shipmFlashptUnit: 'string',
                    packingGroup: 'string',
                    trEMcard: 'string',
                    marPolCategory: 'string',
                    contactCompany: 'string',
                    contactPerson: 'string',
                    contactTelephone: 'string',
                    contactTelephone24H: 'string',
                    ems: 'string',
                    mfag: 'string'
                  }
                }
              ],
              crn: 'string',
              poo: 'CNNSA',
              pol: 'CNNSA',
              pod: 'TZDAR',
              fpod: 'TZDAR'
            },
            {
              manifestId: 7,
              action: 'Create',
              blSequenceNo: 0,
              version: 0,
              blNumber: 'HUA900230400',
              blType: 'S',
              blLineCode: 'PIL',
              blLineName: 'string',
              blAgentCode: 'string',
              blAgentName: 'string',
              tradeType: 'string',
              terminal: 'WITZDL018',
              depot: 'string',
              packagesNo: 0,
              packagesNoUnit: 'PK',
              grossWeight: 27680,
              grossWeightUnit: 'KG',
              netWeight: 27680,
              netWeightUnit: 'KG',
              grossVolume: 68,
              grossVolumeUnit: 'CBM',
              freightPrepaidUnit: 'CBM',
              freightCollectUnit: 'CBM',
              totalGoodsValueUnit: 'CBM',
              insuranceValueUnit: 'CBM',
              goodsDescription: 'MOTORCYCLE SPARE PARTS. NET WEIGHT 27320 KGS FREIGHT COLLECT CARGO DELIVERY TO JEFAG ICD',
              shipper: {
                tin: 'null',
                code: 'null',
                name: 'WHITE DRAGON CO. LTD',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactPhone: 'null'
              },
              consignee: {
                consigneeId: 13,
                code: 'null',
                name: 'ANET KIPOMELA MWAMBOSA',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              notify: {
                code: 'null',
                name: 'SAME AS CONSIGNEE',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              masterBL: {
                blnumber: 'string',
                crn: 'string'
              },
              goods: [
                {
                  goodsItemNo: 0,
                  commodityCode: 'string',
                  commoditySpecification: 'string',
                  hsCode: 'string',
                  description: 'string',
                  packageQuantity: 0,
                  packageType: 'string',
                  marksNumbers: 'string',
                  vehicleVIN: 'string',
                  vehicleModel: 'string',
                  vehicleMake: 'string',
                  vehicleOwnDrive: 'string',
                  containerID: 12,
                  grossWeight: 0,
                  grossWeightUnit: 'string',
                  netWeight: 0,
                  netWeightUnit: 'string',
                  volume: 0,
                  volumeUnit: 'string',
                  length: 0,
                  lengthUnit: 'string',
                  width: 0,
                  widthUnit: 'string',
                  height: 0,
                  heightUnit: 'string',
                  goodsValue: 0,
                  goodsValueUnit: 'string',
                  dgs: {
                    imdgClass: 'string',
                    unNumber: 'string',
                    classCode: 'string',
                    imdgPage: 'string',
                    description: 'string',
                    flashPointValue: 0,
                    flashPointUnit: 'string',
                    shipmFlashptValue: 0,
                    shipmFlashptUnit: 'string',
                    packingGroup: 'string',
                    trEMcard: 'string',
                    marPolCategory: 'string',
                    contactCompany: 'string',
                    contactPerson: 'string',
                    contactTelephone: 'string',
                    contactTelephone24H: 'string',
                    ems: 'string',
                    mfag: 'string'
                  }
                }
              ],
              crn: 'string',
              poo: 'CNNSA',
              pol: 'CNNSA',
              pod: 'TZDAR',
              fpod: 'TZDAR'
            }
          ]
        },
        {
          documentId: '113',
          callId: '11',
          mrn: '19PIL000059',
          voyage: '9V9975',
          lineName: 'Lomboki',
          docTime: '18:45',
          terminal: 'WITZDL022',
          eta: '22/07/2019',
          ata: '22/07/2019',
          bln: [
            {
              manifestId: 7,
              action: 'Create',
              blSequenceNo: 0,
              version: 0,
              blNumber: 'HUA900230100',
              blType: 'S',
              blLineCode: 'PIL',
              blLineName: 'string',
              blAgentCode: 'string',
              blAgentName: 'string',
              tradeType: 'string',
              terminal: 'WITZDL018',
              depot: 'string',
              packagesNo: 0,
              packagesNoUnit: 'PK',
              grossWeight: 219490,
              grossWeightUnit: 'KG',
              netWeight: 216580,
              netWeightUnit: 'KG',
              grossVolume: 544,
              grossVolumeUnit: 'CBM',
              freightPrepaidUnit: 'CBM',
              freightCollectUnit: 'CBM',
              totalGoodsValueUnit: 'CBM',
              insuranceValueUnit: 'CBM',
              goodsDescription: 'SHOES HANDBAGS MOBILE ACCESSORIES. PCIU8592647 NET WEIGHT:26010 KGS PCIU8913834 NET WEIGHT 27410 KGS PCIU8724194 NET WEIGHT:27480 KGS PCIU8396120 NET WEIGHT 27490 KGS PCIU9370407 NET WEIGHT 27640 KGS',
              shipper: {
                tin: 'null',
                code: 'null',
                name: 'WHITE DRAGON CO. LTD',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactPhone: 'null'
              },
              consignee: {
                consigneeId: 12,
                code: 'string',
                name: 'OPPO AGENCIES COMPANY LIMITED',
                addressline1: 'P O BOX:25538',
                addressline2: 'DAR ES SALAAM ',
                addressline3: 'TANZANIA',
                locationCode: 'string',
                locationName: 'string',
                contactName: 'string',
                contactTelephone: '+255 713 400 276',
                tin: 'string'
              },
              notify: {
                code: 'null',
                name: 'SAME AS CONSIGNEE',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              masterBL: {
                blnumber: 'string',
                crn: 'string'
              },
              goods: [
                {
                  goodsItemNo: 0,
                  commodityCode: 'string',
                  commoditySpecification: 'string',
                  hsCode: 'string',
                  description: 'string',
                  packageQuantity: 0,
                  packageType: 'string',
                  marksNumbers: 'string',
                  vehicleVIN: 'string',
                  vehicleModel: 'string',
                  vehicleMake: 'string',
                  vehicleOwnDrive: 'string',
                  containerID: 12,
                  grossWeight: 0,
                  grossWeightUnit: 'string',
                  netWeight: 0,
                  netWeightUnit: 'string',
                  volume: 0,
                  volumeUnit: 'string',
                  length: 0,
                  lengthUnit: 'string',
                  width: 0,
                  widthUnit: 'string',
                  height: 0,
                  heightUnit: 'string',
                  goodsValue: 0,
                  goodsValueUnit: 'string',
                  dgs: {
                    imdgClass: 'string',
                    unNumber: 'string',
                    classCode: 'string',
                    imdgPage: 'string',
                    description: 'string',
                    flashPointValue: 0,
                    flashPointUnit: 'string',
                    shipmFlashptValue: 0,
                    shipmFlashptUnit: 'string',
                    packingGroup: 'string',
                    trEMcard: 'string',
                    marPolCategory: 'string',
                    contactCompany: 'string',
                    contactPerson: 'string',
                    contactTelephone: 'string',
                    contactTelephone24H: 'string',
                    ems: 'string',
                    mfag: 'string'
                  }
                }
              ],
              crn: 'string',
              poo: 'CNNSA',
              pol: 'CNNSA',
              pod: 'TZDAR',
              fpod: 'TZDAR'
            },
            {
              manifestId: 7,
              action: 'Create',
              blSequenceNo: 0,
              version: 0,
              blNumber: 'HUA900230400',
              blType: 'S',
              blLineCode: 'PIL',
              blLineName: 'string',
              blAgentCode: 'string',
              blAgentName: 'string',
              tradeType: 'string',
              terminal: 'WITZDL018',
              depot: 'string',
              packagesNo: 0,
              packagesNoUnit: 'PK',
              grossWeight: 27680,
              grossWeightUnit: 'KG',
              netWeight: 27680,
              netWeightUnit: 'KG',
              grossVolume: 68,
              grossVolumeUnit: 'CBM',
              freightPrepaidUnit: 'CBM',
              freightCollectUnit: 'CBM',
              totalGoodsValueUnit: 'CBM',
              insuranceValueUnit: 'CBM',
              goodsDescription: 'MOTORCYCLE SPARE PARTS. NET WEIGHT 27320 KGS FREIGHT COLLECT CARGO DELIVERY TO JEFAG ICD',
              shipper: {
                tin: 'null',
                code: 'null',
                name: 'WHITE DRAGON CO. LTD',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactPhone: 'null'
              },
              consignee: {
                consigneeId: 13,
                code: 'null',
                name: 'ANET KIPOMELA MWAMBOSA',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              notify: {
                code: 'null',
                name: 'SAME AS CONSIGNEE',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              masterBL: {
                blnumber: 'string',
                crn: 'string'
              },
              goods: [
                {
                  goodsItemNo: 0,
                  commodityCode: 'string',
                  commoditySpecification: 'string',
                  hsCode: 'string',
                  description: 'string',
                  packageQuantity: 0,
                  packageType: 'string',
                  marksNumbers: 'string',
                  vehicleVIN: 'string',
                  vehicleModel: 'string',
                  vehicleMake: 'string',
                  vehicleOwnDrive: 'string',
                  containerID: 12,
                  grossWeight: 0,
                  grossWeightUnit: 'string',
                  netWeight: 0,
                  netWeightUnit: 'string',
                  volume: 0,
                  volumeUnit: 'string',
                  length: 0,
                  lengthUnit: 'string',
                  width: 0,
                  widthUnit: 'string',
                  height: 0,
                  heightUnit: 'string',
                  goodsValue: 0,
                  goodsValueUnit: 'string',
                  dgs: {
                    imdgClass: 'string',
                    unNumber: 'string',
                    classCode: 'string',
                    imdgPage: 'string',
                    description: 'string',
                    flashPointValue: 0,
                    flashPointUnit: 'string',
                    shipmFlashptValue: 0,
                    shipmFlashptUnit: 'string',
                    packingGroup: 'string',
                    trEMcard: 'string',
                    marPolCategory: 'string',
                    contactCompany: 'string',
                    contactPerson: 'string',
                    contactTelephone: 'string',
                    contactTelephone24H: 'string',
                    ems: 'string',
                    mfag: 'string'
                  }
                }
              ],
              crn: 'string',
              poo: 'CNNSA',
              pol: 'CNNSA',
              pod: 'TZDAR',
              fpod: 'TZDAR'
            },
            {
              manifestId: 7,
              action: 'Create',
              blSequenceNo: 0,
              version: 0,
              blNumber: 'HUA900230400',
              blType: 'S',
              blLineCode: 'PIL',
              blLineName: 'string',
              blAgentCode: 'string',
              blAgentName: 'string',
              tradeType: 'string',
              terminal: 'WITZDL018',
              depot: 'string',
              packagesNo: 0,
              packagesNoUnit: 'PK',
              grossWeight: 27680,
              grossWeightUnit: 'KG',
              netWeight: 27680,
              netWeightUnit: 'KG',
              grossVolume: 68,
              grossVolumeUnit: 'CBM',
              freightPrepaidUnit: 'CBM',
              freightCollectUnit: 'CBM',
              totalGoodsValueUnit: 'CBM',
              insuranceValueUnit: 'CBM',
              goodsDescription: 'MOTORCYCLE SPARE PARTS. NET WEIGHT 27320 KGS FREIGHT COLLECT CARGO DELIVERY TO JEFAG ICD',
              shipper: {
                tin: 'null',
                code: 'null',
                name: 'WHITE DRAGON CO. LTD',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactPhone: 'null'
              },
              consignee: {
                consigneeId: 13,
                code: 'null',
                name: 'ANET KIPOMELA MWAMBOSA',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              notify: {
                code: 'null',
                name: 'SAME AS CONSIGNEE',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              masterBL: {
                blnumber: 'string',
                crn: 'string'
              },
              goods: [
                {
                  goodsItemNo: 0,
                  commodityCode: 'string',
                  commoditySpecification: 'string',
                  hsCode: 'string',
                  description: 'string',
                  packageQuantity: 0,
                  packageType: 'string',
                  marksNumbers: 'string',
                  vehicleVIN: 'string',
                  vehicleModel: 'string',
                  vehicleMake: 'string',
                  vehicleOwnDrive: 'string',
                  containerID: 12,
                  grossWeight: 0,
                  grossWeightUnit: 'string',
                  netWeight: 0,
                  netWeightUnit: 'string',
                  volume: 0,
                  volumeUnit: 'string',
                  length: 0,
                  lengthUnit: 'string',
                  width: 0,
                  widthUnit: 'string',
                  height: 0,
                  heightUnit: 'string',
                  goodsValue: 0,
                  goodsValueUnit: 'string',
                  dgs: {
                    imdgClass: 'string',
                    unNumber: 'string',
                    classCode: 'string',
                    imdgPage: 'string',
                    description: 'string',
                    flashPointValue: 0,
                    flashPointUnit: 'string',
                    shipmFlashptValue: 0,
                    shipmFlashptUnit: 'string',
                    packingGroup: 'string',
                    trEMcard: 'string',
                    marPolCategory: 'string',
                    contactCompany: 'string',
                    contactPerson: 'string',
                    contactTelephone: 'string',
                    contactTelephone24H: 'string',
                    ems: 'string',
                    mfag: 'string'
                  }
                }
              ],
              crn: 'string',
              poo: 'CNNSA',
              pol: 'CNNSA',
              pod: 'TZDAR',
              fpod: 'TZDAR'
            }

          ]
        },
        {
          documentId: '114',
          callId: '12',
          mrn: '19PIL000059',
          voyage: '9V9975',
          lineName: 'Athena',
          docTime: '18:45',
          terminal: 'WITZDL022',
          eta: '22/07/2019',
          ata: '22/07/2019',
          bln: [
            {
              manifestId: 7,
              action: 'Create',
              blSequenceNo: 0,
              version: 0,
              blNumber: 'HUA900230100',
              blType: 'S',
              blLineCode: 'PIL',
              blLineName: 'string',
              blAgentCode: 'string',
              blAgentName: 'string',
              tradeType: 'string',
              terminal: 'WITZDL018',
              depot: 'string',
              packagesNo: 0,
              packagesNoUnit: 'PK',
              grossWeight: 219490,
              grossWeightUnit: 'KG',
              netWeight: 216580,
              netWeightUnit: 'KG',
              grossVolume: 544,
              grossVolumeUnit: 'CBM',
              freightPrepaidUnit: 'CBM',
              freightCollectUnit: 'CBM',
              totalGoodsValueUnit: 'CBM',
              insuranceValueUnit: 'CBM',
              goodsDescription: 'SHOES HANDBAGS MOBILE ACCESSORIES. PCIU8592647 NET WEIGHT:26010 KGS PCIU8913834 NET WEIGHT 27410 KGS PCIU8724194 NET WEIGHT:27480 KGS PCIU8396120 NET WEIGHT 27490 KGS PCIU9370407 NET WEIGHT 27640 KGS',
              shipper: {
                tin: 'null',
                code: 'null',
                name: 'WHITE DRAGON CO. LTD',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactPhone: 'null'
              },
              consignee: {
                consigneeId: 12,
                code: 'string',
                name: 'OPPO AGENCIES COMPANY LIMITED',
                addressline1: 'P O BOX:25538',
                addressline2: 'DAR ES SALAAM ',
                addressline3: 'TANZANIA',
                locationCode: 'string',
                locationName: 'string',
                contactName: 'string',
                contactTelephone: '+255 713 400 276',
                tin: 'string'
              },
              notify: {
                code: 'null',
                name: 'SAME AS CONSIGNEE',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              masterBL: {
                blnumber: 'string',
                crn: 'string'
              },
              goods: [
                {
                  goodsItemNo: 0,
                  commodityCode: 'string',
                  commoditySpecification: 'string',
                  hsCode: 'string',
                  description: 'string',
                  packageQuantity: 0,
                  packageType: 'string',
                  marksNumbers: 'string',
                  vehicleVIN: 'string',
                  vehicleModel: 'string',
                  vehicleMake: 'string',
                  vehicleOwnDrive: 'string',
                  containerID: 12,
                  grossWeight: 0,
                  grossWeightUnit: 'string',
                  netWeight: 0,
                  netWeightUnit: 'string',
                  volume: 0,
                  volumeUnit: 'string',
                  length: 0,
                  lengthUnit: 'string',
                  width: 0,
                  widthUnit: 'string',
                  height: 0,
                  heightUnit: 'string',
                  goodsValue: 0,
                  goodsValueUnit: 'string',
                  dgs: {
                    imdgClass: 'string',
                    unNumber: 'string',
                    classCode: 'string',
                    imdgPage: 'string',
                    description: 'string',
                    flashPointValue: 0,
                    flashPointUnit: 'string',
                    shipmFlashptValue: 0,
                    shipmFlashptUnit: 'string',
                    packingGroup: 'string',
                    trEMcard: 'string',
                    marPolCategory: 'string',
                    contactCompany: 'string',
                    contactPerson: 'string',
                    contactTelephone: 'string',
                    contactTelephone24H: 'string',
                    ems: 'string',
                    mfag: 'string'
                  }
                }
              ],
              crn: 'string',
              poo: 'CNNSA',
              pol: 'CNNSA',
              pod: 'TZDAR',
              fpod: 'TZDAR'
            },
            {
              manifestId: 7,
              action: 'Create',
              blSequenceNo: 0,
              version: 0,
              blNumber: 'HUA900230400',
              blType: 'S',
              blLineCode: 'PIL',
              blLineName: 'string',
              blAgentCode: 'string',
              blAgentName: 'string',
              tradeType: 'string',
              terminal: 'WITZDL018',
              depot: 'string',
              packagesNo: 0,
              packagesNoUnit: 'PK',
              grossWeight: 27680,
              grossWeightUnit: 'KG',
              netWeight: 27680,
              netWeightUnit: 'KG',
              grossVolume: 68,
              grossVolumeUnit: 'CBM',
              freightPrepaidUnit: 'CBM',
              freightCollectUnit: 'CBM',
              totalGoodsValueUnit: 'CBM',
              insuranceValueUnit: 'CBM',
              goodsDescription: 'MOTORCYCLE SPARE PARTS. NET WEIGHT 27320 KGS FREIGHT COLLECT CARGO DELIVERY TO JEFAG ICD',
              shipper: {
                tin: 'null',
                code: 'null',
                name: 'WHITE DRAGON CO. LTD',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactPhone: 'null'
              },
              consignee: {
                consigneeId: 13,
                code: 'null',
                name: 'ANET KIPOMELA MWAMBOSA',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              notify: {
                code: 'null',
                name: 'SAME AS CONSIGNEE',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              masterBL: {
                blnumber: 'string',
                crn: 'string'
              },
              goods: [
                {
                  goodsItemNo: 0,
                  commodityCode: 'string',
                  commoditySpecification: 'string',
                  hsCode: 'string',
                  description: 'string',
                  packageQuantity: 0,
                  packageType: 'string',
                  marksNumbers: 'string',
                  vehicleVIN: 'string',
                  vehicleModel: 'string',
                  vehicleMake: 'string',
                  vehicleOwnDrive: 'string',
                  containerID: 12,
                  grossWeight: 0,
                  grossWeightUnit: 'string',
                  netWeight: 0,
                  netWeightUnit: 'string',
                  volume: 0,
                  volumeUnit: 'string',
                  length: 0,
                  lengthUnit: 'string',
                  width: 0,
                  widthUnit: 'string',
                  height: 0,
                  heightUnit: 'string',
                  goodsValue: 0,
                  goodsValueUnit: 'string',
                  dgs: {
                    imdgClass: 'string',
                    unNumber: 'string',
                    classCode: 'string',
                    imdgPage: 'string',
                    description: 'string',
                    flashPointValue: 0,
                    flashPointUnit: 'string',
                    shipmFlashptValue: 0,
                    shipmFlashptUnit: 'string',
                    packingGroup: 'string',
                    trEMcard: 'string',
                    marPolCategory: 'string',
                    contactCompany: 'string',
                    contactPerson: 'string',
                    contactTelephone: 'string',
                    contactTelephone24H: 'string',
                    ems: 'string',
                    mfag: 'string'
                  }
                }
              ],
              crn: 'string',
              poo: 'CNNSA',
              pol: 'CNNSA',
              pod: 'TZDAR',
              fpod: 'TZDAR'
            },
            {
              manifestId: 7,
              action: 'Create',
              blSequenceNo: 0,
              version: 0,
              blNumber: 'HUA900230400',
              blType: 'S',
              blLineCode: 'PIL',
              blLineName: 'string',
              blAgentCode: 'string',
              blAgentName: 'string',
              tradeType: 'string',
              terminal: 'WITZDL018',
              depot: 'string',
              packagesNo: 0,
              packagesNoUnit: 'PK',
              grossWeight: 27680,
              grossWeightUnit: 'KG',
              netWeight: 27680,
              netWeightUnit: 'KG',
              grossVolume: 68,
              grossVolumeUnit: 'CBM',
              freightPrepaidUnit: 'CBM',
              freightCollectUnit: 'CBM',
              totalGoodsValueUnit: 'CBM',
              insuranceValueUnit: 'CBM',
              goodsDescription: 'MOTORCYCLE SPARE PARTS. NET WEIGHT 27320 KGS FREIGHT COLLECT CARGO DELIVERY TO JEFAG ICD',
              shipper: {
                tin: 'null',
                code: 'null',
                name: 'WHITE DRAGON CO. LTD',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactPhone: 'null'
              },
              consignee: {
                consigneeId: 13,
                code: 'null',
                name: 'ANET KIPOMELA MWAMBOSA',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              notify: {
                code: 'null',
                name: 'SAME AS CONSIGNEE',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              masterBL: {
                blnumber: 'string',
                crn: 'string'
              },
              goods: [
                {
                  goodsItemNo: 0,
                  commodityCode: 'string',
                  commoditySpecification: 'string',
                  hsCode: 'string',
                  description: 'string',
                  packageQuantity: 0,
                  packageType: 'string',
                  marksNumbers: 'string',
                  vehicleVIN: 'string',
                  vehicleModel: 'string',
                  vehicleMake: 'string',
                  vehicleOwnDrive: 'string',
                  containerID: 12,
                  grossWeight: 0,
                  grossWeightUnit: 'string',
                  netWeight: 0,
                  netWeightUnit: 'string',
                  volume: 0,
                  volumeUnit: 'string',
                  length: 0,
                  lengthUnit: 'string',
                  width: 0,
                  widthUnit: 'string',
                  height: 0,
                  heightUnit: 'string',
                  goodsValue: 0,
                  goodsValueUnit: 'string',
                  dgs: {
                    imdgClass: 'string',
                    unNumber: 'string',
                    classCode: 'string',
                    imdgPage: 'string',
                    description: 'string',
                    flashPointValue: 0,
                    flashPointUnit: 'string',
                    shipmFlashptValue: 0,
                    shipmFlashptUnit: 'string',
                    packingGroup: 'string',
                    trEMcard: 'string',
                    marPolCategory: 'string',
                    contactCompany: 'string',
                    contactPerson: 'string',
                    contactTelephone: 'string',
                    contactTelephone24H: 'string',
                    ems: 'string',
                    mfag: 'string'
                  }
                }
              ],
              crn: 'string',
              poo: 'CNNSA',
              pol: 'CNNSA',
              pod: 'TZDAR',
              fpod: 'TZDAR'
            },
            {
              manifestId: 7,
              action: 'Create',
              blSequenceNo: 0,
              version: 0,
              blNumber: 'HUA900230400',
              blType: 'S',
              blLineCode: 'PIL',
              blLineName: 'string',
              blAgentCode: 'string',
              blAgentName: 'string',
              tradeType: 'string',
              terminal: 'WITZDL018',
              depot: 'string',
              packagesNo: 0,
              packagesNoUnit: 'PK',
              grossWeight: 27680,
              grossWeightUnit: 'KG',
              netWeight: 27680,
              netWeightUnit: 'KG',
              grossVolume: 68,
              grossVolumeUnit: 'CBM',
              freightPrepaidUnit: 'CBM',
              freightCollectUnit: 'CBM',
              totalGoodsValueUnit: 'CBM',
              insuranceValueUnit: 'CBM',
              goodsDescription: 'MOTORCYCLE SPARE PARTS. NET WEIGHT 27320 KGS FREIGHT COLLECT CARGO DELIVERY TO JEFAG ICD',
              shipper: {
                tin: 'null',
                code: 'null',
                name: 'WHITE DRAGON CO. LTD',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactPhone: 'null'
              },
              consignee: {
                consigneeId: 13,
                code: 'null',
                name: 'ANET KIPOMELA MWAMBOSA',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              notify: {
                code: 'null',
                name: 'SAME AS CONSIGNEE',
                addressline1: 'null',
                addressline2: 'null',
                addressline3: 'null',
                locationCode: 'null',
                locationName: 'null',
                contactName: 'null',
                contactTelephone: 'null',
                tin: 'null'
              },
              masterBL: {
                blnumber: 'string',
                crn: 'string'
              },
              goods: [
                {
                  goodsItemNo: 0,
                  commodityCode: 'string',
                  commoditySpecification: 'string',
                  hsCode: 'string',
                  description: 'string',
                  packageQuantity: 0,
                  packageType: 'string',
                  marksNumbers: 'string',
                  vehicleVIN: 'string',
                  vehicleModel: 'string',
                  vehicleMake: 'string',
                  vehicleOwnDrive: 'string',
                  containerID: 12,
                  grossWeight: 0,
                  grossWeightUnit: 'string',
                  netWeight: 0,
                  netWeightUnit: 'string',
                  volume: 0,
                  volumeUnit: 'string',
                  length: 0,
                  lengthUnit: 'string',
                  width: 0,
                  widthUnit: 'string',
                  height: 0,
                  heightUnit: 'string',
                  goodsValue: 0,
                  goodsValueUnit: 'string',
                  dgs: {
                    imdgClass: 'string',
                    unNumber: 'string',
                    classCode: 'string',
                    imdgPage: 'string',
                    description: 'string',
                    flashPointValue: 0,
                    flashPointUnit: 'string',
                    shipmFlashptValue: 0,
                    shipmFlashptUnit: 'string',
                    packingGroup: 'string',
                    trEMcard: 'string',
                    marPolCategory: 'string',
                    contactCompany: 'string',
                    contactPerson: 'string',
                    contactTelephone: 'string',
                    contactTelephone24H: 'string',
                    ems: 'string',
                    mfag: 'string'
                  }
                }
              ],
              crn: 'string',
              poo: 'CNNSA',
              pol: 'CNNSA',
              pod: 'TZDAR',
              fpod: 'TZDAR'
            }

          ]
        }],
      requestFlag: 'EXP',

      isHovered: [],

      ifThereIsParam: '',

      finalUserPermissions: [],

      tableHeaders: ['Call ID', 'MRN', 'Voyage', 'liner', 'Doc Time', 'Terminal', 'ETA', 'ATA'],
      tableDataKeys: ['callId', 'mrn', 'voyage', 'lineName', 'docTime', 'terminal', 'eta', 'ata']

      // showMarkDuplicate: false,
    }
  },
  validations: {
    searchParameter: { required }
  },
  methods: {
    ...mapActions('loadinglists', [
      // "loadApprovedLoadingListsOnCreated",
      'searchApprovedLoadingList',
      'loadAllApprovedLoadingLists',
      'loadApprovedLoadingLists'
    ]),
    loadParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('loadinglists/pageSizing', this.pageSize)
        this.$store.dispatch('loadinglists/requestStatusing', this.status)
        this.$store.dispatch('loadinglists/pageNumbering', this.pageNo)
        this.$store.dispatch(
          'loadinglists/requestFlagging',
          this.requestFlag
        )

        resolve()
      })
    },

    loadPageableRequestOnCreated () {
      this.loadParameters().then(() => {
        this.loadApprovedLoadingListsOnCreated({})
          .then(() => {
            this.requests = this.getRequestList
            this.pageCount = this.getPageCount
            this.myFirstLoader = this.getMyFirstLoader
            this.noDataFound = this.getNoDataFound
            this.SuccessMessage = this.getSuccessAlert
            this.ErrorMessage = this.getErrorMessage
            this.ErrorAlert = this.getErrorAlert
            this.SuccessAlert = this.getSuccessMessage
            this.pageNo = this.getPageNo
            this.pageSize = this.getPageSize
          })
          .catch(() => {})

        this.myFirstLoader = this.getMyFirstLoader
      })
    },

    onChange (event) {
      this.$store.dispatch('loadinglists/pageSizing', event)
      this.$store.dispatch('loadinglists/pageNumbering', 0)

      this.pageSize = this.getPageSize
      this.pageNo = this.getPageNo

      if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.searchParameter)
      } else {
        this.loadApprovedLoadingLists({}).then(() => {
          this.requests = this.getRequestList
          this.muLoader = this.getMyLoader
          this.pageCount = this.getPageCount
        })
        this.myLoader = this.getMyLoader
      }
    },

    nextPage () {
      this.$store.dispatch(
        'loadinglists/pageNumbering',
        (this.pageNo = this.pageNo + 1)
      )
      this.pageNo = this.getPageNo

      if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
      } else {
        this.loadApprovedLoadingList({}).then(() => {
          this.requests = this.getRequestList
          this.myLoader = this.getMyLoader
          this.pageCount = this.getPageCount
        })

        this.myLoader = this.getMyLoader
      }
    },

    prevPage () {
      this.$store.dispatch(
        'loadinglists/pageNumbering',
        (this.pageNo = this.pageNo + 1)
      )

      this.pageNo = this.getpageNo

      if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
      } else {
        this.loadApprovedLoadingList({}).then(() => {
          this.requests = this.getRequestList
          this.myLoader = this.getMyLoader
          this.pageCount = this.getPageCount
        })

        this.myLoader = this.getMyLoader
      }
    },

    firstPage () {
      this.$store.dispatch('loadinglists/pageNumbering', 0)
      this.pageNo = this.getPageNo

      if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
      } else {
        this.loadApprovedLoadingList({}).then(() => {
          this.requests = this.getRequestList
          this.myLoader = this.getMyLoader
          this.pageCount = this.getPageCount
        })

        this.myLoader = this.getMyLoader
      }
    },

    lastPage () {
      this.pageCount = this.getPageCount
      this.$store.dispatch(
        'loadinglists/pageNumbering',
        (this.pageNo = this.pageCount - 1)
      )
      this.pageNo = this.getPageNo

      if (this.getHasSearchedVariable) {
        this.searchRequestByName(this.getSearchParameter)
      } else {
        this.loadApprovedLoadingList({}).then(() => {
          this.requests = this.getRequestList
          this.myLoader = this.getMyLoader
          this.pageCount = this.getPageCount
        })

        this.myLoader = this.getMyLoader
      }
    },

    loadAllPageable (searchParams) {
      this.searchParameter = searchParams

      if (this.searchParameter === '' && this.getHasSearchedVariable) {
        this.loadAllApprovedLoadingList({}).then(() => {
          this.loadApprovedLoadingList({}).then(() => {
            this.requests = this.getRequestList
            this.myLoader = this.getMyLoader
            this.pageCount = this.getPageCount
            this.pageNo = this.getPageNo
            this.pageSize = this.getPageSize
          })

          this.myLoader = this.getMyLoader
          this.noDataFound = this.getNoDataFound
          this.hasSearchedVariable = this.getHasSearchedVariable
          this.reloadAllRequest = this.getReloadAllRequest
          this.searchParameter = this.getSearchParameter
          this.pageCount = this.getPageCount
          this.pageNo = this.getPageNo
          this.pageSize = this.getPageSize
        }).catch(() => {

        })
      }
    },

    reloadByIcon (searchParam) {
      this.loadAllApprovedLoadingList({}).then(() => {
        this.loadApprovedLoadingList({}).then(() => {
          this.requests = this.getRequestList
          this.myLoader = this.getMyLoader
          this.pageCount = this.getPageCount
          this.pageNo = this.getPageNo
          this.pageSize = this.getPageSize
        })

        this.myLoader = this.getMyLoader
        this.noDataFound = this.getNoDataFound
        this.hasSearchedVariable = this.getHasSearchedVariable
        this.reloadAllRequest = this.getReloadAllRequest
        this.searchParameter = this.getSearchParameter
        this.pageCount = this.getPageCount
        this.pageNo = this.getPageNo
        this.pageSize = this.getPageSize
      }).catch(() => {

      })
    },

    // TODO: Mark as duplicate is not done due to the fact that i do not know if these have any
    // duplicate functionality

    showToolTip (id) {
      this.isHovered = [id]
    },

    hideToolTip (id) {
      this.isHovered.splice(this.isHovered.indexOf(id), 1)
    },

    goToApplication (documentId) {
      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
      this.$router.push('/shipping-tallying/document-view/' + documentId)
    },

    closeModal () {
      this.showMarkDuplicate = false
    }

  }
}
</script>
