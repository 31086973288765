<template>
  <card>
    <div class="row">
      <div class="col-12">
        <data-table
          :tableHeaders="tableHeaders"
          :tableData="filteredRequests"
          :tableDataKeys="tableDataKeys"
          :pageSizeProp="pageSize"
          :pageNo="pageNo"
          :hasFilter="false"
          :myLoader="loading"
          :myFirstLoader="loading"
          :noDataFound="noDataFound"
          :searchParameterProp="searchParameter"
          :loadingTitle="'Please wait while system is loading Statement'"
          :hasManageButton="false"
          :totalElements="totalElements"
          :totalPages="totalPages"
          :isFirst="isFirst"
          :isLast="isLast"
          :isHovered="[]"
          :pageNumber="pageNumber"
          :is-hovered="false"
          :permissions="[ {key: 'searchPermission', value: 'PRINCIPAL_TRANSACTION_BY-REFERENCE_{REFERENCENO}_GET'} ]"
          :isPageable="false"
          @onChange="onChange"
          @goToPage="goToPage"
          @goToBillGenerate="goToApplication"
        >
          <div slot = "pagination">
            <paginate
              v-model="getIsPage"
              :page-count=parseInt(totalPages)
              :page-range="3"
              :margin-pages="2"
              :click-handler="goToPage"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'">
            </paginate>
          </div>
        </data-table>

      </div>
    </div>
  </card>

</template>

<script>
    import AGENCYAPI from "../../../../api/agency";

    export default {
        name: "sub-disbersmentTransactions",


      components:{
        DataTable: () => import('../../../../components/DataTable/dataTable'),

      },

      created() {
        this.$store.dispatch('pagenames/currentPageName', 'Sub-Disbursement Transactions');
        this.transactions();
      },

      props:{
        referenceId:{
          type:String
        }
      },

      computed:{
        getIsPage: {

          get: function () {
            return this.isPage
          },
          set: function (newValue) {
            this.isPage = newValue;
          }

        }
      },

      data(){

        return{
          tableHeaders:['Reference No','Description','Transaction Type','Amount','Balance', 'Transaction Date'],
          tableDataKeys:['referenceNo','description','transactionType','amount','balance', 'transactionDate'],
          statement:[],
          filteredRequests:[],
          pageNo: 0,
          pageSize: 2000,
          totalElements: "",
          totalPages: "",
          isFirst: false,
          isLast: false,
          pageNumber: '',
          pageCount: 1,
          noDataFound:false,
          searchParameter: '',
          isPage: 0,

          params:"",
          color: 'green',
          size: '12px',
          size1: '9px',
          loading:false,
        }
      },

      methods:{

        sortingData(data) {
          this.filteredRequests=[];
          for(let x = 0 ; x < data.length ; x++) {

            const requestObject = {
              ...data[x],
              amount:this.putComma(data[x].amount,2),
              balance:this.putComma(data[x].balance,2)
            };

            this.filteredRequests.push(requestObject)

          }

        },

        transactions(){

          this.loading=true;

          let reference=this.referenceId!==undefined? this.referenceId:this.$route.params.referenceId;

          AGENCYAPI.getDisbursementTransactions(reference).then((resp)=>{
            this.loading=false;
            if(resp.data.status){
              this.statement=resp.data.data;

              this.sortingData(this.statement);
            }
          }).catch((err)=>{
            this.loading=false;
            this.loadNotification('error', 4000, 'Loading Statement',
              'Failed To Load Customer Statement',
              'response', 1000 , true  , true)
          });
        },

        onChange(event) {
          this.pageSize=event;
          this.pageNo=0;
        },

        goToPage(page) {

          if(page > 0) {page = page - 1;}
          this.isPage = page + 1;
          this.pageNo=page;

          this.loadAllreports();

        },

        goToApplication(){

        }
      }
    }
</script>

<style scoped>

</style>
