<template>
<div>
  <div class="text-center">
    <crud-notification
      :error-message="ErrorMessage"
      :success-message="SuccessMessage"
      :error-alert="ErrorAlert"
      :success-alert="SuccessAlert"
      @fadeMe="fadeMe"
      @enter="enter"
    />
  </div>
  <data-table
    :table-headers="containerHeaders"
    :table-data="containers"
    :table-data-keys="tableDataKeys"
    :my-first-loader="myFirstLoader"
    :is-pageable="false"
    :no-data-found="noDataFound"
    :hasFilter="false"
    :hasPagination="false"
    :has-manage-button="false"
    :has-search-form="false"
    :has-view-button="false"
    :has-edit-button="actionButton"
    :has-delete-button="actionButton"
    :loading-title="'Please wait while system is loading Container Details'"
    :permissions="[ {key: 'searchPermission', value: 'CFAREPORT_ASYNC_REPORT_GET'} ]"
    @goToDelete="deleteContainer"
    @goToEdit="editContainer"
  />

</div>
</template>

<script>
    import AGENCY from "../../../api/agency";
    import CrudNotification from '../../../components/DataTable/crudNotification'

    export default {
        name: "containers",
      props:{
        shippingOderId:{
          type:String,
        },
        actionButton:{
          type:Boolean,
          default:true
        }
      },

      components:{
        DataTable: () => import('../../../components/DataTable/dataTable'),
        CrudNotification
      },

      created() {
          this.getAllContainersForShippingOrder();
      },

      data(){
          return {
            orderId:this.shippingOderId,
            containers:[],
            tableDataKeys:[
              "containerNo",
              "containerSize",
              "grossWeight",
              "description"
            ],
            containerHeaders:['Container Number','Container Size','Weight','Description'],
            myFirstLoader: false,
            noDataFound:true,

            ErrorMessage: '',
            SuccessAlert: false,
            ErrorAlert: false,
            SuccessMessage: '',
          }
      },

      methods:{

          getAllContainersForShippingOrder(){
            AGENCY.containersForShippingOrder(this.orderId).then((response)=>{
              this.containers=response.data.data;
              this.noDataFound=false;
            })
          },

        editContainer(e){
          this.$router.push({name:"editContainer",params:{containerId:e.id,shippingOrderId:this.orderId}});

        },

        deleteContainer(e){
          AGENCY.deleteContainers(e.id).then((response)=>{
            this.containers=response.data.data;
            this.ErrorMessage = '';
            this.SuccessAlert = true;
            this.ErrorAlert = false;
            this.SuccessMessage = 'Container Deleted';
            this.getAllContainersForShippingOrder();
          }).catch((error)=>{
            this.ErrorMessage = 'Failed Delete Container';
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.SuccessMessage = '';
          })
        },
        enter: function (el, done) {
          var that = this;

          setTimeout(function () {
            that.SuccessAlert = false;
            that.ErrorAlert = false;
          }, 3000); // hide the message after 3 seconds
        },
        fadeMe: function () {
          if (this.SuccessAlert) {
            this.SuccessAlert = !this.SuccessAlert;
          } else {
            this.ErrorAlert = !this.ErrorAlert;
          }
        }

      }
    }
</script>

<style scoped>

</style>
