<template>
  <div>
    <card>
      <crud-notification
        :error-message="ErrorMessage"
        :success-message="SuccessMessage"
        :error-alert="ErrorAlert"
        :success-alert="SuccessAlert"
        @fadeMe="fadeMe"
        @enter="enter"
      />
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-success custom-btn mr-2" style="float: right; width: 220px" @click.prevent="openModal('ADD')">
            Add Delivery Order Charge
          </button>
        </div>
      </div>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="false"
        :total-elements="totalElements"
        :total-pages="totalPages"
        :is-first="isFirst"
        :is-last="isLast"
        :no-data-found="noDataFound"
        :page-number="pageNumber"
        :date-prop="'Created At '"
        :has-manage-button="false"
        :has-view-button="false"
        :has-search-form="false"
        :loading-title="'Please wait while system is loading delivery order charges'"
      />
    </card>
    <modal v-if="showModal">
      <div slot="header" class="modal-title col-md-12">
        <div class="row">
          <div class="col-md-12">
            <b>Add Delivery Order Charge</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <form method="POST" @submit.prevent="saveFormDetails">
            <div class="row">
              <div class="col-md-5">
                <label> Select Tariff Fee </label>
                <model-list-select
                  :list="fees"
                  v-model="agencyChargeDetails.tariffFeeId"
                  option-value="tariffFeeId"
                  class="form-control special-bottom"
                  option-text="tariffFeeName"
                  placeholder="Select Associated Tariff Fee"
                />
                <div v-if="tariffFeeError">
                  <div class="error" v-if="!$v.agencyChargeDetails.tariffFeeId.required">
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <label> Select Tariff Item </label>
                <model-list-select
                  :list="tariffItems"
                  v-model="agencyChargeDetails.tariffItemId"
                  class="form-control special-bottom"
                  option-value="tariffItemId"
                  option-text="tariffItemName"
                  placeholder="Select Associated Tariff Item"
                />
                <div v-if="tariffItemError">
                  <div class="error" v-if="!$v.agencyChargeDetails.tariffItemId.required">
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="custom-control custom-checkbox mt-2 " style="padding-top: 30px">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="mandatory"
                    v-model="agencyChargeDetails.mandatory"
                  >
                  <label
                    class="custom-control-label"
                    for="mandatory"
                  >Mandatory Charge</label>
                </div>
              </div>


            </div>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right pr-2"
                  style="margin-inline-start:20px"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>

          </form>
        </card>
      </div>
    </modal>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>


<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader';
  import GlobalModal from '../../../components/globalLoader';
  import DataTable from '../../../components/DataTable/updatedTable';
  import { mapActions, mapGetters } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import API from "../../../api";
  import API_REVENUE from "../../../api";
  import { ModelListSelect } from 'vue-search-select';
  import Modal from '../../../components/Inputs/Modal';
  import CrudNotification from '../../../components/DataTable/crudNotification'

  export default {
    name: "agencyChargesList",

    components: {
      ModelListSelect,
      PulseLoader,
      GlobalModal,
      DataTable,
      Modal,
      CrudNotification
    },
    created() {
      this.$store.dispatch('pagenames/currentPageName', 'Delivery Order Charges');
      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.loadDetails();
    },
    data() {
      return {

        shippingOrderId: '',
        agencyChargeDetails : {
          mandatory: false,
          tariffFeeId: '',
          tariffItemId: ''
        },

        tariffFeeError:false,
        tariffItemError:false,
        fees:[],
        pageNo: 0,

        color: 'green',
        size: '12px',
        loading: true,

        SuccessMessage: '',
        ErrorMessage: '',
        SuccessAlert: false,
        ErrorAlert: false,

        pageSize: 15,
        totalElements: '',
        noDataFound:'',
        totalPages: '',
        isFirst: false,
        isLast: false,
        pageNumber: '',
        isPageable: true,
        requests: [],
        myLoader: false,
        myFirstLoader: false,
        reloadRequests: false,
        tableHeaders: ['Tariff Item', 'Tariff Fee', 'Mandatory'],
        tableDataKeys: ['tariffItemName', 'tariffFeeName', 'isMandatory'],
        tariffGroups:[],
        tariffFees:[],
        tariffFeeItems:[],
        tariffItems:[],
        showModal:false,
        editMyLoader:false

      };
    },
    computed: {
      ...mapGetters('agencyCharge', [

        'getMyLoader',
        'getPageNo',
        'getAgencyChargeDetails',
        'getNoDataFound',
        'getSuccessAlert',
        'getErrorMessage',
        'getErrorAlert',
        'getSuccessMessage',
        'getloadingAgencyCharges'

      ])
    },
    validations:{
      agencyChargeDetails : {
        mandatory: { required },
        tariffFeeId: { required },
        tariffItemId: { required }
      },
    },
    methods:{
      ...mapActions('agencyCharge',[
        'getAllAgencyCharges',
        'getAgencyChargesById',
        'addAgencyCharge'
      ]),


      loadAllAgencyChargesOnCreated(){
        this.getAllAgencyCharges().then(()=>{
          this.requests = this.formatDetails(this.getAgencyChargeDetails, 'TARIFF-FEE');
          this.noDataFound = this.getNoDataFound;
          this.myFirstLoader = this.getloadingAgencyCharges;
          this.myLoader = this.getloadingAgencyCharges;
        }).catch(()=>{})
      },

      saveFormDetails(){
        if (this.$v.agencyChargeDetails.tariffItemId.$invalid || this.$v.agencyChargeDetails.tariffFeeId.$invalid ){

          if (this.$v.agencyChargeDetails.tariffItemId.$invalid){
            this.tariffItemError = true;
          }
          if (this.$v.agencyChargeDetails.tariffFeeId.$invalid){
            this.tariffFeeError = true
          }
        }else{
          this.$store.dispatch('agencyCharge/savingDetails',this.agencyChargeDetails);
          this.addAgencyCharge({}).then(()=>{
            this.SuccessAlert = this.getSuccessAlert;
            this.ErrorAlert = this.getErrorAlert;
            this.editMyLoader = this.getMyLoader;
            this.loadAllAgencyChargesOnCreated();
            this.closeModal();
          }).catch((e)=>{})
        }
      },

      loadDetails(){
        return new Promise((resolve)=>{
          this.loadTariffFees()
          this.loadTariffItems()
          resolve();
        }).then(()=>{
          this.loadAllAgencyChargesOnCreated();
        }).catch((e)=>{

        })

      },

      loadTariffItems () {
        API_REVENUE.getTariffItemsActive().then((response) => {
          if (response.data.status) {
            this.tariffItems = JSON.parse(JSON.stringify(response.data.data))
          } else {
          }
        }).catch((e) => {

        })
      },

      loadTariffFees () {
        API_REVENUE.getActiveTariffFee().then((response) => {
          if (response.data.status){
            this.fees = JSON.parse(JSON.stringify(response.data.data))
            // this.fees = this.formatDetails(JSON.parse(JSON.stringify(response.data.data)), 'TARIFF-FEE');
          }
        }).catch((e) => {
        })
      },
      formatDetails(data, type){
        if (data.length > 0){
          for (let i = 0; i < data.length; i++) {
            data[i].isMandatory = data[i].mandatory ? 'YES':'NO';
            data[i].tariffFeeName =  data[i].tariffFee.tariffFeeName;
            data[i].tariffItemName = data[i].tariffItem.tariffItemName;
            }
          return data
          }else{
            console.log('no data yet')
            return []
          }
          // return data;
      },
      openModal(modalType){
        if (modalType === 'ADD'){
          this.showModal = true;
        }
      },

      closeModal() {
        this.showModal = false;
        this.agencyChargeDetails = {
          mandatory: true,
          tariffFeeId: '',
          tariffItemId: ''
        }
      },
      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert
        } else {
          this.ErrorAlert = !this.ErrorAlert
        }
      },
      enter: function (el, done) {
        var that = this

        setTimeout(function () {
          that.SuccessAlert = false
          that.ErrorAlert = false
        }, 3000) // hide the message after 3 seconds
      }


    }
  }
</script>

<style scoped>
  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: 120px;
    background-color: #FFFFFF !important;
  }
</style>
