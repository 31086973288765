<template>
  <div class="container-fluid">
    <modal v-if="showModal">
      <div slot="body">
        <div class="col-md-12" v-if="attachmentModal">
          <div class="row">
            <div class="col-md-12">
              <strong>Attachments</strong>
              <div class="input-group mb-3">
                <div class="col-md-6 mt-3">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="inputGroupFile01" ref="fileupload" @change="selectedFile" accept=".pdf">
                    <label class="custom-file-label" for="inputGroupFile01">{{fileData!==''? fileData.name:'Choose File'}}</label>
                  </div>
                  <div v-if="fileDataError">
                    <div
                      class="error"
                      v-if="!$v.fileData.required"
                    >
                      This field is required
                    </div>
                  </div>

                </div>
                <div class="col-md-6 mt-3">
                  <select class="custom-select" v-model="selectedAttachmentTypeId">
                    <option selected>Select Attachment type</option>
                    <option :value="attachment.attachmentTypeId" v-for="(attachment,index) in attachmentTypes">{{attachment.attachmentTypesName}}</option>
                  </select>
                  <div v-if="selectedAttachmentTypeIdError">
                    <div
                      class="error"
                      v-if="!$v.selectedAttachmentTypeId.required"
                    >
                      This field is required
                    </div>
                  </div>

                </div>
                <div class="col-md-12 mt-3">
                  <div class="form-group">
                    <textarea class="form-control" placeholder="Description" rows="3" v-model="description"/>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <button class="btn btn-default btn-fill float-right" @click="closeModal">Close</button>
                  <button class="btn btn-success float-right mr-3" @click="uploadFile">Save</button>
                </div>
                <div class="col-md-12">
                  <div class="text-center">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      style="margin-top: 4px;"
                    />
                  </div>
                </div>

                <div class="container-fluid mt-3">
                  <div class="legend">
                    <h6 class="legend-title">
                      Files
                    </h6>
                    <div class="col-md-12">
                      <strong>Icons</strong><br/>
                      <span class="mr-2">
                        <strong class="mr-2">Print</strong>
                        <font-awesome-icon icon="print" :style="{ color: 'green' }" size="lg"/>
                      </span>
                      <span>
                        <strong class="mr-2">Delete</strong>
                        <font-awesome-icon icon="trash-alt" :style="{ color: 'red' }" size="lg"/>
                      </span>
                    </div>
                    <div class="col-md-12">
                      <data-table
                        :tableHeaders="tableHeadersFiles"
                        :tableData="uploadedFiles"
                        :tableDataKeys="tableDataKeysFiles"
                        :pageSizeProp="pageSize"
                        :pageNo="pageNo"
                        :myLoader="myLoader"
                        :myFirstLoader="myFirstLoader"
                        :noDataFound="noDataFound"
                        :loadingTitle="'title'"
                        :has-view-button="true"
                        :totalElements="totalElements"
                        :totalPages="totalPages"
                        :isFirst="isFirst"
                        :isLast="isLast"
                        :pageNumber="pageNumber"
                        :hasFilter="false"
                        @goToView="getFileFromMinio"
                        :is-pageable="false"
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-if="formData">
          <div class="row">
            <div class="col-md-12">
              <h4><svg-icon
                class="nc-icon"
                icon="document"
                :has-fill="true"
              />&nbsp;&nbsp;&nbsp;&nbsp;Fill all Fields To Request Delivery order</h4>
              <div class="text-center">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label for="bl">Bill Of Lading Number</label>
                <input type="text" class="form-control" id="bl" v-model="blNumber"/>
                <div v-if="blnumberError">
                  <div class="error text-danger">
                    <span v-if="!$v.blNumber.required">Field is required</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="vg">Voyage Number</label>
                <input type="text" class="form-control" id="vg" v-model="voyageNumber"/>
                <div v-if="voyageNumber">
                  <div class="error text-danger">
                    <span v-if="!$v.voyageNumber.required">Field is required</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="cargoDeliveryType">Cargo Delivery Type</label>
                <select class="form-control" id="cargoDeliveryType" v-model="type">
                  <option value=""></option>
                  <option value="LOCAL_DELIVERY">Local Delivery</option>
                  <option value="TRANSIT">Transit</option>
                </select>
                <div v-if="typeError">
                  <div class="error text-danger">
                    <span v-if="!$v.type.required">Field is required</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="consigneeName">Consignee Name</label>
                <input type="text" class="form-control" id="consigneeName" v-model="consigneeName">
                <div v-if="consigneeNameError">
                  <div class="error text-danger">
                    <span v-if="!$v.consigneeName.required">Field is required</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <label for="email">Consignee Email</label>
                <input type="text" class="form-control" id="email" v-model="emailAddress">
                <div v-if="emailAddressError">
                  <div class="error text-danger">
                    <span v-if="!$v.emailAddress.required">Field is required</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="mobileNumber">Consignee Phone Number</label>
            <input type="text" class="form-control" id="mobileNumber" v-model="mobileNumber">
            <div v-if="mobileNumberError">
              <div class="error text-danger">
                <span v-if="!$v.mobileNumber.required">Field is required</span>
              </div>
            </div>
          </div>

          <div class="form-group form-check">
            <label for="destination">Destination</label>
            <input type="text" class="form-control" id="destination" v-model="destination">
            <div v-if="destinationError">
              <div class="error text-danger">
                <span v-if="!$v.destination.required">Field is required</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-check-label" for="description">Description</label>
            <textarea class="form-control" id="description" rows="3" v-model="description"></textarea>
            <div v-if="descriptionError">
              <div class="error text-danger">
                <span v-if="!$v.description.required">Field is required</span>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary float-right" @click.prevent="closeModal">Close</button>
          <button type="submit" class="btn btn-primary float-right mr-2" @click.prevent="submit">Next</button>
        </div>

      </div>
    </modal>

    <div class="row">
      <div class="col-md-12">
        <h3>TASAC DELIVERY ORDER REQUESTS</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <span class="float-right pointer" @click="openModal">
          <font-awesome-icon icon="plus" :style="{ color: 'green' }" size="lg"/>&nbsp;&nbsp;New Delivery Order Request
        </span>
      </div>
    </div>
    <card>
      <div class="row mt-2">
        <div class="col-md-12">
          <ul class="nav-justified">
            <li>
              <a @click.prevent="setActive('CREATED' , 'CREATED_TAB')" :class="[ isActive('CREATED_TAB') ? 'is-active-created' : '' ]" href="#CREATED">CREATED</a>
            </li>
            <li>
              <a @click.prevent="setActive('REJECTED' , 'REJECTED_TAB')" :class="[ isActive('REJECTED_TAB') ? 'is-active-rejected' : '' ]" href="#REJECTED">REJECTED</a>
            </li>
            <li>
              <a @click.prevent="setActive('ACCEPTED' , 'ACCEPTED_TAB')" :class="[ isActive('ACCEPTED_TAB') ? 'is-active-accepted' : '' ]" href="#REJECTED">ACCEPTED</a>
            </li>
          </ul>
        </div>

        <div class="col-md-12">
          <data-table
            :tableHeaders="tableHeaders"
            :tableData="filteredRequests"
            :tableDataKeys="tableDataKeys"
            :pageSizeProp="pageSize"
            :pageNo="pageNo"
            :myLoader="myLoader"
            :myFirstLoader="myFirstLoader"
            :noDataFound="noDataFound"
            :loadingTitle="'title'"
            :has-custom-button="true"
            custom-button-name="Manage"
            @fireCustomMethod="uploadAttachmentModal"
            :totalElements="totalElements"
            :totalPages="totalPages"
            :isFirst="isFirst"
            :isLast="isLast"
            :pageNumber="pageNumber"
            :hasFilter="false"
            @onChange="onChange"
            @goToPage="goToPage"
            :is-pageable="false"
          />

        </div>
      </div>

    </card>
    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Downloading requested file, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>
  import Card from '../../../components/Cards/Card.vue';
  import DataTable from '../../../components/DataTable/dataTable';
  import Modal from '../../../components/Inputs/Modal';
  import {required} from "vuelidate/lib/validators";
  import SvgIcon from '../../../components/SvgLoader/svgLoader';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import { ModelListSelect } from 'vue-search-select'
  import AGENCY_API from "../../../api/agency";
  import { mapActions, mapGetters } from 'vuex';
  import GlobalModal from '../../../components/globalLoader'



  export default {
    components: {
      Card,
      DataTable,
      GlobalModal,
      Modal,
      SvgIcon,
      PulseLoader,
      ModelListSelect
    },
    name: "Agcustomer",

    created() {
      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      console.log(this.finalUserPermissions);

      try {
        this.status=localStorage.getItem('DOtabstatus');
        this.selectedTab=localStorage.getItem('DOtab');
      }catch (e) {
        // this.status="CREATED";
        // this.selectedTab="CREATED_TAB"
      }
      this.setActive();
      this.sortingData(this.data);
      this.getAttachmentTypes();
      //this.listAllFiles();
    },
    validations:{
      blNumber:{required},
      voyageNumber:{required},
      type:{required},
      consigneeName:{required},
      destination:{required},
      description:{required},
      emailAddress:{required},
      mobileNumber:{required},
      selectedAttachmentTypeId:{required},
      fileData:{required},

    },

    computed: {

    ...mapGetters('global', [

      'getGlobalLoader'

    ])

  },

    data(){

      return {
        finalUserPermissions:[],
        filteredRequests: [],
        formData:false,
        fileData:'',
        pageCount:0,
        globalLoader: false,
        pageNo:1,
        tableHeaders:['Cargo Delivery Type','Destination','BlNumber','Description','Date','Reject Reason'],
        tableDataKeys:['cargoDeliveryType','destination','blNumber','description','createdDate','rejectionReason'],
        tableHeadersFiles:['Attachment'],
        tableDataKeysFiles:['attachmentTypesName'],
        noDataFound:false,
        loader:false,
        data: [],
        pageSize: 15,
        totalElements: "",
        totalPages: "",
        isFirst: false,
        isLast: false,
        pageNumber: '',
        myLoader: false,
        isHovered: [],
        myFirstLoader: false,
        showModal:false,
        selectedAttachmentTypeId:'',
        attachmentModal:false,
        blNumber:"",
        voyageNumber:"",
        type:"",
        cfAgents:[],
        consigneeName:"",
        destination:"",
        description:"",
        emailAddress:"",
        mobileNumber:"",
        cargoDeliveryType:"",

        color: 'green',
        size: '12px',
        loading: false,

        blnumberError:false,
        voyageNumberError:false,
        typeError:false,
        consigneeNameError:false,
        destinationError:false,
        descriptionError:false,
        emailAddressError:false,
        mobileNumberError:false,
        selectedAttachmentTypeIdError:false,
        fileDataError:false,
        selectedRequestId:'',
        uploadedFiles:[],
        selectedTab:"CREATED_TAB",
        status:"CREATED",
      }
    },

    methods:{

      ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    loadParameters (fileUrl, fileName) {

      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
      })

    },

    getFileFromMinio (fileUrl, fileName) {

      this.loadParameters(fileUrl, fileName).then(() => {

      this.loadFileFromMinio({}).then((res) => {

          if(res.status === 204) {

              this.loadNotification('warn', 4000, 'File Download',
              'File not found, please contact system admin',
              'response', 1000 , true  , true)

          } else {

            this.loadNotification('success', 4000, 'File Download',
              'File has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true)


          }

            this.globalLoader = this.getGlobalLoader

        }).catch(() => {

          this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

          this.globalLoader = this.getGlobalLoader

        })

        this.globalLoader = this.getGlobalLoader

      }).catch(() => {

      })

    },

      onChange () {},
      goToPage () {},

      setActive(status=null , selectedTab=null){
        if(status!==null && selectedTab!==null){
          let storedStatus=localStorage.setItem('DOtabstatus',status);
          let storedTab=localStorage.setItem('DOtab',selectedTab);

          if(storedStatus && storedTab){
            this.status=storedStatus;
            this.selectedTab=storedTab;
          }else {
            this.status=status;
            this.selectedTab=selectedTab;
          }
          this.requests=[];

          this.getMyDeliveryOrders();
        }else {

          let storedStatus=localStorage.getItem('DOtabstatus');
          let storedTab=localStorage.getItem('DOtab');

          if(storedStatus && storedTab){
            this.status=storedStatus;
            this.selectedTab=storedTab;
          }else {
            this.status="CREATED";
            this.selectedTab="CREATED_TAB";
          }

          this.requests=[];
          this.getMyDeliveryOrders();
        }

      },
      isActive(tab){
        return this.selectedTab===tab;
      },
      codeAndNameAndDesc(data){
        return `${data.name} `
      },

      uploadAttachmentModal(data){
        console.log(data);
        this.$router.push({name:"deliveryOrdersCustomerRequestsDetails",params:{blNumber:data.id}});
      },

      viewRequest(requestId){
        this.selectedRequest=requestId;
      },

      sortingData(data) {

        for(let x = 0 ; x < data.length ; x++) {

          const requestObject = {
            ...data[x],
            createdDate: this.dateTimeToHuman(data[x].createdDate)
          };

          this.filteredRequests.push(requestObject)

        }

      },

      getMyDeliveryOrders(){
        this.loading=true;
        this.data=[];
        this.filteredRequests=[];
        AGENCY_API.customerDeliveryOrder().then((response)=>{
          this.loading=false;
          for(let i=0;i<response.data.data.content.length;i++){
            if(response.data.data.content[i].status===this.status){
              this.data.push(response.data.data.content[i])
            }
          }
          this.sortingData(this.data)
        }).catch((error)=>{
          this.loading=false;
        });
      },

      submit(){
        if(this.$v.blNumber.required &&
          this.$v.type.required &&
          this.$v.consigneeName.required &&
          this.$v.destination.required &&
          this.$v.description.required &&
          this.$v.voyageNumber.required
        ){
          this.blnumberError=false;
          this.voyageNumberError=false;
          this.typeError=false;
          this.descriptionError=false;
          this.destinationError=false;
          this.emailAddressError=false;
          this.mobileNumberError=false;
          this.loading=true;



          AGENCY_API.requestDeliveryOrder({

            blNumber: this.blNumber,
            voyageNumber:this.voyageNumber,
            cfaAgentId:null,
            cargoDeliveryType: this.type,
            consigneeName: this.consigneeName,
            description: this.description,
            destination: this.destination,
            emailAddress: this.emailAddress,
            mobileNumber: this.mobileNumber,
            traLicenceNumber: "string"

          }).then((response)=>{
            this.loading=false;
            this.attachmentModal=true;
            this.formData=false;
            this.selectedRequestId=response.data.data.id;
            this.listAllFiles();
            this.blNumber="";
            this.cargoDeliveryType="";
            this.consigneeName="";
            this.description="";
            this.destination="";
            this.emailAddress="";
            this.mobileNumber="";
            this.getMyDeliveryOrders();

            this.type="";

          }).catch(()=>{
            this.notifyUser('error', 'Error', `Delivery order Request Failed please Contact TASAC` )
            this.loading=false;

          })

        }else {

          this.blnumberError=true;
          this.voyageNumberError=true;
          this.typeError=true;
          this.consigneeNameError=true;
          this.destinationError=true;
          this.descriptionError=true;
          this.emailAddressError=true;
          this.mobileNumberError=true;
        }
      },


      nextPage () {
        this.pageNo = this.pageNo + 1;
      },

      prevPage () {
        this.pageNo = this.pageNo - 1;
      },

      firstPage () {
        this.pageNo = 0

      },

      lastPage () {
        this.pageNo = this.getPageNo -1;
      },

      openModal(type=null,selectedRequestId=null){
        if(type==='ATTACHMENTS'){
          this.attachmentModal=true;
          this.selectedRequestId=selectedRequestId;
          this.listAllFiles();
        }else {
          this.formData=true
        }
        this.showModal=true;
      },

      closeModal(){
        this.showModal=false;
        this.attachmentModal=false;
        this.formData=false;
        this.selectedRequestId="";
        this.$refs.fileupload.value=null;

      },

      selectedFile(event){
        this.fileData = event.target.files[0]
      },

      getAttachmentTypes(){
        AGENCY_API.listDeliveryOrderAttachmentsTypes().then((resp)=>{
          this.attachmentTypes=resp.data.data;
        }).catch((err)=>{
          console.log(err);
        })
      },

      listAllFiles(){
        this.loading=true;
        AGENCY_API.getDeliveryOrderAttachments(this.selectedRequestId).then((resp)=>{
          this.loading=false;

          this.uploadedFiles=[];

          for(let i=0;i<=resp.data.data.length;i++){
            if(resp.data.data[i].attachmentType){
              this.uploadedFiles.push({
                attachmentTypesName:resp.data.data[i].attachmentType.attachmentTypesName,
                id:resp.data.data[i].id,
                attachmentUrl:resp.data.data[i].attachmentUrl
              })
            }else {
              this.uploadedFiles.push({
                attachmentTypesName:"Not Specified",
                id:resp.data.data[i].id,
                attachmentUrl:resp.data.data[i].attachmentUrl
              })
            }

          }

        }).catch(()=>{
          this.loading=false;
        });
      },

      uploadFile(){

        if (!this.$v.selectedAttachmentTypeId.$invalid && !this.$v.fileData.$invalid) {
          this.loading=true;
          this.selectedAttachmentTypeIdError=false;
          this.fileDataError=false;

          let formData = new FormData();
          formData.append('file', this.fileData);

          AGENCY_API.uploadDOattachment(formData, this.selectedAttachmentTypeId,this.selectedRequestId,this.descrition).then((resp)=>{
            this.selectedAttachmentTypeId='';
            this.description='';
            this.fileData='';
            this.loading=false;
            this.listAllFiles();
            this.notifyUser('success', 'Success', `Delivery order Request was Successfully Request` );
            this.$refs.fileupload.value=null;
            parentNode.insertBefore(f,ref);

          }).catch((e)=>{
            this.loading=false;
            this.notifyUser('error', 'Success', `Delivery order Request was Successfully Request` )

          })
        }else {
          this.selectedAttachmentTypeIdError=true;
          this.fileDataError=true;
        }

      },
      deleteAttachment(attachmentId){
        this.loading=true;
        AGENCY_API.deleteDOAttachment(attachmentId).then((resp)=>{
          this.listAllFiles();
          this.loading=false;
        }).catch((err)=>{
          this.loading=false;
        })
      },

    }
  }
</script>

<style scoped>
  .pointer{
    cursor: pointer;
  }


  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: auto;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }

  .is-active-created, .is-active-created:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: blue !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-rejected, .is-active-rejected:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: red !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-accepted, .is-active-accepted:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
</style>
