<template>
  <div>
    <card>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <p><b>Create Delivery Order Request</b></p> ( <i> All Fields are mandatory </i> )
            <hr />
          </div>
                <div class="col-md-12">
                  <label>Search CF Agent</label>
                  <multiselect :options="cfAgents"
                      v-model="selectedAgent"
                      :multiple="false"
                      label="name"
                      track-by="code"
                      placeholder="Type to search CFA Agent"
                      :searchable="true"
                      :loading="isLoading"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      @search-change="searchCfAgents"
                    >
                      <template slot="clear" slot-scope="props">
                      </template>
                      <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                  </multiselect>
                  <div class="col-md-12 mb-3">
                    <div v-if="selectedAgentError">
                      <div class="error text-danger">
                        <span v-if="!$v.selectedAgent.required">Field is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-4">
                  <div class="form-group">
                    <label for="bl">Call ID</label>
                    <input type="text" class="form-control" v-model="callId"/>
                    <div v-if="callIdError">
                      <div class="error text-danger">
                        <span v-if="!$v.callId.required">Field is required</span>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="bl">Bill Of Lading Number</label>
                    <input type="text" class="form-control" id="bl" v-model="blNumber"/>
                    <div v-if="blnumberError">
                      <div class="error text-danger">
                        <span v-if="!$v.blNumber.required">Field is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="voyage">Voyage Number</label>
                    <input type="text" class="form-control" id="voyage" v-model="voyageNumber"/>
                    <div v-if="voyageNumberError">
                      <div class="error text-danger">
                        <span v-if="!$v.voyageNumber.required">Field is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="cargoDeliveryType">Cargo Delivery Type</label>
                    <select class="form-control" id="cargoDeliveryType" v-model="type">
                      <option value=""></option>
                      <option value="LOCAL_DELIVERY">Local Delivery</option>
                      <option value="TRANSIT">Transit</option>
                    </select>
                    <div v-if="typeError">
                      <div class="error text-danger">
                        <span v-if="!$v.type.required">Field is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="consigneeName">Consignee Name</label>
                    <input type="text" class="form-control" id="consigneeName" v-model="consigneeName">
                    <div v-if="consigneeNameError">
                      <div class="error text-danger">
                        <span v-if="!$v.consigneeName.required">Field is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="email">Consignee Email</label>
                    <input type="email" class="form-control" id="email" v-model="emailAddress">
                    <div v-if="emailAddressError">
                      <div class="error text-danger">
                        <span v-if="!$v.emailAddress.required">Field is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label for="mobileNumber">Consignee Phone Number</label>
                    <!-- <input type="text" class="form-control" id="mobileNumber" v-model="mobileNumber"> -->
                    <masked-input class="col-md-12 custom-form-group" placeholder="Consignee Phone Number" label="Phone Number" type="tel" mask="(\+\255) 111 - 111 - 111" @input="mobileNumber = arguments[1]" />
                    <div v-if="mobileNumberError">
                      <div class="error text-danger">
                        <span v-if="!$v.mobileNumber.required">Field is required</span>
                      </div>
                    </div>
                  </div>
                </div>
              <!-- </div> -->
              <div class="col-md-12">
                <div class="form-group form-check">
                  <label for="destination">Destination</label>
                  <input type="text" class="form-control" id="destination" v-model="destination">
                  <div v-if="destinationError">
                    <div class="error text-danger">
                      <span v-if="!$v.destination.required">Field is required</span>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-check-label" for="description">Description</label>
                  <textarea class="form-control" id="description" rows="3" v-model="description"></textarea>
                  <div v-if="descriptionError">
                    <div class="error text-danger">
                      <span v-if="!$v.description.required">Field is required</span>
                    </div>
                  </div>
                </div>
              </div>
            <div class="col-md-12 text-right">
              <div class="row">
                <div class="col-md-10 text-right mt-2">
                  <pulse-loader
                  :loading="isSubmitting"
                  :color="color"
                  :size="size"
                />
                </div>
                <div class="col-md-2">
                  <button type="submit" class="btn btn-primary custom-btn" @click.prevent="submit">Submit Request</button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </card>

  </div>
</template>

<script>

  import { required } from 'vuelidate/lib/validators';
  import AGENCYAPI from '../../../api/agency'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import SvgIcon from '../../../components/SvgLoader/svgLoader';
  import { ModelListSelect } from 'vue-search-select'
  import Multiselect from 'vue-multiselect'
  import MaskedInput from 'vue-masked-input'



  export default {
        name: "customer",

    components: {
      PulseLoader,
      ModelListSelect,
      Multiselect,
      SvgIcon,
      MaskedInput

    },

    validations:{
      blNumber:{required},
      callId: { required },
      selectedAgent:{required},
      type:{required},
      consigneeName:{required},
      destination:{required},
      description:{required},
      emailAddress:{required},
      mobileNumber:{required},
      voyageNumber:{required}
    },

    created() {
          //this.getAllCfAgents();
    },

    data(){
          return {
            blNumber:"",
            voyageNumber:"",
            type:"",
            selectedAgent:{
              code: "",
              desc: "",
              name: ""
            },
            isLoading:false,
            cfAgents:[],
            consigneeName:"",
            destination:"",
            description:"",
            emailAddress:"",
            mobileNumber:"",
            cargoDeliveryType:"",
            callId: '',

            color: 'green',
            size: '12px',
            loading: false,
            isSubmitting: false,

            blnumberError:false,
            typeError:false,
            consigneeNameError:false,
            destinationError:false,
            descriptionError:false,
            emailAddressError:false,
            mobileNumberError:false,
            selectedAgentError:false,
            voyageNumberError:false,
            callIdError: false
          }
    },

    methods: {

        codeAndNameAndDesc(data){
          return `${data.name} `
        },

        searchCfAgents(name){
          if(name!==''){
            this.isLoading=true;
            this.cfAgents=[];
            AGENCYAPI.getCfAgentByAgentName(name).then((response)=>{
              this.isLoading=false;
              response.data.data.map((data)=>{
                this.cfAgents.push({ code: data.cfAgentId, name: data.agentName, desc: 'desc01' },)
              })
            }).catch((e)=>{
            });
          }

        },

          getAllCfAgents(){
            AGENCYAPI.getAllCfAgentsNonPegeable().then((response)=>{
              response.data.data.content.map((data)=>{
                this.cfAgents.push({ code: data.cfAgentId, name: data.agentName, desc: 'desc01' },)
              })
            }).catch(()=>{

            })
          },

          submit(){

            if( this.$v.blNumber.required && this.$v.type.required && this.$v.consigneeName.required && this.$v.destination.required
                && this.$v.description.required && this.$v.selectedAgent.required && this.$v.voyageNumber.required){
              this.blnumberError = false
              this.typeError = false
              this.descriptionError = false
              this.destinationError = false
              this.emailAddressError = false
              this.mobileNumberError = false
              this.selectedAgentError = false
              this.voyageNumberError = false
              // this.callIdError = false
              this.isSubmitting = true

              let myObject = {

                blNumber: this.blNumber,
                cfaAgentId:this.selectedAgent.code,
                cargoDeliveryType: this.type,
                consigneeName: this.consigneeName,
                description: this.description,
                destination: this.destination,
                emailAddress: this.emailAddress,
                mobileNumber: '255' + this.mobileNumber,
                traLicenceNumber: "string",
                voyageNumber:this.voyageNumber
                // callId: this.callId

              }

              AGENCYAPI.requestDeliveryOrder(myObject).then((response) => {

                if(response.data.data.status) {

                  this.loadNotification('success', 4000, 'Delivery Order Request','Delivery order Request has been Successfully submitted','response', 1000 , true  , true)
                  this.isSubmitting = false;
                  this.blNumber="";
                  this.cargoDeliveryType="";
                  this.consigneeName="";
                  this.description="";
                  this.destination="";
                  this.emailAddress="";
                  this.mobileNumber="";
                  this.voyageNumber="";
                  // this.callId = ""
                  this.selectedAgent = {
                      blNumber: "",
                      cargoDeliveryType: "",
                      cfaAgentId: "",
                      consigneeName: "",
                      createdDate: "",
                      customerId: "",
                      description: "",
                      destination: "",
                      emailAddress: "",
                      mobileNumber: ""
                  };
                  this.type="";

                } else {

                  this.loadNotification('error', 4000, 'Delivery Order Request','Delivery order Request not submitted submitted, please contact system Admin','response', 1000 , true  , true)

                }


              }).catch(() => {

                this.loadNotification('error', 4000, 'Delivery Order Request','Delivery order Request not submitted submitted, please contact system Admin','response', 1000 , true  , true)
                this.isSubmitting = false;

              })

            }else {

              this.blnumberError = true;
              this.typeError = true;
              this.consigneeNameError = true;
              this.destinationError = true;
              this.descriptionError = true;
              this.emailAddressError = true;
              this.mobileNumberError = true;
              this.voyageNumberError = true;
              // this.callIdError = true

            }
          }

      }

    }
</script>

<style scoped>
  h3 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid green;
    line-height: 0.1em;
    margin: 10px 0 60px;
  }

  h3 span {
    background:#fff;
    padding:0 10px;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }
  .bg-grey{
    background-color:rgba(249, 249, 249 , 1);
  }
  .custom-form-group {
    background-color: #FFFFFF !important;
    border: 1px solid #E3E3E3 !important;
    border-radius: 4px !important;
    color: #565656 !important;
    padding: 8px 12px !important;
    height: 40px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .custom-btn {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      padding: 5px 9px 5px 9px !important;
      font-size: 14px !important;
      width: auto;
      background-color: #FFFFFF !important;
  }
</style>
