<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="filteredRequests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :total-elements="totalElements"
        :no-data-found="noDataFound"
        :is-first="isFirst"
        :is-last="isLast"
        :total-pages="totalPages"
        :page-number="pageNumber"
        :is-hovered="isHovered"
        :has-manage-button="true"
        :loading-title="'Please wait while the system is loading disbursment payment requests'"
        :permissions="[ {key: 'listPermission', value: 'DISBURSEMENT_PAYMENT_ALL_GET'} ]"
        :hasFilter="false"
        :coloredStatus="true"
        @showToolTip="showToolTip"
        @hideToolTip="hideToolTip"
        @goToPage="goToPage"
        @goToApplication="goToApplication"
      >
      <div slot = "pagination">
        <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
        </paginate>
      </div>
      </data-table>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

import PulseLoader from 'vue-spinner/src/PulseLoader'
import GlobalModal from '../../../../components/globalLoader'
import { mapActions, mapGetters } from 'vuex'

export default {

  name: 'disbursmentPaymentRequests',

  components: {

    PulseLoader,
    GlobalModal,
    DataTable: () => import('../../../../components/DataTable/dataTable')

  },

  created() {

    this.$store.dispatch('pagenames/currentPageName', 'DA Payment Requests')
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageableRequestsOnCreated()

  },

  computed: {

    ...mapGetters('disbursmentpaymentrequest', [

      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getMyLoader',
      'getPageNo',
      'getRequestId',
      'getTotalElement',
      'getTotalPage',
      'getPageNumber',
      'getIsFirst',
      'getIsLast'

    ]),

    getIsPage: {

      get: function () {
          return this.isPage
      },
      set: function (newValue) {
          this.isPage = newValue;
      }
    }
  },

  data() {

    return {

      requests: [],
      filteredRequests: [],
      isHovered: [],
      isPage: 0,
      totalElements: "",
      totalPages: "",
      isFirst: false,
      isLast: false,
      pageNumber: 0,
      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      noDataFound: false,
      loading: false,
      color: 'green',
      size: '12px',
      myLoader: false,
      myFirstLoader: false,

      finalUserPermissions: [],

      tableHeaders: ['Vessel Name', 'Voyage Number', 'Proforma Number', 'Reference Number', 'Payment Deadline', 'Service Provider', 'Payment Amount', 'Status', 'Payment Type'],
      tableDataKeys: ['vesselName', 'voyageNumber', 'invoiceNo', 'referenceNo', 'paymentDeadline', 'serviceProvider', 'paymentAmount', 'status', 'paymentType']

    }
  },
  methods: {

    ...mapActions('disbursmentpaymentrequest', [

      'loadDisbursementInvoicePaymentRequestsOnCreated',
      'loadDisbursementInvoicePaymentRequestsAfterCreated'

    ]),

    sortingData(data) {

      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {

          ...data[x],
          paymentType: data[x].serviceProvider.government ? 'Government' : 'Non Government',
          paymentAmount: this.putComma(data[x].paymentAmount),
          vesselName: data[x].vesselName,
          voyageNumber: data[x].voyageNumber
          };

          this.filteredRequests.push(requestObject)

      }

    },

    setAllGetters(flag) {

      this.requests = this.getRequestList;
      flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
      flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
      this.noDataFound = this.getNoDataFound;
      this.SuccessMessage = this.getSuccessMessage;
      this.ErrorMessage = this.getErrorMessage;
      this.ErrorAlert = this.getErrorAlert;
      this.SuccessAlert = this.getSuccessAlert;
      this.pageNo = this.getPageNo;
      this.pageSize = this.getPageSize;

      this.totalElements = this.getTotalElement;
      this.totalPages = this.getTotalPage;
      this.pageNumber = this.getPageNumber;
      this.isFirst = this.getIsFirst;
      this.isLast = this.getIsLast;

      this.sortingData(this.requests)

    },

    loadParameters() {

      return new Promise((resolve) => {

        this.$store.dispatch('disbursmentpaymentrequest/pageSizing', this.pageSize)
        this.$store.dispatch('disbursmentpaymentrequest/pageNumbering', this.pageNo)

        resolve()

      })

    },

    loadPageableRequestsOnCreated() {

      this.filteredRequests = [];

      this.loadParameters().then(() => {

        this.loadDisbursementInvoicePaymentRequestsOnCreated({}).then(() => {

          this.setAllGetters('onCreated')

        }).catch(() => {})

        this.myFirstLoader = this.getMyFirstLoader
      })

    },

    loadPageableRequestsAfterCreated(pageNo,pageSize) {

        this.loadParameters(pageNo , pageSize).then(() => {

          this.loadDisbursementInvoicePaymentRequestsAfterCreated({}).then(() => {

            this.setAllGetters('afterCreated');

          });

          this.myLoader = this.getMyLoader;

        }).catch(() => {


        });

      },

    onChange(event) {

      this.$store.dispatch("disbursmentpaymentrequest/pageSizing", event);
      this.$store.dispatch("disbursmentpaymentrequest/pageNumbering", 0);
      this.isPage = 0

      this.pageSize = this.getPageSize;
      this.pageNo = this.getPageNo;

      this.loadPageableRequestsOnCreated(this.pageNo , this.pageSize);
      this.filteredRequests = []

    },

    goToPage(page) {

      if(page > 0) {page = page - 1;}
      this.isPage = page + 1

      this.pageNo = page;

      this.loadPageableRequestsAfterCreated(this.pageNo , this.pageSize);
      this.filteredRequests = []

    },

    goToApplication(object) {
      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
      this.$router.push({name: 'disbursementPaymentRequestDetails', params: { id : object.disbursementInvoicePaymentId }})

    },

    showToolTip (object) {

      this.isHovered = [object];

    },

    hideToolTip (object) {

      this.isHovered.splice(this.isHovered.indexOf(object), 1)

    },

  }
}
</script>

<style scoped>
.btn-space {
  margin-right: 10px !important;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

.special-limit {
  max-width: 100px !important;
  min-width: 90px !important;
}

.loader-div {
  line-height: 40px;
}

.loader-div-gif {
  line-height: 50px;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.fileUpload span {
  cursor: pointer;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}

.special-bottom {
  margin-bottom: -1px;
}
</style>
