<template>
  <div>
    <card
      style="font-size: 12px"
      v-if="!myFirstLoader"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Document Details
            </h6>

            <br>
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Voyage
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ this.voyageId }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Cargo Type
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ extractedTalliedBlDetails.cargoType }}
              </div>
            </div>

            <br>
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Declared Amount
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ extractedTalliedBlDetails.declaredAmount }}
              </div>

              <div class="col-md-3 div-table div-table-title">
                Completion Status
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ extractedTalliedBlDetails.completed ? 'YES' : 'NO' }}
              </div>
            </div>

            <br>
            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                DC Master Bill of Lading
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ extractedTalliedBlDetails.dcMasterBillOfLadingId }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Master Bill of Lading
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ extractedTalliedBlDetails.masterBillOfLading }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                House Bill of Lading
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ extractedTalliedBlDetails.houseBillOfLading }}
              </div>
            </div>

            <br>
          </div>
        </div>

        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              {{ extractedTalliedBlDetails.cargoType }} Details
            </h6>

            <br>

            <data-table
              :table-headers="tableHeaders"
              :table-data="tableDetails"
              :table-data-keys="tableKeys"
              :page-size-prop="pageSize"
              :page-no="pageNo"
              :is-hovered="isHovered"
              :my-loader="myLoader"
              :page-count="pageCount"
              :reload-all-request="reloadAllRequest"
              :my-first-loader="myFirstLoader"
              :no-data-found="noDataFound"
              :search-parameter-prop="searchParameter"
              :status="status"
              :loading-title="'Please wait while system is loading bill of lading details'"
              @goToApplication="goToApplication"
              :has-date-query="true"
              @queryByDate="queryByDate"
              @reloadDateQueryByIcon="reloadDateQueryByIcon"
              @onChange="onChange"
            />
          </div>
        </div>
      </div>

      <div class="row noprint">
        <div class="col-md-12 text-right">
          <div class="col-md-12 text-right">
            <button
              class="btn btn-default btn-fill btn-space"
              @click.prevent="printDetails('toPrint')"
            >
              <font-awesome-icon icon="print" /> &nbsp; Print Tally Sheet
            </button>

            <button
              class="btn btn-default btn-fill btn-space"
              @click.prevent="goBack"
            >
              <font-awesome-icon icon="undo" /> &nbsp; Back
            </button>
          </div>
        </div>
      </div>
    </card>
    <global-modal v-if="myFirstLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>

    <pre> {{ this.tableDetails }} </pre>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import Modal from '@/components/Inputs/Modal'
import DataTable from '@/components/DataTable/table'
import GlobalModal from '@/components/globalLoader'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BlnDetails',
  components: {

    PulseLoader,
    Modal,
    DataTable,
    GlobalModal

  },
  data () {
    return {

      // accessedFrom: "tallySheets",
      accessedFrom: '',
      color: 'green',
      size: '12px',
      loading: true,

      ifThereIsParam: '',

      finalUserPermissions: [],

      extractedTalliedBlDetails: [],
      tareWeight: 0,
      tareWeightUnit: 'string',
      netWeight: 0,
      netWeightUnit: 'string',
      grossWeight: 0,
      grossWeightUnit: 'string',

      tableKeys: ['containerNo', 'containerSize', 'typeOfContainer', 'sealNumbers', 'tareWeight', 'grossWeight', 'netWeight'],
      tableHeaders: ['Container No.', 'Container Size', 'Container Type', 'Seal No.', 'Tare Weight', 'Gross Weight', 'Net Weight'],
      status: 'SHIPPING_TALLY_DETAILS',

      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,
      pdfFormatError: false,
      loadAllHeaderLoader: false,
      allAttachmentVerified: false,
      myLoader: false,
      myFirstLoader: false,
      isSearchable: false,
      tableDetails: [],
      requestFlag: 'EXP',
      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      sortBy: 'createdAt',
      isHovered: [],

      queryDate: '',
      voyageId: '',
      shiftId: ''
    }
  },
  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Shipping Tally Bill of Lading')
    this.accessedFrom = localStorage.getItem('accessedFrom')
    this.cargoType = this.$route.params.cargoType
    this.voyageId = this.$route.params.documentId
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))

    if (this.cargoType === 'CONTAINER') {
      this.loadShippingTallyContainerBlnDetails()
    } else {
      this.loadShippingTallyGeneralBlnDetails()
    }
  },
  computed: {
    ...mapGetters('notifications', [

      'getNewAppPage'

    ]),
    ...mapGetters('shippingtallying', [

      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getMyLoader',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo',
      // "getCustomerName",
      'getPageSize',
      'getRequestId',
      // "getBlNumber",
      'getMyFirstLoader'
    ])
  },

  methods: {
    ...mapActions('shippingtallying', [
      'loadShippingTallyContainerAllByVoyage',
      'loadShippingTallyContainerInDayDetails',
      'loadShippingTallyContainerInShiftInDayDetails',
      'loadShippingTallyGoodsInDayDetails',
      'loadShippingTallyGoodsInShiftInDayDetails',
      'loadAllShippingTallyingSheets'
    ]),

    loadParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('shippingtallying/pageSizing', this.pageSize)
        this.$store.dispatch('shippingtallying/pageNumbering', this.pageNo)
        this.$store.dispatch('shippingtallying/queryingDate', this.queryDate)
        this.$store.dispatch('shippingtallying/voyageIding', this.voyageId)
        this.$store.dispatch('shippingtallying/shiftIding', this.shiftId)
        resolve()
      })
    },
    getDateQueryParameterValue (dateSearchParam) {
      return new Promise((resolve) => {
        this.queryDate = String(dateSearchParam)
        console.log(this.queryDate)
        this.$store.dispatch('shippingtallying/queryingDate', this.queryDate)

        resolve()
      })
    },

    loadShippingTallyGoodsBlnDetails () {
      console.log('no implemented yet')

      this.loadParameters().then(() => {
        this.loadShippingTallyBlnDetailsOnCreated({}).then(() => {
          this.tableDetails = this.getRequestList
          this.pageCount = this.getPageCount
          this.myFirstLoader = this.getMyFirstLoader
          this.noDataFound = this.getNoDataFound
          this.SuccessMessage = this.getSuccessAlert
          this.ErrorMessage = this.getErrorMessage
          this.ErrorAlert = this.getErrorAlert
          this.SuccessAlert = this.getSuccessMessage
          this.pageNo = this.getPageNo
          this.pageSize = this.getPageSize
          this.reloadAllRequest = this.getReloadAllRequest

          this.extractTalliedBlDetails(this.tableDetails)
        }).catch(() => {

        })

        this.myFirstLoader = this.getMyFirstLoader
      }).catch(() => {

      })
    },
    loadShippingTallyContainerBlnDetails () {
      this.loadParameters().then(() => {
        console.log('parameters loaded')
        this.loadShippingTallyContainerAllByVoyage({}).then(() => {
          this.tableDetails = this.getRequestList
          this.pageCount = this.getPageCount
          this.myFirstLoader = this.getMyFirstLoader
          this.noDataFound = this.getNoDataFound
          this.SuccessMessage = this.getSuccessAlert
          this.ErrorMessage = this.getErrorMessage
          this.ErrorAlert = this.getErrorAlert
          this.SuccessAlert = this.getSuccessMessage
          this.pageNo = this.getPageNo
          this.pageSize = this.getPageSize
          this.reloadAllRequest = this.getReloadAllRequest

          this.extractTalliedBlDetails(this.tableDetails)
        }).catch(() => {

        })

        this.myFirstLoader = this.getMyFirstLoader
      }).catch(() => {

      })
    },
    searchShippingTallyContainerBlnDetailsByDate (dateSearchParams) {
      this.getDateQueryParameterValue(dateSearchParams).then(() => {
        this.loadShippingTallyContainerInDayDetails({}).then(() => {
          this.requests = this.getRequestList
          this.pageCount = this.getPageCount
          this.myLoader = this.getMyLoader
          this.noDataFound = this.getNoDataFound
          this.pageNo = this.getPageNo
          this.pageSize = this.getPageSize
          this.searchParameter = this.getSearchParameter

          this.reloadAllRequest = this.getReloadAllRequest
          console.log(this.getReloadAllRequest)
        }).catch(() => {

        })

        this.requests = this.getRequestList
        this.myLoader = this.getMyLoader
        this.pageCount = this.getPageCount
        this.pageNo = this.getPageNo
        this.pageSize = this.getPageSize
        this.noDataFound = this.getNoDataFound
        this.reloadAllRequest = this.getReloadAllRequest
        this.hasSearchedVariable = this.getHasSearchedVariable
      }).catch(() => {

      })
    },
    searchShippingTallyGoodsBlnDetailsByDate (dateSearchParams) {
      this.getDateQueryParameterValue(dateSearchParams).then(() => {
        this.loadShippingTallyGoodsInDayDetails({}).then(() => {
          this.requests = this.getRequestList
          this.pageCount = this.getPageCount
          this.myLoader = this.getMyLoader
          this.noDataFound = this.getNoDataFound
          this.pageNo = this.getPageNo
          this.pageSize = this.getPageSize
          this.searchParameter = this.getSearchParameter
        }).catch(() => {

        })

        this.requests = this.getRequestList
        this.myLoader = this.getMyLoader
        this.pageCount = this.getPageCount
        this.pageNo = this.getPageNo
        this.pageSize = this.getPageSize
        this.noDataFound = this.getNoDataFound
        this.reloadAllRequest = this.getReloadAllRequest
        this.hasSearchedVariable = this.getHasSearchedVariable
      }).catch(() => {

      })
    },

    reloadDateQueryByIcon () {
      console.log('niko hapa')
      this.loadAllShippingTallyingSheets({}).then(() => {
        // this.queryDate = "";
        this.$store.dispatch('shippingtallying/reloadingRequest', false)
        // console.log('niko hapa')
        if (this.cargoType === 'CONTAINER') {
          this.loadShippingTallyContainerBlnDetails()
        } else {
          this.loadShippingTallyGoodsBlnDetails()
        }
      })
    },

    extractTalliedBlDetails (tallyDetails) {
      this.extractedTalliedBlDetails = tallyDetails[0].talliedBl
    },
    onChange (event) {
      this.$store.dispatch('shippingtallying/pageSizing', event)
      this.$store.dispatch('shippingtallying/pageNumbering', this.pageNo)

      this.pageSize = this.getPageSize
      this.pageNo = this.getPageNo

      if (this.cargoType === 'CONTAINER') {
        if (this.getHasSearchedVariable) {
          this.searchShippingTallyContainerBlnDetailsByDate(this.getQueryDate)
        } else {
          this.loadShippingTallyContainerBlnDetails()
        }
      } else { // for non-Container type of cargo
        if (this.getHasSearchedVariable) {
          this.searchShippingTallyGoodsBlnDetailsByDate(this.getQueryDate)
        } else {
          this.loadShippingTallyGoodsBlnDetails()
        }
      }

      // if (this.getHasSearchedVariable) {
      //
      //   this.searchRequestByName(this.getSearchParameter);
      //
      // } else {console.log('in search')},
    },

    goToApplication () {
      console.log('in application details --->')
    },
    queryByDate (dateSearchParams) {
      if (this.cargoType === 'CONTAINER') {
        this.searchShippingTallyContainerBlnDetailsByDate(dateSearchParams)
      } else {
        this.searchShippingTallyGoodsBlnDetailsByDate(dateSearchParams)
      }
    },
    goBack () {
      if (window.$cookies.isKey('browser-local-cashed-url-back-next')) {
        this.$router.push(window.$cookies.get('browser-local-cashed-url-back-next'))

        window.$cookies.remove('browser-local-cashed-url-back-next')
      }
    }

  }

}
</script>

<style scoped>

.legend {
  position: relative;
  border: 1px solid #DCDCDC;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  background-color: inherit;
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #d59a18;
}

.notice-table-label {
  width: 250px;
  background-color: #99badd;
  font-weight: normal;
}

.custom-table, .custom-table th, .custom-table td {
  border: 1px solid #DCDCDC;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
  padding: 8px;
  text-align: left;
}

.add-receiving-point , .add-delivery-or-exit-point {
  color: green;
}

.remove-delivery-or-exit-point {
  color: red;
  margin-top: 35px;
}

.remove-delivery-or-exit-point-in-table {
  color: red;
}

.add-receiving-point:hover, .add-delivery-or-exit-point:hover, .remove-delivery-or-exit-point:hover, .remove-delivery-or-exit-point-in-table:hover {
  color: #d59a18;
  cursor: pointer;
}

.row-receiving-point {
  padding: 0 30px 0 30px;
}

.text-view {
  color: green;
}

.text-view:hover {
  counter-reset: blue;
  cursor: pointer;
}

.current-update {
  background-color: #dcdcdc !important;
}

.btn-space {
  margin-right: 10px !important;
}

ul.nav-justified {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ededee;
  border-radius: 3px;
}

ul.nav-justified li {
  float: left;
}

ul.nav-justified li a:hover {
  color: #d59a18;
}

.text-link {
  color: blue;
}

.text-link:hover {
  color: #d59a18;
  cursor: pointer;
}

ul.nav-justified li a {
  display: block;
  text-align: center;
  padding: 16px 20px 10px 21px;
  text-decoration: none;
  color: blue;
  font-weight: bold;
  text-transform: uppercase;
}

.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 0.5em 5px;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.26;
  text-decoration: none;
  top: 16px;
  font-weight: normal;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.fileUpload span {
  cursor: pointer;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}

#label-upload {
  position: relative;
  top: 16px;
}

.btn-disabled {
  background-color: #DCDCDC;
  cursor: not-allowed;
  color: #696969;
  padding: 10px 20px;
  border: none;
  width: 100%;
  opacity: 0.9;
  min-height: 55px;
  max-height: 55px;
}

.btn-disabled:hover {
  background-color: #DCDCDC;
  cursor: not-allowed;
  color: #696969;
  padding: 10px 20px;
  border: none;
  width: 100%;
  opacity: 1;
  min-height: 55px;
  max-height: 55px;
}

.application-status {
  /*background-color: #ededee;*/
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 78%;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltipe:hover .tooltiptext {
  visibility: visible;
  cursor: help;
}

.tooltiptext2 {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 78%;
}

.tooltiptext2::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltipe:hover .tooltiptext2 {
  visibility: visible;
  cursor: help;
}

.div-table-row {
  padding: 0 15px 0 15px;
}

.div-table {
  padding: 9px 8px 9px 8px;
}

.div-table-title {
  width: 250px;
  background-color: rgba(153, 186, 221, .5);
  font-weight: normal;
}

.div-table-value {
  border: 1px solid #DCDCDC;
  font-weight: 900;
}

.insert-bl {
  margin-top: 36px;
  color: forestgreen;
  font-weight: bold;
}

.insert-bl .save-bl:hover {
  cursor: pointer;
}

.custom-btn {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px 9px 5px 9px !important;
  font-size: 14px !important;
}

.btn-blue:hover {
  border-color: #0000ff;
  color: #0000ff;
  background-color: #ffffff;
}

.btn-blue {
  border-color: #4169e1;
  color: #4169e1;
}

.btn-blue-fill {
  background-color: #4169e1;
  opacity: 1;
  color: #FFFFFF;
}

.btn-purple:hover {
  border-color: #800080;
  color: #800080;
  background-color: #ffffff;
}

.btn-purple {
  border-color: #9932cc;
  color: #9932cc;
}

.btn-purple-fill {
  background-color: #9932cc;
  opacity: 1;
  color: #FFFFFF;
}

.btn-linen:hover {
  border-color: #D2691E;
  color: #D2691E;
  background-color: #ffffff;
}

.btn-linen {
  border-color: rgba(210, 105, 30, .5);
  color: rgba(210, 105, 30, .5);
}

.btn-linen-fill {
  background-color: rgb(210, 105, 30);
  opacity: 1;
  color: #FFFFFF;
}

.btn-olive:hover {
  border-color: #4B0082;
  color: #4B0082;
  background-color: #ffffff;
}

.btn-olive {
  border-color: #6A5ACD;
  color: #6A5ACD;
}

.btn-olive-fill {
  background-color: #6A5ACD;
  opacity: 1;
  color: #FFFFFF;
}

.btn-success-custom:hover {
  border-color: #008000;
  color: #008000;
  background-color: #ffffff;
}

.btn-success-custom {
  border-color: rgba(0, 128, 0, .5);
  color: rgba(0, 128, 0, .5);
}

.btn-success-custom-fill {
  background-color: rgba(0, 128, 0, .8);
  opacity: 1;
  color: #FFFFFF;
}

.alert-success {
  padding-top:10px;
  padding-bottom: 10px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  padding-top:10px;
  padding-bottom: 10px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

</style>
