<template>
  <div>
    <card
      v-if="finalUserPermissions.includes('REPORTS_UPLOAD-BILL-REPORT_POST')"
    >
      <div class="col-md-12 text-center">
        <b>UPLOAD BILLING REPORT</b
        ><i>
        ( Please use excel document downloaded from
        <span class="custom-link"><b>here</b></span> )</i
      >
      </div>

      <br v-if="excelReportLoader"/>

      <div class="col-md-12 text-center">
        <form method="post" enctype="multipart/form-data">
          <div class="fileUpload btn btn-info btn-fill">
            <span>Choose Excel Report..</span>
            <input
              type="file"
              name="attachment"
              ref="file"
              class="upload"
              id="first_upload"
              @change.prevent="uploadFile"
            />
          </div>
          <label
            class="myLabel"
            for="first_upload"
            id="label-upload"
            v-if="!isUploadingRevenueExcelReport"
          >
            {{
            file ? "Selected File: " + '" ' + file + ' "' : "No File Selected"
            }}
          </label>
          <label
            class="myLabel2"
            for="first_upload"
            v-if="isUploadingRevenueExcelReport"
          >
            <pulse-loader :loading="loading" :color="color" :size="size"/>
          </label>
        </form>
      </div>
      <br/>
    </card>
    <form
      @submit.prevent="loadReport"
      v-if="finalUserPermissions.includes('REPORTS_UPLOAD-BILL-REPORT_POST')"
    >
      <card>

        <div class="col-md-12 text-center" v-if="!areAllLoaded">
          <b>DOWNLOAD BILLING REPORT</b>
          <i>
            ( if no option selected, report with all available options based on today transcation(s) will be
            generated )</i
          >
        </div>
        <div class="col-md-12 text-center" v-if="areAllLoaded">
          <div class="row">
            <div class="col-md-6 text-right">
              Please wait loading contents .....
            </div>
            <div class="col-md-6 text-left">
              <pulse-loader :loading="loading" :color="color" :size="size"/>
            </div>
          </div>
        </div>
        <br/> <br/>

        <ul class="nav-justified">
          <li>
            <a @click.prevent="setActive('VAT' , 'VAT')" :class="[ isActive('VAT') ? 'is-active-verified' : '' ]" href="#VAT">VAT Report</a>
          </li>
          <li>
            <a @click.prevent="setActive('BILLS' , 'BILLS')" :class="[ isActive('BILLS') ? 'is-active-new' : '' ]" href="#REVENUE">Bills Report</a>
          </li>
          <li>
            <a @click.prevent="setActive('RVREPORT' , 'RVREPORT')" :class="[ isActive('RVREPORT') ? 'is-active-rvreport' : '' ]" href="#REVENUE">Revenue Report</a>
          </li>
        </ul>

        <div class="tab-content py-2" id="myTabContent">
          <div class="tab-pane fade pt-3 back-color-vat pr-3 pl-3" :class="{ 'active show': isActive('VAT')}" id="VAT">
            <div class="col-md-12 text-center" v-if="!areAllLoaded">
              <b>VAT REPORT</b>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Bill Type</label>
                <model-list-select
                  :list="billTypes"
                  v-model="billType"
                  class="form-control"
                  @input="setCutomers"
                  option-value="id"
                  option-text="name"
                  placeholder="Bill Type"
                >
                </model-list-select>
              </div>
              <div class="col-md-4">
                <base-input
                  type="date"
                  label="Start Date"
                  v-model="startDate"
                  :max="todayDate"
                />
              </div>

              <div class="col-md-4">
                <base-input
                  type="date"
                  label="End Date"
                  v-model="endDate"
                  :min="startDate"
                  :max="todayDate"
                  :disabled="startDate === ''"
                />
              </div>
              <div class="col-md-12" v-if="billTypeName === 'cfa'">
                <label>Search TASAC customer</label>
                <multiselect v-model="customerIds" 
                  :options="allUsers"
                  :close-on-select="false" 
                  :clear-on-select="false" 
                  :preserve-search="true"
                  :multiple="true"
                  :disabled="allUsers.length <= 0"
                  placeholder="Search TASAC customer" 
                  label="name" 
                  track-by="customerId" 
                  :preselect-first="false"
                  >

                  </multiselect>
              </div>

            </div>
            <br />
            <div class="row">
              <div class="col-md-12" v-if="billTypeName === 'dc'">
                <label>Search TASAC customer</label>
                <multiselect v-model="customerIds" 
                  :options="allUsers"
                  :close-on-select="false" 
                  :clear-on-select="false" 
                  :preserve-search="true"
                  :multiple="true"
                  :disabled="allUsers.length <= 0"
                  placeholder="Search companies" 
                  label="tariffItemName"
                  :custom-label="customCompanyCodeAndName"
                  track-by="companyId" 
                  :preselect-first="false"
                  >

                  </multiselect>
              </div>
              <!-- <div class="col-md-4">
                <base-input
                  type="text"
                  label="Control Number"
                  v-model="controlNumber"
                />
              </div> -->
            </div>
            <!-- <br /> -->
            <div class="row">
              <div class="col-md-12">
                  <label class="typo__label" v-if="activeTariffItems.length > 0">Select tariff Items</label>
                  <label class="typo__label" v-else>
                      <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                      Loading tariff Items, please wait
                  </label>
                  <multiselect v-model="tariffItemIds" 
                  :options="activeTariffItems"
                  :close-on-select="false" 
                  :clear-on-select="false" 
                  :preserve-search="true"
                  :multiple="true"
                  :disabled="activeTariffItems.length <= 0"
                  placeholder="Select tariff Items" 
                  label="tariffItemName" 
                  track-by="tariffItemId" 
                  :preselect-first="false"
                  >

                  </multiselect>
                  <!-- <pre class="language-json"><code>{{ tariffItemId  }}</code></pre> -->
              </div>
              <!-- <div class="col-md-4">
                <base-input
                  type="text"
                  label="Invoice Number"
                  v-model="invoiceNo"
                />
              </div> -->
              <!-- <div class="col-md-8">
                <label>Bill Status</label>
                <model-list-select
                  :isDisabled=true
                  :list="billStatuses"
                  v-model="billStatus"
                  class="form-control"
                  option-value="id"
                  option-text="name"
                  placeholder="Bill Status"
                >
                </model-list-select>
              </div> -->
            </div>

            <br/>

            <div class="row">
              <!-- <div class="col-md-4">
                  <div class="row">
                      <div class="col-md-12">
                      TANSAD NUMBER ( FORMAT: XXXX-XX-XXXXXXX )
                      </div>
                      <div class="col-md-3" style="margin-top:6px;"><base-input maxlength="4" @input="shiftCursor('fourDigit' , 'twoDigit')" id="fourDigit" type="text" v-model="TANSADNumber4"></base-input>
                      </div>
                      <div class="col-md-1" style="font-size:29px;">-</div>
                      <div class="col-md-2" style="margin-top:6px;"><base-input maxlength="2" @input="shiftCursor('twoDigit' , 'sevenDigit')" id="twoDigit" type="text" v-model="TANSADNumber2"></base-input>
                      </div>
                      <div class="col-md-1" style="font-size:29px;">-</div>
                      <div class="col-md-5" style="margin-top:6px;"><base-input maxlength="7"  @input="shiftCursor('sevenDigit' , 'tarrifItem')" id="sevenDigit" type="text" v-model="TANSADNumber7"></base-input>
                      </div>
                  </div>
              </div > -->
            </div>
          </div>

          <div class="tab-pane fade pt-3 back-color-non-vat pr-3 pl-3" :class="{ 'active show': isActive('BILLS') }" id="REVENUE">
            <div class="col-md-12 text-center" v-if="!areAllLoaded">
              <b>BILLS REPORT</b>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Bill Type</label>
                <model-list-select
                  :list="billTypes"
                  v-model="billType"
                  class="form-control"
                  @input="setCutomers"
                  option-value="id"
                  option-text="name"
                  placeholder="Bill Type"
                >
                </model-list-select>
              </div>
              <div class="col-md-8" v-if="billTypeName === 'cfa'">
                <label>Search TASAC customer</label>
                <multiselect v-model="customerIds" 
                  :options="allUsers"
                  :close-on-select="false" 
                  :clear-on-select="false" 
                  :preserve-search="true"
                  :multiple="true"
                  :disabled="allUsers.length <= 0"
                  placeholder="Search TASAC customer" 
                  label="name" 
                  track-by="customerId" 
                  :preselect-first="false"
                  >

                  </multiselect>
              </div>
              <div class="col-md-8" v-if="billTypeName === 'dc'">
                <label>Search TASAC customer</label>
                <multiselect v-model="customerIds" 
                  :options="allUsers"
                  :close-on-select="false" 
                  :clear-on-select="false" 
                  :preserve-search="true"
                  :multiple="true"
                  :disabled="allUsers.length <= 0"
                  placeholder="Search companies" 
                  label="tariffItemName"
                  :custom-label="customCompanyCodeAndName"
                  track-by="companyId" 
                  :preselect-first="false"
                  >

                  </multiselect>
              </div>
            </div>

            <br/>

            <div class="row">

              <div class="col-md-12">
                  <label class="typo__label" v-if="activeTariffItems.length > 0">Select tariff Items</label>
                  <label class="typo__label" v-else>
                      <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                      Loading tariff Items, please wait
                  </label>
                  <multiselect v-model="tariffItemIds" 
                  :options="activeTariffItems"
                  :close-on-select="false" 
                  :clear-on-select="false" 
                  :preserve-search="true"
                  :multiple="true"
                  :disabled="activeTariffItems.length <= 0"
                  placeholder="Search tariff Items" 
                  label="tariffItemName" 
                  track-by="tariffItemId" 
                  :preselect-first="false"
                  >

                  </multiselect>
              </div>

            </div>

            <br/>

            <div class="row">
              <!-- <div class="col-md-4">
                <base-input
                  type="text"
                  label="TANSAD Number"
                  v-model="tansadNo"
                />
              </div> -->
              <div class="col-md-4">
                  <div class="row">
                      <div class="col-md-12">
                      TANSAD NUMBER ( FORMAT: XXXX-XX-XXXXXXX )
                      </div>
                      <div class="col-md-3" style="margin-top:6px;"><base-input maxlength="4" @input="shiftCursor('fourDigit' , 'twoDigit')" id="fourDigit" type="text" v-model="TANSADNumber4"></base-input>
                      </div>
                      <div class="col-md-1" style="font-size:29px;">-</div>
                      <div class="col-md-2" style="margin-top:6px;"><base-input maxlength="2" @input="shiftCursor('twoDigit' , 'sevenDigit')" id="twoDigit" type="text" v-model="TANSADNumber2"></base-input>
                      </div>
                      <div class="col-md-1" style="font-size:29px;">-</div>
                      <div class="col-md-5" style="margin-top:6px;"><base-input maxlength="7"  @input="shiftCursor('sevenDigit' , 'tarrifItem')" id="sevenDigit" type="text" v-model="TANSADNumber7"></base-input>
                      </div>
                  </div>
              </div >
              <div class="col-md-8">
                <label>Bill Status</label>
                <multiselect v-model="billStatusIds" 
                  :options="billStatuses"
                  :close-on-select="false" 
                  :clear-on-select="false" 
                  :preserve-search="true"
                  :multiple="true"
                  :disabled="activeTariffItems.length <= 0"
                  placeholder="Search Bill Status" 
                  label="name" 
                  track-by="id" 
                  :preselect-first="false"
                  >

                  </multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="date"
                  label="Start Date"
                  v-model="startDate"
                  :max="todayDate"
                />
              </div>

              <div class="col-md-4">
                <base-input
                  type="date"
                  label="End Date"
                  v-model="endDate"
                  :min="startDate"
                  :max="todayDate"
                  :disabled="startDate === ''"
                />
              </div>
            </div>
          </div>

          <div class="tab-pane fade pt-3 back-color-revenue pr-3 pl-3" :class="{ 'active show': isActive('RVREPORT') }"
              id="RVREPORT">
            <div class="col-md-12 text-center" v-if="!areAllLoaded">
              <b>REVENUE REPORT</b>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Bill Type</label>
                <model-list-select
                  :list="billTypes"
                  v-model="billType"
                  class="form-control"
                  @input="setCutomers"
                  option-value="id"
                  option-text="name"
                  placeholder="Bill Type"
                >
                </model-list-select>
              </div>
              <div class="col-md-8">
                  <label class="typo__label" v-if="activeTariffItems.length > 0">Select tariff Items</label>
                  <label class="typo__label" v-else>
                      <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
                      Loading tariff Items, please wait
                  </label>
                  <multiselect v-model="tariffItemIds" 
                  :options="activeTariffItems"
                  :close-on-select="false" 
                  :clear-on-select="false" 
                  :preserve-search="true"
                  :multiple="true"
                  :disabled="activeTariffItems.length <= 0"
                  placeholder="Search tariff Items" 
                  label="tariffItemName" 
                  track-by="tariffItemId" 
                  :preselect-first="false"
                  >

                  </multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="date"
                  label="Start Date"
                  v-model="startDate"
                  :max="todayDate"
                />
              </div>

              <div class="col-md-4">
                <base-input
                  type="date"
                  label="End Date"
                  v-model="endDate"
                  :min="startDate"
                  :max="todayDate"
                  :disabled="startDate === ''"
                />
              </div>
            </div>
          </div>

        </div>

        <hr/>

        <br/>

        <div class="text-center row">
          <div class="col-md-6 text-right loader-div">
          </div>
          <div class="col-md-2 left loader-div-gif">
          </div>
          <div class="col-md-4">
            <button
              @click.prevent="resetParams"
              class="btn btn-fill btn-outline-info float-right custom-btn"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-space float-right custom-btn"
            >
              Generate Report
            </button>
          </div>
        </div>
      </card>
    </form>
    <div>
      <bill-report-list></bill-report-list>
    </div>
    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Downloading requested {{ globalLoaderName }} Report, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>
  import API from "../../../api/index";
  import API_REVENUE from '../../../api/rev'
  import PulseLoader from "vue-spinner/src/PulseLoader.vue";
  import {ModelListSelect} from 'vue-search-select';
  import GlobalModal from '../../../components/globalLoader'
  import BillReportList from "./BillReportList"
  import Multiselect from 'vue-multiselect'
  import { mapGetters, mapActions } from "vuex"
  import { BILL_TYPES, BILL_STATUSES } from '../../../mixins/constants'

  export default {
    name: "TaxAndRevenue",

    components: {
      PulseLoader,
      ModelListSelect,
      GlobalModal,
      BillReportList,
      Multiselect
    },

    computed: {

      ...mapGetters('global' , [

        'getActiveTariffItems'

      ])

    },

    created() {
      this.$store.dispatch("pagenames/currentPageName", "TAX And Revenue Report");

      this.finalUserPermissions = JSON.parse(
        localStorage.getItem("browser-local-permits")
      );

      this.loadAllCustomers()
      this.loadAllCompanies()
      this.loadAllActiveTariffItems()

      this.setActive(this.activeItem, this.reportType);
    },

    data() {

      return {

        billStatuses: BILL_STATUSES,
        billTypes: BILL_TYPES,

        todayDate: new Date().toJSON().slice(0, 10),

        file: '',
        fileData: '',

        finalUserPermissions: [],
        startDate: '',
        endDate: '',
        billStatus: '',
        billType: '',
        allCustomers: [],
        allCompanies: [],
        activeTariffItems: [],
        customerIds: [],
        tariffItemIds: [],
        billStatusIds: [],
        tariffItemId: {},
        billTypeName: 'cfa',
        controlNumber: '',
        invoiceNo: '',
        TANSADNumber4: '',
        TANSADNumber2: '',
        TANSADNumber7: '',
        allUsers: [],
        activeItem: 'VAT',
        reportType: 'VAT',
        customerId: '',
        globalLoader: false,
        globalLoaderName: '',
        // reportIsGenerated: 0,

        color: 'green',
        size: '15px',
        size1: '9px',
        loading: true,
        tansadNo: '',
        areAllLoaded: false,
        excelReportLoader: false,
        isUploadingRevenueExcelReport: false,
        SingleCustomerNameError: false

      };

    },

    methods: {

      ...mapActions("global", [

          'loadActiveTariffItems'

      ]),

      customCompanyCodeAndName(item) {

        return `${item.companyName} ( ${item.companyCode} )`

      },

      loadAllActiveTariffItems() {

          this.loadActiveTariffItems({}).then(() => {

            this.activeTariffItems = this.getActiveTariffItems;

          }).catch(() => {})

      },

      shiftCursor(currentId, nextid) {

          let currentIdDom = document.getElementById(currentId);

          if(currentId === 'fourDigit') {

              if(currentIdDom.value.length === 4) {

              document.getElementById(currentId).blur();
              document.getElementById(nextid).focus();

              }

          } else if (currentId === 'twoDigit') {

              if(currentIdDom.value.length === 2) {

              document.getElementById(currentId).blur();
              document.getElementById(nextid).focus();

              }


          } else if (currentId === 'fourDigitEdit') {

              if(currentIdDom.value.length === 4) {

              document.getElementById(currentId).blur();
              document.getElementById(nextid).focus();

              }


          } else if (currentId === 'twoDigitEdit') {

              if(currentIdDom.value.length === 2) {

              document.getElementById(currentId).blur();
              document.getElementById(nextid).focus();

              }


          } else if (currentId === 'sevenDigit') {

              if(currentIdDom.value.length === 7) {

              document.getElementById(currentId).blur();
              // document.getElementById(nextid).focus();

              }

          } else {

              if(currentIdDom.value.length === 7) {

              document.getElementById(currentId).blur();
              // document.getElementById(nextid).focus();

              }

          }

      },

      setCutomers(event) {

        this.allUsers = []
        this.name = ''
        this.value = ''

        switch (event) {

          case 'cfa':
            this.allUsers = this.allCustomers
            this.billTypeName = 'cfa'
            break;
          case 'tally':
            break;
          case 'agency':
            break;
          default:
            this.allUsers = this.allCompanies
            this.billTypeName = 'dc'
            break;

        }

      },

      isActive(menuItem) {

        return this.activeItem === menuItem;

      },

      setActive(menuItem, reportType) {

        this.activeItem = menuItem;
        this.reportType = reportType;

      },

      loadAllCustomers() {
        this.myloader = true;

        API.getCustomerAll()
          .then((response) => {
            if (response.data.status) {
              this.allCustomers = response.data.data;
            } else {
            }
          })
          .catch((e) => {
            this.myloader = false;
          });
      },

      loadAllCompanies() {

        this.myloader = true;

        API.getCompany()
          .then((response) => {
            if (response.data.status) {
              this.allCompanies = response.data.data;
            } else {
            }
          })
          .catch((e) => {
            this.myloader = false;
          });

      },

      uploadFile() {
        this.fileData = this.$refs.file.files[0];

        if (
          this.fileData.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          this.fileData = "";
          this.loadNotification('error', 4000, 'Report Message', ' Invalid uploaded file format',
            'response', 1000, true, true);
          this.excelReportLoader = true;
        } else {
          this.isUploadingRevenueExcelReport = true;

          this.file = this.fileData.name;

          this.excelReportLoader = false;

          const formData = new FormData();

          formData.append("file", this.fileData);

          // setTimeout(() => {

          API_REVENUE.uploadRevenueExcelReport(formData)
            .then((response) => {
              if (response.data.status) {
                this.file = "";
                this.fileData = "";
                this.loadNotification('success', 4000, 'Report Message', ' Report has been successifully uploaded',
                  'response', 1000, true, true);
              } else {
                this.file = "";
                this.fileData = "";
                this.loadNotification('error', 4000, 'Report Message', ' Report, not uploaded, please try',
                  'response', 1000, true, true);
              }

              this.excelReportLoader = true;
              this.isUploadingRevenueExcelReport = false;
            })
            .catch(() => {
              this.loadNotification('error', 4000, 'Report Message', ' Report, not uploaded, please try',
                'response', 1000, true, true);
            });

          // }, 2500);
        }
      },

      resetParams() {

        this.startDate = ''
        this.endDate = ''
        this.billStatus = ''
        this.startDate = ''
        this.endDate = ''
        this.billType = ''
        this.customerIds = []
        this.tariffItemIds = []
        this.billStatusIds = []
        this.allUsers = []
        this.customerIds = []
        this.TANSADNumber4 = ''
        this.TANSADNumber2 = ''
        this.TANSADNumber7 = ''

      },

      loadReport() {

        let reportType;
        let reportObject = {}
        let tansadNumber = ''
        let billStatusIds = []
        let tariffItemIds = []
        let customerIds = []

        if(this.activeItem === "RVREPORT"){

            if (this.tariffItemIds.length > 0) {

              for(let x = 0 ; x < this.tariffItemIds.length ; x++) {

                tariffItemIds.push(this.tariffItemIds[x].tariffItemId)

              }

            }

            reportObject = {

                billType: this.billType !== '' ? this.trimString(this.billType) : null,
                controlNumber: null,
                customerIds: null,
                endDate: this.endDate !== '' ? this.trimString(this.endDate) : null,
                invoiceNo: null,
                // isRevenue: true,
                startDate: this.startDate !== '' ? this.trimString(this.startDate) : null,
                statuses: null,
                tansadNo: null,
                tariffItemIds: tariffItemIds.length > 0 ? tariffItemIds : null
                
            }

        } else if (this.activeItem === "VAT") {

          if (this.tariffItemIds.length > 0) {

            for(let x = 0 ; x < this.tariffItemIds.length ; x++) {

              tariffItemIds.push(this.tariffItemIds[x].tariffItemId)

            }

          }

          if (this.customerIds.length > 0) {

            for(let x = 0 ; x < this.customerIds.length ; x++) {

              customerIds.push(this.customerIds[x].customerId)

            }

          }

          reportObject = {

            billType: this.billType !== '' ? this.trimString(this.billType) : null,
            controlNumber: null,
            customerIds: customerIds.length > 0 ? customerIds : null,
            endDate: this.endDate !== '' ? this.trimString(this.endDate) : null,
            invoiceNo: null,
            // isRevenue: true,
            startDate: this.startDate !== '' ? this.trimString(this.startDate) : null,
            statuses: null,
            tansadNo: null,
            tariffItemIds: tariffItemIds.length > 0 ? tariffItemIds : null
            
        }

        } else {

          if(this.TANSADNumber4 !== '' && this.TANSADNumber2 !== '' && this.TANSADNumber7 !== '' ) {

              tansadNumber = this.trimString(this.TANSADNumber4) + '-' + this.trimString(this.TANSADNumber2) + '-' + this.trimString(this.TANSADNumber7)

          } else {

              tansadNumber = null
          }

          if (this.billStatusIds.length > 0) {

            for(let x = 0 ; x < this.billStatusIds.length ; x++) {

              billStatusIds.push(this.billStatusIds[x].id)

            }

          }

          if (this.tariffItemIds.length > 0) {

            for(let x = 0 ; x < this.tariffItemIds.length ; x++) {

              tariffItemIds.push(this.tariffItemIds[x].tariffItemId)

            }

          }

          if (this.customerIds.length > 0) {

            for(let x = 0 ; x < this.customerIds.length ; x++) {

              customerIds.push(this.customerIds[x].customerId)

            }

          }

          reportObject = {

              billType: this.billType !== '' ? this.trimString(this.billType) : null,
              controlNumber: null,
              customerIds: customerIds.length > 0 ? customerIds : null,
              endDate: this.endDate !== '' ? this.trimString(this.endDate) : null,
              invoiceNo: null,
              // isRevenue: true,
              startDate: this.startDate !== '' ? this.trimString(this.startDate) : null,
              statuses: billStatusIds.length > 0 ? billStatusIds : null,
              tansadNo: tansadNumber !== null ? this.trimString(tansadNumber) : null,
              tariffItemIds: tariffItemIds.length > 0 ? tariffItemIds : null
              
          }

        }

        if (this.activeItem === 'VAT') {

          reportType = API_REVENUE.generateAsyncVatReport;
          this.globalLoaderName = 'VAT'

        } else if (this.activeItem === 'BILLS') {

          reportType = API_REVENUE.generateAsyncBillReport
          this.globalLoaderName = 'BILL'

        } else {

          reportType = API_REVENUE.generateAsyncRevenueReport;
          this.globalLoaderName = 'REVENUE'

        }

        this.globalLoader = true;

        reportType(reportObject).then((response) => {

            this.globalLoader = false;
            this.resetParams()
            if (response.data.status) {

              this.loadNotification("success", 4000, 'Request Message', response.data.message, 'response', 1000, true, true);

            } else {

              this.loadNotification("warning", 4000, 'Request Message', response.data.message, 'response', 1000, true, true);

            }

          })
          .catch(() => {

            this.loadNotification('error', 4000, 'Request Message',
              'No ' + this.globalLoaderName + '  report, please check your internet connection and try',
              'response', 1000, true, true);
            this.globalLoader = false

          });
      }
    },
  };
</script>

<style scoped>
  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
  }

  .back-color-vat {
    background-color: rgba(216, 225, 232, 0.3);
  }

  .back-color-non-vat {
    background-color: rgba(187, 255, 255, 0.3);
  }

  .back-color-revenue {
    background-color: rgba(162, 158, 218, 0.2);
  }

  .is-active-verified, .is-active-verified :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-new, .is-active-new :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: gray !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-rvreport,.is-active-rvreport  :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    top: 16px;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label.myLabel {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  label.myLabel2 {
    position: relative;
    right: 0;
    margin: 0;
    padding: 0;
    top: 23px;
  }

  #label-upload {
    position: relative;
    top: 16px;
  }

  .btn-space {
    margin-right: 10px;
  }

  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: 224px;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active {
    transition: all .3s ease;
  }

  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
  {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
<style>
  .custom-link {
    color: navy;
  }

  .custom-link:hover {
    color: #d59a18 !important;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
