<template>
  <div class="content">
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->

      <br>

      <div class="text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="myloader"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Specified Goods List
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            class="all-fa-icons btn-space"
            title="add new request"
            @click.prevent="openModal(null , null , null , actionButton = 'AddModal')"
            v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SPECIFIEDGOODS_POST')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>
            <div class="col-md-2 float-left">
              <select
                name="PageSize"
                @change="onChange($event)"
                class=""
                v-model="pageSize"
                style="width:73px;"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-7 text-right"
              v-if="finalUserPermissions.includes('USERS_ALLUSERS_SEARCH_GET')"
            >
              <!--              <form class="search" @submit.prevent="searchRequestByName(pageNo , pageSize , sortBy)">-->
              <!--                <div class="form-group float-right">-->
              <!--                  <div class="input-group">-->
              <!--                    <div class="refresh-all" title="load all requests" v-if="reloadAllRequest"-->
              <!--                         @click.prevent="reloadByIcon">-->
              <!--                      <font-awesome-icon icon="sync-alt"/>-->
              <!--                    </div>-->
              <!--                    <input type="text" placeholder="Search ...." id="inputGroup" v-model="searchParameter"-->
              <!--                           @input="loadAllPageble"/>-->
              <!--                    <span class="input-group-addon">-->
              <!--                        <font-awesome-icon icon="search" @click="searchRequestByName(pageNo , pageSize , sortBy)"/>-->
              <!--                    </span>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </form>-->
            </div>
            <div class="col-md-5 text-right">
              <div
                class="col-md-12 disable-highlight"
                unselectable="on"
                style="font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left" />
                </span>
                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right" />&nbsp;&nbsp;
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <!--   END TOP PAGENATION AND SEARCH ELEMENTS   -->
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              HS Code
            </th>
            <th scope="col">
              Description
            </th>
            <th scope="col">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in getSpecifiedGood"
            :key="list.specifiedGoodId"
            v-if="!noDataFound"
          >
            <th scope="row">
              {{ index + 1 }}
            </th>
            <td>
              {{ list.hsCode }}
            </td><td>
              {{ list.description }}
            </td>
            <td>
              <div
                class=" all-fa-icons edit-fa-icon"
                @click.prevent="openModal(list.specifiedGoodId , list.hsCode ,
                                          list.description , actionButton = 'EditModal')"
                title="edit"
                v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SPECIFIEDGOODS_{SPECIFIEDGOODID}_PUT')"
              >
                <font-awesome-icon icon="edit" />
              </div>
              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                @click.prevent="openModal(list.specifiedGoodId , null , null , actionButton = 'ShowModal')"
                title="view"
                v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SPECIFIEDGOODS_{SPECIFIEDGOODID}_GET')"
              >
                <font-awesome-icon icon="eye" />
              </div>
            <!--<div class="all-fa-icons fa-icons-space delete-fa-icon"-->
            <!--@click.prevent="deleteVesselType(list.specifiedGoodId , list.hsCode , list.description)"-->
            <!--title="delete" v-if="finalUserPermissions.includes('PERMISSION_SETTINGS_CONFIGURATION_SPECIFIED_GOOD_DELETE')">-->
            <!--<font-awesome-icon icon="trash-alt"/>-->
            <!--</div>-->
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="col-md-12 text-center bg-grey"
        v-if="noDataFound"
      >
        No data found
      </div>

      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
          </div>

          <div
            class="col-md-9 text-right disable-highlight"
            unselectable="on"
            style="line-height: 5px;font-size: 20px"
          >
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              title="go to first page"
            >First
            </span>
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="prevPage"
              title="go to previous page"
            >
              <font-awesome-icon icon="angle-double-left" />
            </span>

            <span class="btn-space" />
            <span class="btn-space" />

            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="nextPage"
              title="go to next page"
            >
              <font-awesome-icon icon="angle-double-right" />
            </span>
            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="lastPage"
              title="go to last page"
            >Last
            </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Specified Good <b>{{ hsCode }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            Register Specified Good
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            Details for Specified Good: <b>{{ data.hsCode }}</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editSpecifiedGood"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="HS Code"
                  placeholder="HS Code"
                  v-model="hsCode"
                  value="hsCode"
                  class="special-bottom"
                />
                <div v-if="hsCodeErrorE">
                  <div
                    class="error"
                    v-if="!$v.hsCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Description"
                  placeholder="Description"
                  v-model="description"
                  value="description"
                  class="special-bottom"
                />
                <div v-if="descriptionErrorE">
                  <div
                    class="error"
                    v-if="!$v.description.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveSpecifiedGood"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="HS Code"
                  placeholder="HS Code"
                  v-model="specifiedgood.hsCode"
                  class="special-bottom"
                />
                <div v-if="hsCodeError">
                  <div
                    class="error"
                    v-if="!$v.specifiedgood.hsCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Description"
                  placeholder="Description"
                  v-model="specifiedgood.description"
                  class="special-bottom"
                />
                <div v-if="descriptionError">
                  <div
                    class="error"
                    v-if="!$v.specifiedgood.description.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    HS Code
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.hsCode }}
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Description
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.description }}
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'SpecifiedGoodList',

  components: {

    PulseLoader,
    Modal

  },

  computed: {

    ...mapGetters('specifiedgoods', [

      'getValuesBeforeEdit',
      'getNameBeforeDelete'

    ])

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Specified Goods List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadSpecifiedGood(this.pageNo, this.pageSize)
  },

  validations: {

    specifiedgood: {
      hsCode: {
        required
      },
      description: {
        required
      }
    },

    hsCode: {
      required
    },
    description: {
      required
    }

  },

  data () {
    return {

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      docPageNo: 0,
      docPageCount: 0,
      sortBy: 'id',
      docSortBy: 'id',
      docPageSize: 0,
      getPageableDoc: 0,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,

      array: [],
      getSpecifiedGood: [],

      userDetails: {},
      permissionGroups: [],
      finalUserPermissions: [],

      data: {},

      color: 'green',
      size: '15px',
      loading: true,

      SuccessMessage: '',
      ErrorMessage: '',

      description: '',
      hsCode: '',
      specifiedGoodId: '',

      error: '',
      specifiedgood: {
        description: '',
        hsCode: ''
      },

      title: null,
      options: {
        color: 'primary',
        width: 290,
        zIndex: 200
      },

      SuccessAlert: false,
      ErrorAlert: false,
      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,

      hsCodeError: false,
      descriptionError: false,
      hsCodeErrorE: false,
      descriptionErrorE: false
    }
  },
  methods: {

    nextPage () {
      this.pageNo++
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        this.loadSpecifiedGood(this.pageNo, this.pageSize)
      }
    },

    prevPage () {
      this.pageNo--
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        this.loadSpecifiedGood(this.pageNo, this.pageSize)
      }
    },

    firstPage () {
      this.pageNo = 0
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        this.loadSpecifiedGood(this.pageNo, this.pageSize)
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        this.loadSpecifiedGood(this.pageNo, this.pageSize)
      }
    },

    onChange (event) {
      this.getUsers = []
      this.searchParamenter = ''
      this.pageSize = event.target.value
      this.pageNo = 0

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        this.loadSpecifiedGood(this.pageNo, this.pageSize)
      }
    },

    loadAllPageble () {
      if (this.searchParameter === '' && this.hasSearchedVariable === true) {
        this.loadSpecifiedGood(this.pageNo, this.pageSize)
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
      }
    },

    reloadByIcon () {
      this.loadSpecifiedGood(this.pageNo, this.pageSize)

      this.noDataFound = false
      this.hasSearchedVariable = false
      this.reloadAllRequest = false
      this.searchParamenter = ''
    },

    searchRequestByName (pageNo, pageSize, sortBy) {
      if (this.$v.searchParameter.$invalid) {

      } else {
        this.myloader = true
        this.reloadAllRequest = true
        this.hasSearchedVariable = true

        API.searchPagebleUser(this.searchParameter, pageNo, pageSize, sortBy).then(
          response => {
            if (response.data.status) {
              this.myloader = false

              if (response.data.data.length > 0) {
                this.noDataFound = false
                this.getSpecifiedGood = response.data.data
                this.pageCount = response.data.message
                this.docPageCount = this.pageCount
                this.docPageNo = this.pageNo
                this.docPageSize = this.pageSize
                this.myloader = false
              } else {
                this.noDataFound = true
                this.pageCount = response.data.message
                this.docPageCount = this.pageCount
                this.docPageNo = this.pageNo
                this.docPageSize = this.pageSize
                this.myloader = false
              }
            } else {
              this.noDataFound = true
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.getSpecifiedGood = response.data.data
              this.myloader = false
            }
          }
        ).catch(e => {
          this.myloader = false
        })
      }
    },

    loadSpecifiedGood (pageNo, pageSize) {
      return new Promise((resolve, reject) => {
        this.myloader = true

        API.getSpecifiedGood(pageNo, pageSize).then(
          response => {
            if (response.data.status) {
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.getSpecifiedGood = response.data.data
              this.myloader = false
            } else {

            }

            resolve()
          }
        ).catch(e => {
          this.ErrorMessage = ' Requests Not loaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false

          reject(e)
        })
      })
    },

    // loadSpecifiedGood() {
    //
    //   this.myLoader = true;
    //
    //   API.getSpecifiedGood().then(
    //     response => {
    //
    //       if (response.data.status) {
    //
    //         this.getSpecifiedGood = response.data.data;
    //         this.myLoader = false;
    //
    //       } else {
    //
    //       }
    //     }
    //   ).catch(e => {
    //
    //     this.ErrorMessage = " Record not loaded";
    //     this.SuccessAlert = false;
    //     this.ErrorAlert = true;
    //     this.SuccessMessage = "";
    //     this.SuccessAlert = false;
    //     this.myLoader = false;
    //
    //   });
    // },

    loadSingleSpecifiedGood (data) {
      API.getIndividualSpecifiedGood(data).then(
        response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'Data not found'
          }
        }
      ).catch(e => {
        // this.state.errors.push(e)
      })
    },

    saveSpecifiedGood () {
      if (this.$v.specifiedgood.hsCode.$invalid || this.$v.specifiedgood.description.$invalid) {
        if (this.$v.specifiedgood.hsCode.$invalid) {
          this.hsCodeError = true
        }
        if (this.$v.specifiedgood.description.$invalid) {
          this.descriptionError = true
        }
      } else {
        this.editMyLoader = true

        // setTimeout(() => {

        API.postSpecifiedGood(JSON.stringify(this.specifiedgood)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = ',Specified Good with HS Code[ ' + response.data.data.hsCode + ' ] '
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadSpecifiedGood(this.pageNo, this.pageSize)
              this.editMyLoader = false
              this.specifiedgood.hsCode = ''
              this.specifiedgood.description = ''
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ' Record not loaded'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.specifiedgood.hsCode = ''
          this.specifiedgood.description = ''
          this.closeModal()
        })

        // }, 2000);
      }
    },

    // deleteSpecifiedGood(data, name, code) {
    //
    //   this.$store.dispatch("specifiedgoods/nameBeforeDelete", name);
    //
    //   this.$dialog.confirm("If you delete Specified Good with HS Code [ " + name + " ], it'll be gone forever.", {
    //
    //     loader: true
    //   })
    //     .then((dialog) => {
    //
    //       // setTimeout(() => {
    //
    //       API.deleteSpecifiedGood(data).then(
    //         response => {
    //
    //           if (response.data.status) {
    //
    //             this.SuccessMessage = ", Specified Good with HS Code [ " + this.getNameBeforeDelete + " ] has been deleted";
    //             this.$store.dispatch("specifiedgoods/nameBeforeDelete", "");
    //             this.ErrorMessage = "";
    //             this.SuccessAlert = true;
    //             this.ErrorAlert = false;
    //             this.loadSpecifiedGood();
    //
    //           } else {
    //
    //           }
    //         }
    //       ).catch(e => {
    //
    //         this.ErrorMessage = " Record not deleted";
    //         this.SuccessMessage = "";
    //         this.SuccessAlert = false;
    //         this.ErrorAlert = true;
    //
    //       });
    //
    //       dialog.close();
    //       // }, 2500);
    //
    //     })
    //     .catch(() => {
    //
    //       this.myLoader = false;
    //       this.SuccessAlert = false;
    //
    //     });
    // },

    editSpecifiedGood () {
      if (this.$v.hsCode.$invalid || this.$v.description.$invalid) {
        if (this.$v.hsCode.$invalid) {
          this.hsCodeErrorE = true
        }
        if (this.$v.description.$invalid) {
          this.descriptionErrorE = true
        }
      } else {
        this.editMyLoader = true

        this.array =
            {
              description: this.description,
              hsCode: this.hsCode,
              specifiedGoodId: this.specifiedGoodId
            }

        // setTimeout(() => {

        API.updateSpecifiedGood(JSON.stringify(this.array)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = `${this.updatedValueStatus(this.getValuesBeforeEdit,
                  [response.data.data.hsCode, response.data.data.description], 'Specified Good with HS Code', response.data.data.hsCode)}`
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              window.scrollTo(10, 0)
              this.closeModal()
              this.loadSpecifiedGood(this.pageNo, this.pageSize)
              this.$store.dispatch('specifiedgoods/valuesBeforeEdit', [])
              this.editMyLoader = false
            } else {

            }
          }
        ).catch(e => {
          this.errors = e
          this.editMyLoader = false
          this.ErrorMessage = ' Record not edited'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.closeModal()
        })

        // }, 2000);
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (specifiedGoodId = null, hsCode = null, description = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.AddModalDiv = false
        this.ShowModalDiv = false
        this.hsCode = hsCode
        this.description = description
        this.specifiedGoodId = specifiedGoodId
        this.$store.dispatch('specifiedgoods/valuesBeforeEdit', [this.hsCode, this.description])
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
      } else {
        this.loadSingleSpecifiedGood(specifiedGoodId)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.hsCodeError = false
      this.descriptionError = false
      this.hsCodeErrorE = false
      this.descriptionErrorE = false
    }

  }
}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>
