<template>
  <div>
    <card>
      <modal v-if="showModal">
        <div slot="body">
          <div class="row">
            <div class="col-md-12 text-center">
              <pulse-loader
                :loading="initBalanceLoader"
                :color="color"
                :size="size"
              />
            </div>

            <div class="col-md-12 mb-3">
              <div class="form-group">
                <h3>{{customerData.name}} INITIAL BALANCE</h3>
                <label for="initBalance">Init Balance</label>
                <input type="text" class="form-control float-right" id="initBalance" v-model="initBalance">
                <div v-if="initBalanceError">
                  <div
                    class="error text-danger"
                    v-if="!$v.initBalance.required"
                  >
                    This field is required
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-12">
              <button
                type="submit"
                @click.prevent="closeModal"
                class="btn btn-default btn-space custom-btn-all float-right"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                @click.prevent="setInitBalance"
                class="btn btn-primary btn-space custom-btn float-right mr-2"
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </modal>
      <div class="row">
        <div class="col-md-3 mb-3">
          <label>Select Customer Category</label>
          <list-select
            :list="customerTypes"
            :selected-item="selectedCustomerTypeObject"
            class="form-control"
            option-value="id"
            option-text="name"
            placeholder="Select customer Category"
            @select="loadAllCustomers"
          >
          </list-select>
        </div>

        <div class="col-md-3" v-if="allCustomers.length > 0 && selectedCustomerType !== ''">
          <label class="typo__label" v-if="allCustomers.length > 0">Search customer</label>
          <label class="typo__label" v-else>
              <pulse-loader :color="color" :size="size1" class="float-left mr-4 pt-1"/>
              Loading customers, please wait
          </label>
          <list-select
            :list="allCustomers"
            :selected-item="customerData"
            class="form-control"
            option-value="id"
            option-text="name"
            placeholder="Search customer"
            @select="selectedCustomer"
          >
          </list-select>
        </div>
        <div class="col-md-3 text-center" v-else style="margin-top:35px;">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
          />
        </div>
        <div class="col-md-3" v-if="customerId !== ''">
          <!-- {{ myDate[0] | moment("YYYY-MM-DD") }} -->
          <label class="custom-font">Pick Start Date</label>
          <date-picker  style="width:100% !important;" v-model="startDate" @change="setFormattedStartDate" format="YYYY-MM-DD" :disabled-date="validateStartDate"></date-picker>
          <div v-if="startDateError">
                    <div class="text-danger" v-if="!$v.startDate.required">Required</div>
                  </div>
        </div>
        <div class="col-md-3" v-if="customerId !== ''">
          <!-- {{ myDate[0] | moment("YYYY-MM-DD") }} -->
          <label class="custom-font">Pick End Date</label>
          <date-picker  style="width:100% !important;" v-model="endDate" @change="setFormattedEndDate" format="YYYY-MM-DD" :disabled-date="validateEndDate"></date-picker>
          <div v-if="endDateError">
                    <div class="text-danger" v-if="!$v.endDate.required">Required</div>
                  </div>
        </div>
      </div>
      <div class="row" v-if="customerId !== ''">
        <div class="col-md-12 text-right">
          <button
            type="submit"
            @click.prevent="openModal"
            :disabled="getCustomerStatementLoader"
            class="btn btn-success btn-space custom-btn float-right mb-2"
            data-dismiss="modal"
          >
            Set Initial Balance
          </button>
        </div>
          <div class="col-md-12">
            <hr />
          </div>
      </div>
      <div class="row" v-if="Object.keys(selectedCustomerTypeObject).length > 0 && Object.keys(customerData).length > 0">
        <div class="col-md-8 text-right" style="margin-top:15px;">
            <pulse-loader
              :loading="getCustomerStatementLoader"
              :color="color"
              :size="size"
            />
          </div>
        <div class="col-md-4 float-right">
          <button
            type="submit"
            @click.prevent="clearResult"
            :disabled="getCustomerStatementLoader"
            class="btn btn-default btn-fill custom-btn-all float-right"
            data-dismiss="modal"
          >
            Clear Result
          </button>
          <button
            type="submit"
            @click.prevent="printStatement"
            :disabled="getCustomerStatementLoader"
            class="btn btn-default btn-space custom-btn float-right mr-2"
            data-dismiss="modal"
          >
            Print Statement
          </button>

          <button
            type="submit"
            @click.prevent="getCustomerStatement"
            :disabled="getCustomerStatementLoader"
            class="btn btn-primary btn-space custom-btn float-right mr-2"
            data-dismiss="modal"
          >
            View Statement
          </button>
        </div>
      </div>
    </card>
    <card v-if="statementData.customer.name !== undefined">
      <div class="row" >
        <div class="col-md-12 text-center">
          <div>
            <span class="statement-dates-2">Period Covered</span>
            <span>( {{statementData.periodCovered }} )</span>
          </div>
          <div>
            <span class="statement-dates-1">Statement Date</span>
            <span>( {{statementData.statementDate }} )</span>
          </div>
        </div>

        <div class="col-md-12">
          <hr class="charge-custom-hr"/>
        </div>

        <div class="col-md-6 text-right" style="padding-left:100px;">

          <table class="float-left">
            <tbody>
              <tr>
                  <th class="declaration-table-th mr-4">Customer Name:</th>
                  <th class="declaration-table-th mr-4" style="width:50px;"></th>
                  <td> {{ statementData.customer.name }} </td>
              </tr>
              <tr>
                  <th class="declaration-table-th mr-4">Phone Number:</th>
                  <th class="declaration-table-th mr-4" style="width:50px;"></th>
                  <td> {{ statementData.customer.mobile }} </td>
              </tr>
              <tr>
                  <th class="declaration-table-th mr-4">TIN:</th>
                  <th class="declaration-table-th mr-4" style="width:50px;"></th>
                  <td> {{ statementData.customer.tin }} </td>
              </tr>
              <tr>
                  <th class="declaration-table-th mr-4">VRN:</th>
                  <th class="declaration-table-th mr-4" style="width:50px;"></th>
                  <td> {{ statementData.customer.vrn }} </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-md-6 text-right" style="padding-right:100px;">
          <table class="float-right">
            <tbody>
              <tr>
                  <th class="declaration-table-th mr-4">Opening Balance:</th>
                  <th class="declaration-table-th mr-4" style="width:50px;"></th>
                  <td> {{ openingBalance }} </td>
              </tr>
              <tr>
                  <th class="declaration-table-th mr-4">Total Credit Amount:</th>
                  <th class="declaration-table-th mr-4" style="width:50px;"></th>
                  <td> {{ totalCredit }} </td>
              </tr>
              <tr>
                  <th class="declaration-table-th mr-4">Total Debit Amount:</th>
                  <th class="declaration-table-th mr-4" style="width:50px;"></th>
                  <td> {{ totalDebit }} </td>
              </tr>
              <tr>
                  <th class="declaration-table-th mr-4">Closing Balance:</th>
                  <th class="declaration-table-th mr-4" style="width:50px;"></th>
                  <td> {{ closingBalance }} </td>
              </tr>
              <tr>
                  <th class="declaration-table-th mr-4">Number of Transactions:</th>
                  <th class="declaration-table-th mr-4" style="width:50px;"></th>
                  <td> {{ putComma(statementData.transCount, 0) }} </td>
              </tr>
              <tr>
                  <th class="declaration-table-th mr-4">Statement Currency:</th>
                  <th class="declaration-table-th mr-4" style="width:50px;"></th>
                  <td> TZS </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12">
          <br />
        </div>
        <div class="col-md-12">
          <b>Transactions</b>
        </div>
      </div>
      <data-table
        :table-headers="tableHeaders"
        :table-data="filteredRequests"
        :table-data-keys="tableDataKeys"
        :is-pageable="false"
        :no-data-found="noDataFound"
        :has-pagination="false"
        :has-filter="false"
        :has-manage-button="false"
        :loading-title="'balaaaa'"
        :permissions="[ { key: 'searchPermission', value: 'STATEMENT_GET_POST' } ]">
      </data-table>
    </card>
  </div>

</template>

<script>
  import API from "../../../api/index"
  import API_REVENUE from "../../../api/rev";
  import {ListSelect} from 'vue-search-select';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import Modal from '../../../components/Inputs/Modal'
  import { required } from 'vuelidate/lib/validators'
  import {mapActions} from "vuex";
  import { CUSTOMER_TYPE } from '../../../mixins/constants'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';


  /* this method below set current month in second calendar and previous month on first calendar */
  // const updateCalendars = DatePicker.CalendarRange.methods.updateCalendars;
  // DatePicker.CalendarRange.methods.updateCalendars = function (...args) {
  //   updateCalendars.apply(this, args);

  //   this.calendars = this.calendars.map((v) => {
  //     const next = new Date(v);
  //     next.setMonth(next.getMonth() - 1);
  //     return next;
  //   });

  // };


  export default {

        name: "customerStatement",

      components:{
        ListSelect,
        PulseLoader,
        DatePicker,
        DataTable: () => import('../../../components/DataTable/dataTable'),
        Modal

      },

    validations:{

      initBalance:{required},
      startDate:{required},
      endDate:{required}

    },

      created() {

        this.$store.dispatch("pagenames/currentPageName", "Customer Statement")

      },


      data () {

          return {

            loading:false,
            initBalanceLoader:false,
            color: 'green',
            size: '12px',
            size1: '9px',
            allCustomers:[],
            customerId:"",
            customerData:{},
            selectedCustomerType:"",
            selectedCustomerTypeObject:{},
            todayDate: new Date().toJSON().slice(0, 10),
            startDate:"",
            endDate:"",
            noDataFound: false,
            startDateFormatted:"",
            endDateFormatted:"",
            customerTypes: CUSTOMER_TYPE,
            getCustomerStatementLoader:false,

            statementData:{

              customer:{},
              statements:[]

            },

            showModal:false,
            initBalance:"",
            initBalanceError:false,
            startDateError:"",
            endDateError:"",

            tableHeaders: ['Date', 'Description', 'Debit', 'Credit', 'Balance'],
            tableDataKeys: ['transDate', 'description', 'debit', 'credit', 'balance'],
            filteredRequests: [],
            openingBalance: ''

          }

      },

      methods:{

        setFormattedStartDate () {

          this.startDateFormatted = this.$moment(this.startDate).format("YYYY-MM-DD")

        },
        setFormattedEndDate () {

          this.endDateFormatted = this.$moment(this.endDate).format("YYYY-MM-DD")

        },

        validateEndDate(date) {

          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return date > today || date < this.startDate

        },

        validateStartDate (date) {

          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return date > today || date < this.endDate

        },

        clearResult () {

          this.startDate = '';
          this.endDate = '';
          this.startDateFormatted = '';
          this.endDateFormatted = '';
          this.selectedCustomerTypeObject = {};
          this.customerData = {};
          this.customerId = '';
          this.selectedCustomerType = '';
          this.statementData = {

              customer: {},
              statements: []

          }

        },

        ...mapActions("global", [

          'loadFileFromMinio'

        ]),

        loadParameters (fileUrl, fileName) {

          return new Promise((resolve) => {

            this.$store.dispatch('global/fileUrling', fileUrl);
            this.$store.dispatch('global/fileNaming', fileName);

            resolve()

          })

        },

        openModal () {

          this.showModal = true

        },

        closeModal () {

          this.showModal = false;
          this.initBalance = "";
          this.initBalanceError = false

        },

        setInitBalance(){

          this.initBalanceError = false;

          if(this.initBalance !== "") {

            this.initBalanceLoader = true;

            API_REVENUE.setInitialBalance(this.customerId,this.initBalance).then(() => {

              this.initBalanceLoader = false;
              this.initBalance = "";
              this.closeModal()

            }).catch(() => {

              this.initBalanceLoader = false

            })

          }else {

            this.initBalanceError = true
          }
        },

        printStatement () {


          // this.startDateError=false;
          // this.endDateError=false;
          // if(this.startDate!=="" && this.endDate!==""){
            this.getCustomerStatementLoader=true;

            // let startDateRaw=new Date(this.startDate);
            // let monthStart=startDateRaw.getMonth() + 1;
            // let endDateRaw=new Date(this.endDate);
            // let monthEnd=endDateRaw.getMonth() + 1;

            // let startDate=startDateRaw.getFullYear() + "-" + monthStart  + "-" + ("0" + startDateRaw.getDate()).slice(-2);
            // let endDate=endDateRaw.getFullYear() + "-" + monthEnd + "-" + ("0" + endDateRaw.getDate()).slice(-2);

            if (this.$v.endDate.$invalid) { this.startDateFormatted = null }
            if (this.$v.startDate.$invalid) { this.endDateFormatted = null }

            let data = {
              billType: this.selectedCustomerType,
              customerId: this.customerId,
              endDate: this.endDateFormatted,
              statDate: this.startDateFormatted
            };

            API_REVENUE.printCustomerStatement(data).then(
              response => {
                this.getCustomerStatementLoader=false;

                if (response.status === 200) {

                  const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', 'invoice.pdf'); // or any other extension
                  document.body.appendChild(link);
                  link.click();
                  this.loadNotification('success', 4000, 'Request Message',
                    'Statement has been successfully downloaded, please have a look at it',
                    'response', 1000 , true  , true);

                } else {

                  this.loadNotification('error', 4000, 'Request Message',
                    'No content found for requested Statement',
                    'response', 1000 , true  , true);
                }

              }).catch(() => {
              this.getCustomerStatementLoader=false;

              this.loadNotification('error', 4000, 'Request Message',
                'No content found for requested Statement',
                'response', 1000 , true  , true);

            })


          // }else {
          //   this.startDateError=true;
          //   this.endDateError=true;
          // }

        },

        getCustomerStatement() {

          // if(this.$v.startDate.$invalid || this.$v.endDate.$invalid) {

              if (this.$v.endDate.$invalid) { this.startDateFormatted = null }
              if (this.$v.startDate.$invalid) { this.endDateFormatted = null }

          // }

          //else {

            this.getCustomerStatementLoader = true;
            this.filteredRequests = [];
            let requestData = {

              billType: this.selectedCustomerType,
              customerId: this.customerId,
              statDate:this.startDateFormatted,
              endDate: this.endDateFormatted

            };

            API_REVENUE.getCustomerStatement(requestData).then((response) => {

              if(response.data.status) {

                this.statementData = {

                  customer: {},
                  statements: []

                }

                this.statementData = response.data.data;
                this.openingBalance = this.statementData.openingBalance < 0 ? this.putComma(this.statementData.openingBalance * (-1)) : this.putComma(this.statementData.openingBalance);
                this.totalCredit = this.statementData.totalCredit < 0 ? this.putComma(this.statementData.totalCredit * (-1)) : this.putComma(this.statementData.totalCredit);
                this.totalDebit = this.statementData.totalDebit < 0 ? this.putComma(this.statementData.totalDebit * (-1)) : this.putComma(this.statementData.totalDebit);
                this.closingBalance = this.statementData.closingBalance < 0 ? this.putComma(this.statementData.closingBalance * (-1)) : this.putComma(this.statementData.closingBalance);
                this.sortingData(this.statementData.statements)

              } else {



              }

              this.getCustomerStatementLoader = false;

            }).catch(() => {

              this.getCustomerStatementLoader = false;

            });

          // }


        },

        sortingData(data) {

        for (let x = 0; x < data.length; x++) {

            const requestObject = {
              ...data[x],
              debit: data[x].transactionType === 'DEBIT' ? this.putComma(data[x].amount < 0 ? (data[x].amount * (-1)) : data[x].amount) : '',
              credit: data[x].transactionType === 'CREDIT' ? this.putComma(data[x].amount < 0 ? (data[x].amount * (-1)) : data[x].amount) : '',
              balance: this.putComma(data[x].balance < 0 ? (data[x].balance * (-1)) : data[x].balance)
            };

            this.filteredRequests.push(requestObject)

          }

        },

        selectedCustomer(data){

          this.customerId = data.id;
          this.customerData = data;

        },

        refactorCustomer(data){
          this.allCustomers=[];

          if(this.selectedCustomerType==='CFA'){

            for(let x = 0 ; x < data.length ; x++) {

              const requestObject = {
                id:data[x].customerId,
                name:data[x].name
              };

              this.allCustomers.push(requestObject)

            }
          }else {
            for(let x = 0 ; x < data.length ; x++) {

              const requestObject = {
                id:data[x].companyId,
                name:data[x].companyName
              };

              this.allCustomers.push(requestObject)

            }
          }

        },


        loadAllCustomers(data) {
          this.selectedCustomerTypeObject = data;
          this.selectedCustomerType = this.selectedCustomerTypeObject.id;
          this.loading = true;
          this.allCustomers=[];
          this.customerId="";
          this.customerData={};
          this.startDate="";
          this.endDate="";
          this.statementData={
            customer:{},
            statements:[]
          };


          if(this.selectedCustomerType==='CFA'){

            API.getCustomerAll().then((response) => {
              this.loading=false;

              if (response.data.status) {
                this.refactorCustomer(response.data.data);
              } else {

              }
            }).catch((e) => {
                this.loading=false;
            });

          }else {

            API.getCompany().then((response) => {
              this.loading=false;
              if (response.data.status) {
                this.refactorCustomer(response.data.data);
              } else {

              }
            }).catch((e) => {
                this.loading=false;
            });
          }
        },

      }
    }
</script>

<style scoped>
  .custom-btn-all {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      padding: 5px 9px 5px 9px !important;
      font-size: 14px !important;
      width: auto;
  }

  .custom-btn {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      padding: 5px 9px 5px 9px !important;
      font-size: 14px !important;
      width: auto;
      background-color: #FFFFFF !important;
  }
  .table-header {
    background-color:rgba(249, 249, 249 , 5);
    padding-top: 10px;
    padding-left:40px;
    padding-right:40px;
    margin-bottom: 5px;
    margin-top: 5px;
    border: 1px solid #dcdcdc;
    /* margin-left: 60px; */
    border-radius: 3px;
  }

  .custom-table-header {
    background-color:rgba(33, 115, 208 , .7);
    margin-bottom:50px;
  }

  .table > thead > tr > th {
    color: black !important;
  }

  .customer-info{
    padding: 30px;
    text-align: left;
  }

  .customer-ifo-wrapper{
    display: flex;
  }

  .detail-info{
    width: 200px;
  }
  .statement{
    border-bottom: 4px solid rgba(249, 249, 249 , 5);
    padding: 10px;
  }
  .account-header{
    letter-spacing: 6px;
    font-weight: 800;
  }

  .statement-dates-1{
    letter-spacing: 4px;
    font-weight: 500;
  }

  .statement-dates-2{
    letter-spacing: 2px;
    font-weight: 500;
  }
</style>
<style>
.mx-input {
    height: 40px !important;
  }
</style>
