<template>
  <div>
    <card v-if="firstLoader">
      <div class="row">
        <div class="col-md-12 text-center">
          <b>Please wait while system is loading bill of lading details </b>
        </div>
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader
            :color="color"
            :size="size">
          </pulse-loader>
        </div>
      </div>
    </card>

    <card style="font-size: 12px" v-if="!firstLoader">
      <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size">
        </pulse-loader>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ul class="nav">
            <!-- <li>
              <button @click.prevent="cancelCharge"
                      class="btn btn-primary mr-2 custom-btn">
                Cancel Charge
              </button>
            </li> -->
            <!-- <li>
              <button @click.prevent="generateBill"
                      class="btn btn-blue btn-blue-fill custom-btn">
                Generate Bill
              </button>
            </li> -->
          </ul>
        </div>
      </div>

      <div class="legend">
        <h6 class="legend-title">
          Tally Notification Details
        </h6>
        <div>
          <div class="row div-table-row">
            <div class="col-md-2 div-table div-table-title mb-3">
              Vessel Name
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.vesselName}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Voyage
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.vesselName}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Operation Type
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.operationType}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Start Time
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.startTime}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              End Time
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.endTime}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Location Type
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.locationType}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Location
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.locationName}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Status
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.completed ? "COMPLETED": "NOT COMPLETED"}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Created By
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.createdBy}}
            </div>
          </div>
        </div>
      </div>

      <div class="legend">
        <h6 class="legend-title">
          Tally Charge Details
        </h6>
        <div>
          <div class="row div-table-row">

            <div class="col-md-2 div-table div-table-title mb-3">
              Status
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.chargeStatus}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Empty FEU
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.feuemptyQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Empty TEU
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.teuemptyQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Full FEU
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.feufullQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Full TEU
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.teufullQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Vehicles
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.vehiclesQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Packages
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.packageQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Created By
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.createdBy}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Creation Time
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.createdAt}}
            </div>

            <!-- <div class="col-md-2 div-table div-table-title mb-3">
              Active
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.active}}
            </div> -->
          </div>
        </div>
      </div>
      <div
        class="row text-right"
        v-if="!billingFormShower"
      >
        <div class="col-md-12">
          <button
            type="submit"
            class="btn btn-primary btn-space mr-2 custom-btn"
            @click="showBillChargeForm"
            data-dismiss="modal"
          >
            Generate Bill
          </button>
          <button
            class="btn btn-default btn-fill custom-btn"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo" /> &nbsp; Back
          </button>
        </div>
      </div>
      <div class="row" v-if="billingFormShower">
        <!-- BILL GENERATION  -->
        <div class="col-md-12">
          <label for="cancelCharge">Provide Comment for bill generation</label>
          <textarea
            class="form-control"
            id="generateCharge"
            rows="4"
            v-model="billComment"
          />
          <div v-if="billCommentError">
            <div
              class="error"
              v-if="$v.billComment.required"
            >
              This field is required
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <br>
        </div>
        <div class="col-md-12 text-right">
          <div class="row">
            <div class="col-md-10 pt-3">
              <pulse-loader
                :loading="billingLoader"
                :color="color"
                :size="size"
              />
            </div>
            <div class="col-md-2">
              <button
                type="submit"
                class="btn btn-primary btn-space mr-2 custom-btn"
                data-dismiss="modal"
                :disabled="billingLoader"
                @click.prevent="generateCfaBill"
              >
                Generate Bill
              </button>

              <button
                class="btn btn-default btn-fill custom-btn"
                @click.prevent="showBillChargeForm"
                :disabled="billingLoader"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader';
  import GlobalModal from '../../../../components/globalLoader'
  import Modal from '../../../../components/Inputs/Modal';
  import TALLY_API from '../../../../api/tally'
  import API_REVENUE from '../../../../api/rev'
  import { required } from 'vuelidate/lib/validators'

  export default {

    name: "tallyChargeDetail",

    components: {

      PulseLoader,
      GlobalModal,
      Modal

    },

    validations: {

      billComment: { required }

    },

    data() {

      return {

        color: 'green',
        size: '12px',
        id: '',
        charge: {},
        firstLoader: false,
        loading: false,
        billComment: '',
        billCommentError: false,
        billingFormShower: false,
        billingLoader: false

      }

    },

    created() {

      this.charge = this.$route.params.obj;

      // if (this.$route.params.tallySheetChargeId) {

      //   this.laodChargeDetails(this.$route.params.tallySheetChargeId)

      // }

    },

    methods: {

      showBillChargeForm () {

        this.billingFormShower = !this.billingFormShower
        this.scrollToBottom()

      },

      laodChargeDetails(data) {

        this.firstLoader = true;

        TALLY_API.getTallySheetDocumentChargeById(data).then((response) => {

          if(response.data.status) {

            this.charge = response.data.data

          }

          this.firstLoader = false;
          
        });
      },

      generateCfaBill () {

      this.$dialog.confirm('You are about to generate bill for the currently selected tallying charge. <br><br>' +
          ' Are you sure!.', {
        loader: true,
        html: true,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        okText: 'Yes, generate',
        cancelText: 'No, close',

      }).then((dialog) => {

        this.billCommentError = false
        this.billingLoader = true

        API_REVENUE.generateBillForTallySheetCharges(this.charge.tallyDocumentId, this.charge.operationType, this.billComment).then(response => {

          if (response.data.status) {

            this.billingLoader = false
            this.billComment = ''
            this.cancelBillingFormShower = false
            this.$router.push({name: "ChargedTallySheets"});
            this.loadNotification('success', 4000, '', 'Bill has been successfully created', 'response', 1000 , true  , true)

          } else {

            this.myloader = false;
            this.billingLoader = false;
            this.loadNotification('error', 4000, '', response.data.message, 'response', 1000 , true  , true)

          }

        }).catch(() => {

          this.loadNotification('error', 4000, '', 'Bill not generated, please try or contact system admin', 'response', 1000 , true  , true)
          this.myloader = false;
          this.billingLoader = false;

        });

          dialog.close();
      }).catch(()=>{});

      },

      goBack () {
        if (window.$cookies.isKey('browser-local-cashed-url-back')) {
          this.$router.push(window.$cookies.get('browser-local-cashed-url-back'))

          window.$cookies.remove('browser-local-cashed-url-back')
        }
      },

      getCharge() {

        // getTallySheetDocumentChargeById

      },

      cancelCharge() {

        console.log("hello")

      },

      generateBill() {

        console.log("hello")

      }

    }

  }
</script>

<style scoped>

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    background-color: white;
    padding: 0.2rem 1rem;
    color: #d59a18;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(153, 186, 221, .5) !important;
    font-weight: normal !important;
  }

  tbody tr {
    color: #212529 !important;
    font-weight: 400 !important;
  }

  .table td, .table th {
    text-align: center !important;
  }

  .btn {
    font-size: 0.9rem !important;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }
</style>
