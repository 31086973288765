<template>
<card>
  <div class="col-md-12">
    <data-table
      :tableHeaders="tableHeaders"
      :tableData="filteredRequests"
      :tableDataKeys="tableDataKeys"
      :pageSizeProp="pageSize"
      :pageNo="pageNo"
      :hasFilter="false"
      :myLoader="loading"
      :myFirstLoader="loading"
      :noDataFound="noDataFound"
      :searchParameterProp="searchParameter"
      :loadingTitle="'Please wait while system is loading Bill of lading charges'"
      :hasManageButton="true"
      :totalElements="totalElements"
      :totalPages="totalPages"
      :isFirst="isFirst"
      :isLast="isLast"
      :isHovered="[]"
      :pageNumber="pageNo"
      :is-hovered="false"
      :permissions="[ {key: 'searchPermission', value: 'BL-CHARGE_ALL_GET'} ]"
      @goToApplication="goToApplication"
      @onChange="onChange"
      @goToPage="goToPage"
    >
      <div slot = "pagination">
        <paginate
          v-model="getIsPage"
          :page-count=parseInt(totalPages)
          :page-range="3"
          :margin-pages="2"
          :click-handler="goToPage"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination'"
          :page-class="'page-item'">
        </paginate>
      </div>
    </data-table>

  </div>
</card>
</template>

<script>

  import PulseLoader from 'vue-spinner/src/PulseLoader';
  import GlobalModal from '../../../../components/globalLoader';
  import API from "../../../../api/agency";

    export default {
        name: "billOfLadingCharges",

      components: {

        PulseLoader,
        GlobalModal,
        DataTable: () => import('../../../../components/DataTable/dataTable')

      },

      computed:{

        getIsPage: {

          get: function () {
            return this.isPage
          },
          set: function (newValue) {
            this.isPage = newValue;
          }

        }
      },

      created() {
        this.$store.dispatch('pagenames/currentPageName', 'BILL of Lading Charges');
        this.getAllBillofLadingsCharges();
      },

      data(){
          return{
            tableHeaders:['BILL-No','Exporter','Delivery Address','Consignee', 'Cancelled', 'Billed'],
            tableDataKeys:['number','exporter','deliveryAddress','consignee', 'cancelled', 'billed'],
            filteredRequests:[],
            loading:false,
            pageNo: 0,
            pageSize: 15,
            totalElements: "",
            totalPages: "",
            isFirst: false,
            isLast: false,
            pageCount: 1,
            noDataFound:false,
            searchParameter: '',
            isPage: 0,
          }
      },

      methods:{

        sortingData(data) {
          this.filteredRequests=[];
          for(let x = 0 ; x < data.length ; x++) {

            const requestObject = {
              ...data[x],
              number:data[x].billOfLading.billOfLadingNo,
              exporter:data[x].billOfLading.exporterName,
              deliveryAddress:data[x].billOfLading.placeOfDelivery,
              consignee:data[x].billOfLading.consigneeName,
              cancelled: data[x].cancelled ? 'YES' : 'NO',
              billed: data[x].billed ? 'YES' : 'NO'
            };

            this.filteredRequests.push(requestObject);
          }

        },

          getAllBillofLadingsCharges(){
          this.loading=true;
            API.getBillOfLadingCharges().then((resp)=>{
              this.loading=false;

              if(resp.data.status){
                this.totalPages = resp.data.data.totalPages;
                this.totalElements = resp.data.data.totalElements;
                this.pageNumber = resp.data.data.number;
                this.isFirst = resp.data.data.first;
                this.isLast = resp.data.data.isLast;

                if (resp.data.data.content.length > 0) {
                  this.noDataFound = false;
                  this.sortingData(resp.data.data.content);
                } else {
                  this.noDataFound = true;
                }
              }

            }).catch((e) => {
              
              this.loadNotification('error', 4000, 'Error',
                'Failed To Load Charges',
                'response', 1000 , true  , true);
            });
          },

        onChange(event) {

          this.pageSize = event;
          this.pageNo = 0;
          this.isPage = 0

        },

        goToPage(page) {

          if(page > 0) {page = page - 1;}
          this.isPage = page + 1;
          this.pageNo = page;

          this.getAllBillofLadingsCharges();

        },

        goToApplication(data){

          document.cookie = "browser-local-cashed-url-back=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
          this.$router.push({name:'billOfLadingChargesView',params:{billChargeId:data.billOfLadingChargeId}});

        }

      }
    }
</script>

<style scoped>

</style>
