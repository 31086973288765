<template>
  <div>
    <div class="col-md-12">
      <form>
        <div class="row" v-if="!tallyDaily && !tallyVesselPerformance">
          <div class="col-md-12 category-title">
            <b>Pick Tally Shift from category below</b> <sup class="error">*</sup>
          </div>
          <div :key="indexTallyShift" class="col-md-4 tally-shift" v-for="(tallyShift, indexTallyShift) in tallyShifts">
            <div class="custom-control">
              <input :id="tallyShift.id"
                     :value="tallyShift.id"
                     name="shift"
                     type="radio"
                     v-model="shiftId"/>&nbsp;&nbsp;
              <label :for="tallyShift.id"> {{ tallyShift.name.toString().toUpperCase() }}
                ( {{ tallyShift.startTime }} - {{ tallyShift.endTime }} )</label>
            </div>
          </div>
        </div>

        <div class="row" v-if="operationType === 'DISCHARGING' || operationType === 'LOADING' ">
          <div class="col-md-12 category-title">
            <b>Pick Cargo Type from category below</b> <sup class="error">*</sup>
          </div>
          <div :key="indexCargoType" class="col-md-4 cargo-type"
               v-for="(cargoType , indexCargoType) in globalCargoType">
            <div class="custom-control">
              <input :id="cargoType.id"
                     :value="cargoType.id"
                     name="cargo"
                     type="radio"
                     v-model="cargoTypeName"/>&nbsp;&nbsp;
              <label :for="cargoType.id"> {{ cargoType.name }} </label>
            </div>
          </div>
        </div>
        <div class="row" v-if="(operationType === 'STUFFING' || operationType ==='DESTUFFING' ) && tallyShift ">
          <div class="col-md-12 category-title">
            <b>Add Tallying operation location</b> <sup class="error">*</sup>
          </div>
          <div class="col-md-4">
            <div class="custom-control">
              <base-input type="text" v-model="operationLocation"></base-input>
            </div>
          </div>
        </div>
        <br/>

        <div class="row" v-if="!tallyVesselPerformance">
          <div class="col-md-12 category-title">
            <b>Pick Operation Date below</b> <sup class="error">*</sup>
          </div>
          <div class="col-md-3">
            <div class="custom-control">
              <base-input type="date" v-model="operationDate"></base-input>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <hr/>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6 text-left">
          <h5><b><span class="error">*</span> - means required</b></h5>
        </div>
        <div class="col-md-6">
          <button @click="closeModal" class="btn btn-fill btn-outline-info float-right"
                  type="button"> Close
          </button>

          <button @click="downloadReports" class="btn btn-primary float-right mr-3"
                  type="button"
                  v-if=" ( (operationDate !== '' && cargoTypeName !== '' && shiftId !== '' && (tallySheet || tallyShift))
                   || (operationDate !== '' && cargoTypeName !== '' && tallyDaily)
                   || (cargoTypeName !== '' && tallyVesselPerformance) )
                   && (operationType === 'LOADING' || operationType === 'DISCHARGING')">
            Generate Report 1
          </button>


          <button @click="downloadReports" class="btn btn-primary float-right mr-3"
                  type="button"form
                  v-if="( (operationDate !== '' && shiftId !== '' && tallySheet )
                   || (operationDate !== '' && operationLocation !== '' && shiftId !== '' &&  tallyShift)
                   || (operationDate !== ''  && tallyDaily) )
                   && (operationType === 'STUFFING' || operationType === 'DESTUFFING')">
            Generate Report 2
          </button>

          <button @click="downloadReports" class="btn btn-primary float-right mr-3"
                  type="button"
                  v-if=" tallyDelays || tallyEvents">
            Generate Report 3
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import {mapActions, mapGetters} from "vuex";

  export default {

    name: "tallySheetShiftDailyVesselPerformance",

    data() {

      return {
        tallyShifts: [],
        globalCargoType: [],
        operationDate: "",
        cargoTypeName: "",
        shiftId: "",
        operationLocation: ""
      }
    },

    props: {
      tallyDaily: false,
      tallySheet: false,
      tallyShift: false,
      tallyVesselPerformance: false,
      operationType: '',
      tallyDelays: false,
      tallyEvents: false,
    },

    computed: {

      ...mapGetters('tallyshift',[
        'getTallyShiftDetails'
      ]),

      // ...mapGetters({
      //
      //   getTallyShiftList: "tallyshift/getTallyShiftDetails",
      // }),

    },

    created() {
      this.loadTallyShifts();
      this.globalCargoType = this.getGlobalCargoType();
    },

    methods: {
      ...mapActions("tallyshift", [

        "loadShifts"
      ]),

      closeModal() {
        this.$emit("closeModal");
      },

      downloadReports() {
        let reportType = '';

        if (this.tallyDaily)
          reportType = 'DAILY';
        if (this.tallySheet)
          reportType = 'TALLY_SHEET';
        if (this.tallyShift)
          reportType = 'SHIFT';
        if (this.tallyVesselPerformance)
          reportType = 'VESSEL_PERFORMANCE';

        this.$emit("downloadReports", this.operationDate, this.cargoTypeName, this.shiftId, this.operationLocation, reportType);
      },

      loadTallyShifts() {
        this.loadShifts({}).then(() => {
          this.tallyShifts = this.getTallyShiftDetails;
        }).catch(() => {

        });
        this.tallyShifts = this.getTallyShiftDetails;
      },
    }
  }

</script>

<style scoped>

  .category-title {
    background-color: #f2f3f4;
    border-radius: 3px;
    margin-bottom: 10px
  }

  .tally-shift:hover, .cargo-type:hover {
    background-color: #f8f8ff;
    border-radius: 3px;
  }

  .error {
    color: red;
  }

</style>
