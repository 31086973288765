<template>
    <div>
        <card>
            <data-table
            :table-headers="tableHeaders"
            :table-data="filteredRequests"
            :table-data-keys="tableDataKeys"
            :my-first-loader="myFirstLoader"
            :my-loader="myLoader"
            :is-pageable="true"
            :page-size-prop="pageSize"
            :page-no="pageNo"
            :total-elements="totalElements"
            :total-pages="totalPages"
            :is-first="isFirst"
            :is-last="isLast"
            :page-number="pageNumber"
            :no-data-found="noDataFound"
            :loading-title="'Please wait while the system is loading disbursment deposits'"
            :permissions="[ {key: 'searchPermission', value: 'DISBURSEMENT_PAYMENT_ALL_PAGEABLE_GET'} ]"
            :whichModuleAndType="whichModuleAndType"
            @search="searchGeneralParams"
            @resetParams="resetParams"
            @onChange="onChange"
            @goToPage="goToPage">
            <div slot = "pagination">
                <paginate
                    v-model="getIsPage"
                    :page-count=parseInt(totalPages)
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="goToPage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </paginate>
            </div>
            </data-table>
        </card>

        <global-modal v-if="myLoader">
            <div slot="globalLoader">
                <div class="row text-center">
                <div class="col-md-12">Loading, Please wait</div>
                <div class="col-md-12">
                    <br/>
                </div>
                <div class="col-md-12">
                    <pulse-loader :color="color" :size="size"></pulse-loader>
                </div>
                </div>
            </div>
        </global-modal>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import GlobalModal from "../../../../components/globalLoader"
import PulseLoader from "vue-spinner/src/PulseLoader.vue"

export default {

    name: 'disbursementDeposit',

    components: {

        DataTable: () => import('../../../../components/DataTable/dataTable.vue'),
        GlobalModal,
        PulseLoader

    },

    computed: {

        ...mapGetters('subdisbursment', [

            'getRequestList',
            'getPageCount',
            'getMyFirstLoader',
            'getNoDataFound',
            'getPageNo',
            'getTotalElement',
            'getTotalPage',
            'getPageNumber',
            'getPageSize',
            'getIsFirst',
            'getIsLast',
            'getMyFirstLoader',
            'getMyLoader',
            'getHasSearchedVariable',

        ]),


        getIsPage: {

            get: function () {

                return this.isPage

            },
            set: function (newValue) {

                this.isPage = newValue;

            }

        }

    },

    created () {

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
        this.$store.dispatch('pagenames/currentPageName', 'Disbursment account deposits')
        this.loadPageableRequestOnCreated(this.initialParams, 0)

    },

    data () {

        return {

            color: "green",
            size: "12px",

            requests: [],
            filteredRequests: [],
            finalUserPermissions: [],
            hasSearchedVariable: false,
            noDataFound: false,
            isPage: 0,
            pageNo: 0,
            pageSize: 15,
            pageCount: 1,
            pageNoPart: 0,
            totalElements: '',
            totalPages: '',
            isFirst: false,
            isLast: false,
            pageNumber: '',
            myFirstLoader: false,
            myLoader: false,
            initialParams: '?size=' + 15 + '&page=' + 0,
            newParams: '',
            whichModuleAndType: 'disbursementDeposit',

            tableHeaders: ['Payer Name', 'Amount', 'Amount Type', 'Transaction Channel', 'Payment Reference', 'Transaction Date', 'Payment Type', 'Payer Mobile', 'Payment Description'],
            tableDataKeys: ['payerName', 'amount', 'amountType', 'transactionChannel', 'paymentReference', 'transactionDate', 'paymentType', 'payerMobile', 'paymentDesc'],

        }

    },

    methods: {

        ...mapActions('subdisbursment', [

            'loadDisbursementDepositsOnCreated',
            'loadDisbursementDepositsAfterCreated',
            'loadDisbursementDepositsOnSearch'

        ]),

        sortingData (data) {

            for(let x = 0 ; x < data.length ; x++) {

                const requestObject = {

                    ...data[x],
                    transactionDate:this.dateTimeToHuman(data[x].transactionDate),
                    dateBilled: data[x].transactionDate !== null ? this.dateTimeToHuman(data[x].transactionDate) : '',
                    amount: data[x].amount !== null ? this.putComma(data[x].amount) + ' ' + data[x].currency : ''

                }

                this.filteredRequests.push(requestObject)

            }

        },

        resetParams() {

            this.loadPageableRequestOnCreated(this.initialParams , 0)
            this.$store.dispatch("subdisbursment/hasSearchngParams", false);
            this.newParams = ""
            this.pageNoPart = 0
            this.isPage = this.pageNoPart + 1
            this.filteredRequests = []
            this.requests = []


        },

        loadSearchParameters(params, fromApplicationPageNo) {

            let fromApplicationPageNoOrNot
            typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0

            let firstPart = params.split("&page=")[0]
            let secondPart = firstPart.split("size=")[0]
            this.newParams = ""

            this.isPage = fromApplicationPageNo
            this.newParams = secondPart + 'size=' + this.pageSize + '&page=' + this.pageNo

            return new Promise((resolve) => {

            this.$store.dispatch("subdisbursment/pageSizing", this.pageSize);
            this.$store.dispatch("subdisbursment/pageNumbering", fromApplicationPageNoOrNot);
            this.$store.dispatch("subdisbursment/searchingParameter", this.newParams);

            resolve();

            })

        },

        setAllGetters(flag) {

            this.requests = this.getRequestList;
            flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
            flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
            this.noDataFound = this.getNoDataFound;
            this.pageNo = this.getPageNo;
            this.pageSize = this.getPageSize;

            this.totalElements = this.getTotalElement;
            this.totalPages = this.getTotalPage;
            this.pageNumber = this.getPageNumber;
            this.isFirst = this.getIsFirst;
            this.isLast = this.getIsLast;

            this.sortingData(this.requests)

        },

        searchGeneralParams(params, fromApplicationPageNo) {

            this.newParams = params

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.loadDisbursementDepositsOnSearch({}).then(() => {

                this.setAllGetters('onCreated')

                }).catch(() => {


                });

                this.myFirstLoader = this.getMyFirstLoader;

            }).catch(() => {


            });

        },

        loadPageableRequestOnCreated(params, fromApplicationPageNo) {

            this.newParams = params

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.loadDisbursementDepositsOnCreated({}).then(() => {

                this.setAllGetters('onCreated');

                }).catch(() => {


                });

                this.myFirstLoader = this.getMyFirstLoader;

            }).catch(() => {


            });

        },

        loadPageableRequestsAfterCreated(params, fromApplicationPageNo) {

            this.newParams = params

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.loadDisbursementDepositsAfterCreated({}).then(() => {

                this.setAllGetters('afterCreated');

                });

                this.myLoader = this.getMyLoader;

            }).catch(() => {


            });

        },

        goToPage(page) {

            if(page > 0) {page = page - 1;}
            this.isPage = page + 1

            this.pageNo = page;

            if (this.getHasSearchedVariable) {

                let firstPart = this.newParams.split("page=")[0]
                this.newParams = ""
                this.newParams = firstPart + '&page=' + this.pageNo
                this.pageNoPart = this.isPage

                this.searchGeneralParams(this.newParams, this.pageNoPart);
                this.filteredRequests = []

            } else {
                console.log('this.pageNoPart', this.pageNoPart)
                let newparams = '?size=' + this.pageSize + '&page=' + this.pageNo

                this.loadPageableRequestsAfterCreated(newparams, this.pageNoPart);
                this.filteredRequests = []
            }

        },

        onChange (event) {

            this.$store.dispatch("subdisbursment/pageSizing", event);
            this.$store.dispatch("subdisbursment/pageNumbering", 0);
            this.isPage = 0

            this.pageSize = Number(this.getPageSize);
            this.pageNo = this.getPageNo;

            if (this.getHasSearchedVariable) {

                let firstPart = this.newParams.split("&page=")[0]
                let secondPart = firstPart.split("size=")[0]
                let thirdPart = firstPart.split("size=")[1]
                this.newParams = ""
                this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + 0
                this.pageNoPart = 0

                this.searchGeneralParams(this.newParams , this.pageNoPart);
                this.filteredRequests = []

            } else {

                let newparams = '?size=' + this.pageSize + '&page=' + 0

                this.loadPageableRequestOnCreated(newparams , 0);
                this.filteredRequests = []
            }

        },

    },

}

</script>

<style scoped>

</style>

<style>

</style>
